import "./index.less";

import React, { FunctionComponent, useCallback, useEffect, useState } from "react";
import { Select, Tag } from "antd";
import { SizeType } from "antd/lib/config-provider/SizeContext";

const { Option } = Select;
export type SelectOption = {
  value: string,
  text: string
}
const QuerySelectComponent: FunctionComponent<{
  disabled: boolean,
  selectedValue: string[],
  setSelectedValue: any,
  optionSource: SelectOption[],
  placeholder?: string,
  size?: SizeType
}> = (props: {
  disabled: boolean,
  selectedValue: string[],
  setSelectedValue: any,
  optionSource: SelectOption[],
  placeholder?: string,
  size?: SizeType
}) => {
    const [options, setOptions] = useState<SelectOption[]>([]);
    const { selectedValue, setSelectedValue }: { selectedValue: string[], setSelectedValue: any } = props;
    const { disabled, optionSource, placeholder, size } = props;

    useEffect(() => { setOptions(optionSource.filter(x => selectedValue.indexOf(x.value) == -1)); }, [optionSource, selectedValue]);

    const onSelect = useCallback((value) => {
        if (selectedValue.filter(x => x == value).length === 0) {
            setSelectedValue([...selectedValue, value]);
            setOptions([...options.filter(x => x.value != value)]);
        }
    }, [selectedValue, options]);
    const onDeselect = useCallback((value) => {
        setSelectedValue([...selectedValue.filter(x => x != value)]);
        const newOptions = [...options, ...(optionSource.filter(x => x.value == value))];
        setOptions(optionSource.filter(x => newOptions.findIndex(o => o.value == x.value) > -1));
    }, [selectedValue, options]);

    return (
        <div className="queryselect-container">
            <Select size={size ? size : "large"}
                mode="multiple" disabled={disabled}
                showArrow
                allowClear
                placeholder={placeholder}
                value={[]}
                onSelect={onSelect}
            >
                {options.map(x => <Option key={x.value} value={x.value}>{x.text}</Option>)}
            </Select>
            <br />
            <div className="div-tags">
                {selectedValue.map(x => <Tag closable={!disabled} key={x} onClose={e => { onDeselect(x); }}>{x}</Tag>)}
            </div>
        </div>
    );
};
export default QuerySelectComponent;
