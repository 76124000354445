export const simulationInitData = {
    "queryId": 962,
    "queryGroupData": [
        {
            "crop": "maize",
            "enso": "ALL",
            "location": "9 de Julio",
            "latitude": -35.4013,
            "longitude": -60.9338,
            "soilTexture": "Clay_Loam",
            "faw": "100",
            "soilDepth": 100,
            "cultivar": "Medium",
            "season": "",
            "plantDayOfYear": [
                277,
                284,
                291,
                298,
                305,
                312,
                319,
                326,
                333,
                340,
                347
            ],
            "plantDate": [
                "Oct 04",
                "Oct 11",
                "Oct 18",
                "Oct 25",
                "Nov 01",
                "Nov 08",
                "Nov 15",
                "Nov 22",
                "Nov 29",
                "Dec 06",
                "Dec 13"
            ],
            "data": [
                {
                    "water": "Irrigated",
                    "percentiles": {
                        "p25": {
                            "yield": [
                                16.018464,
                                15.88818,
                                15.097236000000002,
                                14.70546,
                                14.770140000000001,
                                14.500332,
                                13.903428,
                                12.371436000000001,
                                9.953328,
                                8.191256000000001,
                                6.917988
                            ],
                            "dapR1": [
                                97,
                                93,
                                89,
                                88,
                                83,
                                81,
                                80,
                                78,
                                77,
                                78,
                                76
                            ],
                            "dapR6": [
                                160,
                                157,
                                158,
                                156,
                                154,
                                157,
                                157,
                                154,
                                158,
                                151,
                                148
                            ]
                        },
                        "p50": {
                            "yield": [
                                17.367504,
                                17.044104,
                                16.61814,
                                16.520196000000002,
                                16.09608,
                                15.882636,
                                15.085224,
                                14.309988000000002,
                                12.411168000000002,
                                9.847992000000001,
                                7.793016000000001
                            ],
                            "dapR1": [
                                100,
                                96,
                                94,
                                90,
                                86,
                                85,
                                81,
                                81,
                                80,
                                81,
                                79
                            ],
                            "dapR6": [
                                165,
                                162,
                                161,
                                161,
                                159,
                                164,
                                164,
                                163,
                                172,
                                168,
                                161
                            ]
                        },
                        "p75": {
                            "yield": [
                                17.653944000000003,
                                17.540292,
                                17.871084,
                                17.345328000000002,
                                17.137428,
                                16.942464,
                                15.953783999999999,
                                15.215508,
                                13.349952,
                                10.668504,
                                8.963728000000001
                            ],
                            "dapR1": [
                                102,
                                100,
                                96,
                                92,
                                88,
                                87,
                                85,
                                84,
                                84,
                                83,
                                83
                            ],
                            "dapR6": [
                                171,
                                166,
                                164,
                                165,
                                164,
                                170,
                                169,
                                175,
                                178,
                                173,
                                166
                            ]
                        }
                    }
                },
                {
                    "water": "Rainfed",
                    "percentiles": {
                        "p25": {
                            "yield": [
                                4.1450640000000005,
                                4.354812,
                                6.027252000000001,
                                5.6373240000000004,
                                5.789784000000001,
                                5.6308560000000005,
                                5.141136,
                                5.682600000000001,
                                4.777080000000001,
                                4.834368,
                                4.177404
                            ],
                            "dapR1": [
                                97,
                                93,
                                89,
                                88,
                                83,
                                81,
                                80,
                                78,
                                77,
                                78,
                                76
                            ],
                            "dapR6": [
                                159,
                                156,
                                156,
                                154,
                                153,
                                154,
                                149,
                                151,
                                149,
                                142,
                                135
                            ]
                        },
                        "p50": {
                            "yield": [
                                9.175320000000001,
                                9.465456000000001,
                                10.285044000000001,
                                8.130276,
                                9.784236,
                                9.376752000000002,
                                8.427804,
                                8.19588,
                                7.92792,
                                7.565712000000001,
                                6.01524
                            ],
                            "dapR1": [
                                100,
                                96,
                                94,
                                90,
                                86,
                                85,
                                81,
                                81,
                                80,
                                81,
                                79
                            ],
                            "dapR6": [
                                165,
                                162,
                                160,
                                161,
                                159,
                                160,
                                161,
                                163,
                                166,
                                165,
                                158
                            ]
                        },
                        "p75": {
                            "yield": [
                                13.604976,
                                13.249236,
                                12.867624000000001,
                                13.391532000000002,
                                13.141128000000002,
                                13.449744000000003,
                                12.587652,
                                11.325468,
                                10.723944000000001,
                                8.498952000000001,
                                7.645175999999999
                            ],
                            "dapR1": [
                                102,
                                100,
                                96,
                                92,
                                88,
                                87,
                                85,
                                84,
                                84,
                                83,
                                83
                            ],
                            "dapR6": [
                                171,
                                166,
                                164,
                                163,
                                164,
                                170,
                                167,
                                173,
                                176,
                                173,
                                166
                            ]
                        }
                    }
                }
            ]
        },
        {
            "crop": "maize",
            "enso": "ALL",
            "location": "9 de Julio",
            "latitude": -35.4013,
            "longitude": -60.9338,
            "soilTexture": "Clay_Loam",
            "faw": "100",
            "soilDepth": 140,
            "cultivar": "Medium",
            "season": "",
            "plantDayOfYear": [
                277,
                284,
                291,
                298,
                305,
                312,
                319,
                326,
                333,
                340,
                347
            ],
            "plantDate": [
                "Oct 04",
                "Oct 11",
                "Oct 18",
                "Oct 25",
                "Nov 01",
                "Nov 08",
                "Nov 15",
                "Nov 22",
                "Nov 29",
                "Dec 06",
                "Dec 13"
            ],
            "data": [
                {
                    "water": "Irrigated",
                    "percentiles": {
                        "p25": {
                            "yield": [
                                16.018464,
                                15.88818,
                                15.097236000000002,
                                14.70546,
                                14.770140000000001,
                                14.500332,
                                13.903428,
                                12.371436000000001,
                                9.953328,
                                8.191256000000001,
                                6.917988
                            ],
                            "dapR1": [
                                97,
                                93,
                                89,
                                88,
                                83,
                                81,
                                80,
                                78,
                                77,
                                78,
                                76
                            ],
                            "dapR6": [
                                160,
                                157,
                                158,
                                156,
                                154,
                                157,
                                157,
                                154,
                                158,
                                151,
                                148
                            ]
                        },
                        "p50": {
                            "yield": [
                                17.367504,
                                17.044104,
                                16.61814,
                                16.520196000000002,
                                16.09608,
                                15.882636,
                                15.085224,
                                14.309988000000002,
                                12.411168000000002,
                                9.847992000000001,
                                7.793016000000001
                            ],
                            "dapR1": [
                                100,
                                96,
                                94,
                                90,
                                86,
                                85,
                                81,
                                81,
                                80,
                                81,
                                79
                            ],
                            "dapR6": [
                                165,
                                162,
                                161,
                                161,
                                159,
                                164,
                                164,
                                163,
                                172,
                                168,
                                161
                            ]
                        },
                        "p75": {
                            "yield": [
                                17.653944000000003,
                                17.540292,
                                17.871084,
                                17.345328000000002,
                                17.137428,
                                16.942464,
                                15.953783999999999,
                                15.215508,
                                13.349952,
                                10.668504,
                                8.963728000000001
                            ],
                            "dapR1": [
                                102,
                                100,
                                96,
                                92,
                                88,
                                87,
                                85,
                                84,
                                84,
                                83,
                                83
                            ],
                            "dapR6": [
                                171,
                                166,
                                164,
                                165,
                                164,
                                170,
                                169,
                                175,
                                178,
                                173,
                                166
                            ]
                        }
                    }
                },
                {
                    "water": "Rainfed",
                    "percentiles": {
                        "p25": {
                            "yield": [
                                11.545380000000002,
                                11.099088000000002,
                                10.227756,
                                10.524360000000001,
                                10.562244,
                                10.840368,
                                10.856076000000002,
                                10.46892,
                                9.507036,
                                7.882644000000001,
                                6.148296
                            ],
                            "dapR1": [
                                97,
                                93,
                                89,
                                88,
                                83,
                                81,
                                80,
                                78,
                                77,
                                78,
                                76
                            ],
                            "dapR6": [
                                160,
                                157,
                                158,
                                156,
                                154,
                                157,
                                157,
                                154,
                                158,
                                151,
                                144
                            ]
                        },
                        "p50": {
                            "yield": [
                                12.717936000000002,
                                13.315764,
                                13.823964000000002,
                                13.750044,
                                13.464528000000001,
                                12.759516000000001,
                                13.03302,
                                11.666424000000001,
                                10.017084,
                                8.498952000000001,
                                7.266336
                            ],
                            "dapR1": [
                                100,
                                96,
                                94,
                                90,
                                86,
                                85,
                                81,
                                81,
                                80,
                                81,
                                79
                            ],
                            "dapR6": [
                                165,
                                162,
                                161,
                                161,
                                159,
                                164,
                                164,
                                163,
                                172,
                                168,
                                161
                            ]
                        },
                        "p75": {
                            "yield": [
                                15.899268000000001,
                                16.11918,
                                15.347640000000002,
                                15.830892,
                                15.872472,
                                15.187788000000001,
                                14.476308000000001,
                                13.760208,
                                12.087768,
                                10.084536,
                                7.9417800000000005
                            ],
                            "dapR1": [
                                102,
                                100,
                                96,
                                92,
                                88,
                                87,
                                85,
                                84,
                                84,
                                83,
                                83
                            ],
                            "dapR6": [
                                171,
                                166,
                                164,
                                165,
                                164,
                                170,
                                169,
                                175,
                                178,
                                173,
                                166
                            ]
                        }
                    }
                }
            ]
        },
        {
            "crop": "maize",
            "enso": "ALL",
            "location": "9 de Julio",
            "latitude": -35.4013,
            "longitude": -60.9338,
            "soilTexture": "Clay_Loam",
            "faw": "100",
            "soilDepth": 180,
            "cultivar": "Medium",
            "season": "",
            "plantDayOfYear": [
                277,
                284,
                291,
                298,
                305,
                312,
                319,
                326,
                333,
                340,
                347
            ],
            "plantDate": [
                "Oct 04",
                "Oct 11",
                "Oct 18",
                "Oct 25",
                "Nov 01",
                "Nov 08",
                "Nov 15",
                "Nov 22",
                "Nov 29",
                "Dec 06",
                "Dec 13"
            ],
            "data": [
                {
                    "water": "Irrigated",
                    "percentiles": {
                        "p25": {
                            "yield": [
                                16.018464,
                                15.88818,
                                15.097236000000002,
                                14.70546,
                                14.770140000000001,
                                14.500332,
                                13.903428,
                                12.371436000000001,
                                9.953328,
                                8.191256000000001,
                                6.917988
                            ],
                            "dapR1": [
                                97,
                                93,
                                89,
                                88,
                                83,
                                81,
                                80,
                                78,
                                77,
                                78,
                                76
                            ],
                            "dapR6": [
                                160,
                                157,
                                158,
                                156,
                                154,
                                157,
                                157,
                                154,
                                158,
                                151,
                                148
                            ]
                        },
                        "p50": {
                            "yield": [
                                17.367504,
                                17.044104,
                                16.61814,
                                16.520196000000002,
                                16.09608,
                                15.882636,
                                15.085224,
                                14.309988000000002,
                                12.411168000000002,
                                9.847992000000001,
                                7.793016000000001
                            ],
                            "dapR1": [
                                100,
                                96,
                                94,
                                90,
                                86,
                                85,
                                81,
                                81,
                                80,
                                81,
                                79
                            ],
                            "dapR6": [
                                165,
                                162,
                                161,
                                161,
                                159,
                                164,
                                164,
                                163,
                                172,
                                168,
                                161
                            ]
                        },
                        "p75": {
                            "yield": [
                                17.653944000000003,
                                17.540292,
                                17.871084,
                                17.345328000000002,
                                17.137428,
                                16.942464,
                                15.953783999999999,
                                15.215508,
                                13.349952,
                                10.668504,
                                8.963728000000001
                            ],
                            "dapR1": [
                                102,
                                100,
                                96,
                                92,
                                88,
                                87,
                                85,
                                84,
                                84,
                                83,
                                83
                            ],
                            "dapR6": [
                                171,
                                166,
                                164,
                                165,
                                164,
                                170,
                                169,
                                175,
                                178,
                                173,
                                166
                            ]
                        }
                    }
                },
                {
                    "water": "Rainfed",
                    "percentiles": {
                        "p25": {
                            "yield": [
                                12.574716,
                                12.591348000000002,
                                12.247620000000001,
                                12.354804000000001,
                                12.403776,
                                12.020316000000001,
                                11.435424000000001,
                                11.969496,
                                9.805488,
                                7.952868,
                                6.459684
                            ],
                            "dapR1": [
                                97,
                                93,
                                89,
                                88,
                                83,
                                81,
                                80,
                                78,
                                77,
                                78,
                                76
                            ],
                            "dapR6": [
                                160,
                                157,
                                158,
                                156,
                                154,
                                157,
                                157,
                                154,
                                158,
                                151,
                                148
                            ]
                        },
                        "p50": {
                            "yield": [
                                14.208348,
                                14.301672,
                                14.887488,
                                14.656488,
                                14.674044000000002,
                                14.219436,
                                13.468224000000001,
                                12.6126,
                                11.500104,
                                9.227988000000002,
                                7.266336
                            ],
                            "dapR1": [
                                100,
                                96,
                                94,
                                90,
                                86,
                                85,
                                81,
                                81,
                                80,
                                81,
                                79
                            ],
                            "dapR6": [
                                165,
                                162,
                                161,
                                161,
                                159,
                                164,
                                164,
                                163,
                                172,
                                168,
                                161
                            ]
                        },
                        "p75": {
                            "yield": [
                                16.603356,
                                16.11918,
                                15.840132,
                                16.45182,
                                15.901116,
                                15.793932000000002,
                                15.281112,
                                14.12796,
                                12.303984,
                                10.240692000000001,
                                7.989828
                            ],
                            "dapR1": [
                                102,
                                100,
                                96,
                                92,
                                88,
                                87,
                                85,
                                84,
                                84,
                                83,
                                83
                            ],
                            "dapR6": [
                                171,
                                166,
                                164,
                                165,
                                164,
                                170,
                                169,
                                175,
                                178,
                                173,
                                166
                            ]
                        }
                    }
                }
            ]
        }
    ]
};

export const simulationLocations = [
    {
        "id": 129,
        "country": "Argentina",
        "countryCode": "AR",
        "location": "9 de Julio",
        "latitude": -35.40138,
        "longitude": -60.93383,
        "isActive": true
    },
    {
        "id": 57,
        "country": "Brazil",
        "countryCode": "BR",
        "location": "Acapu",
        "latitude": -8.36,
        "longitude": -50.83,
        "isActive": true
    },
    {
        "id": 72,
        "country": "Brazil",
        "countryCode": "BR",
        "location": "Agua Boa",
        "latitude": -14,
        "longitude": -52.2,
        "isActive": true
    },
    {
        "id": 54,
        "country": "Brazil",
        "countryCode": "BR",
        "location": "Alto Piquiri",
        "latitude": -24.12,
        "longitude": -53.46,
        "isActive": true
    },
    {
        "id": 103,
        "country": "Argentina",
        "countryCode": "AR",
        "location": "Anguil",
        "latitude": -36.542,
        "longitude": -63.991,
        "isActive": true
    },
    {
        "id": 81,
        "country": "Brazil",
        "countryCode": "BR",
        "location": "Araguaiana",
        "latitude": -7.19,
        "longitude": -48.21,
        "isActive": true
    },
    {
        "id": 65,
        "country": "Brazil",
        "countryCode": "BR",
        "location": "Araxa",
        "latitude": -19.6,
        "longitude": -46.93,
        "isActive": true
    },
    {
        "id": 149,
        "country": "Argentina",
        "countryCode": "AR",
        "location": "Azul",
        "latitude": -36.833,
        "longitude": -59.883,
        "isActive": true
    },
    {
        "id": 107,
        "country": "Argentina",
        "countryCode": "AR",
        "location": "Bahia Blanca",
        "latitude": -38.725,
        "longitude": -62.169,
        "isActive": true
    },
    {
        "id": 98,
        "country": "Argentina",
        "countryCode": "AR",
        "location": "Balcarce",
        "latitude": -37.763,
        "longitude": -58.298,
        "isActive": true
    },
    {
        "id": 70,
        "country": "Brazil",
        "countryCode": "BR",
        "location": "Bambui",
        "latitude": -19.91,
        "longitude": -46.1,
        "isActive": true
    },
    {
        "id": 142,
        "country": "Argentina",
        "countryCode": "AR",
        "location": "Barrow",
        "latitude": -38.333,
        "longitude": -60.217,
        "isActive": true
    },
    {
        "id": 146,
        "country": "Argentina",
        "countryCode": "AR",
        "location": "Bella Vista",
        "latitude": -28.43,
        "longitude": -58.92,
        "isActive": true
    },
    {
        "id": 151,
        "country": "Argentina",
        "countryCode": "AR",
        "location": "Benito Juarez",
        "latitude": -37.704952,
        "longitude": -59.792872,
        "isActive": true
    },
    {
        "id": 156,
        "country": "Argentina",
        "countryCode": "AR",
        "location": "Bordenave",
        "latitude": -37.85,
        "longitude": -63.02,
        "isActive": true
    },
    {
        "id": 88,
        "country": "Brazil",
        "countryCode": "BR",
        "location": "Campo Mourao",
        "latitude": -24.05,
        "longitude": -52.36,
        "isActive": true
    },
    {
        "id": 48,
        "country": "Brazil",
        "countryCode": "BR",
        "location": "Canarana",
        "latitude": -13.47,
        "longitude": -52.27,
        "isActive": true
    },
    {
        "id": 78,
        "country": "Brazil",
        "countryCode": "BR",
        "location": "Capinopolis",
        "latitude": -18.71,
        "longitude": -49.55,
        "isActive": true
    },
    {
        "id": 85,
        "country": "Brazil",
        "countryCode": "BR",
        "location": "Cascavel",
        "latitude": -24.53,
        "longitude": -53.33,
        "isActive": true
    },
    {
        "id": 122,
        "country": "Argentina",
        "countryCode": "AR",
        "location": "Castelar",
        "latitude": -34.605,
        "longitude": -58.671,
        "isActive": true
    },
    {
        "id": 67,
        "country": "Brazil",
        "countryCode": "BR",
        "location": "Catalao",
        "latitude": -18.18,
        "longitude": -47.95,
        "isActive": true
    },
    {
        "id": 140,
        "country": "Argentina",
        "countryCode": "AR",
        "location": "Ceres",
        "latitude": -29.883,
        "longitude": -61.95,
        "isActive": true
    },
    {
        "id": 82,
        "country": "Brazil",
        "countryCode": "BR",
        "location": "Chapeco",
        "latitude": -27.08,
        "longitude": -52.61,
        "isActive": true
    },
    {
        "id": 120,
        "country": "Argentina",
        "countryCode": "AR",
        "location": "Colonia Benitez",
        "latitude": -27.42,
        "longitude": -58.93,
        "isActive": true
    },
    {
        "id": 110,
        "country": "Argentina",
        "countryCode": "AR",
        "location": "Concepcion del Uruguay",
        "latitude": -32.488,
        "longitude": -58.348,
        "isActive": true
    },
    {
        "id": 121,
        "country": "Argentina",
        "countryCode": "AR",
        "location": "Concordia",
        "latitude": -31.297,
        "longitude": -57.997,
        "isActive": true
    },
    {
        "id": 145,
        "country": "Argentina",
        "countryCode": "AR",
        "location": "Cordoba",
        "latitude": -31.324,
        "longitude": -64.208,
        "isActive": true
    },
    {
        "id": 125,
        "country": "Argentina",
        "countryCode": "AR",
        "location": "Coronel Suarez",
        "latitude": -37.445601,
        "longitude": -61.889037,
        "isActive": true
    },
    {
        "id": 97,
        "country": "Argentina",
        "countryCode": "AR",
        "location": "Corrientes",
        "latitude": -27.446,
        "longitude": -58.762,
        "isActive": true
    },
    {
        "id": 61,
        "country": "Brazil",
        "countryCode": "BR",
        "location": "Cruz Alta",
        "latitude": -28.63,
        "longitude": -53.6,
        "isActive": true
    },
    {
        "id": 141,
        "country": "Argentina",
        "countryCode": "AR",
        "location": "Dolores",
        "latitude": -36.35,
        "longitude": -57.733,
        "isActive": true
    },
    {
        "id": 115,
        "country": "Argentina",
        "countryCode": "AR",
        "location": "El Colorado",
        "latitude": -26.3,
        "longitude": -59.38,
        "isActive": true
    },
    {
        "id": 87,
        "country": "Brazil",
        "countryCode": "BR",
        "location": "Encruzilhada",
        "latitude": -30.53,
        "longitude": -52.51,
        "isActive": true
    },
    {
        "id": 106,
        "country": "Argentina",
        "countryCode": "AR",
        "location": "Ezeiza",
        "latitude": -34.822,
        "longitude": -58.536,
        "isActive": true
    },
    {
        "id": 137,
        "country": "Argentina",
        "countryCode": "AR",
        "location": "Famailla",
        "latitude": -26.81,
        "longitude": -65.25,
        "isActive": true
    },
    {
        "id": 111,
        "country": "Argentina",
        "countryCode": "AR",
        "location": "Formosa",
        "latitude": -26.213,
        "longitude": -58.228,
        "isActive": true
    },
    {
        "id": 52,
        "country": "Brazil",
        "countryCode": "BR",
        "location": "Gleba Celeste",
        "latitude": -12.2,
        "longitude": -56.5,
        "isActive": true
    },
    {
        "id": 74,
        "country": "Brazil",
        "countryCode": "BR",
        "location": "Goias",
        "latitude": -15.91,
        "longitude": -50.13,
        "isActive": true
    },
    {
        "id": 113,
        "country": "Argentina",
        "countryCode": "AR",
        "location": "Gral Pico",
        "latitude": -35.696,
        "longitude": -63.758,
        "isActive": true
    },
    {
        "id": 105,
        "country": "Argentina",
        "countryCode": "AR",
        "location": "Gral Villegas",
        "latitude": -34.92,
        "longitude": -62.73,
        "isActive": true
    },
    {
        "id": 134,
        "country": "Argentina",
        "countryCode": "AR",
        "location": "Gualeguaychu",
        "latitude": -33.01,
        "longitude": -58.613,
        "isActive": true
    },
    {
        "id": 119,
        "country": "Argentina",
        "countryCode": "AR",
        "location": "Hilario Ascasubi",
        "latitude": -39.383,
        "longitude": -62.617,
        "isActive": true
    },
    {
        "id": 90,
        "country": "Brazil",
        "countryCode": "BR",
        "location": "Ibiruba",
        "latitude": -28.65,
        "longitude": -53.11,
        "isActive": true
    },
    {
        "id": 66,
        "country": "Brazil",
        "countryCode": "BR",
        "location": "Irai",
        "latitude": -27.18,
        "longitude": -53.23,
        "isActive": true
    },
    {
        "id": 89,
        "country": "Brazil",
        "countryCode": "BR",
        "location": "Irati",
        "latitude": -25.46,
        "longitude": -50.63,
        "isActive": true
    },
    {
        "id": 86,
        "country": "Brazil",
        "countryCode": "BR",
        "location": "Ivinhema",
        "latitude": -22.3,
        "longitude": -53.81,
        "isActive": true
    },
    {
        "id": 118,
        "country": "Argentina",
        "countryCode": "AR",
        "location": "Junin",
        "latitude": -34.546,
        "longitude": -60.931,
        "isActive": true
    },
    {
        "id": 148,
        "country": "Argentina",
        "countryCode": "AR",
        "location": "Laboulaye",
        "latitude": -34.135,
        "longitude": -63.362,
        "isActive": true
    },
    {
        "id": 94,
        "country": "Brazil",
        "countryCode": "BR",
        "location": "Lagoa Vermelha",
        "latitude": -28.21,
        "longitude": -51.5,
        "isActive": true
    },
    {
        "id": 154,
        "country": "Argentina",
        "countryCode": "AR",
        "location": "Las Brenas",
        "latitude": -27.073,
        "longitude": -61.062,
        "isActive": true
    },
    {
        "id": 126,
        "country": "Argentina",
        "countryCode": "AR",
        "location": "Las Flores",
        "latitude": -36.067,
        "longitude": -59.1,
        "isActive": true
    },
    {
        "id": 53,
        "country": "Brazil",
        "countryCode": "BR",
        "location": "Lucas do Rio Verde",
        "latitude": -13.37,
        "longitude": -55.54,
        "isActive": true
    },
    {
        "id": 55,
        "country": "Brazil",
        "countryCode": "BR",
        "location": "Machado",
        "latitude": -21.66,
        "longitude": -45.91,
        "isActive": true
    },
    {
        "id": 147,
        "country": "Argentina",
        "countryCode": "AR",
        "location": "Manfredi",
        "latitude": -31.82,
        "longitude": -63.77,
        "isActive": true
    },
    {
        "id": 136,
        "country": "Argentina",
        "countryCode": "AR",
        "location": "Mar del Plata",
        "latitude": -37.934,
        "longitude": -57.573,
        "isActive": true
    },
    {
        "id": 124,
        "country": "Argentina",
        "countryCode": "AR",
        "location": "Marcos Juarez",
        "latitude": -32.719,
        "longitude": -62.106,
        "isActive": true
    },
    {
        "id": 71,
        "country": "Brazil",
        "countryCode": "BR",
        "location": "Maringa",
        "latitude": -23.4,
        "longitude": -51.91,
        "isActive": true
    },
    {
        "id": 62,
        "country": "Brazil",
        "countryCode": "BR",
        "location": "Mineiros",
        "latitude": -18.04,
        "longitude": -52.62,
        "isActive": true
    },
    {
        "id": 144,
        "country": "Argentina",
        "countryCode": "AR",
        "location": "Monte Caseros",
        "latitude": -30.272,
        "longitude": -57.64,
        "isActive": true
    },
    {
        "id": 77,
        "country": "Brazil",
        "countryCode": "BR",
        "location": "Monte Santo",
        "latitude": -10.43,
        "longitude": -39.29,
        "isActive": true
    },
    {
        "id": 93,
        "country": "Brazil",
        "countryCode": "BR",
        "location": "Nova Ubirata",
        "latitude": -12.99,
        "longitude": -55.26,
        "isActive": true
    },
    {
        "id": 153,
        "country": "Argentina",
        "countryCode": "AR",
        "location": "Oliveros",
        "latitude": -32.55,
        "longitude": -60.85,
        "isActive": true
    },
    {
        "id": 92,
        "country": "Brazil",
        "countryCode": "BR",
        "location": "Paracatu",
        "latitude": -17.23,
        "longitude": -46.88,
        "isActive": true
    },
    {
        "id": 96,
        "country": "Argentina",
        "countryCode": "AR",
        "location": "Parana",
        "latitude": -31.795,
        "longitude": -60.48,
        "isActive": true
    },
    {
        "id": 73,
        "country": "Brazil",
        "countryCode": "BR",
        "location": "Pato Branco",
        "latitude": -26.29,
        "longitude": -52.61,
        "isActive": true
    },
    {
        "id": 64,
        "country": "Brazil",
        "countryCode": "BR",
        "location": "Patos de Minas",
        "latitude": -18.51,
        "longitude": -46.43,
        "isActive": true
    },
    {
        "id": 59,
        "country": "Brazil",
        "countryCode": "BR",
        "location": "Pedro Afonso",
        "latitude": -8.97,
        "longitude": -48.17,
        "isActive": true
    },
    {
        "id": 138,
        "country": "Argentina",
        "countryCode": "AR",
        "location": "Pehuajo",
        "latitude": -35.844127,
        "longitude": -61.85541,
        "isActive": true
    },
    {
        "id": 152,
        "country": "Argentina",
        "countryCode": "AR",
        "location": "Pergamino",
        "latitude": -33.888,
        "longitude": -60.569,
        "isActive": true
    },
    {
        "id": 150,
        "country": "Argentina",
        "countryCode": "AR",
        "location": "Pilar",
        "latitude": -31.667,
        "longitude": -63.883,
        "isActive": true
    },
    {
        "id": 60,
        "country": "Brazil",
        "countryCode": "BR",
        "location": "Ponta Pora",
        "latitude": -22.53,
        "longitude": -55.53,
        "isActive": true
    },
    {
        "id": 75,
        "country": "Brazil",
        "countryCode": "BR",
        "location": "Poxoreu",
        "latitude": -15.83,
        "longitude": -54.38,
        "isActive": true
    },
    {
        "id": 79,
        "country": "Brazil",
        "countryCode": "BR",
        "location": "Primavera do Leste",
        "latitude": -14.96,
        "longitude": -54.21,
        "isActive": true
    },
    {
        "id": 123,
        "country": "Argentina",
        "countryCode": "AR",
        "location": "Rafaela",
        "latitude": -31.18,
        "longitude": -61.55,
        "isActive": true
    },
    {
        "id": 133,
        "country": "Argentina",
        "countryCode": "AR",
        "location": "Reconquista",
        "latitude": -29.21,
        "longitude": -59.68,
        "isActive": true
    },
    {
        "id": 117,
        "country": "Argentina",
        "countryCode": "AR",
        "location": "Resistencia",
        "latitude": -27.45,
        "longitude": -59.056,
        "isActive": true
    },
    {
        "id": 114,
        "country": "Argentina",
        "countryCode": "AR",
        "location": "Rio Cuarto",
        "latitude": -33.085,
        "longitude": -64.261,
        "isActive": true
    },
    {
        "id": 63,
        "country": "Brazil",
        "countryCode": "BR",
        "location": "Rio Verde",
        "latitude": -17.8,
        "longitude": -50.91,
        "isActive": true
    },
    {
        "id": 155,
        "country": "Argentina",
        "countryCode": "AR",
        "location": "Rosario",
        "latitude": -32.904,
        "longitude": -60.785,
        "isActive": true
    },
    {
        "id": 132,
        "country": "Argentina",
        "countryCode": "AR",
        "location": "Saenz Pena",
        "latitude": -26.733,
        "longitude": -60.483,
        "isActive": true
    },
    {
        "id": 135,
        "country": "Argentina",
        "countryCode": "AR",
        "location": "San Luis",
        "latitude": -33.273,
        "longitude": -66.356,
        "isActive": true
    },
    {
        "id": 101,
        "country": "Argentina",
        "countryCode": "AR",
        "location": "San Pedro",
        "latitude": -33.775,
        "longitude": -59.752,
        "isActive": true
    },
    {
        "id": 49,
        "country": "Brazil",
        "countryCode": "BR",
        "location": "Santa Carmen",
        "latitude": -12.04,
        "longitude": -54.96,
        "isActive": true
    },
    {
        "id": 116,
        "country": "Argentina",
        "countryCode": "AR",
        "location": "Santa Rosa",
        "latitude": -36.588,
        "longitude": -64.276,
        "isActive": true
    },
    {
        "id": 95,
        "country": "Argentina",
        "countryCode": "AR",
        "location": "Santiago del Estero",
        "latitude": -27.766,
        "longitude": -64.31,
        "isActive": true
    },
    {
        "id": 76,
        "country": "Brazil",
        "countryCode": "BR",
        "location": "Sao Jose do Rio Claro",
        "latitude": -13.43,
        "longitude": -56.71,
        "isActive": true
    },
    {
        "id": 84,
        "country": "Brazil",
        "countryCode": "BR",
        "location": "Sao Luiz Gonzaga",
        "latitude": -28.4,
        "longitude": -55.01,
        "isActive": true
    },
    {
        "id": 83,
        "country": "Brazil",
        "countryCode": "BR",
        "location": "Sao Pedro do Iguazu",
        "latitude": -24.96,
        "longitude": -53.71,
        "isActive": true
    },
    {
        "id": 68,
        "country": "Brazil",
        "countryCode": "BR",
        "location": "Sao Pedro do Piaui",
        "latitude": -5.93,
        "longitude": -42.72,
        "isActive": true
    },
    {
        "id": 56,
        "country": "Brazil",
        "countryCode": "BR",
        "location": "Sao Vicente",
        "latitude": -15.81,
        "longitude": -55.41,
        "isActive": true
    },
    {
        "id": 58,
        "country": "Brazil",
        "countryCode": "BR",
        "location": "Sapezal",
        "latitude": -13.46,
        "longitude": -58.37,
        "isActive": true
    },
    {
        "id": 128,
        "country": "Argentina",
        "countryCode": "AR",
        "location": "Sauce Viejo",
        "latitude": -31.712,
        "longitude": -60.812,
        "isActive": true
    },
    {
        "id": 69,
        "country": "Brazil",
        "countryCode": "BR",
        "location": "Sinop",
        "latitude": -11.74,
        "longitude": -55.09,
        "isActive": true
    },
    {
        "id": 80,
        "country": "Brazil",
        "countryCode": "BR",
        "location": "Taguatinga",
        "latitude": -12.4,
        "longitude": -46.41,
        "isActive": true
    },
    {
        "id": 108,
        "country": "Argentina",
        "countryCode": "AR",
        "location": "Tandil",
        "latitude": -37.237,
        "longitude": -59.228,
        "isActive": true
    },
    {
        "id": 112,
        "country": "Argentina",
        "countryCode": "AR",
        "location": "Tartagal",
        "latitude": -22.62,
        "longitude": -63.794,
        "isActive": true
    },
    {
        "id": 51,
        "country": "Brazil",
        "countryCode": "BR",
        "location": "Taruma",
        "latitude": -22.79,
        "longitude": -50.62,
        "isActive": true
    },
    {
        "id": 104,
        "country": "Argentina",
        "countryCode": "AR",
        "location": "Tres Arroyos",
        "latitude": -38.387,
        "longitude": -60.33,
        "isActive": true
    },
    {
        "id": 143,
        "country": "Argentina",
        "countryCode": "AR",
        "location": "Tucuman",
        "latitude": -26.841,
        "longitude": -65.105,
        "isActive": true
    },
    {
        "id": 91,
        "country": "Brazil",
        "countryCode": "BR",
        "location": "Uberaba",
        "latitude": -19.73,
        "longitude": -47.95,
        "isActive": true
    },
    {
        "id": 131,
        "country": "Argentina",
        "countryCode": "AR",
        "location": "Villa Dolores",
        "latitude": -31.945,
        "longitude": -65.146,
        "isActive": true
    },
    {
        "id": 139,
        "country": "Argentina",
        "countryCode": "AR",
        "location": "Villa Maria del Rio",
        "latitude": -29.9,
        "longitude": -63.683,
        "isActive": true
    },
    {
        "id": 109,
        "country": "Argentina",
        "countryCode": "AR",
        "location": "Villa Mercedes",
        "latitude": -33.72,
        "longitude": -65.48,
        "isActive": true
    },
    {
        "id": 102,
        "country": "Argentina",
        "countryCode": "AR",
        "location": "Villa Reynolds",
        "latitude": -33.73,
        "longitude": -65.387,
        "isActive": true
    },
    {
        "id": 50,
        "country": "Brazil",
        "countryCode": "BR",
        "location": "Votuporanga",
        "latitude": -20.41,
        "longitude": -49.98,
        "isActive": true
    },
    {
        "id": 99,
        "country": "Argentina",
        "countryCode": "AR",
        "location": "Zavalla",
        "latitude": -33.02,
        "longitude": -60.88,
        "isActive": true
    }
];