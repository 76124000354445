export const countryOptions = [
    {
        countryName: "Australia",
        countryCode: "AU"
    },
    {
        countryName: "Austria",
        countryCode: "AT"
    },
    {
        countryName: "Belarus",
        countryCode: "BY"
    },
    {
        countryName: "Belgium",
        countryCode: "BE"
    },
    {
        countryName: "Botswana",
        countryCode: "BW"
    },
    {
        countryName: "Canada",
        countryCode: "CA"
    },
    {
        countryName: "Germany",
        countryCode: "DE"
    },
    {
        countryName: "Lesotho",
        countryCode: "LS"
    },
    {
        countryName: "Morocco",
        countryCode: "MA"
    },
    {
        countryName: "Mozambique",
        countryCode: "MZ"
    },
    {
        countryName: "Namibia",
        countryCode: "NA"
    },
    {
        countryName: "Netherlands",
        countryCode: "NL"
    },
    {
        countryName: "New Zealand",
        countryCode: "NZ"
    },
    {
        countryName: "Philippines",
        countryCode: "PH"
    },
    {
        countryName: "Pakistan",
        countryCode: "PK"
    },
    {
        countryName: "Russia",
        countryCode: "RU"
    },
    {
        countryName: "South Africa",
        countryCode: "ZA"
    },
    {
        countryName: "Spain",
        countryCode: "ES"
    },
    {
        countryName: "Swaziland",
        countryCode: "SZ"
    },
    {
        countryName: "Switzerland",
        countryCode: "CH"
    },
    {
        countryName: "United Kingdom",
        countryCode: "UK"
    },
    {
        countryName: "USA",
        countryCode: "US"
    },
    {
        countryName: "Zimbabwe",
        countryCode: "ZW"
    }
];

export const countryCenterAndZoomScape = [
    {
        CountryCode: "CH",
        center: {
            lat: 47.0,
            lng: 7.2
        },
        zoom: 7,
    },
    {
        CountryCode: "CA",
        center: {
            lat: 51.8,
            lng: -90.5
        },
        zoom: 4,
    },
    {
        CountryCode: "ES",
        center: {
            lat: 40.0,
            lng: -4.23
        },
        zoom: 6,
    },
    {
        CountryCode: "AT",
        center: {
            lat: 47.75,
            lng: 13.3
        },
        zoom: 6,
    },
    {
        CountryCode: "DE",
        center: {
            lat: 50.67,
            lng: 10.25
        },
        zoom: 6,
    },
    {
        CountryCode: "UK",
        center: {
            lat: 54.28,
            lng: -2.19
        },
        zoom: 5,
    },
    {
        CountryCode: "US",
        center: {
            lat: 39.87,
            lng: -91.97
        },
        zoom: 4,
    },
    {
        CountryCode: "AU",
        center: {
            lat: -30.5,
            lng: 133.5
        },
        zoom: 4,
    },
    {
        CountryCode: "MA",
        center: {
            lat: 33.8371085,
            lng: -6.914022
        },
        zoom: 8,
    },
    {
        CountryCode: "NZ",
        center: {
            lat: -39.6,
            lng: 176.55
        },
        zoom: 8,
    },
    {
        CountryCode: "RU",
        center: {
            // lat: 62,
            lat: 55,
            // lng: 109
            lng: 99
        },
        zoom: 3,
    },
    {
        CountryCode: "BY",
        center: {
            // lat: 62,
            lat: 53.705,
            // lng: 109
            lng: 27.37387
        },
        zoom: 7,
    },
    {
        CountryCode: "SZ",
        center: {
            lat: -26.53,
            lng: 31.3
        },
        zoom: 8,
    },
    {
        CountryCode: "LS",
        center: {
            lat: -29.45,
            lng: 27.77
        },
        zoom: 8,
    },
    {
        CountryCode: "BW",
        center: {
            lat: -23.26,
            lng: 23.93
        },
        zoom: 6,
    },
    {
        CountryCode: "MZ",
        center: {
            lat: -21.9,
            lng: 33.75
        },
        zoom: 5,
    },
    {
        CountryCode: "NA",
        center: {
            lat: -25.59,
            lng: 18
        },
        zoom: 5,
    },
    {
        CountryCode: "ZA",
        center: {
            lat: -30.24,
            lng: 24.8
        },
        zoom: 5,
    },
    {
        CountryCode: "ZW",
        center: {
            lat: -20.02,
            lng: 29.82
        },
        zoom: 6,
    },
    {
        CountryCode: "PH",
        center: {
            lat: 11.65,
            lng: 122
        },
        zoom: 5,
    },
    {
        CountryCode: "PK",
        center: {
            lat: 26.5,
            lng: 68
        },
        zoom: 6,
    },
    {
        CountryCode: "BE",
        center: {
            lat: 50.70598975,
            lng: 4.1879027
        },
        zoom: 8,
    },
    {
        CountryCode: "NL",
        center: {
            lat: 52.311321 ,
            lng: 5.917274
        },
        zoom: 7,
    },
];

export const initCropOptions = [
    {
        cropName: "Apples",
        cropValue: "Apples"
    },
    {
        cropName: "Canola/OSR",
        cropValue: "Canola"
    },
    {
        cropName: "Cereal",
        cropValue: "Cereal"
    },
    {
        cropName: "Corn",
        cropValue: "Corn"
    },
    {
        cropName: "Grapes",
        cropValue: "Grapes"
    },
    {
        cropName: "Potato_V2",
        cropValue: "Potato"
    }
];


