import { Axios, AxiosResponse } from "axios";
import { http, ServerType } from "../http";

import {
    IFieldsModel,
    IGetGeoNamesLocationDataReqModel,
    IGetPlaceNameResModel,
    IGetWeatherByDaysResModel,
    INozzleColorResModel,
    IReportsModel,
    ISettingModel,
    IUserDataModel,
    IGetUserForLoginModel,
    IUploadLogsResModel
} from "./responseType";
import {
    IAddFieldToBookMarkReqModel,
    IGetPlaceNameReqModel,
    IGetWeatherByDaysReqModel,
    INozzleColorReqModel,
    ISettingReqModel,
    IUpdateUserRoleReqModel,
    ISignInReqModel,
    IResetPasswordReqModel,
    ISignUpReqModel,
    IAddFieldReqModel,
    IUpdateFieldReqModel,
    IDeleteFieldReqModel
} from "./requestType";

//============= for report page =================
const getReportDatas = async (email?: string) => {
    const url = "/api/QuantisHome/GetReports";
    const res = await http.post<{email?:string},AxiosResponse<IReportsModel[]>>(url,
        {
            email: email
        },
        ServerType.ServerSimulation,
        false,
        null,
        true
    );
    return res;
};
const deleteReport = async (reportID: number) => {
    const url = "/api/QuantisHome/DeleteReport";
    const res = await http.post<{reportID:number},AxiosResponse<string>>(url,
        {
            reportID: reportID
        },
        ServerType.ServerSimulation,
        false,
        null,
        true
    );
    return res;
};
//============= for report page =================

//============= for report edit and add page ===================
const checkIsEnableReportsByCrops = async (crop:string) => {
    const url = "/api/QuantisHome/CheckIsEnableReportsByCrop";
    const res = await http.post<{crop: string},AxiosResponse<boolean>>(url,
        {
            crop: crop
        },
        ServerType.ServerSimulation,
        false,
        null,
        true
    );
    return res;
};
const addReportData = async (reportData:IReportsModel) => {
    const url = "/api/QuantisHome/AddReport";
    const res = await http.post<IReportsModel,AxiosResponse<string>>(url,
        reportData,
        ServerType.ServerSimulation,
        false,
        null,
        true
    );
    return res;
};
const editReportData = async (reportData:IReportsModel) => {
    const url = "/api/QuantisHome/UpdateReport";
    const res = await http.post<IReportsModel,AxiosResponse<boolean>>(url,
        reportData,
        ServerType.ServerSimulation,
        false,
        null,
        true
    );
    return res;
};
// TODO: upload images funciton, 需要特殊处理，要看老代码,后端代码也是有问题的
const uploadImage = async (formData?:any) => {
    const url = "/api/QuantisHome/UploadReportLogo";
    const res = await http.post<any,AxiosResponse<IUploadLogsResModel>>(
        url,
        formData,
        ServerType.ServerSimulation,
        false,
        null,
        true, // quantis
        true // upload
    );
    return res;
};
//============= for report edit and add page ===================

//============= for main page ===================
const getFields = async () => {
    const url = "/api/QuantisHome/GetFields";
    const res = await http.post<null,AxiosResponse<IFieldsModel[]>>(url,
        null,
        ServerType.ServerSimulation,
        false,
        null,
        true
    );
    return res;
};
const getAllCrop = async () => {
    const url = "/api/QuantisHome/GetAllCrops";
    const res = await http.post<null,AxiosResponse<string[]>>(url,
        null,
        ServerType.ServerSimulation,
        false,
        null,
        true
    );
    return res;
};
const getSetting = async (crop:string) => {
    const url = "/api/QuantisHome/GetSetting";
    const res = await http.post<{crop:string},AxiosResponse<ISettingModel>>(url,
        {
            crop:crop
        },
        ServerType.ServerSimulation,
        false,
        null,
        true
    );
    return res;
};
// used for get weather
const getWeatherByDays = async (data:IGetWeatherByDaysReqModel) => {
    const url = "/api/Quantis/GetWeatherByDays";
    const res = await http.post<IGetWeatherByDaysReqModel,AxiosResponse<IGetWeatherByDaysResModel>>(
        url,
        data,
        ServerType.ServerPublicService,
        false,
        null,
        true, // quantis
        false, // upload
    );
    return res;
};
const getNozzleColor = async (data:INozzleColorReqModel) => {
    const url = "/api/Quantis/GetNozzleColor";
    const res = await http.post<INozzleColorReqModel,AxiosResponse<INozzleColorResModel[]>>(
        url,
        data,
        ServerType.ServerPublicService,
        false,
        null,
        true, // quantis
        false, // upload
    );
    return res;
};
const getPlaceNameByLatAndLong = async (data:IGetPlaceNameReqModel) => {
    const url = "/api/QuantisHome/GetPlaceNameAndCountryByLatAndLong";
    const res = await http.post<IGetPlaceNameReqModel,AxiosResponse<IGetPlaceNameResModel>>(
        url,
        data,
        ServerType.ServerSimulation,
        false,
        null,
        true, // quantis
        false, // upload
    );
    return res;
};
const getGeonamesLocationData = async (data: string) => {
    const url = "/api/QuantisHome/GetGeonamesLocationData";
    const res = await http.post<{strSearchText:string},AxiosResponse<IGetGeoNamesLocationDataReqModel[]>>(
        url,
        {
            strSearchText: data
        },
        ServerType.ServerSimulation,
        false,
        null,
        true, // quantis
        false, // upload
    );
    return res;
};
const disableReportByCrop = async (crop: string) => {
    const url = "/api/QuantisHome/DisableReportsByCrop";
    const res = await http.post<{crop:string},AxiosResponse<string>>(
        url,
        {
            crop: crop
        },
        ServerType.ServerSimulation,
        false,
        null,
        true, // quantis
        false, // upload
    );
    return res;
};
const activeReportByCrop = async (crop: string) => {
    const url = "/api/QuantisHome/ActiveReportsByCrop";
    const res = await http.post<{crop:string},AxiosResponse<string>>(
        url,
        {
            crop: crop
        },
        ServerType.ServerSimulation,
        false,
        null,
        true, // quantis
        false, // upload
    );
    return res;
};
const updateSetting = async (setting: ISettingReqModel) => {
    const url = "/api/QuantisHome/UpdateSetting";
    const res = await http.post<ISettingReqModel,AxiosResponse<string>>(
        url,
        setting,
        ServerType.ServerSimulation,
        false,
        null,
        true, // quantis
        false, // upload
    );
    return res;
};
const addNewSetting = async (setting: ISettingReqModel) => {
    const url = "/api/QuantisHome/AddSetting";
    const res = await http.post<ISettingReqModel,AxiosResponse<string>>(
        url,
        setting,
        ServerType.ServerSimulation,
        false,
        null,
        true, // quantis
        false, // upload
    );
    return res;
};
const addFieldToBookMark = async (data: IAddFieldToBookMarkReqModel) => {
    const url = "/api/QuantisHome/AddFieldToBookMark";
    const res = await http.post<IAddFieldToBookMarkReqModel,AxiosResponse<string>>(
        url,
        data,
        ServerType.ServerSimulation,
        false,
        null,
        true, // quantis
        false, // upload
    );
    return res;
};
const addField = async (data: IAddFieldReqModel) => {
    const url = "/api/QuantisHome/AddField";
    const res = await http.post<IAddFieldReqModel,AxiosResponse<string>>(
        url,
        data,
        ServerType.ServerSimulation,
        false,
        null,
        true, // quantis
        false, // upload
    );
    return res;
};
const updateCurrentSelectedField = async (data: IUpdateFieldReqModel) => {
    const url = "/api/QuantisHome/UpdateField";
    const res = await http.post<IUpdateFieldReqModel,AxiosResponse<string>>(
        url,
        data,
        ServerType.ServerSimulation,
        false,
        null,
        true, // quantis
        false, // upload
    );
    return res;
};
const deleteCurrentSelectedField = async (data: IDeleteFieldReqModel) => {
    const url = "/api/QuantisHome/DeleteField";
    const res = await http.post<IDeleteFieldReqModel,AxiosResponse<string>>(
        url,
        data,
        ServerType.ServerSimulation,
        false,
        null,
        true, // quantis
        false, // upload
    );
    return res;
};
const getReportImgUrlFromS3 = async (imageName: string) => {
    const url = "/api/QuantisHome/GetReportImgs";
    const res = await http.post<{imgName:string},AxiosResponse<string>>(
        url,
        {
            imgName: imageName
        },
        ServerType.ServerSimulation,
        false,
        null,
        true, // quantis
        false, // upload
    );
    return res;
};
//============= for main page ===================

//============= for Article page ===================
// TODO: get article funciton missed
const getAriticle = async () => {
    const url = "/api/QuantisHome/GetFields";
    const res = await http.post<null,AxiosResponse<IFieldsModel[]>>(url,
        null,
        ServerType.ServerSimulation,
        false,
        null,
        true
    );
    return res;
};
//============= for main page ===================
//start for usermanagement funciton
const getUserListByEmail = async (email: string) => {
    const url = "/api/QuantisHome/GetUserListByEmail";
    const res = await http.post<{email:string},AxiosResponse<IUserDataModel[]>>(
        url,
        {
            email: email
        },
        ServerType.ServerSimulation,
        false,
        null,
        true, // quantis
        false, // upload
    );
    return res;
};
const getUpdateUserRole = async (data:IUpdateUserRoleReqModel) => {
    const url = "/api/QuantisHome/UpdateUserRole";
    const res = await http.post<IUpdateUserRoleReqModel,AxiosResponse<string>>(
        url,
        data,
        ServerType.ServerSimulation,
        false,
        null,
        true // quantis
    );
    return res;
};
//end for usermanagement funciton
//start for login funciton
const getSignUp = async (data:ISignUpReqModel) => {
    const url = "/api/User/SignUp";
    const res = await http.post<ISignUpReqModel,AxiosResponse<string>>(
        url,
        data,
        ServerType.ServerSimulation,
        false,
        null,
        true // quantis
    );
    return res;
};
const getSignIn = async (data:ISignInReqModel) => {
    const url = "/api/User/SignIn";
    const res = await http.post<ISignInReqModel,AxiosResponse<IGetUserForLoginModel>>(
        url,
        data,
        ServerType.ServerSimulation,
        false,
        null,
        false
    );
    return res;
};
const getSendPasswordResetEmail = async (email?: string) => {
    const url = "/api/User/SendPasswordResetEmail";
    const res = await http.post<{email?:string},AxiosResponse<string>>(
        url,
        {
            email: email
        },
        ServerType.ServerSimulation,
        false,
        null,
        true // quantis
    );
    return res;
};
const getResetPassword = async (data:IResetPasswordReqModel) => {
    const url = "/api/User/ResetPassword";
    const res = await http.post<IResetPasswordReqModel,AxiosResponse<string>>(
        url,
        data,
        ServerType.ServerSimulation,
        false,
        null,
        true // quantis
    );
    return res;
};
const getCurrentUserRole = async () => {
    const url = "/api/User/GetCurrentUserRole";
    const res = await http.post<null,AxiosResponse<string>>(
        url,
        null,
        ServerType.ServerSimulation,
        false,
        null,
        true // quantis
    );
    return res;
};
//end for login funciton
export {
    getReportDatas,
    deleteReport,
    getFields,
    checkIsEnableReportsByCrops,
    addReportData,
    editReportData,
    uploadImage,
    getAriticle,
    getAllCrop,
    getSetting,
    getWeatherByDays,
    getNozzleColor,
    getPlaceNameByLatAndLong,
    getGeonamesLocationData,
    getUserListByEmail,
    disableReportByCrop,
    activeReportByCrop,
    updateSetting,
    addNewSetting,
    addFieldToBookMark,
    getUpdateUserRole,
    getSignUp,
    getSignIn,
    getSendPasswordResetEmail,
    getResetPassword,
    getCurrentUserRole,
    addField,
    updateCurrentSelectedField,
    deleteCurrentSelectedField,
    getReportImgUrlFromS3
};

