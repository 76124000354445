import React from "react";


interface IProps{
    text?: string;
    className?: string;
}

const Unit = (prop:IProps)=>{
    const {text,className} = prop;
    return(
        <div className={className}>{text || "m"}</div>
    );
};
export default Unit;