import React, { useEffect, useState } from "react";
import type { Locale } from "antd/es/locale";
import enUS from "antd/locale/en_US";
import AE from "antd/locale/ar_EG";
import AU from "antd/locale/en_GB";
import DE from "antd/locale/de_DE";
import DK from "antd/locale/da_DK";
import FI from "antd/locale/fi_FI";
import FR from "antd/locale/fr_FR";
import GB from "antd/locale/en_GB";
import IS from "antd/locale/is_IS";
import JP from "antd/locale/ja_JP";
import KR from "antd/locale/ko_KR";
import NO from "antd/locale/nb_NO";
import NZ from "antd/locale/en_GB";
import SE from "antd/locale/sv_SE";
import ZA from "antd/locale/en_GB";

import dayjs from "dayjs";

import "dayjs/locale/ar"; // AE: 阿拉伯联合酋长国
import "dayjs/locale/en-au"; // AU: 澳大利亚
import "dayjs/locale/be"; // BY: 白俄罗斯
import "dayjs/locale/de"; // DE: 德国
import "dayjs/locale/da"; // DK: 丹麦
import "dayjs/locale/fi"; // FI: 芬兰
import "dayjs/locale/fr"; // FR: 法国
import "dayjs/locale/es"; // ES: 西班牙
import "dayjs/locale/en-gb"; // GB: 英国
import "dayjs/locale/is"; // IS: 冰岛
import "dayjs/locale/ja"; // JP: 日本
import "dayjs/locale/ko"; // KR: 韩国
import "dayjs/locale/nb"; // NO: 挪威
import "dayjs/locale/en-il"; // IL: 以色列
import "dayjs/locale/en-nz"; // NZ: 新西兰
import "dayjs/locale/en-gb"; // ZA: 南非
import "dayjs/locale/sv"; // SE: 瑞典
import "dayjs/locale/pl"; // PL: 波兰语
import "dayjs/locale/pt"; // PT: 葡萄牙语
import "dayjs/locale/si"; // SI: 斯洛文尼亚语
import "dayjs/locale/tr"; // TR: 土耳其语
import "dayjs/locale/uk"; // UK
import "dayjs/locale/es-us"; // US
import { ConfigProvider, DatePicker, Radio, RadioChangeEvent } from "antd";
const { RangePicker } = DatePicker;
import "./index.less";

interface IProps{
  countrycode: string;
  className?: string;
  startDate?: dayjs.Dayjs;
  endDate?: dayjs.Dayjs;
  childChangeStartDateFunc?: (value)=>void
  childChangeEndDateFunc?: (value)=>void
}

const CountryLanguage = {
    "AE": {
        antdTrans: AE,
        dayjsLanguage: "ar"
    },
    "AU": {
        antdTrans: AU,
        dayjsLanguage: "en-au"
    },
    "DE": {
        antdTrans: DE,
        dayjsLanguage: "de"
    },
    "DK": {
        antdTrans: DK,
        dayjsLanguage: "da"
    },
    "FI": {
        antdTrans: FI,
        dayjsLanguage: "fi"
    },
    "FR": {
        antdTrans: FR,
        dayjsLanguage: "fr"
    },
    "GB": {
        antdTrans: GB,
        dayjsLanguage: "en-gb"
    },
    "IS": {
        antdTrans: IS,
        dayjsLanguage: "is"
    },
    "JP": {
        antdTrans: JP,
        dayjsLanguage: "ja"
    },
    "KR": {
        antdTrans: KR,
        dayjsLanguage: "ko"
    },
    "NO": {
        antdTrans: NO,
        dayjsLanguage: "nb"
    },
    "NZ": {
        antdTrans: NZ,
        dayjsLanguage: "en-nz"
    },
    "SE": {
        antdTrans: SE,
        dayjsLanguage: "sv"
    },
    "ZA": {
        antdTrans: ZA,
        dayjsLanguage: "en-gb"
    }
};

const CountryDateFormat = {
    "AE": "DD/MM/YYYY",
    "AU": "DD/MM/YYYY",
    "BY": "DD.MM.Y",
    "DE": "DD.MM.YYYY",
    "ES": "DD/MM/YYYY",
    "FR": "DD/MM/YYYY",
    "GB": "DD/MM/YYYY",
    "IL": "DD/MM/YYYY",
    "JP": "MM/DD/YYYY",
    "KR": "YYYY-MM-DD",
    "PL": "DD.MM.YYYY",
    "PT": "DD-MM-YYYY",
    "SE": "YYYY-MM-DD",
    "SI": "DD.MM.YYYY"
};

const DateChange = (prop:IProps) => {
    const [locale, setLocal] = useState<Locale>(enUS);
    const {countrycode,
        className,
        startDate,
        endDate,
        childChangeEndDateFunc,
        childChangeStartDateFunc
    } = prop;
    useEffect(()=>{
        console.log("datechange",countrycode);
        console.log("datechange a",CountryLanguage[countrycode]);
        if(CountryLanguage[countrycode]){
            setLocal(CountryLanguage[countrycode]["antdTrans"]);
            dayjs.locale(CountryLanguage[countrycode]["dayjsLanguage"]);
        }
        else{
            setLocal(enUS);
            dayjs.locale("en");
        }
    },[countrycode]);

    const disableedDateFunc = (current:dayjs.Dayjs)=>{
        const today = dayjs();
        const yesterday = dayjs().subtract(1,"day");
        // return current && (current >= today || current <= yesterday);
        return current && (current > yesterday);
    };

    return (
        <div>
            <div id="hist-disease-chart-rangepicker-id" className="hist-disease-chart-rangepicker-id">
                <ConfigProvider locale={locale}>
                    <RangePicker
                        format={CountryDateFormat[countrycode.toUpperCase()] || "DD/MM/YYYY"}
                        className={className}
                        value={[startDate,endDate]}
                        allowClear = {false}
                        onChange={(e)=>{
                            console.log("change date value",e);
                            childChangeStartDateFunc(e[0]);
                            childChangeEndDateFunc(e[1]);
                        }}
                        disabledDate={disableedDateFunc}
                        mode={["date","date"]}
                        // getPopupContainer={() => document.getElementById("root")}
                        getPopupContainer={() => document.getElementById("hist-disease-chart-rangepicker-id")}
                    />
                </ConfigProvider>
            </div>
        </div>
    );
};

export default React.memo(DateChange);
