import {
    fork,
    put,
    take,
    takeLatest,
    delay
} from "redux-saga/effects";
import {
    sagaActionTypes,
    updateReportsData,
    updateShowErrorMessage,
    updateShowSuccessMessage,
    updateMainPageFirstGetFieldFlag,
    updateAllFields,
    updateGetAllFieldsStatus,
    updateAllBookMarkFields,
    updateAllCrop,
    updateCropFromFields,
    updateReduxSetting,
    updateCurrnetGetWeatherData,
    updatePlaceName,
    updateGeoLocationListData,
    updateUserList,
    updateGetWeatherAfterAddOrUpdateSetting,
    updateAddNewFieldStatus,
    updateUpdateFieldStatus,
    updateDeleteFieldStatus
} from "../actions/quantisAction";
import {
    getReportDatas,
    deleteReport,
    getFields,
    addReportData,
    editReportData,
    uploadImage,
    getAriticle,
    getAllCrop,
    getSetting,
    getWeatherByDays,
    getNozzleColor,
    getPlaceNameByLatAndLong,
    getGeonamesLocationData,
    getUserListByEmail,
    updateSetting,
    addNewSetting,
    addField,
    updateCurrentSelectedField,
    deleteCurrentSelectedField
} from "@/util/http/Quantis";
import { IFieldsModel } from "@/util/http/Quantis/responseType";
import {converyDateFormat, getDayOfWeek} from "@/util/quantisCommonFunction";
import { IGetWeatherByDaysReqModel } from "@/util/http/Quantis/requestType";



function *fetchReport(action){
    try{
        console.log("fff action ",action.payload);
        const {email} = action.payload;
        const res = yield getReportDatas(email);
        yield put(updateReportsData(res.data));
    }
    catch(error){
        console.log("current countrycdoe have error",error);
    }
}
function *deleteReports(action){
    try{
        console.log("fff action ",action.payload);
        const {reportID} = action.payload;
        const res = yield deleteReport(reportID); // when delete success, will return string: Success
        // when delete success, should get new reports and show success message
        if(res.data){
            /**
             * get new reports and add to redux
             */
            const reportRes = yield getReportDatas(null);
            yield put(updateReportsData(reportRes.data));
            yield put(updateShowSuccessMessage(res.data));
        }
    }
    catch(error){
        console.log("current countrycdoe have error",error);
    }
}

function *getFieldsFunc(action){
    try{
        console.log("fff action ",action.payload);
        const res = yield getFields();
        const tmpAllCrop:string[] = [];
        const tmpIsBookMarkFields:IFieldsModel[] = [];
        res.data?.forEach((item)=>{
            if(tmpAllCrop?.filter( o => o === item?.crop)?.length === 0){
                tmpAllCrop.push(item?.crop);
            }
            if(tmpIsBookMarkFields?.filter(
                o => item?.fieldName === o.fieldName
            )?.length === 0 && item?.isBookMark === "Y"){
                tmpIsBookMarkFields.push(item);
            }
        });
        // set value
        yield put(updateAllFields(res.data));
        yield put(updateCropFromFields(tmpAllCrop));
        yield put(updateAllBookMarkFields(tmpIsBookMarkFields));
        yield put(updateGetAllFieldsStatus(true));
        if(action.payload) yield put(updateMainPageFirstGetFieldFlag(true));
    }
    catch(error){
        console.log("current countrycdoe have error",error);
    }
}

function *addReportFunc(action){
    try{
        const res = yield addReportData(action.payload);
        if(res?.data && res.data === "Success"){
            yield put(updateShowSuccessMessage(res.data));
        }
    }
    catch(error){
        console.log("current countrycdoe have error",error);
    }
}
function *editReportFunc(action){
    try{
        const res = yield editReportData(action.payload);
        if(res?.data && res.data === "Success"){
            yield put(updateShowSuccessMessage(res.data));
        }
    }
    catch(error){
        console.log("current countrycdoe have error",error);
    }
}
function *uploadImageFunc(action){
    try{
        console.log("upload sage action.payload",action.payload);
        const res = yield uploadImage(action.payload);
        // TODO: success invoke logic is missed

    }
    catch(error){
        console.log("current countrycdoe have error",error);
    }
}

function *getArticleFunc(action){
    try{
        console.log("upload sage action.payload",action.payload);
        const res = yield uploadImage(action.payload);

    }
    catch(error){
        console.log("current countrycdoe have error",error);
    }
}
function *getAllCropFunc(action){
    try{
        const res = yield getAllCrop();
        yield put(updateAllCrop(res.data));
    }
    catch(error){
        console.log("current countrycdoe have error",error);
    }
}
function *getCurrentCropSetting(action){
    try{
        const res = yield getSetting(action.payload);
        yield put(updateReduxSetting(res.data));
    }
    catch(error){
        console.log("current countrycdoe have error",error);
    }
}

function *getWeatherDataFunc(action){
    try{
        /**
         * action.payload have five propertis
         * crop -> string
         * latitude  ->  provider string
         * longitude ->  provider string
         * showDays  ->  number
         * startDate -> provider string, just like 2024-05-20
         * endDate   -> provider string, just like 2024-05-24
         */
        const {crop, latitude, longitude,showDays,startDate,endDate} = action.payload;
        // get weatherByDays
        const weatherRes = yield getWeatherByDays({
            lat: Number(latitude),
            lon: Number(longitude),
            showDays: showDays
        });

        // get NozzleColor
        const colorRes = yield getNozzleColor({
            crop: crop || "Potato", // when crop is empty, use Potato
            latitude: latitude,
            longitude: longitude,
            startDate: startDate,
            endDate: endDate
        });
        // process data
        const { time, windspeed_mean, temperature_max, temperature_min, predictability_class, windDirection, pictoCode,windDirectionLetter } = weatherRes.data.data_day;
        const tmpWeekName = time?.map( item => getDayOfWeek(item)); // like [Mondary, Tuesday]
        const tmpNozzleColorFormatDate = colorRes.data?.map((item=>converyDateFormat(item?.date,"DD.MM")));

        // get placename by lat and long
        const placeRes = yield getPlaceNameByLatAndLong({
            latitude: latitude,
            longitude: longitude
        });

        yield put(updateCurrnetGetWeatherData({
            time: time,
            windspeed_mean: windspeed_mean,
            temperature_max: temperature_max,
            temperature_min: temperature_min,
            predictability_class: predictability_class,
            windDirection: windDirection,
            windDirectionLetter: windDirectionLetter,
            nozzleColorData: colorRes.data,
            weekName: tmpWeekName,
            nozzleColorFormatDate: tmpNozzleColorFormatDate,
            pictoCode: pictoCode
        }));
        yield put(updatePlaceName(placeRes.data.name));

    }
    catch(error){
        console.log("current countrycdoe have error",error);
    }
}

function *getPlaceNameByLatAndLongFunc(action){
    try{
        const {latitude,longitude} = action.payload;
        const res = yield getPlaceNameByLatAndLong({
            latitude: latitude,
            longitude: longitude
        });
        yield put(updatePlaceName(res.data.name));
    }
    catch(error){
        console.log("current countrycdoe have error",error);
    }
}

function *getGeoNamesLocationDataFunc(action){
    try{
        const res = yield getGeonamesLocationData(action.payload);
        yield put(updateGeoLocationListData(res.data));
    }
    catch(error){
        console.log("current countrycdoe have error",error);
    }
}

function *getUserListByEmailFunc(action){
    try{
        const {email} = action.payload;
        const res = yield getUserListByEmail(email);
        yield put(updateUserList(res.data));
    }
    catch(error){
        console.log("getUserListByEmailFunc error:",error);
    }
}
function *udpateSettingFunc(action){
    try{
        const {crop} = action.payload;
        // update current setting
        const res = yield updateSetting(action.payload);
        if(res.data === "Success"){
            // when update success, show success message
            yield put(updateShowSuccessMessage(res.data));
            // then get the updated setting from api and store to redux
            const settingRes = yield getSetting(crop);
            yield put(updateReduxSetting(settingRes.data));
            // set the flag to get new weather
            yield put(updateGetWeatherAfterAddOrUpdateSetting(true));
        }
    }
    catch(error){
        console.log("getUserListByEmailFunc",error);
    }
}
function *addNewSettingFunc(action){
    try{
        const {crop} = action.payload;
        // add a new setting
        const res = yield addNewSetting(action.payload);
        if(res.data === "Success"){
            // when add new setting success, show success message
            yield put(updateShowSuccessMessage(res.data));
            // then get the updated setting from api and store to redux
            const settingRes = yield getSetting(crop);
            yield put(updateReduxSetting(settingRes.data));
            // final , should get new allCrop and store to redux
            const allCropRes = yield getAllCrop();
            yield put(updateAllCrop(allCropRes.data));
            // set the flag to get new weather
            yield put(updateGetWeatherAfterAddOrUpdateSetting(true));
        }
    }
    catch(error){
        console.log("getUserListByEmailFunc",error);
    }
}
function *addNewFieldFunc(action){
    try{
        const {crop} = action.payload;
        // add a new setting
        const addRes = yield addField(action.payload);
        if(addRes.data === "Success"){
            // get new allFields and
            const res = yield getFields();
            const tmpAllCrop:string[] = [];
            const tmpIsBookMarkFields:IFieldsModel[] = [];
            res.data?.forEach((item)=>{
                if(tmpAllCrop?.filter( o => o === item?.crop)?.length === 0){
                    tmpAllCrop.push(item?.crop);
                }
                if(tmpIsBookMarkFields?.filter(
                    o => item?.fieldName === o.fieldName
                )?.length === 0 && item?.isBookMark === "Y"){
                    tmpIsBookMarkFields.push(item);
                }
            });
            // set value
            yield put(updateAllFields(res.data));
            yield put(updateCropFromFields(tmpAllCrop));
            yield put(updateAllBookMarkFields(tmpIsBookMarkFields));
            // udpate addNewFieldStatus
            yield put(updateAddNewFieldStatus("Success"));
        }
    }
    catch(error){
        yield put(updateAddNewFieldStatus("Faild"));
        console.log("getUserListByEmailFunc",error);
    }
}

function *updateCurrentSelectedFieldFunc(action){
    try{
        const addRes = yield updateCurrentSelectedField(action.payload);
        if(addRes.data === "Success"){
            // get new allFields and
            const res = yield getFields();
            const tmpAllCrop:string[] = [];
            const tmpIsBookMarkFields:IFieldsModel[] = [];
            res.data?.forEach((item)=>{
                if(tmpAllCrop?.filter( o => o === item?.crop)?.length === 0){
                    tmpAllCrop.push(item?.crop);
                }
                if(tmpIsBookMarkFields?.filter(
                    o => item?.fieldName === o.fieldName
                )?.length === 0 && item?.isBookMark === "Y"){
                    tmpIsBookMarkFields.push(item);
                }
            });
            // set value
            yield put(updateAllFields(res.data));
            yield put(updateCropFromFields(tmpAllCrop));
            yield put(updateAllBookMarkFields(tmpIsBookMarkFields));
            // udpate addNewFieldStatus
            yield put(updateUpdateFieldStatus("Success"));
        }
    }
    catch(error){
        yield put(updateUpdateFieldStatus("Faild"));
        console.log("getUserListByEmailFunc",error);
    }
}

function *deleteCurrentSelectedFieldFunc(action){
    try{
        const addRes = yield deleteCurrentSelectedField(action.payload);
        if(addRes.data === "Success"){
            // get new allFields and
            const res = yield getFields();
            const tmpAllCrop:string[] = [];
            const tmpIsBookMarkFields:IFieldsModel[] = [];
            res.data?.forEach((item)=>{
                if(tmpAllCrop?.filter( o => o === item?.crop)?.length === 0){
                    tmpAllCrop.push(item?.crop);
                }
                if(tmpIsBookMarkFields?.filter(
                    o => item?.fieldName === o.fieldName
                )?.length === 0 && item?.isBookMark === "Y"){
                    tmpIsBookMarkFields.push(item);
                }
            });
            // set value
            yield put(updateAllFields(res.data));
            yield put(updateCropFromFields(tmpAllCrop));
            yield put(updateAllBookMarkFields(tmpIsBookMarkFields));
            // udpate addNewFieldStatus
            yield put(updateDeleteFieldStatus("Success"));
        }
    }
    catch(error){
        yield put(updateDeleteFieldStatus("Faild"));
        console.log("getUserListByEmailFunc",error);
    }
}

function *rootSaga() {
    yield takeLatest(sagaActionTypes.SAGA_REPORTS_DATA,fetchReport);
    yield takeLatest(sagaActionTypes.SAGA_DELETE_REPORT,deleteReports);
    yield takeLatest(sagaActionTypes.SAGA_GET_FIELDS,getFieldsFunc);
    yield takeLatest(sagaActionTypes.SAGA_ADD_REPORT,addReportFunc);
    yield takeLatest(sagaActionTypes.SAGA_EDIT_REPORT,editReportFunc);
    yield takeLatest(sagaActionTypes.SAGA_UPLOAD_IMAGE,uploadImageFunc);
    yield takeLatest(sagaActionTypes.SAGA_GET_ARTICLE,getArticleFunc);
    yield takeLatest(sagaActionTypes.SAGA_GET_ALLCROP,getAllCropFunc);
    yield takeLatest(sagaActionTypes.SAGA_GET_SETTING,getCurrentCropSetting);
    yield takeLatest(sagaActionTypes.SAGA_GET_WEATHERDATA,getWeatherDataFunc);
    yield takeLatest(sagaActionTypes.SAGA_GET_GETGEONAMESLOCATIONDATA,getGeoNamesLocationDataFunc);
    yield takeLatest(sagaActionTypes.SAGA_GET_USERLISTBYEMAIL,getUserListByEmailFunc);
    yield takeLatest(sagaActionTypes.SAGA_UPDATE_SETTING,udpateSettingFunc);
    yield takeLatest(sagaActionTypes.SAGA_ADD_NEW_SETTING,addNewSettingFunc);
    yield takeLatest(sagaActionTypes.SAGA_GET_PLACANAMEBYLATANDLONG,getPlaceNameByLatAndLongFunc);
    yield takeLatest(sagaActionTypes.SAGA_ADD_NEWFIELDS,addNewFieldFunc);
    yield takeLatest(sagaActionTypes.SAGA_UPDATE_CURRENTSELECTEDFIELD,updateCurrentSelectedFieldFunc);
    yield takeLatest(sagaActionTypes.SAGA_DELETE_CURRENTSELECTEDFIELD,deleteCurrentSelectedFieldFunc);

}

export default rootSaga;