import React from "react";
import { createRoot } from "react-dom/client";
import "./global.less";
import { Provider } from "react-redux";
import store from "./redux/reducer/store";
import App from "./App";
import { ConfigProvider } from "antd";

const rootElement = document.getElementById("root");
if (!rootElement) throw new Error("Failed to find the root element");
const root = createRoot(rootElement);

root.render(
    // <React.StrictMode>
    <ConfigProvider
        theme={{
            token: {
                colorPrimary: "#59B948"
            }
        }}
    >
        <Provider store={store}>
            <App />
        </Provider>
    </ConfigProvider>
    // </React.StrictMode>
);