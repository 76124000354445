/* eslint-disable max-len */
import React, { ReactNode, useEffect, useReducer, useRef, useState } from "react";
import "./index.less";
// import Search from "./components/Search";
import { useDispatch, useSelector } from "react-redux";
import { sagaActionTypes } from "@/redux/actions/commonAction";
import { Dropdown, Input, List, MenuProps, Modal, Select, Space, Tabs, Spin, InputNumber } from "antd";
import { BookMarkIcon} from "@/resource/IconComponents";
import { RootState } from "@/redux/reducer/store";
import { IHistDiseaseLstSeriesModel, ILocationModel } from "@/util/http/responseType";
import { useLocation } from "react-router-dom";
import {Eamil_Svg,Download_Svg,
    Risk_Color_Svg_1,
    Risk_Color_Svg_2,
    Risk_Color_Svg_3,
    Risk_Color_Svg_4,
    Risk_Color_Svg_5,
    Risk_Color_Svg_6,
    Risk_Color_Svg_7,
    Risk_Color_Svg_8,
    Risk_Color_Svg_9,
    Risk_Color_Svg_10,
    Risk_Color_Svg_11,
    Risk_Color_Svg_12,
    Risk_Color_Svg_13,
    Risk_Color_Svg_14,
    Risk_Color_Svg
} from "./components/HistWeatherGreenChartIcon";
import dayjs from "dayjs";
import {initChartTypeOptions} from "./components/initData/initHistDiseaseData";
import UnitComponent from "./components/UintComponents";
import EchartRander from "./components/EchartRander";
import useWeatherGreenChartTranslate from "./components/SelfHooks/useWeatherGreenChartTranslate";
import SentEmailModal from "@/components/SentEmailModal";
import IF from "@/components/IF";
import html2canvas from "html2canvas";
import useHistoricWeatherSeries from "./components/SelfHooks/useHistoricWeatherSeries";
import HistRangePicker from "@/components/HistRangePicker";
import HistSearchLocation from "@/components/HistSearchLocation";
import { generateLocationByCoordinate, getHistoricalWeatherCharInfo } from "@/util/http/HistWeather";
import {debounce} from "lodash";
import useAggretation from "./components/SelfHooks/useAggretation";
import { IGetHistWeatherChatInfoResModel, IGetHistoricWeatherSeriesResModel } from "@/util/http/HistWeather/responseType";
import { normalActionTypes } from "@/redux/actions/diseaseAction";
import ForcastButton from "@/components/ForecastProviderButton";
import { color } from "echarts";
import { checkServiceTokenIsValid } from "@/util/http";
const {Option} = Select;

interface ISearchObj{
    theme?: string;
    country?: string;
    language?: string;
    lat?: number;
    long?: number;
    headtitlebg?: string;
    buttonbg?: string;
    forecastbtnbg?: string;
    securitykey?: string;
}
interface IDiseaseOptionsObj{
    riskName?: string;
    riskValue?: string;
    riskColor?: string;
    riskSVG?: any;
    isChecked?: boolean;
}

const HistWeatherGreenChart = () => {

    const SvgAndColorArr = [
        {
            svgComponent: <Risk_Color_Svg_1/>,
            svgColor: "#FF7A00"
        },
        {
            svgComponent: <Risk_Color_Svg_2/>,
            svgColor: "#0047FF"
        },
        {
            svgComponent: <Risk_Color_Svg_3/>,
            svgColor: "#5BC0FA"
        },
        {
            svgComponent: <Risk_Color_Svg_4/>,
            svgColor: "#FFAA2B"
        },
        {
            svgComponent: <Risk_Color_Svg_5/>,
            svgColor: "#FFD600"
        },
        {
            svgComponent: <Risk_Color_Svg_6/>,
            svgColor: "#10D300"
        },
        {
            svgComponent: <Risk_Color_Svg_7/>,
            svgColor: "#65FF57"
        },
        {
            svgComponent: <Risk_Color_Svg_8/>,
            svgColor: "#14580E"
        },
        {
            svgComponent: <Risk_Color_Svg_9/>,
            svgColor: "#8B8B8B"
        },
        {
            svgComponent: <Risk_Color_Svg_10/>,
            svgColor: "#dc1b1b"
        },
        {
            svgComponent: <Risk_Color_Svg_11/>,
            svgColor: "#e72ec9"
        },
        {
            svgComponent: <Risk_Color_Svg_12/>,
            svgColor: "#9f86e1"
        },
        {
            svgComponent: <Risk_Color_Svg_13/>,
            svgColor: "#0bd6a7"
        },
        {
            svgComponent: <Risk_Color_Svg_14/>,
            svgColor: "#a92d6b"
        }
    ];

    const dispatch = useDispatch();
    const urlLocation = useLocation();

    // add google analysis
    useEffect(() => {
        console.log("just invoke once");
        const head = document.getElementsByTagName("head")[0];
        const googleAnalyticsCode = "G-FWQ5VJ81Z7";
        const googleAnalyticsFirstScript = document.createElement("script");
        googleAnalyticsFirstScript.async = true;
        googleAnalyticsFirstScript.src =
          "https://www.googletagmanager.com/gtag/js?id=" + googleAnalyticsCode;

        const googleAnalyticsSecondScript = document.createElement("script");
        googleAnalyticsSecondScript.innerHTML =
          "    window.dataLayer = window.dataLayer || [];\n" +
          "    function gtag(){dataLayer.push(arguments);}\n" +
          "    gtag('js', new Date());\n" +
          "\n" +
          "    gtag('config', '" +
          googleAnalyticsCode +
          "',{\n" +
          "cookie_flags:'max-age=7200;secure;samesite-none'" +
          "});";

        head.insertBefore(googleAnalyticsSecondScript, head.firstChild);
        head.insertBefore(googleAnalyticsFirstScript, head.firstChild);
    }, []);

    const {
        showLodding
    } = useSelector((state: RootState) => state.diseaseReducer);
    const {
        homeIndexLoading,
    } = useSelector((state: RootState) => state.commonReducer);

    // static value
    const pubname = "geotroll1";
    const pubname2 = "glbsip03p";
    const pubnameGreen = "greencast";
    const servicepage = "HistoryData_ECMWF";
    const servicepage2 = "weathercast";
    const moduleName = "HistoricDiseaseUK";
    const unit = "metric";

    // search Obj
    const [searchObj,setSearObj] = useState<ISearchObj>({
        theme: "light",
        country: "GB",
        language: "en",
        lat: 51.5074,
        long: 0.1278,
        headtitlebg: "#0C612C",
        buttonbg: "#59B948",
        forecastbtnbg: "#0071CD",
        securitykey: null
    });
    const initSearchObj = ()=>{
        const searchStr = location?.search.replace("?","");
        const searchArr = searchStr?.split("&");
        const tmpSearchObj = {...searchObj};
        let haveSecurityFlag = false;
        searchArr?.map((item)=>{
            const searchKeyValue = item?.split("=");
            if(searchKeyValue[0].toLowerCase() === "lat" || searchKeyValue[0].toLowerCase() === "long"){
                tmpSearchObj[searchKeyValue[0].toLowerCase()] = Number(searchKeyValue[1]);
            }
            else if(searchKeyValue[0].toLowerCase() === "country"){
                tmpSearchObj[searchKeyValue[0]?.toLocaleLowerCase()] = searchKeyValue[1]?.toUpperCase();
            }
            else if(searchKeyValue[0].toLowerCase() === "securitykey"){
                if(searchKeyValue[1]){
                    tmpSearchObj[searchKeyValue[0].toLowerCase()] = searchKeyValue[1];
                    haveSecurityFlag = true;
                }
            }
            else{
                tmpSearchObj[searchKeyValue[0].toLowerCase()] = searchKeyValue[1];
            }
        });
        if(!haveSecurityFlag){
            tmpSearchObj.securitykey = "no-value";
        }
        console.log("lanlll obj",tmpSearchObj);
        setSearObj(tmpSearchObj);
    };
    useEffect(()=>{
        document.body.style.backgroundColor = searchObj?.theme === "light" ? "white" : "black";
    },[searchObj?.theme]);


    // current location
    const [isReset,setIsReset] = useState(false);
    const changeChildIsReset = (data:boolean) =>{
        setIsReset(data);
    };
    const [currentLocation,setCurrentLocation] = useState<ILocationModel>(null);
    const changeChildCurrentLocation = (value: ILocationModel)=>{
        setCurrentLocation(value);
    };
    // get countryname form the lat and long
    const [initSearchCountry,setInitSearchCountry] = useState<ILocationModel>(null);
    const generateLocationByCoordinateFunc = async()=>{
        try{
            const res = await generateLocationByCoordinate({
                searchText: `${searchObj?.long},${searchObj?.lat}`
            });
            console.log("my coun",res.data);
            console.log("my coun res.data[0]",res.data[0]);
            console.log("my coun res.data[0].AdminName",res.data[0].adminName);
            const tmpData:ILocationModel = {
                AdminName: {_text: res.data[0].adminName},
                AdminName1: { _text: res.data[0].adminName1 },
                AdminName2: { _text: res.data[0].adminName2 },
                AdminName3: res.data[0].adminName3,
                AltName: res.data[0].altName,
                AreaId: res.data[0].areaId,
                CountryCode: { _text: res.data[0].countryCode },
                CountryName: { _text: res.data[0].countryName },
                Latitude: { _text: res.data[0].latitude },
                Longitude: { _text: res.data[0].longitude},
                Name: { _text: res.data[0].name},
                PlaceId: { _text: res.data[0].placeId},
                PostalCode: res.data[0].postalCode,
                Score: res.data[0].score,
                pubName: null,
                cultureCode: null,
                numberOfDays: null,
                isNowcast: null
            };
            console.log("my coun tmpData",tmpData);
            setInitSearchCountry(tmpData);
            // setInitSearchCountry(res.data);
        }
        catch(error){
            console.log("get countryname error",error);
        }
    };

    // start and end date, min and max year
    const [selectYearOption,setSelectYearOpton] = useState<number[]>([]);
    const [comparisionYearMax,setComparsionYearMax] = useState<number>(dayjs().year());
    const [comparisionYearMin,setComparsionYearMin] = useState<number>(dayjs().year());
    const [startDate,setStartDate] = useState<dayjs.Dayjs>(dayjs().subtract(1,"day").subtract(1,"month"));
    const [endDate,setEndDate] = useState<dayjs.Dayjs>(dayjs().subtract(2,"day"));
    const childChangeStartDateFunc = (date:dayjs.Dayjs)=>{
        setStartDate(date);
    };
    const childChangeEndDateFunc = (date:dayjs.Dayjs)=>{
        setEndDate(date);
    };


    // email this page
    const [showEmailModal,setShowEmailModal] = useState(false);
    const [imageBase64Content,setImageBase64Content] = useState(null);

    // Scale and elevation,checkedSeries
    const [aggregation,setAggretation] = useState("daily");
    const [elevation,setElevation] = useState(5);
    const [elevationUnit,setElevationUnit] = useState("cm");
    const [checkedSeries,setCheckedSeries] = useState("");
    const publicGetCheckedSeriesString = (data:IHistDiseaseLstSeriesModel[]):string =>{
        let tmpCheckedSeries = "";
        data?.forEach((item)=>{
            if(item?.isCheck) tmpCheckedSeries = tmpCheckedSeries + item?.id + ";";
        });
        setCheckedSeries(tmpCheckedSeries);
        return tmpCheckedSeries;
    };

    // disease show options
    const [diseaseShowOptions,setDiseaseShowOptions] = useState<IHistDiseaseLstSeriesModel[]>();
    const publicChangeDiseaseShowOptions = (type:string,value:string | boolean,index?:number)=>{
        const tmp = [...diseaseShowOptions];
        tmp[index].isCheck = !!value;
        console.log("tmp change",tmp);
        setDiseaseShowOptions(tmp);
        console.log("clear change diseaseShowOptions",tmp);
    };

    console.log("lanxue diseaseShowOptions",diseaseShowOptions);

    // chart Type
    const [chartType,setChartType] = useState("we_tempandrain");
    const [chartTypeOptions,setChartTypeOptions] = useState<any[]>(initChartTypeOptions);
    const [chartTypeTranslate,setChartTypeTranslate] = useState("Temperature");
    const [echartYName,setEchartYName] = useState("Temperature (°C)");
    const [cumulativeValue,setCumulative] = useState(0);
    const publicGetCumulativeVale = (echartData:IGetHistWeatherChatInfoResModel)=>{
        let allData = 0;
        let tmpCumulativeValue = 0;
        console.log("test error",echartData);
        echartData?.lstResultseries?.forEach((item,index)=>{
            item?.values?.forEach((innerItem)=>{
                allData = allData + innerItem;
            });
        });
        tmpCumulativeValue = allData / (echartData?.lstResultseries?.length * (echartData?.lstResultseries[0]?.values?.length || 1) );
        setCumulative(tmpCumulativeValue);
    };
    console.log("lanxue chartTypeOptions",chartTypeOptions);
    const publicChangeChartTypeTranslateAndYName = (Id:string)=>{
        let tmpCharTypeTranslate = "";
        let tmpEchartYName = "";
        console.log("trans dd id",Id);
        console.log("trans dd chartTypeOptions",chartTypeOptions);
        console.log("trans dd diseaseShowOptions",diseaseShowOptions);
        chartTypeOptions?.forEach((item)=>{
            if(item?.ID === Id){
                tmpCharTypeTranslate = item?.Text;
                tmpEchartYName = item?.aliasName;
            }
        });
        setChartTypeTranslate(tmpCharTypeTranslate);
        setEchartYName(tmpEchartYName);
    };



    // download iamge
    const saveMapToDataUrl = ()=>{
        const element = document.getElementById("chartContentDiv");
        const element1 = document.querySelector(".rightLatLng") as HTMLElement;
        const element2 = document.querySelector(".chart-contnet-right-title") as HTMLElement;
        element1.style.display = "block";
        element2.style.display = "none";
        console.log("test images",element);
        if(element){
            html2canvas(element,{
                useCORS: true
            }).then((canvas)=>{
                const dataUrl = canvas.toDataURL("image/png");
                console.log("test images",dataUrl);

                const link = document.createElement("a");
                link.href = dataUrl;
                link.download = searchObj.country + ".png";
                link.click();
                element1.style.display = "none";
                element2.style.display = "block";
            });
        }
    };
    const getImageBase64Data = ()=>{
        const element = document.getElementById("chartContentDiv");
        const element1 = document.querySelector(".rightLatLng") as HTMLElement;
        const element2 = document.querySelector(".chart-contnet-right-title") as HTMLElement;
        element1.style.display = "block";
        element2.style.display = "none";
        console.log("test images",element);
        if(element){
            html2canvas(element,{
                useCORS: true
            }).then((canvas)=>{
                const dataUrl = canvas.toDataURL("image/png");
                setImageBase64Content(dataUrl);
                element1.style.display = "none";
                element2.style.display = "block";
            });
        }
    };

    // self hooks
    const {weacherGreenChartTranslate} = useWeatherGreenChartTranslate({
        countryCode: searchObj.country,
        language: searchObj.language
    });
    const {aggregationOptions} = useAggretation({
        pubName: pubname2,
        strCultureCode: searchObj.language + "-" + searchObj.country
    });
    const {weatherGreenChartSeries,initWeatherGreenChartSeries,initWeatherGreenChartCategorySeries} = useHistoricWeatherSeries({
        countryCode: searchObj.country,
        language: searchObj.language,
        servicepage,
        pubname: pubnameGreen,
        moduleName,
        view: chartType,
        scale: aggregation
    });
    console.log("lanxue aa weatherGreenChartSeries",weatherGreenChartSeries);
    console.log("lanxue aa initWeatherGreenChartSeries",initWeatherGreenChartSeries);
    console.log("lanxue aa initWeatherGreenChartCategorySeries",initWeatherGreenChartCategorySeries);

    // color and id array, used in echarts
    const echartContainerRef = useRef(null);
    const [echartsColor,setEchartsColor] = useState<string[]>([]);
    const [echartsRiskId,setEchartsRiskId] = useState<string[]>([]);
    const [echartLegend,setEchartLegend] = useState<string[]>([]);
    const publicChangeColorAndIdArray = (tmpOptions?: any[],initFlag?:boolean)=>{
        const tmpColor = [];
        const tmpId = [];
        const tmpLegend = [];
        let tmpDiseaseShowOptions = [];
        if(tmpOptions?.length > 0){
            tmpDiseaseShowOptions = [...tmpOptions];
        }
        else{
            tmpDiseaseShowOptions = [...diseaseShowOptions];
        }
        tmpDiseaseShowOptions?.forEach((item)=>{
            if(item?.isCheck){
                tmpColor.push(item?.color);
                tmpId.push(item.id);
                tmpLegend.push(item?.text);
            }
        });
        setEchartsColor(tmpColor);
        setEchartsRiskId(tmpId);
        setEchartLegend(tmpLegend);
        console.log("lanxue tmpLegent",tmpLegend);
    };
    // final get Echarts data
    // get final data
    const [echartData,setEchartData] = useState<IGetHistWeatherChatInfoResModel>(null);
    const getHistoricalWeatherGreenCharInfoFunc = async(tmpCheckedSeries: string,initFlag?:boolean)=>{
        try{
            // if initFlag is true, mean get checkedSeries from DiseaseShowOptionInit
            // else get checkedSeries from diseaseShowOptions
            // const tmpCheckedSeries = selectedDiseaseRiskId(initFlag);
            // const tmpStartDate = initFlag ? dayjs().subtract(2,"month").format("DD/MM/YYYY") : startDate.format("DD/MM/YYYY");
            // const tmpEndDate = initFlag ? dayjs().format("DD/MM/YYYY") : endDate.format("DD/MM/YYYY");
            dispatch({ type: normalActionTypes.NORMAL_SHOWLODDING, payload: true});
            const res = await getHistoricalWeatherCharInfo({
                pubname: pubnameGreen,
                // servicepage: servicepage,
                unit: unit,
                culture: searchObj.language + "-" + searchObj.country,
                countryCode: searchObj.country,
                latitude: searchObj.lat,
                longitude: searchObj.long,
                placeName: "",
                maxDistanceDiff: 0,
                maxAltitudeDiff: 0,
                startDate: startDate.format("DD/MM/YYYY"),
                endDate: endDate.format("DD/MM/YYYY"),
                aggregation: aggregation,
                seedingDepth: elevation,
                accumulation: false,
                comparisionYearMax: comparisionYearMax,
                comparisionYearMin: comparisionYearMin,
                checkedSeries: tmpCheckedSeries,
                isMBHistGreencast: true
            });
            setEchartData(res.data);
            dispatch({ type: normalActionTypes.NORMAL_SHOWLODDING, payload: false});
        }
        catch(error){
            dispatch({ type: normalActionTypes.NORMAL_SHOWLODDING, payload: false});
            setEchartData(null);
            console.log("get HistroicCharInfoFunc failed",error);
        }
    };

    // clear and refresh function
    const publicClear = ()=>{
        // reset the start and end date
        // setStartDate(dayjs().subtract(2,"month"));
        // setEndDate(dayjs());
        setStartDate(dayjs().subtract(1,"day").subtract(1,"month"));
        setEndDate(dayjs().subtract(2,"day"));
        // set scale
        setAggretation("daily");
        // set chart type
        setChartType("we_tempandrain");
        // init the search obj and current location
        initSearchObj();
        setCurrentLocation(null);
        setIsReset(true);
        //set diseaseOptions
        // const tmpListResultSeries:any[] = JSON.parse(JSON.stringify(initWeatherGreenChartSeries?.lstResultseries));
        console.log("change",initWeatherGreenChartSeries);
        console.log("change",initWeatherGreenChartSeries?.lstResultseries);
        const tmpListResultSeries:any[] = JSON.parse(JSON.stringify(initWeatherGreenChartSeries?.lstResultseries));
        let tmpFilter = [];
        // tmpFilter = tmpListResultSeries?.filter((item)=>{
        //     if(item?.id != "node0_cb_PrecipAmount_mm_DailySum") return item;
        // });
        tmpFilter = tmpListResultSeries;
        console.log("weather aaa tmp",tmpListResultSeries);
        setDiseaseShowOptions(tmpFilter);
        // also need request the echart data
        const tmpCheckedSeries = publicGetCheckedSeriesString(tmpFilter);
        console.log("request ",tmpCheckedSeries);
        console.log("request ",tmpListResultSeries);
        getHistoricalWeatherGreenCharInfoFunc(tmpCheckedSeries);
        // re set the color and id Arr
        publicChangeColorAndIdArray(tmpListResultSeries);
        // reset cumulative value
        setCumulative(0);
        // change title and echart y name
        setChartType(chartTypeOptions[0]?.ID);
        publicChangeChartTypeTranslateAndYName(chartTypeOptions[0]?.ID);
    };
    const publicRefresh = ()=>{
        // re set the color and id Arr
        publicChangeColorAndIdArray();
        // re get the echart data
        const tmpCheckedSeries = publicGetCheckedSeriesString(diseaseShowOptions);
        getHistoricalWeatherGreenCharInfoFunc(tmpCheckedSeries);
    };

    // change chartTypeOptions relevent data translate
    const publicChangeChartTypeOptionsTranslate = (weatherGreenChartSeries:IGetHistoricWeatherSeriesResModel)=>{
        const tmpChartOptions = [...chartTypeOptions];
        let tmpTemperatuer = "Temperature °C";
        let tmpRainfall = "Rain Fall mm";
        weatherGreenChartSeries?.lstLegend?.forEach((item)=>{
            if(item?.key === "gc_temperature") tmpTemperatuer = item?.name;
            if(item?.key === "gc_rainfall") tmpRainfall = item?.name;
        });
        // change alisName
        // tmpChartOptions[0].aliasName = tmpTemperatuer;
        // tmpChartOptions[2].aliasName = tmpTemperatuer;
        // tmpChartOptions[3].aliasName = tmpTemperatuer;
        // tmpChartOptions[1].aliasName = tmpRainfall;
        // tmpChartOptions[4].aliasName = tmpRainfall;
        tmpChartOptions[0].aliasName = tmpTemperatuer;
        tmpChartOptions[1].aliasName = tmpTemperatuer;
        tmpChartOptions[2].aliasName = tmpTemperatuer;
        // tmpChartOptions[1].aliasName = tmpRainfall;
        tmpChartOptions[3].aliasName = tmpRainfall;
        // change Text
        // tmpChartOptions[2].Text = weatherGreenChartSeries?.lstResultseries[1]?.text;
        // tmpChartOptions[3].Text = weatherGreenChartSeries?.lstResultseries[2]?.text;
        // tmpChartOptions[4].Text = weatherGreenChartSeries?.lstResultseries[3]?.text;
        let tmpSeriesName = weatherGreenChartSeries?.lstResultseries[0]?.text;
        if(searchObj.country === "GB" || searchObj.country === "ZA"){
            tmpSeriesName = tmpSeriesName?.split("&")[0];
        }
        tmpChartOptions[0].Text = tmpSeriesName;
        tmpChartOptions[1].Text = weatherGreenChartSeries?.lstResultseries[1]?.text;
        tmpChartOptions[2].Text = weatherGreenChartSeries?.lstResultseries[2]?.text;
        tmpChartOptions[3].Text = weatherGreenChartSeries?.lstResultseries[3]?.text;
        setChartTypeOptions(tmpChartOptions);
    };

    // init function
    const publicInitSetSelectYearOpton = ()=>{
        const tmpOptons = [];
        const initYear = dayjs().year();
        for(let i = 0; i <= 10; i++){
            tmpOptons.push(initYear - i);
        }
        setSelectYearOpton(tmpOptons);
    };
    const publicInitGetEchartData = (tmpCheckedSeries:string)=>{
        getHistoricalWeatherGreenCharInfoFunc(tmpCheckedSeries);
    };



    // =================== useEffect =================
    // init the select year option
    useEffect(()=>{
        publicInitSetSelectYearOpton();
    },[]);

    useEffect(() => {
        initSearchObj();
    }, [urlLocation]);
    // change the Select Aggretation the default value
    useEffect(()=>{
        if(aggregationOptions.length > 0){
            setAggretation(aggregationOptions[0]?.key);
        }
    },[aggregationOptions]);
    // get CountryName by lat and long
    useEffect(()=>{
        const getCountryNameByLagLongDebounceFunc = debounce(generateLocationByCoordinateFunc,500);
        getCountryNameByLagLongDebounceFunc();
        return()=>{
            getCountryNameByLagLongDebounceFunc.cancel();
        };
    },[searchObj.lat,searchObj.long]);
    const [initLocationCount,setInitLocationCount] = useState(0);
    useEffect(()=>{
        if(currentLocation?.Latitude?._text && currentLocation?.Longitude?._text){
            const tmpSearchObj = {...searchObj};
            tmpSearchObj.lat = Number(currentLocation?.Latitude?._text);
            tmpSearchObj.long = Number(currentLocation?.Longitude?._text);
            setSearObj(tmpSearchObj);
            setInitLocationCount(initLocationCount + 1);
        }
    },[currentLocation]);
    useEffect(()=>{
        if(initLocationCount >= 1){
            const tmpCheckedSeries = publicGetCheckedSeriesString(diseaseShowOptions);
            getHistoricalWeatherGreenCharInfoFunc(tmpCheckedSeries);
        }
    },[initLocationCount,searchObj?.lat,searchObj?.lat]);
    // when series change, the disease options also should change
    useEffect(()=>{
        if(weatherGreenChartSeries?.lstResultseries?.length > 0){
            // change ChartOptions
            const tmpChartOptions = [...chartTypeOptions];
            let tmpDiseaseShowOptions = [];
            const tmpListResultSeries:any[] = JSON.parse(JSON.stringify(weatherGreenChartSeries.lstResultseries));
            let tmpFilter = [];
            // if(chartType === "we_tempandrain"){
            //     tmpFilter = tmpListResultSeries?.filter((item)=>{
            //         if(item?.id != "node0_cb_PrecipAmount_mm_DailySum") return item;
            //     });
            // }
            // else if(chartType === "we_tempandrain_rainfall"){
            //     tmpFilter = tmpListResultSeries?.filter((item)=>{
            //         if(item?.id == "node0_cb_PrecipAmount_mm_DailySum") return item;
            //     });
            // }
            if(searchObj.country === "GB" || searchObj.country === "ZA"){
                tmpFilter = tmpListResultSeries?.filter((item)=>{
                    if(item?.id != "node0_cb_PrecipAmount_mm_DailySum") return item;
                });
            }
            else{
                tmpFilter = [...tmpListResultSeries];
            }
            // tmpDiseaseShowOptions = [...weatherGreenChartSeries.lstResultseries];
            tmpDiseaseShowOptions = [...tmpFilter];
            setDiseaseShowOptions(tmpDiseaseShowOptions);

            // tmpListResultSeries.forEach((item)=>{
            //     if(item?.id === "node0_cb_PrecipAmount_mm_DailySum"){
            //         tmpChartOptions[1].Text = item?.text;
            //     }
            // });


            // when chartType change,should request the getHistoricalWeatherCharInfo to get default value
            const tmpCheckedSeries = publicGetCheckedSeriesString(tmpDiseaseShowOptions);
            publicInitGetEchartData(tmpCheckedSeries);
            // also need change the color and id array which used in Echarts
            publicChangeColorAndIdArray(tmpDiseaseShowOptions);

            // set the elevation value ant unit
            const tmpElevationValue = weatherGreenChartSeries?.lstParameters[0]?.seedingDepthDefaultValue || 5;
            const tmpElevationUnit = weatherGreenChartSeries?.lstParameters[0]?.seedingUnit || "cm";
            setElevation(tmpElevationValue);
            setElevationUnit(tmpElevationUnit);
            setChartTypeOptions(tmpChartOptions);

        }
    },[weatherGreenChartSeries]);
    // shen sereis change, also need change chartTypeOptions relevent translate
    useEffect(()=>{
        if(initWeatherGreenChartCategorySeries?.lstResultseries?.length > 0){
            publicChangeChartTypeOptionsTranslate(initWeatherGreenChartCategorySeries);
        }
    },[initWeatherGreenChartCategorySeries]);
    // when get echartData, cumulative the value
    useEffect(()=>{
        if(echartData?.lstResultseries?.length > 0){
            publicGetCumulativeVale(echartData);
        }
    },[echartData]);

    console.log("bg year",selectYearOption);
    console.log("weathe aaa diseaseShowOptions",diseaseShowOptions);
    console.log("weathe aaa initWeatherGreenChartSeries",initWeatherGreenChartSeries?.lstResultseries);
    console.log("ddddd currentLocation",currentLocation);
    console.log("ddddd initSearchCountry",initSearchCountry);

    //if it is hexadecimal color value
    function isHexColor(color) {
        const regex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;
        return regex.test(color);
    }
    //Encapsulate the logic of creating style objects into a function
    function createBgStyle(hexColor) {
        if (hexColor) {
        // Ensure that hexColor is a valid hexadecimal color value
            if (isHexColor(hexColor)) {
                return {
                    backgroundColor: hexColor,
                    color: "#FFFFFF",
                    border:"none",
                };
            } else {
            // If hexColor is not a valid color value, print the log
                console.log("Please provide hexadecimal color values");
            }
        }
        // If hexColor is not provided, return null
        return null;
    }

    const truebuttonbg="#"+searchObj.buttonbg;

    // Creating Style Objects with Functions
    const headTitleStyle = createBgStyle("#" + searchObj.headtitlebg);
    const buttonbgStyle = createBgStyle("#" + searchObj.buttonbg);
    const forecastbtnbgStyle = createBgStyle("#" + searchObj.forecastbtnbg);

    const buttonStyle = {
        border:`1px solid ${truebuttonbg}`,
        color:truebuttonbg,
    };
    const iconColor="#FFFFFF";


    const [showPageByToken,setShowPageByToken] = useState(true);
    const isFirstRender = useRef(true);
    const checkServiceTokenIsValidFunc = async()=>{
        try{
            const res = await checkServiceTokenIsValid(searchObj.securitykey);
            setShowPageByToken(res.data);
            if(!res.data){
                const element1 = document.querySelector(".dark-theme") as HTMLElement;
                if(element1){
                    element1.style.backgroundColor = "white";
                }
            }
        }
        catch(error){
            console.log("error",error);
        }
    };
    useEffect(()=>{
        if (isFirstRender.current) {
            isFirstRender.current = false; // first render
            return; // when first render, do nothing
        }
        // if(searchObj?.securitykey){
        //     if(searchObj.securitykey === "no-value"){
        //         setShowPageByToken(false);
        //     }
        //     else{
        //         checkServiceTokenIsValidFunc();
        //     }
        // }
    },[searchObj.securitykey]);

    return (
        <>
            {
                showPageByToken ?
                    <div className="hist-weather-green-chart-all-container">
                        <div className={`hist-weather-chart bg-${searchObj.theme}`}>
                            <Spin spinning={showLodding || homeIndexLoading}>
                                <div className={"hist-weather-chart-container"}>
                                    <div className="accurate-left">
                                        <div className="head-title" style={headTitleStyle}>
                                            {/* Historical Weather Green Chart */}
                                            {/* {weacherGreenChartTranslate?.txt_HistWeatherGreenChart || "Historical Weather Green Chart"} */}
                                            {(searchObj?.country?.toUpperCase() === "GB" || searchObj?.country?.toUpperCase() === "ZA") ? "Historical Weather" :
                                                weacherGreenChartTranslate?.txt_HistWeatherGreenChart || "Historical Weather Green Chart"}
                                        </div>
                                        <div className="ag-search-div">
                                            <span className="search-title theme-title-color">
                                                {/* Select your location to see accurate weather */}
                                                {/* {weacherGreenChartTranslate?.txt_SelectLocation || "Select your location to see accurate weather"} */}
                                                {weacherGreenChartTranslate?.txt_SelectLocationToSeeHistWeather || "Select your location to see historical weather"}
                                            </span>
                                            <HistSearchLocation
                                                style={buttonbgStyle}
                                                iconColor={iconColor}
                                                country={searchObj.country}
                                                language={searchObj.language}
                                                initSearchCountryName={initSearchCountry?.Name?._text}
                                                placahold={weacherGreenChartTranslate?.txt_LocationNameLngLat}
                                                currentLocation={currentLocation}
                                                isReset = {isReset}
                                                changeChildCurrentLocation={changeChildCurrentLocation}
                                                changeChildIsReset={changeChildIsReset}
                                            />
                                        </div>
                                        <div className="ag-bookmark">
                                            <label className="bookmark-title theme-title-color">
                                                {/* Select your start and end date */}
                                                {weacherGreenChartTranslate?.txt_SelYourStartAndEndDate || "Select your start and end date"}
                                            </label>
                                            <div className="select-div">
                                                <HistRangePicker
                                                    countrycode={searchObj.country}
                                                    className="hisDisease-date"
                                                    startDate={startDate}
                                                    endDate={endDate}
                                                    childChangeEndDateFunc={childChangeEndDateFunc}
                                                    childChangeStartDateFunc={childChangeStartDateFunc}
                                                />
                                            </div>
                                        </div>
                                        {/* Scale */}
                                        <div className="ag-aggregation-elevation">
                                            <div className="ag-aggregation">
                                                <label className="theme-title-color">
                                                    {/* Scale */}
                                                    {weatherGreenChartSeries?.lstLegend[8]?.name || "Scale"}
                                                </label>
                                                <Select
                                                    className="ag-aggregation-select"
                                                    value={aggregation}
                                                    onChange={(e)=>{
                                                        setAggretation(e);
                                                    }}
                                                >
                                                    {
                                                        aggregationOptions?.map((item,index)=>{
                                                            return(
                                                                <>
                                                                    <Option key={item.key + index} value={item.key}>{item.value}</Option>
                                                                </>
                                                            );
                                                        })
                                                    }
                                                </Select>
                                            </div>
                                            <IF show={searchObj?.country != "AU"}>
                                                <div className="ag-elevation">
                                                    <label className="theme-title-color">
                                                        {/* Seeding depth */}
                                                        {weatherGreenChartSeries?.lstLegend[0]?.name || "Seeding depth"}
                                                    </label>
                                                    <div className="ag-elevation-number">
                                                        <InputNumber
                                                            type="number"
                                                            value={elevation}
                                                            onChange={(e)=>{
                                                                // console.log("lanxue number ",e);
                                                                setElevation(Number(e));
                                                                // setElevation(Number(e.target.value));
                                                            }}
                                                            addonAfter={<UnitComponent
                                                                text={elevationUnit}
                                                                className={"ag-elevation-number-unit"}
                                                            />}
                                                        />
                                                        {/* <Input
                                                    type="number"
                                                    value={elevation}
                                                    onChange={(e)=>{
                                                        setElevation(Number(e.target.value));
                                                    }}
                                                    addonAfter={<UnitComponent
                                                        text={elevationUnit}
                                                        className={"ag-elevation-number-unit"}
                                                    />}
                                                /> */}
                                                    </div>
                                                </div>
                                            </IF>
                                        </div>

                                        {/* chart type list */}
                                        <div className="ag-chart-type">
                                            <label className="top-title theme-title-color">
                                                {/* Select Chart Type */}
                                                {weacherGreenChartTranslate?.txt_SelChartType || "Select Chart Type"}
                                            </label>
                                            <Select
                                                className="ag-aggregation-select"
                                                value={chartType}
                                                onChange={(e)=>{
                                                    console.log("fffff e",e);
                                                    console.log("fffff chartTypeOptions[0]",chartTypeOptions[0]);
                                                    setChartType(e);
                                                    publicChangeChartTypeTranslateAndYName(e);
                                                }}
                                            >
                                                {
                                                    chartTypeOptions?.map((item,index)=>{
                                                        let hiddenRain = false;
                                                        if(item?.ID ==="we_tempandrain_rainfall" &&
                                                    (searchObj?.country.toUpperCase() === "GB" || searchObj?.country?.toUpperCase() === "ZA")
                                                        ) hiddenRain = true;
                                                        return(
                                                            hiddenRain ? null : <Option value={item?.ID} key={item?.ID + index}>{item?.Text}</Option>
                                                        );
                                                    })
                                                }
                                            </Select>
                                        </div>

                                        <div className="ag-chart-description">
                                            <label className="sub-title theme-title-color">
                                                {/* Select from the checkboxes below to add or remove graph parameters, then click refresh chart to display changes */}
                                                {weacherGreenChartTranslate?.txt_SelFromCbxToRefreshChart || "Select from the checkboxes below to add or remove graph parameters, then click refresh chart to display changes"}
                                            </label>
                                        </div>

                                        {/* show disease */}
                                        <div className="ag-disease-risk-list-container theme-title-color">
                                            {
                                                diseaseShowOptions?.map((item,index)=>{
                                                    return(
                                                        <>
                                                            <div className="ag-disease-risk-item">
                                                                <Input
                                                                    type="checkbox"
                                                                    checked = {item?.isCheck}
                                                                    onChange={(e)=>{
                                                                        console.log("e",e.target.checked);
                                                                        publicChangeDiseaseShowOptions("isCheckd",e.target.checked,index);
                                                                    }}
                                                                />
                                                                <span>{item?.text}</span>
                                                                <IF show={item?.isSelectYear}>
                                                                    <Select
                                                                        className="weathergreen-year-select"
                                                                        value={item?.id === "node1_cb_TempAir_C_NighttimeMinUser" ? comparisionYearMin : comparisionYearMax}
                                                                        onChange={(e)=>{
                                                                            console.log("fffff diseaseShowOptions",diseaseShowOptions);
                                                                            console.log("fffff item.id",item?.id);
                                                                            console.log("fffff e",e);
                                                                            if(item?.isCheck){
                                                                                if(item?.id === "node1_cb_TempAir_C_NighttimeMinUser"){
                                                                                    console.log("fffff change min",comparisionYearMin);
                                                                                    setComparsionYearMin(e);
                                                                                }
                                                                                else{
                                                                                    console.log("fffff change max",comparisionYearMax);
                                                                                    setComparsionYearMax(e);
                                                                                }
                                                                            }
                                                                        }}
                                                                    >
                                                                        {
                                                                            selectYearOption?.map((innerItem,innerIndex)=>{
                                                                                return(
                                                                                    <>
                                                                                        <Option key={innerItem + innerIndex + item.id} value={innerItem}>{innerItem}</Option>
                                                                                    </>
                                                                                );
                                                                            })
                                                                        }
                                                                    </Select>
                                                                </IF>
                                                            </div>
                                                        </>
                                                    );
                                                })
                                            }
                                        </div>
                                        <div className="ag-disease-button-group theme-title-color">
                                            <button style={buttonStyle} className="common-button clear-button theme-title-color"
                                                onClick={()=>{
                                                    publicClear();
                                                }}
                                            >
                                                {/* Clear Selection */}
                                                {weacherGreenChartTranslate?.ClearSelection || "Clear Selection"}
                                            </button>
                                            <button style={buttonbgStyle} className="common-button refresh-button theme-title-color"
                                                onClick={()=>{
                                                    publicRefresh();
                                                }}
                                            >
                                                {/* Refresh Chart */}
                                                {weacherGreenChartTranslate?.gc_refreshchart || "Refresh Chart"}
                                            </button>
                                        </div>
                                    </div>


                                    <div className="accurate-right">
                                        <div className="top-header-div" style={headTitleStyle}>
                                            <div className="left-title">
                                                <label className="main-title">
                                                    {/* London City Airport */}
                                                    {/* {(currentLocation?.Name?._text || initSearchCountry?.Name?._text) || "London City Airport"} */}
                                                    {(currentLocation?.Name?._text || initSearchCountry?.Name?._text) || " "}
                                                    <span className="sub-lable" style={headTitleStyle}>
                                                        {`${searchObj?.long} , ${searchObj?.lat}`}
                                                    </span>
                                                </label>
                                                {/* <label className="sub-lable"> */}
                                                {/* 51.507351, -0.127758 */}

                                                {/* </label> */}
                                            </div>
                                            <div className="right-title"
                                                onClick={()=>{
                                                    getImageBase64Data();
                                                    setShowEmailModal(true);
                                                }}
                                            >
                                                <Eamil_Svg
                                                    width={15}
                                                    height={12}
                                                />
                                                <span>
                                                    {/* Email this page */}
                                                    {weacherGreenChartTranslate?.gc_EmailPage || "Email this page"}
                                                </span>
                                            </div>
                                        </div>
                                        <IF show={showEmailModal}>
                                            <SentEmailModal
                                                open = {showEmailModal}
                                                id={"hist-disease-sentEmailModal"}
                                                welcomeStr={weacherGreenChartTranslate?.gc_EmailHeader}
                                                toStr={weacherGreenChartTranslate?.gc_EmailTo}
                                                subjectStr={weacherGreenChartTranslate?.gc_EmailSubject}
                                                messageStr={weacherGreenChartTranslate?.gc_EmailMessageArea}
                                                idRequiredStr={weacherGreenChartTranslate?.gc_EmailRequired}
                                                subjectRequiredStr={weacherGreenChartTranslate?.gc_EmailSubjectRequired}
                                                sendEmailStr={weacherGreenChartTranslate?.gc_SendEmailButton}
                                                cancelStr={weacherGreenChartTranslate?.cancel}
                                                idValidStr={weacherGreenChartTranslate?.gc_EmailValid}
                                                emailFailedStr={weacherGreenChartTranslate?.gc_EmailSendFailed}
                                                defaultTitle={weacherGreenChartTranslate?.txt_ShareChartByEmail}
                                                defaultSubject={weacherGreenChartTranslate?.txt_HistWeatherGreenChart}
                                                defaultMailContent={weacherGreenChartTranslate?.txt_HistWeatherGreenChartMailBody}
                                                sentEmailSuccessStr={weacherGreenChartTranslate?.gc_EmailSendSuccess}
                                                cancelCallback={()=>{
                                                    setShowEmailModal(false);
                                                }}
                                                imageBase64={imageBase64Content}
                                            />
                                        </IF>
                                        <div className="chart-content-container"
                                            id={"chartContentDiv"}
                                            ref = {echartContainerRef}
                                        >
                                            <div className="chart-content-title">
                                                <div className="chart-contnet-left-title">
                                                    <span>
                                                        {/* {chartType} - London City Airport */}
                                                        {/* {chartTypeTranslate} - {(currentLocation?.Name?._text || initSearchCountry?.Name?._text) || "London City Airport"} */}
                                                        {`${chartTypeTranslate} - ${(currentLocation?.Name?._text || initSearchCountry?.Name?._text) || " "}`}
                                                        <span style={{display:"none"}} className="rightLatLng">{` (${searchObj?.long} , ${searchObj?.lat})`}</span>
                                                    </span>
                                                </div>
                                                <div className="chart-contnet-right-title"
                                                    style={{cursor:"pointer"}}
                                                    onClick={()=>{
                                                        saveMapToDataUrl();
                                                    }}
                                                >
                                                    <Download_Svg
                                                        width={18}
                                                        height={18}
                                                    />
                                                    <span>
                                                        {/* Download as Image */}
                                                        {weacherGreenChartTranslate?.txt_DownloadAsImage || "Download as Image"}
                                                    </span>
                                                </div>
                                            </div>

                                            <div >
                                                <div className="chart-content-image">
                                                    <EchartRander
                                                        // type={chartTypeTranslate}
                                                        type={chartType}
                                                        title="Test Title"
                                                        echartData={echartData}
                                                        colorArray={echartsColor}
                                                        idArray={echartsRiskId}
                                                        legendArray={echartLegend}
                                                        yName={echartYName}
                                                        yNameForRain={chartTypeOptions[0]?.aliasName + "&" + chartTypeOptions[3]?.aliasName}
                                                        width={echartContainerRef?.current?.clientWidth}
                                                        echartTitle={`${chartTypeTranslate} ${weacherGreenChartTranslate?.txt_Legend || "Legend"}`}
                                                        diseaseShowOptions={diseaseShowOptions?.filter(item => item.isCheck)}
                                                    />
                                                </div>

                                                <div className="chart-content-risk">
                                                    <div className="chart-content-risk-count">
                                                        <span>
                                                            {/* Cummulative Temperature : {"7.5"} */}
                                                            <IF show={searchObj?.language === "fr"}>
                                                                {
                                                                    <>
                                                                        {chartTypeTranslate} {weacherGreenChartTranslate?.txt_Cumulative || "Cumulative"} : {cumulativeValue || "0"}
                                                                    </>
                                                                }
                                                            </IF>
                                                            <IF show={searchObj?.language != "fr"}>
                                                                {
                                                                    <>
                                                                        {weacherGreenChartTranslate?.txt_Cumulative || "Cumulative"} {chartTypeTranslate} : {cumulativeValue || "0"}
                                                                    </>
                                                                }
                                                            </IF>

                                                        </span>
                                                    </div>
                                                    <div className="chart-content-risk-title">
                                                        <span>
                                                            {/* {chartType} Legend */}
                                                            {/* {chartTypeTranslate} {weacherGreenChartTranslate?.txt_Legend || "Legend"} */}
                                                        </span>
                                                    </div>
                                                    <div className="chart-content-risk-list">
                                                        {/* {diseaseShowOptions?.map((item,index)=>{
                                                    return(
                                                        <>
                                                            <IF show={item?.isCheck}>
                                                                <div className="chart-content-risk-item" id={index + item?.id}>
                                                                    <Risk_Color_Svg
                                                                        color={item?.color}
                                                                    />
                                                                    <div className="chart-content-risk-item-title">
                                                                        <span>{item?.text}</span>
                                                                    </div>
                                                                </div>
                                                            </IF>
                                                        </>
                                                    );
                                                })} */}
                                                    </div>
                                                </div>
                                            </div>

                                            {/* forcast button */}
                                            <ForcastButton style={forecastbtnbgStyle}/>
                                        </div>

                                    </div>
                                </div>
                            </Spin>
                        </div>
                    </div>
                    :
                    <div className="error_page">
                        <div className="error_content">
                            <span>There is an issue with accessing the page. </span>
                            <span>Please provide the correct token.</span>
                        </div>
                    </div>
            }
        </>


    );
};



export default HistWeatherGreenChart;