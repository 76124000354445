import { MyLocationIcon, SearchIcon } from "@/resource/IconComponents";
import React, { useState } from "react";
import "./index.less";

interface IProps {
    onChange: (value: string) => void
    searchButtonClick: () => void
    locationButtonClicked: () => void
    placeholder?: string
    value?: string
}


const Search = (props: IProps) => {
    const {
        onChange,
        searchButtonClick,
        locationButtonClicked,
        placeholder = "London City Airport",
        value = ""
    } = props;


    return (
        <div className="input-search">
            <input
                value={value}
                onChange={(e) => {
                    onChange(e.target.value);
                }}
                placeholder={placeholder}
                className="ag-input"
            />
            <button onClick={() => {
                searchButtonClick();
            }}>
                <SearchIcon />
            </button>
            <button onClick={() => {
                locationButtonClicked();
            }}>
                <MyLocationIcon />
            </button>
        </div>
    );
};

export default Search;