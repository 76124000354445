import React, { useState,useEffect,useRef } from "react";
import {Checkbox,Divider,Select,Input,Form,Modal,Button} from "antd";
import { useDispatch} from "react-redux";
import { useNavigate } from "react-router-dom";
import {getSignUp} from "@/util/http/Quantis/index";
import { normalActionTypes } from "@/redux/actions/quantisAction";
import Article from "@/page/Quantis/Article";
import "./index.less";

const SignUp = ()=>{
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [updateKey,setUpdateKey] = useState<string>("");
    const [firstName,setFirstName]=useState("");
    const [lastName,setLastName]=useState("");
    const [companyName,setCompanyName]=useState("");
    const [email,setEmail]=useState("");
    const [password,setPassword]=useState("");

    const onFinish = async (values: any) => {
        const resData = {
            firstName,
            lastName,
            companyName,
            email,
            password
        };
        const key = Object.keys(values).filter(e=>e!="confirm"&&e!="regulations"&&e!="regulationsEd");
        key.map((obj:any)=>resData[obj]=values[obj]);

        const res=await getSignUp({
            firstName:resData.firstName,
            lastName:resData.lastName,
            companyName:resData.companyName,
            email:resData.email,
            password:resData.password
        });
        if(res.data=="Success"){
            console.log("Sign Up successfully.");
            // navigate("/visualization/cropstress-login");
            navigate("/cropstress/login");
        }else{
            dispatch({
                type: normalActionTypes.NORMAL_UPDATE_SHOWERRORMESSAGE,
                payload: res.data
            });
        }
    };
    const [showMapModalVisableDesc,setShowMapModalVisableDesc] = useState(false);
    const [numb,setNumb] = useState(10);
    const timer:any = useRef();
    const [exValue,setExValue] = useState(true);
    const { Option } = Select;
    useEffect(() => {
        form.setFieldsValue({
            regulations:false,
            regulationsEd:false,
        });
    }, []);
    const regulation = (e,param)=>{
        setUpdateKey(param);
        console.log(e);
        if(e.target.checked){
            setShowMapModalVisableDesc(true);
            let i = 0;
            timer.current = setInterval(e=>{
                // console.log(numb)
                if(i==10){
                    clearInterval(timer.current);
                    return;
                }
                i++;
                const ex:number = numb-i;
                setNumb(ex);
            },1000);
        }
    };
    const settingHandleOk = ()=>{
        setShowMapModalVisableDesc(false);
        form.setFieldsValue({
            [updateKey]:true
        });
        setNumb(10);
        clearInterval(timer.current);
    };
    const handleCancel = ()=>{
        setShowMapModalVisableDesc(false);
        form.setFieldsValue({
            [updateKey]:false
        });
        setNumb(10);
        clearInterval(timer.current);
    };
    const saveMapModel = ()=>{
        setShowMapModalVisableDesc(false);
        form.setFieldsValue({
            [updateKey]:true
        });
        setNumb(10);
        clearInterval(timer.current);
    };
    const flag="signUp";
    return(
        <div className="sign-up-style">
            <Modal
                className="sign-up-modal"
                title={updateKey=="regulationsEd"?"Terms & Conditions":"Privacy Notice"}
                bodyStyle={{ height: 360, overflow: "auto" }}
                width="90%"
                open={showMapModalVisableDesc}
                onOk={settingHandleOk}
                onCancel={handleCancel}
                // getContainer = {()=> document.getElementById("privacy-notice-container") as HTMLElement}
                footer={[
                    <Button key="close" type="default" onClick={handleCancel}>
                        Close
                    </Button>,
                    <Button key="submit" type="primary" onClick={saveMapModel}>
                        {/* disabled={numb != 0} */}
                        {/* {numb==0?(updateKey=="regulationsEd"?"Accept":"Read"):(updateKey=="regulationsEd"?"Accept":"Read")+numb} */}
                        {updateKey=="regulationsEd"?"Accept":"Read"}
                    </Button>
                ]}
            >
                <Article aletc={updateKey} />
            </Modal>
            <Form
                name="basic"
                onFinish={onFinish}
                form={form}
                layout="vertical"
                autoComplete="off"
            >
                <Divider>
                    <p>Please Sign Up</p>
                </Divider>

                <Form.Item label="First Name" name="firstName"
                    rules={[{ required: true, message: "Please input your First Name!" }]}>
                    <Input placeholder="Input first name"
                        onChange={(e)=>{
                            console.log(e.target.value);
                            setFirstName(e.target.value);
                        }} />
                </Form.Item>
                <Form.Item
                    label="Last Name" name="lastName"
                    rules={[{ required: true, message: "Please input your Last Name!" }]}
                >
                    <Input placeholder="Input last name"
                        onChange={(e)=>{
                            console.log(e.target.value);
                            setLastName(e.target.value);
                        }} />
                </Form.Item>
                <Form.Item label="Company Name " name="companyName"
                    rules={[{ required: true, message: "Please input your Company Name!" }]}>
                    <Input placeholder="Input company name"
                        onChange={(e)=>{
                            console.log(e.target.value);
                            setCompanyName(e.target.value);
                        }} />
                </Form.Item>
                <Form.Item
                    label="Email address" name="email"
                    rules={[
                        { required: true, message: "Please input your Email address!" },
                        {pattern: /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/,message: "Incorrect format!",max:50}
                    ]}
                >
                    <Input placeholder="Input email"
                        onChange={(e)=>{
                            console.log(e.target.value);
                            setEmail(e.target.value);
                        }} />
                </Form.Item>
                <Form.Item
                    label="Password" name="password"
                    rules={[{ required: true, message: "Please input your Password!" },
                        ()=>({
                            validator(_,value){
                                const rx = /^(?![\d]+$)(?![a-zA-Z]+$)(?![^\da-zA-Z]+$).{8,18}$/;
                                if (rx.test(value)){
                                    return Promise.resolve();
                                }else{
                                    return Promise.reject(new Error(`The password strength is not enough. The password must contain letters and numbers,
                                    and the length is greater than 8 and less than 30`));
                                }
                            }
                        })
                    ]}
                >
                    <Input.Password placeholder="Input password"
                        onChange={(e)=>{
                            console.log(e.target.value);
                            setPassword(e.target.value);
                        }} />
                </Form.Item>
                <Form.Item
                    name="confirm"
                    label="Confirm Password"
                    dependencies={["password"]}
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: "Please confirm your password!",
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue("password") === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error("The two passwords that you entered do not match!"));
                            },
                        }),
                    ]}
                >
                    <Input.Password placeholder="Input password again" onChange={(e)=>{
                        console.log(e.target.value);
                    }} />
                </Form.Item>
                <Form.Item>
                    <p>
                        <a>Your use of the Crop Heat Stress Tool will be subject to the terms & conditions. Please take the time to read these via the link below. </a>
                    </p>
                    <p>
                        <a>Please also read the privacy notice (via the link below) which explains how your personal data will be processed.</a>
                    </p>
                </Form.Item>
                <Form.Item name="regulations" valuePropName="checked"
                    rules={[
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if(value){

                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error("Please read the regulations and confirm!"));
                            },
                        })
                    ]}>
                    <Checkbox checked={exValue} onChange={e=>regulation(e,"regulations")}>
                        <a className="term-link">I understand that my personal data will be processed as set out in the privacy notice </a>
                    </Checkbox>
                </Form.Item>
                <Form.Item name="regulationsEd" valuePropName="checked"
                    rules={[
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if(value){
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error("Please read the regulations and confirm!"));
                            },
                        })
                    ]}>
                    <Checkbox checked={exValue} onChange={e=>regulation(e,"regulationsEd")}>
                        <a className="term-link">I accept the terms & conditions </a>
                    </Checkbox>
                </Form.Item>
                <Form.Item>
                    <Button type="primary"
                        htmlType="submit"
                        className="sign-up-button">
                        Sign Up
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default SignUp;