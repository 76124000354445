import React, { useEffect } from "react";
import "./index.less";
import { DewPointFirstIcon, GoundTempIcon, StormProbabilityThirdIcon, TemperatureReduceIcon, TempratureFirstIcon } from "@/resource/IconComponents";
import { IWeatherData } from "@/redux/reducer/commonReducer";
import IF from "@/components/IF";
import { useSelector } from "react-redux";
import { RootState } from "@/redux/reducer/store";

interface IProps {
    weatherData?: {
        [keyValu: string]: string
    }
    isFiveWeatherDay?: boolean
}


const DailyTemperature = (props: IProps) => {

    const {
        currentLanguage
    } = useSelector((state: RootState) => state.commonReducer);

    const {
        weatherData,
        isFiveWeatherDay
    } = props;

    return (
        <div className="daily-container">
            <div className="up-div">
                {currentLanguage.txt_Temperature}
            </div>
            <div className="down-div">
                <IF show={Boolean(weatherData["temperature_max"]) || Boolean(weatherData["temperaturef_max"])}>
                    <div>
                        <div className="icon-div">
                            <TempratureFirstIcon />
                            <label className="number-label">{weatherData["temperature_max"]}</label>
                            <label className="number-label">{weatherData["temperaturef_max"]}</label>
                        </div>
                        <IF show={Boolean(weatherData["temperature_max-name"])}>
                            <div className="text-div">
                                {weatherData["temperature_max-name"]}
                            </div>
                        </IF>
                        <IF show={Boolean(weatherData["temperaturef_max-name"])}>
                            <div className="text-div">
                                {weatherData["temperaturef_max-name"]}
                            </div>
                        </IF>
                    </div>
                </IF>
                <IF show={Boolean(weatherData["temperature_min"]) || Boolean(weatherData["temperaturef_min"])}>
                    <div>
                        <div className="icon-div">
                            <TemperatureReduceIcon />
                            <label className="number-label">{weatherData["temperature_min"]}</label>
                            <label className="number-label">{weatherData["temperaturef_min"]}</label>
                        </div>
                        <IF show={Boolean(weatherData["temperature_min-name"])}>
                            <div className="text-div">
                                {weatherData["temperature_min-name"]}
                            </div>
                        </IF>
                        <IF show={Boolean(weatherData["temperaturef_min-name"])}>
                            <div className="text-div">
                                {weatherData["temperaturef_min-name"]}
                            </div>
                        </IF>
                    </div>
                </IF>
                <IF show={Boolean(weatherData["groundtemp"]) || Boolean(weatherData["groundtemp_f"])}>
                    <div>
                        <div className="icon-div">
                            <GoundTempIcon />
                            <label className="number-label">{weatherData["groundtemp"]}</label>
                            <label className="number-label">{weatherData["groundtemp_f"]}</label>
                        </div>
                        <div className="text-div">
                            {weatherData["groundtemp-name"]}
                            {weatherData["groundtemp_f-name"]}
                        </div>
                    </div>
                </IF>
                <IF show={Boolean(weatherData["dewpoint"])}>
                    <div>
                        <div className="icon-div">
                            <DewPointFirstIcon />
                            <label className="number-label">{weatherData["dewpoint"]}</label>
                        </div>
                        <div className="text-div">
                            {weatherData["dewpoint-name"]}
                        </div>
                    </div>
                </IF>
                <IF show={Boolean(weatherData["dewpointf"])}>
                    <div>
                        <div className="icon-div">
                            <DewPointFirstIcon />
                            <label className="number-label">{weatherData["dewpointf"]}</label>
                        </div>
                        <div className="text-div">
                            {weatherData["dewpointf-name"]}
                        </div>
                    </div>
                </IF>
            </div>
        </div>
    );
};

export default DailyTemperature;