import { MyLocationIcon, SearchIcon } from "@/resource/IconComponents";
import React, { useState } from "react";
import "./index.less";
import IF from "@/components/IF";

interface IProps {
    style?:any
    iconColor?:any
    onChange: (value: string) => void
    searchButtonClick: () => void
    locationButtonClicked?: () => void
    placeholder?: string
    value?: string
    showLocationButton?: boolean
}


const Search = (props: IProps) => {
    const {
        onChange,
        searchButtonClick,
        locationButtonClicked,
        placeholder = "London City Airport",
        value = "",
        showLocationButton=true,
        style,
        iconColor
    } = props;


    return (
        <div className="input-search">
            <input
                value={value}
                onChange={(e) => {
                    onChange(e.target.value);
                }}
                placeholder={placeholder}
                className="ag-input"
            />
            <button style={style} onClick={() => {
                searchButtonClick();
            }}>
                <SearchIcon color={iconColor}/>
            </button>
            <IF show={showLocationButton}>
                <button style={style} onClick={() => {
                    locationButtonClicked();
                }}>
                    <MyLocationIcon color={iconColor}/>
                </button>
            </IF>
        </div>
    );
};

export default Search;