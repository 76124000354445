/* eslint-disable max-len */
import React, { CSSProperties, useCallback, useEffect, useState } from "react";
import "./index.less";

const selectedPosition = {
    lat: "",
    lng: ""
};

interface IPoint{
    lat?: number;
    lng?: number;
    location?: string;
}
interface IProp{
    className?: string;
    lat?: number;
    lng?: number;
    crimeId?: string;
    isSelected?: boolean;
    children?: React.ReactNode;
    mapSelectedLocationCallBack?: (isClick:boolean,locationInfo?:string)=>void
}

const SoilProfileMarker = (props: {
    className?: string,
    lat?: number,
    lng?: number,
    crimeId?: string,
    isSelected: boolean,
    mapSelectedLocationCallBack: (crimeId?:string)=>void,
    initialSelectCallBack: (lat?:any,lng?:any,crimeId?:any)=>void
}) => {
    const [isSelectPoint, setIsSelectPoint] = useState(false);
    const { isSelected, mapSelectedLocationCallBack, initialSelectCallBack } = props;
    const selectedPonint = useCallback((lat, lng, crimeId) => {
        if (!selectedPosition.lat && !selectedPosition.lng) {
            setIsSelectPoint(true);
            selectedPosition.lat = lat;
            selectedPosition.lng = lng;
            if (crimeId) {
                mapSelectedLocationCallBack(crimeId);
                initialSelectCallBack(lat, lng, crimeId);
            }
        } else if (selectedPosition.lat && selectedPosition.lng && lat && lng) {
            if (Number(selectedPosition.lat) === Number(lat) && Number(selectedPosition.lng) === Number(lng)) {
                setIsSelectPoint(false);
                selectedPosition.lat = "";
                selectedPosition.lng = "";
                mapSelectedLocationCallBack();
                initialSelectCallBack();
            } else {
                setIsSelectPoint(true);
                selectedPosition.lat = lat;
                selectedPosition.lng = lng;
                if (crimeId) {
                    mapSelectedLocationCallBack(crimeId);
                    initialSelectCallBack(lat, lng, crimeId);
                }
            }
        }
    }, []);
    useEffect(() => {
        setIsSelectPoint(isSelected);
        console.log(`=========isSelected=========${isSelected}`);
    });
    return (
        <svg onClick={() => selectedPonint(props.lat, props.lng, props.crimeId)} className={props.className} width="42" height="52" viewBox="0 0 42 52" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M15.0765 41.153C6.36274 38.5959 0 30.541 0 21C0 9.40202 9.40202 0 21 0C32.598 0 42 9.40202 42 21C42 30.541 35.6373 38.5959 26.9235 41.153L21.8944 51.2111C21.5259 51.9482 20.4741 51.9482 20.1056 51.2111L15.0765 41.153Z" fill="white" />
            <path fillRule="evenodd" clipRule="evenodd" d="M21 39C30.9411 39 39 30.9411 39 21C39 11.0589 30.9411 3 21 3C11.0589 3 3 11.0589 3 21C3 30.9411 11.0589 39 21 39Z" fill={isSelectPoint ? "#EE5B3A" : "#59B948"} />
            <path fillRule="evenodd" clipRule="evenodd" d={isSelectPoint ? "M18.7951 24.8769L14.6251 20.7069L13.2051 22.1169L18.7951 27.7069L30.7951 15.7069L29.3851 14.2969L18.7951 24.8769Z" : "M21 26C23.7614 26 26 23.7614 26 21C26 18.2386 23.7614 16 21 16C18.2386 16 16 18.2386 16 21C16 23.7614 18.2386 26 21 26Z"} fill="white" />
        </svg>
    );
};

const SoilSearchProfileCount = (props: { style?: CSSProperties, className?: string, lat?: number, lng?: number, onClick?: (e) => void, children?: React.ReactNode }) => (
    <div>
        <svg onClick={(e) => { if (props.onClick) { props.onClick(e); } }} style={props.style} width="42" height="52" viewBox="0 0 42 52" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M15.0765 41.153C6.36274 38.5959 0 30.541 0 21C0 9.40202 9.40202 0 21 0C32.598 0 42 9.40202 42 21C42 30.541 35.6373 38.5959 26.9235 41.153L21.8944 51.2111C21.5259 51.9482 20.4741 51.9482 20.1056 51.2111L15.0765 41.153Z" fill="white" />
            <path fillRule="evenodd" clipRule="evenodd" d="M21 39C30.9411 39 39 30.9411 39 21C39 11.0589 30.9411 3 21 3C11.0589 3 3 11.0589 3 21C3 30.9411 11.0589 39 21 39Z" fill="#59B948" />
        </svg>
        {props.children}
    </div>
);



const SimulationZoomOut = (prop: IProp)=>{
    const {className} = prop;
    return(
        <svg className={className} width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14 8H8V14H6V8H0V6H6V0H8V6H14V8Z" fill="#696F88" />
        </svg>
    );
};

const SimulationZoomIn = (prop: IProp)=>{
    const {className} = prop;
    return(
        <svg className={className} width="14" height="2" viewBox="0 0 14 2" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14 2H0V0H14V2Z" fill="#696F88" />
        </svg>
    );
};

const SimulationCurrentLocationMap = (prop: IProp)=>{
    const {className} = prop;
    return(
        <svg className={className} width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11 7C8.79 7 7 8.79 7 11C7 13.21 8.79 15 11 15C13.21 15 15 13.21 15 11C15 8.79 13.21 7 11 7ZM19.94 10C19.48 5.83 16.17 2.52 12 2.06V0H10V2.06C5.83 2.52 2.52 5.83 2.06 10H0V12H2.06C2.52 16.17 5.83 19.48 10 19.94V22H12V19.94C16.17 19.48 19.48 16.17 19.94 12H22V10H19.94ZM11 18C7.13 18 4 14.87 4 11C4 7.13 7.13 4 11 4C14.87 4 18 7.13 18 11C18 14.87 14.87 18 11 18Z" fill="#707374" />
        </svg>
    );
};

// simulation count marker
const SimulationProfileCount = (prop: IProp)=>{
    const {
        lat,
        lng,
        children,
        className
    } = prop;
    return(
        <div className={className}>
            <svg width="42" height="52" viewBox="0 0 42 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M15.0765 41.153C6.36274 38.5959 0 30.541 0 21C0 9.40202 9.40202 0 21 0C32.598 0 42 9.40202 42 21C42 30.541 35.6373 38.5959 26.9235 41.153L21.8944 51.2111C21.5259 51.9482 20.4741 51.9482 20.1056 51.2111L15.0765 41.153Z" fill="white" />
                <path fillRule="evenodd" clipRule="evenodd" d="M21 39C30.9411 39 39 30.9411 39 21C39 11.0589 30.9411 3 21 3C11.0589 3 3 11.0589 3 21C3 30.9411 11.0589 39 21 39Z" fill="#59B948" />
            </svg>
            {children}
        </div>
    );
};

// simulation selected marker, is show red color
const SimulationSelectedMarker = (prop: IProp)=>{
    const {className} = prop;
    return(
        <svg className={className} width="42" height="52" viewBox="0 0 42 52" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M15.0765 41.153C6.36274 38.5959 0 30.541 0 21C0 9.40202 9.40202 0 21 0C32.598 0 42 9.40202 42 21C42 30.541 35.6373 38.5959 26.9235 41.153L21.8944 51.2111C21.5259 51.9482 20.4741 51.9482 20.1056 51.2111L15.0765 41.153Z" fill="white" />
            <path fillRule="evenodd" clipRule="evenodd" d="M21 39C30.9411 39 39 30.9411 39 21C39 11.0589 30.9411 3 21 3C11.0589 3 3 11.0589 3 21C3 30.9411 11.0589 39 21 39Z" fill="#EE5B3A" />
            <path d="M18.7951 24.8769L14.6251 20.7069L13.2051 22.1169L18.7951 27.7069L30.7951 15.7069L29.3851 14.2969L18.7951 24.8769Z" fill="white" />
        </svg>
    );
};

// simulation marker
const SimulationProfileMarker = (props: IProp) => {
    const {
        lat,
        lng,
        className,
        isSelected,
        crimeId,
        mapSelectedLocationCallBack
    } = props;
    // const [isSelectPoint, setIsSelectPoint] = useState(false);

    return (
        <div
            style={{
                // display:"float",
                width:"42px",
                height:"52px",
                float:"left"
            }}
            onClick={()=>{
                if(isSelected){
                    mapSelectedLocationCallBack(false,null);
                }
                else{
                    mapSelectedLocationCallBack(true,crimeId);
                }

            }}
        >
            <svg className={className} width="42" height="52" viewBox="0 0 42 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M15.0765 41.153C6.36274 38.5959 0 30.541 0 21C0 9.40202 9.40202 0 21 0C32.598 0 42 9.40202 42 21C42 30.541 35.6373 38.5959 26.9235 41.153L21.8944 51.2111C21.5259 51.9482 20.4741 51.9482 20.1056 51.2111L15.0765 41.153Z" fill="white" />
                <path fillRule="evenodd" clipRule="evenodd" d="M21 39C30.9411 39 39 30.9411 39 21C39 11.0589 30.9411 3 21 3C11.0589 3 3 11.0589 3 21C3 30.9411 11.0589 39 21 39Z" fill={isSelected ? "#EE5B3A" : "#59B948"} />
                <path fillRule="evenodd" clipRule="evenodd" d={isSelected ? "M18.7951 24.8769L14.6251 20.7069L13.2051 22.1169L18.7951 27.7069L30.7951 15.7069L29.3851 14.2969L18.7951 24.8769Z" : "M21 26C23.7614 26 26 23.7614 26 21C26 18.2386 23.7614 16 21 16C18.2386 16 16 18.2386 16 21C16 23.7614 18.2386 26 21 26Z"} fill="white" />
            </svg>
        </div>
    );
};

// disease map download svg
const DiseaseMapDownloadSvg = (prop:{
    theme:string
})=>{
    const {theme} = prop;
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M3 15.375L15 15.375L15 16.875L3 16.875L3 15.375ZM9.75 11.7525L12.4425 9.0675L13.5 10.125L9 14.625L4.5 10.125L5.5575 9.06L8.25 11.7525L8.25 1.125L9.75 1.125L9.75 11.7525Z"
                fill={theme === "dark" ? "white" : "#696F88"}/>
        </svg>

    );
};


export {
    SimulationProfileCount,
    SimulationZoomOut,
    SimulationZoomIn,
    SimulationCurrentLocationMap,
    SimulationSelectedMarker,
    SimulationProfileMarker,
    DiseaseMapDownloadSvg
};