// import echarts from "echarts/types/dist/echarts";
import { IGetHistWeatherChatInfoResModel } from "@/util/http/HistWeather/responseType";
import dayjs from "dayjs";
import * as echarts from "echarts";
import React, { useEffect, useRef, useState } from "react";


const CountryDateFormat = {
    "AE": "DD/MM/YYYY",
    "AU": "DD/MM/YYYY",
    "BY": "DD.MM.Y",
    // "CH": "dd.mm.yy",
    "CH": "MM/DD/YYYY",
    "DE": "DD.MM.YYYY",
    "NZ": "dd-mm-yyyy",
    "US": "MM/DD/YYYY",
    "ES": "DD/MM/YYYY",
    "FR": "DD/MM/YYYY",
    "GB": "DD/MM/YYYY",
    "IL": "DD/MM/YYYY",
    "JP": "YYYY/MM/DD",
    "KR": "YYYY-MM-DD",
    "PL": "DD.MM.YYYY",
    "PT": "DD-MM-YYYY",
    "SE": "YYYY-MM-DD",
    "SI": "DD.MM.YYYY",
    "TR": "DD.MM.YYYY",
    "ZA": "dd/mm/yyyy",
    "NO": "DD/MM/YYYY",
    "FI": "DD/MM/YYYY",
    "DK": "DD/MM/YYYY",
};
interface IProp{
    type?: string;
    title: string;
    echartData?: IGetHistWeatherChatInfoResModel;
    colorArray: string[];
    idArray: string[];
    legendArray: string[];
    yName?: string;
    yNameForRain?: string;
    width?: number;
    countrycode?: string;
    echartTitle?: string;
    diseaseShowOptions?: any[]
}

const EchartRender = (prop:IProp)=>{

    const {
        type,
        title,
        echartData,
        colorArray,
        idArray,
        legendArray,
        yName,
        yNameForRain,
        width,
        countrycode,
        echartTitle,
        diseaseShowOptions
    } = prop;
    console.log("echart render type",type);
    console.log("echart render width",width);
    console.log("lanxue echart render echartData",echartData);
    console.log("echart render colorArray",colorArray);
    console.log("echart render idArray",idArray);
    console.log("lanxue echart render legendArray",legendArray);
    const [echartContainerHeight,setEchartContainerHeight] = useState(430);

    const chartRef = useRef<HTMLDivElement>(null);
    const options = {
        grid: {
            show: true,
            containLabel: true,
            left: 50,
            top: 40,
            width: width ? width -20 -50: 800 -50,
            height: 350
        },
        color: colorArray,
        xAxis: {
            data: [],
            type: "category",
            splitNumber: 14,
            position: "top",
            axisLine: {
                //show: false,
                lineStyle: {
                    color: "#A3A9B9",
                    type: "solid"
                }
            },
            axisTick: {
                show: false,
            },
            splitLine: {
                show: true,
                //interval: 0,
                lineStyle: {
                    type: "solid",
                    color: "#A3A9B9"
                }
            },
            axisLabel: {
                show: true,
                //interval: 1,
            }
        },
        yAxis: {
            type: "value",
            name: "",
            nameLocation: "center",
            //nameGap: 40,
            // splitNumber: 9,
            // min: 0,
            // max: 12,
            position: "left" ,
            nameTextStyle: {
                color: "#14151C",
                fontSize: 12,
                fontfamily: "Noto-Sans-Regular"
            },
            axisLabel: {
                show: true,
                interval: 0,
            },
            axisLine: {
                show: true, // 显示轴线
                lineStyle: {
                    type: "solid",
                    color: "#A3A9B9"
                }
            },
            axisTick: {
                show: false, // 隐藏刻度线
                alignWithLabel: true
            },
            data: [], // 刻度线对应的值
            splitLine: {
                show: true,
                //interval: 0.5,
                lineStyle: {
                    type: "dashed",
                    color: "#A3A9B9"
                }
            }
        },
        series: [],
        tooltip: {
            trigger: "axis",
            backgroundColor: "white"
        },
        textStyle: {
            fontFamily: "Noto-Sans-Regular",
            fontSize: 12,
            fontWeight: 400
        },
    };

    const getXAxisData = ():string[] =>{
        const tmpXAxisData = [];
        const formatStr = CountryDateFormat[countrycode] || "DD/MM/YYYY";
        echartData?.lstResultseries[0]?.date?.forEach((item)=>{
            tmpXAxisData.push(dayjs(item).format(formatStr));
        });
        return tmpXAxisData;
    };
    // set left width
    const getLeftWidth = (isYAxisWidth?:boolean):number=>{
        let tmpWidth = 25;
        if(isYAxisWidth){
            if(width <= 1023) tmpWidth = 20;
            else tmpWidth = 40;
        }
        else{
            if(width <= 1023) tmpWidth = 25;
            else tmpWidth = 50;
        }
        return tmpWidth;
    };

    const changeEchartContainerHeight = ()=>{
        let tmpHeight = 430;
        if(width <= 425){
            if(idArray.length <= 1){
                tmpHeight = 435;
            }
            else{
                const addHeight = (idArray.length -1 ) * 25;
                tmpHeight = tmpHeight + addHeight;
            }
        }
        else if(width > 425 && width < 800){
            if(idArray.length >= 3){
                const tmpCount = Math.ceil((idArray.length -3) / 2);
                const addHeight = tmpCount * 25;
                tmpHeight = tmpHeight + addHeight;
            }
        }
        else if(width >= 800){
            if(idArray.length >= 5){
                const tmpCount = Math.ceil((idArray.length -4) / 3);
                const addHeight = tmpCount * 25;
                tmpHeight = tmpHeight + addHeight;
            }
        }
        setEchartContainerHeight(tmpHeight);
    };

    useEffect(()=>{
        if(idArray?.length > 0){
            changeEchartContainerHeight();
        }
    },[idArray]);


    console.log("lanxue echart diseaseShowOptions",diseaseShowOptions);

    // set Option
    const changeEchartOption = ()=>{
        changeEchartContainerHeight();
        const tmpNewSeriesName = legendArray.map((item,index)=>{
            let tmp = "";
            if(item === diseaseShowOptions[index]?.text &&
                 diseaseShowOptions[index]?.id === "node3_cb_PrecipAmount_mm_DailySumUser"){

                const regex = /(\d+)\D+(\d+)/;
                const match = echartData?.lstResultseries[index]?.legendSupplement?.match(regex);
                // const tmpLegent = echartData?.lstResultseries[index]?.legendSupplement?.split("-");
                if(match && match.length === 3){
                    const [_, startYear, endYear] = match;
                    tmp = item + startYear;
                }
                else{
                    tmp = item + echartData?.lstResultseries[index]?.legendSupplement;
                }
            }
            else{
                tmp = item + " " + echartData?.lstResultseries[index]?.legendSupplement;
            }
            return tmp;
        });

        const yData = [-5,-3,-1,1,3,5,7,9,11,13,15,17,19,21]; // y轴的刻度值
        const xData = getXAxisData(); // x轴的刻度值
        const options = {
            color: colorArray,
            grid: {
                show: true,
                containLabel: true,
                left: getLeftWidth(),
                top: 10,
                width: width ? width -20 -50 - 10: 800 -50,
                // height: gridHeight
                height: 370
                // height: gridHeight
            },
            xAxis: {
                data: xData,
                type: "category",
                splitNumber: 14,
                axisLine: {
                    //show: false,
                    lineStyle: {
                        color: "#A3A9B9",
                        type: "solid"
                    }
                },
                axisTick: {
                    show: false,
                },
                splitLine: {
                    show: true,
                    //interval: 0,
                    lineStyle: {
                        type: "solid",
                        color: "#A3A9B9"
                    }
                },
                axisLabel: {
                    show: true,
                    //interval: 1,
                }
            },
            yAxis: {
                type: "value",
                name: yName,
                nameLocation: "center",
                nameGap: getLeftWidth(true),
                nameTextStyle: {
                    color: "#14151C",
                    fontSize: 12,
                    //fontWeight: 400,
                    fontfamily: "Noto-Sans-Regular"
                },
                axisLabel: {
                    show: true,
                    interval: 0,
                },
                axisLine: {
                    show: true, // 显示轴线
                    lineStyle: {
                        type: "solid",
                        color: "#A3A9B9"
                    }
                },
                axisTick: {
                    show: false, // 隐藏刻度线
                    //interval: 2,
                    alignWithLabel: true
                },
                data: yData, // 刻度线对应的值
                splitLine: {
                    show: true,
                    interval: 0,
                    lineStyle: {
                        type: "dashed",
                        color: "#A3A9B9"
                    }
                }
            },
            series: (()=>{
                const tmpData = [];
                echartData?.lstResultseries?.forEach((item,index)=>{
                    const tmpType = type === "we_tempandrain_rainfall" || type === "we_precipitation" ? "bar" : "line";
                    // const tmpName = legendArray[index];
                    const tmpName = tmpNewSeriesName[index];
                    tmpData.push({
                        // name: item?.legend, tmpNewSeriesName
                        name: tmpName,
                        type: tmpType,
                        smooth: true,
                        symbol: "none",
                        data: item?.values
                    });
                });
                return tmpData;
            })(),
            tooltip: {
                trigger: "axis",
                backgroundColor: "white"
            },
            textStyle: {
                fontFamily: "Noto-Sans-Regular",
                fontSize: 12,
                fontWeight: 400
            },
            title: {
                text: echartTitle,
                top: 385,
                left: getLeftWidth(),
                textStyle: {
                    fontFamily: "Noto-Sans-Regular",
                    fontSize: 14,
                    fontWeight: 600,
                },
            },
            legend: {
                data: tmpNewSeriesName,
                selected: (()=>{
                    let tmp = {};
                    tmpNewSeriesName?.forEach((item)=>{
                        tmp = {...tmp,item:true};
                    });
                })(),
                left: getLeftWidth(),
                top: 410
            }
        };
        // chart.setOption(options);
        return options;
    };

    const setYAxis = ()=>{
        const yData = [-5,-3,-1,1,3,5,7,9,11,13,15,17,19,21]; // y轴的刻度值
        const tmp = [];
        let tmpFirstName = "";
        let tmpYAxisCount = 1;
        if(idArray.length === 1){
            if(idArray[0] === "node0_cb_PrecipAmount_mm_DailySum"){
                tmpFirstName = yNameForRain?.split("&")[1];
            }
            else{
                tmpFirstName = yNameForRain?.split("&")[0];
            }
        }
        else if(idArray?.length >= 2){
            const tmpValue = idArray.filter(item => item === "node0_cb_PrecipAmount_mm_DailySum");
            if(tmpValue?.length >=1){
                tmpYAxisCount = 2;
                tmpFirstName = yNameForRain?.split("&")[0];
            }
            else{
                tmpFirstName = yNameForRain?.split("&")[0];
            }
        }
        const yAxisLeft = {
            type: "value",
            name: tmpFirstName,
            nameLocation: "center",
            position: "left",
            nameGap: getLeftWidth(true),
            nameTextStyle: {
                color: "#14151C",
                fontSize: 12,
                //fontWeight: 400,
                fontfamily: "Noto-Sans-Regular"
            },
            axisLabel: {
                show: true,
                interval: 0,
            },
            axisLine: {
                show: true, // 显示轴线
                lineStyle: {
                    type: "solid",
                    color: "#A3A9B9"
                }
            },
            axisTick: {
                show: false, // 隐藏刻度线
                //interval: 2,
                alignWithLabel: true
            },
            data: yData, // 刻度线对应的值
            splitLine: {
                show: true,
                interval: 0,
                lineStyle: {
                    type: "dashed",
                    color: "#A3A9B9"
                }
            }
        };
        const yAxisRight = {
            type: "value",
            name: yNameForRain?.split("&")[1],
            nameLocation: "center",
            position: "right",
            nameGap: getLeftWidth(true),
            nameTextStyle: {
                color: "#14151C",
                fontSize: 12,
                //fontWeight: 400,
                fontfamily: "Noto-Sans-Regular"
            },
            axisLabel: {
                show: true,
                interval: 0,
            },
            axisLine: {
                show: true, // 显示轴线
                lineStyle: {
                    type: "solid",
                    color: "#A3A9B9"
                }
            },
            axisTick: {
                show: false, // 隐藏刻度线
                //interval: 2,
                alignWithLabel: true
            },
            data: yData, // 刻度线对应的值
            splitLine: {
                show: true,
                interval: 0,
                lineStyle: {
                    type: "dashed",
                    color: "#A3A9B9"
                }
            }
        };
        if(tmpYAxisCount === 1){
            tmp.push(yAxisLeft);
        }
        else{
            tmp.push(yAxisLeft);
            tmp.push(yAxisRight);
        }
        return {
            Count: tmpYAxisCount,
            yAxisArray: tmp
        };
    };
    const changeEchartOptionForHaveRain = ()=>{
        changeEchartContainerHeight();
        const tmpNewSeriesName = legendArray;
        const tmpYName = yNameForRain?.split("&");

        const yData = [-5,-3,-1,1,3,5,7,9,11,13,15,17,19,21]; // y轴的刻度值
        const xData = getXAxisData(); // x轴的刻度值
        console.log("echart render set ",setYAxis());
        const {Count,yAxisArray} = setYAxis();
        const options = {
            color: colorArray,
            grid: {
                show: true,
                containLabel: true,
                left: getLeftWidth(),
                top: 10,
                width: width ? width -20 -50 - 10: 800 -50,
                // height: gridHeight
                height: 370
                // height: gridHeight
            },
            xAxis: {
                data: xData,
                type: "category",
                splitNumber: 14,
                axisLine: {
                    //show: false,
                    lineStyle: {
                        color: "#A3A9B9",
                        type: "solid"
                    }
                },
                axisTick: {
                    show: false,
                },
                splitLine: {
                    show: true,
                    //interval: 0,
                    lineStyle: {
                        type: "solid",
                        color: "#A3A9B9"
                    }
                },
                axisLabel: {
                    show: true,
                    //interval: 1,
                }
            },
            yAxis: yAxisArray,
            series: (()=>{
                const tmpData = [];
                echartData?.lstResultseries?.forEach((item,index)=>{
                    // const tmpType = type === "we_tempandrain_rainfall" || type === "we_precipitation" ? "bar" : "line";
                    const tmpType = idArray[index] === "node0_cb_PrecipAmount_mm_DailySum" ? "bar" : "line";
                    // const tmpName = legendArray[index];
                    const tmpName = tmpNewSeriesName[index];
                    tmpData.push({
                        // name: item?.legend, tmpNewSeriesName
                        name: tmpName,
                        type: tmpType,
                        smooth: true,
                        symbol: "none",
                        data: item?.values,
                        yAxisIndex: (Count === 2 && idArray[index] === "node0_cb_PrecipAmount_mm_DailySum") ? 1 : 0
                    });
                });
                return tmpData;
            })(),
            tooltip: {
                trigger: "axis",
                backgroundColor: "white"
            },
            textStyle: {
                fontFamily: "Noto-Sans-Regular",
                fontSize: 12,
                fontWeight: 400
            },
            title: {
                text: echartTitle,
                top: 385,
                left: getLeftWidth(),
                textStyle: {
                    fontFamily: "Noto-Sans-Regular",
                    fontSize: 14,
                    fontWeight: 600,
                },
            },
            legend: {
                data: tmpNewSeriesName,
                selected: (()=>{
                    let tmp = {};
                    tmpNewSeriesName?.forEach((item)=>{
                        tmp = {...tmp,item:true};
                    });
                })(),
                left: getLeftWidth(),
                top: 410
            }
        };
        // chart.setOption(options);
        return options;
    };


    useEffect(() => {
        const chart = echarts.init(chartRef.current);
        let tmpOptions = null;
        if(echartData?.lstResultseries?.length > 0){
            console.log("ddd",echartData);
            console.log("ddd",echartData.lstResultseries);
            if(type === "we_tempandrain"){
                tmpOptions = changeEchartOptionForHaveRain();
            }
            else{
                tmpOptions = changeEchartOption();
            }
        }
        else{
            tmpOptions = options;
        }
        chart.setOption(tmpOptions);
        console.log("echart render options",tmpOptions);
        return ()=>{
            chart.dispose();
        };
    }, [type,echartData]);
    return(
        <div
            ref={chartRef}
            style={{width:`${width ? width - 20 : 800}px`,height:`${echartContainerHeight}px`}}></div>
    );
};

export default React.memo(EchartRender);