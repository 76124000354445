/* eslint-disable max-len */
import React from "react";
import "./index.less";
// import image
import NoRiskImage from "@/resource/images/svg/diseaseMap/no_risk.svg";
import LowRiskImage from "@/resource/images/svg/diseaseMap/low_risk.svg";
import MediumRiskImage from "@/resource/images/svg/diseaseMap/mefium_risk.svg";
import HighRiskImage from "@/resource/images/svg/diseaseMap/high_risk.svg";
import VeryHighRiskImage from "@/resource/images/svg/diseaseMap/very_high_risk.svg";
import { RootState } from "@/redux/reducer/store";
import { useSelector } from "react-redux";
import IF from "@/components/IF";

interface IProp{
    title?: string;
    disease?: string;
}

const RiskPicAndNotes = (props:IProp) => {
    const {title,disease} = props;
    const {
        diseaseData,
    } = useSelector((state: RootState) => state.diseaseReducer);
    console.log("lanxue disease",disease);
    return (
        <div className="diseasemap-riskAndNotes">
            <div className="risk-title theme-color">
                {/* Disease Risk Color Legend */}
                { title || "Disease Risk Color Legend"}
            </div>
            {/* basic note */}
            <IF show={disease !== "SoilTemperatureRisk"}>
                <div className="image-list">
                    <div className="image-list-item">
                        <img src={NoRiskImage} />
                        <span className="theme-color">{diseaseData?.disRiskLegend[0]?.textDescription || "No Risk"}</span>
                    </div>
                    <div className="image-list-item">
                        <img src={LowRiskImage} />
                        <span className="theme-color">{diseaseData?.disRiskLegend[1]?.textDescription || "Low Risk"}</span>
                    </div>
                    <div className="image-list-item">
                        <img src={MediumRiskImage} />
                        <span className="theme-color">{diseaseData?.disRiskLegend[2]?.textDescription || "Medium Risk"}</span>
                    </div>
                    <div className="image-list-item">
                        <img src={HighRiskImage} />
                        <span className="theme-color">{diseaseData?.disRiskLegend[3]?.textDescription || "Low Risk"}</span>
                    </div>
                    <div className="image-list-item">
                        <img src={VeryHighRiskImage} />
                        <span className="theme-color">{diseaseData?.disRiskLegend[4]?.textDescription || "Very High Risk"}</span>
                    </div>
                </div>
            </IF>
            {/* Soil Tempreture Risk  */}
            <IF show={disease === "SoilTemperatureRisk"}>
                <div className="image-list">
                    <div className="image-list-item">
                        <SoilRiskSvg color="#320097"/>
                        <span className="theme-color">{"< 4"}</span>
                    </div>
                    <div className="image-list-item">
                        <SoilRiskSvg color="#01f3ff40"/>
                        <span className="theme-color">{"5-7"}</span>
                    </div>
                    <div className="image-list-item">
                        <SoilRiskSvg color="#00FEF6"/>
                        <span className="theme-color">{"8-10"}</span>
                    </div>
                    <div className="image-list-item">
                        <SoilRiskSvg color="#66F196"/>
                        <span className="theme-color">{"11-13"}</span>
                    </div>
                    <div className="image-list-item">
                        <SoilRiskSvg color="#FFDB00"/>
                        <span className="theme-color">{"14-16"}</span>
                    </div>
                    <div className="image-list-item">
                        <SoilRiskSvg color="#FF7903"/>
                        <span className="theme-color">{"> 17"}</span>
                    </div>
                </div>
            </IF>
            <div className="image-bottom"></div>
        </div>
    );
};

export default RiskPicAndNotes;

interface IRiskProp{
    color: string;
}
const SoilRiskSvg = (prop:IRiskProp)=>{
    const {color} = prop;
    return(
        <svg width="44" height="54" viewBox="0 0 44 54" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M16.0765 42.153C7.36274 39.5959 1 31.541 1 22C1 10.402 10.402 1 22 1C33.598 1 43 10.402 43 22C43 31.541 36.6373 39.5959 27.9235 42.153L22.8944 52.2111C22.5259 52.9482 21.4741 52.9482 21.1056 52.2111L16.0765 42.153Z" fill="white"/>
            <path d="M16.0765 42.153L16.5237 41.9294L16.4263 41.7345L16.2173 41.6732L16.0765 42.153ZM27.9235 42.153L27.7827 41.6732L27.5737 41.7345L27.4763 41.9294L27.9235 42.153ZM21.1056 52.2111L20.6584 52.4348L21.1056 52.2111ZM16.2173 41.6732C7.71067 39.1769 1.5 31.3131 1.5 22H0.5C0.5 31.7689 7.01481 40.0149 15.9357 42.6327L16.2173 41.6732ZM1.5 22C1.5 10.6782 10.6782 1.5 22 1.5V0.5C10.1259 0.5 0.5 10.1259 0.5 22H1.5ZM22 1.5C33.3218 1.5 42.5 10.6782 42.5 22H43.5C43.5 10.1259 33.8741 0.5 22 0.5V1.5ZM42.5 22C42.5 31.3131 36.2893 39.1769 27.7827 41.6732L28.0643 42.6327C36.9852 40.0149 43.5 31.7689 43.5 22H42.5ZM23.3416 52.4348L28.3707 42.3766L27.4763 41.9294L22.4472 51.9875L23.3416 52.4348ZM20.6584 52.4348C21.2111 53.5403 22.7889 53.5403 23.3416 52.4348L22.4472 51.9875C22.263 52.3561 21.737 52.3561 21.5528 51.9875L20.6584 52.4348ZM15.6293 42.3766L20.6584 52.4348L21.5528 51.9875L16.5237 41.9294L15.6293 42.3766Z" fill="black" fillOpacity="0.25"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M22 40C31.9411 40 40 31.9411 40 22C40 12.0589 31.9411 4 22 4C12.0589 4 4 12.0589 4 22C4 31.9411 12.0589 40 22 40Z" fill={color} stroke="black" strokeOpacity="0.25"/>
        </svg>
    );
};
