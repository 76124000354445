import React, { useState,useEffect } from "react";
import { Input, Form, Button,Divider,Row,Col,notification } from "antd";
import { useDispatch} from "react-redux";
import { useNavigate } from "react-router-dom";
import {getSendPasswordResetEmail,getResetPassword} from "@/util/http/Quantis/index";
import "./index.less";
import { normalActionTypes } from "@/redux/actions/quantisAction";

const ForgetPassword = ()=>{
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading,useLoading] = useState(false);
    const [countDown,useCountDown] = useState(60);
    const [count,useCount] = useState(undefined);
    const [form] = Form.useForm();
    const [email,setEmail]=useState("");
    const [password,setPassword]=useState("");
    const [verificationCode,setVerificationCode]=useState("");

    const onFinish = async (values: any) => {
        console.log("values",values);
    };
    const runGetCaptchaCountDown = () => {
        let counts:any = countDown || 59;
        useCount(counts);
        const interval = setInterval(() => {
            counts -= 1;
            useCount(counts);
            if (counts === 0) {
                clearInterval(interval);
            }
        }, 1000);
    };
    const onVerificationButtonClick = async()=>{
        if(email){
            const res = await getSendPasswordResetEmail(email);
            runGetCaptchaCountDown();
            if(res.data=="Success"){
                console.log("Verification Code sent successfully.");
            }else{
                dispatch({
                    type: normalActionTypes.NORMAL_UPDATE_SHOWERRORMESSAGE,
                    payload: "Email account is not exist"
                });
            }
        }else{
            dispatch({
                type: normalActionTypes.NORMAL_UPDATE_SHOWERRORMESSAGE,
                payload: "The email field is required."
            });
        }
    };
    const changPassword = async()=>{
        if (email.trim() && verificationCode.trim() && password.trim()){
            const res = await getResetPassword({
                email: email,
                resetCode: verificationCode,
                newPassword:password
            });
            if(res.data=="Success"){
                // navigate("/visualization/cropstress-login");
                navigate("/cropstress/login");
            }else{
                dispatch({
                    type: normalActionTypes.NORMAL_UPDATE_SHOWERRORMESSAGE,
                    payload: res.data
                });
            }
        }
        else
        {
            return;
        }
    };
    return(
        <div className="forget-password-style">
            <Form
                name="basic"
                labelCol={{ span:10 }}
                form={form}
                onFinish={onFinish}
                layout="vertical"
                autoComplete="off"
            >
                <Divider>
                    <p>Retrieve Password</p>
                </Divider>

                <Form.Item
                    name="email"
                    label="Email address"
                    rules={[
                        { required: true, message: "Please input your Email address!" },
                        {pattern: /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/,message: "Incorrect format!",max:50}
                    ]}
                >
                    <Input placeholder="Email" onChange={(e)=>{
                        setEmail(e.target.value);
                    }} />
                </Form.Item>


                <Form.Item>
                    <Row gutter={8}>
                        <Col span={14}>
                            <Form.Item
                                name="resetCode"
                                label="Verification Code"
                                rules={[{ required: true, message: "Please Enter Verification Code from Email!" }]}
                            >
                                <Input
                                    placeholder="Verification Code"
                                    onChange={
                                        (e)=>{
                                            //console.log(e.target.value);
                                            setVerificationCode(e.target.value);
                                        }
                                    }
                                />
                            </Form.Item>
                        </Col>
                        <Col span={10}>
                            <Button
                                type="primary"
                                className={undefined == count || 0 == count ? "verification-code-button" : "disabled-verification-code-button"}
                                onClick={onVerificationButtonClick}
                                loading={loading}
                                disabled={
                                    undefined != count && 0 != count
                                }
                            >
                                {undefined == count || 0 == count ? "Verification Code" : count+"second"}
                            </Button>
                        </Col>
                    </Row>
                </Form.Item>
                <Form.Item
                    name="newPassword"
                    label="New Password"
                    rules={[{ required: true, message: "Please input your Password!" },
                        ()=>({
                            validator(_,value){
                                const rx = /^(?![\d]+$)(?![a-zA-Z]+$)(?![^\da-zA-Z]+$).{8,18}$/;
                                if (rx.test(value)){
                                    return Promise.resolve();
                                }else{
                                    return Promise.reject(new Error(`The password strength is not enough. The password must contain letters and numbers, 
                        and the length is greater than 8 and less than 30`));
                                }
                            }
                        })
                    ]}
                >
                    <Input.Password placeholder="new password"
                        onChange={(e)=>{
                            setPassword(e.target.value);
                        }} />
                </Form.Item>
                <Form.Item
                    name="confirm"
                    label="Confirm Password"
                    dependencies={["password"]}
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: "Please confirm your password!",
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue("newPassword") === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error("The two passwords that you entered do not match!"));
                            },
                        }),
                    ]}
                >
                    <Input.Password />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" className="change-password-button" onClick={changPassword}>
                        Change Password
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default ForgetPassword;