/* eslint-disable max-len */
import React, { ReactNode, useEffect, useReducer, useRef, useState } from "react";
import "./index.less";
import { useDispatch, useSelector } from "react-redux";
import { sagaActionTypes } from "@/redux/actions/commonAction";
import { Dropdown, Input, List, MenuProps, Modal, Select, Space, Spin, Tabs, TabsProps } from "antd";
import { BookMarkIcon} from "@/resource/IconComponents";
import { RootState } from "@/redux/reducer/store";
import { IHistDiseaseLstSeriesModel, ILocationModel } from "@/util/http/responseType";
import { useLocation } from "react-router-dom";
import {Eamil_Svg,Download_Svg,
    Risk_Color_Svg,
    Risk_Color_Svg_2,
    Risk_Color_Svg_3,
} from "./components/HistoricalWeatherDataIcon";
// import DateChange from "./components/DateChange";
import HistRangePicker from "@/components/HistRangePicker";
import dayjs from "dayjs";
import {initListDisease} from "./components/initData/initHistDiseaseData";
import UnitComponent from "./components/UintComponents";
import EchartRander from "./components/EchartRander";
import html2canvas from "html2canvas";
import useSeries from "./components/SelfHooks/useSeries";
import useAggretation from "./components/SelfHooks/useAggretation";
import { IGetHistWeatherChatInfoResModel, IGetHistoricCharInfoResModel } from "@/util/http/HistWeather/responseType";
import { generateLocationByCoordinate, getHistoricalCharInfo, getHistoricalWeatherCharInfo } from "@/util/http/HistWeather";
import useWeatherChartTranslate from "./components/SelfHooks/useWeatherChartTranslate";
import IF from "@/components/IF";
import SentEmailModal from "@/components/SentEmailModal";
import HistSearchLocation from "@/components/HistSearchLocation";
import {debounce} from "lodash";
import { normalActionTypes } from "@/redux/actions/diseaseAction";
import ForcastButton from "@/components/ForecastProviderButton";
import useAltitude from "./components/SelfHooks/useAltitude";

const {Option} = Select;

interface ISearchObj{
    theme?: string;
    country?: string;
    language?: string;
    lat?: number;
    long?: number;
    securitykey?: string;
    unit?: string;
}
interface IDiseaseOptionsObj{
    riskName?: string;
    riskValue?: string;
    riskColor?: string;
    riskSVG?: any;
    isChecked?: boolean;
    risk_id?: string;
    chartType: string;
    risk_series: string;
}

const DiseaseShowOptionsInit = [
    {
        riskName: "Air Temperature Max",
        riskValue: "TMAX",
        riskColor: "#FF7A00",
        // riskSVG: <Risk_Color_Svg_1/>,
        isChecked: false,
        risk_id: "tempair_c_daytimemax_AltAdj",
        chartType: "Temperaure",
        risk_series: "node1_cb_TempAir_C_DaytimeMax"
    },
    {
        riskName: "Air Temperature Min",
        riskValue: "TMIN",
        riskColor: "#0047FF",
        // riskSVG: <Risk_Color_Svg_2/>,
        isChecked: false,
        risk_id: "tempair_c_nighttimemin_AltAdj",
        chartType: "Temperaure",
        risk_series: "node1_cb_TempAir_C_NighttimeMin"
    },
    {
        riskName: "Precipitation",
        riskValue: "Precipitation",
        riskColor: "#5BC0FA",
        // riskSVG: <Risk_Color_Svg_3/>,
        isChecked: false,
        risk_id: "precipamount_mm_dailysum",
        chartType: "Precipitation",
        risk_series: "node0_cb_PrecipAmount_mm_DailySum"
    },
];

const USDiseaseShowOptionsInit = [
    {
        riskName: "Air Temperature Max",
        riskValue: "TMAX",
        riskColor: "#FF7A00",
        // riskSVG: <Risk_Color_Svg_1/>,
        isChecked: true,
        risk_id: "tempair_c_daytimemax_AltAdj",
        chartType: "Temperaure",
        risk_series: "node1_cb_TempAir_C_DaytimeMax"
    },
    {
        riskName: "Air Temperature Min",
        riskValue: "TMIN",
        riskColor: "#0047FF",
        // riskSVG: <Risk_Color_Svg_2/>,
        isChecked: true,
        risk_id: "tempair_c_nighttimemin_AltAdj",
        chartType: "Temperaure",
        risk_series: "node1_cb_TempAir_C_NighttimeMin"
    },
    {
        riskName: "Precipitation",
        riskValue: "Precipitation",
        riskColor: "#5BC0FA",
        // riskSVG: <Risk_Color_Svg_3/>,
        isChecked: true,
        risk_id: "precipamount_mm_dailysum",
        chartType: "Precipitation",
        risk_series: "node0_cb_PrecipAmount_mm_DailySum"
    },
];


const HistWeatherChart = () => {

    // static data
    const pubname1 = "geotroll1";
    const pubname2 = "glbsip03p";
    const servicepage = "HistoryData_ECMWF";
    const USCheckedSeries = "node1_cb_TempAir_C_DaytimeMax;node1_cb_TempAir_C_NighttimeMin;node5_cb_GDD_C_DailySum;node5_cb_GDD_C_Accumulated";
    // const unit = "metric";

    const dispatch = useDispatch();
    const urlLocation = useLocation();

    // add google analysis
    useEffect(() => {
        console.log("just invoke once");
        const head = document.getElementsByTagName("head")[0];
        const googleAnalyticsCode = "G-C6SR5DY7G9";
        const googleAnalyticsFirstScript = document.createElement("script");
        googleAnalyticsFirstScript.async = true;
        googleAnalyticsFirstScript.src =
          "https://www.googletagmanager.com/gtag/js?id=" + googleAnalyticsCode;

        const googleAnalyticsSecondScript = document.createElement("script");
        googleAnalyticsSecondScript.innerHTML =
          "    window.dataLayer = window.dataLayer || [];\n" +
          "    function gtag(){dataLayer.push(arguments);}\n" +
          "    gtag('js', new Date());\n" +
          "\n" +
          "    gtag('config', '" +
          googleAnalyticsCode +
          "',{\n" +
          "cookie_flags:'max-age=7200;secure;samesite-none'" +
          "});";

        head.insertBefore(googleAnalyticsSecondScript, head.firstChild);
        head.insertBefore(googleAnalyticsFirstScript, head.firstChild);
    }, []);

    const {
        showLodding
    } = useSelector((state: RootState) => state.diseaseReducer);
    const {
        homeIndexLoading,
    } = useSelector((state: RootState) => state.commonReducer);

    // current location
    const [isReset,setIsReset] = useState(false);
    const changeChildIsReset = (data:boolean) =>{
        setIsReset(data);
    };
    const [currentLocation,setCurrentLocation] = useState<ILocationModel>(null);
    const changeChildCurrentLocation = (value: ILocationModel)=>{
        setCurrentLocation(value);
    };
    // get countryname form the lat and long
    const [initSearchCountry,setInitSearchCountry] = useState<ILocationModel>(null);
    const generateLocationByCoordinateFunc = async()=>{
        try{
            // const res = await generateLocationByCoordinate({
            //     latitude: searchObj.lat,
            //     longitude: searchObj.long
            // });
            const res = await generateLocationByCoordinate({
                searchText: `${searchObj?.long},${searchObj?.lat}`
            });
            console.log("my coun",res.data);
            console.log("my coun",res.data[0]);
            const tmpData:ILocationModel = {
                AdminName: {_text: res.data[0].adminName},
                AdminName1: { _text: res.data[0].adminName1 },
                AdminName2: { _text: res.data[0].adminName2 },
                AdminName3: res.data[0].adminName3,
                AltName: res.data[0].altName,
                AreaId: res.data[0].areaId,
                CountryCode: { _text: res.data[0].countryCode },
                CountryName: { _text: res.data[0].countryName },
                Latitude: { _text: res.data[0].latitude },
                Longitude: { _text: res.data[0].longitude},
                Name: { _text: res.data[0].name},
                PlaceId: { _text: res.data[0].placeId},
                PostalCode: res.data[0].postalCode,
                Score: res.data[0].score,
                pubName: null,
                cultureCode: null,
                numberOfDays: null,
                isNowcast: null
            };
            setInitSearchCountry(tmpData);
            // setInitSearchCountry(res.data);
        }
        catch(error){
            console.log("get countryname error",error);
        }
    };

    // search obj init
    const [searchObj,setSearObj] = useState<ISearchObj>({
        theme: "light",
        country: "GB",
        language: "en",
        lat: 55,
        long: 55,
        unit: "metric",
        securitykey: "BsQIdrsA5HiGN3u2Z1pKXB/zhk3kfQjRbpsx1VBumnfYhd6BKz0Y25E6jpZA/cBWkd/vq1A7CpHL7179vbXzDLdoXd871Z+OiG7gtePTiuRohRBgwz1yl3j6DCnEKA2An/JGBr0ZFpmjVf87i1NKfMVtb+mHsO6+9Iy1Tv0cELLkptsh+yBw0udNaYd1ZI736mox/WLyLZo="
    });
    useEffect(()=>{
        document.body.style.backgroundColor = searchObj?.theme === "light" ? "white" : "black";
    },[searchObj?.theme]);

    // disease show options and start and end date
    const [diseaseShowOptions,setDiseaseShowOptions] = useState<IDiseaseOptionsObj[]>([]);
    const [startDate,setStartDate] = useState<dayjs.Dayjs>(dayjs().subtract(1,"day").subtract(1,"month"));
    const [endDate,setEndDate] = useState<dayjs.Dayjs>(dayjs().subtract(2,"day"));
    const childChangeStartDateFunc = (date:dayjs.Dayjs)=>{
        setStartDate(date);
    };
    const childChangeEndDateFunc = (date:dayjs.Dayjs)=>{
        setEndDate(date);
    };

    // aggregation and elevation
    const [aggregation,setAggretation] = useState("daily");
    const [elevation,setElevation] = useState(null);
    const [chartType,setChartType] = useState("Temperaure");
    const [chartTypeTranslate,setChartTypeTranslate] = useState("Temperaure");

    // static
    const {seriesOptions} = useSeries({
        countryCode: searchObj.country,
        culture: searchObj.language + "-" + searchObj.country,
        unit: searchObj.unit,
        servicepage: servicepage,
        pubname: pubname1
    });
    const {aggregationOptions} = useAggretation({
        pubName: pubname2,
        strCultureCode: searchObj.language + "-" + searchObj.country
    });
    const {weacherChartTranslate} = useWeatherChartTranslate({
        countryCode: searchObj.country,
        language: searchObj.language
    });
    const {altitude} = useAltitude({
        longitude: searchObj.long,
        latitude: searchObj.lat
    });

    // set gdd value , which used for US
    const [maxDistanceDiff,setMaxDistanceDiff] = useState(0);
    const [maxAltitudeDiff,setMaxAltitudeDiff] = useState(60);



    const initSearchObj = ()=>{
        const searchStr = location?.search.replace("?","");
        const searchArr = searchStr?.split("&");
        const tmpSearchObj = {...searchObj};
        console.log("search aaa",tmpSearchObj);
        searchArr?.map((item)=>{
            const searchKeyValue = item?.split("=");
            console.log("search aaa bbb",searchKeyValue);
            if( searchKeyValue[0].toLowerCase() === "lat" ||
                searchKeyValue[0].toLowerCase() === "long"){
                tmpSearchObj[searchKeyValue[0]?.toLocaleLowerCase()] = Number(searchKeyValue[1]);
                console.log("search aaa bbb ccc",Number(searchKeyValue[1]));
            }
            else if(searchKeyValue[0].toLowerCase() === "country"){
                tmpSearchObj[searchKeyValue[0]?.toLocaleLowerCase()] = searchKeyValue[1]?.toUpperCase();
            }else{
                tmpSearchObj[searchKeyValue[0].toLowerCase()] = searchKeyValue[1];
            }
        });
        setSearObj(tmpSearchObj);
    };

    const publicChangeDiaseaseShowOptions = (value?:boolean,index?:number,initFlag?:boolean)=>{
        // if initFlag is true, set all optins isChecked is false;
        const tmpDiseaseOptions = [...diseaseShowOptions];

        if(tmpDiseaseOptions.length != 0){
            if(initFlag){
                tmpDiseaseOptions.forEach((item)=>{
                    item["isChecked"] = false;
                });
            }
            else{
                tmpDiseaseOptions[index]["isChecked"] = value;
            }

        }
        setDiseaseShowOptions(tmpDiseaseOptions);
    };


    // email this page
    const [showEmailModal,setShowEmailModal] = useState(false);
    // download image and email iamge
    const [imageBase64Content,setImageBase64Content] = useState(null);
    const saveMapToDataUrl = ()=>{
        const element = document.getElementById("chartContentDiv");
        // const element1 = document.querySelector(".chartContentDiv .test:first-child") as HTMLElement;
        const element1 = document.querySelector(".rightLatLng") as HTMLElement;
        const element2 = document.querySelector(".chart-contnet-right-title") as HTMLElement;
        element1.style.display = "block";
        element2.style.display = "none";
        console.log("test images",element);
        console.log("test images1",element1);
        if(element){
            html2canvas(element,{
                useCORS: true
            }).then((canvas)=>{
                const dataUrl = canvas.toDataURL("image/png");
                console.log("test images",dataUrl);

                const link = document.createElement("a");
                link.href = dataUrl;
                link.download = searchObj.country + ".png";
                link.click();
                element1.style.display = "none";
                element2.style.display = "block";
            });
        }
    };
    const getImageBase64Data = ()=>{
        const element = document.getElementById("chartContentDiv");
        const element1 = document.querySelector(".rightLatLng") as HTMLElement;
        const element2 = document.querySelector(".chart-contnet-right-title") as HTMLElement;
        element1.style.display = "block";
        element2.style.display = "none";
        console.log("test images",element);
        if(element){
            html2canvas(element,{
                useCORS: true
            }).then((canvas)=>{
                const dataUrl = canvas.toDataURL("image/png");
                setImageBase64Content(dataUrl);
                element1.style.display = "none";
                element2.style.display = "block";
            });
        }
    };

    // color and id array, used in echarts
    const echartContainerRef = useRef(null);
    const [echartTitle,setEchartTitle] = useState("Air temperature (°C)");
    const [echartsColor,setEchartsColor] = useState<string[]>([]);
    const [echartsRiskId,setEchartsRiskId] = useState<string[]>([]);
    const [echartSeriesName,setEchartSeriesName] = useState<string[]>([]);
    const publicChangeColorAndIdArray = ()=>{
        const tmpColor = [];
        const tmpId = [];
        const tmpEchartSeriesName = [];
        let tmpDiseaseShowOptions = [];
        tmpDiseaseShowOptions = [...diseaseShowOptions];
        // if(initFlag){
        //     const tmpData: IDiseaseOptionsObj[] = JSON.parse(JSON.stringify(DiseaseShowOptionsInit));
        //     tmpDiseaseShowOptions = [...tmpData];
        // }
        // else{
        //     tmpDiseaseShowOptions = [...diseaseShowOptions];
        // }
        tmpDiseaseShowOptions?.forEach((item)=>{
            if(item.isChecked && item.chartType === chartType){
                tmpColor.push(item.riskColor);
                tmpId.push(item.risk_id);
                tmpEchartSeriesName.push(item.riskName);
            }
        });
        setEchartsColor(tmpColor);
        setEchartsRiskId(tmpId);
        setEchartSeriesName(tmpEchartSeriesName);
    };
    const selectedDiseaseRiskId = (initFlag?:boolean):string=>{
        let tmpId = "";
        let tmpDiseaseShowOptions = [];
        if(initFlag){
            tmpDiseaseShowOptions = [...DiseaseShowOptionsInit];
        }
        else{
            tmpDiseaseShowOptions = [...diseaseShowOptions];
        }
        tmpDiseaseShowOptions?.forEach((item,index)=>{
            if(item?.isChecked && item?.chartType == chartType)
                tmpId = tmpId + item?.risk_series + ";";
        });
        // selected
        return tmpId;
    };

    const publicChangeDiseaseShowOptionFromSeries = ()=>{
        const tmpCharTypeOptionObj = [...diseaseShowOptions];

        tmpCharTypeOptionObj[0].riskName = seriesOptions[1]?.text;
        // tmpCharTypeOptionObj[0].risk_id = seriesOptions[1]?.id;
        tmpCharTypeOptionObj[0].isChecked = seriesOptions[1]?.isCheck;
        tmpCharTypeOptionObj[1].riskName = seriesOptions[2]?.text;
        // tmpCharTypeOptionObj[1].risk_id = seriesOptions[2]?.id;
        tmpCharTypeOptionObj[1].isChecked = seriesOptions[2]?.isCheck;
        tmpCharTypeOptionObj[2].riskName = seriesOptions[0]?.text;
        // tmpCharTypeOptionObj[2].risk_id = seriesOptions[0]?.id;
        tmpCharTypeOptionObj[2].isChecked = seriesOptions[0]?.isCheck;

        setDiseaseShowOptions(tmpCharTypeOptionObj);
    };

    const publicChangeDiseaseShowOptionByChartTypeOrAggregation = (chartType:string,aggregation:string)=>{
        const tmpDiseaseShowOptions = [...diseaseShowOptions];
        if(chartType === "Temperaure"){
            if(aggregation != "daily"){
                tmpDiseaseShowOptions[0].risk_id = "avgtempair_c_daytimemax_AltAdj";
                tmpDiseaseShowOptions[1].risk_id = "avgtempair_c_nighttimemin_AltAdj";
            }
            else{
                tmpDiseaseShowOptions[0].risk_id = DiseaseShowOptionsInit[0].risk_id;
                tmpDiseaseShowOptions[1].risk_id = DiseaseShowOptionsInit[1].risk_id;
            }
            tmpDiseaseShowOptions[2].risk_id = DiseaseShowOptionsInit[2].risk_id;
        }
        else{
            if(aggregation != "daily"){
                tmpDiseaseShowOptions[2].risk_id = "sumprecipamount_mm_dailysum";
            }
            else{
                tmpDiseaseShowOptions[2].risk_id = DiseaseShowOptionsInit[2].risk_id;
            }
            tmpDiseaseShowOptions[0].risk_id = DiseaseShowOptionsInit[0].risk_id;
            tmpDiseaseShowOptions[1].risk_id = DiseaseShowOptionsInit[1].risk_id;
        }
        console.log("echart change",tmpDiseaseShowOptions);
        setDiseaseShowOptions(tmpDiseaseShowOptions);
    };

    // change echart title
    const publicChangeEchartTitle = (init:boolean) => {
        let tmpEchartTitle = "";
        if(init){
            tmpEchartTitle = weacherChartTranslate?.historicTemperaturTip;
            if(searchObj?.country?.toLowerCase() === "us"){
                if(searchObj?.unit === "imperial"){
                    tmpEchartTitle = tmpEchartTitle + "(°F)";
                }
                else{
                    tmpEchartTitle = tmpEchartTitle + "(℃)";
                }
            }
        }
        else{
            if(chartType === "Temperaure"){
                tmpEchartTitle = weacherChartTranslate?.historicTemperaturTip;
                if(searchObj?.country?.toLowerCase() === "us"){
                    if(searchObj?.unit === "imperial"){
                        tmpEchartTitle = tmpEchartTitle + "(°F)";
                    }
                    else{
                        tmpEchartTitle = tmpEchartTitle + "(℃)";
                    }
                }
            }
            else{
                tmpEchartTitle = weacherChartTranslate?.historicPrecipitationsTip;
            }
        }
        setEchartTitle(tmpEchartTitle);
    };

    // get final data
    const [echartData,setEchartData] = useState<IGetHistoricCharInfoResModel>(null);
    const getHistoricalCharInfoFunc = async(initFlag?:boolean,newAlltitude?:number)=>{
        try{
            // if initFlag is true, mean get checkedSeries from DiseaseShowOptionInit
            // else get checkedSeries from diseaseShowOptions
            const tmpCheckedSeries = selectedDiseaseRiskId(initFlag);
            // const tmpStartDate = initFlag ? dayjs().subtract(2,"month").format("DD/MM/YYYY") : startDate.format("DD/MM/YYYY");
            // const tmpEndDate = initFlag ? dayjs().format("DD/MM/YYYY") : endDate.format("DD/MM/YYYY");
            const tmpStartDate = initFlag ? dayjs().subtract(1,"day").subtract(1,"month").format("DD/MM/YYYY") : startDate.format("DD/MM/YYYY");
            const tmpEndDate = initFlag ? dayjs().subtract(2,"day").format("DD/MM/YYYY") : endDate.format("DD/MM/YYYY");
            const finalCheckedSeries = searchObj.country === "US" ? USCheckedSeries : (tmpCheckedSeries || "");
            let tmpIOC = initFlag ? altitude : elevation;
            if(newAlltitude) tmpIOC = newAlltitude;
            dispatch({ type: normalActionTypes.NORMAL_SHOWLODDING, payload: true});
            const res = await getHistoricalCharInfo({
                pubname: pubname1,
                // servicepage,
                unit: searchObj.unit,
                culture: searchObj.language + "-" + searchObj.country,
                countryCode: searchObj.country,
                latitude: searchObj.lat,
                longitude: searchObj.long,
                startDate: tmpStartDate,
                endDate: tmpEndDate,
                aggregation: aggregation,
                // locAltitude: initFlag ? altitude : elevation,
                locAltitude: tmpIOC,
                checkedSeries: finalCheckedSeries,
                maxAltitudeDiff: searchObj.country === "US" ? maxAltitudeDiff : 0,
                maxDistanceDiff: searchObj.country === "US" ? maxDistanceDiff : 0,
                isMBHistGreencast: false
            });
            console.log("dddd error");
            setEchartData(res.data);
            dispatch({ type: normalActionTypes.NORMAL_SHOWLODDING, payload: false});
        }
        catch(error){
            setEchartData(null);
            dispatch({ type: normalActionTypes.NORMAL_SHOWLODDING, payload: false});
            console.log("get HistroicCharInfoFunc failed",error);
        }
    };

    const [cumulativeValue,setCumulative] = useState(0);
    const [usCumulativeTemperature,setUSCumulativeTemperature] = useState(0);
    const [usCumulativeGDD,setUSCumulativeGDD] = useState(0);
    const publicGetCumulativeVale = (echartData:IGetHistoricCharInfoResModel)=>{
        let allData = 0;
        let tmpCumulativeValue = 0;
        console.log("test errror",echartData);
        if(echartData?.data?.length > 0){
            echartData.data.forEach((item,index)=>{
                allData = allData + (item["precipamount_mm_dailysum"] || 0);
                allData = allData + (item["tempair_c_daytimemax_AltAdj"] || 0);
                allData = allData + (item["tempair_c_nighttimemin_AltAdj"] || 0);
                // not daily, like 10 day, week, month
                allData = allData + (item["sumprecipamount_mm_dailysum"] || 0);
                allData = allData + (item["avgtempair_c_daytimemax_AltAdj"] || 0);
                allData = allData + (item["avgtempair_c_nighttimemin_AltAdj"] || 0);
            });
            tmpCumulativeValue = allData / echartData.data.length;
        }
        setCumulative(tmpCumulativeValue);
    };
    const publicGetUSCumulativeValue = (echartData:IGetHistoricCharInfoResModel)=>{
        let allTemData = 0;
        let allGDDData = 0;
        let tmpCumulativeTem = 0;
        let tmpCumulativeGDDValue = 0;
        console.log("test errror us",echartData);
        if(echartData?.data?.length > 0){
            echartData.data.forEach((item,index)=>{
                allTemData = allTemData + (item["tempair_c_daytimemax_AltAdj"] || 0);
                allTemData = allTemData + (item["tempair_c_nighttimemin_AltAdj"] || 0);
                allGDDData = allGDDData + (item["gdd_c_accumulated"] || 0);
                allGDDData = allGDDData + (item["gdd_c_dailysum"] || 0);
            });
            tmpCumulativeTem = allTemData / echartData.data.length;
            tmpCumulativeGDDValue = allGDDData / echartData.data.length;
        }
        setUSCumulativeTemperature(tmpCumulativeTem);
        setUSCumulativeGDD(tmpCumulativeGDDValue);
    };


    // clear selection: mean reset the data
    const clearSelection = ()=>{
        setIsReset(true);
        // reset the diseaseShowOption data
        const tmpData: any[] = JSON.parse(JSON.stringify(DiseaseShowOptionsInit));
        setDiseaseShowOptions(tmpData);
        // reset the searchObj and currentlocation
        initSearchObj();
        setCurrentLocation(null);
        // reset the elevation and aggretation
        setAggretation("daily");
        setElevation(altitude);
        // reset the chartType
        setChartType("Temperaure");
        setChartTypeTranslate(weacherChartTranslate?.txt_Temperature || "Temperaure");
        // reset the date
        // setStartDate(dayjs().subtract(2,"month"));
        // setEndDate(dayjs());
        setStartDate(dayjs().subtract(1,"day").subtract(1,"month"));
        setEndDate(dayjs().subtract(2,"day"));
        // reset echart title
        publicChangeEchartTitle(true);
        // when clear , restart request the func
        getHistoricalCharInfoFunc(true);
        // reset echart data
        setEchartsColor([]);
        setEchartsRiskId([]);
        setEchartSeriesName([]);
        setEchartData(null);
        // reset the cumulative value
        setCumulative(0);
        setUSCumulativeGDD(0);
        setUSCumulativeTemperature(0);
    };
    // refresh chart : mean start request the function
    const refreshChart = ()=>{
        // change echart title
        publicChangeEchartTitle(false);
        // set color and id array which used in echart
        publicChangeColorAndIdArray();
        // request the function
        getHistoricalCharInfoFunc();
    };


    // ============  useEffect  ==============
    // init the disease options
    useEffect(()=>{
        const tmpData: any[] = JSON.parse(JSON.stringify(DiseaseShowOptionsInit));
        setDiseaseShowOptions(tmpData);
    },[]);
    useEffect(()=>{
        if(seriesOptions?.length > 0){
            // when get or update seriesOptions, should execute the request function
            publicChangeDiseaseShowOptionFromSeries();
            // execute the request function to get data
            getHistoricalCharInfoFunc(true);
        }
    // },[seriesOptions,altitude]);
    },[seriesOptions]);

    const [initLocationCount,setInitLocationCount] = useState(0);
    // set altitude by the long and lat
    useEffect(()=>{
        setElevation(altitude);
        if(initLocationCount >= 1){
            console.log("count aaa");
            getHistoricalCharInfoFunc(false,altitude);
        }
    },[altitude,initLocationCount,searchObj?.lat,searchObj?.long]);

    useEffect(() => {
        initSearchObj();
    }, [urlLocation]);

    useEffect(()=>{
        if(currentLocation?.Latitude?._text && currentLocation?.Longitude?._text){
            const tmpSearchObj = {...searchObj};
            tmpSearchObj.lat = Number(currentLocation?.Latitude?._text);
            tmpSearchObj.long = Number(currentLocation?.Longitude?._text);
            setSearObj(tmpSearchObj);
            setInitLocationCount(initLocationCount + 1);
        }
    },[currentLocation]);



    useEffect(()=>{
        if(searchObj?.unit && weacherChartTranslate?.historicTemperaturTip
             && searchObj?.country.toLowerCase() === "us"){
            publicChangeEchartTitle(true);
        }
    },[searchObj.unit,weacherChartTranslate?.historicTemperaturTip]);

    useEffect(()=>{
        const getCountryNameByLagLongDebounceFunc = debounce(generateLocationByCoordinateFunc,500);
        getCountryNameByLagLongDebounceFunc();
        return()=>{
            getCountryNameByLagLongDebounceFunc.cancel();
        };
    },[searchObj.lat,searchObj.long]);
    // change the Select Aggretation the default value
    useEffect(()=>{
        if(aggregationOptions.length > 0){
            setAggretation(aggregationOptions[0]?.key);
        }
    },[aggregationOptions]);

    useEffect(()=>{
        if(chartType && aggregation && diseaseShowOptions?.length > 0){
            publicChangeDiseaseShowOptionByChartTypeOrAggregation(chartType,aggregation);
        }
    },[chartType,aggregation]);

    useEffect(()=>{
        if(echartData?.data?.length > 0){
            if(searchObj.country === "US"){
                publicGetUSCumulativeValue(echartData);
            }
            else{
                publicGetCumulativeVale(echartData);
            }
        }
    },[echartData]);

    useEffect(()=>{
        if(searchObj?.unit === "imperial"){
            setMaxDistanceDiff(32);
            setMaxAltitudeDiff(140);
        }
    },[searchObj.unit]);


    console.log("ddd ",diseaseShowOptions);
    console.log("search aaa searchObj",searchObj);


    return (
        <div className="hist-weather-chart-all-container">

            <div className={`hist-weather-chart bg-${searchObj.theme}`}>
                <Spin spinning={showLodding || homeIndexLoading}>
                    <div className={"hist-weather-chart-container"}>
                        <div className="accurate-left">
                            <div className="head-title">
                                {/* Historical Weather Chart */}
                                {weacherChartTranslate?.txt_HistWeatherChart || "Historical Weather Chart"}
                            </div>
                            <div className="ag-search-div">
                                <span className="search-title theme-title-color">
                                    {/* Select your location to see accurate weather */}
                                    {/* {weacherChartTranslate?.txt_SelectLocation || "Select your location to see accurate weather"} */}
                                    {weacherChartTranslate?.txt_SelectLocationToSeeHistWeather || "Select your location to see historical weather"}
                                </span>
                                <HistSearchLocation
                                    country={searchObj.country}
                                    language={searchObj.language}
                                    initSearchCountryName={initSearchCountry?.Name?._text}
                                    placahold={weacherChartTranslate?.txt_LocationNameLngLat}
                                    currentLocation={currentLocation}
                                    isReset = {isReset}
                                    changeChildCurrentLocation={changeChildCurrentLocation}
                                    changeChildIsReset={changeChildIsReset}
                                />
                            </div>
                            <div className="ag-bookmark">
                                <label className="bookmark-title theme-title-color">
                                    {/* Select your start and end date */}
                                    {weacherChartTranslate?.txt_SelYourStartAndEndDate || "Select your start and end date"}
                                </label>
                                <div className="select-div">
                                    <HistRangePicker
                                        countrycode={searchObj.country}
                                        className="hisDisease-date"
                                        startDate={startDate}
                                        endDate={endDate}
                                        childChangeEndDateFunc={childChangeEndDateFunc}
                                        childChangeStartDateFunc={childChangeStartDateFunc}
                                    />
                                </div>
                            </div>
                            <IF show={searchObj.country != "US"}>
                                {/* select Aggregation */}
                                <div className="ag-aggregation-elevation">
                                    <div className="ag-aggregation">
                                        <label className="theme-title-color">
                                            {/* Select Aggretation */}
                                            {weacherChartTranslate?.txt_Select || "Select"} {weacherChartTranslate?.Aggregation || "Aggretation"}
                                        </label>
                                        <Select
                                            className="ag-aggregation-select"
                                            value={aggregation}
                                            onChange={(e)=>{
                                                setAggretation(e);
                                            }}
                                        >
                                            {
                                                aggregationOptions?.map((item,index)=>{
                                                    return(
                                                        <>
                                                            <Option key={item.key + index} value={item.key}>{item.value}</Option>
                                                        </>
                                                    );
                                                })
                                            }
                                        </Select>
                                    </div>
                                    <IF show={searchObj.country != "US"}>
                                        <div className="ag-elevation">
                                            <label className="theme-title-color">
                                                {/* Location Elevation */}
                                                {weacherChartTranslate?.Altitude || "Location elevation"}
                                            </label>
                                            <div className="ag-elevation-number">
                                                <Input
                                                    type="number"
                                                    value={elevation}
                                                    onChange={(e)=>{
                                                        setElevation(Number(e.target.value));
                                                    }}
                                                    addonAfter={<UnitComponent
                                                        text={"m"}
                                                        className={"ag-elevation-number-unit"}
                                                    />}
                                                />
                                            </div>
                                        </div>
                                    </IF>
                                </div>
                            </IF>

                            {/* chart type list */}
                            <IF show={searchObj?.country != "US"}>
                                <div className="ag-chart-type">
                                    <label className="top-title theme-title-color">
                                        {/* Select Chart Type */}
                                        {weacherChartTranslate?.txt_SelChartType || "Select Chart Type"}
                                    </label>
                                    <Select
                                        className="ag-aggregation-select"
                                        value={chartType}
                                        onChange={(e)=>{
                                            publicChangeDiaseaseShowOptions(null,null,true);
                                            setChartType(e);
                                            if(e === "Temperaure"){
                                                setChartTypeTranslate(weacherChartTranslate?.txt_Temperature || "Temperaure");
                                            }
                                            else{
                                                setChartTypeTranslate(weacherChartTranslate?.txt_Precipitaion || "Precipitation");
                                            }
                                        }}
                                    >
                                        <Option value="Temperaure" key="Temperaure">{weacherChartTranslate?.txt_Temperature || "Temperaure"}</Option>
                                        <Option value="Precipitation" key="Precipitation">{weacherChartTranslate?.txt_Precipitaion || "Precipitation"}</Option>
                                    </Select>
                                </div>

                                <div className="ag-chart-description">
                                    <label className="sub-title theme-title-color">
                                        {/* Select from the checkboxes below to add or remove graph parameters, then click refresh chart to display changes */}
                                        {weacherChartTranslate?.txt_SelFromCbxToRefreshChart || "Select from the checkboxes below to add or remove graph parameters, then click refresh chart to display changes"}
                                    </label>
                                </div>

                                {/* Disease Options */}
                                <div className="ag-disease-risk-list-container theme-title-color">
                                    {
                                        diseaseShowOptions?.map((item,index)=>{
                                            return(
                                                <>
                                                    <IF show={item.chartType === chartType}>
                                                        <div className="ag-disease-risk-item">
                                                            <Input
                                                                type="checkbox"
                                                                checked = {item?.isChecked}
                                                                onChange={(e)=>{
                                                                    console.log("fff checked",e.target.checked);
                                                                    publicChangeDiaseaseShowOptions(e.target.checked,index);
                                                                }}
                                                            />
                                                            <span>{item?.riskName}</span>
                                                        </div>
                                                    </IF>
                                                </>
                                            );
                                        })
                                    }
                                </div>
                            </IF>
                            {/* US page  */}
                            <IF show={searchObj?.country === "US"}>
                                {/* Disease Options */}
                                <div className="ag-disease-risk-list-container theme-title-color">
                                    {
                                        USDiseaseShowOptionsInit?.map((item,index)=>{
                                            return(
                                                <>
                                                    <div className="ag-disease-risk-item">
                                                        <Input
                                                            type="checkbox"
                                                            disabled = {true}
                                                            checked = {item?.isChecked}
                                                            onChange={(e)=>{
                                                                console.log("fff checked",e.target.checked);
                                                                publicChangeDiaseaseShowOptions(e.target.checked,index);
                                                            }}
                                                        />
                                                        <span>{item?.riskName}</span>
                                                    </div>
                                                </>
                                            );
                                        })
                                    }
                                </div>

                                {/* Two input button */}
                                <div className="ag-diseae-risk-list-input-group">
                                    {/* maxDistanceDiff   */}
                                    <Input
                                        value={maxDistanceDiff}
                                        type="number"
                                        onChange={(e)=>{
                                            setMaxDistanceDiff(Number(e.target.value));
                                        }}
                                    />
                                    {/* maxAltitudeDiff */}
                                    <Input
                                        value={maxAltitudeDiff}
                                        type="number"
                                        onChange={(e)=>{
                                            setMaxAltitudeDiff(Number(e.target.value));
                                        }}
                                    />
                                </div>
                            </IF>


                            <div className="ag-disease-button-group theme-title-color">
                                <button className="common-button clear-button theme-title-color"
                                    onClick={()=>{
                                        clearSelection();
                                    }}
                                >
                                    {/* Clear Selection */}
                                    {weacherChartTranslate?.ClearSelection || "Clear Selection"}
                                </button>
                                <button className="common-button refresh-button theme-title-color"
                                    onClick={()=>{
                                        refreshChart();
                                    }}
                                >
                                    {/* Refresh Chart */}
                                    {weacherChartTranslate?.gc_refreshchart || "Refresh Chart"}
                                </button>
                            </div>
                        </div>


                        <div className="accurate-right">
                            <div className="top-header-div">
                                <div className="left-title">
                                    <label className="main-title">
                                        {/* London City Airport */}
                                        {(currentLocation?.Name?._text || initSearchCountry?.Name?._text)}
                                        <label
                                            className="sub-lable"
                                        // style={{marginTop: "7px"}}
                                        >
                                            {/* 51.507351, -0.127758 */}
                                            {`${searchObj?.long} , ${searchObj?.lat}`}
                                        </label>
                                    </label>
                                </div>
                                <div className="right-title"
                                    onClick={()=>{
                                        getImageBase64Data();
                                        setShowEmailModal(true);
                                    }}
                                >
                                    <Eamil_Svg
                                        width={15}
                                        height={12}
                                    />
                                    <span>
                                        {/* Email this page */}
                                        {weacherChartTranslate?.gc_EmailPage || "Email this page"}
                                    </span>
                                </div>
                            </div>
                            <IF show={showEmailModal}>
                                <SentEmailModal
                                    open = {showEmailModal}
                                    id={"hist-disease-sentEmailModal"}
                                    welcomeStr={weacherChartTranslate?.gc_EmailHeader}
                                    toStr={weacherChartTranslate?.gc_EmailTo}
                                    subjectStr={weacherChartTranslate?.gc_EmailSubject}
                                    messageStr={weacherChartTranslate?.gc_EmailMessageArea}
                                    idRequiredStr={weacherChartTranslate?.gc_EmailRequired}
                                    subjectRequiredStr={weacherChartTranslate?.gc_EmailSubjectRequired}
                                    sendEmailStr={weacherChartTranslate?.gc_SendEmailButton}
                                    cancelStr={weacherChartTranslate?.cancel}
                                    idValidStr={weacherChartTranslate?.gc_EmailValid}
                                    emailFailedStr={weacherChartTranslate?.gc_EmailSendFailed}
                                    defaultTitle={weacherChartTranslate?.txt_ShareChartByEmail}
                                    defaultSubject={weacherChartTranslate?.txt_HistWeatherChart}
                                    defaultMailContent={weacherChartTranslate?.txt_HistWeatherChartMailBody}
                                    sentEmailSuccessStr={weacherChartTranslate?.gc_EmailSendSuccess}
                                    cancelCallback={()=>{
                                        setShowEmailModal(false);
                                    }}
                                    imageBase64={imageBase64Content}
                                />
                            </IF>
                            <div className="chart-content-container"
                                id="chartContentDiv"
                                ref = {echartContainerRef}
                            >
                                <div className="chart-content-title">
                                    <div className="chart-contnet-left-title">
                                        <span>
                                            {/* {chartType} - London City Airport */}
                                            {/* {chartTypeTranslate} - {(currentLocation?.Name?._text || initSearchCountry?.Name?._text) || "London City Airport"} */}
                                            {`${chartTypeTranslate} - ${(currentLocation?.Name?._text || initSearchCountry?.Name?._text) || " "}`}
                                            <span style={{display:"none"}} className="rightLatLng">{` (${searchObj?.long} , ${searchObj?.lat})`}</span>
                                        </span>
                                    </div>
                                    <div className="chart-contnet-right-title"
                                        style={{cursor:"pointer"}}
                                        onClick={saveMapToDataUrl}
                                    >
                                        <Download_Svg
                                            width={18}
                                            height={18}
                                        />
                                        <span>
                                            {/* Download as Image */}
                                            {weacherChartTranslate?.txt_DownloadAsImage || "Download as Image"}
                                        </span>
                                    </div>
                                </div>

                                <div className="chartContentDiv">
                                    <div className="chart-content-image">
                                        <EchartRander
                                            type={chartType}
                                            title={echartTitle}
                                            countrycode= { searchObj.country}
                                            echartData={echartData}
                                            colorArray={echartsColor}
                                            idArray={echartsRiskId}
                                            width={echartContainerRef?.current?.clientWidth}
                                            echartSeriesName={echartSeriesName}
                                            echartTitle={`${chartTypeTranslate} ${weacherChartTranslate?.txt_Legend || "Legend"}`}
                                        />
                                    </div>

                                    <div className="chart-content-risk">
                                        {/* Cumulative value */}
                                        <IF show = {searchObj.country != "US"}>
                                            <div className="chart-content-risk-count">
                                                <span>
                                                    {/* Cumulative Temperature : {"7.5"} */}
                                                    {weacherChartTranslate?.txt_Cumulative || "Cumulative"} {chartTypeTranslate} : {cumulativeValue}
                                                </span>
                                            </div>
                                        </IF>
                                        <IF show = {searchObj.country === "US"}>
                                            <div className="chart-content-risk-count-us">
                                                <span>
                                                    {/* Cumulative Temperature : {"7.5"} */}
                                                Cumulative temperature : {usCumulativeTemperature}
                                                </span>
                                                <span>
                                                    {/* Cumulative Temperature : {"7.5"} */}
                                                Cumulative GDD : {usCumulativeGDD}
                                                </span>
                                            </div>
                                        </IF>
                                        {/* US should not see this */}
                                        <IF show={searchObj.country != "US"}>
                                            <div className="chart-content-risk-title">
                                                {/* Temperature Legend */}
                                                {/* <span>{chartTypeTranslate} {weacherChartTranslate?.txt_Legend || "Legend"}</span> */}
                                            </div>
                                            <div className="chart-content-risk-list">
                                                {/* {diseaseShowOptions?.filter((innerItem,index)=>{
                                                    if(innerItem.isChecked === true) return innerItem;
                                                })?.map((item,index)=>{
                                                    return(
                                                        <>
                                                            <div className="chart-content-risk-item" id={index + item?.riskName}>
                                                                <Risk_Color_Svg
                                                                    color={item?.riskColor}
                                                                />
                                                                <div className="chart-content-risk-item-title">
                                                                    <span>{item?.riskName}</span>
                                                                </div>
                                                            </div>
                                                        </>
                                                    );
                                                })} */}
                                            </div>
                                        </IF>
                                    </div>
                                </div>

                                {/* forcast button */}
                                <ForcastButton/>
                            </div>

                        </div>
                    </div>
                </Spin>

            </div>
        </div>


    );
};



export default HistWeatherChart;