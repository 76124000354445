// this is used to set date format for different country
export const CountryDateFormat = {
    "AE": "DD/MM/YYYY",
    // "AE": "DD.MM.YYYY",
    "AU": "DD/MM/YYYY",
    "BY": "DD.MM.YY",
    // "CH": "DD.mm.YY",
    "CH": "DD/MM/YYYY",
    "DE": "DD.MM.YYYY",
    "NZ": "DD-MM-YYYY",
    "US": "MM/DD/YYYY",
    "ES": "DD/MM/YYYY",
    "FR": "DD/MM/YYYY",
    "GB": "DD/MM/YYYY",
    "IL": "DD/MM/YYYY",
    "JP": "YYYY/MM/DD",
    "KR": "YYYY-MM-DD",
    "PL": "DD.MM.YYYY",
    "PT": "DD-MM-YYYY",
    "SE": "YYYY-MM-DD",
    "SI": "DD.MM.YYYY",
    "TR": "DD.MM.YYYY",
    "ZA": "DD/MM/YYYY"
};

// this is abbreviation of week
export const CountryAbbrWeek = {
    "US": ["Sun","Mon","Tus","Wed","Thu","Fri","Sat"],
    "DK": ["Søn","Man","Tir","Ons","Tor","Fre","Lør"],
    "FI": ["Sun","Maa","Tii","Kes","Tor","Per","Lau"],
    "NO": ["Søn","Man","Tir","Ons","Tor","Fre","Lør"]
};

