/* eslint-disable react/display-name */
import React, { useEffect, useRef, useState } from "react";
import "./index.less";
import MapImage from "@/resource/images/png/map.png";
import ArrowLeft from "@/resource/images/svg/diseaseMap/Vectorleft.svg";
import ArrowRight from "@/resource/images/svg/diseaseMap/Vectorright.svg";
import High_risk from "@/resource/images/svg/diseaseMap/high_risk.svg";

import GoogleMap from "google-maps-react-markers";
import DiseaseMarker from "./components/DiseaseMarker";

import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);

import {countryCenterAndZoomScape} from "../initDdata/init";

import { Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/redux/reducer/store";
import { normalActionTypes } from "@/redux/actions/diseaseAction";
import useSize from "./components/selfHooks/useSize";

interface ITimeType{
    index:number,
    week: string;
    day: string;
}

interface IProps{
    forcastDay:number;
    startDate?:string;
    selectedDisease?:string;
    selectedCountry?:string;
    selectedCrop?:string;
    dateFormat?: string;
    childChangeCurrentSelectedDate?: (value:string)=>void
}

const mapOptions = {
    zoomControl: false,
    mapTypeControl: false,
    streetViewControl: false,
};

const jobForcastData = 6;

const MapRender = (props:IProps)=>{
    const {
        forcastDay,
        startDate,
        selectedDisease,
        selectedCrop,
        selectedCountry,
        dateFormat,
        childChangeCurrentSelectedDate
    } = props;
    const {
        showLodding,
        diseaseMapPredictionRiskInfo
    } = useSelector((state: RootState) => state.diseaseReducer);
    const dispatch = useDispatch();
    console.log("forcast: props ",props);
    console.log("forcast: ",forcastDay);
    const [markers,setMarkers] = useState<any[]>( [
        {
            "countrycode": "AU",
            "longitude": -30.5,
            "latitude": 133.5,
        },
        {
            "countrycode": "AU",
            "longitude": -31.5,
            "latitude": 134.5,
        }
    ]);
    const [defaultProps,setDefaultProps] = useState({
        center: {
            lat: -30.5,
            lng: 133.5
        },
        zoom: 4
    });

    const weekOjb = ["Sun","Mon","Tus","Wed","Thu","Fri","Sat"];
    const [forcastDayArray,setForcastDayArray] = useState<ITimeType[]>([]);

    const [finalForcastDay,setFinalForcastDay] = useState(7);

    const testRef = useRef(null);
    const [clientWidth, setClientWidth] = useState(null);

    useEffect(() => {
        // 当testRef.current存在时，设置clientWidth状态
        if (testRef.current) {
            setClientWidth(testRef.current.clientWidth);
        }

        // 监听resize事件来更新clientWidth状态
        const handleResize = () => {
            if (testRef.current) {
                setClientWidth(testRef.current.clientWidth);
            }
        };

        window.addEventListener("resize", handleResize);

        // 清除effect
        return () => window.removeEventListener("resize", handleResize);
    }, []); // 空依赖数组意味着这个effect只在组件挂载和卸载时运行

    /**
     * currentIndex mean current selected index
     * righIndex mean the rightmost index
     * rightIndex 表示可视范围内最大的index，而不是数组最右边的
     * viewItemCount 表示页面最多同时呈现多少个 Item
     */
    const [currentIndex,setCurrentIndex] = useState(0);
    const [rightIndex,setRightIndex] = useState(0);
    const [viewItemCount,setViewItemCount] = useState(1);
    useEffect(()=>{
        if(forcastDay <=7){
            setRightIndex(forcastDay -1);
        }
        else{
            setRightIndex(6);
        }
    },[]);
    const initForcastDay = ()=>{
        // const tmpArray = [...forcastDayArray];
        const tmpArray:ITimeType[] = [];
        console.log("dd tmpArray",tmpArray);
        console.log("dd forcastDay",forcastDay);
        // acculate the end date
        const tmpCurrentDate = dayjs();
        const tmpJobEndDate = dayjs().add(jobForcastData,"day");
        const tmpStartDate = startDate ? dayjs(startDate,dateFormat || "DD/MM/YYYY") : dayjs();
        const tmpDiff = tmpJobEndDate.diff(tmpStartDate,"day") + 1;
        const tmpFinalDay = tmpDiff < forcastDay ? tmpDiff : forcastDay;
        for(let i = 0 ; i < tmpFinalDay; i++){
            tmpArray.push({
                index: i,
                week: weekOjb[tmpStartDate.add(i, "day").day()],
                // day: dayjs().add(i, "day").format("DD-MM-YYYY")
                // day: tmpStartDate.add(i, "day").format("DD/MM/YYYY")
                day: tmpStartDate.add(i, "day").format(dateFormat || "DD/MM/YYYY")
            });
        }
        setForcastDayArray(tmpArray);
        setFinalForcastDay(tmpFinalDay);
    };
    useEffect(()=>{
        setCurrentIndex(0);
    },[startDate]);
    useEffect(()=>{
        initForcastDay();
    },[forcastDay,startDate,dateFormat]);


    // const width = (forcastDay >= 7? 1226/7 : 1226/forcastDay);
    const width = (finalForcastDay >= 7? 1226/7 : 1226/finalForcastDay);
    const [itemWidth,setItemWidth] = useState(10);
    // 监听 client，动态调整 item 的 宽度
    useEffect(()=>{
        if(clientWidth > 0 && finalForcastDay >= 1){
            let tmpItemWidth = null;
            let tmpRightIndex = 0;
            let tmpViewItemCount = 1;
            const minWidth = 130;
            const tmpWidth = Math.ceil(clientWidth / finalForcastDay);
            // 不对，应该从 finalForcastDay 的数量开始判断
            if(finalForcastDay === 1){
                tmpItemWidth = clientWidth;
                tmpRightIndex = 0;
                tmpViewItemCount = 1;
            }
            if(finalForcastDay === 2){
                if(clientWidth >= 130 * 2){
                    tmpItemWidth = clientWidth / 2;
                    tmpRightIndex = finalForcastDay -1;
                    tmpViewItemCount = 2;
                }
                else{
                    tmpItemWidth = clientWidth;
                    tmpRightIndex = 0;
                    tmpViewItemCount = 1;
                }
            }
            if(finalForcastDay === 3){
                if(clientWidth >= 130 * 3){
                    tmpItemWidth = clientWidth / 3;
                    tmpRightIndex = finalForcastDay -1;
                    tmpViewItemCount = 3;
                }
                else if(clientWidth >= 130 * 2){
                    tmpItemWidth = clientWidth / 2;
                    tmpRightIndex = finalForcastDay -2;
                    tmpViewItemCount = 2;
                }
                else{
                    tmpItemWidth = clientWidth;
                    tmpRightIndex = 0;
                    tmpViewItemCount = 1;
                }
            }
            if(finalForcastDay === 4){
                if(clientWidth >= 130 * 4){
                    tmpItemWidth = clientWidth / 4;
                    tmpRightIndex = finalForcastDay -1;
                    tmpViewItemCount = 4;
                }
                else if(clientWidth >= 130 * 3) {
                    tmpItemWidth = clientWidth / 3;
                    tmpRightIndex = finalForcastDay -2;
                    tmpViewItemCount = 3;
                }
                else if(clientWidth >= 130 * 2) {
                    tmpItemWidth = clientWidth / 2;
                    tmpRightIndex = finalForcastDay - 3;
                    tmpViewItemCount = 2;
                }
                else{
                    tmpItemWidth = clientWidth;
                    tmpRightIndex = 0;
                    tmpViewItemCount = 1;
                }
            }
            if(finalForcastDay === 5){
                if(clientWidth >= 130 * 5) {
                    tmpItemWidth = clientWidth / 5;
                    tmpRightIndex = finalForcastDay - 1;
                    tmpViewItemCount = 5;
                }
                else if(clientWidth >= 130 * 4) {
                    tmpItemWidth = clientWidth / 4;
                    tmpRightIndex = finalForcastDay - 2;
                    tmpViewItemCount = 4;
                }
                else if(clientWidth >= 130 * 3) {
                    tmpItemWidth = clientWidth / 3;
                    tmpRightIndex = finalForcastDay - 3;
                    tmpViewItemCount = 3;
                }
                else if(clientWidth >= 130 * 2) {
                    tmpItemWidth = clientWidth / 2;
                    tmpRightIndex = finalForcastDay - 4;
                    tmpViewItemCount = 2;
                }
                else{
                    tmpItemWidth = clientWidth;
                    tmpRightIndex = 0;
                    tmpViewItemCount = 1;
                }
            }
            if(finalForcastDay === 6){
                if(clientWidth >= 130 * 6) {
                    tmpItemWidth = clientWidth / 6;
                    tmpRightIndex = finalForcastDay -1;
                    tmpViewItemCount = 6;
                }
                else if(clientWidth >= 130 * 5) {
                    tmpItemWidth = clientWidth / 5;
                    tmpRightIndex = finalForcastDay - 2;
                    tmpViewItemCount = 5;
                }
                else if(clientWidth >= 130 * 4) {
                    tmpItemWidth = clientWidth / 4;
                    tmpRightIndex = finalForcastDay - 3;
                    tmpViewItemCount = 4;
                }
                else if(clientWidth >= 130 * 3) {
                    tmpItemWidth = clientWidth / 3;
                    tmpRightIndex = finalForcastDay - 4;
                    tmpViewItemCount = 3;
                }
                else if(clientWidth >= 130 * 2) {
                    tmpItemWidth = clientWidth / 2;
                    tmpRightIndex = finalForcastDay - 5;
                    tmpViewItemCount = 2;
                }
                else{
                    tmpItemWidth = clientWidth;
                    tmpRightIndex = 0;
                    tmpViewItemCount = 1;
                }
            }
            if(finalForcastDay === 7){
                if(clientWidth >= 130 * 7) {
                    tmpItemWidth = clientWidth / 7;
                    tmpRightIndex = finalForcastDay - 1;
                    tmpViewItemCount = 7;
                }
                else if(clientWidth >= 130 * 6) {
                    tmpItemWidth = clientWidth / 6;
                    tmpRightIndex = finalForcastDay - 2;
                    tmpViewItemCount = 6;
                }
                else if(clientWidth >= 130 * 5) {
                    tmpItemWidth = clientWidth / 5;
                    tmpRightIndex = finalForcastDay - 3;
                    tmpViewItemCount = 5;
                }
                else if(clientWidth >= 130 * 4) {
                    tmpItemWidth = clientWidth / 4;
                    tmpRightIndex = finalForcastDay - 4;
                    tmpViewItemCount = 4;
                }
                else if(clientWidth >= 130 * 3) {
                    tmpItemWidth = clientWidth / 3;
                    tmpRightIndex = finalForcastDay - 5;
                    tmpViewItemCount = 3;
                }
                else if(clientWidth >= 130 * 2) {
                    tmpItemWidth = clientWidth / 2;
                    tmpRightIndex = finalForcastDay - 6;
                    tmpViewItemCount = 2;
                }
                else{
                    tmpItemWidth = clientWidth;
                    tmpRightIndex = 0;
                    tmpViewItemCount = 1;
                }
            }
            setItemWidth(tmpItemWidth);
            setRightIndex(tmpRightIndex);
            setViewItemCount(tmpViewItemCount);
        }
    },[clientWidth,finalForcastDay]);



    console.log("lanxue aaaa ref",testRef.current?.clientWidth);
    console.log("lanxue aaaa ref clientWidth",clientWidth);
    console.log("lanxue aaaa ref itemWidth",itemWidth);
    console.log("lanxue aaaa ref rightIndex",rightIndex);
    console.log("lanxue aaaa ref viewItemCount",viewItemCount);
    console.log("lanxue aaaa ref currentIndex",currentIndex);

    const divRef = useRef<any>(null);
    const leftArrow = ()=>{
        // 该方法能执行的话，表示允许向右移动
        let tmpCurrentIndex = currentIndex;
        let tmpRightIndex = rightIndex;
        if(currentIndex <= 0){
            tmpCurrentIndex = 0;
        }
        else{
            tmpCurrentIndex = tmpCurrentIndex -1;
        }
        // if forcast day less 7, mean you dont't move the date item
        // if(finalForcastDay<= 7)
        // return;
        /**
         * whether to move date item depends on the current wether more lean 7
         */
        if(tmpRightIndex > (viewItemCount -1)){
            const leftMoveWidth = itemWidth * (rightIndex - (viewItemCount ));
            divRef.current.style.transform = `translateX(-${leftMoveWidth}px)`;
            tmpRightIndex = tmpRightIndex -1;
        }
        setCurrentIndex(tmpCurrentIndex);
        setRightIndex(tmpRightIndex);
    };
    const rithtArrow = ()=>{
        // 该方法能执行的话，表示允许向右移动
        let tmpCurrentIndex = currentIndex;
        let tmpRightIndex = rightIndex;
        if(currentIndex >= forcastDay -1){
            tmpCurrentIndex = forcastDay -1;
        }
        else tmpCurrentIndex = tmpCurrentIndex + 1;
        // 如果移动后的 tmpCurrentIndex 大于当前页面最右边的 index，就表明更改 translate， 否则只需要更改 currentIndex 的值了
        if(tmpCurrentIndex > rightIndex){
            // 需要右移
            tmpRightIndex = tmpRightIndex + 1; // 最右边的 rightIndex 加一
            // 设置左移
            const leftMoveWidth = itemWidth * (tmpCurrentIndex - (viewItemCount - 1));
            divRef.current.style.transform = `translateX(-${leftMoveWidth}px)`;
            divRef.current.style.scrolling = "auto";
        }
        setCurrentIndex(tmpCurrentIndex);
        setRightIndex(tmpRightIndex);
    };
    const mapRef = useRef(null);
    const mapsRef = useRef(null);

    // this function is used to get map and maps instance
    const onGoogleApiLoaded = ({map,maps})=>{
        mapRef.current = map;
        mapsRef.current = maps;
    };

    const wantCallback = (params:any)=>{
        console.log("Params ",params);
    };

    // when selected countrycode change, change the map instance center
    const changeMpaCenterWhenSelectedCountryChange = ()=>{
        const tmpCenterAndZoom = countryCenterAndZoomScape.filter((item)=>{
            if(item.CountryCode === selectedCountry) return item;
        })[0];
        if(mapRef.current){
            mapRef.current?.setCenter(tmpCenterAndZoom.center);
            mapRef.current?.setZoom(tmpCenterAndZoom.zoom);
        }
    };

    const [testMarkers,setTestMarkers] = useState<{[key:number]: any}>({});
    const [testMarkersOrder,setTestMarkerOrder] = useState<number[]>([]);

    // when diseaseMapPredictionRiskInfo have data,change markes
    const changeMarkersWhenCountryOrCropOrDiseaseChange = ()=>{
        const date = forcastDayArray[currentIndex].day;
        const date2 = dayjs(forcastDayArray[currentIndex].day,dateFormat || "DD/MM/YYYY").format("YYYY-MM-DD");
        console.log("date all data:",diseaseMapPredictionRiskInfo);
        console.log("date all date:",forcastDayArray[currentIndex].day);
        console.log("date all date2:",date2);
        const tmpCountryAndRisk = diseaseMapPredictionRiskInfo.filter((item)=>{
            // const date1 = dayjs(item.datetimeUtc).format("DD/MM/YYYY");
            // console.log("date all item.datetimeutc:",item.datetimeutc);
            // console.log("date all item.datetimeutc format:",dayjs(item.datetimeUtc).format("DD/MM/YYYY"));
            // console.log("date all date1:",date1);
            if(!item?.datetimeutc) item.datetimeutc = date2;
            if(item?.datetimeutc === date2){
                return item;
            }
        });
        console.log("date all filter tmpCountryAndRisk:",tmpCountryAndRisk);
        setMarkers(tmpCountryAndRisk);


        // new function
        if(tmpCountryAndRisk?.length > 0){
            const tmpMarkers: {[key:number]:any} = {};
            const tmpMarkersOrder:number[] = [];
            tmpCountryAndRisk.forEach((item,index)=>{
                const tmpRiskLevel = publicGetRiskLevel(selectedDisease.toLowerCase(),item[selectedDisease.toLowerCase()] || 0,index);
                const marker = (
                    <DiseaseMarker
                        key = {(item?.longitude + item?.latitude) + index }
                        lng = {item?.longitude}
                        lat = {item?.latitude}
                        width={markerWidth}
                        height={markerHeight}
                        riskLevel={tmpRiskLevel}
                    />
                );
                tmpMarkers[index] = marker;
                tmpMarkersOrder.push(index);
            });
            setTestMarkers(tmpMarkers);
            setTestMarkerOrder(tmpMarkersOrder);
        }
    };

    const [changeZoom,setChangeZoom] = useState(defaultProps.zoom);

    const publicGetRiskLevel = (selectedDisease: string,tmpValue?: number,index?:number) =>{
        const value = Number(tmpValue);
        let tmpRiskLevel = "";
        if(selectedDisease.toLowerCase() === "DollarSpotSmithModelRisk" || selectedDisease.toLowerCase() === "CornGrayLeafSpot"){
            if(value <= 2) tmpRiskLevel = "NO";
            else if(value > 2 && value <= 4) tmpRiskLevel = "LOW";
            else if(value > 4 && value <= 6) tmpRiskLevel = "MEDIUM";
            else if(value > 6 && value <= 8) tmpRiskLevel = "HIGH";
            else if(value > 10) tmpRiskLevel = "VERYHITH";
        }
        else{
            if(value === 0 || !value) tmpRiskLevel = "NO";
            else if(value > 0 && value <= 1) tmpRiskLevel = "LOW";
            else if(value > 1 && value <= 2) tmpRiskLevel = "MEDIUM";
            else if(value > 2 && value <= 3) tmpRiskLevel = "HIGH";
            else if(value > 3) tmpRiskLevel = "VERYHITH";
        }
        console.log("fffff a index",index);
        console.log("fffff a selectedDisease",selectedDisease);
        console.log("fffff a value",value);
        console.log("fffff a tmpRiskLevel",tmpRiskLevel);
        return tmpRiskLevel;
    };

    const {markerWidth,markerHeight} = useSize({
        countryCode: selectedCountry,
        zoom: changeZoom
    });

    // ===== ============guseEffect ===============
    // only change the map when country change, and the map should be instance
    useEffect(()=>{
        changeMpaCenterWhenSelectedCountryChange();
    },[selectedCountry,mapRef?.current]);

    useEffect(()=>{
        if(forcastDayArray?.length > 0){
            const tmpCurrentDate = dayjs(forcastDayArray[currentIndex].day,dateFormat || "DD/MM/YYYY").format("DD/MM/YYYY");
            childChangeCurrentSelectedDate(tmpCurrentDate);
        }
    },[currentIndex]);
    useEffect(()=>{
        if(diseaseMapPredictionRiskInfo?.length >= 0 && forcastDayArray?.length > 0){
            changeMarkersWhenCountryOrCropOrDiseaseChange();
        }
    },[currentIndex,diseaseMapPredictionRiskInfo,selectedDisease,selectedCountry,selectedCrop,markerWidth]);


    console.log("lanxue ddddd currentIndex",currentIndex);
    console.log("lanxue ddddd finalForcastDay",finalForcastDay);
    return(
        <div className='v3-risk-map-container' id="v3-risk-map-container"
            ref={testRef}
        >

            <div style={{width:"1226",height:"400px"}} className="day-container">
                <div className=''>
                    <div className={`${currentIndex === 0 ? "left-arrow disabled" : "left-arrow"}`}
                        onClick={()=>{leftArrow();}}
                    >
                        <img src={ArrowRight}/>
                    </div>
                    <div className={`${currentIndex === finalForcastDay -1 ? "right-arrow disabled" : "right-arrow"}`}
                        onClick={()=>{rithtArrow();}}
                    >
                        <img src={ArrowLeft}/>
                    </div>
                    <div className='day-item-container'
                        ref = {divRef}
                        id = 'day-item-container'
                    >

                        {
                            forcastDayArray.map((item,index)=>{
                                return(
                                    <>
                                        <div
                                            key={item.day + index}
                                            className={index == currentIndex ? "day-item active" : "day-item"}
                                            // style={{width: width.toString()+"px"}}
                                            style={{width: itemWidth.toString()+"px"}}
                                            onClick={(e)=>{
                                                console.log("e",e);
                                                setCurrentIndex(index);
                                            }}
                                        >
                                            {`${item.week}  ${item.day}`}
                                        </div>
                                    </>
                                );
                            })
                        }
                    </div>
                </div>
                <Spin spinning={showLodding}>
                    <div id="mapDiv">
                        <GoogleMap
                            apiKey={"AIzaSyDlsxkoR8-fiIgncnDunmEtWEwDmr896H0"}
                            defaultCenter={defaultProps.center}
                            defaultZoom={defaultProps.zoom}
                            options={mapOptions}
                            onGoogleApiLoaded = {onGoogleApiLoaded}
                            onChange={(map:any) => {
                                console.log("test change size Map moved", mapRef.current?.zoom);
                                setChangeZoom(mapRef.current?.zoom);
                            }}
                            yesIWantToUseGoogleMapApiInternals = {wantCallback}
                        >
                            {
                                testMarkersOrder.map((index)=> testMarkers[index])
                            }
                        </GoogleMap>
                    </div>
                </Spin>
            </div>

        </div>
    );
};

export default React.memo(MapRender);
