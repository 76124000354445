import { IGetDiseaseRiskInfoReqModel, IGetLocationReqModel } from "@/util/http/requestType";
import { ILocationModel } from "@/util/http/responseType";
import { IUserModel } from "../reducer/commonReducer";

export const normalActionTypes = {
    NORMAL_DISEASE_DATA: "NORMAL_DISEASE_DATA",
    NORMAL_TRANSLATETEXT: "NORMAL_TRANSLATETEXT",
    NORMAL_DISEASERISKINFO: "NORMAL_DISEASERISKINFO",
    NORMAL_SHOWLODDING: "NORMAL_SHOWLODDING",
    NORMAL_DISEASEMAPPREDICTIONRISKINFO: "NORMAL_DISEASEMAPPREDICTIONRISKINFO",
    NORMAL_ISEXECUTEDONE: "NORMAL_ISEXECUTEDONE"
};

export const sagaActionTypes = {
    SAGA_DISEASE_DATA: "SAGA_DISEASE_DATA",
    SAGA_TRANSLATETEXT: "SAGA_TRANSLATETEXT",
    SAGA_DISEASERISKINFO: "SAGA_DISEASERISKINFO",
    SAGA_SHOWLODDING: "SAGA_SHOWLODDING",
    SAGA_DISEASEMAPPREDICTIONRISKINFO: "SAGA_DISEASEMAPPREDICTIONRISKINFO"
};


export const updateDiseaseData = (diseaseData:any) => {
    return {
        type: normalActionTypes.NORMAL_DISEASE_DATA,
        payload: diseaseData
    };
};

export const updateTranslateText = (translateText:any) => {
    return {
        type: normalActionTypes.NORMAL_TRANSLATETEXT,
        payload: translateText
    };
};

export const updateDiseaseRiskInfo = (diseaseRiskInfo:IGetDiseaseRiskInfoReqModel) => {
    return {
        type: normalActionTypes.NORMAL_DISEASERISKINFO,
        payload: diseaseRiskInfo
    };
};

export const updateShowLodding = (showLoading:boolean) => {
    return {
        type: normalActionTypes.NORMAL_SHOWLODDING,
        payload: showLoading
    };
};
// used in weather page,
export const updateIsExecuteDone = (isExecuteDone:boolean) => {
    return {
        type: normalActionTypes.NORMAL_ISEXECUTEDONE,
        payload: isExecuteDone
    };
};
// used in disease map prediction
export const updateDiseaseMapPredictionRiskInfo = (diseaseMapPredictionReq:IGetLocationReqModel) => {
    return {
        type: normalActionTypes.NORMAL_DISEASEMAPPREDICTIONRISKINFO,
        payload: diseaseMapPredictionReq
    };
};


