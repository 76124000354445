import React, { useState,useEffect } from "react";
import { Button, Checkbox, Form, Input ,Divider,notification} from "antd";
import { UserOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import store from "@/util/store";
import {getSignIn} from "@/util/http/Quantis/index";
import "./index.less";

const Login = ()=>{
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [email,setEmail]=useState("");
    const [password,setPassword]=useState("");

    const onLoginButtonClick = async()=>{
        const res = await getSignIn({
            email: email,
            Password: password
        });
        if(res.data.result=="Success"){
            store.set("quantis-token", res.data.token);
            store.set("quantisUserRole", res.data.userRole);
            store.set("quantisUser", res.data.email);
            // navigate("/visualization/cropstress");
            navigate("/cropstress/home");
        }else{
            notification.error({
                placement:"bottomLeft",
                message: res.data.result,
                onClick: () => {
                    console.log("Notification Clicked!");
                },
            });
        }
    };
    const [saveUser,setSaveUser] = useState<boolean>(false);
    const check = (e)=>{
        setSaveUser(e.target.checked);
    };
    const onFinish = (values: any) => {
        if(saveUser){
            store.set("quantisRemember",values);
        }
    };
    useEffect(() => {
        const quantisRemember:any = store.get("quantisRemember");
        if(quantisRemember!=undefined){
            form.setFieldsValue({
                email: quantisRemember.email,
                Password: quantisRemember.Password
            });
            setEmail(quantisRemember.email);
            setPassword(quantisRemember.Password);
        }

    }, [location]);
    return (
        <div className="login-style" >
            <Form
                name="basic"
                form={form}
                labelCol={{ span: 16 }}
                layout="vertical"
                onFinish={onFinish}
                autoComplete="off"
                initialValues={{
                    email: "",
                    Password: ""
                }}
            >
                <Divider>
                    <p>Please Sign In</p>
                </Divider>
                <Form.Item
                    label="Email address"
                    name="email"
                    rules={[
                        { required: true, message: "Please input your Email!" },
                        {pattern: /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/,message: "Incorrect format!",max:50}
                    ]}
                >
                    <Input placeholder="Email address"
                        value={email}
                        prefix={<UserOutlined className="site-form-item-icon" />}
                        onChange={(e)=>{
                            setEmail(e.target.value);
                        }} />
                </Form.Item>
                <Form.Item
                    name="Password"
                    label="Password"
                    rules={[{ required: true, message: "Please input your Password!" }]}
                >
                    <Input.Password
                        placeholder="new password"
                        value={password}
                        onChange={(e)=>{
                            setPassword(e.target.value);
                        }} />
                </Form.Item>
                <Form.Item>
                    <Form.Item valuePropName="checked" noStyle>
                        <Checkbox onChange={e=>check(e)}>Remember me</Checkbox>
                    </Form.Item>

                    {/* <a className="forgot-password-link" onClick={e => {navigate("/visualization/cropstress-forgetPassword");}}> */}
                    <a className="forgot-password-link" onClick={e => {navigate("/cropstress/forgetPassword");}}>
                        Forgot password
                    </a>
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit" className="login-button"
                        onClick={onLoginButtonClick}
                    >
                        Login
                    </Button>
                    {/* <p className="no-account-note">Don&apos;t have an account? <a onClick={e => {navigate("/visualization/cropstress-signUp");}}>Sign Up</a></p> */}
                    <p className="no-account-note">Don&apos;t have an account? <a onClick={e => {navigate("/cropstress/signUp");}}>Sign Up</a></p>
                </Form.Item>
            </Form>
        </div>
    );
};


export default Login;