import React from "react";
import "./index.less";
import {
    AirPressureFourthIcon,
    IntensityIcon,
    VisiblityFourthIcon
} from "@/resource/IconComponents";
import IF from "@/components/IF";

interface IProps {
    include?: {
        [keyValue: string]: boolean
    }
    weatherData?: {
        [keyValu: string]: string
    }
    indent?: boolean
    justShowIcon?: boolean
}

const HourlyFourth = (props: IProps) => {
    const {
        include,
        weatherData,
        indent,
        justShowIcon
    } = props;

    return (
        <div className={`ag-fourth-div ${indent && "indent-container-class"}`}>
            <IF show={!justShowIcon}>
                <IF show={include["visibility"] || (!indent && Boolean(weatherData["visibility"]))}>
                    <div className="second-div">
                        <IF show={!indent}>
                            <div>
                                <VisiblityFourthIcon />
                            </div>
                            <label className="second-label">
                                {weatherData["visibility-name"]}
                            </label>
                        </IF>
                        <label className="third-label">
                            {weatherData["visibility"]}
                        </label>
                    </div>
                </IF>
                <IF show={include["Air Pressure (hPa)"] || (!indent && Boolean(weatherData["airpressure_hPa"]))}>
                    <div className="third-div">
                        <div>
                            <AirPressureFourthIcon />
                        </div>
                        <IF show={!indent}>
                            <label className="second-label">
                                {weatherData["airpressure_hPa-name"]}
                            </label>
                        </IF>
                        <label className="third-label">
                            {weatherData["airpressure_hPa"]}
                        </label>
                    </div>
                </IF>
                <IF show={include["Light Intensity (µmol/m²/s)"] || (!indent && Boolean(weatherData["gc_LightIntensity"]))}>
                    <div className="third-div">
                        <div>
                            <IntensityIcon />
                        </div>
                        <IF show={!indent}>
                            <label className="second-label">
                                {weatherData["gc_LightIntensity-name"]}
                            </label>
                        </IF>
                        <label className="third-label">
                            {weatherData["gc_LightIntensity"]}
                        </label>
                    </div>
                </IF>
                <IF show={include["Light Intensity (µmol/m²/d)"] || (!indent && Boolean(weatherData["gc_LightIntensity8Hourly"]))}>
                    <div className="third-div">
                        <div>
                            <IntensityIcon />
                        </div>
                        <IF show={!indent}>
                            <label className="second-label">
                                {weatherData["gc_LightIntensity8Hourly-name"]}
                            </label>
                        </IF>
                        <label className="third-label">
                            {weatherData["gc_LightIntensity8Hourly"]}
                        </label>
                    </div>
                </IF>
            </IF>
            <IF show={justShowIcon}>
                <IF show={justShowIcon && include["visibility"]}>
                    <div>
                        <VisiblityFourthIcon />
                    </div>
                </IF>
                <IF show={justShowIcon && include["Air Pressure (hPa)"]}>
                    <div>
                        <AirPressureFourthIcon />
                    </div>
                </IF>
                <IF show={justShowIcon && include["Light Intensity (µmol/m²/s)"]}>
                    <div>
                        <IntensityIcon />
                    </div>
                </IF>
                <IF show={justShowIcon && include["Light Intensity(mol/m²/d)"]}>
                    <div>
                        <IntensityIcon />
                    </div>
                </IF>
            </IF>
        </div>
    );
};

export default HourlyFourth;