import React, { useEffect, useRef, useState } from "react";
import "./index.less";
import GoogleMap from "google-maps-react-markers";
import useSupercluster from "use-supercluster";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/redux/reducer/store";
import {normalActionTypes} from "@/redux/actions/simulationAction";
// import Svg components
import {
    SimulationCurrentLocationMap,
    SimulationZoomOut,
    SimulationZoomIn,
    SimulationProfileCount,
    SimulationSelectedMarker,
    SimulationProfileMarker
} from "@/components/IconComponents/iconComponents";

const mapOptions = {
    zoomControl: false,
    mapTypeControl: false,
    streetViewControl: false,
};
interface IPoint{
    lat?: number;
    lng?: number;
    location?: string;
}
interface IProp{
    selectedLocation?: string;
    mapSelectedLocationCallBack?: (data:any)=>void
}

const googleMapComponents = (prop: IProp)=>{

    const {
        selectedLocation,
        mapSelectedLocationCallBack
    } = prop;
    const dispatch = useDispatch();
    // get data from redux
    const {
        simulationLocations,
        simulationLocationInfo,
        simulationData,
        simulationClickCount
    } = useSelector((state: RootState) => state.simulationReducer);
    // init setting
    const [defaultProps,setDefaultProps] = useState({
        center: {
            lat: -30.5,
            lng: 133.5
        },
        zoom: 3
    });
    const [currentSelectedPoint,setCurrentSelectedPoint] = useState<IPoint>(null);
    // bounds and zoom is used in the cluster
    const [mapBounds,setMapBounds] = useState<number[]>([]);
    const [mapZoom,setMapZoom] = useState(10);
    const [points,setPoints] = useState<any[]>([]);

    // this function is used to get map and maps instance
    const mapRef = useRef(null);
    const mapsRef = useRef(null);
    const onGoogleApiLoaded = ({map,maps})=>{
        mapRef.current = map;
        mapsRef.current = maps;
    };

    // cluster setting
    const {clusters} = useSupercluster({
        points,
        bounds: mapBounds,
        zoom: mapZoom,
        options: { radius: 75, maxZoom: 11 }
    });

    // change marker either selected
    const childMapSelectedLocationCallbackFunc = (data?:IPoint)=>{
        setCurrentSelectedPoint(data);
    };
    const mapMarkerCallback = (isClick:boolean,locationInfo?:string)=>{
        const tmpIsClick = isClick;
        let tmpLocationInfo = locationInfo;
        const tmpMapClickCount = simulationClickCount + 1;
        if(!isClick){
            // tmpIsClick = true;
            tmpLocationInfo = "0#0#0#0";
            changeMapCenterAndZoom(null,null,3);
        }
        dispatch({
            type: normalActionTypes.NORMAL_SIMULATION_IS_MAPCLICK,
            payload: tmpIsClick
        });
        dispatch({
            type: normalActionTypes.NORMAL_SIMULATION_LOCATION_INFO,
            payload: tmpLocationInfo
        });
        dispatch({
            type: normalActionTypes.NORMAL_SIMULATION_MAPCOUNT,
            payload: tmpMapClickCount
        });
    };

    const changeMapCenterAndZoom = (lat?:number,lng?:number,zoom?:number)=>{
        if(lat && lng){
            mapRef.current?.setCenter({lat,lng});
        }
        if(zoom){
            mapRef.current?.setZoom(zoom);
        }
    };

    // change simulation
    useEffect(()=>{
        if(simulationData?.queryId){
            const tmpDefaultProps = {...defaultProps};
            tmpDefaultProps.center.lat = simulationData?.queryGroupData[0]?.latitude;
            tmpDefaultProps.center.lng = simulationData?.queryGroupData[0]?.longitude;
            setDefaultProps(tmpDefaultProps);
        }
    },[simulationData]);

    useEffect(() => {
        if (simulationLocations && simulationLocations.length > 0) {
            const geoJsonPoints = simulationLocations.map((item, index) => {
                return {
                    type: "Feature",
                    properties: {
                        isSelected: false,
                        cluster: false,
                        crimeId: `${item.location}#${item.latitude}#${item.longitude}#${index}`,
                        category: "anti-social-behaviour"
                    },
                    geometry: {
                        type: "Point",
                        coordinates: [
                            item.longitude,
                            item.latitude,
                        ]
                    }
                };
            });
            setPoints(geoJsonPoints);
        }
        if (simulationLocations && simulationLocations.length === 0) {
            setPoints([]);
        }
    }, [simulationLocations]);

    const [simulationMarkers,setSimulationMarkers] = useState<{[key:number]:any}>({});
    const [simulationMarkersOrder,setSimulationMarkersOrder] = useState<number[]>([]);

    useEffect(()=>{
        if(simulationLocations?.length > 0 ){
            console.log("aaaaa locatinoInfo",simulationLocationInfo);
            const tmpMarkers: {[key:number]:any} = {};
            const tmpMarkersOrder:number[] = [];
            // const [location, latitude, longitude] = selectedLocationInfo?.split("#");
            const tmpInfo = simulationLocationInfo?.split("#") || "0#0#0#0";
            console.log("aa selected locationinfo selectedLocationInfo",simulationLocationInfo);
            console.log("aa selected locationinfo",tmpInfo[0]);
            console.log("aa selected locationinfo",tmpInfo[1]);
            console.log("aa selected locationinfo",tmpInfo[2]);
            simulationLocations.forEach((item,index)=>{
                const crimeId = `${item.location}#${item.latitude}#${item.longitude}#${index}`;
                let isSelected = false;
                if(simulationLocationInfo){
                    if (item?.latitude === Number(tmpInfo[1]) && item?.longitude === Number(tmpInfo[2])) {
                        isSelected = true;
                    }
                }
                const marker = (
                    <SimulationProfileMarker
                        key = {`marker-${crimeId}-key`}
                        lat={item?.latitude}
                        lng={item?.longitude}
                        crimeId={crimeId}
                        isSelected={isSelected}
                        mapSelectedLocationCallBack = {mapMarkerCallback}
                        className={"marker-svgIcon"}
                    />
                );
                tmpMarkers[index] = marker;
                tmpMarkersOrder.push(index);
            });
            setSimulationMarkers(tmpMarkers);
            setSimulationMarkersOrder(tmpMarkersOrder);

        }
    },[simulationLocations,simulationLocationInfo]);

    useEffect(()=>{
        console.log("ddd tmpCrimeId simulationLocationInfo",simulationLocationInfo);
        if(simulationLocationInfo && simulationLocationInfo != "0#0#0#0"){
            const tmpCrimeId = simulationLocationInfo?.split("#");
            console.log("ddd tmpCrimeId",tmpCrimeId);
            // if(Number(tmpCrimeId[1]) && Number(tmpCrimeId[2])){
            changeMapCenterAndZoom(Number(tmpCrimeId[1]),Number(tmpCrimeId[2]),10);
            // }
        }
    },[simulationLocationInfo]);

    console.log("data from redux",simulationData);

    return (
        <div className="google-map-components-all-container">
            <div style={{
                width: "600px",
                display:"flex",
                flexWrap: "wrap"
            }}>
                {/* <h2>Test Svg</h2> */}
                {/* <div>
                    SimulationProfileCount: <SimulationProfileCount/>
                    SimulationSelectedMarker: <SimulationSelectedMarker/>
                    SimulationProfileMarker: <SimulationProfileMarker/>
                </div> */}
                {/* {
                    simulationMarkersOrder?.map((index)=> simulationMarkers[index])
                } */}
            </div>
            <div className="google-map-container-div">
                <GoogleMap
                    apiKey={"AIzaSyDlsxkoR8-fiIgncnDunmEtWEwDmr896H0"}
                    defaultCenter={defaultProps.center}
                    defaultZoom={defaultProps.zoom}
                    options={mapOptions}
                    onGoogleApiLoaded = {onGoogleApiLoaded}
                    onChange={(map:any) => {
                        const ne = map?.bounds?.getNorthEast();
                        const sw = map?.bounds?.getSouthWest();
                        console.log("ne lng",ne.lng());
                        console.log("ne lat",ne.lat());
                        console.log("sw lng",sw.lng());
                        console.log("sw lat",sw.lat());
                        setMapBounds([sw.lng(), sw.lat(), ne.lng(), ne.lat()]);
                        setMapZoom(map?.zoom);
                    }}
                    yesIWantToUseGoogleMapApiInternals = {true}
                >
                    {clusters.length > 0 && clusters.map(cluster => {
                        console.log("test marker",cluster);
                        const [longitude, latitude] = cluster.geometry.coordinates;
                        const {
                            cluster: isCluster,
                            point_count: pointCount
                        } = cluster.properties;
                        if (isCluster) {
                            return (
                                <SimulationProfileCount
                                    key={`cluster-${cluster.id}`}
                                    lat={latitude}
                                    lng={longitude}
                                    className='marker-annotation-count'
                                    // childOnClickFunc={() => {
                                    //     console.log("test!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", pointCount);
                                    // }}
                                >
                                    <span
                                        className={pointCount > 9 ? "point-count-more-than-99-span" : "point-count-span"}
                                    >
                                        {pointCount > 99 ? "99+" : pointCount}
                                    </span>
                                    {/* </div> */}
                                </SimulationProfileCount>
                            );
                        }
                        let isSelected = false;
                        const tmpId = cluster?.properties?.crimeId?.split("#")?.slice(0,3)?.join("#");
                        const tmpLocation = simulationLocationInfo?.split("#").slice(0,3)?.join("#");
                        if(tmpId === tmpLocation){
                            // changeMapCenterAndZoom(cluster?.geometry?.coordinates[1],cluster?.geometry?.coordinates[0],10);
                            isSelected = true;
                        }
                        // console.log("selected cluster:",cluster.properties);
                        // const selectObj = selectedLocation ? simulationLocations.filter(x => x.location == selectedLocation)[0] : null;
                        // // const selectObj = selectedLocation ? simulationLocations.filter(x => x.location == selectedLocation)[0] : initSelectObj;
                        // const latlog = cluster.properties.crimeId.split("#");
                        // if (initSelectObj.crimeId) {
                        //     if (initSelectObj.crimeId === cluster.properties.crimeId) {
                        //         isSelected = true;
                        //     }
                        // } else if (selectedLocation) {
                        //     if (parseFloat(latlog[1]) === parseFloat(selectObj.latitude) && parseFloat(latlog[2]) === parseFloat(selectObj.longitude)) {
                        //         isSelected = true;
                        //     }
                        // }
                        // if(selectedLocation){
                        //     if (Number(latlog[1]) === Number(selectObj?.latitude) && Number(latlog[2]) === Number(selectObj?.longitude)) {
                        //         isSelected = true;
                        //     }
                        // }
                        return (
                            <SimulationProfileMarker
                                key = {`marker-${cluster?.properties?.crimeId}-key`}
                                lat={cluster?.geometry?.coordinates[1]}
                                lng={cluster?.geometry?.coordinates[0]}
                                crimeId={cluster?.properties?.crimeId}
                                isSelected={isSelected}
                                mapSelectedLocationCallBack = {mapMarkerCallback}
                                className={"marker-svgIcon"}
                            />
                        );
                    })}
                </GoogleMap>
            </div>
        </div>
    );
};

export default React.memo(googleMapComponents);

