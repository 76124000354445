import React, { useCallback, useEffect, useState } from "react";
import "./index.less";
import { Link, useParams } from "react-router-dom";
import { getCalculatorConvertorDataFromXML } from "@/util/http";
import { IGetCalculatorConvertorResModel } from "@/util/http/responseType";
import { IGetCalculatorConvertorReqModel } from "@/util/http/requestType";
import IF from "@/components/IF";
import { Button, Col, Input, Row, Space, Tabs } from "antd";
import { ResetValueIcon } from "@/resource/IconComponents";
import TabPane from "antd/es/tabs/TabPane";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { useLocation } from "react-router-dom";

enum tabEum {
    Nozzle = "Nozzle",
    Loading = "Loading"
}

enum themeEnum {
    LightTheme = "light-theme",
    DarkTheme = "dark-theme"
}

const SprayerSetupCalculators = () => {
    const urlLocation = useLocation();
    const headtitlebgReg = /(headtitlebg=)([^&]+)/i;
    const buttonbgReg = /(buttonbg=)([^&]+)/i;

    const headtitlebgRes = urlLocation.pathname.match(headtitlebgReg);
    const buttonbgRes = urlLocation.pathname.match(buttonbgReg);

    const headtitlebg=headtitlebgRes && headtitlebgRes[2]||null;
    const buttonbg=buttonbgRes && buttonbgRes[2]||null;

    //if it is hexadecimal color value
    function isHexColor(color) {
        const regex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;
        return regex.test(color);
    }
    //Encapsulate the logic of creating style objects into a function
    function createBgStyle(hexColor) {
        if (hexColor) {
            // Ensure that hexColor is a valid hexadecimal color value
            if (isHexColor(hexColor)) {
                return {
                    backgroundColor: hexColor,
                    color: "#FFFFFF",
                    border:"none"
                };
            } else {
                // If hexColor is not a valid color value, print the log
                console.log("Please provide hexadecimal color values");
            }
        }
        // If hexColor is not provided, return null
        return null;
    }
    // Creating Style Objects with Functions
    const headTitleStyle = createBgStyle("#" + headtitlebg);
    const buttonbgStyle = createBgStyle("#" + buttonbg);

    // add google analysis
    useEffect(() => {
        console.log("just invoke once");
        const head = document.getElementsByTagName("head")[0];
        const googleAnalyticsCode = "G-V7DYXQDJXJ";
        const googleAnalyticsFirstScript = document.createElement("script");
        googleAnalyticsFirstScript.async = true;
        googleAnalyticsFirstScript.src =
          "https://www.googletagmanager.com/gtag/js?id=" + googleAnalyticsCode;

        const googleAnalyticsSecondScript = document.createElement("script");
        googleAnalyticsSecondScript.innerHTML =
          "    window.dataLayer = window.dataLayer || [];\n" +
          "    function gtag(){dataLayer.push(arguments);}\n" +
          "    gtag('js', new Date());\n" +
          "\n" +
          "    gtag('config', '" +
          googleAnalyticsCode +
          "',{\n" +
          "cookie_flags:'max-age=7200;secure;samesite-none'" +
          "});";

        head.insertBefore(googleAnalyticsSecondScript, head.firstChild);
        head.insertBefore(googleAnalyticsFirstScript, head.firstChild);
    }, []);

    const routerParams = useParams();
    // use regex to get title, unit, description
    const formatTitle = (title: string) => {
        const strReg = /\{((\S| )*)\}/;
        return title.match(strReg) == null ? title : title.match(strReg)[1].trim();
    };
    const getUnit = (description: string) => {
        const strReg = /\s+(\([a-zA-Z0-9/<>.° ]*\))\s*/;
        description = formatTitle(description);
        if (description.match(strReg) == null) {
            return null;
        }
        else {
            const strUnit = description.match(strReg)[1].trim();
            return <div dangerouslySetInnerHTML={{ __html: strUnit.match(/[^()]*/g)[1].trim() }}></div>;
        }
    };
    const getDescription = (description: string) => {
        const strReg = /[a-zA-Z0-9/_ ]*(\(\d*\))?/;
        description = formatTitle(description);
        return description.match(strReg) == null ? description : description.match(strReg)[0].trim();
    };
    // reset calculators & converters
    const resetCalculator = (calculatorIndex) => {
        const newDetails = { ...details };
        const newCalculator = newDetails.calculators[calculatorIndex];
        for (let x = 0; x < newCalculator.columns.length; x++) {
            newCalculator.columns[x].value = "";
        }
        newDetails.calculators[calculatorIndex] = newCalculator;
        setDetails(newDetails);
    };
    const resetConverter = (converterIndex) => {
        const newDetails = { ...details };
        const newConvertor = newDetails.convertors[converterIndex];
        for (let x = 0; x < newConvertor.columns.length; x++) {
            newConvertor.columns[x].value = "";
        }
        newDetails.convertors[converterIndex] = newConvertor;
        setDetails(newDetails);
    };
    const formatCalculateResult = (value: string) => {
        if (value == null || value == undefined || value == "" || value == "NaN") return value;
        const num = (+value);
        if (num == 0) return 0;
        if (Math.abs(num) > 1E6 || Math.abs(num) < 1E-6)
            return Number(num).toExponential(4);
        if (Math.abs(num) > 1)
            return Number(num).toFixed(2);
        if (Math.abs(num) < 1)
            return Math.round(num * 1E6) / 1E6;
        return num;
    };
    const changeCalaulatorValue = (calculatorIndex, expression, value) => {
        const newDetails = { ...details };
        const newCalculator = newDetails.calculators[calculatorIndex];
        for (let x = 0; x < newCalculator.columns.length; x++) {
            if (newCalculator.columns[x].expression == expression) {
                newCalculator.columns[x].value = value;
                break;
            }
        }
        newDetails.calculators[calculatorIndex] = newCalculator;
        setDetails(newDetails);
    };
    const calculateCalculatorValue = (calculatorIndex) => {
        const newDetails = { ...details };
        const newCalculator = newDetails.calculators[calculatorIndex];
        if (newCalculator.columns.filter(x => x.expressionType == "CNV Calculation Parameter" && (x.value ?? "") == "").length > 0) {
            for (let x = 0; x < newCalculator.columns.length; x++) {
                if (newCalculator.columns[x].expressionType == "CNV Calculation Result") {
                    newCalculator.columns[x].value = "NaN";
                }
            }
        } else {
            const strExpressions = newCalculator.columns.filter(x => x.expressionType == "CNV Calculation Parameter").map(x => `var ${x.expression}=${x.value};`).join(" ");
            for (let x = 0; x < newCalculator.columns.length; x++) {
                if (newCalculator.columns[x].expressionType == "CNV Calculation Result") {
                    let result = 0;
                    result = 0;
                    eval(`${strExpressions}; result=${newCalculator.columns[x].expression};`);
                    newCalculator.columns[x].value = `${result}`;
                }
            }
        }
        newDetails.calculators[calculatorIndex] = newCalculator;
        setDetails(newDetails);
    };
    const changeConvertorValue = (convertorIndex, columnIndex, value) => {
        const newDetails = { ...details };
        const newConvertor = newDetails.convertors[convertorIndex];
        newConvertor.columns[columnIndex].value = value ?? "";
        if (newConvertor.columns[columnIndex].value == "") {
            for (let x = 0; x < newConvertor.columns.length; x++) {
                newConvertor.columns[x].value = "";
            }
        } else {
            let val = 0;
            for (let x = 0; x < newConvertor.columns.length; x++) {
                val = (+newConvertor.columns[columnIndex].value);
                eval(`val=${newConvertor.columns[columnIndex].toReference}`);
                if (x != columnIndex) {
                    eval(`val=${newConvertor.columns[x].fromReference}`);
                    newConvertor.columns[x].value = `${formatCalculateResult(`${val}`)}`;
                }
            }
        }
        newDetails.convertors[convertorIndex] = newConvertor;
        setDetails(newDetails);
    };

    const [currentTab, setCurrentTab] = useState<string>(tabEum.Nozzle.toString());
    const [currentTheme, setCurrentTheme] = useState<string>(themeEnum.LightTheme.toString());
    const [basicParams, setBasicParams] = useState<IGetCalculatorConvertorReqModel>({
        language: "en",
        countryCode: "GB",
        token: "F56B5906-C23E-4EA2-914D-BAB9973E490D"
    });
    const tabs = [{
        key: tabEum.Nozzle.toString(),
        title: "Sprayer Set-up Calculators"
    }, {
        key: tabEum.Loading.toString(),
        title: "Loading Calculations"
    }];

    const handleSwitchTab = (key) => {
        setCurrentTab(key);
    };
    const [clickTragger,setClickTragger] = useState(false);
    const [showHelpNotes, setShowHelpNotes] = useState<boolean>(false);
    const [showHelpNotesForAU, setShowHelpNotesForAU] = useState<boolean>(false);
    // const toggleHelp = useCallback((toggle: boolean) => {
    //     let show = showHelpNotes || showHelpNotesForAU;
    //     if (toggle) show = !show;
    //     if (show) {
    //         if (currentTab == tabEum.Nozzle.toString() && basicParams.countryCode.toUpperCase() == "AU") {
    //             setShowHelpNotes(false);
    //             setShowHelpNotesForAU(true);
    //         } else if (currentTab == tabEum.Nozzle.toString() && basicParams.countryCode.toUpperCase() != "AU") {
    //             setShowHelpNotes(true);
    //             setShowHelpNotesForAU(false);
    //         } else if (currentTab == tabEum.Loading.toString()) {
    //             setShowHelpNotes(true);
    //             setShowHelpNotesForAU(false);
    //         }
    //     } else {
    //         setShowHelpNotes(false);
    //         setShowHelpNotesForAU(false);
    //     }
    // }, [showHelpNotes, showHelpNotesForAU, currentTab]);

    const helpSwitch=(trigger?: boolean)=>{
        let tmpHelp = showHelpNotes;
        let tmpHelpAU = showHelpNotesForAU;
        if(trigger){
            if(tmpHelp || tmpHelpAU){
                tmpHelp = false;
                tmpHelpAU = false;
            }
            else{
                if ( basicParams.language.toLowerCase() == "en") {
                    tmpHelp = false;
                    tmpHelpAU = true;
                } else if (basicParams.language.toLowerCase() != "en") {
                    tmpHelp = true;
                    tmpHelpAU = false;
                }
            }
        }
        else{
            if(!clickTragger) return;
            if ( basicParams.language.toLowerCase() == "en") {
                tmpHelp = false;
                tmpHelpAU = true;
            } else if (basicParams.language.toLowerCase() != "en") {
                tmpHelp = true;
                tmpHelpAU = false;
            }
        }
        setShowHelpNotes(tmpHelp);
        setShowHelpNotesForAU(tmpHelpAU);
    };

    // useEffect(() => {
    //     toggleHelp(false);
    // }, [currentTab]);

    const [details, setDetails] = useState<IGetCalculatorConvertorResModel | null>(null);

    const getCalculatorAndConvertors = async (basicParams) => {
        const res = await getCalculatorConvertorDataFromXML(basicParams);
        setDetails(res.data);
    };

    useEffect(() => {
        const { language, countryCode, token, param4, param5 } = routerParams;
        let _tab = tabEum.Nozzle.toString();
        let _theme = themeEnum.LightTheme.toString();
        if (param4) {
            if (param4.match(`/${tabEum.Loading.toString()}/ig`)) _tab = tabEum.Loading.toString();
            else if (param4.match(/dark/ig)) _theme = themeEnum.DarkTheme.toString();
        }
        if (param5) {
            if (param5.match(`/${tabEum.Loading.toString()}/ig`)) _tab = tabEum.Loading.toString();
            else if (param5.match(/dark/ig)) _theme = themeEnum.DarkTheme.toString();
        }
        setBasicParams({ ...basicParams, language, countryCode, token });
        setCurrentTab(_tab);
        setCurrentTheme(_theme);
    }, []);

    useEffect(() => {
        getCalculatorAndConvertors(basicParams);
    }, [basicParams]);

    return (
        <div className={`div-container ${currentTheme}`}>
            <div style={headTitleStyle} className="pageTitleDiv">
                <p className="pageTitle">
            Sprayer Set-up & Loading Calculators
                </p>
            </div>
            <div className="div-content">
                <div className="div-main">
                    <Space.Compact >
                        <Link className="question-link" to={undefined} onClick={(e) => {
                            setClickTragger(!clickTragger);
                            helpSwitch(true);
                        }}>
                            <QuestionCircleOutlined className="question-icon" />
                        </Link>
                    </Space.Compact>
                    <Tabs className="div-tabs" size="large" type="line"
                        tabPosition="top"
                        centered={false}
                        animated={false}
                        activeKey={currentTab} tabBarGutter={100}
                        tabBarStyle={{ marginBottom: "0px", lineHeight: "18px", marginLeft: "0px", position: "initial" }}
                        defaultActiveKey={currentTab}
                        onChange={handleSwitchTab}>
                        {
                            tabs.map(tab =>
                                <TabPane className="tab-container"
                                    key={tab.key}
                                    tab={
                                        <Link className={`tab-link ${tab.key == currentTab ? "tab-link-active" : ""}`} to={undefined}>
                                            {tab.title}
                                        </Link>}>
                                    <div className="tab-div-container">
                                        <IF show={showHelpNotes}>
                                            <div className="div-notes">
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                            Suspendisse varius enim in eros elementum tristique.
                                            Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam libero vitae erat.
                                            Aenean faucibus nibh et justo cursus id rutrum lorem imperdiet. Nunc ut sem vitae risus tristique posuere.
                                            </div>
                                        </IF>
                                        <IF show={showHelpNotesForAU}>
                                            <div className="div-notes">
                                            Need some help setting up your sprayer?
                                            Contact your local Syngenta sales manager to set up a Spray Workshop for you and your colleagues.
                                            Or ask us a question about these calculators via <Link to={"mailto:greencast.au@syngenta.com"}>greencast.au@syngenta.com</Link>.
                                            </div>
                                        </IF>
                                        <Row gutter={36} className="tab-row">
                                            <IF show={details != null && details.calculators != null}>
                                                {
                                                    details == null || details.calculators == null ? null :
                                                        details.calculators.map((calculator, calculatorIndex) =>
                                                            formatTitle(calculator.buttontext) != currentTab ? null :
                                                                <Col xs={23} sm={23} md={12} lg={9} xl={7} key={calculatorIndex} className="tab-card">
                                                                    <div className="card-container">
                                                                        <div className="card-header">
                                                                            <div className="card-title">{formatTitle(calculator.title)}</div>
                                                                            <div className="card-reset" onClick={(e) => { resetCalculator(calculatorIndex); }}>
                                                                                <ResetValueIcon />
                                                                                <span className="card-reset-text">Reset Values</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="card-content">
                                                                            {
                                                                                calculator.columns.filter(x => x.expressionType == "CNV Calculation Parameter").map((column, columnIndex) =>
                                                                                    <div className="card-row" key={columnIndex}>
                                                                                        <Row gutter={36}>
                                                                                            <Col xs={23} sm={23} md={10} lg={10} xl={10}>
                                                                                                <span className="card-row-title">{getDescription(column.label)} :</span>
                                                                                            </Col>
                                                                                            <Col xs={23} sm={23} md={6} lg={6} xl={6}>
                                                                                                <Input type="number" addonAfter={getUnit(column.label)} value={column.value}
                                                                                                    onChange={(e) => { changeCalaulatorValue(calculatorIndex, column.expression, e.target.value); }} />
                                                                                            </Col>
                                                                                        </Row>
                                                                                    </div>
                                                                                )
                                                                            }
                                                                            <div className="divider"></div>
                                                                            <Button style={buttonbgStyle}
                                                                                className="btn-calculate"
                                                                                onClick={() => { calculateCalculatorValue(calculatorIndex); }}>
                                                                                Calculate
                                                                            </Button>
                                                                            {
                                                                                calculator.columns.filter(x => x.expressionType == "CNV Calculation Result").map((column, columnIndex) =>
                                                                                    <div className="card-row" style={{ marginTop: "14px" }} key={columnIndex}>
                                                                                        <span className="card-result-title">{formatTitle(column.label)}</span>
                                                                                        <div className="card-result-div">
                                                                                            <span className="card-result-value">{formatCalculateResult(column.value)}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                        )
                                                }
                                            </IF>
                                            <IF show={currentTab == tabEum.Nozzle.toString() && details != null && details.convertors != null}>
                                                {
                                                    details == null || details.convertors == null ? null :
                                                        details.convertors.map((converter, converterIndex) =>
                                                            <Col xs={23} sm={23} md={12} lg={9} xl={7} key={converterIndex} className="tab-card">
                                                                <div className="card-container">
                                                                    <div className="card-header">
                                                                        <div className="card-title">{formatTitle(converter.groupname)}</div>
                                                                        <div className="card-reset" onClick={(e) => { resetConverter(converterIndex); }}>
                                                                            <ResetValueIcon />
                                                                            <span className="card-reset-text">Reset Values</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="card-content">
                                                                        {
                                                                            converter.columns.map((column, columnIndex) =>
                                                                                <div className="card-row" key={columnIndex}>
                                                                                    <span className="card-row-title">{getDescription(column.label)} :</span>
                                                                                    <Space.Compact size="large" style={{ float: "right" }}>
                                                                                        <Input type="number" addonAfter={getUnit(column.label)} value={column.value}
                                                                                            onChange={(e) => { changeConvertorValue(converterIndex, columnIndex, e.target.value); }} />
                                                                                    </Space.Compact>
                                                                                </div>
                                                                            )
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        )
                                                }
                                            </IF>
                                        </Row>
                                    </div>
                                </TabPane>)
                        }
                        destroyInactiveTabPane={true}
                    </Tabs>
                </div>
            </div>
        </div>
    );
};

export default SprayerSetupCalculators;