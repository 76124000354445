import { getHistoricalCharInfo, getSeries } from "@/util/http/HistWeather";
import { IGetHistoricCharInfoResModel, IGetSeriesResModel } from "@/util/http/HistWeather/responseType";
import { useRequest } from "ahooks";
import { useCallback, useEffect,useRef,useState } from "react";
import {debounce} from "lodash";
import { useDispatch } from "react-redux";
import { normalActionTypes } from "@/redux/actions/diseaseAction";

interface IProp{
    country: string;
    language: string;
    latitude: number;
    longitude: number;
    unit: string;
    startdate: string;
    enddate: string;
    gddmax: number;
    gddmin: number;
    isExecute?: boolean;
}

const useWeatherData = (props:IProp)=>{
    const {
        country,
        language,
        latitude,
        longitude,
        unit,
        startdate,
        enddate,
        gddmax,
        gddmin,
        isExecute
    } = props;


    const dispatch = useDispatch();

    // static data
    const pubname = "geotroll1";
    const servicepage = "HistoryData_ECMWF";
    const checkedSeries = "node1_cb_TempAir_C_DaytimeMax;node1_cb_TempAir_C_NighttimeMin;node5_cb_GDD_C_DailySum;node5_cb_GDD_C_Accumulated";
    const aggregation = "daily";
    const locAltitude = 3;

    console.log("echart components",country);
    console.log("echart components",language);
    console.log("echart components",unit);
    // get final data
    const [echartData,setEchartData] = useState<IGetHistoricCharInfoResModel>(null);

    const getHistoricalCharInfoFunc = async()=>{
        console.log("echart components start run function");
        try{
            console.log("get data");
            dispatch({ type: normalActionTypes.NORMAL_SHOWLODDING, payload: true});
            const res = await getHistoricalCharInfo({
                pubname: pubname,
                // servicepage,
                unit: unit,
                culture: language + "-" + country,
                countryCode: country,
                latitude: latitude,
                longitude: longitude,
                startDate: startdate,
                endDate: enddate,
                aggregation: aggregation,
                locAltitude: locAltitude,
                checkedSeries: checkedSeries,
                maxAltitudeDiff: gddmax,
                maxDistanceDiff: gddmin,
                isMBHistGreencast: true
            });
            setEchartData(res.data);
            dispatch({ type: normalActionTypes.NORMAL_SHOWLODDING, payload: false});
        }
        catch(error){
            console.log("get HistroicCharInfoFunc failed",error);
        }
    };


    useEffect(()=>{
        const myDebounceFunc = debounce(getHistoricalCharInfoFunc,500);
        if(country && language && unit && isExecute ) {
            myDebounceFunc();
        }
        return()=>{
            // myRef.current.cancel();
            myDebounceFunc.cancel();
        };
    },[country,language,unit,isExecute]);

    return {
        echartData
    };
};

export default useWeatherData;