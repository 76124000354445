import React, { useState,useEffect, useRef } from "react";
import { Input, Table , Row, Col,Space,Radio,Button} from "antd";
import { useNavigate } from "react-router-dom";
import { sagaActionTypes } from "@/redux/actions/quantisAction";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/redux/reducer/store";
import store from "@/util/store";
import {getUpdateUserRole,getCurrentUserRole} from "@/util/http/Quantis/index";
import { normalActionTypes } from "@/redux/actions/quantisAction";
import "./index.less";
import { IUserDataModel } from "@/util/http/Quantis/responseType";

const UserManageMent = ()=>{
    const {
        userList
    } = useSelector((state: RootState) => state.quantisReducer);

    const { Search } = Input;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const currentUser = store.get("quantisUser");
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [email,setEmail]=useState("");
    let newAdminArr: any[] = [];
    let newOrdinaryArr: any[] = [];
    //只运行一次(仅在组件挂载和卸载时执行)
    useEffect(()=>{
        dispatch({
            type: sagaActionTypes.SAGA_GET_USERLISTBYEMAIL,
            payload: email
        });
    },[]);

    const userListRef = useRef<IUserDataModel[]>(null);
    useEffect(()=>{
        if(userList?.length >= 1){
            const tmpUserList:IUserDataModel[] = JSON.parse(JSON.stringify(userList));
            userListRef.current = tmpUserList;
        }
    },[userList]);



    const onSearch = (value?:string) => {
        setEmail(value);
        dispatch({
            type: sagaActionTypes.SAGA_GET_USERLISTBYEMAIL,
            payload:{
                email: value
            }
        });
    };
    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {

            newAdminArr = [];
            newOrdinaryArr = [];
            for (let i = 0; i < selectedRowKeys.length; i++) {
                const p = selectedRowKeys[i];
                if (selectedRows[i].role == "admin") {
                    newAdminArr.push(p);
                }
                if (selectedRows[i].role == "ordinary") {
                    newOrdinaryArr.push(p);
                }
            }
        },
    };
    const onRowChange = (selectedRowKeys: React.Key[], selectedRows: any[]) => {
        console.log(`selectedRowKeys: ${selectedRowKeys}`, "selectedRows: ", selectedRows);
        setSelectedRowKeys(selectedRowKeys);
    };

    const radioOnChange = (e, id) => {
        let tmpIndex = 0;
        userList.forEach((item,index)=>{
            if(item.userID === id) tmpIndex = index;
        });
        userListRef.current[tmpIndex]["role"] = e.target.value;
        console.log("user manager radioOnChange userListRef.current",userListRef.current);
        console.log("user manager radioOnChange userList",userList);
    };

    const updateUserRole = async() => {
        selectedRowKeys?.forEach((it: any, index) => {
            const tmp = userListRef.current.filter((item: any, index) => item.userID == it)[0];

            if (tmp.role == "admin") {
                newAdminArr.push(tmp.userID);
            }
            if (tmp.role == "ordinary") {
                newOrdinaryArr.push(tmp.userID);
            }
        });
        const res = await getUpdateUserRole({
            adminUserIDs: newAdminArr.toString(),
            ordinaryUserIDs: newOrdinaryArr.toString()
        });

        if(res.data=="Success"){
            dispatch({
                type: normalActionTypes.NORMAL_UPDATE_SHOWSUCCESSMESSAGE,
                payload: res.data
            });
        }else{
            dispatch({
                type: normalActionTypes.NORMAL_UPDATE_SHOWERRORMESSAGE,
                payload: res.data
            });
        }
        // when set the setSelectedRowKeys empty, the checkbox can be nothing selected
        setSelectedRowKeys([]);
    };

    const columns= [
        {
            title: "Email Id",
            dataIndex: "email",
        },
        {
            title: "Role",
            dataIndex: "role",
            key: "userID",
            render: (text, record) => {
                return (
                    <Space size="middle" >
                        <Radio.Group
                            onChange={(e) => { radioOnChange(e, record.userID); }}
                            defaultValue={userList.filter((item: any) => item.userID == record.userID)[0]?.role}
                        >
                            <Radio value={"admin"} >Administrator</Radio>
                            <Radio value={"ordinary"}>Ordinary user</Radio>
                        </Radio.Group>
                    </Space>
                );
            },
        }
    ];
    const changeRole = () => {
        updateUserRole();
        selectedRowKeys?.forEach((it: any, index) => {
            // const tmpUser = userList.filter((item: any, index) => item.userID == it)[0];
            const tmpUser = userListRef.current.filter((item: any, index) => item.userID == it)[0];
            if (tmpUser.email == currentUser) {
                if (tmpUser.role == "ordinary") {
                    store.set("quantisUserRole", "ordinary");
                    // navigate("/visualization/cropstress");
                    navigate("/cropstress/home");
                }
            }
        });
        dispatch({
            type: sagaActionTypes.SAGA_GET_USERLISTBYEMAIL,
            payload:{
                email: email
            }
        });
    };
    console.log("usermanager userList",userList);
    return(
        <div className="user-management-style">
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="rowStyle">
                <Col className="gutter-row" xs={8} sm={8} md={4} lg={3} xl={3}>
                    <span>Email ID:</span>
                </Col>
                <Col className="gutter-row" xs={16} sm={12} md={10} lg={9} xl={8}>
                    <Search
                        className="searchStyle"
                        placeholder="input search text"
                        allowClear
                        enterButton="Search"
                        size="middle"
                        onSearch={onSearch}
                    />
                </Col>
            </Row>
            <Table className="userTable"
                rowKey={record => record.userID}
                rowSelection={{
                    type: "checkbox",
                    preserveSelectedRowKeys: true,
                    onChange: onRowChange,
                    selectedRowKeys: selectedRowKeys}}
                columns={columns}
                dataSource={userList}/>
            <Button type='primary' onClick={changeRole}>Save</Button>
        </div>
    );
};

export default UserManageMent;