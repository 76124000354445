import "./index.less";

import React, { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import QueryChartComponent from "../QueryChart";
import { NoDataIcon } from "@/resource/IconComponents";
import IF from "@/components/IF";
import {
    downloadQueryRawData
} from "@/util/http";

enum TabOptionEnum {
  AttainableYield = "Attainable yield",
  DaysToFlowering = "Days to flowering",
  CropCycleDuration = "Crop cycle duration",
}
enum FieldKeyEnum {
  Yield = "yield",
  DapR1 = "dapR1",
  DapR6 = "dapR6",
}

interface IProps {
  chartsData: [];
  queryId: number;
  showMapViewBtn: boolean;
  mapViewCallback?: () => void;
}

export default function QueryTabPaneComponent(
    props: IProps = {
        chartsData: [],
        queryId: 0,
        showMapViewBtn: false,
    },
) {

    const dispatch = useDispatch();

    const {
        chartsData,
        queryId,
        showMapViewBtn,
        mapViewCallback,
    } = props;
    const [tabActive, setTabActive] = useState<string>("");
    const [waters, setWaters] = useState<any>(null);

    useEffect(() => {
        setTabActive(TabOptionEnum.AttainableYield);
        const waters: string[] = [];
        chartsData.forEach((x: any) => {
            x.data.forEach((d: any) => {
                waters.push(d.water);
            });
        });
        const distictWaters: string[] = Array.from(new Set(waters));
        setWaters(distictWaters);
    }, [chartsData]);

    const changeTab = useCallback((tabName) => {
        setTabActive(tabName);
    }, []);
    const download = useCallback(() => {
        console.log("777777Enter Download");
        let fieldKey = "";
        switch (tabActive) {
            case TabOptionEnum.AttainableYield:
                fieldKey = FieldKeyEnum.Yield.toString();
                break;
            case TabOptionEnum.DaysToFlowering:
                fieldKey = FieldKeyEnum.DapR1.toString();
                break;
            case TabOptionEnum.CropCycleDuration:
                fieldKey = FieldKeyEnum.DapR6.toString();
                break;
        }
        // dispatch({
        //     type: "query/downloadQueryRawData",
        //     payload: { queryId: queryId, fieldKey: fieldKey },
        // });
        console.log("777777queryId",typeof(queryId));
        console.log("777777queryId",queryId);
        downFile(queryId,fieldKey);
    }, [queryId, tabActive]);

    const downFile=(queryId:number,fieldKey:string)=>{
        const res = downloadQueryRawData(
            {
                queryId:queryId,
                fieldKey:fieldKey
            });
        console.log("downFile res",res);
    };
    const mapView = useCallback(() => {
        if (mapViewCallback) {
            mapViewCallback();
        }
    }, [mapViewCallback]);
    console.log("chartsData",chartsData);
    return (
        <div>
            {/* All chart and map button container */}
            <div className="div-tab-bar">
                {/* Attainable yield */}
                <div
                    className={`div-tab-pane ${
                        tabActive === TabOptionEnum.AttainableYield ? "tab-active" : ""
                    }`}
                    key={TabOptionEnum.AttainableYield}
                    onClick={() => {
                        changeTab(TabOptionEnum.AttainableYield);
                    }}
                >
                    {TabOptionEnum.AttainableYield.toString()}
                </div>
                {/* Days to flowering */}
                <div
                    className={`div-tab-pane ${
                        tabActive === TabOptionEnum.DaysToFlowering ? "tab-active" : ""
                    }`}
                    key={TabOptionEnum.DaysToFlowering}
                    onClick={() => {
                        changeTab(TabOptionEnum.DaysToFlowering);
                    }}
                >
                    {TabOptionEnum.DaysToFlowering.toString()}
                </div>
                {/* Crop cycle duration */}
                <div
                    className={`div-tab-pane ${
                        tabActive === TabOptionEnum.CropCycleDuration ? "tab-active" : ""
                    }`}
                    key={TabOptionEnum.CropCycleDuration}
                    onClick={() => {
                        changeTab(TabOptionEnum.CropCycleDuration);
                    }}
                >
                    {TabOptionEnum.CropCycleDuration.toString()}
                </div>

                {/* Download button */}
                <div className="div-download">
                    <input
                        type="button"
                        className="btn-download"
                        value="Download"
                        onClick={download}
                    />
                </div>
                {/* Map View button */}
                <IF show={showMapViewBtn}>
                    <div className="div-map-view">
                        <input
                            type="button"
                            className="btn-map-view"
                            value="Map View"
                            onClick={mapView}
                        />
                    </div>
                </IF>
            </div>

            {/* Show details information */}

            {/* Attainable yield : show information components */}
            <div
                className="div-tab-content"
                style={{
                    display:
                    tabActive === TabOptionEnum.AttainableYield ? "block" : "none",
                }}
            >
                {chartsData ? (
                    chartsData?.map((data, index) => (
                        <QueryChartComponent
                            key={index}
                            fieldKey={FieldKeyEnum.Yield}
                            chartData={data}
                            waters={waters}
                        />
                    ))
                ) : (
                    <NoDataIcon />
                )}
            </div>

            {/* Days to flowering: show information components */}
            <div
                className="div-tab-content"
                style={{
                    display:
                    tabActive === TabOptionEnum.DaysToFlowering ? "block" : "none",
                }}
            >
                {chartsData ? (
                    chartsData?.map((data, index) => (
                        <QueryChartComponent
                            key={index}
                            fieldKey={FieldKeyEnum.DapR1}
                            chartData={data}
                            waters={waters}
                        />
                    ))
                ) : (
                    <NoDataIcon />
                )}
            </div>

            {/* Crop cycle duration: show information components */}
            <div
                className="div-tab-content"
                style={{
                    display:
                    tabActive === TabOptionEnum.CropCycleDuration ? "block" : "none",
                }}
            >
                {chartsData ? (
                    chartsData?.map((data, index) => (
                        <QueryChartComponent
                            key={index}
                            fieldKey={FieldKeyEnum.DapR6}
                            chartData={data}
                            waters={waters}
                        />
                    ))
                ) : (
                    <NoDataIcon />
                )}
            </div>
            <div style={{ height: "24px", background: "#F3F4F6" }}></div>
        </div>
    );
}
