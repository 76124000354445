export const zoomConfig = [
    {
        countryCode: "AE",
        conditions: [
            { zoomRange: [0,5], width: 15, height: 15},
            { zoomRange: [6,6], width: 20, height: 20},
            { zoomRange: [7,7], width: 25, height: 25},
            { zoomRange: [0,8], width: 30, height: 30},
            { zoomRange: [9,9], width: 35, height: 35},
            { zoomRange: [10,Infinity], width: 45, height: 45},
        ]
    },
    {
        countryCode: "GB",
        conditions: [
            { zoomRange: [0,4], width: 10, height: 10},
            { zoomRange: [5,5], width: 15, height: 15},
            { zoomRange: [6,6], width: 20, height: 20},
            { zoomRange: [7,7], width: 25, height: 25},
            { zoomRange: [8,8], width: 30, height: 30},
            { zoomRange: [9,9], width: 35, height: 35},
            { zoomRange: [10,Infinity], width: 45, height: 45},
        ]
    },
    {
        countryCode: "AU",
        conditions: [
            { zoomRange: [0,4], width: 10, height: 10},
            { zoomRange: [5,5], width: 15, height: 15},
            { zoomRange: [6,6], width: 20, height: 20},
            { zoomRange: [7,7], width: 25, height: 25},
            { zoomRange: [8,8], width: 30, height: 30},
            { zoomRange: [9,9], width: 35, height: 35},
            { zoomRange: [10,Infinity], width: 45, height: 45},
        ]
    },
    {
        countryCode: "DE",
        conditions: [
            { zoomRange: [0,5], width: 10, height: 10},
            { zoomRange: [6,6], width: 15, height: 15},
            { zoomRange: [7,7], width: 20, height: 20},
            { zoomRange: [8,8], width: 25, height: 25},
            { zoomRange: [9,9], width: 30, height: 30},
            { zoomRange: [10,Infinity], width: 45, height: 45},
        ]
    },
    {
        countryCode: "DK",
        conditions: [
            { zoomRange: [0,4], width: 10, height: 10},
            { zoomRange: [5,5], width: 15, height: 15},
            { zoomRange: [6,6], width: 20, height: 20},
            { zoomRange: [7,7], width: 25, height: 25},
            { zoomRange: [8,8], width: 30, height: 30},
            { zoomRange: [9,9], width: 35, height: 35},
            { zoomRange: [10,Infinity], width: 45, height: 45},
        ]
    },
    {
        countryCode: "FI",
        conditions: [
            { zoomRange: [0,4], width: 10, height: 10},
            { zoomRange: [5,5], width: 15, height: 15},
            { zoomRange: [6,6], width: 20, height: 20},
            { zoomRange: [7,7], width: 25, height: 25},
            { zoomRange: [8,8], width: 30, height: 30},
            { zoomRange: [9,9], width: 35, height: 35},
            { zoomRange: [10,Infinity], width: 45, height: 45},
        ]
    },
    {
        countryCode: "FR",
        conditions: [
            { zoomRange: [0,6], width: 20, height: 20},
            { zoomRange: [7,7], width: 25, height: 25},
            { zoomRange: [8,8], width: 30, height: 30},
            { zoomRange: [9,9], width: 35, height: 35},
            { zoomRange: [10,Infinity], width: 45, height: 45},
        ]
    },
    {
        countryCode: "JP",
        conditions: [
            { zoomRange: [0,4], width: 10, height: 10},
            { zoomRange: [5,5], width: 15, height: 15},
            { zoomRange: [6,6], width: 20, height: 20},
            { zoomRange: [7,7], width: 25, height: 25},
            { zoomRange: [8,8], width: 30, height: 30},
            { zoomRange: [9,9], width: 35, height: 35},
            { zoomRange: [10,Infinity], width: 45, height: 45},
        ]
    },
    {
        countryCode: "KR",
        conditions: [
            { zoomRange: [0,6], width: 20, height: 20},
            { zoomRange: [7,7], width: 25, height: 25},
            { zoomRange: [8,8], width: 30, height: 30},
            { zoomRange: [9,9], width: 35, height: 35},
            { zoomRange: [10,Infinity], width: 45, height: 45},
        ]
    },
    {
        countryCode: "NO",
        conditions: [
            { zoomRange: [0,4], width: 10, height: 10},
            { zoomRange: [5,5], width: 15, height: 15},
            { zoomRange: [6,6], width: 20, height: 20},
            { zoomRange: [7,7], width: 25, height: 25},
            { zoomRange: [8,8], width: 30, height: 30},
            { zoomRange: [9,9], width: 35, height: 35},
            { zoomRange: [10,Infinity], width: 45, height: 45},
        ]
    },
    {
        countryCode: "NZ",
        conditions: [
            { zoomRange: [0,4], width: 10, height: 10},
            { zoomRange: [5,5], width: 15, height: 15},
            { zoomRange: [6,6], width: 20, height: 20},
            { zoomRange: [7,7], width: 25, height: 25},
            { zoomRange: [8,8], width: 30, height: 30},
            { zoomRange: [9,9], width: 35, height: 35},
            { zoomRange: [10,Infinity], width: 45, height: 45},
        ]
    },
    {
        countryCode: "ZA",
        conditions: [
            { zoomRange: [0,4], width: 10, height: 10},
            { zoomRange: [5,5], width: 15, height: 15},
            { zoomRange: [6,6], width: 20, height: 20},
            { zoomRange: [7,7], width: 25, height: 25},
            { zoomRange: [8,8], width: 30, height: 30},
            { zoomRange: [9,9], width: 35, height: 35},
            { zoomRange: [10,Infinity], width: 45, height: 45},
        ]
    },
    {
        countryCode: "SE",
        conditions: [
            { zoomRange: [0,4], width: 10, height: 10},
            { zoomRange: [5,5], width: 15, height: 15},
            { zoomRange: [6,6], width: 20, height: 20},
            { zoomRange: [7,7], width: 25, height: 25},
            { zoomRange: [8,8], width: 30, height: 30},
            { zoomRange: [9,9], width: 35, height: 35},
            { zoomRange: [10,Infinity], width: 45, height: 45},
        ]
    },
    {
        countryCode: "IE",
        conditions: [
            { zoomRange: [0,4], width: 10, height: 10},
            { zoomRange: [5,5], width: 15, height: 15},
            { zoomRange: [6,6], width: 20, height: 20},
            { zoomRange: [7,7], width: 25, height: 25},
            { zoomRange: [8,8], width: 30, height: 30},
            { zoomRange: [9,9], width: 35, height: 35},
            { zoomRange: [10,Infinity], width: 45, height: 45},
        ]
    }
];
