/* eslint-disable max-len */
import React, { useEffect, useRef, useState } from "react";
import "./index.less";
import { Button, Col, DatePicker, Form, Input, InputNumber, Layout, Radio, Row, Select } from "antd";
const { Header, Content, Footer } = Layout;
const { Option } = Select;

// third dependences for react-tagsinput
import * as TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
// third dependencs for wangeditor
import wangEditObj from "wangeditor";
import i18next from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/redux/reducer/store";
import { useLocation, useNavigate } from "react-router-dom";
import { normalActionTypes, sagaActionTypes } from "@/redux/actions/quantisAction";
// request
import {checkIsEnableReportsByCrops} from "@/util/http/Quantis/index";
import store from "@/util/store";
import { IFieldsModel, IReportsModel } from "@/util/http/Quantis/responseType";
import dayjs from "dayjs";
// import ImageUpload from "@/page/Quantis/ReportEdit/components/ImageUploadComponent/imageUploadComponent";
import ImageUpload from "./components/ImageUploadComponent";
import type { UploadFile as IFileListType } from "antd";
import {API_LOCAL_URL} from "@/util/http/config";
// const API_LOCAL_URL = "https://cehub-dev.syngenta-ais.com";
interface middleObject {
    fromDate?: string;
    untilDate?: string;
    reminder?: string;
    longitude?: string;
    latitude?: string;
    reportID?: number;
    crop?: string;
    fieldName?: string;
}
// TODO: 这个页面刷新后存在 redux 中的值就没有了，但老的是有的，所以还是需要处理到 localStore 中

const ReportEdit = ()=>{

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const urlLocation = useLocation();

    const {
        allFields,
        currentSelectedReport,
        reportAddOrEditFlag
    } = useSelector((state: RootState) => state.quantisReducer);

    const reportSingle = store.get("report")? store.get("report") : {};
    const [form] = Form.useForm();

    const reminderEditRef = useRef(null);
    const reminderEditorRef = useRef(null);
    // const [reminderEditor,setReminderEditor] = useState({} as any); // editor setting
    const [reportInitImgUrl, setReportInitImgUrl] = useState<IFileListType[]>([]);
    const [tag, setTag] = useState([]);
    const [tag2, setTag2] = useState([]);
    // set the isActiveReport button whether can click, not for value
    const [isActiveReportDisabled, setIsActiveReportDisabled] = useState(false);
    const [isDispaly, setIsDispaly] = useState("none");

    const checkIsEnableReportsByCropsFunc = async(crop:string,locationOptionChange?:boolean)=>{
        try{
            const res = await checkIsEnableReportsByCrops(crop);
            if(res.data === true){
                setIsDispaly("none");
                setIsActiveReportDisabled(false);
                if(locationOptionChange){
                    form.setFieldsValue({
                        ...form.getFieldsValue(),
                        isActive: "Y"
                    });
                }
            }
            if(res.data === false){
                setIsDispaly("block");
                setIsActiveReportDisabled(true);
                if(locationOptionChange){
                    form.setFieldsValue({
                        ...form.getFieldsValue(),
                        isActive: "N"
                    });
                }
            }
        }
        catch(error){
            console.log("error checkIsEnableReportsByCropsFunc",error);
        }
    };
    const locationOptionChange = (item:string)=>{
        // item is IFieldModels 's fieldName, because Option 's value is fieldName
        // this function invoke when Location Name Option changeed, will call function
        const tmpCrop = allFields.find( o => o.fieldName === item).crop;
        checkIsEnableReportsByCropsFunc(tmpCrop,true);
    };
    const tagsInputChange = (tags) => {
        console.log(tags);
        setTag(tags);
        form.setFieldsValue({
            emailTo: tags.join(";")
        });
    };
    const tagsInputChange2 = (tags) => {
        console.log(tags);
        setTag2(tags);
        form.setFieldsValue({
            emailBcc: tags.join(";")
        });
    };

    // used for image upload
    // const uploadFunc = (files?:any)=>{
    //     // console.log(param)
    //     const formData = new FormData();
    //     formData.append("file", files);
    //     console.log("upload 父组件的 uploadFunc files",files);
    //     dispatch({
    //         type: sagaActionTypes.SAGA_UPLOAD_IMAGE,
    //         payload: files
    //     });
    // };
    const childChangeFormProperties = (value?:string)=>{
        console.log("父组件的 更改 form 表单的 logos函数: value",value);
        console.log("父组件的 更改 form 表单的 logos函数: form value",form.getFieldsValue());
        form.setFieldsValue({
            logos: value
        });
    };
    const onFinish = async(formsData:any)=>{
        const middleObject: middleObject = {
            fromDate: formsData.fromDate.format("YYYY-MM-DD"),
            untilDate: formsData.untilDate.format("YYYY-MM-DD"),
            reminder: reminderEditorRef.current.txt.html(),
            longitude: allFields.find(o => o.fieldName == formsData.fieldName)?.longitude,
            latitude: allFields.find(o => o.fieldName == formsData.fieldName)?.latitude,
            crop: allFields.find(o => o.fieldName == formsData.fieldName)?.crop,
            fieldName: formsData.fieldName,
        };
        if(reportAddOrEditFlag === "Edit"){
            // mean should invoke edit report
            middleObject.reportID = currentSelectedReport.reportID;
            const tmpEditReportData = Object.assign(formsData,middleObject);
            // TODO: edit function
            dispatch({
                type: sagaActionTypes.SAGA_EDIT_REPORT,
                payload: tmpEditReportData
            });
        }
        else if(reportAddOrEditFlag === "Add"){
            // mean should invoke add report
            const tmpAddReportData = Object.assign(formsData,middleObject);
            // invoke add report function
            // TODO: add function
            dispatch({
                type: sagaActionTypes.SAGA_ADD_REPORT,
                payload: tmpAddReportData
            });
            // when invoke success, should set new report data to localStore
        }
    };

    function escape2Html(str) {
        // console.log(str)
        const arrEntities = { "lt": "<", "gt": ">", "nbsp": " ", "amp": "&", "quot": "\"", "#39": "\"" };
        if (str != null) return str.replace(/&(lt|gt|nbsp|amp|quot|#39);/ig, function (all, t) {
            return arrEntities[t];
        });
    }

    const initEdit =()=>{
        // console.log("init reminderEditRef.current",reminderEditRef.current);
        // if(reminderEditRef.current) return;
        const editor:any = new wangEditObj(reminderEditRef.current);
        // set editor config
        editor.config.lang = "en";
        editor.config.zIndex = 2;
        editor.i18next = i18next;
        editor.config.height = 140;
        editor.config.menus = [
            "bold"
            , "head"
            , "fontSize"
            , "fontName"
            , "italic"
            , "underline"
            , "strikeThrough"
            , "indent"
            , "lineHeight"
            , "foreColor"
            , "backColor"
            , "link"
            , "list"
            , "todo"
            , "justify"
            , "quote"
            , "emoticon"
            , "table"
            , "splitLine"
            , "undo"
            , "redo"
        ];
        editor.config.fontNames = [
            "Arial",
            "Tahoma",
            "Verdana",
            "Times New Roman",
            "Courier New",
        ];
        editor.create();

        // if reportSingle have data, mean we is used for Edit page, else we used for Add Page
        // if(Object.keys(reportSingle).length > 0){

        // }
        if(reportAddOrEditFlag === "Edit"){
            form.setFieldsValue({
                isActive: currentSelectedReport.isActive,
                reportName: currentSelectedReport.reportName,
                fieldName: currentSelectedReport.fieldName,
                fromDate: dayjs(currentSelectedReport.fromDate, "YYYY/MM/DD"),//reportSingle.fromDate,
                untilDate: dayjs(currentSelectedReport.untilDate, "YYYY/MM/DD"),//reportSingle.untilDate,
                reportForecastDays: currentSelectedReport.forecastDays,
                emailTo: currentSelectedReport.emailTo,
                emailBcc: currentSelectedReport.emailBcc,
                // Reminder:reportSingle.reminder,
                logos: currentSelectedReport.logos,
            });
            setTag(currentSelectedReport?.emailTo?.split(";"));
            setTag2(currentSelectedReport?.emailBcc?.split(";"));
            editor.txt.html(escape2Html(currentSelectedReport.reminder));
            if (currentSelectedReport.logos != null) setReportInitImgUrl((e)=> {
                console.log("currentSelectedReport--------",currentSelectedReport);
                return currentSelectedReport.logos.split(",").map((obj,index) => {
                    return {
                        uid:`Edit-logos-${index}`,
                        // url: API_LOCAL_URL + "/quantis-logos/" + obj,
                        url: API_LOCAL_URL + "api/quantis-logos/" + obj,
                        name: obj
                    };
                });
            });
        }
        else if(reportAddOrEditFlag === "Add"){
            form.setFieldsValue({
                logos: "Quantis.jpg",
                reportForecastDays: 7
            });
            setReportInitImgUrl([{
                uid:"add-logos-1",
                // url: API_LOCAL_URL + "/quantis-logos/Quantis.jpg",
                url: API_LOCAL_URL + "api/quantis-logos/Quantis.jpg",
                name: "Quantis.jpg" }
            ]);
            editor.txt.html("<p style=\"text-align: left;\"/>Once alerted, an application of QUANTIS at 2.0l/ha maybe due one to three days prior to the 1st stress event. Check parameters for relevant crop.</p><p><br/></p><p style=\"text-align: left;\"><b>Heat stress event Parameters<br/></b>Potato&Sugar Beet:</p><p style=\"text-align: left;\"></p><p style=\"text-align: left;\">3 x stress (or near stress) events for 3 days in a row OR 1 x extreme stress event over 30⁰C</p><p><br/></p><p style=\"text-align: left;\">For more information please go to <a href=https://www.syngenta.co.uk/quantis><span> https://www.syngenta.co.uk/quantis</span></a></p>");

        }
        // set editor instance store into ref
        reminderEditorRef.current = editor;
    };

    useEffect(()=>{
        // if(reportSingle?.crop){
        //     checkIsEnableReportsByCropsFunc(reportSingle?.crop);
        // }
        // set init Eidit vlaue, origin project is invoke after get fields, but is hove no depencies
        // initEdit();
        return()=>{
            console.log("report卸载");
            dispatch({
                type: normalActionTypes.NORMAL_UPDATE_CURRENTSELECTEDREPORT,
                payload: null
            });
            dispatch({
                type: normalActionTypes.NORMAL_UPDATE_REPORTADDOREDITFLAG,
                payload: null
            });
        };
    },[]);
    const effectRunCount = useRef(0);
    useEffect(()=>{
        // effectRunCount.current += 1;
        // console.log("useEffect 运行次数:", effectRunCount.current);
        // if this page is used to Edit report, should invoke this function first
        console.log("useEffect 运行次数 dd:", effectRunCount.current);
        if(reportAddOrEditFlag ){
            if(reportAddOrEditFlag === "Edit"){
                checkIsEnableReportsByCropsFunc(currentSelectedReport?.crop);
            }
            effectRunCount.current += 1;
            console.log("useEffect 运行次数:", effectRunCount.current);
            console.log("lanxue reportAddOrEditFlag",reportAddOrEditFlag);
            initEdit();
        }
    },[reportAddOrEditFlag]);
    useEffect(()=>{
        // get all fields
        dispatch({
            type: sagaActionTypes.SAGA_GET_FIELDS,
            payload:null
        });
        // 进入当前页面的时候会被调用两次，应该属于dev的问题，切换到别的页面是就不会被调用
        console.log("reportEdit 的路由变化");

    },[urlLocation]);

    return(
        <div>
            <div className={"report-edit-container"}>
                <div className="ghost">
                    <Layout className="layout" style={{ backgroundColor: "#fff", padding: 15 }}>
                        <Row>
                            <Col lg={4} xs={0}> </Col>
                            <Col lg={16} xs={24}>
                                <Content >
                                    <Form
                                        name="basic"
                                        labelAlign="left"
                                        labelCol={{ span: 6 }}
                                        wrapperCol={{ span: 18 }}
                                        onFinish={onFinish}
                                        form={form}
                                        autoComplete="off"
                                    >
                                        <Form.Item label="Activate Report" name="isActive" style={{ marginBottom: "18px" }}
                                            rules={[{ required: true, message: "Please select Activate!" }]}>
                                            <Radio.Group >
                                                <Radio value={"Y"} disabled={isActiveReportDisabled}>Y</Radio>
                                                <Radio value={"N"} disabled={isActiveReportDisabled}>N</Radio>
                                            </Radio.Group>
                                        </Form.Item>
                                        <Form.Item label="" name="" style={{ display: isDispaly, marginTop:"-31px",marginBottom:"4px" }}>
                                            <Row>
                                                <Col span={16} offset={8}>
                                                    <p style={{ color: "red", fontSize: "12px" }} >Reports have been disabled for this crop</p>
                                                </Col>
                                            </Row>
                                        </Form.Item>
                                        <Form.Item label="Name" name="reportName"
                                            rules={[{ required: true, message: "Please input your reportName!" }]}>
                                            <Input placeholder="input reportName" />
                                        </Form.Item>
                                        <Form.Item label="Location Name" name="fieldName"
                                            rules={[{ required: true, message: "Please Select a location!" }]}>
                                            <Select placeholder="Select a location"
                                                onChange={(item) => locationOptionChange(item)} >
                                                {
                                                    allFields?.map((item,index)=>{
                                                        return(
                                                            <>
                                                                <Option
                                                                    key = {index + item?.crop + item?.fieldName}
                                                                    value={item?.fieldName}
                                                                >
                                                                    {item?.crop + "--" + item?.fieldName}
                                                                </Option>
                                                            </>
                                                        );
                                                    })
                                                }
                                            </Select>
                                        </Form.Item>
                                        <Form.Item label="Send options">
                                            <Form.Item label="From" name="fromDate"
                                                rules={[{ required: true, message: "Please Select time!" }]}>
                                                <DatePicker />
                                            </Form.Item>
                                            <Form.Item label="Until" name="untilDate"
                                                rules={[{ required: true, message: "Please Select time!" }]}>
                                                <DatePicker />
                                            </Form.Item>
                                        </Form.Item>
                                        <Form.Item label="Forecast Days" name="reportForecastDays"
                                            rules={[{ required: true, message: "Please Select Forecast Days!" }]}>
                                            <InputNumber min={1} max={14} defaultValue={1} />
                                        </Form.Item>
                                        <Form.Item label="Email Address" name="emailTo"
                                            rules={[{ required: true, message: "Please input Email Address!" }]}>
                                            <Input.TextArea hidden allowClear showCount className="hidden"/>
                                            <TagsInput value={tag}
                                                inputProps={{
                                                    placeholder: "Add Email"
                                                }}
                                                onChange={tagsInputChange} />
                                        </Form.Item>
                                        <Form.Item label="Bcc" name="emailBcc">
                                            <Input.TextArea hidden allowClear showCount className="hidden" />
                                            <TagsInput value={tag2}
                                                inputProps={{
                                                    placeholder: "Add Email"
                                                }}
                                                onChange={tagsInputChange2} />
                                        </Form.Item>
                                        <Form.Item label="Reminder" name="reminder">
                                            <div ref={reminderEditRef} style={{ textAlign: "left" }} />
                                        </Form.Item>
                                        <Form.Item label="Logos" name="logos">
                                            <ImageUpload
                                                initReportImgUrl={reportInitImgUrl}
                                                changeParentReportLogosProperties={childChangeFormProperties}
                                                // uploadFunc={uploadFunc}
                                            />
                                        </Form.Item>
                                        <Form.Item>
                                            <Button type="primary" htmlType="submit" className="login-form-button">
                                            Save
                                            </Button>
                                            <Button type="default"
                                                // onClick={e => history.replace({ pathname: "/visualization/cropstress-report" })}
                                                // onClick={e => {navigate("/visualization/cropstress-report");}}
                                                onClick={e => {navigate("/cropstress/report");}}
                                                className="login-form-button"
                                                style={{ marginLeft: 20 }}>
                                            Close
                                            </Button>
                                        </Form.Item>
                                    </Form>
                                </Content>
                            </Col>
                            {/* <Col span={4}> </Col>*/}
                        </Row>

                    </Layout>
                </div>
            </div>
        </div>
    );
};

export default ReportEdit;