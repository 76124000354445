import {
    IBookmarkResModel,
    ICountryBoundries,
    IDailyDataModel,
    IDiseaseMapDataModel,
    IHeatMapLocationModel,
    IHour24DataModel,
    IHour8DataModel,
    ILocalDailyWidgetWeatherResModel,
    ILocationModel,
    INewLocationModel,
    IWeatherForcastDailyListResModel
} from "@/util/http/responseType";
import { normalActionTypes } from "../actions/commonAction";
// init dieseae and translate data
import {InitDiseaseDataFromXML,InitTranslateText} from "@/resource/js/diseaseInitData";
import enDefault, { ILanguage } from "@/util/en";
import { MenuProps } from "antd";
import { circleArray, colorMap as colorLocalMap , restrictionLegend, srlMap as srlLocalMap, toolTip} from "@/util/cosntants";

interface IWeatherData {
    serieName: string
    serieValue: string
    serieKey: string
}

interface ICommonModel {
    locations?: ILocationModel[]
    newLocations?: INewLocationModel[]
    diseaseData?: IDiseaseMapDataModel
    translateText?: ITranslateTextModel[]
    userName?: string
    serviceToken?: string
    bookmarks?: {
        label: string
        value: number
        bookmark: IBookmarkResModel
    }[]
    currentLocation?: ILocationModel
    loading?: boolean
    weatherForcastDailyList?: IWeatherForcastDailyListResModel[]
    localDailyWidgetWeather?: ILocalDailyWidgetWeatherResModel[],
    currentLanguage?: ILanguage
    chartData?: { temperature: number[], precip: number[], date: string[] },
    cropsItems?: MenuProps["items"],
    cropsSpray?: {
        circleObj: {
            [keyValue: string]: {
                indexMap?: string[]
                srlMap?: unknown
                colorMap?: unknown
                toolTip?: unknown
            }
        }
        restrictionLegend?: {keyText: string, name: string}[]
        translate: {
            ResColLegRecomm: string
            ResColLegRestrict: string
            ResColLegNotRec: string
            ResHeadLegSpary: string
        }
    }
    cropsSprayGround: {
        circleObj: {
            [keyValue: string]: {
                indexMap?: string[]
                srlMap?: unknown
                colorMap?: unknown
                toolTip?: unknown
            }
        }
        restrictionLegend?: {[keyText: string]: string}
    }
    cropsSprayGoundWithNoggle: {
        circleObj: {
            [keyValue: string]: {
                indexMap?: string[]
                srlMap?: unknown
                colorMap?: unknown
                toolTip?: unknown
            }
        }
        restrictionLegend?: {[keyText: string]: string}
    }
    weatherListLoading?: boolean
    topInfoCardLoading?: boolean
    sprayLoading?: boolean,
    paramModel?: {
        security: string
        theme: string
        modelName: string
        countryCode: string
        latitude?: number
        longitude?: number
        language: string
        cropList: string
        numberOfDays: number
        unit?: "metric" | "imperial",
        emailid?: string,
        placename?:string,
        headtitlebg? :string,
        tabletitlebg? :string,
        buttonbg? :string,
        forecastbtnbg? :string

    }
    weather24HoursData: {
        hours24Data: IHour24DataModel
        defaultShowIcon: { [keyText: string]: boolean}
    }
    weather8HoursData: {
        hours8Data: IHour8DataModel
        defaultShowIcon: { [keyText: string]: boolean}
    }
    weatherDayData: {
        hourDay: IDailyDataModel
        defaultShowIcon: { [keyText: string]: boolean}
    }
    fiveWeather24HoursData: {
        hours24Data: IHour24DataModel
        defaultShowIcon: { [keyText: string]: boolean}
    }
    fiveWeather8HoursData: {
        hours8Data: IHour8DataModel
        defaultShowIcon: { [keyText: string]: boolean}
    }
    fiveWeatherDayData: {
        hourDay: IDailyDataModel
        defaultShowIcon: { [keyText: string]: boolean}
    }
    keyTurfData?: {
        [keyText: string]: {
            weatherDate: string
            serieName: string
            serieValue: string
            formatDay:string
        }}[]
    disease?: {
        diseaseData: IHour24DataModel
        defaultShowIcon: { [keyText: string]: string}
        lstLegend: {
            key: string
            name: string
            text: string
        }[]
        formatDay?: { [keyText: string]: string}[]
    }
    showAddBookSuccess?: boolean
    showAddBookFailed?: boolean
    searchCoordinateResult?: boolean
    ssoSigninUrl?: string
    homeIndexLoading?: boolean
    heatMaplocations?: IHeatMapLocationModel[],
    mapSetting?: {
        amber_level: number,
        boundary_day: number,
        red_level: number,
        amber_count: number,
        red_count: number,
    }
    mapSettingOrigin?: {
        amber_level: number,
        boundary_day: number,
        red_level: number,
        amber_count: number,
        red_count: number,
    }
    coountryBoundary?: ICountryBoundries[]
    riskColor?: string,
    token?: string
    userSimplifyName?: string
    heatMapLoading?: boolean
    warnningMessage?: string
    geoIds?: string[]
    countryGeonameIdSet?: string[]
    excelName?: string,
    changeSettingFlag?: boolean;
    searchByPlace?: boolean
    searchByPlaceResult?: boolean
}

interface IUserModel {
    name: string
    sexual: string
}
interface ITranslateTextModel{
    key: string;
    value: string;
}

const initialCommon: ICommonModel = {
    locations: [],
    newLocations:[],
    diseaseData: InitDiseaseDataFromXML,
    translateText: InitTranslateText,
    bookmarks: [],
    loading: false,
    weatherForcastDailyList: [],
    localDailyWidgetWeather: [],
    currentLanguage: enDefault,
    cropsItems: [],
    cropsSpray: {
        circleObj: {
            [0]: {
                indexMap: circleArray,
                srlMap: srlLocalMap,
                colorMap: colorLocalMap,
                toolTip: toolTip
            }
        },
        restrictionLegend: restrictionLegend,
        translate: {
            ResColLegRecomm: "Recomend",
            ResColLegRestrict: "Limited",
            ResColLegNotRec: "Not Advisiable",
            ResHeadLegSpary: "Spary",
        }
    },
    cropsSprayGround: {
        circleObj: {
            [0]: {
                indexMap: circleArray,
                srlMap: srlLocalMap,
                colorMap: colorLocalMap,
                toolTip: toolTip
            }
        },
        restrictionLegend: {}
    },
    cropsSprayGoundWithNoggle: {
        circleObj: {
            [0]: {
                indexMap: circleArray,
                srlMap: srlLocalMap,
                colorMap: colorLocalMap,
                toolTip: toolTip
            }
        },
        restrictionLegend: {}
    },
    weatherListLoading: false,
    topInfoCardLoading: false,
    sprayLoading: false,
    paramModel: {
        security: "",
        theme: "light",
        modelName: "vew_GSprayVine",
        countryCode: "GB",
        language: "en",
        cropList: "vew_spraygrnd_s|vew_spraygrndFung|vew_AerialSpray|vew_GSprayHerbPE",
        numberOfDays: 10,
        unit: "metric",
        emailid: "cehub-gb@syngentaprocessmail.com"
    },
    weather24HoursData: {
        hours24Data: {},
        defaultShowIcon: { }
    },
    weather8HoursData: {
        hours8Data: {},
        defaultShowIcon: {}
    },
    weatherDayData: {
        hourDay: {},
        defaultShowIcon: {}
    },
    fiveWeather24HoursData: {
        hours24Data: {},
        defaultShowIcon: { }
    },
    fiveWeather8HoursData: {
        hours8Data: {},
        defaultShowIcon: {}
    },
    fiveWeatherDayData: {
        hourDay: {},
        defaultShowIcon: {}
    },
    showAddBookSuccess: false,
    searchCoordinateResult: false,
    homeIndexLoading: false,
    heatMaplocations: [],
    mapSetting: {
        amber_level: 10,
        boundary_day: 3,
        red_level: 15,
        amber_count: 3,
        red_count: 2,
    },
    mapSettingOrigin: {
        amber_level: 10,
        boundary_day: 3,
        red_level: 15,
        amber_count: 3,
        red_count: 2,
    },
    userSimplifyName: "EL",
    heatMapLoading: false,
    changeSettingFlag: false,
    searchByPlace: false,
    searchByPlaceResult: false
};

const commonReducer = (state: ICommonModel = initialCommon, action): ICommonModel => {
    switch (action.type) {
        case normalActionTypes.NORMAL_UPDATE_LOCATION_SEARCH_RESULT:
            return {
                ...state,
                locations: action.payload
            };
            break;

        case normalActionTypes.NORMAL_UPDATE_CURRENT_LOCATION:
            return {
                ...state,
                currentLocation: action.payload
            };
            break;

        case normalActionTypes.NORMAL_UPDATE_USERNAME:
            return {
                ...state,
                userName: action.payload
            };
            break;

        case normalActionTypes.NORMAL_UPDATE_SERVICETOKEN:
            return {
                ...state,
                serviceToken: action.payload
            };
            break;

        case normalActionTypes.NOMRAL_UPDATE_BOOKMARK: {
            const bookmarks: IBookmarkResModel[] = action.payload;
            const newBookmarks = bookmarks.map((item) => {
                return {
                    value: item.booKmarkID,
                    label: item.placeName,
                    bookmark: item
                };
            });
            return {
                ...state,
                bookmarks: newBookmarks
            };
            break;

        }
        case normalActionTypes.SHOW_LOADING:
            return {
                ...state,
                loading: true
            };
            break;

        case normalActionTypes.HIDE_LOADING:
            return {
                ...state,
                loading: false
            };
            break;

        case normalActionTypes.NORMAL_UPDATE_WEATHERFORCAST_DAILY_LIST:
            return {
                ...state,
                weatherForcastDailyList: action.payload,
                weatherListLoading: false
            };
            break;

        case normalActionTypes.NORMAL_GET_LOCAL_HOURLY_WIDGET:
            return {
                ...state,
                localDailyWidgetWeather: action.payload
            };
            break;

        case normalActionTypes.NORMAL_UPDATE_CURRENT_LANGUAGE:
            return {
                ...state,
                currentLanguage: action.payload
            };
            break;

        case normalActionTypes.NORMAL_UPDATE_CHART_DATA:
            return {
                ...state,
                chartData: action.payload
            };
            break;

        case normalActionTypes.NOMRAL_UPDATE_CROPS:
            return {
                ...state,
                cropsItems: action.payload
            };
            break;

        case normalActionTypes.NOMRAL_UPDATE_CROPS_SPRAY:
            return {
                ...state,
                cropsSpray: action.payload
            };
            break;
        case normalActionTypes.NORMAL_UPDATE_GROUND_SPRAYING:
            return {
                ...state,
                cropsSprayGround: action.payload
            };
            break;
        case normalActionTypes.NORMAL_UPDATE_GROUND_SPRAYING_WITH_NOZZLE:
            return {
                ...state,
                cropsSprayGoundWithNoggle: action.payload
            };
            break;
        case normalActionTypes.NOMRAL_UPDATE_WEATHER_LIST_LOADING:
            return {
                ...state,
                weatherListLoading: action.payload
            };
            break;
        case normalActionTypes.NOMRAL_UPDATE_TOP_TITLE_LOADING:
            return {
                ...state,
                topInfoCardLoading: action.payload
            };
            break;
        case normalActionTypes.NOMRAL_UPDATE_SPRAY_LOADING:
            return {
                ...state,
                sprayLoading: action.payload
            };
            break;
        case normalActionTypes.NOMRAL_UPDATE_PARAM_MODEL:
            return {
                ...state,
                paramModel: action.payload
            };
            break;
        case normalActionTypes.NOMRAL_UPDATE_HOURS_DAY_DATA:
            return {
                ...state,
                ...action.payload
            };
        case normalActionTypes.NOMRAL_UPDATE_SERVER_WARNING:
            return {
                ...state,
                ...action.payload
            };
        case normalActionTypes.NORMAL_UPDATE_KEY_TURF_GROWTH_DATA:
            return {
                ...state,
                keyTurfData: action.payload
            };
        case normalActionTypes.NORMAL_UPDATE_DISEASE:
            return {
                ...state,
                disease: action.payload
            };
        case normalActionTypes.NORMAL_ADD_BOOKMARK_SUCCESS:
            return {
                ...state,
                showAddBookSuccess: action.payload
            };
        case normalActionTypes.NORMAL_ADD_BOOKMARK_FAILE:
            return {
                ...state,
                showAddBookFailed: action.payload
            };
        case normalActionTypes.NORMAL_UPDATE_NEW_LOCATION_SEARCH_RESULT:
            return {
                ...state,
                newLocations: action.payload
            };
        case normalActionTypes.NORMAL_SEARCH_TARGET_COORDINATE_RESULT:
            return {
                ...state,
                searchCoordinateResult: action.payload
            };
        case normalActionTypes.NORMAL_UPDATE_REDIRECT_URL:
            return {
                ...state,
                ssoSigninUrl: action.payload.ssoSigninUrl
            };
        case normalActionTypes.NORMAL_UPDATE_HOME_INDEX_LOADING:
            return {
                ...state,
                homeIndexLoading: action.payload
            };
        case normalActionTypes.NORMAL_UPDATE_ALL_LOCATIONS: {
            const { locations, geoIds, countryGeonameIdSet } = action.payload;
            return {
                ...state,
                heatMaplocations: locations,
                geoIds,
                countryGeonameIdSet
            };
        }
        case normalActionTypes.NORMAL_UPDATE_LOCAL_MAP_SETTING: {
            const result = {
                ...state,
                mapSetting: {
                    ...state.mapSetting,
                    ...action.payload
                }
            };
            return result;
        }
        case normalActionTypes.NORMAL_UPDATE_LOCAL_MAP_SETTING_ORIGIN: {
            const {
                mapSetting,
                update
            } = action.payload;
            if (update) {
                return {
                    ...state,
                    mapSettingOrigin: mapSetting
                };
            } else {
                return {
                    ...state,
                    mapSetting: {
                        ...state.mapSettingOrigin
                    }
                };
            }
        }
        case normalActionTypes.NORMAL_UPDATE_COUNTRY_BOUNDRIES:
            return {
                ...state,
                coountryBoundary: action.payload
            };
        case normalActionTypes.NORMAL_UPDATE_AREA_RISK_VALUE:
            return {
                ...state,
                riskColor: action.payload
            };
        case normalActionTypes.NORMAL_UPDATE_TOKEN:
            return {
                ...state,
                token: action.payload.token,
                userSimplifyName: action.payload.userSimplifyName
            };
        case normalActionTypes.NORMAL_UPDATE_HEATMAP_LOADING:
            return {
                ...state,
                heatMapLoading: action.payload
            };
        case normalActionTypes.NORMAL_UPDATE_WARNING_MESSAGE:
            return {
                ...state,
                warnningMessage: action.payload
            };
        case normalActionTypes.NORMAL_UPDATE_EXCEL_NAME:
            return {
                ...state,
                excelName: action.payload
            };
        case normalActionTypes.NORMAL_UPDATE_REQUEST_AFTER_CHANGE_SETTING:
            return {
                ...state,
                changeSettingFlag: action.payload
            };
        case normalActionTypes.NORMAL_UPDATE_SEARCH_BY_PLACE:
            return {
                ...state,
                searchByPlace: action.payload
            };
        case normalActionTypes.NORMAL_UPDATE_SEARCH_BY_PLACE_RESULT:
            return {
                ...state,
                searchByPlaceResult: action.payload
            };
        default: {
            return state;
            break;
        }
    }
};

export type {
    ICommonModel,
    IUserModel,
    ITranslateTextModel,
    IWeatherData
};

export default commonReducer;