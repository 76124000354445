import { MyLocationIcon, SearchIcon } from "@/resource/IconComponents";
import React, { useEffect, useState } from "react";
import "./index.less";
import { List, Modal } from "antd";
import { ILocationModel } from "@/util/http/responseType";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/redux/reducer/store";
import { sagaActionTypes } from "@/redux/actions/commonAction";
import { Button, notification } from "antd";
import { useTrackedEffect , useWhyDidYouUpdate} from "ahooks";


interface IProps {
    country: string;
    language: string;
    initSearchCountryName?: string;
    placahold?: string;
    errorMessage?: string;
    currentLocation?: ILocationModel;
    isReset?: boolean;
    changeChildCurrentLocation?: (ILocationModel)=>void;
    changeChildIsReset?: (data:boolean) => void;
    style?:any;
    iconColor?:any;
}


const Search = (props: IProps) => {
    const {
        country,
        language,
        placahold,
        initSearchCountryName,
        errorMessage,
        isReset,
        currentLocation,
        changeChildCurrentLocation,
        changeChildIsReset,
        style,
        iconColor
    } = props;

    const {
        locations,
    } = useSelector((state: RootState) => state.commonReducer);
    const dispatch = useDispatch();

    console.log("useLocation name",currentLocation);

    // notification
    const [api, contextHolder] = notification.useNotification();
    const openNotification = () => {
        console.log("ddd open error");
        api.error({
            message: "",
            description: errorMessage || "No place found,please enter a valid place name or longtitude,latitude",
            duration: 4.5,
            closeIcon: false
        });
    };

    // set first run
    const [initRequest,setInitRequest] = useState(false);

    // use for location search
    const [inputText, setInputText] = useState<string>("");
    const [locationListShow, setLocationListShow] = useState(false);
    // const [currentLocation, setCurrentLocation] = useState<ILocationModel>(); // current location

    useWhyDidYouUpdate("ahooks useWhyDidYouUpdateComponent update", { ...props, locations,inputText,locationListShow,api });

    const searchLocationFunc = ()=>{
        const strArr = inputText.split(",");
        let tmpCountry = null;
        if(country?.toUpperCase() === "AU"){
            tmpCountry = "AU;NZ";
        }
        else if(country?.toUpperCase() === "GB"){
            tmpCountry = "GB;IE";
        }
        else if(country?.toUpperCase() === "SE"){
            tmpCountry = "SE;DK;FI;NO";
        }
        else{
            tmpCountry = country;
        }
        if (strArr.length === 2) {
            const longitude = parseFloat(strArr[0]);
            const latitude = parseFloat(strArr[1]);
            if (typeof(latitude) === "number" && typeof(longitude) === "number") {
                dispatch({
                    type: sagaActionTypes.SAGA_COORDINATE_SEARCH,
                    payload: {searchText:`${longitude},${latitude}`}

                });
            }
        } else {
            dispatch({
                type: sagaActionTypes.SAGA_COORDINATE_SEARCH,
                payload: {searchText: `${inputText},${tmpCountry}`}
            });
        }
        setInitRequest(true);
    };

    useEffect(() => {
        console.log("ddd effect",locations);
        console.log("ddd effect length",locations?.length);
        if (locations?.length > 0 && locations?.length !== 1) {
            console.log("ddd a",locations);
            setLocationListShow(true);
        }
        if (locations?.length === 1) {
            console.log("ddd b",locations);
            changeChildCurrentLocation(locations[0]);
            setInputText(locations[0]?.Name?._text);
        }
        if(locations?.length === 0 && initRequest){
            changeChildCurrentLocation([]);
            openNotification();
        }
    }, [locations]);


    useEffect(()=>{
        if(initSearchCountryName){
            setInputText(initSearchCountryName);
        }
    },[initSearchCountryName]);

    useTrackedEffect(
        (changes) => {
            console.log("ahooks Index of changed dependencies: ", changes, " Locations ", locations);
        },
        [locations],
    );

    return (
        <div className="hist-search-input-search"
            id="hist-search-input-search-id"
        >
            {contextHolder}
            <Modal
                title={(
                    <label
                        style={{ fontSize: "22px", color: "green" }}
                    >
                        Search Location
                    </label>
                )}
                open={locationListShow}
                onCancel={() => setLocationListShow(false)}
                style={{
                    minWidth: "50%"
                }}
                footer={null}
                destroyOnClose={true}
                getContainer = {()=> document.getElementById("hist-search-input-search-id") as HTMLElement}
            >
                <List
                    dataSource={locations}
                    style={{
                        height: "500px",
                        overflow: "auto",
                        borderTop: "1px solid lightgray",
                    }}
                    renderItem={(item: ILocationModel) => {
                        return (
                            <div
                                className="result-item"
                            >
                                <label
                                    onClick={() => {
                                        changeChildCurrentLocation(item);
                                        setInputText(item?.Name?._text);
                                        setLocationListShow(false);
                                    }}
                                    style={{
                                        color: "#337ab7",
                                        fontSize: "20px"
                                    }}
                                >
                                    {item.AdminName._text}
                                </label>
                            </div>
                        );
                    } } />
            </Modal>
            <input
                value={inputText}
                onChange={(e) => {
                    setInputText(e.target.value);
                }}
                // placeholder={"London City Airport"}
                placeholder={placahold}
                className="ag-input"
            />
            <button style={style} onClick={() => {
                searchLocationFunc();
            }}>
                <SearchIcon color={iconColor}/>
            </button>
        </div>
    );
};

export default Search;