// 使用对象字面量而不是枚举
const WeatherImageMapping: Record<number, string> = {
    1: require("@/resource/images/png/Daily/1_Daily.png"),
    2: require("@/resource/images/png/Daily/2_Daily.png"),
    3: require("@/resource/images/png/Daily/3_Daily.png"),
    4: require("@/resource/images/png/Daily/4_Daily.png"),
    5: require("@/resource/images/png/Daily/5_Daily.png"),
    6: require("@/resource/images/png/Daily/6_Daily.png"),
    7: require("@/resource/images/png/Daily/7_Daily.png"),
    8: require("@/resource/images/png/Daily/8_Daily.png"),
    9: require("@/resource/images/png/Daily/9_Daily.png"),
    10: require("@/resource/images/png/Daily/10_Daily.png"),
    11: require("@/resource/images/png/Daily/11_Daily.png"),
    12: require("@/resource/images/png/Daily/12_Daily.png"),
    13: require("@/resource/images/png/Daily/13_Daily.png"),
    14: require("@/resource/images/png/Daily/14_Daily.png"),
    15: require("@/resource/images/png/Daily/15_Daily.png"),
    16: require("@/resource/images/png/Daily/16_Daily.png"),
    17: require("@/resource/images/png/Daily/17_Daily.png")
};

const NozzleColorImageMapping: Record<string, string> = {
    "green": require("@/resource/images/png/nozzleColorImage/green.png"),
    "red": require("@/resource/images/png/nozzleColorImage/red.png"),
    "orange": require("@/resource/images/png/nozzleColorImage/orange.png")
};

const NozzleColorSmallImageMapping: Record<string, string> = {
    "green": require("@/resource/images/png/nozzleColorImage/green_small.png"),
    "red": require("@/resource/images/png/nozzleColorImage/red_small.png"),
    "orange": require("@/resource/images/png/nozzleColorImage/orange_small.png")
};

// 使用方法
function getWeatherImage(weatherCode: number): string | undefined {
    return WeatherImageMapping[weatherCode];
}
function getNozzleImage(color: string,small?:boolean): string | undefined {
    if(small){
        return NozzleColorSmallImageMapping[color];
    }
    else{
        return NozzleColorImageMapping[color];
    }

}

export{
    getWeatherImage,
    getNozzleImage
};