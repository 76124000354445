/* eslint-disable max-len */
import React, { useEffect, useReducer, useRef, useState } from "react";
import "./index.less";
// import AGSearch from "./components/AGSearch";
import AGHistSearchLocation from "@/components/HistSearchLocation";
import { useDispatch, useSelector } from "react-redux";
import { sagaActionTypes } from "@/redux/actions/commonAction";
import { Input, Spin} from "antd";
import { RootState } from "@/redux/reducer/store";
import { IHistDiseaseLstSeriesModel, ILocationModel } from "@/util/http/responseType";
import { useLocation } from "react-router-dom";
import {Eamil_Svg,Download_Svg,
    Risk_Color_Svg_1,
    Risk_Color_Svg_2,
    Risk_Color_Svg_3,
    Risk_Color_Svg_4,
    Risk_Color_Svg_5,
    Risk_Color_Svg_6,
    Risk_Color_Svg_7,
    Risk_Color_Svg_8,
    Risk_Color_Svg_9,
    Risk_Color_Svg_10,
    Risk_Color_Svg_11,
    Risk_Color_Svg_12,
    Risk_Color_Svg_13,
    Risk_Color_Svg_14,
} from "./components/Email";
import DateChange from "./components/DateChange";
import EchartRander from "./components/EchartRander";
import dayjs from "dayjs";
import { IGenerateLocationByCoordinateResModel, IGetHistoricDiseaseCharInfoResModel, IGetHistoricWeatherSeriesResModel } from "@/util/http/HistWeather/responseType";
import useHistoricDiseaseSeries from "./components/SelfHooks/useHistoricDiseaseSeries";
import { generateLocationByCoordinate, getHistoricDiseaseCharInfo } from "@/util/http/HistWeather";
import html2canvas from "html2canvas";
import AGSentEmailModal from "@/components/SentEmailModal";
import IF from "@/components/IF";
import { normalActionTypes } from "@/redux/actions/diseaseAction";
import useHistDiseaseTranslate from "./components/SelfHooks/useHistDiseaseTranslate";
import {debounce} from "lodash";


interface ISearchObj{
    theme?: string;
    country?: string;
    language?: string;
    longitude?: number;
    latitude?: number;
    chartbgcolor?: string;
    headtitlebg?: string;
    buttonbg?: string;
    forecastbtnbg?: string;
}

const AGAccurateWeather = () => {

    const SvgAndColorArr = [
        {
            svgComponent: <Risk_Color_Svg_1/>,
            // svgColor: "#FF7A00"
            svgColor: "#ff4e00" // orange
        },
        {
            svgComponent: <Risk_Color_Svg_2/>,
            // svgColor: "#0047FF"
            svgColor: "RGB(0, 128, 128)" // deep blue
        },
        {
            svgComponent: <Risk_Color_Svg_3/>,
            // svgColor: "#5BC0FA",
            svgColor: "RGB(150, 150, 150)" // gray
        },
        {
            svgComponent: <Risk_Color_Svg_4/>,
            // svgColor: "#FFAA2B"
            svgColor: "RGB(80, 0, 80)"
        },
        {
            svgComponent: <Risk_Color_Svg_5/>,
            // svgColor: "#FFD600"
            svgColor: "#334999"
        },
        {
            svgComponent: <Risk_Color_Svg_6/>,
            // svgColor: "#10D300"
            svgColor: "#339952" // deep red
        },
        {
            svgComponent: <Risk_Color_Svg_7/>,
            svgColor: "#507218"
            // svgColor: "RGB(128, 0, 128)"
        },
        {
            svgComponent: <Risk_Color_Svg_8/>,
            // svgColor: "#14580E"
            svgColor: "#89349f"
        },
        {
            svgComponent: <Risk_Color_Svg_9/>,
            // svgColor: "#8B8B8B"
            svgColor: "RGB(139, 0, 0)"
        },
        {
            svgComponent: <Risk_Color_Svg_10/>,
            // svgColor: "#dc1b1b"
            svgColor: "RGB(255, 140, 0)"
        },
        {
            svgComponent: <Risk_Color_Svg_11/>,
            // svgColor: "#e72ec9"
            svgColor: "RGB(255, 215, 0)"
        },
        {
            svgComponent: <Risk_Color_Svg_12/>,
            // svgColor: "#9f86e1"
            svgColor: "RGB(0, 128, 128)"
        },
        {
            svgComponent: <Risk_Color_Svg_13/>,
            // svgColor: "#0bd6a7"
            svgColor: "RGB(80, 0, 80)"
        },
        {
            svgComponent: <Risk_Color_Svg_14/>,
            // svgColor: "#a92d6b"
            svgColor: "RGB(173, 216, 230)"
        }
    ];

    const dispatch = useDispatch();
    const urlLocation = useLocation();

    // add google analysis
    useEffect(() => {
        console.log("just invoke once");
        const head = document.getElementsByTagName("head")[0];
        const googleAnalyticsCode = "G-EJVDQ8HQNR";
        const googleAnalyticsFirstScript = document.createElement("script");
        googleAnalyticsFirstScript.async = true;
        googleAnalyticsFirstScript.src =
          "https://www.googletagmanager.com/gtag/js?id=" + googleAnalyticsCode;

        const googleAnalyticsSecondScript = document.createElement("script");
        googleAnalyticsSecondScript.innerHTML =
          "    window.dataLayer = window.dataLayer || [];\n" +
          "    function gtag(){dataLayer.push(arguments);}\n" +
          "    gtag('js', new Date());\n" +
          "\n" +
          "    gtag('config', '" +
          googleAnalyticsCode +
          "',{\n" +
          "cookie_flags:'max-age=7200;secure;samesite-none'" +
          "});";

        head.insertBefore(googleAnalyticsSecondScript, head.firstChild);
        head.insertBefore(googleAnalyticsFirstScript, head.firstChild);
    }, []);


    const pubname = "greencast";
    const servicepage = "weathercast";
    const moduleName = "HistoricDisease";
    const unit = "metric";

    const {
        showLodding,
    } = useSelector((state: RootState) => state.diseaseReducer);
    const {
        homeIndexLoading,
    } = useSelector((state: RootState) => state.commonReducer);

    const [searchObj,setSearObj] = useState<ISearchObj>({
        theme: "light",
        country: "GB",
        language: "en",
        longitude: 0.1278,
        latitude: 51.5074,
        chartbgcolor: "false",
        headtitlebg: "#0C612C",
        buttonbg: "#59B948",
        forecastbtnbg: "#0071CD"
    });

    useEffect(()=>{
        document.body.style.backgroundColor = searchObj?.theme === "light" ? "white" : "black";
    },[searchObj?.theme]);


    // current location
    const [isReset,setIsReset] = useState(false);
    const changeChildIsReset = (data:boolean) =>{
        setIsReset(data);
    };
    const [currentLocation,setCurrentLocation] = useState<ILocationModel>(null);
    const changeChildCurrentLocation = (value: ILocationModel)=>{
        setCurrentLocation(value);
    };
    // get countryname form the lat and long
    const [initSearchCountry,setInitSearchCountry] = useState<ILocationModel>(null);
    const generateLocationByCoordinateFunc = async()=>{
        try{
            // const res = await generateLocationByCoordinate({
            //     latitude: searchObj.latitude,
            //     longitude: searchObj.longitude
            // });
            const res = await generateLocationByCoordinate({
                searchText: `${searchObj?.longitude},${searchObj?.latitude}`
            });
            const tmpData:ILocationModel = {
                AdminName: {_text: res.data[0].adminName},
                AdminName1: { _text: res.data[0].adminName1 },
                AdminName2: { _text: res.data[0].adminName2 },
                AdminName3: res.data[0].adminName3,
                AltName: res.data[0].altName,
                AreaId: res.data[0].areaId,
                CountryCode: { _text: res.data[0].countryCode },
                CountryName: { _text: res.data[0].countryName },
                Latitude: { _text: res.data[0].latitude },
                Longitude: { _text: res.data[0].longitude},
                Name: { _text: res.data[0].name},
                PlaceId: { _text: res.data[0].placeId},
                PostalCode: res.data[0].postalCode,
                Score: res.data[0].score,
                cultureCode: null,
                numberOfDays: null,
                isNowcast: null
            };
            console.log("my coun",res.data);
            console.log("my coun",res.data[0]);
            setInitSearchCountry(tmpData);
            // setInitSearchCountry(res.data);
        }
        catch(error){
            console.log("get countryname error",error);
        }
    };

    // series optiosn and translate
    const {initListDisease} = useHistoricDiseaseSeries({
        pubname,
        servicepage,
        moduleName,
        culture: searchObj.language + "-" + searchObj.country,
        countryCode: searchObj.country
    });
    const {histDiseaseTranslate} = useHistDiseaseTranslate({
        language: searchObj.language,
        countryCode: searchObj.country
    });

    const [echartData,setEchartData] = useState<IGetHistoricDiseaseCharInfoResModel>(null);
    const [diseaseShowOptions,setDiseaseShowOptions] = useState<IHistDiseaseLstSeriesModel[]>();
    const [startDate,setStartDate] = useState<dayjs.Dayjs>(dayjs().subtract(2,"day").subtract(6,"month"));
    const [endDate,setEndDate] = useState<dayjs.Dayjs>(dayjs().subtract(2,"day"));
    const childChangeStartDateFunc = (date:dayjs.Dayjs)=>{
        setStartDate(date);
    };
    const childChangeEndDateFunc = (date:dayjs.Dayjs)=>{
        setEndDate(date);
    };

    const publicChangeDiseaseShowOptions = (type:string,value:string | boolean,index?:number)=>{
        const tmp = [...diseaseShowOptions];
        tmp[index].isCheck = !!value;
        setDiseaseShowOptions(tmp);
    };

    const initSearchObj = ()=>{
        const searchStr = location?.search.replace("?","");
        const searchArr = searchStr?.split("&");
        const tmpSearchObj = {...searchObj};
        searchArr?.map((item)=>{
            const searchKeyValue = item?.split("=");
            if(searchKeyValue[0].toLowerCase() === "latitude" || searchKeyValue[0].toLowerCase() === "longitude"){
                tmpSearchObj[searchKeyValue[0].toLowerCase()] = Number(searchKeyValue[1]);
            }
            else{
                tmpSearchObj[searchKeyValue[0].toLowerCase()] = searchKeyValue[1];
            }
        });
        setSearObj(tmpSearchObj);
    };

    // color and id array, used in echarts
    const echartContainerRef = useRef(null);
    const [echartsColor,setEchartsColor] = useState<string[]>([]);
    const [echartsRiskId,setEchartsRiskId] = useState<string[]>([]);
    const [echartSeriesName,setEchartSeriesName] = useState<string[]>([]);
    const publicChangeColorAndIdArray = (initFlag?:boolean)=>{
        const tmpColor = [];
        const tmpId = [];
        const tmpSeriesName = [];
        let tmpDiseaseShowOptions = [];
        if(initFlag){
            tmpDiseaseShowOptions = [...initListDisease.lstResultseries];
        }
        else{
            tmpDiseaseShowOptions = [...diseaseShowOptions];
        }
        tmpDiseaseShowOptions?.forEach((item)=>{
            if(item.isCheck){
                tmpColor.push(item.color);
                tmpId.push(item.id);
                tmpSeriesName.push(item.text);
            }
        });
        setEchartsColor(tmpColor);
        setEchartsRiskId(tmpId);
        setEchartSeriesName(tmpSeriesName);
    };
    const selectedDiseaseRiskId = (initFlag?:boolean):string=>{
        let tmpId = "";
        let tmpDiseaseShowOptions = [];
        if(initFlag){
            tmpDiseaseShowOptions = [...initListDisease.lstResultseries];
        }
        else{
            tmpDiseaseShowOptions = [...diseaseShowOptions];
        }
        tmpDiseaseShowOptions?.forEach((item)=>{
            if(item.isCheck) tmpId = tmpId + item.id + ";";
        });
        return tmpId;
    };

    // email this page
    const [showEmailModal,setShowEmailModal] = useState(false);
    const [imageBase64Content,setImageBase64Content] = useState(null);

    // download image func
    const saveMapToDataUrl = ()=>{
        const element = document.getElementById("chartContentDiv");
        const element1 = document.querySelector(".rightLatLng") as HTMLElement;
        const element2 = document.querySelector(".chart-contnet-right-title") as HTMLElement;
        element1.style.display = "block";
        element2.style.display = "none";
        console.log("test images",element);
        if(element){
            html2canvas(element,{
                useCORS: true
            }).then((canvas)=>{
                const dataUrl = canvas.toDataURL("image/png");
                console.log("test images",dataUrl);

                const link = document.createElement("a");
                link.href = dataUrl;
                link.download = searchObj.country + ".png";
                link.click();
                element1.style.display = "none";
                element2.style.display = "block";
            });
        }
    };
    const getImageBase64Data = ()=>{
        const element = document.getElementById("chartContentDiv");
        const element1 = document.querySelector(".rightLatLng") as HTMLElement;
        const element2 = document.querySelector(".chart-contnet-right-title") as HTMLElement;
        element1.style.display = "block";
        element2.style.display = "none";
        console.log("test images",element);
        if(element){
            html2canvas(element,{
                useCORS: true
            }).then((canvas)=>{
                const dataUrl = canvas.toDataURL("image/png");
                setImageBase64Content(dataUrl);
                element1.style.display = "none";
                element2.style.display = "block";
            });
        }
    };

    // set disease show options according by the initListDisease
    const changeDiseaseShowOptions = ()=>{
        // const tmpListResultSeries:IHistDiseaseLstSeriesModel[] = [...initListDisease.lstResultseries];
        const tmpListResultSeries:IHistDiseaseLstSeriesModel[] = JSON.parse(JSON.stringify(initListDisease.lstResultseries));
        tmpListResultSeries?.map((item,index)=>{
            item.color = SvgAndColorArr[index]["svgColor"];
            item.svg_render = SvgAndColorArr[index]["svgComponent"];
        });
        setDiseaseShowOptions(tmpListResultSeries);
    };

    const getHistoricalDiseaseCharInfoFunc = async(initFlag?:boolean)=>{
        try{
            const tmpId = selectedDiseaseRiskId(initFlag);
            // const tmpStartDate = initFlag ? dayjs().format("DD/MM/YYYY") : startDate.format("DD/MM/YYYY");
            // const tmpEndDate = initFlag ? dayjs().add(2,"month").format("DD/MM/YYYY") : endDate.format("DD/MM/YYYY");
            const tmpStartDate = initFlag ? dayjs().subtract(2,"day").subtract(6,"month").format("DD/MM/YYYY") : startDate.format("DD/MM/YYYY");
            const tmpEndDate = initFlag ? dayjs().subtract(2,"day").format("DD/MM/YYYY") : endDate.format("DD/MM/YYYY");
            dispatch({ type: normalActionTypes.NORMAL_SHOWLODDING, payload: true});
            const res = await getHistoricDiseaseCharInfo({
                pubname: pubname,
                // servicepage: servicepage,
                // moduleName: moduleName,
                unit: unit,
                culture: searchObj.language + "-" + searchObj.country,
                countryCode: searchObj.country,
                latitude: Number(currentLocation?.Latitude?._text) || Number(initSearchCountry?.Latitude?._text) || searchObj.latitude,
                longitude: Number(currentLocation?.Longitude?._text) || Number(initSearchCountry?.Longitude?._text) || searchObj.longitude,
                startDate: tmpStartDate,
                endDate: tmpEndDate,
                checkedSeries: tmpId,
                isGreencastHistorical: true
            });
            setEchartData(res.data);
            dispatch({ type: normalActionTypes.NORMAL_SHOWLODDING, payload: false});
        }
        catch(error){
            console.log("error",error);
        }
    };

    // clear selection
    const clearSelection = ()=>{
        setIsReset(true);
        // reset the disease show options
        changeDiseaseShowOptions();
        // reset the search obj
        initSearchObj();
        setCurrentLocation(null);
        // reset the date
        // setStartDate(dayjs());
        // setEndDate(dayjs().add(2,"month"));
        setStartDate(dayjs().subtract(2,"day").subtract(6,"month"));
        setEndDate(dayjs().subtract(2,"day"));
        // refresh Color and Id array
        publicChangeColorAndIdArray(true);
        // start execute the query function
        getHistoricalDiseaseCharInfoFunc(true);
    };

    useEffect(()=>{
        console.log("test init",initListDisease);
        if(initListDisease?.lstResultseries?.length > 0){
            changeDiseaseShowOptions();
            // refresh Color and Id array
            publicChangeColorAndIdArray(true);
            // and should execute the query data function
            getHistoricalDiseaseCharInfoFunc(true);
        }
    },[initListDisease]);

    useEffect(() => {
        // init the searchobj, and the value data is form the url
        initSearchObj();
    }, [urlLocation]);

    const [initLocationCount,setInitLocationCount] = useState(0);
    useEffect(()=>{
        if(currentLocation?.Latitude?._text && currentLocation?.Longitude?._text){
            const tmpSearchObj = {...searchObj};
            tmpSearchObj.latitude = Number(currentLocation?.Latitude?._text);
            tmpSearchObj.longitude = Number(currentLocation?.Longitude?._text);
            setSearObj(tmpSearchObj);
            setInitLocationCount(initLocationCount + 1);
        }
    },[currentLocation]);
    useEffect(()=>{
        if(initLocationCount >= 1){
            console.log("ffff initLocationCount",initLocationCount);
            console.log("ffff initLocationCount",currentLocation);
            getHistoricalDiseaseCharInfoFunc(true);
            publicChangeColorAndIdArray();
        }
    },[initLocationCount,searchObj?.latitude,searchObj?.longitude]);

    useEffect(()=>{
        const getCountryNameByLagLongDebounceFunc = debounce(generateLocationByCoordinateFunc,500);
        getCountryNameByLagLongDebounceFunc();
        return()=>{
            getCountryNameByLagLongDebounceFunc.cancel();
        };
    },[searchObj.latitude,searchObj.longitude]);

    console.log("searchObj",searchObj);
    console.log("initSearchCountry",initSearchCountry);
    const [echartContainer,setEchartContainer] = useState<any>();
    useEffect(()=>{
        if(echartContainerRef.current){
            const updateWidth = ()=>{
                const width = echartContainerRef.current.clientWidth;
            };
            updateWidth();
        }
    },[]);
    console.log("lanxue change width echartContainerRef.current.clientWidth",echartContainerRef.current?.clientWidth);

    //if it is hexadecimal color value
    function isHexColor(color) {
        const regex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;
        return regex.test(color);
    }
    //Encapsulate the logic of creating style objects into a function
    function createBgStyle(hexColor) {
        if (hexColor) {
        // Ensure that hexColor is a valid hexadecimal color value
            if (isHexColor(hexColor)) {
                return {
                    backgroundColor: hexColor,
                    color: "#FFFFFF",
                    border:"none",
                };
            } else {
            // If hexColor is not a valid color value, print the log
                console.log("Please provide hexadecimal color values");
            }
        }
        // If hexColor is not provided, return null
        return null;
    }

    const truebuttonbg="#"+searchObj.buttonbg;

    // Creating Style Objects with Functions
    const headTitleStyle = createBgStyle("#" + searchObj.headtitlebg);
    const buttonbgStyle = createBgStyle("#" + searchObj.buttonbg);
    const forecastbtnbgStyle = createBgStyle("#" + searchObj.forecastbtnbg);

    const buttonStyle = {
        border:`1px solid ${truebuttonbg}`,
        color:truebuttonbg,
    };
    const iconColor="#FFFFFF";
    return (
        <div className="hist-disease-chart-all-container">
            <div className={`hist-disease-chart bg-${searchObj.theme}`}>
                <Spin spinning={showLodding || homeIndexLoading}>
                    <div className={"hist-disease-chart-container"}>
                        <div className="accurate-left">
                            <div className="head-title" style={headTitleStyle}>
                                {/* Historical Disease Chart */}
                                {histDiseaseTranslate?.txt_HistoricDiseaseChart || "Historical Disease Chart"}
                            </div>
                            <div className="ag-search-div">
                                <span className="search-title theme-title-color">
                                    {/* Select your location to see accurate weather */}
                                    {/* {histDiseaseTranslate?.txt_SelectLocation || "Select your location to see accurate weather"} */}
                                    {histDiseaseTranslate?.txt_SelectLocationToSeeHistWeather || "Select your location to see historical weather"}
                                </span>
                                <AGHistSearchLocation
                                    style={buttonbgStyle}
                                    iconColor={iconColor}
                                    country={searchObj.country}
                                    language={searchObj.language}
                                    initSearchCountryName={initSearchCountry?.Name?._text}
                                    placahold={histDiseaseTranslate?.txt_LocationNameLngLat}
                                    currentLocation={currentLocation}
                                    isReset = {isReset}
                                    changeChildCurrentLocation={changeChildCurrentLocation}
                                    changeChildIsReset={changeChildIsReset}
                                />
                            </div>
                            <div className="ag-bookmark">
                                <label className="bookmark-title theme-title-color">
                                    {/* Select your start and end date */}
                                    { histDiseaseTranslate?.txt_SelYourStartAndEndDate || "Select your start and end date"}
                                </label>
                                <div className="select-div">
                                    <DateChange
                                        countrycode={searchObj?.country}
                                        className="hisDisease-date"
                                        startDate={startDate}
                                        endDate={endDate}
                                        childChangeEndDateFunc={childChangeEndDateFunc}
                                        childChangeStartDateFunc={childChangeStartDateFunc}
                                    />
                                </div>
                            </div>
                            <div className="ag-weather-list">
                                <label className="top-title theme-title-color">
                                    {/* Disease Risks */}
                                    {histDiseaseTranslate?.txt_DiseaseRisks || "Disease Risks"}
                                </label>
                                <label className="sub-title theme-title-color">
                                    {/* Select disease risk below to add or remove graph parameters, then click refresh chart to display changes */}
                                    {histDiseaseTranslate?.txt_SelectDiseaseRiskMessage || "Select disease risk below to add or remove graph parameters, then click refresh chart to display changes"}
                                </label>
                            </div>
                            <div className="ag-disease-risk-list-container theme-title-color">
                                {
                                    diseaseShowOptions?.map((item,index)=>{
                                        return(
                                            <>
                                                <div className="ag-disease-risk-item">
                                                    <Input
                                                        type="checkbox"
                                                        checked = {item?.isCheck}
                                                        onChange={(e)=>{
                                                            console.log("e",e.target.checked);
                                                            publicChangeDiseaseShowOptions("isCheckd",e.target.checked,index);
                                                        }}
                                                    />
                                                    <span>{item?.text}</span>
                                                </div>
                                            </>
                                        );
                                    })
                                }
                            </div>
                            <div className="ag-disease-button-group theme-title-color">
                                <button style={buttonStyle} className="common-button clear-button theme-title-color"
                                    onClick={()=>{
                                        console.log("clear");
                                        clearSelection();
                                    }}
                                >
                                    {/* Clear Selection */}
                                    {histDiseaseTranslate?.ClearSelection || "Clear Selection"}
                                </button>
                                <button style={buttonbgStyle} className="common-button refresh-button theme-title-color"
                                    onClick={()=>{
                                        getHistoricalDiseaseCharInfoFunc();// get final query data
                                        publicChangeColorAndIdArray(); // change color and id array
                                    }}
                                >
                                    {/* Refresh Chart */}
                                    {histDiseaseTranslate?.gc_refreshchart || "Refresh Chart"}
                                </button>
                            </div>
                        </div>
                        <div className="accurate-right">
                            <div className="top-header-div" style={headTitleStyle}>
                                <div className="left-title">
                                    <span className="main-title">
                                        {/* London City Airport */}
                                        {/* {(currentLocation?.Name?._text || initSearchCountry?.Name?._text) || "London City Airport"} */}
                                        {(currentLocation?.Name?._text || initSearchCountry?.Name?._text)}
                                    </span>
                                    <span className="sub-lable" style={headTitleStyle}>
                                        {/* 51.507351, -0.127758 */}
                                        {`${searchObj?.longitude} , ${searchObj?.latitude}`}
                                    </span>
                                </div>
                                <div className="right-title"
                                    onClick={()=>{
                                        getImageBase64Data();
                                        setShowEmailModal(true);
                                    }}
                                >
                                    <Eamil_Svg
                                        width={15}
                                        height={12}
                                    />
                                    <span>
                                        {/* Email this page */}
                                        {histDiseaseTranslate?.email_to_page || "Email this page"}
                                    </span>
                                </div>
                            </div>
                            <IF show={showEmailModal}>
                                <AGSentEmailModal
                                    open = {showEmailModal}
                                    id={"hist-disease-sentEmailModal"}
                                    welcomeStr={histDiseaseTranslate?.gc_EmailHeader}
                                    toStr={histDiseaseTranslate?.gc_EmailTo}
                                    subjectStr={histDiseaseTranslate?.gc_EmailSubject}
                                    messageStr={histDiseaseTranslate?.gc_EmailMessageArea}
                                    idRequiredStr={histDiseaseTranslate?.gc_EmailRequired}
                                    subjectRequiredStr={histDiseaseTranslate?.gc_EmailSubjectRequired}
                                    sendEmailStr={histDiseaseTranslate?.gc_SendEmailButton}
                                    cancelStr={histDiseaseTranslate?.cancel}
                                    idValidStr={histDiseaseTranslate?.gc_EmailValid}
                                    emailFailedStr={histDiseaseTranslate?.gc_EmailSendFailed}
                                    defaultTitle={histDiseaseTranslate?.txt_ShareChartByEmail}
                                    defaultSubject={histDiseaseTranslate?.txt_HistoricDiseaseChart}
                                    defaultMailContent={histDiseaseTranslate?.txt_HistDiseaseChartMailBody}
                                    sentEmailSuccessStr={histDiseaseTranslate?.gc_EmailSendSuccess}
                                    cancelCallback={()=>{
                                        setShowEmailModal(false);
                                    }}
                                    imageBase64={imageBase64Content}
                                />
                            </IF>
                            <div className="chart-content-container"
                                id="chartContentDiv"
                                ref = {echartContainerRef}
                            >
                                <div className="chart-content-title">
                                    <div className="chart-contnet-left-title">
                                        <span>
                                            {/* Historic Disease Risk - London City Airport */}
                                            {/* {histDiseaseTranslate?.txt_HistoricDiseaseRisk || "Historic Disease Risk"} - {(currentLocation?.Name?._text || initSearchCountry?.Name?._text) || "London City Airport"} */}
                                            {`${histDiseaseTranslate?.txt_HistoricDiseaseRisk || "Historic Disease Risk"} - ${(currentLocation?.Name?._text || initSearchCountry?.Name?._text) || " "}`}
                                            <span style={{display:"none"}} className="rightLatLng">{` (${searchObj?.longitude} , ${searchObj?.latitude})`}</span>
                                        </span>
                                    </div>
                                    <div className="chart-contnet-right-title"
                                        style={{cursor:"pointer"}}
                                        onClick={saveMapToDataUrl}
                                    >
                                        <Download_Svg
                                            width={18}
                                            height={18}
                                        />
                                        <span>
                                            {/* Download as Image */}
                                            {histDiseaseTranslate?.txt_DownloadAsImage || "Download as Image"}
                                        </span>
                                    </div>
                                </div>

                                <div >
                                    <div className="chart-content-image">
                                        <EchartRander
                                            name={"Historic Disease Risk"}
                                            echartData={echartData}
                                            colorArray={echartsColor}
                                            idArray={echartsRiskId}
                                            width={echartContainerRef?.current?.clientWidth}
                                            // width={echartContainer?.style?.width}
                                            countrycode={searchObj?.country}
                                            echartSeries={echartSeriesName}
                                            echartTitle= {`${histDiseaseTranslate?.txt_DiseaseRisks || "Disease Risk"}  ${histDiseaseTranslate?.txt_Legend || "Legend"}`}
                                            chartbgcolor = {searchObj?.chartbgcolor}
                                        />
                                    </div>

                                    <div className="chart-content-risk" >
                                        <div className="chart-content-risk-title">
                                            <span>
                                                {/* Disease Risk Legend */}
                                                {/* {`${histDiseaseTranslate?.txt_DiseaseRisks || "Disease Risk"}  ${histDiseaseTranslate?.txt_Legend || "Legend"}`} */}
                                            </span>
                                        </div>
                                        {/* <div className="chart-content-risk-list">
                                            {diseaseShowOptions?.filter((innerItem,index)=>{
                                                if(innerItem.isCheck === true) return innerItem;
                                            })?.map((item,index)=>{
                                                return(
                                                    <>
                                                        <div className="chart-content-risk-item" id={index + item?.text}>
                                                            {item?.svg_render}
                                                            <div className="chart-content-risk-item-title">
                                                                <span>{item?.text}</span>
                                                            </div>
                                                        </div>
                                                    </>
                                                );
                                            })}
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Spin>
            </div>
        </div>


    );
};



export default AGAccurateWeather;