import React, { useEffect, useRef, useState } from "react";
import "./index.less";
import RiskPicAndNotes from "./components/RiskPicAndNotes";
import DownloadImage from "@/resource/images/svg/diseaseMap/download.svg";
import { DiseaseMapDownloadSvg } from "@/components/IconComponents/iconComponents";
import MapRender from "./components/MapRender";
import { useLocation } from "react-router-dom";

import {Select, Spin} from "antd";

import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);
const {Option} = Select;

// function

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/redux/reducer/store";
import { sagaActionTypes } from "@/redux/actions/diseaseAction";
// import { diseaseActionTypes } from "@/redux/actions/diseaseAction";
import {debounce} from "lodash";
import { IDiseaseMapVoerlaysModel } from "@/util/http/responseType";
import {useTrackedEffect,useRequest} from "ahooks";

import DateChange from "./components/DateChange";
import IF from "@/components/IF";
import html2canvas from "html2canvas";
import useDiseaseMapTranslate from "./components/SelfHooks/useDiseaseMapTranslate";
import { generateLocationByCoordinate } from "@/util/http/HistWeather";
import AGForcastButton from "@/components/ForecastProviderButton";
import {CountryAbbrWeek,CountryDateFormat} from "@/resource/js/dateSetting";
import { checkServiceTokenIsValid } from "@/util/http";

interface ISearchObj{
    theme?:string;
    forcastday: number;
    startdate: string;
    country: string;
    language: string;
    headtitlebg?: string;
    tabletitlebg?: string;
    forecastbtnbg?: string;
    securitykey?: string;
}

const DiseaseMap = () => {
    const location = useLocation();
    const dispatch = useDispatch();

    // add google analysis
    useEffect(() => {
        console.log("just invoke once");
        const head = document.getElementsByTagName("head")[0];
        const googleAnalyticsCode = "G-MVZ1BGCWDK";
        const googleAnalyticsFirstScript = document.createElement("script");
        googleAnalyticsFirstScript.async = true;
        googleAnalyticsFirstScript.src =
          "https://www.googletagmanager.com/gtag/js?id=" + googleAnalyticsCode;

        const googleAnalyticsSecondScript = document.createElement("script");
        googleAnalyticsSecondScript.innerHTML =
          "    window.dataLayer = window.dataLayer || [];\n" +
          "    function gtag(){dataLayer.push(arguments);}\n" +
          "    gtag('js', new Date());\n" +
          "\n" +
          "    gtag('config', '" +
          googleAnalyticsCode +
          "',{\n" +
          "cookie_flags:'max-age=7200;secure;samesite-none'" +
          "});";

        head.insertBefore(googleAnalyticsSecondScript, head.firstChild);
        head.insertBefore(googleAnalyticsFirstScript, head.firstChild);
    }, []);

    const height = window.screen.height;
    const [searchObj,setSearObj] = useState<ISearchObj>({
        theme: "light",
        forcastday: 7,
        startdate: dayjs().format("DD/MM/YYYY"),
        country: "GB",
        language: "en",
        headtitlebg: "#0C612C",
        tabletitlebg: "#19A04B",
        forecastbtnbg: "#0071CD",
        securitykey: null
    });
    const [weekOjb,setWeekOjb] = useState<string[]>(CountryAbbrWeek["US"]);
    const AURegion = [
        "New South Wales & Australian Capital Territory",
        "Northern Territory",
        "Queensland",
        "South Australia",
        "Tasmania",
        "Victoria",
        "Western Australia",
        "New Zealand"
    ];
    // get data from redux
    const {
        diseaseData,
        translateText,
        showLodding
    } = useSelector((state: RootState) => state.diseaseReducer);
    console.log("lanaa diseaseData",diseaseData);
    console.log("lanaa translateText",translateText);
    useEffect(()=>{
        document.body.style.backgroundColor = searchObj?.theme === "light" ? "white" : "black";
    },[searchObj?.theme]);
    const [selectedRegion,setSelectedRegion] = useState("New South Wales & Australian Capital Territory");
    const [selectedDisease,setSelectedDisease] = useState("");
    const childChangeSelectedDate = (value:string)=>{
        const tmpSearchObj = {...searchObj};
        tmpSearchObj.startdate = value;
        setSearObj(tmpSearchObj);
    };
    const [currentSelectedDate,setCurrentSelectedDate] = useState(null);
    const childChangeCurrentSelectedDate = (value:string)=>{
        setCurrentSelectedDate(value);
    };

    const initSearchObj = ()=>{
        const searchStr = location?.search.replace("?","");
        const searchArr = searchStr?.split("&");
        const tmpSearchObj = {...searchObj};
        let tmpStartDate = "";
        let tmpCountry = "";
        let haveSecurityFlag = false;
        searchArr?.map((item)=>{
            const searchKeyValue = item?.split("=");
            if(searchKeyValue[0].toLowerCase() === "forcastday"){
                tmpSearchObj[searchKeyValue[0]] = Number(searchKeyValue[1]);
            }
            else if(searchKeyValue[0].toLowerCase() === "startdate"){
                // tmpSearchObj[searchKeyValue[0].toLowerCase()] = dayjs(searchKeyValue[1],tmpFormat).format("DD/MM/YYYY");
                tmpSearchObj[searchKeyValue[0].toLowerCase()] = searchKeyValue[1];
                tmpStartDate = searchKeyValue[1];
            }
            else if(searchKeyValue[0].toLowerCase() === "country"){
                // tmpSearchObj[searchKeyValue[0].toLowerCase()] = dayjs(searchKeyValue[1],tmpFormat).format("DD/MM/YYYY");
                tmpSearchObj[searchKeyValue[0].toLowerCase()] = searchKeyValue[1];
                tmpCountry = searchKeyValue[1];
            }
            else if(searchKeyValue[0].toLowerCase() === "securitykey"){
                if(searchKeyValue[1]){
                    tmpSearchObj[searchKeyValue[0].toLowerCase()] = searchKeyValue[1];
                    haveSecurityFlag = true;
                }
            }
            else{
                tmpSearchObj[searchKeyValue[0].toLowerCase()] = searchKeyValue[1];
            }
        });
        if(!haveSecurityFlag){
            tmpSearchObj.securitykey = "no-value";
        }
        const tmpFormat = CountryDateFormat[tmpCountry] || "DD/MM/YYYY";
        // tmpSearchObj.startdate = dayjs(tmpStartDate || tmpSearchObj.startdate,[tmpFormat,"YYYY-MM-DD","DD/MM/YYYY"]).format("DD/MM/YYYY");
        if(tmpStartDate){
            tmpSearchObj.startdate = dayjs(tmpStartDate ,[tmpFormat,"YYYY-MM-DD","DD/MM/YYYY"]).format("DD/MM/YYYY");
        }

        setSearObj(tmpSearchObj);
    };

    // when language or countryCode change, request the new translate
    const getDiseaseDataAndTranslateText = ()=>{
        console.log("current countrycdoe", searchObj.country);
        // get Disease Data by saga, and put response to the redux store
        dispatch({
            type: sagaActionTypes.SAGA_DISEASE_DATA,
            payload:{
                language: searchObj.language,
                countrycode: searchObj.country,
                token: null
            }
        });
    };
    const getDiseaseRiskInfoByNameFunc = ()=>{
        const tmpSearchDate = dayjs(searchObj.startdate,"DD/MM/YYYY").format("YYYY-MM-DD") === currentSelectedDate ?
            dayjs(searchObj.startdate,"DD/MM/YYYY").format("YYYY-MM-DD") : currentSelectedDate;
        // get disease risk info , this data is used to render the google map
        dispatch({
            type: sagaActionTypes.SAGA_DISEASERISKINFO,
            payload:{
                countrycode: searchObj.country,
                startDatetimeUtc: tmpSearchDate,
                riskName: selectedDisease,
                region: searchObj.country === "AU" ? selectedRegion : null
            }
        });
    };

    // translate
    const {diseaseMapTranslate} = useDiseaseMapTranslate({
        language: searchObj.language,
        countryCode: searchObj.country
    });

    // all disease options and init center
    const [diseaseOptions,setDiseaseOptions] = useState<IDiseaseMapVoerlaysModel[]>([]);


    // =================== useEffect ===============
    useEffect(()=>{
        if(searchObj.startdate){
            const tmpCurrentDate = dayjs(searchObj.startdate,"DD/MM/YYYY").format("YYYY-MM-DD");
            setCurrentSelectedDate(tmpCurrentDate);
        }
    },[searchObj.startdate]);
    useEffect(()=>{
        getDiseaseRiskInfoByNameFunc();
    },[searchObj.country,searchObj.language,searchObj.startdate,selectedDisease,currentSelectedDate,selectedRegion]);
    useEffect(()=>{
        getDiseaseDataAndTranslateText();
    },[searchObj.country,searchObj.language]);
    useTrackedEffect((changes)=>{
        console.log("current countrycdoe dependess",changes);
    },[searchObj.country,searchObj.language,searchObj.forcastday,searchObj.startdate,searchObj.theme]);
    useEffect(()=>{
        initSearchObj();
    },[location]);
    useEffect(()=>{
        if(diseaseData?.mapSetting[0]?.overlays?.length > 0){
            const tmpDisease = diseaseData.mapSetting[0].overlays?.filter((item)=>{
                if(item.diseasename !== "TempSoil005cm_dAVG") return item;
            });
            setDiseaseOptions(tmpDisease);
            setSelectedDisease(diseaseData.mapSetting[0].overlays[0]?.diseasename);
        }
    },[diseaseData]);
    useEffect(()=>{
        if(CountryAbbrWeek[searchObj?.country?.toUpperCase()]){
            const tmpWeaek = CountryAbbrWeek[searchObj?.country?.toUpperCase()];
            setWeekOjb(tmpWeaek);
        }
        else{
            const tmpWeaek = CountryAbbrWeek["US"];
            setWeekOjb(tmpWeaek);
        }
    },[searchObj?.country]);

    const saveMapToDataUrl = ()=>{
        const element = document.getElementById("mapDiv");
        const element1 = document.querySelector(".download-item") as HTMLElement;
        element1.style.display = "none";
        console.log("test images",element);
        if(element){
            html2canvas(element,{
                useCORS: true
            }).then((canvas)=>{
                const dataUrl = canvas.toDataURL("image/png");
                console.log("test images",dataUrl);

                const link = document.createElement("a");
                link.href = dataUrl;
                link.download = searchObj.country + ".png";
                link.click();
                element1.style.display = "flex";
            });
        }
    };


    //if it is hexadecimal color value
    function isHexColor(color) {
        const regex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;
        return regex.test(color);
    }
    //Encapsulate the logic of creating style objects into a function
    function createBgStyle(hexColor) {
        if (hexColor) {
            // Ensure that hexColor is a valid hexadecimal color value
            if (isHexColor(hexColor)) {
                return {
                    backgroundColor: hexColor,
                    color: "#FFFFFF",
                    border:"none",
                };
            } else {
                // If hexColor is not a valid color value, print the log
                console.log("Please provide hexadecimal color values");
            }
        }
        // If hexColor is not provided, return null
        return null;
    }

    // Creating Style Objects with Functions
    const headTitleStyle = createBgStyle("#" + searchObj.headtitlebg);
    const tabletitlebgStyle = createBgStyle("#" + searchObj.tabletitlebg);
    const forecastbtnbgStyle = createBgStyle("#" + searchObj.forecastbtnbg);

    const test1="#" + searchObj.headtitlebg;
    const test2="#" + searchObj.tabletitlebg;

    /**
     * used for controll page whether hidden
     */
    const [showPageByToken,setShowPageByToken] = useState(true);
    const isFirstRender = useRef(true);
    const checkServiceTokenIsValidFunc = async()=>{
        try{
            const res = await checkServiceTokenIsValid(searchObj.securitykey);
            setShowPageByToken(res.data);
            if(!res.data){
                const element1 = document.querySelector(".dark-theme") as HTMLElement;
                if(element1){
                    element1.style.backgroundColor = "white";
                }
            }
        }
        catch(error){
            console.log("error",error);
        }
    };
    useEffect(()=>{
        if (isFirstRender.current) {
            isFirstRender.current = false; // first render
            return; // when first render, do nothing
        }
        // if(searchObj?.securitykey){
        //     if(searchObj.securitykey === "no-value"){
        //         setShowPageByToken(false);
        //     }
        //     else{
        //         checkServiceTokenIsValidFunc();
        //     }
        // }
    },[searchObj.securitykey]);

    return (
        <>
            {
                showPageByToken ?
                    <div className="v3-diseasemap-all-container">
                        <div className={`v3-main-bg bg-${searchObj.theme}`}
                            // style={{minHeight:`${height}px`}}
                        >
                            <div className="v3-diseasemap">
                                <div style={headTitleStyle} className="diseasemap-header">
                                    {/* <h2>Disease Map</h2> */}
                                    <h2>
                                        {diseaseMapTranslate?.txt_DiseaseMap || "Disease Map"}
                                    </h2>
                                </div>
                                <div id="mapDiv">
                                    {/* select disease map */}
                                    <div className="disease-select-container bg-header">
                                        <div style={{padding:"0px 25px"}} className="disease-select-container-flex">
                                            <div className="crop-item">
                                                <div className="disease-item view-item">
                                                    <div className="disease-item-title base-font">
                                                        {/* Select disease to view map */}
                                                        {diseaseMapTranslate?.txt_SelDiseaseToViewMap || "Select disease to view map"}
                                                    </div>
                                                    <div className="disease-select-item">
                                                        <Select className="base-button"
                                                            value={selectedDisease}
                                                            onChange={(e)=>{
                                                                setSelectedDisease(e);
                                                            }}
                                                        >
                                                            {diseaseOptions?.map((item, index) => {
                                                                return (
                                                                    <>
                                                                        <Option key={item.overlaysName + index} value={item.diseasename}>
                                                                            {item.overlaysName}
                                                                        </Option>
                                                                    </>
                                                                );
                                                            })}
                                                        </Select>
                                                    </div>
                                                </div>
                                                <IF show={searchObj.country === "AU"}>
                                                    <div className="disease-item">
                                                        <div className="disease-item-title base-font">
                                                            {/* Select Region */}
                                                            {diseaseMapTranslate?.gc_SelectRegion || "Select Region"}
                                                        </div>
                                                        <div className="disease-select-item">
                                                            <Select className="base-button"
                                                                value={selectedRegion}
                                                                onChange={(e)=>{
                                                                    setSelectedRegion(e);
                                                                }}
                                                                popupMatchSelectWidth = {false}
                                                            >
                                                                {AURegion?.map((item, index) => {
                                                                    return (
                                                                        <>
                                                                            <option key={item + index} value={item}>
                                                                                {item}
                                                                            </option>
                                                                        </>
                                                                    );
                                                                })}
                                                            </Select>
                                                        </div>
                                                    </div>
                                                </IF>
                                                <div className="disease-item date-item">
                                                    <div className="disease-item-title base-font">
                                                        <span>
                                                            {/* Select start date : */}
                                                            {diseaseMapTranslate?.txt_SelStartDate || "Select start date :"}
                                                        </span>
                                                    </div>
                                                    <div className="disease-select-date-item base-font">
                                                        <DateChange
                                                            dateFormat={CountryDateFormat[searchObj.country?.toUpperCase()] || "DD/MM/YYYY"}
                                                            countrycode={searchObj.country}
                                                            className="base-button"
                                                            forcastDay={searchObj?.forcastday}
                                                            selectedData={searchObj.startdate}
                                                            childChangeDateFunc={childChangeSelectedDate}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="download-item"
                                                style={{cursor:"pointer"}}
                                                onClick={()=>{
                                                    saveMapToDataUrl();
                                                }}
                                            >
                                                {/* <img src={DownloadImage} /> */}
                                                <DiseaseMapDownloadSvg
                                                    theme={searchObj?.theme}
                                                />
                                                <span className="font-14">
                                                    {/* Download as Image */}
                                                    {diseaseMapTranslate?.txt_DownloadAsImage || "Download as Image"}
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    {/* map Render */}
                                    <Spin spinning={showLodding}>
                                        <MapRender
                                            backgroundColor1={test2}
                                            backgroundColor2={test1}
                                            forcastDay={searchObj.forcastday}
                                            countrycode={searchObj.country}
                                            region={selectedRegion}
                                            startDate={searchObj.startdate}
                                            selectedCountry={searchObj.country}
                                            selectedDisease={selectedDisease}
                                            dateFormat={CountryDateFormat[searchObj.country] || "DD/MM/YYYY"}
                                            weekOjb = {weekOjb}
                                            childChangeCurrentSelectedDate={childChangeCurrentSelectedDate}
                                        />
                                    </Spin>
                                    {/* risk show components */}
                                    <RiskPicAndNotes
                                        title={`${diseaseMapTranslate?.txt_DiseaseRisks || "Disease Risk"} ${diseaseMapTranslate?.txt_Color || "Color"} ${diseaseMapTranslate?.txt_Legend || "Legend"}`}
                                        disease={selectedDisease}
                                    />
                                </div>
                                <div className="forecastbutton">
                                    <AGForcastButton style={forecastbtnbgStyle}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="error_page">
                        <div className="error_content">
                            <span>There is an issue with accessing the page. </span>
                            <span>Please provide the correct token.</span>
                        </div>
                    </div>
            }
        </>

    );
};

export default DiseaseMap;
