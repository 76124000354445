import React from "react";
import { RouterProvider } from "react-router-dom";
import routers from "./router/routers";
import "./App.less";
import "./global.less";
import { ConfigProvider, Spin } from "antd";
import { useSelector } from "react-redux";
import { RootState } from "./redux/reducer/store";

const App = () => {

    const loading = useSelector((state: RootState) => state.commonReducer.loading);

    return (
        <React.Suspense fallback={"...loading"}>
            <ConfigProvider
                theme={{
                    components: {
                        Button: {
                            colorText: "white"
                        }
                    }
                }}
            >
                <Spin spinning={loading}>
                    <div className="App">
                        <RouterProvider router={routers} />
                    </div>
                </Spin>
            </ConfigProvider>
        </React.Suspense>
    );
};

export default App;
