import {
    IBookmarkResModel,
    IDailyDataModel,
    IGetCorpsSprayResModel,
    IHour24DataModel,
    IHour8DataModel,
    ILocalDailyWidgetWeatherResModel,
    ILocationModel,
    INewLocationModel,
    IWeatherForcastDailyListResModel
} from "@/util/http/responseType";
import { IUserModel } from "../reducer/commonReducer";

export const normalActionTypes = {
    SHOW_LOADING: "SHOW_LOADING",
    HIDE_LOADING: "HIDE_LOADING",
    COMMON_ADD_USER: "COMMON_ADD_USER",
    NORMAL_UPDATE_LOCATION_SEARCH_RESULT: "NORMAL_UPDATE_LOCATION_SEARCH_RESULT",
    NORMAL_UPDATE_CURRENT_LOCATION: "NORMAL_UPDATE_CURRENT_LOCATION",
    NORMAL_UPDATE_USERNAME: "NORMAL_UPDATE_USERNAME",
    NORMAL_UPDATE_SERVICETOKEN: "NORMAL_UPDATE_SERVICETOKEN",
    NORMAL_TRANSLATETEXT: "NORMAL_TRANSLATETEXT",
    NOMRAL_UPDATE_BOOKMARK: "NOMRAL_UPDATE_BOOKMARK",
    NORMAL_UPDATE_WEATHERFORCAST_DAILY_LIST: "NORMAL_UPDATE_WEATHERFORCAST_DAILY_LIST",
    NORMAL_GET_LOCAL_HOURLY_WIDGET: "NORMAL_GET_LOCAL_HOURLY_WIDGET",
    NORMAL_UPDATE_CURRENT_LANGUAGE: "NORMAL_UPDATE_CURRENT_LANGUAGE",
    NORMAL_UPDATE_CHART_DATA: "NORMAL_UPDATE_CHART_DATA",
    NOMRAL_UPDATE_CROPS: "NOMRAL_UPDATE_CROPS",
    NOMRAL_UPDATE_CROPS_SPRAY: "NOMRAL_UPDATE_CROPS_SPRAY",
    NOMRAL_UPDATE_WEATHER_LIST_LOADING: "NOMRAL_UPDATE_WEATHER_LIST_LOADING",
    NOMRAL_UPDATE_TOP_TITLE_LOADING: "NOMRAL_UPDATE_TOP_TITLE_LOADING",
    NOMRAL_UPDATE_SPRAY_LOADING: "NOMRAL_UPDATE_SPRAY_LOADING",
    NOMRAL_UPDATE_PARAM_MODEL: "NOMRAL_UPDATE_PARAM_MODEL",
    NOMRAL_UPDATE_HOURS_DAY_DATA: "NOMRAL_UPDATE_HOURS_DAY_DATA",
    NOMRAL_UPDATE_SERVER_WARNING: "NOMRAL_UPDATE_SERVER_WARNING",
    NORMAL_UPDATE_KEY_TURF_GROWTH_DATA: "NORMAL_UPDATE_KEY_TURF_GROWTH_DATA",
    NORMAL_UPDATE_GROUND_SPRAYING: "NORMAL_UPDATE_GROUND_SPRAYING",
    NORMAL_UPDATE_GROUND_SPRAYING_WITH_NOZZLE: "NORMAL_UPDATE_GROUND_SPRAYING_WITH_NOZZLE",
    NORMAL_UPDATE_DISEASE: "NORMAL_UPDATE_DISEASE",
    NORMAL_ADD_BOOKMARK_SUCCESS: "NORMAL_ADD_BOOKMARK_SUCCESS",
    NORMAL_ADD_BOOKMARK_FAILE: "NORMAL_ADD_BOOKMARK_FAILE",
    NORMAL_UPDATE_NEW_LOCATION_SEARCH_RESULT: "NORMAL_UPDATE_NEW_LOCATION_SEARCH_RESULT",
    NORMAL_SEARCH_TARGET_COORDINATE_RESULT: "NORMAL_SEARCH_TARGET_COORDINATE_RESULT",
    NORMAL_UPDATE_REDIRECT_URL: "NORMAL_UPDATE_REDIRECT_URL",
    NORMAL_UPDATE_HOME_INDEX_LOADING: "NORMAL_UPDATE_HOME_INDEX_LOADING",
    NORMAL_UPDATE_ALL_LOCATIONS: "NORMAL_UPDATE_ALL_LOCATIONS",
    NORMAL_UPDATE_LOCAL_MAP_SETTING: "NORMAL_UPDATE_LOCAL_MAP_SETTING",
    NORMAL_UPDATE_COUNTRY_BOUNDRIES: "NORMAL_UPDATE_COUNTRY_BOUNDRIES",
    NORMAL_UPDATE_AREA_RISK_VALUE: "NORMAL_UPDATE_AREA_RISK_VALUE",
    NORMAL_UPDATE_EXCEL_NAME: "NORMAL_UPDATE_EXCEL_NAME",
    NORMAL_UPDATE_TOKEN: "NORMAL_UPDATE_TOKEN",
    NORMAL_UPDATE_HEATMAP_LOADING: "NORMAL_UPDATE_HEATMAP_LOADING",
    NORMAL_UPDATE_WARNING_MESSAGE: "NORMAL_UPDATE_WARNING_MESSAGE",
    NORMAL_UPDATE_HEATMAP_PARAM: "MORMAL_UPDATE_HEATMAP_PARAM",
    NORMAL_UPDATE_SEARCH_BY_PLACE: "NORMAL_UPDATE_SEARCH_BY_PLACE",
    NORMAL_UPDATE_SEARCH_BY_PLACE_RESULT: "NORMAL_UPDATE_SEARCH_BY_PLACE_RESULT",
    NORMAL_UPDATE_LOCAL_MAP_SETTING_ORIGIN: "NORMAL_UPDATE_LOCAL_MAP_SETTING_ORIGIN",
    NORMAL_UPDATE_REQUEST_AFTER_CHANGE_SETTING: "NORMAL_UPDATE_REQUEST_AFTER_CHANGE_SETTING"
};

export const sagaActionTypes = {
    SAGA_LOCATION_SEARCH: "SAGA_LOCATION_SEARCH",
    SAGA_COORDINATE_SEARCH: "SAGA_COORDINATE_SEARCH",
    SAGA_COORDINATE_PARAM_SEARCH: "SAGA_COORDINATE_PARAM_SEARCH",
    SAGA_COMMON_ADD_USER: "SAGA_COMMON_ADD_USER",
    SAGA_DISEASE_DATA: "SAGA_DISEASE_DATA",
    SAGA_TRANSLATETEXT: "SAGA_TRANSLATETEXT",
    SAGA_GET_BOOKMARK: "SAGA_GET_BOOKMARK",
    SAGA_ADD_BOOKMARK: "SAGA_ADD_BOOKMARK",
    SAGA_GET_USERNAME: "SAGA_GET_USERNAME",
    SAGA_GET_WEATHERFORCAST_DAILY_LIST: "SAGA_GET_WEATHERFORCAST_DAILY_LIST",
    SAGA_GET_LOCAL_HOURLY_WIDGET: "SAGA_GET_LOCAL_HOURLY_WIDGET",
    SAGA_UPDATE_CURRENT_LANGUAGE: "SAGA_UPDATE_CURRENT_LANGUAGE",
    SAGA_GET_CHART_DATA: "SAGA_GET_CHART_DATA",
    SAGA_GET_CROPS: "SAGA_GET_CROPS",
    SAGA_GET_CORPS_SPRAY: "SAGA_GET_CORPS_SPRAY",
    SAGA_GET_AGGREGATED_WEATHER: "SAGA_GET_AGGREGATED_WEATHER",
    SAGA_GET_SERVER_WARNING: "SAGA_GET_SERVER_WARNING",
    SAGA_FIVE_GET_AGGREGATED_WEATHER: "SAGA_FIVE_GET_AGGREGATED_WEATHER",
    SAGA_GET_KEY_TURF_GROWTH_DATA: "SAGA_GET_KEY_TURF_GROWTH_DATA",
    SAGA_GET_FROST_WARNING: "SAGA_GET_FROST_WARNING",
    SAGA_GET_GROUND_SPRAYING: "SAGA_GET_GROUND_SPRAYING",
    SAGA_GET_GROUND_SPRAYING_WITH_NOZZLE: "SAGA_GET_GROUND_SPRAYING_WITH_NOZZLE",
    SAGA_GET_DISEASE: "SAGA_GET_DISEASE",
    SAGA_GET_SIGN_URL: "SAGA_GET_SIGN_URL",
    SAGA_GET_TOKEN: "SAGA_GET_TOKEN",
    SAGA_GET_ALL_LOCATIONS: "SAGA_GET_ALL_LOCATIONS",
    SAGA_GET_HEAT_MAP_SETTING: "SAGA_GET_HEAT_MAP_SETTING",
    SAGA_GET_TEMP_REGION_SETTING: "SAGA_GET_TEMP_REGION_SETTING",
    SAGA_GET_COUNTRY_BOUNDARY: "SAGA_GET_COUNTRY_BOUNDARY",
    SAGA_UPDATE_TEMP_AND_DAYS_SETTING: "SAGA_UPDATE_TEMP_AND_DAYS_SETTING"
};


export const fetchUserInfo = (params = {}) => {
    return {
        type: sagaActionTypes.SAGA_COMMON_ADD_USER,
        payload: {
            params
        }
    };
};

export const fetchUserInfoSuccess = (userInfo: IUserModel) => {
    return {
        type: normalActionTypes.COMMON_ADD_USER,
        payload: userInfo
    };
};

export const updateLocationSearchResult = (locations: ILocationModel[]) => {
    return {
        type: normalActionTypes.NORMAL_UPDATE_LOCATION_SEARCH_RESULT,
        payload: locations
    };
};

export const updateNewLocationSearchResult = (locations: INewLocationModel[]) => {
    return {
        type: normalActionTypes.NORMAL_UPDATE_NEW_LOCATION_SEARCH_RESULT,
        payload: locations
    };
};

export const updateCurrentLocation = (locations: ILocationModel) => {
    return {
        type: normalActionTypes.NORMAL_UPDATE_CURRENT_LOCATION,
        payload: locations
    };
};

export const updateTranslateText = (translateText:any) => {
    return {
        type: normalActionTypes.NORMAL_TRANSLATETEXT,
        payload: translateText
    };
};

export const updateUserName = (userName: string) => {
    return {
        type: normalActionTypes.NORMAL_UPDATE_USERNAME,
        payload: userName
    };
};
export const updateServiceToken = (serviceToken: string) => {
    return {
        type: normalActionTypes.NORMAL_UPDATE_SERVICETOKEN,
        payload: serviceToken
    };
};

export const updateBookmarks = (bookmarks: IBookmarkResModel[]) => {
    return {
        type: normalActionTypes.NOMRAL_UPDATE_BOOKMARK,
        payload: bookmarks
    };
};

export const showLoading = () => {
    return {
        type: normalActionTypes.SHOW_LOADING,
        payload: true
    };
};

export const hideLoading = () => {
    return {
        type: normalActionTypes.HIDE_LOADING,
        payload: false
    };
};

export const updateWeatherForcastdailyList = (data: IWeatherForcastDailyListResModel[]) => {
    return {
        type: normalActionTypes.NORMAL_UPDATE_WEATHERFORCAST_DAILY_LIST,
        payload: data
    };
};


export const updateLocalDailyWidgetWeather = (data: ILocalDailyWidgetWeatherResModel[]) => {
    return {
        type: normalActionTypes.NORMAL_GET_LOCAL_HOURLY_WIDGET,
        payload: data
    };
};

export const updateCurrentLanguage = (data: {[keyText: string]: string}) => {
    return {
        type: normalActionTypes.NORMAL_UPDATE_CURRENT_LANGUAGE,
        payload: data
    };
};


export const updateChartData = (data: { temperature: number[], precip: number[], date: string[] }) => {
    return {
        type: normalActionTypes.NORMAL_UPDATE_CHART_DATA,
        payload: data
    };
};

export const updateCorpsData = (data: {key: string, label: string}[]) => {
    return {
        type: normalActionTypes.NOMRAL_UPDATE_CROPS,
        payload: data
    };
};


export const updateCorpsSprayData = (data: IGetCorpsSprayResModel) => {
    const circleObj: unknown = {};
    for (const item of data.lstWeather) {
        const indexMap: string[] = [];
        const srlMap: unknown = {};
        const colorMap: unknown = {};
        const toolTip: unknown = {};

        for (const weatherData of item.lstHourData) {
            indexMap.push(weatherData.hour);
            srlMap[weatherData.hour] = weatherData.srl;
            toolTip[weatherData.hour] = weatherData.toolTip;
            colorMap[weatherData.hour] = weatherData.backColor;
        }
        const splitArray = item.date.split("/");
        const monthString = splitArray[0];
        const dayString = splitArray[1];

        const newDateArray = [splitArray[2], monthString, dayString];
        const dateFormate = newDateArray.join("/");
        circleObj[dateFormate] = {
            indexMap,
            srlMap,
            colorMap,
            toolTip
        };
    }
    const restrictionLegend = [];
    const translate = {};
    data.lstLegend.forEach((item, index) => {
        if (index === 0) {
            restrictionLegend.push({
                keyText: "title",
                name: item.name
            });
        } else {
            if (item.text.length > 0) {
                restrictionLegend.push({
                    keyText: item.text,
                    name: item.name
                });
            }
        }
        if(item?.text?.length == 0){
            translate[item.key] = item.name;
        }
        // if (index > 8) {
        //     translate[item.key] = item.name;
        // }
    });
    console.log("restrictionLegend:====", restrictionLegend);
    return {
        type: normalActionTypes.NOMRAL_UPDATE_CROPS_SPRAY,
        payload: {
            circleObj, restrictionLegend, translate
        }
    };
};

export const updateCorpsSprayGroundData = (data: IGetCorpsSprayResModel) => {
    const circleObj: unknown = {};
    for (const item of data.lstWeather) {
        const indexMap: string[] = [];
        const srlMap: unknown = {};
        const colorMap: unknown = {};
        const toolTip: unknown = {};
        for (const weatherData of item.lstHourData) {
            indexMap.push(weatherData.hour);
            srlMap[weatherData.hour] = weatherData.srl;
            toolTip[weatherData.hour] = weatherData.toolTip;
            colorMap[weatherData.hour] = weatherData.backColor;
        }
        const splitArray = item.date.split("/");
        const monthString = splitArray[0];
        const dayString = splitArray[1];

        const newDateArray = [splitArray[2], monthString, dayString];
        const dateFormate = newDateArray.join("/");
        circleObj[dateFormate] = {
            indexMap,
            srlMap,
            colorMap,
            toolTip
        };
    }
    const restrictionLegend = {};
    data.lstLegend.forEach((item) => {
        if (item.key === "ResColLegRecomm") {
            restrictionLegend[item.key] = item.name;
        }
        if (item.key === "ResColLegRestrict") {
            restrictionLegend[item.key] = item.name;
        }
        if (item.key === "ResColLegNotRec") {
            restrictionLegend[item.key] = item.name;
        }
    });

    return {
        type: normalActionTypes.NORMAL_UPDATE_GROUND_SPRAYING,
        payload: {
            circleObj,
            restrictionLegend
        }
    };
};

export const updateCorpsSprayGroundWithNozzleData = (data: IGetCorpsSprayResModel) => {

    const circleObj: unknown = {};
    for (const item of data.lstWeather) {
        const indexMap: string[] = [];
        const srlMap: unknown = {};
        const colorMap: unknown = {};
        const toolTip: unknown = {};
        for (const weatherData of item.lstHourData) {
            indexMap.push(weatherData.hour);
            srlMap[weatherData.hour] = weatherData.srl;
            colorMap[weatherData.hour] = weatherData.backColor;
            toolTip[weatherData.hour] = weatherData.toolTip;

        }
        const splitArray = item.date.split("/");
        const monthString = splitArray[0];
        const dayString = splitArray[1];

        const newDateArray = [splitArray[2], monthString, dayString];
        const dateFormate = newDateArray.join("/");
        circleObj[dateFormate] = {
            indexMap,
            srlMap,
            colorMap,
            toolTip
        };
    }
    const restrictionLegend = [];
    data.lstLegend.forEach((item, index) => {
        if (index === 0) {
            restrictionLegend.push({
                keyText: "title",
                name: item.name
            });
        } else {
            if (item.text.length > 0) {
                restrictionLegend.push({
                    keyText: item.text,
                    name: item.name
                });
            }
        }
    });
    return {
        type: normalActionTypes.NORMAL_UPDATE_GROUND_SPRAYING_WITH_NOZZLE,
        payload: {
            circleObj, restrictionLegend
        }
    };
};

export const updateParamModel = (data) => {
    return {
        type: normalActionTypes.NOMRAL_UPDATE_PARAM_MODEL,
        payload: data
    };
};

export const updateHoursOrDayData = (data: { weather24HoursData: {
    hours24Data: IHour24DataModel,
    defaultShowIcon: { [keyText: string]: boolean}
},
weather8HoursData: {
    hours8Data: IHour8DataModel,
    defaultShowIcon: { [keyText: string]: boolean}
},
weatherDayData: {
    hourDay: IDailyDataModel,
    defaultShowIcon: { [keyText: string]: boolean}
} }) => {
    return {
        type: normalActionTypes.NOMRAL_UPDATE_HOURS_DAY_DATA,
        payload: data
    };
};

export const updateFiveDayHoursOrDayData = (data: {
    fiveWeather24HoursData: {
        hours24Data: IHour24DataModel,
        defaultShowIcon: { [keyText: string]: boolean}
    },
    fiveWeather8HoursData: {
        hours8Data: IHour8DataModel,
        defaultShowIcon: { [keyText: string]: boolean}
    },
    fiveWeatherDayData: {
        hourDay: IDailyDataModel,
        defaultShowIcon: { [keyText: string]: boolean}
} }) => {
    return {
        type: normalActionTypes.NOMRAL_UPDATE_SERVER_WARNING,
        payload: data
    };
};

export const updateFiveDayWeatherServerWarningCircleData = (data: IGetCorpsSprayResModel) => {

    const circleObj: unknown = {};
    for (const item of data.lstWeather) {
        const indexMap: string[] = [];
        const srlMap: unknown = {};
        const colorMap: unknown = {};
        const toolTip: unknown = {};

        for (const weatherData of item.lstHourData) {
            indexMap.push(weatherData.hour);
            srlMap[weatherData.hour] = weatherData.srl;
            toolTip[weatherData.hour] = weatherData.toolTip;
            colorMap[weatherData.hour] = weatherData.backColor;
        }
        const splitArray = item.date.split("/");
        const monthString = splitArray[0];
        const dayString = splitArray[1];

        const newDateArray = [splitArray[2], monthString, dayString];
        const dateFormate = newDateArray.join("/");
        circleObj[dateFormate] = {
            indexMap,
            srlMap,
            colorMap,
            toolTip
        };
    }
    const restrictionLegend = [];
    data.lstLegend.forEach((item, index) => {
        if (index === 0) {
            restrictionLegend.push({
                keyText: "title",
                name: item.name
            });
        } else {
            if (item.text.length > 0) {
                restrictionLegend.push({
                    keyText: item.text,
                    name: item.name
                });
            }
        }
    });
    return {
        type: normalActionTypes.NOMRAL_UPDATE_CROPS_SPRAY,
        payload: {
            circleObj, restrictionLegend
        }
    };
};

export const updateKeyTurfData = (data) => {
    return {
        type: normalActionTypes.NORMAL_UPDATE_KEY_TURF_GROWTH_DATA,
        payload: data
    };
};

export const updateDiseaseData = (data) => {
    return {
        type: normalActionTypes.NORMAL_UPDATE_DISEASE,
        payload: data
    };
};

export const showAddBookmarkSuccessFunc = (data) => {
    return {
        type: normalActionTypes.NORMAL_ADD_BOOKMARK_SUCCESS,
        payload: data
    };
};

export const showAddBookmarkFailedFunc = (data: boolean) => {
    return {
        type: normalActionTypes.NORMAL_ADD_BOOKMARK_FAILE,
        payload: data
    };
};

export const searchTargetCoordinate = (data: boolean) => {
    return {
        type: normalActionTypes.NORMAL_SEARCH_TARGET_COORDINATE_RESULT,
        payload: data
    };
};
export const searchByPalce = (data: boolean) => {
    return {
        type: normalActionTypes.NORMAL_UPDATE_SEARCH_BY_PLACE,
        payload: data
    };
};
export const searchByPalceResult = (data: boolean) => {
    return {
        type: normalActionTypes.NORMAL_UPDATE_SEARCH_BY_PLACE_RESULT,
        payload: data
    };
};


