export const zoomConfig = [
    {
        countryCode: "AU",
        conditions: [
            { zoomRange: [0,4], width: 10, height: 10},
            { zoomRange: [5,5], width: 15, height: 15},
            { zoomRange: [6,6], width: 20, height: 20},
            { zoomRange: [7,7], width: 25, height: 25},
            { zoomRange: [8,8], width: 30, height: 30},
            { zoomRange: [9,9], width: 35, height: 35},
            { zoomRange: [10,Infinity], width: 45, height: 45},
        ]
    },
    {
        countryCode: "US",
        conditions: [
            { zoomRange: [0,4], width: 10, height: 10},
            { zoomRange: [5,5], width: 15, height: 15},
            { zoomRange: [6,6], width: 20, height: 20},
            { zoomRange: [7,7], width: 25, height: 25},
            { zoomRange: [8,8], width: 30, height: 30},
            { zoomRange: [9,9], width: 35, height: 35},
            { zoomRange: [10,Infinity], width: 45, height: 45},
        ]
    },
    {
        countryCode: "RU",
        conditions: [
            { zoomRange: [0,4], width: 10, height: 10},
            { zoomRange: [5,5], width: 15, height: 15},
            { zoomRange: [6,6], width: 20, height: 20},
            { zoomRange: [7,7], width: 25, height: 25},
            { zoomRange: [8,8], width: 30, height: 30},
            { zoomRange: [9,9], width: 35, height: 35},
            { zoomRange: [10,Infinity], width: 45, height: 45},
        ]
    },
    {
        countryCode: "PK",
        conditions: [
            { zoomRange: [0,6], width: 20, height: 20},
            { zoomRange: [7,7], width: 25, height: 25},
            { zoomRange: [8,8], width: 30, height: 30},
            { zoomRange: [9,9], width: 35, height: 35},
            { zoomRange: [10,Infinity], width: 45, height: 45},
        ]
    },
    {
        countryCode: "AT",
        conditions: [
            { zoomRange: [0,4], width: 10, height: 10},
            { zoomRange: [5,5], width: 15, height: 15},
            { zoomRange: [6,6], width: 20, height: 20},
            { zoomRange: [7,7], width: 25, height: 25},
            { zoomRange: [8,8], width: 30, height: 30},
            { zoomRange: [9,9], width: 35, height: 35},
            { zoomRange: [10,Infinity], width: 45, height: 45},
        ]
    },
    {
        countryCode: "BW",
        conditions: [
            { zoomRange: [0,6], width: 20, height: 20},
            { zoomRange: [7,7], width: 25, height: 25},
            { zoomRange: [8,8], width: 30, height: 30},
            { zoomRange: [9,9], width: 35, height: 35},
            { zoomRange: [10,Infinity], width: 45, height: 45},
        ]
    },
    {
        countryCode: "CA",
        conditions: [
            { zoomRange: [0,4], width: 10, height: 10},
            { zoomRange: [5,5], width: 15, height: 15},
            { zoomRange: [6,6], width: 20, height: 20},
            { zoomRange: [7,7], width: 25, height: 25},
            { zoomRange: [8,8], width: 30, height: 30},
            { zoomRange: [9,9], width: 35, height: 35},
            { zoomRange: [10,Infinity], width: 45, height: 45},
        ]
    },
    {
        countryCode: "DE",
        conditions: [
            { zoomRange: [0,4], width: 10, height: 10},
            { zoomRange: [5,5], width: 15, height: 15},
            { zoomRange: [6,6], width: 20, height: 20},
            { zoomRange: [7,7], width: 25, height: 25},
            { zoomRange: [8,8], width: 30, height: 30},
            { zoomRange: [9,9], width: 35, height: 35},
            { zoomRange: [10,Infinity], width: 45, height: 45},
        ]
    },
    {
        countryCode: "LS",
        conditions: [
            { zoomRange: [0,4], width: 10, height: 10},
            { zoomRange: [5,5], width: 15, height: 15},
            { zoomRange: [6,6], width: 20, height: 20},
            { zoomRange: [7,7], width: 25, height: 25},
            { zoomRange: [8,8], width: 30, height: 30},
            { zoomRange: [9,9], width: 35, height: 35},
            { zoomRange: [10,Infinity], width: 45, height: 45},
        ]
    },
    {
        countryCode: "MA",
        conditions: [
            { zoomRange: [0,4], width: 10, height: 10},
            { zoomRange: [5,5], width: 15, height: 15},
            { zoomRange: [6,6], width: 20, height: 20},
            { zoomRange: [7,7], width: 25, height: 25},
            { zoomRange: [8,8], width: 30, height: 30},
            { zoomRange: [9,9], width: 35, height: 35},
            { zoomRange: [10,Infinity], width: 45, height: 45},
        ]
    },
    {
        countryCode: "MZ",
        conditions: [
            { zoomRange: [0,4], width: 10, height: 10},
            { zoomRange: [5,5], width: 15, height: 15},
            { zoomRange: [6,6], width: 20, height: 20},
            { zoomRange: [7,7], width: 25, height: 25},
            { zoomRange: [8,8], width: 30, height: 30},
            { zoomRange: [9,9], width: 35, height: 35},
            { zoomRange: [10,Infinity], width: 45, height: 45},
        ]
    },
    {
        countryCode: "NA",
        conditions: [
            { zoomRange: [0,4], width: 10, height: 10},
            { zoomRange: [5,5], width: 15, height: 15},
            { zoomRange: [6,6], width: 20, height: 20},
            { zoomRange: [7,7], width: 25, height: 25},
            { zoomRange: [8,8], width: 30, height: 30},
            { zoomRange: [9,9], width: 35, height: 35},
            { zoomRange: [10,Infinity], width: 45, height: 45},
        ]
    },
    {
        countryCode: "NZ",
        conditions: [
            { zoomRange: [0,4], width: 10, height: 10},
            { zoomRange: [5,5], width: 15, height: 15},
            { zoomRange: [6,6], width: 20, height: 20},
            { zoomRange: [7,7], width: 25, height: 25},
            { zoomRange: [8,8], width: 30, height: 30},
            { zoomRange: [9,9], width: 35, height: 35},
            { zoomRange: [10,Infinity], width: 45, height: 45},
        ]
    },
    {
        countryCode: "ZA",
        conditions: [
            { zoomRange: [0,4], width: 10, height: 10},
            { zoomRange: [5,5], width: 15, height: 15},
            { zoomRange: [6,6], width: 20, height: 20},
            { zoomRange: [7,7], width: 25, height: 25},
            { zoomRange: [8,8], width: 30, height: 30},
            { zoomRange: [9,9], width: 35, height: 35},
            { zoomRange: [10,Infinity], width: 45, height: 45},
        ]
    },
    {
        countryCode: "ES",
        conditions: [
            { zoomRange: [0,4], width: 10, height: 10},
            { zoomRange: [5,5], width: 15, height: 15},
            { zoomRange: [6,6], width: 20, height: 20},
            { zoomRange: [7,7], width: 25, height: 25},
            { zoomRange: [8,8], width: 30, height: 30},
            { zoomRange: [9,9], width: 35, height: 35},
            { zoomRange: [10,Infinity], width: 45, height: 45},
        ]
    },
    {
        countryCode: "SZ",
        conditions: [
            { zoomRange: [0,4], width: 10, height: 10},
            { zoomRange: [5,5], width: 15, height: 15},
            { zoomRange: [6,6], width: 20, height: 20},
            { zoomRange: [7,7], width: 25, height: 25},
            { zoomRange: [8,8], width: 30, height: 30},
            { zoomRange: [9,9], width: 35, height: 35},
            { zoomRange: [10,Infinity], width: 45, height: 45},
        ]
    },
    {
        countryCode: "CH",
        conditions: [
            { zoomRange: [0,4], width: 10, height: 10},
            { zoomRange: [5,5], width: 15, height: 15},
            { zoomRange: [6,6], width: 20, height: 20},
            { zoomRange: [7,7], width: 25, height: 25},
            { zoomRange: [8,8], width: 30, height: 30},
            { zoomRange: [9,9], width: 35, height: 35},
            { zoomRange: [10,Infinity], width: 45, height: 45},
        ]
    },
    {
        countryCode: "UK",
        conditions: [
            { zoomRange: [0,4], width: 10, height: 10},
            { zoomRange: [5,5], width: 15, height: 15},
            { zoomRange: [6,6], width: 20, height: 20},
            { zoomRange: [7,7], width: 25, height: 25},
            { zoomRange: [8,8], width: 30, height: 30},
            { zoomRange: [9,9], width: 35, height: 35},
            { zoomRange: [10,Infinity], width: 45, height: 45},
        ]
    },
    {
        countryCode: "ZW",
        conditions: [
            { zoomRange: [0,4], width: 10, height: 10},
            { zoomRange: [5,5], width: 15, height: 15},
            { zoomRange: [6,6], width: 20, height: 20},
            { zoomRange: [7,7], width: 25, height: 25},
            { zoomRange: [8,8], width: 30, height: 30},
            { zoomRange: [9,9], width: 35, height: 35},
            { zoomRange: [10,Infinity], width: 45, height: 45},
        ]
    },
    {
        countryCode: "PH",
        conditions: [
            { zoomRange: [0,4], width: 10, height: 10},
            { zoomRange: [5,5], width: 15, height: 15},
            { zoomRange: [6,6], width: 20, height: 20},
            { zoomRange: [7,7], width: 25, height: 25},
            { zoomRange: [8,8], width: 30, height: 30},
            { zoomRange: [9,9], width: 35, height: 35},
            { zoomRange: [10,Infinity], width: 45, height: 45},
        ]
    },
    {
        countryCode: "BE",
        conditions: [
            { zoomRange: [0,4], width: 10, height: 10},
            { zoomRange: [5,5], width: 15, height: 15},
            { zoomRange: [6,6], width: 20, height: 20},
            { zoomRange: [7,7], width: 25, height: 25},
            { zoomRange: [8,8], width: 30, height: 30},
            { zoomRange: [9,9], width: 35, height: 35},
            { zoomRange: [10,Infinity], width: 45, height: 45},
        ]
    },
    {
        countryCode: "NL",
        conditions: [
            { zoomRange: [0,4], width: 10, height: 10},
            { zoomRange: [5,5], width: 15, height: 15},
            { zoomRange: [6,6], width: 20, height: 20},
            { zoomRange: [7,7], width: 25, height: 25},
            { zoomRange: [8,8], width: 30, height: 30},
            { zoomRange: [9,9], width: 35, height: 35},
            { zoomRange: [10,Infinity], width: 45, height: 45},
        ]
    }
];
