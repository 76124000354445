import { Button, Input, Modal, Spin } from "antd";
import React, { useEffect, useState } from "react";
import "./index.less";
import TextArea from "antd/es/input/TextArea";
import { postSentEmail } from "@/util/http/HistWeather";
import { IPostSentEmailResModel } from "@/util/http/HistWeather/responseType";
import IF from "../IF";

interface IProp {
    open: boolean;
    welcomeStr: string;
    toStr: string;
    subjectStr: string;
    messageStr: string;
    idRequiredStr: string;
    subjectRequiredStr: string;
    sendEmailStr: string;
    cancelStr: string;
    idValidStr: string;
    emailFailedStr: string;
    id: string;
    imageBase64?: string;
    defaultTitle: string;
    defaultSubject: string;
    defaultMailContent: string;
    sentEmailSuccessStr: string;
    emailFrom?: string;
    attachFileName?: string;
    cancelCallback?: ()=>void
}

const sentEmailModal = (prop:IProp)=>{

    const {
        welcomeStr,
        toStr,
        subjectRequiredStr,
        subjectStr,
        messageStr,
        idRequiredStr,
        idValidStr,
        sendEmailStr,
        cancelStr,
        open,
        id,
        emailFailedStr,
        imageBase64,
        defaultTitle,
        defaultSubject,
        defaultMailContent,
        sentEmailSuccessStr,
        emailFrom,
        attachFileName,
        cancelCallback
    } = prop;

    const [sentEmailResult,setSentEmailResult] = useState<IPostSentEmailResModel>(null);
    // email content
    const [emailTo,setEmailTo] = useState("");
    const [emailSubject,setEmailSubject] = useState("");
    // const [emailMessage,setEamilMessage] = useState("Hej,\n\nBifogad är den historiska vädergrönkartan. \n\nBästa hälsningar\nCE Hub Team");
    const [emailMessage,setEamilMessage] = useState("");
    const removeStr = "data:image/png;base64,";
    const [showSpinLoadding,setShowSpinLoadding] = useState(false);
    const [resultTitle,setResultTitle] = useState("");
    // show check result
    const [emailFailedCheck,setEmailFailedCheck] = useState(false);
    const [idCheck,setIdCheck] = useState({
        noData: false,
        errorData: false
    });
    const [subjectCheck,setSubjectCheck] = useState(false);

    const changeSubjectAndContent = ()=>{
        if(defaultMailContent){
            setEmailSubject(defaultSubject);
            const tmpConent = defaultMailContent?.replace(/\\n/g,"\n");
            setEamilMessage(tmpConent);
        }
    };

    useEffect(()=>{
        changeSubjectAndContent();
    },[defaultTitle,defaultMailContent,defaultSubject]);

    // sent email function
    const beforeSendEmailCheck = ()=>{
        // check subject value
        if(!emailSubject){
            setSubjectCheck(true);
            return;
        }
        if(!emailTo){
            const tmpIdCheck = {...idCheck};
            tmpIdCheck.noData = true;
            tmpIdCheck.errorData = false;
            setIdCheck(tmpIdCheck);
            return;
        }
        if(emailTo && !checkEmailValudValid(emailTo)){
            const tmpIdCheck = {...idCheck};
            tmpIdCheck.noData = false;
            tmpIdCheck.errorData = true;
            setIdCheck(tmpIdCheck);
            return;
        }
        if(!subjectCheck && !idCheck.errorData && !idCheck.noData){
            postSendEmailFunc();
        }
        else{
            setEmailFailedCheck(true);
        }
    };
    const postSendEmailFunc = async()=>{
        try{
            setShowSpinLoadding(true);
            const res = await postSentEmail({
                mailImageArray: imageBase64?.replace(removeStr,""),
                // mailImageArray: null,
                mailMessage: emailMessage,
                mailSubject: emailSubject,
                mailTo: emailTo,
                mailFrom: emailFrom,
                attachFileName: attachFileName
            });
            setSentEmailResult(res.data);
            setResultTitle(res.data?.title);
            setEmailFailedCheck(false);
            setShowSpinLoadding(false);
            // show successfully dialog
            setSentStatusModal(true);
        }
        catch(error){
            console.log("have error",error);
            setShowSpinLoadding(false);
            setEmailFailedCheck(true);
            // show successfully dialog
            setSentStatusModal(true);
        }
    };

    const checkEmailValudValid = (value:string)=>{
        const reg = /^[a-zA-Z0-9]+([-_.][A-Za-zd]+)*@([a-zA-Z0-9]+[-.])+[A-Za-zd]{2,5}$/;
        return reg.test(value);
    };

    const [sentStatusModal,setSentStatusModal] = useState(false);

    useEffect(()=>{
        if(open){
            setEmailTo("");
            setSentEmailResult(null);
            setEmailFailedCheck(false);
            changeSubjectAndContent();
            setIdCheck({
                noData: false,
                errorData: false
            });
        }
    },[open]);

    return(
        <div className="sentEmail" id={id}>
            <Modal
                open={open}
                footer={false}
                width={"600px"}
                // getContainer = {()=> document.getElementById('warn-modal') as HTMLElement}
                getContainer = {()=> document.getElementById(id) as HTMLElement}
                // title = {welcomeStr || "Welcome!"}
                title = {defaultTitle || "Welcome!"}
                onCancel={cancelCallback}
                zIndex={100}
            >
                <div className="email-title"
                >
                    {/* <Spin spinning={showSpinLoadding}>
                        <IF show={!emailFailedCheck && !!sentEmailResult?.title}>
                            <h3 style={{color:"red"}}>{sentEmailSuccessStr}</h3>
                        </IF>
                        <IF show={emailFailedCheck}>
                            <h3 style={{color:"red"}}>{emailFailedStr || "Sorry! Email failed."}</h3>
                        </IF>
                    </Spin> */}
                </div>

                <Spin spinning={showSpinLoadding}>
                    <div className="email-content-container">
                        <div>
                            <h2>{toStr || "To:"}</h2>
                        </div>
                        <div>
                            <Input
                                value={emailTo}
                                onChange={(e)=>{
                                // const reg=/^\s*|\s*$/g;
                                    const reg=/^\s*/g;
                                    //使用正则表达式,\s为空格，^\s为开头的空格，*代表0个或多个，\s*$为结尾的零个或多个空格
                                    const tmpValue = e.target.value.replace(reg,"");
                                    console.log("eamil input value ",tmpValue);
                                    const tmpIdCheck = {...idCheck};
                                    if(!tmpValue){
                                        tmpIdCheck.noData = true;
                                        tmpIdCheck.errorData = false;
                                        setIdCheck(tmpIdCheck);
                                        setEmailTo(tmpValue);
                                    }
                                    else if(!checkEmailValudValid(tmpValue)){
                                        tmpIdCheck.noData = false;
                                        tmpIdCheck.errorData = true;
                                        setIdCheck(tmpIdCheck);
                                        setEmailTo(tmpValue);
                                    }
                                    else{
                                        tmpIdCheck.noData = false;
                                        tmpIdCheck.errorData = false;
                                        setIdCheck(tmpIdCheck);
                                        setEmailTo(tmpValue);
                                    }
                                }}
                            />
                        </div>
                        {/* showld check id value */}
                        <IF show={idCheck.noData}>
                            <div style={{color:"red"}}>
                                {idRequiredStr || "Email id is required."}
                            </div>
                        </IF>
                        <IF show={idCheck.errorData}>
                            <div style={{color:"red"}}>
                                {idValidStr || "Please enter a valid email id."}
                            </div>
                        </IF>
                        <div>
                            <h2>{subjectStr || "Subject:"}</h2>
                        </div>
                        {/* should check subject value */}
                        <div>
                            <Input
                                value={emailSubject}
                                onChange={(e)=>{
                                    console.log("ddd",e.target.value);
                                    setEmailSubject(e.target.value);
                                }}
                                onBlur={(e)=>{
                                    console.log("ddd blue",e.target.value);
                                    if(!(e.target.value)){
                                        setSubjectCheck(true);
                                    }
                                    else{
                                        setSubjectCheck(false);
                                    }
                                }}
                            />
                        </div>
                        <IF show={subjectCheck}>
                            <div style={{color:"red"}}>
                                {subjectRequiredStr || "Subject is required."}
                            </div>
                        </IF>
                        <div>
                            <h2>{messageStr || "Message:"}</h2>
                        </div>
                        <div className="textarea-container">
                            <TextArea
                                rows={10}
                                value={emailMessage}
                                onChange={(e)=>{
                                    setEamilMessage(e.target.value);
                                }}
                            // dangerouslySetInnerHTML={{__html: emailMessage}}
                            />
                        </div>
                    </div>
                    <div className="email-button-group">
                        <Button
                            onClick={()=>{
                                beforeSendEmailCheck();
                            }}
                        >{sendEmailStr || "Send Email"}</Button>
                        <Button
                            onClick={() => {
                                console.log("close");
                                cancelCallback();
                            }}
                        >
                            {cancelStr || "Cancel"}
                        </Button>
                    </div>
                </Spin>

            </Modal>
            <Modal
                open = {sentStatusModal}
                onCancel={cancelCallback}
                footer={false}
                width={"600px"}
                // getContainer = {()=> document.getElementById('warn-modal') as HTMLElement}
                getContainer = {()=> document.getElementById(id) as HTMLElement}
                zIndex={100}
                closeIcon = {null}
                className="sent-status-modal-container"
            >
                <div className="sent-status-content">
                    {/* <span>{ emailFailedCheck ? emailFailedStr : sentEmailResult?.title}</span> */}
                    <span>{ emailFailedCheck ? emailFailedStr : sentEmailSuccessStr}</span>
                </div>
                <div className="email-button-group">
                    <Button
                        onClick={()=>{
                            if(emailFailedCheck){
                                // close this status modal
                                setSentStatusModal(false);
                            }
                            else{
                                setSentStatusModal(false);
                                cancelCallback();
                            }
                        }}
                    >{"OK"}</Button>
                </div>
            </Modal>
        </div>
    );
};

export default sentEmailModal;