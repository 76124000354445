import { Row, Col, Popconfirm, message,Button, Modal, Space } from "antd";

import { useDispatch, useSelector } from "react-redux";
import React, { FunctionComponent, useState, useRef, useCallback, LegacyRef, useEffect } from "react";
import {
    DeleteQueryIcon,
    ExecuteQueryIcon,
    ShareQueryIcon,
    WarningIcon,
    CloseIcon
}
    from "@/resource/IconComponents";
import {
    deleteQueryForSimulation
} from "@/util/http";
import "./index.less";

const QueryBookMarkComponent: FunctionComponent<{ query: any, bookmarkKey: string,
    setBookmarkKey: any, refreshQueries: any, executeQuery: any }>
  = ({ query = undefined, bookmarkKey = "", setBookmarkKey, refreshQueries, executeQuery }) => {

      const [sharePopVisible, setSharePopVisible] = useState<boolean>(false);
      const [deletePopVisible, setDeletePopVisible] = useState<boolean>(false);
      const linkEle: LegacyRef<HTMLInputElement> = useRef(null);

      const dispatch = useDispatch();

      useEffect(() => {
          setDeletePopVisible(false);
          setSharePopVisible(false);
      }, []);
      useEffect(() => {
          if ((query.queryId).toString() !== bookmarkKey) {
              setDeletePopVisible(false);
              setSharePopVisible(false);
          }
      }, [bookmarkKey]);

      const popDeleteWindow = useCallback((queryId) => {
          setBookmarkKey((queryId).toString());
          if (sharePopVisible) { setSharePopVisible(false); }
          setDeletePopVisible(true);
      }, [sharePopVisible]);
      const executeQueryClick = useCallback(async (queryId) => {
          setBookmarkKey((queryId).toString());
          if (deletePopVisible) { setDeletePopVisible(false); }
          if (sharePopVisible) { setSharePopVisible(false); }
          executeQuery(queryId);
      }, [deletePopVisible, sharePopVisible]);
      const popShareWindow = useCallback((queryId) => {
          setBookmarkKey((queryId).toString());
          if (deletePopVisible) { setDeletePopVisible(false); }
          setSharePopVisible(true);
      }, [deletePopVisible]);
      const deleteConfirm = useCallback(async (queryId) => {
          const flag = await deleteQueryForSimulation({queryId:queryId});
          if (flag) {
              message.success("Delete Succeed");
              setDeletePopVisible(false);
              refreshQueries();
          }
      }, []);
      const deleteCancel = useCallback(() => { setDeletePopVisible(false); }, []);
      const copyLink = useCallback(() => { linkEle.current?.select(); document.execCommand("copy", true); setSharePopVisible(false); }, []);
      const sendLink = useCallback(() => { setSharePopVisible(false); }, []);
      const closeSharePopWindow = useCallback(() => { setSharePopVisible(false); }, []);
      const handleVisibleChange = (e) => { console.log(e); };
      return (
          <div className={`div-query ${bookmarkKey == query.queryId.toString() ? "div-query-active" : ""}`}>
              <div className="div-query-content">
                  <div className="div-query-title">{query.queryName}</div>
                  <div className="div-query-value">{query.lastDate}</div>
                  <div style={{ marginTop: "16px" }}>
                      <Row>
                          <Col span={10}><div className="div-query-key">Location</div></Col>
                          <Col span={14}><div className="div-query-key">Soil texture</div></Col>
                      </Row>
                      <Row>
                          <Col span={10}><div className="div-query-value">{query.location}</div></Col>
                          <Col span={14}><div className="div-query-value">{query.soilTexture}</div></Col>
                      </Row>
                  </div>
                  <div style={{ marginTop: "16px" }}>
                      <Row>
                          <Col span={10}><div className="div-query-key">Rooting depth</div></Col>
                          <Col span={14}><div className="div-query-key">Water availability at planting</div></Col>
                      </Row>
                      <Row>
                          <Col span={10}><div className="div-query-value">{query.soilDepth.join(", ")} cm</div></Col>
                          <Col span={14}><div className="div-query-value">{query.faw} % FC</div></Col>
                      </Row>
                  </div>
              </div>
              <div className="div-query-operation">
                  {/*Warning pop-up */}
                  <Popconfirm overlayInnerStyle={{ width: "423px", height: "164px", backgroundColor: "#FFFFFF", boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.15)", borderRadius: "16px" }}
                      icon={
                          <div style={{ paddingTop: "8px" }}>
                              <div style={{ display: "inline", paddingLeft: "16px" }}><WarningIcon /></div>
                              <div style={{ display: "inline", fontWeight: 600, fontSize: "16px", lineHeight: "24px", color: "#14151C", paddingLeft: "10px" }}>Warning</div>
                          </div>
                      }
                      placement="rightBottom"
                      title={
                          <div style={{ marginLeft: "-60px" }}>You are about to remove query</div>
                      }
                      open={deletePopVisible}
                      onOpenChange={handleVisibleChange}
                      onConfirm={() => deleteConfirm(query.queryId)}
                      okButtonProps={{
                          style: {
                              width: "116px", height: "40px", background: "#CF3537",color:"#FFFFFF",
                              border: "1px solid #CF3537", borderRadius: "24px", marginTop: "12px"
                          }
                      }}
                      okText="Delete query"
                      onCancel={deleteCancel}
                      cancelButtonProps={{
                          style: {
                              width: "76px", height: "40px", background: "#FFFFFF",
                              border: "1px solid #C2C7D0", borderRadius: "24px", marginTop: "12px"
                          }
                      }}
                      cancelText="Cancel">
                      <span className="span-delete" onClick={() => { popDeleteWindow(query.queryId); }}><DeleteQueryIcon /></span>
                  </Popconfirm>
                  {/*Share link pop-up */}
                  <span className="span-execute" onClick={() => executeQueryClick(query.queryId)}><ExecuteQueryIcon /></span>
                  <Popconfirm overlayInnerStyle={{ width: "452px", height: "294px", backgroundColor: "#FFFFFF", boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.15)", borderRadius: "16px" }}
                      icon={
                          <div style={{ paddingTop: "8px" }}>
                              <div style={{ display: "inline" }}>
                                  <span style={{ width: "77px", height: "24px", fontWeight: 600,
                                      fontSize: "16px", lineHeight: "24px", color: "#14151C", paddingLeft: "10px",display: "block" }}>Share link</span>
                                  <span style={{ float: "right", marginTop:"-19px",marginRight:"-320px" }} onClick={closeSharePopWindow}><CloseIcon /></span>
                              </div>
                          </div>
                      }
                      placement="rightBottom"
                      title={
                          <div style={{ marginLeft: "-77px"}}>
                              <div style={{ paddingTop: "24px", fontWeight: 400, fontSize: "14px", lineHeight: "20px", color: "#14151C" }}>Send the link by email or via any messenger.</div>
                              <div style={{ paddingTop: "24px", fontWeight: 400, fontSize: "14px", lineHeight: "16px", color: "#14151C" }}>Query link</div>
                              <div><input type="text" style={{
                                  boxSizing: "border-box", width: "403px", height: "40px", color: "#0071CD",
                                  background: "#F3F4F6", border: "1px solid #C2C7D0", borderRadius: "4px"
                              }} ref={linkEle} value={`${window.location.protocol}//${window.location.host}/${process.env.SIMULATION_ROOT}/shared/${query.queryGuid}`} readOnly={true} />
                              </div>
                          </div>
                      }
                      open={sharePopVisible}
                      onOpenChange={handleVisibleChange}
                      onConfirm={sendLink}
                      okButtonProps={{
                          style: {
                              width: "121px", height: "40px", background: "#59B948", color: "#FFFFFF",
                              border: "1px solid #59B948", borderRadius: "24px", marginTop: "24px", marginRight: "8px"
                          }
                      }}
                      okText={<a href={`mailto:?body=${linkEle.current?.value}`}>Send by email</a>}
                      onCancel={copyLink}
                      cancelButtonProps={{
                          style: {
                              width: "76px", height: "40px", background: "#FFFFFF", color: "#14151C",
                              border: "0px solid #FFFFFF", marginTop: "24px"
                          }
                      }}
                      cancelText="Copy link">
                      <span className="span-share" onClick={() => { popShareWindow(query.queryId); }}><ShareQueryIcon /></span>
                  </Popconfirm>
              </div>
          </div>
      );
  };

export default QueryBookMarkComponent;
