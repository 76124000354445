const dayjs = require("dayjs");

function getDayOfWeek(dateString:string,simple?:boolean) {
    // 使用 dayjs 解析时间字符串
    const date = dayjs(dateString);

    // 获取星期几的索引（0 代表星期日，1 代表星期一，以此类推）
    const dayIndex = date.day();

    // 创建一个星期几的数组
    const weekDays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const simpleWeekDays = ["Sun.","Mon.","Tue.","Wed.","Thu.","Fri.","Sat."];

    // 返回对应的星期几
    if(simple){
        return simpleWeekDays[dayIndex];
    }
    else{
        return weekDays[dayIndex];
    }
}

function converyDateFormat(dateString:string, format: string){
    return dayjs(dateString).format(format);
}

export {
    getDayOfWeek,
    converyDateFormat
};