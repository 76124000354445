interface ILanguage {
    txt_SelectLocation: string
    txt_DisplayMore: string
    txt_SelectSprayWindow: string
    txt_OrderForecast: string
    ResDistanceFrom: string
    Search_Location: string
    cancel: string
    Add_bookmark: string
    My_Bookmarks: string
    Input_your_location: string
    "Forecast for":string
    No_place_found: string
    no_weather_data: string
    "Bookmark Added successfully": string
    txt_DisplayLess: string
    txt_AccurateWeather: string
    txt_BookmarkReminder: string
    txt_WeatherConditions: string
    txt_SelectWeatherConditions: string
    txt_Max: string
    txt_Min: string
    txt_DetailedWeather: string
    txt_Select: string
    txt_OptimalSprayHourFor: string
    txt_Hourly: string
    txt_8Hourly: string
    txt_Daily: string
    txt_ShowDetailView: string
    txt_Hour: string
    txt_Hours: string
    txt_Temperature: string
    txt_Wind: string
    txt_OtherPrams: string
    txt_Precipitaion: string
    txt_AirTemperature: string
    Apply: string
    StartDate: string
    EndDate: string
    Duration: string
    Aggregation: string
    Altitude: string
    Series: string
    ClearSelection: string
    Excel: string
    lblMasl1: string
    lblMasl2: string
    NoDataTooltip: string
    tipForSelectOptions: string
    historicStartDateTip: string
    historicTemperaturTip: string
    historicPrecipitationsTip: string
    Cumulative_temperature: string
    Cumulative_precipitations: string
    rs_hour: string
    txt_SelectLocation_Historic: string
    Forecast_for_historic: string
    gc_tooltip: string
    gc_SelectDisease: string
    gc_Weekday: string
    gc_EndDateEmpty: string
    gc_SelectLocationHistoricDisease: string
    gc_WidgetRisk: string
    gc_ExportImage: string
    gc_refreshchart: string
    lbl_weatherstations: string
    lbl_displaycharterrormsg: string
    lbl_seegraphicchart: string
    Ge_Evapotranspiration: string
    gc_WeeklyView: string
    gc_HourlyView: string
    gc_Hours: string
    gc_KeyTurfGrowthTemperatures: string
    gc_DiseaseForecastsKeyToColor: string
    gc_KeyTurfGrowthTemperaturesKeyToColor: string
    gc_SlightGrowth: string
    gc_ModerateGrowth: string
    gc_StrongGrowth: string
    gc_EmailPage: string
    gc_EmailHeader: string
    gc_EmailTo: string
    gc_EmailSubject: string
    gc_EmailMessageArea: string
    gc_SendEmailButton: string
    gc_EmailIsSending: string
    gc_EmailSendFailed: string
    gc_EmailValid: string
    gc_EmailRequired: string
    gc_EmailSubjectRequired: string
    gc_EmailSendSuccess: string
    close: string
    HistChartAuthError: string
    gc_SelectRegion: string
    txt_weatherSeverityWarning: string
    txt_keyTurfGrowTemp: string
    txt_diseaseRiskIndex: string
    txt_groundSpraying: string
    txt_today: string
    txt_fiveDayWeather: string
    txt_groundSprayDriftReduNozzle: string
    txt_frostwarning: string
    txt_addBookMarkFailed: string
    txt_nowcast: string
    txt_of: string
    txt_LocationNameLngLat: string
    txt_keyPlantTurfGrowTemp: string
    txt_OrnamentalsWeather: string
    txt_Warning:string
    txt_Cancel:string
    txt_Ok:string
    txt_SeeForecastProviderData:string
    txt_ForecastProviderData:string
    txt_MeteoblueForecast:string
    txt_ForecastProviderContentP1:string
    txt_ForecastProviderContentP2:string
    txt_ForecastProviderContentP3:string
}


const enDefault: ILanguage = {
    "txt_SelectLocation": "Select your location to see the accurate weather",
    "txt_DisplayMore": "Display more",
    "txt_SelectSprayWindow": "Select spray window",
    "txt_OrderForecast": "Order forecast",
    "ResDistanceFrom": ",Elevation} m asl, Dist}km Dir} from <strong>CityName}</strong>",
    "Search_Location": "Search Location",
    "cancel": "Cancel",
    "Add_bookmark": "Add bookmark",
    "My_Bookmarks": "My Bookmarks",
    "Input_your_location": "Input your location",
    "Forecast for": "Forecast for",
    "No_place_found": "No place found,please enter a valid place name or longtitude,latitude",
    "no_weather_data": "Currently no weather data available for this location",
    "Bookmark Added successfully": "Bookmark added successfully",
    "txt_DisplayLess": "Display Less",
    "txt_AccurateWeather": "Accurate Weather",
    "txt_BookmarkReminder": "Select from your saved bookmarks",
    "txt_WeatherConditions": "Weather Conditions",
    "txt_SelectWeatherConditions": "Select a day to review  detailed weather conditions",
    "txt_Max": "Max",
    "txt_Min": "Min",
    "txt_DetailedWeather": "Detailed Weather Conditions",
    "txt_Select": "Select",
    "txt_OptimalSprayHourFor": "Optimal Spraying Hours for",
    "txt_Hourly": "Hourly",
    "txt_8Hourly": "8Hourly",
    "txt_Daily": "Daily",
    "txt_ShowDetailView": "Show detailed view",
    "txt_Hour": "Hour",
    "txt_Hours": "hours",
    "txt_Temperature": "Temperature",
    "txt_Wind": "Wind",
    "txt_OtherPrams": "Other Parameters",
    "txt_Precipitaion": "Precipitaion",
    "txt_AirTemperature": "Air Temperature",
    "txt_Warning":"Avertissement",
    "txt_Cancel":"Annulation",
    "txt_Ok":"D'accord",
    "Apply": "Apply To Chart",
    "StartDate": "Start Date",
    "EndDate": "End Date",
    "Duration": "Duration",
    "Aggregation": "Aggregation",
    "Altitude": "Location elevation",
    "Series": "Weather variables",
    "ClearSelection": "Clear Selection",
    "Excel": "Excel",
    "lblMasl1": "meter",
    "lblMasl2": "ft asl",
    "NoDataTooltip": "No Data}",
    "tipForSelectOptions": "Please select at least one series.",
    "historicStartDateTip": "Start Date cannot be empty",
    "historicTemperaturTip": "Air temperature (°C)",
    "historicPrecipitationsTip": "Precipitation (mm)",
    "Cumulative_temperature": "Cumulative temperature",
    "Cumulative_precipitations": "Cumulative precipitations",
    "rs_hour": "Hour",
    "txt_SelectLocation_Historic": "Select your location to see the accurate weather",
    "Forecast_for_historic": "Forecast for",
    "gc_tooltip": "This is a kind of toggle input, please enter only one value.",
    "gc_SelectDisease": "Select Disease",
    "gc_Weekday": "Weekday",
    "gc_EndDateEmpty": "End Date cannot be empty",
    "gc_SelectLocationHistoricDisease": "Select your location to see the historical disease",
    "gc_WidgetRisk": "Risk or Potential High! (hover or click for more details)",
    "gc_ExportImage": "Export Image",
    "gc_refreshchart": "Refresh Chart",
    "lbl_weatherstations": "Other weather stations",
    "lbl_displaycharterrormsg": "You must be logged in to display the graphic chart",
    "lbl_seegraphicchart": "See graphic chart",
    "Ge_Evapotranspiration": "Evapotranspiration (mm)",
    "gc_WeeklyView": "Weekly view",
    "gc_HourlyView": "Hourly view",
    "gc_Hours": "Hours",
    "gc_KeyTurfGrowthTemperatures": "Key Turf Growth Temperatures",
    "gc_DiseaseForecastsKeyToColor": "Diseases Forecasts - Key to color",
    "gc_KeyTurfGrowthTemperaturesKeyToColor": "gc_KeyTurfGrowthTemperaturesKeyToColor}",
    "gc_SlightGrowth": "gc_SlightGrowth}",
    "gc_ModerateGrowth": "gc_ModerateGrowth}",
    "gc_StrongGrowth": "gc_StrongGrowth}",
    "gc_EmailPage": "Email this page",
    "gc_EmailHeader": "Welcome!",
    "gc_EmailTo": "To:",
    "gc_EmailSubject": "Subject:",
    "gc_EmailMessageArea": "Message:",
    "gc_SendEmailButton": "Send Email",
    "gc_EmailIsSending": "Sending..",
    "gc_EmailSendFailed": "Sorry! Email failed.",
    "gc_EmailValid": "Please enter a valid email id.",
    "gc_EmailRequired": "Email id is required.",
    "gc_EmailSubjectRequired": "Subject is required.",
    "gc_EmailSendSuccess": "Email sent successfully!",
    "close": "Close",
    "HistChartAuthError": "HistChartAuthError}",
    "gc_SelectRegion": "Select Region",
    "txt_weatherSeverityWarning": "Weather Severity Warning",
    "txt_keyTurfGrowTemp": "Key Turf Growth Temperatures",
    "txt_diseaseRiskIndex": "Disease Risk Index",
    "txt_groundSpraying": "Ground Spraying",
    "txt_today": "Today",
    "txt_fiveDayWeather": "Five Day Weather",
    "txt_groundSprayDriftReduNozzle": "Ground Spraying with Drift Reducing Nozzle",
    "txt_frostwarning": "Frost Warning",
    "txt_addBookMarkFailed": "The bookmark already exists",
    "txt_nowcast": "Nowcast",
    "txt_of": "of",
    "txt_LocationNameLngLat": "Location name or long,lat",
    "txt_keyPlantTurfGrowTemp": "Key Plant Growth Temperatures",
    "txt_OrnamentalsWeather": "Ornamentals Weather",
    "txt_SeeForecastProviderData":"See forecast provider data details",
    "txt_ForecastProviderData":"Forecast provider data details",
    "txt_MeteoblueForecast":"Meteoblue Forecast",
    "txt_ForecastProviderContentP1":"The Forecast is provided by meteoblue using their meteoblue learning multimodel (mLM) & numerical forecast models.",
    "txt_ForecastProviderContentP2":"The meteoblue learning multimodel (mLM) is a new "
    +"technique of post-processing the output from numerical weather forecast models using actual weather measurements.",
    "txt_ForecastProviderContentP3":"The mLM reads actual weather measurement data and selects the best simulation model for making a forecast. The mLM is "
    +"currently validated and implemented for air temperature, dewpoint temperature and wind speed. For other variables, they use multi models.",
};

export type {
    ILanguage
};

export default enDefault;