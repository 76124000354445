import {IReportsModel,IFieldsModel,ISettingModel, ICurrentGetWeatherDataModel, IGetGeoNamesLocationDataReqModel,IUserDataModel, IMapCenterModel} from "@/util/http/Quantis/responseType";


export const normalActionTypes = {
    NORMAL_REPORTS_DATA: "NORMAL_REPORTS_DATA",
    NORMAL_UPDATE_SHOWSUCCESSMESSAGE: "NORMAL_UPDATE_SHOWSUCCESSMESSAGE",
    NORMAL_UPDATE_SHOWERRORMESSAGE: "NORMAL_UPDATE_SHOWERRORMESSAGE",
    NORMAL_UPDATE_MAINPAGEFIRSTGETFIELDFLAG: "NORMAL_UPDATE_MAINPAGEFIRSTGETFIELDFLAG",
    NORMAL_UPDATE_ALLFIELDS: "NORMAL_UPDATE_ALLFIELDS",
    NORMAL_UPDATE_GETALLFIELDSSTATUS: "NORMAL_UPDATE_GETALLFIELDSSTATUS",
    NORMAL_UPDATE_CROPFROMFIELDS: "NORMAL_UPDATE_CROPFROMFIELDS",
    NORMAL_UPDATE_ALLBOOKMARKFIELDS: "NORMAL_UPDATE_ALLBOOKMARKFIELDS",
    NORMAL_UPDATE_ALLCROP: "NORMAL_UPDATE_ALLCROP",
    NORMAL_UPDATE_REDUX_SETTING: "NORMAL_UPDATE_REDUX_SETTING",
    NORMAL_UPDATE_REPORTADDOREDITFLAG: "NORMAL_UPDATE_REPORTADDOREDITFLAG",
    NORMAL_UPDATE_CURRENTSELECTEDREPORT: "NORMAL_UPDATE_CURRENTSELECTEDREPORT",
    NORMAL_UPDATE_CURRENTGETWEATHERDATA: "NORMAL_UPDATE_CURRENTGETWEATHERDATA",
    NORMAL_UPDATE_PLACENAME: "NORMAL_UPDATE_PLACENAME",
    NORMAL_UPDATE_GEOLOCATIONLISTDATA: "NORMAL_UPDATE_GEOLOCATIONLISTDATA",
    NORMAL_UPDATE_USERLIST: "NORMAL_UPDATE_USERLIST",
    NORMAL_UPDATE_GETWEATHERAFTERADDORUPDATESETTING: "NORMAL_UPDATE_GETWEATHERAFTERADDORUPDATESETTING",
    NORMAL_UPDATE_CURRENTMAPCENTER: "NORMAL_UPDATE_CURRENTMAPCENTER",
    NORMAL_UPDATE_ADDNEWFIELDSTATUS: "NORMAL_UPDATE_ADDNEWFIELDSTATUS",
    NORMAL_UPDATE_DELETEFIELDSTATUS: "NORMAL_UPDATE_DELETEFIELDSTATUS",
    NORMAL_UPDATE_UPDATEFIELDSTATUS: "NORMAL_UPDATE_UPDATEFIELDSTATUS",
    NORMAL_UPDATE_UNAUTHORIZEDFLAG: "NORMAL_UPDATE_UNAUTHORIZEDFLAG",
    NORMAL_UPDATE_MAININITDATACOUNT: "NORMAL_UPDATE_MAININITDATACOUNT",

};

export const sagaActionTypes = {
    SAGA_REPORTS_DATA: "SAGA_REPORTS_DATA",
    SAGA_DELETE_REPORT: "SAGA_DELETE_REPORT",
    SAGA_GET_FIELDS: "SAGA_GET_FIELDS",
    SAGA_ADD_REPORT: "SAGA_ADD_REPORT",
    SAGA_EDIT_REPORT: "SAGA_EDIT_REPORT",
    SAGA_UPLOAD_IMAGE: "SAGA_UPLOAD_IMAGE",
    SAGA_GET_ARTICLE: "SAGA_GET_ARTICLE",
    SAGA_GET_ALLCROP: "SAGA_GET_ALLCROP",
    SAGA_GET_SETTING: "SAGA_GET_SETTING",
    SAGA_GET_WEATHERDATA: "SAGA_GET_WEATHERDATA",
    SAGA_GET_GETGEONAMESLOCATIONDATA: "SAGA_GET_GETGEONAMESLOCATIONDATA",
    SAGA_GET_USERLISTBYEMAIL: "SAGA_GET_USERLISTBYEMAIL",
    SAGA_UPDATE_SETTING: "SAGA_UPDATE_SETTING",
    SAGA_ADD_NEW_SETTING: "SAGA_ADD_NEW_SETTING",
    SAGA_GET_PLACANAMEBYLATANDLONG: "SAGA_GET_PLACANAMEBYLATANDLONG",
    SAGA_ADD_NEWFIELDS: "SAGA_ADD_NEWFIELDS",
    SAGA_DELETE_CURRENTSELECTEDFIELD: "SAGA_DELETE_CURRENTSELECTEDFIELD",
    SAGA_UPDATE_CURRENTSELECTEDFIELD: "SAGA_UPDATE_CURRENTSELECTEDFIELD",
};

// =================== report  ========================================
// for all reports data
export const updateReportsData = (reportsData?:IReportsModel[]) => {
    return {
        type: normalActionTypes.NORMAL_REPORTS_DATA,
        payload: reportsData
    };
};
export const updateReportAddOrEditFlag = (addOrEditFlag?:string) => {
    return {
        type: normalActionTypes.NORMAL_REPORTS_DATA,
        payload: addOrEditFlag
    };
};
export const updateCurrentSelectedReport = (currentReport?:IReportsModel) => {
    return {
        type: normalActionTypes.NORMAL_REPORTS_DATA,
        payload: currentReport
    };
};
// =================== report  ========================================


// =================== main  ========================================
export const updateAllFields = (reportsData?:IFieldsModel[]) => {
    return {
        type: normalActionTypes.NORMAL_UPDATE_ALLFIELDS,
        payload: reportsData
    };
};
export const updateGetAllFieldsStatus = (status:boolean) => {
    return {
        type: normalActionTypes.NORMAL_UPDATE_GETALLFIELDSSTATUS,
        payload: status
    };
};
export const updateAllCrop = (reportsData?:string[]) => {
    return {
        type: normalActionTypes.NORMAL_UPDATE_ALLCROP,
        payload: reportsData
    };
};
export const updateAllBookMarkFields = (reportsData?:IFieldsModel[]) => {
    return {
        type: normalActionTypes.NORMAL_UPDATE_ALLBOOKMARKFIELDS,
        payload: reportsData
    };
};
export const updateCropFromFields = (reportsData?:string[]) => {
    return {
        type: normalActionTypes.NORMAL_UPDATE_CROPFROMFIELDS,
        payload: reportsData
    };
};
export const updateMainPageFirstGetFieldFlag = (flag: boolean) => {
    return {
        type: normalActionTypes.NORMAL_UPDATE_MAINPAGEFIRSTGETFIELDFLAG,
        payload: flag
    };
};
export const updateReduxSetting = (setting?: ISettingModel) => {
    return {
        type: normalActionTypes.NORMAL_UPDATE_REDUX_SETTING,
        payload: setting
    };
};
export const updateUserList = (userList?: IUserDataModel[]) => {
    return {
        type: normalActionTypes.NORMAL_UPDATE_USERLIST,
        payload: userList
    };
};
export const updateCurrnetGetWeatherData = (currentWeatherData?: ICurrentGetWeatherDataModel) => {
    return {
        type: normalActionTypes.NORMAL_UPDATE_CURRENTGETWEATHERDATA,
        payload: currentWeatherData
    };
};
export const updatePlaceName = (placeName?: string) => {
    return {
        type: normalActionTypes.NORMAL_UPDATE_PLACENAME,
        payload: placeName
    };
};
export const updateGeoLocationListData = (listData?: IGetGeoNamesLocationDataReqModel[]) => {
    return {
        type: normalActionTypes.NORMAL_UPDATE_GEOLOCATIONLISTDATA,
        payload: listData
    };
};
export const updateGetWeatherAfterAddOrUpdateSetting = (flag:boolean) => {
    return {
        type: normalActionTypes.NORMAL_UPDATE_GETWEATHERAFTERADDORUPDATESETTING,
        payload: flag
    };
};
export const updateCurrentMapCenter = (centerInfo:IMapCenterModel) => {
    return {
        type: normalActionTypes.NORMAL_UPDATE_CURRENTMAPCENTER,
        payload: centerInfo
    };
};
export const updateAddNewFieldStatus = (status:string) => {
    return {
        type: normalActionTypes.NORMAL_UPDATE_ADDNEWFIELDSTATUS,
        payload: status
    };
};
export const updateDeleteFieldStatus = (status:string) => {
    return {
        type: normalActionTypes.NORMAL_UPDATE_DELETEFIELDSTATUS,
        payload: status
    };
};
export const updateUpdateFieldStatus = (status:string) => {
    return {
        type: normalActionTypes.NORMAL_UPDATE_UPDATEFIELDSTATUS,
        payload: status
    };
};
// =================== main  ========================================

// =================== common function ================================
export const updateShowSuccessMessage = (message?: string) => {
    return {
        type: normalActionTypes.NORMAL_UPDATE_SHOWSUCCESSMESSAGE,
        payload: message
    };
};
export const updateShowErrorMessage = (message?:string) => {
    return {
        type: normalActionTypes.NORMAL_UPDATE_SHOWERRORMESSAGE,
        payload: message
    };
};
export const updateUnAuthorizedFlag = (flag:boolean) => {
    return {
        type: normalActionTypes.NORMAL_UPDATE_UNAUTHORIZEDFLAG,
        payload: flag
    };
};
// =================== common function ================================

