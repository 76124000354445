import { getAltitude, getSeries } from "@/util/http/HistWeather";
import { IGetSeriesResModel } from "@/util/http/HistWeather/responseType";
import { useEffect,useState } from "react";
import {debounce} from "lodash";
import { useDispatch } from "react-redux";
import { normalActionTypes } from "@/redux/actions/diseaseAction";

interface IProp{
    longitude: number;
    latitude: number;
}

const useAltitude = (props:IProp)=>{
    const {
        longitude,
        latitude
    } = props;

    const dispatch = useDispatch();

    const [altitude,setAltitude] = useState<number>(5);
    const [altitudeInit,setAltitudeInit] = useState<number>(5);

    const getSeriesFunc = async()=>{
        try{
            dispatch({ type: normalActionTypes.NORMAL_SHOWLODDING, payload: true});
            const res = await getAltitude({
                latitude: latitude,
                longitude: longitude
            });
            setAltitude(res.data);
            dispatch({ type: normalActionTypes.NORMAL_SHOWLODDING, payload: false});
        }
        catch(error){
            dispatch({ type: normalActionTypes.NORMAL_SHOWLODDING, payload: false});
            console.log("========= useAggretation  getDropdownTranslata error======",error);
        }
    };

    useEffect(()=>{
        const myDebounceFunc = debounce(getSeriesFunc,500);
        myDebounceFunc();
        return()=>{
            myDebounceFunc.cancel();
        };
    },[longitude,latitude]);

    return {
        altitude
    };
};

export default useAltitude;