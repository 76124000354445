/* eslint-disable max-len */
import React, { useEffect, useState } from "react";
import {Button, Modal} from "antd";
import icon from "@/resource/images/png/icon-left.png";
import $footmodal1 from "@/resource/js/footmodal2";
import "./index.less";

interface IProp{
    style?:any;
    buttonName?: string;
    closeName?: string;
    forecastProvider?: string;
    meteoblueForecast?: string;
    modalContentOne?: string;
    modalContentTwo?: string;
    modalContentThree?: string;
}

const forcastProviderButton = (prop: IProp)=>{

    const {
        style,
        buttonName,
        closeName,
        forecastProvider,
        meteoblueForecast,
        modalContentOne,
        modalContentTwo,
        modalContentThree
    } = prop;

    const [showModal,setShowModal] = useState(false);

    return(
        <div className="forcastProviderButton_container"
            id = "forcastProviderButton_container"
        >
            <div
                style={{ display:"flex", justifyContent:"center" ,textAlign: "center", marginTop: "20px",paddingBottom:"20px" }}>
                <Button
                    id="basic_btn"
                    type="primary"
                    className="login-form-button"
                    style={style}
                    onClick={()=>{
                        setShowModal(true);
                    }}
                >
                    <img src={icon} />
                    <span className="spanStyle">&nbsp;&nbsp;{buttonName || "See forecast provider data details"}</span>
                </Button>
            </div>

            {/* set Modal Content */}
            <Modal
                className="forecastProviderButton_modal_container"
                closeIcon = {false}
                footer = {null}
                maskClosable = {true}
                onCancel={()=>{
                    setShowModal(false);
                }}
                open = {showModal}
                getContainer = {()=> document.getElementById("forcastProviderButton_container") as HTMLElement}
            >
                <div className="forecast-modal-titel">
                    <span>{forecastProvider || "Forecast provider data details"}</span>
                </div>
                <div className="forecast-modal-content-container">
                    <div className="forecast-modal-content-container-title">
                        <p>{meteoblueForecast || "Meteoblue Forecast"}</p>
                    </div>

                    <div className="forecast-modal-content-container-content">
                        <p>{modalContentOne || "The Forecast is provided by meteoblue using their meteoblue learning multimodel (mLM) & numerical forecast models."}</p>
                        <br/>
                        <br/>
                        <p>{modalContentTwo || "The meteoblue learning multimodel (mLM) is a new technique of post-processing the output from numerical weather forecast models using actual weather measurements."}</p>
                        <br/>
                        <br/>
                        <p>{modalContentThree || "The mLM reads actual weather measurement data and selects the best simulation model for making a forecast. The mLM is currently validated and implemented for air temperature, dewpoint temperature and wind speed. For other variables, they use multi models."}</p>
                    </div>

                    <div className="forecast-modal-content-container-close">
                        <button
                            className=""
                            onClick={()=>{
                                setShowModal(false);
                            }}
                        >{closeName || "Close"}</button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};
export default forcastProviderButton;