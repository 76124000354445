import React, { useEffect } from "react";
import "./index.less";
import store from "store";
import { Col, Row, Input, Button, Select, Layout, Table, Space, Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { useLocation, useNavigate } from "react-router-dom";
import { RootState } from "@/redux/reducer/store";
import { normalActionTypes, sagaActionTypes } from "@/redux/actions/quantisAction";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { IReportsModel } from "@/util/http/Quantis/responseType";
const { Header, Content, Footer } = Layout;

const { Search } = Input;
const { Option } = Select;
const {confirm} = Modal;
// TODO:整个页面统一颜色还没有完成，需要使用统一颜色变量来实现
const Report = ()=>{
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const urlLocation = useLocation();

    const {
        reports
    } = useSelector((state: RootState) => state.quantisReducer);

    const report:any = "";

    const onSearch = (value?:string) => {
        dispatch({
            type: sagaActionTypes.SAGA_REPORTS_DATA,
            payload:{
                email: value
            }
        });
    };

    // TODO:将string 以 html 显示的还没有完成
    function escape2Html(str) {
        const arrEntities = { "lt": "<", "gt": ">", "nbsp": " ", "amp": "&", "quot": "\"", "#39": "\"" };
        if (str != null) return str.replace(/&(lt|gt|nbsp|amp|quot|#39);/ig, function (all, t) {
            return arrEntities[t];
        });
    }
    // const addReport = (param = {}) => {
    //     store.set("report", param);
    //     console.log("test param: ",param);
    //     setTimeout(()=>{
    //         // history.push({ pathname: "/visualization/cropstress-reportEdit" });
    //         navigate("/visualization/cropstress-reportEdit");
    //     });
    // };

    // TODO: 需要验证这个函数有没有成功，成功了的需要去修改 report-edit 页面中所有涉及到 add or edit 的判断
    // edit and add report function, should go to report edit page, value should store in redux
    const editOrAddReport = (flag: string, reportData?: IReportsModel) =>{
        /**
         * go to report edit page, add store current select report to the redux
         * go to report edit page, add store empty value to the redux
         */
        //
        dispatch({
            type: normalActionTypes.NORMAL_UPDATE_CURRENTSELECTEDREPORT,
            payload: flag === "Edit" ? reportData : null
        });
        dispatch({
            type: normalActionTypes.NORMAL_UPDATE_REPORTADDOREDITFLAG,
            payload: flag
        });
        // navigate("/visualization/cropstress-reportEdit");
        navigate("/cropstress/reportEdit");
        // setTimeout(()=>{
        //     // history.push({ pathname: "/visualization/cropstress-reportEdit" });
        //     navigate("/visualization/cropstress-reportEdit");
        // });
    };

    function showConfirm(record:IReportsModel) {
        confirm({
            title: "Do you Want to delete this?",
            icon: <ExclamationCircleOutlined />,
            content: record.reportName,
            className:"report-delete-confirm-modal",
            // getContainer = {()=> document.getElementById("setting-modal-container") as HTMLElement}
            onOk() {
                dispatch({
                    type: sagaActionTypes.SAGA_DELETE_REPORT,
                    payload:{
                        reportID: Number(record.reportID)
                    }
                });
            },
            onCancel() {
                // console.log('Cancel');
            },
        });
    }

    useEffect(()=>{
        // when url change, should get value
        dispatch({
            type: sagaActionTypes.SAGA_REPORTS_DATA,
            payload:{
                email: null
            }
        });
    },[urlLocation]);

    const columns = [

        {
            title: "Name",
            dataIndex: "reportName",
            key: "reportID",
        },
        {
            title: "Crop",
            dataIndex: "crop",
            key: "reportID",
        },
        {
            title: "Text",
            dataIndex: "reminder",
            key: "reportID",
            class:"report-reminder",
            render: text => {
                return <div dangerouslySetInnerHTML={{ __html: escape2Html(text) }} />;
            }
        },
        {
            title: "Is active",
            dataIndex: "isActive",
            key: "reportID",
        },
        {
            title: "Send options",
            // dataIndex: "untilDate",
            key: "reportID",
            render: (text,record:IReportsModel) => {
                return dayjs(record.fromDate).format("YYYY-MM-DD") + " | " + dayjs(record.untilDate).format("YYYY-MM-DD");
            }
        },
        {
            title: "Management",
            // key: 'reportID',
            render: (text, record:IReportsModel) => {
                return <Space size="middle">
                    <a onClick={e =>{
                        //  addReport(text);
                        editOrAddReport("Edit",record);
                    }}
                    >Edit</a>
                    <a onClick={e => showConfirm(record)}>Delete</a>
                </Space>;
            },
        },
    ];
    return(
        // <div className={styles.ghost}>
        <div className={"report-container ghost"}>
            <Layout className="layout">
                <Content style={{ padding: "0 2%",backgroundColor:"#fff" }}>
                    <Row gutter={[20,20]} style={{marginTop:20}}>
                        <Col span={24} style={{display:"flex",alignItems:"center"}}>
                        Search report by email:
                        </Col>
                        <Col span={12} style={{display:"flex",alignItems:"center"}}>
                            <Search
                                placeholder="input search text"
                                allowClear
                                enterButton="Search"
                                style={{maxWidth:300}}
                                onSearch={onSearch}
                                className="report-search-button"
                            />
                        </Col>
                        <Col span={12} style={{textAlign:"right"}}>
                            <Button type='primary'
                                className="add-report-button"
                                onClick={e=>{
                                // addReport({});
                                    editOrAddReport("Add",null);
                                }}>Add Report</Button>
                        </Col>
                    </Row>
                    <Row style={{ overflowX: "auto" }}>
                        <Col span={24}>
                            <Table className="report-table-list" columns={columns} dataSource={reports} rowKey={column=>column.reportID}/>
                        </Col>
                    </Row>

                </Content>
            </Layout>
        </div>
    );
};

export default Report;