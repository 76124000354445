import { all, call, fork } from "redux-saga/effects";
import accurateSaga from "./accurateSaga";
import diseaseSaga from "./diseaseSaga";
import simulationSaga from "./simulationSaga";
import quantisSaga from "./quantisSaga";

export default function *rootSaga() {
    yield all([
        fork(accurateSaga),
        fork(diseaseSaga),
        fork(simulationSaga),
        fork(quantisSaga)
    ]);
}

