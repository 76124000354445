// import echarts from "echarts/types/dist/echarts";
import { IGetHistoricDiseaseCharInfoResModel } from "@/util/http/HistWeather/responseType";
import dayjs from "dayjs";
import * as echarts from "echarts";
import { opacity } from "html2canvas/dist/types/css/property-descriptors/opacity";
import React, { useEffect, useRef, useState } from "react";

interface IProp{
    name: string;
    echartData?: IGetHistoricDiseaseCharInfoResModel;
    colorArray: string[];
    idArray: string[];
    width?: number;
    echartSeries?: string[];
    countrycode?: string;
    echartTitle?: string;
    chartbgcolor?: string
}

const CountryDateFormat = {
    "AE": "DD/MM/YYYY",
    "AU": "DD/MM/YYYY",
    "BY": "DD.MM.YY",
    "CH": "MM/DD/YYYY",
    "DE": "DD.MM.YYYY",
    "NZ": "DD-MM-YYYY",
    "US": "MM/DD/YYYY",
    "ES": "DD/MM/YYYY",
    "FR": "DD/MM/YYYY",
    "GB": "DD/MM/YYYY",
    "IL": "DD/MM/YYYY",
    "JP": "YYYY/MM/DD",
    "KR": "YYYY-MM-DD",
    "PL": "DD.MM.YYYY",
    "PT": "DD-MM-YYYY",
    "SE": "YYYY-MM-DD",
    "SI": "DD.MM.YYYY",
    "TR": "DD.MM.YYYY",
    "ZA": "DD/MM/YYYY",
    "NO": "DD/MM/YYYY",
    "FI": "DD/MM/YYYY",
    "DK": "DD/MM/YYYY",
};

const EchartRender = (prop:IProp)=>{

    const {
        name,
        echartData,
        colorArray,
        idArray,
        width,
        echartSeries,
        countrycode,
        echartTitle,
        chartbgcolor
    } = prop;

    console.log("histdisease echart idArray",idArray);
    console.log("histdisease echart echartData",echartData);
    console.log("histdisease echart echartSeries",echartSeries);
    console.log("lanxue change width echart",width);
    console.log("lanxue change width chartbgcolor",chartbgcolor);

    const chartRef = useRef<HTMLDivElement>(null);
    // const [containerHeight,setContainerHeight] = useState(450);
    const [containerHeight,setContainerHeight] = useState(600);


    const setTemplateOption = ()=>{
        const data = [0, 0.5,1,1.5,2,2.5,3,3.5,4]; // series中的值
        const options = {
            grid: {
                show: true,
                containLabel: true,
                left: 50,
                top: 40,
                width: width ? width -20 -50-10: 800 -50,
                height: 350
            },
            xAxis: {
                data: [
                    dayjs().format("DD/MM/YYYY"),
                    dayjs().add(2,"day").format("DD/MM/YYYY"),
                    dayjs().add(4,"day").format("DD/MM/YYYY"),
                    dayjs().add(6,"day").format("DD/MM/YYYY"),
                    dayjs().add(8,"day").format("DD/MM/YYYY"),
                    dayjs().add(10,"day").format("DD/MM/YYYY"),
                    dayjs().add(12,"day").format("DD/MM/YYYY"),
                    dayjs().add(14,"day").format("DD/MM/YYYY"),
                    dayjs().add(16,"day").format("DD/MM/YYYY"),
                    dayjs().add(18,"day").format("DD/MM/YYYY"),
                    dayjs().add(20,"day").format("DD/MM/YYYY"),
                    dayjs().add(22,"day").format("DD/MM/YYYY"),
                    dayjs().add(24,"day").format("DD/MM/YYYY"),
                    dayjs().add(26,"day").format("DD/MM/YYYY"),
                    dayjs().add(28,"day").format("DD/MM/YYYY"),
                    dayjs().add(30,"day").format("DD/MM/YYYY"),
                    dayjs().add(32,"day").format("DD/MM/YYYY"),
                ],
                type: "category",
                splitNumber: 5,
                axisTick: {
                    show: false,
                },
                splitLine: {
                    show: true,
                    interval: 0,
                    lineStyle: {
                        type: "solid",
                        color: "#A3A9B9"
                    }
                }
            },
            yAxis: {
                type: "value",
                // name: name,
                nameLocation: "center",
                nameGap: 40,
                //splitNumber: 9,
                nameTextStyle: {
                    color: "#14151C",
                    fontSize: 12,
                    fontfamily: "Noto-Sans-Regular"
                },
                axisLabel: {
                },
                axisLine: {
                    show: true, // 显示轴线
                    lineStyle: {
                        type: "solid",
                        color: "#A3A9B9"
                    }
                },
                axisTick: {
                    show: false, // 隐藏刻度线
                    interval: 0
                },
                data: data, // 刻度线对应的值
                splitLine: {
                    show: true,
                    //interval: 0.5,
                    lineStyle: {
                        type: "dashed",
                        color: "#A3A9B9"
                    }
                }
            },
            tooltip: {
                trigger: "item",
                backgroundColor: "white"
            },
            textStyle: {
                fontFamily: "Noto-Sans-Regular",
                fontSize: 12,
                fontWeight: 400
            },
        };
        // chart.setOption(options);
        return options;
    };

    // set echart container and grid height
    const getHeight = ():{gridHeight:number,gridTop:number} =>{
        let gridHeight = 350;
        let gridTop = 40;
        const rowCount = idArray.length / 3;
        if(rowCount <= 1){
            gridTop = 40;
            gridHeight = 350;
        }else if(rowCount > 1 && rowCount <= 2){
            gridTop = 60;
            gridHeight = 330;
        }else if(rowCount > 2 && rowCount <= 3){
            gridTop = 80;
            gridHeight = 310;
        }
        console.log("getHeight idArray",idArray);
        console.log("getHeight rowCount",rowCount);
        return({
            gridHeight,
            gridTop
        });
    };

    // set xAxis data array
    const getXAisData = ():string[] =>{
        const tmpData = [];
        const interval = Math.floor(echartData?.data?.length / 15);
        const formatStr = CountryDateFormat[countrycode] || "DD/MM/YYYY";
        console.log("lanxue formatStr",formatStr);
        echartData?.data?.forEach((item)=>{
            tmpData.push(dayjs(item?.date).format(formatStr));
        });
        return tmpData;
    };

    // set left width
    const getLeftWidth = (isYAxisWidth?:boolean):number=>{
        let tmpWidth = 25;
        if(isYAxisWidth){
            if(width <= 1023) tmpWidth = 15;
            else tmpWidth = 40;
        }
        else{
            if(width <= 1023) tmpWidth = 25;
            else tmpWidth = 50;
        }
        return tmpWidth;
    };

    const changeOption = ()=>{
        const {gridTop,gridHeight} = getHeight();

        const yData = [0,1,2,3,4]; // series中的值
        const xData = getXAisData();
        const options = {
            color: colorArray,
            grid: {
                show: true,
                containLabel: true,
                // left: width <= 320 ? 25 : 50,
                left: getLeftWidth(),
                // top: gridTop,
                top: 10,
                width: width ? width -20 -50-10: 800 -50,
                // height: gridHeight
                height: 350
            },
            xAxis: {
                data: xData,
                type: "category",
                //splitNumber: 15,
                axisTick: {
                    show: false,
                },
                splitLine: {
                    show: true,
                    //interval: 0,
                    lineStyle: {
                        type: "solid",
                        color: "#A3A9B9"
                    }
                },
            },
            yAxis: {
                type: "value",
                name: name,
                nameLocation: "center",
                // nameGap: 40,
                // nameGap: width <= 320 ? 15 : 40,
                nameGap: getLeftWidth(true),
                //splitNumber: 9,
                interval: 1,
                nameTextStyle: {
                    color: "#14151C",
                    fontSize: 12,
                    fontfamily: "Noto-Sans-Regular"
                },
                axisLabel: {
                },
                axisLine: {
                    show: true, // 显示轴线
                    intervale: 1,
                    lineStyle: {
                        type: "solid",
                        color: "#A3A9B9"
                    }
                },
                axisTick: {
                    show: false, // 隐藏刻度线
                    // interval: 1
                },
                data: yData, // 刻度线对应的值
                splitLine: {
                    show: true,
                    interval: 1,
                    lineStyle: {
                        type: "dashed",
                        color: "#A3A9B9"
                    }
                }
            },
            series: (()=>{
                const tmp = [];
                idArray?.forEach((item,index)=>{
                    const tmpName = echartSeries[index]?.replace("\n","");
                    tmp.push({
                        name: tmpName,
                        type: "line",
                        smooth: true,
                        symbol: "none",
                        data: getFilterData(item),
                        markArea: chartbgcolor === "true" ? {
                            silent: true, // 设置为 true 以防止点击或悬停时触发其他事件
                            itemStyle: {
                                color: "rgba(0,0,0,0.1)" // 设置透明度以模拟背景色效果
                            },
                            data: [
                                // 0-1 area's color is green
                                [{yAxis: 0}, {yAxis: 1, itemStyle: {color: "RGB(123, 204, 116, 0.4)",opacity: "0.4"}}],
                                // 1-2 area's color is yellow
                                [{yAxis: 1}, {yAxis: 2, itemStyle: {color: "rgb(255,245,157,0.3)",opacity:"0.4"}}],
                                // 2-3 area's color is orange
                                [{yAxis: 2}, {yAxis: 3, itemStyle: {color: "RGB(255, 200, 100,0.4)",opacity:"0.4"}}],
                                // 3-4 area's color is red
                                [{yAxis: 3}, {yAxis: 4, itemStyle: {color: "RGB(255, 120, 120,0.4)",opacity:"0.4"}}]
                            ]
                        } : {},
                    });
                });
                return tmp;
            })(),
            tooltip: {
                trigger: "axis",
                backgroundColor: "white"
            },
            textStyle: {
                fontFamily: "Noto-Sans-Regular",
                fontSize: 12,
                fontWeight: 400
            },
            title: {
                text: echartTitle,
                top: 365,
                // left: width <= 320 ? 25 : 50,
                left: getLeftWidth(),
                textStyle: {
                    fontFamily: "Noto-Sans-Regular",
                    fontSize: 14,
                    fontWeight: 600
                },
            },
            legend: {
                data: echartSeries?.map((item)=>{
                    return item?.replace("\n","");
                }),
                // width: "80%",
                // itemWidth: 55,
                selected: (()=>{
                    let tmp = {};
                    echartSeries.forEach((item)=>{
                        tmp = {...tmp,item:true};
                    });
                    return tmp;
                })(),
                top: 390,
                left: getLeftWidth(),
                right: 0,
                textStyle: {
                    overflow: "break"
                }
                // bottom: gridTop
                // bottom: 0
            },

        };
        // chart.setOption(options);
        return options;
    };

    const getFilterData = (id:string):number[] =>{
        const filterId = id.charAt(0).toLocaleLowerCase() + id.slice(1);
        console.log("histdisease echart filter echartData",echartData);
        console.log("histdisease echart filter item-orgin",id);
        console.log("histdisease echart filter item-new",filterId);
        const tmpData = [];
        echartData?.data.forEach((item,index)=>{
            tmpData.push(item[filterId]);
        });
        return tmpData;
    };
    useEffect(() => {
        const chart = echarts.init(chartRef.current);
        let tmpOptions = null;
        if(echartData?.data.length > 0){
            tmpOptions = changeOption();
        }
        else{
            tmpOptions = setTemplateOption();
        }
        console.log("test echarts options",tmpOptions);
        console.log("test echarts colorArray",colorArray);
        console.log("test echarts idArray",idArray);
        console.log("test echarts echartData",echartData);
        chart.setOption(tmpOptions);
        return ()=>{
            chart.dispose();
        };
    }, [echartData]);
    console.log("fffff");
    return(
        <>
            {/* <h2>Width:{width}px</h2> */}
            <div
                id="disease-echart"
                ref={chartRef}
                style={{width:`${width ? width - 20 : 800}px`,height:`${containerHeight}px`}}></div>
        </>
    );
};

export default React.memo(EchartRender);