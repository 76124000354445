import { getDropdownTranslation } from "@/util/http/HistWeather";
import { DropDownTranslationModel } from "@/util/http/HistWeather/responseType";
import { useEffect,useState } from "react";
import {debounce} from "lodash";

interface IProp{
    pubName: string;
    strCultureCode: string;
}

const useAggretation = (props:IProp)=>{
    const {pubName,strCultureCode} = props;

    const [aggregationOptions,setAggretationOptions] = useState<DropDownTranslationModel[]>([]);

    const getDropdownTranslationFunc = async()=>{
        try{
            const res = await getDropdownTranslation({
                pubName,
                strCultureCode
            });
            const tmpAggregation = res.data[1].values;
            setAggretationOptions(tmpAggregation);
        }
        catch(error){
            console.log("========= useAggretation  getDropdownTranslata error======",error);
        }
    };

    useEffect(()=>{
        const myDebounceFunc = debounce(getDropdownTranslationFunc,500);
        if(pubName && strCultureCode){
            myDebounceFunc();
        }
        return()=>{
            myDebounceFunc.cancel();
        };
    },[pubName,strCultureCode]);

    return {
        aggregationOptions
    };
};

export default useAggretation;