/* eslint-disable max-depth */
import { fork, put, select, take, takeLatest } from "redux-saga/effects";
import {
    hideLoading,
    normalActionTypes,
    sagaActionTypes,
    searchTargetCoordinate,
    showAddBookmarkFailedFunc,
    showAddBookmarkSuccessFunc,
    showLoading,
    updateBookmarks,
    updateCorpsData,
    updateCorpsSprayData,
    updateCorpsSprayGroundData,
    updateCorpsSprayGroundWithNozzleData,
    updateCurrentLanguage,
    updateDiseaseData,
    updateFiveDayHoursOrDayData,
    updateFiveDayWeatherServerWarningCircleData,
    updateHoursOrDayData,
    updateKeyTurfData,
    updateLocalDailyWidgetWeather,
    updateLocationSearchResult,
    updateUserName,
    updateServiceToken,
    updateWeatherForcastdailyList,
    searchByPalce,
    searchByPalceResult,
} from "../actions/commonAction";
import {
    getBookMark,
    getUserInfo,
    addBookMark,
    getWeatherForCastDailyList,
    getLocalHourlyWidget,
    getTranslatedText,
    getChartData,
    getCorps,
    getCorpsSpray,
    getAggregateWeather,
    getServerWarning,
    getKeyTurfGrowthData,
    getFrostWarning,
    getDisease,
    getLocationOrCoordinate,
    simulationGetSignUrl,
    simulationGetToken,
    getAllLocations,
    getMapSetting,
    getTemperatureByRegionAndSetting,
    getContriesBoundries,
    updateMapSetting
} from "@/util/http";
import { RootState } from "../reducer/store";
import { ILocationModel, INewLocationModel } from "@/util/http/responseType";

function handleBridgeNewLocationToLatestLocationModel(locations: INewLocationModel[]): ILocationModel[] {
    const result = locations.map((item) => {
        return {
            AdminName: { _text: item.adminName },
            AdminName1: { _text: item.adminName1 },
            AdminName2: { _text: item.adminName2 },
            AdminName3: item.adminName3,
            AltName: { _text: item.altName },
            AreaId: { _text:  `${item.areaId}` },
            CountryCode: { _text: item.countryCode },
            CountryName: { _text: item.countryName },
            Latitude: { _text: `${item.latitude}` },
            Longitude: { _text: `${item.longitude}` },
            Name: { _text: item.name },
            PlaceId: { _text: `${item.placeId}` },
            PostalCode: item.postalCode,
            Score: { _text: item.score },
            Emailid:item.emailid
        };
    });
    return result;
}

function* watchLocationSearch() {
    while (true) {
        try {
            const action = yield take(sagaActionTypes.SAGA_LOCATION_SEARCH);
            const res = yield getLocationOrCoordinate(action.payload);
            if (res.data.length > 0 ) {
                const resultArray = handleBridgeNewLocationToLatestLocationModel(res.data);
                yield put(updateLocationSearchResult(resultArray));
            }
            else
                yield put(searchTargetCoordinate(true));
        } catch (err) {
            yield put(searchTargetCoordinate(true));
        }
    }
}

function* watchCoordinateSearch(action) {
    try {
        const {searchText,searchFlag} = action.payload;
        yield put({ type: normalActionTypes.NORMAL_UPDATE_HOME_INDEX_LOADING, payload: true });
        const res = yield getLocationOrCoordinate({searchText});
        if(searchFlag){
            if(res.data.length > 0){
                const resultArray = handleBridgeNewLocationToLatestLocationModel(res.data);
                yield put(updateLocationSearchResult(resultArray));
                yield put(searchByPalceResult(true));
            }
            else{
                yield put(searchByPalce(true));
            }
        }
        else{
            if (res.data.length > 0 ) {
                const resultArray = handleBridgeNewLocationToLatestLocationModel(res.data);
                yield put(updateLocationSearchResult(resultArray));
                yield put(searchByPalceResult(false));
            }
            else
                yield put(searchTargetCoordinate(true));
        }
        yield put({ type: normalActionTypes.NORMAL_UPDATE_HOME_INDEX_LOADING, payload: false });
    } catch (err) {
        yield put(searchTargetCoordinate(true));
        yield put({ type: normalActionTypes.NORMAL_UPDATE_HOME_INDEX_LOADING, payload: false });
    }
}

function* watchAutoCoordinateSearch() {
    while (true) {
        try {
            const action = yield take(sagaActionTypes.SAGA_COORDINATE_PARAM_SEARCH);
            const res = yield getLocationOrCoordinate(action.payload);
            if (res.data.length > 0 ) {
                const resultArray = handleBridgeNewLocationToLatestLocationModel(res.data);
                yield put(updateLocationSearchResult(resultArray));
            }
            else
                yield put(searchTargetCoordinate(true));
        } catch (err) {
            yield put(searchTargetCoordinate(true));
        }
    }
}

function* watchGetBookmark(action) {
    try {
        const {countryCode,emailid} = action.payload;

        const userName = yield select(
            (state: RootState) => state.commonReducer.userName
        );
        const res = yield getBookMark({ userName:emailid, countryCode });
        const dataArr = res.data;
        const filterDataArr = [];
        dataArr.forEach(ele => {
            const res = filterDataArr.filter((item) =>{
                return item.placeName === ele.placeName;
            });
            if (!res[0]) {
                filterDataArr.push(ele);
            }
        });
        yield put(updateBookmarks(dataArr));
    } catch (err) {
        console.log("err", err);
    }
}

function* watchAddBookmark(action) {
    try {
        const {securityStr,emailid} = action.payload;
        const userInfoRes = yield getUserInfo(securityStr);
        const userInfoArr =
      userInfoRes.data.decryptedText.split("#");
        const userName = userInfoArr[1];
        yield put(updateUserName(userName));
        yield put(showLoading());
        yield addBookMark({
            userName:emailid,
            latitude: parseFloat(action.payload.currentLocation.Latitude._text),
            longitude: parseFloat(action.payload.currentLocation.Longitude._text),
            placeName: action.payload.currentLocation.AdminName._text,
            countryCode: action.payload.currentLocation.CountryCode._text,
        });
        yield put(showAddBookmarkSuccessFunc(true));

        yield put(hideLoading());
    } catch (err) {
        console.log("err:===", err);
        yield put(showAddBookmarkFailedFunc(true));
        yield put(hideLoading());
    }
}

function* watchGetUserInfo() {
    while (true) {
        try {
            const action = yield take(sagaActionTypes.SAGA_GET_USERNAME);
            const {security, countryCode,emailid} = action.payload;
            const userInfoRes = yield getUserInfo(security);
            const userInfoArr =
        userInfoRes.data.decryptedText.split("#");
            const userName = userInfoArr[1];
            const serviceToken = userInfoArr[0];
            yield put(updateUserName(userName));
            yield put(updateServiceToken(serviceToken? serviceToken : "no-value"));
            const res = yield getBookMark({ userName:emailid, countryCode });
            const dataArr = res.data;
            const filterDataArr = [];
            dataArr.forEach(ele => {
                const res = filterDataArr.filter((item) =>{
                    return item.placeName === ele.placeName;
                });
                if (!res[0]) {
                    filterDataArr.push(ele);
                }
            });
            yield put(updateBookmarks(filterDataArr));
        } catch (err) {
            yield put(updateServiceToken("no-value"));
            console.log("err:==", err);
        }
    }
}

function* watchGetWeatherForcastDailyList(action) {
    try {
        yield put({ type: normalActionTypes.NORMAL_UPDATE_HOME_INDEX_LOADING, payload: true });

        const location: ILocationModel = action.payload;
        const res = yield getWeatherForCastDailyList({
            latitude: parseFloat(location.Latitude._text),
            longitude: parseFloat(location.Longitude._text),
            cultureCode: location.cultureCode,
            numberOfDays: location.numberOfDays,
            isGreencastForecast: location?.isGreencastForecast
        });
        yield put(updateWeatherForcastdailyList(res.data));
        yield put({ type: normalActionTypes.NORMAL_UPDATE_HOME_INDEX_LOADING, payload: false });

    } catch (err) {
        console.log("err:==", err);
        yield put({ type: normalActionTypes.NORMAL_UPDATE_HOME_INDEX_LOADING, payload: false });

    }
}

function* watchGetLocalHourlyWidget(action) {
    try {

        yield put({
            type: normalActionTypes.NOMRAL_UPDATE_WEATHER_LIST_LOADING,
            payload: true,
        });
        const location: ILocationModel = action.payload;
        const res = yield getLocalHourlyWidget({
            latitude: parseFloat(location.Latitude._text),
            longitude: parseFloat(location.Longitude._text),
            cultureCode: location.cultureCode,
            numberOfDays: location.numberOfDays,
            isNowcast: location.isNowcast,
            isGreencastForecast: location.isGreencastForecast
        });
        yield put(updateLocalDailyWidgetWeather(res.data));
    } catch (err) {
        console.log("res:", err);
        yield put({
            type: normalActionTypes.NOMRAL_UPDATE_WEATHER_LIST_LOADING,
            payload: false,
        });
    }
}

function* watchLanugage() {
    try {
        const action = yield take(sagaActionTypes.SAGA_UPDATE_CURRENT_LANGUAGE);
        const res = yield getTranslatedText(action.payload);
        console.log("res:====", res);
        const translateText: { [keyText: string]: string } = {};
        for (const item of res.data) {
            translateText[item.key] = item.value;
        }
        yield put(updateCurrentLanguage(translateText));
    } catch (err) {
        console.log(err);
    }
}

function* watchGetChartData(action) {
    try {
        yield put({
            type: normalActionTypes.NOMRAL_UPDATE_TOP_TITLE_LOADING,
            payload: true,
        });
        const data = action.payload;
        const res = yield getChartData(data);
        const xAxisData = [];
        const temperature = [];
        const precip = [];
        for (const item of res.data) {
            const dateTime = item?.dateTime
                ?.match(/(?<=\d\d\d\d\/)\d\d\/\d\d/g)[0]
                .replace("/", ".");
            const hourTime = item?.dateTime?.match(/(?<=\d\d\d\d\/\d\d\/\d\d\s*)\d\d/g)[0];
            xAxisData.push(`${ item.formatDay.toUpperCase().substring(0, 3)} \n ${dateTime} ${hourTime}:00`);
            temperature.push(item.tempAir);
            precip.push(item.precip);
        }

        yield put({
            type: normalActionTypes.NORMAL_UPDATE_CHART_DATA,
            payload: { temperature, precip, date: xAxisData },
        });
        yield put({
            type: normalActionTypes.NOMRAL_UPDATE_TOP_TITLE_LOADING,
            payload: false,
        });
    } catch (err) {
        yield put({
            type: normalActionTypes.NOMRAL_UPDATE_TOP_TITLE_LOADING,
            payload: false,
        });
    }
}

function* watchGetCrops(action) {
    try {
        const data = action.payload;
        const res = yield getCorps(data);
        const cropsItems = res.data.map((item) => {
            return {
                key: item.key,
                label: item.name,
            };
        });
        yield put(updateCorpsData(cropsItems));
    } catch (err) {
        console.log("err:===", err);
    }
}

function* watchGetCorpsSpray(action) {
    try {
        yield put({
            type: normalActionTypes.NOMRAL_UPDATE_SPRAY_LOADING,
            payload: true,
        });
        const data = action.payload;
        const res = yield getCorpsSpray(data);
        console.log("res:=============================", res);
        yield put(updateCorpsSprayData(res.data));
        yield put({
            type: normalActionTypes.NOMRAL_UPDATE_SPRAY_LOADING,
            payload: false,
        });
    } catch (err) {
        yield put({
            type: normalActionTypes.NOMRAL_UPDATE_SPRAY_LOADING,
            payload: false,
        });
    }
}

function* watchGetCorpsGroundSpray(action) {
    try {
        yield put({
            type: normalActionTypes.NOMRAL_UPDATE_SPRAY_LOADING,
            payload: true,
        });
        const data = action.payload;
        const res = yield getCorpsSpray(data);
        yield put(updateCorpsSprayGroundData(res.data));
        yield put({
            type: normalActionTypes.NOMRAL_UPDATE_SPRAY_LOADING,
            payload: false,
        });
    } catch (err) {
        yield put({
            type: normalActionTypes.NOMRAL_UPDATE_SPRAY_LOADING,
            payload: false,
        });
    }
}

function* watchGetCorpsGroundSprayWithNozzle(action) {
    try {
        yield put({
            type: normalActionTypes.NOMRAL_UPDATE_SPRAY_LOADING,
            payload: true,
        });
        const data = action.payload;
        const res = yield getCorpsSpray(data);
        yield put(updateCorpsSprayGroundWithNozzleData(res.data));
        yield put({
            type: normalActionTypes.NOMRAL_UPDATE_SPRAY_LOADING,
            payload: false,
        });
    } catch (err) {
        yield put({
            type: normalActionTypes.NOMRAL_UPDATE_SPRAY_LOADING,
            payload: false,
        });
    }
}

function* watchGetAggregateedWeather(action) {
    try {
        const data = action.payload;
        const constructLocalIconMap = {};
        // first get 24h
        const res24h = yield getAggregateWeather({ ...data, timeInterVal: "1h" });
        let default24HoursShowIcon;
        const includeObj24Default = res24h.data.defaultShow;
        const hours24Data = {};
        res24h.data.lstAggregatedWeather.forEach((item) => {
            const dateArr = item.weatherDate.split("/");
            const yearString = dateArr[2];
            const monthString = dateArr[0];
            const dayString = dateArr[1];
            const dateString = yearString + "/" + monthString + "/" + dayString;
            const allData = [];
            item.lstSerieData.forEach((item) => {
                const single = {};
                item.lstSerie.forEach((item) => {
                    single[item.serieKey] = item.serieValue;
                    single[`${item.serieKey}-name`] = item.serieName;
                    constructLocalIconMap[item.serieName] = item.serieKey;
                });
                allData.push(single);
            });
            hours24Data[dateString] = allData;
        });
        includeObj24Default.forEach((item) => {
            if (default24HoursShowIcon) {
                default24HoursShowIcon[constructLocalIconMap[item]] = true;
            } else {
                default24HoursShowIcon = { [constructLocalIconMap[item]]: true };
            }
        });
        // second get 8h
        const res8h = yield getAggregateWeather({ ...data, timeInterVal: "8h" });
        let default8HoursShowIcon;
        const includeObj8Default = res24h.data.defaultShow;
        includeObj8Default.forEach((item) => {
            if (default8HoursShowIcon) {
                default8HoursShowIcon[constructLocalIconMap[item]] = true;
            } else {
                default8HoursShowIcon = { [constructLocalIconMap[item]]: true };
            }
        });
        const hours8Data = {};

        res8h.data.lstAggregatedWeather.forEach((item) => {
            const dateArr = item.weatherDate.split("/");
            const yearString = dateArr[2];
            const monthString = dateArr[0];
            const dayString = dateArr[1];
            const dateString = yearString + "/" + monthString + "/" + dayString;
            const allData = [];
            item.lstSerieData.forEach((item) => {
                const single = {};
                item.lstSerie.forEach((item) => {
                    single[item.serieKey] = item.serieValue;
                    single[`${item.serieKey}-name`] = item.serieName;
                });
                allData.push(single);
            });
            hours8Data[dateString] = allData;
        });
        // third get day
        const resDay = yield getAggregateWeather({ ...data, timeInterVal: "day" });
        let defaultDayShowIcon;
        const includeObjDayDefault = res24h.data.defaultShow;
        includeObjDayDefault.forEach((item) => {
            if (defaultDayShowIcon) {
                defaultDayShowIcon[constructLocalIconMap[item]] = true;
            } else {
                defaultDayShowIcon = { [constructLocalIconMap[item]]: true };
            }
        });
        const hourDay = {};
        resDay.data.lstAggregatedWeather.forEach((item) => {
            const dateArr = item.weatherDate.split("/");
            const yearString = dateArr[2];
            const monthString = dateArr[0];
            const dayString = dateArr[1];
            const dateString = yearString + "/" + monthString + "/" + dayString;
            const allData = [];
            item.lstSerieData.forEach((item) => {
                const single = {};
                item.lstSerie.forEach((item) => {
                    single[item.serieKey] = item.serieValue;
                    single[`${item.serieKey}-name`] = item.serieName;
                });
                allData.push(single);
            });
            hourDay[dateString] = allData;
        });
        yield put(
            updateHoursOrDayData({
                weather24HoursData: {
                    hours24Data,
                    defaultShowIcon: default24HoursShowIcon,
                },
                weather8HoursData: {
                    hours8Data,
                    defaultShowIcon: default8HoursShowIcon,
                },
                weatherDayData: {
                    hourDay,
                    defaultShowIcon: defaultDayShowIcon,
                },
            })
        );
    } catch (err) {
        console.log("err:===1111111111111111111", err);
    }
}

function* watchGetFiveDayCorpsSpray(action) {
    try {
        const data = action.payload;
        // first get 24h
        const res24h = yield getAggregateWeather({ ...data, timeInterVal: "1h" });
        const constructLocalIconMap = {};

        let default24HoursShowIcon;
        const includeObj24Default = res24h.data.defaultShow;

        const hours24Data = {};
        res24h.data.lstAggregatedWeather.forEach((item) => {
            const dateArr = item.weatherDate.split("/");
            const yearString = dateArr[2];
            const monthString = dateArr[0];
            const dayString = dateArr[1];
            const dateString = yearString + "/" + monthString + "/" + dayString;
            const allData = [];
            item.lstSerieData.forEach((item) => {
                const single = {};
                item.lstSerie.forEach((item) => {
                    single[item.serieKey] = item.serieValue;
                    single[`${item.serieKey}-name`] = item.serieName;
                    constructLocalIconMap[item.serieName] = item.serieKey;
                });
                allData.push(single);
            });
            hours24Data[dateString] = allData;
        });
        includeObj24Default.forEach((item) => {
            if (default24HoursShowIcon) {
                default24HoursShowIcon[constructLocalIconMap[item]] = true;
            } else {
                default24HoursShowIcon = { [constructLocalIconMap[item]]: true };
            }
        });
        // second get 8h
        const res8h = yield getAggregateWeather({ ...data, timeInterVal: "8h" });
        let default8HoursShowIcon;
        const includeObj8Default = res24h.data.defaultShow;
        includeObj8Default.forEach((item) => {
            if (default8HoursShowIcon) {
                default8HoursShowIcon[constructLocalIconMap[item]] = true;
            } else {
                default8HoursShowIcon = { [constructLocalIconMap[item]]: true };
            }
        });
        const hours8Data = {};

        res8h.data.lstAggregatedWeather.forEach((item) => {
            const dateArr = item.weatherDate.split("/");
            const yearString = dateArr[2];
            const monthString = dateArr[0];
            const dayString = dateArr[1];
            const dateString = yearString + "/" + monthString + "/" + dayString;
            const allData = [];
            item.lstSerieData.forEach((item) => {
                const single = {};
                item.lstSerie.forEach((item) => {
                    single[item.serieKey] = item.serieValue;
                    single[`${item.serieKey}-name`] = item.serieName;
                });
                allData.push(single);
            });
            hours8Data[dateString] = allData;
        });
        // third get day
        const resDay = yield getAggregateWeather({ ...data, timeInterVal: "day" });
        let defaultDayShowIcon;
        const includeObjDayDefault = res24h.data.defaultShow;
        includeObjDayDefault.forEach((item) => {
            if (defaultDayShowIcon) {
                defaultDayShowIcon[constructLocalIconMap[item]] = true;
            } else {
                defaultDayShowIcon = { [constructLocalIconMap[item]]: true };
            }
        });
        const hourDay = {};
        resDay.data.lstAggregatedWeather.forEach((item) => {
            const dateArr = item.weatherDate.split("/");
            const yearString = dateArr[2];
            const monthString = dateArr[0];
            const dayString = dateArr[1];
            const dateString = yearString + "/" + monthString + "/" + dayString;
            const allData = [];
            item.lstSerieData.forEach((item) => {
                const single = {};
                item.lstSerie.forEach((item) => {
                    single[item.serieKey] = item.serieValue;
                    single[`${item.serieKey}-name`] = item.serieName;
                });
                allData.push(single);
            });
            hourDay[dateString] = allData;
        });
        yield put(
            updateFiveDayHoursOrDayData({
                fiveWeather24HoursData: {
                    hours24Data,
                    defaultShowIcon: default24HoursShowIcon,
                },
                fiveWeather8HoursData: {
                    hours8Data,
                    defaultShowIcon: default8HoursShowIcon,
                },
                fiveWeatherDayData: {
                    hourDay,
                    defaultShowIcon: defaultDayShowIcon,
                },
            })
        );
    } catch (err) {
        console.log("err:==================================", err);
    }
}

function* watchGetFiveDayCircleData(action) {
    try {
        yield put({
            type: normalActionTypes.NOMRAL_UPDATE_SPRAY_LOADING,
            payload: true,
        });
        const data = action.payload;
        const res = yield getServerWarning(data);
        yield put(updateFiveDayWeatherServerWarningCircleData(res.data));
        yield put({
            type: normalActionTypes.NOMRAL_UPDATE_SPRAY_LOADING,
            payload: false,
        });
    } catch (err) {
        console.log("err:===", err);
        yield put({
            type: normalActionTypes.NOMRAL_UPDATE_SPRAY_LOADING,
            payload: false,
        });
    }
}

function* watchGetKeyTurfGrowthData(action) {
    try {
        const data = action.payload;
        const res = yield getKeyTurfGrowthData(data);
        const resData = res.data;
        const aggretionData = resData.lstAggregatedWeather.map((item) => {
            const constructObj = {};
            item.lstSerieData.forEach((serie) => {
                serie.lstSerie.forEach((data) => {
                    constructObj[data.serieKey] = {
                        serieName: data.serieName,
                        serieValue: data.serieValue,
                    };
                });
            });
            return { ...constructObj, weatherDate: item.weatherDate ,formatDay: item?.formatDay};
        });
        const frostWarningRes = yield getFrostWarning(data);

        aggretionData.forEach((item) => {
            frostWarningRes.data.lstWeather.forEach((frostItem) => {
                if (item.weatherDate === frostItem.date) {
                    item["frostWarning"] = {
                        serieName: frostItem.name,
                        serieValue: frostItem.lstHourData[0].toolTip,
                    };
                }
            });
        });
        yield put(updateKeyTurfData(aggretionData));
    } catch (err) {
        console.log("err", err);
    }
}

function* watchGetDisease(action) {
    try {
        const data = action.payload;
        const res = yield getDisease(data);
        let defaultShowIcon;
        const includeObj24Default =
      res.data.lstAggregatedWeather[0].lstSerieData[0].lstSerie;

        includeObj24Default.forEach((item) => {
            if (defaultShowIcon) {
                defaultShowIcon[item.serieKey] = item.serieName;
            } else {
                defaultShowIcon = { [item.serieKey]: item.serieName };
            }
        });
        const diseaseData = {};
        const formatDay = {};
        res.data.lstAggregatedWeather.forEach((item) => {
            // const dateArr = item.weatherDate.split("/");
            // const yearString = dateArr[2];
            // const monthString = dateArr[0];
            // const dayString = dateArr[1];
            // const dateString = yearString + "/" + monthString + "/" + dayString;
            const single = {};
            item.lstSerieData.forEach((item) => {
                item.lstSerie.forEach((item) => {
                    single[item.serieKey] = item.serieValue;
                });
            });
            diseaseData[item.weatherDate] = single;
            formatDay[item.weatherDate] = item?.formatDay;
        });
        const disease = {
            diseaseData,
            defaultShowIcon,
            lstLegend: res.data.lstLegend,
            formatDay
        };
        yield put(updateDiseaseData(disease));
    } catch (err) {
        console.log("err", err);
    }
}

function *watchGetSignUrl() {
    try {
        const res = yield simulationGetSignUrl();
        const {
            url,
            codeVerifier
        } = res.data;
        localStorage.setItem("simulation-code-verifier", codeVerifier);
        localStorage.setItem("simulation-redirect-url", url);

        yield put({ type: normalActionTypes.NORMAL_UPDATE_REDIRECT_URL, payload: {ssoSigninUrl: url} });
    } catch(err) {
        console.log("err", err);
    }
}

function *watchGetToken(action) {
    try {
        const {
            authorizedCode,
            codeVerifier
        } = action.payload;
        const res = yield simulationGetToken({ authorizedCode, codeVerifier });
        const {
            token,
            user
        } = res.data;
        yield put({ type: normalActionTypes.NORMAL_UPDATE_TOKEN, payload: { token, userSimplifyName: user.userSimplifyName } });
        localStorage.setItem("userDisplayName", user.userSimplifyName);
        localStorage.setItem("simulation-token", token);
        localStorage.removeItem("simulation-code-verifier");
        localStorage.removeItem("simulation-redirect-url");
    } catch(err) {
        console.log("err", err);
    }
}

function *wathchGetAllLocations() {
    try {
        const res = yield getAllLocations();

        const data = res.data.map(country => {
            return {
                ...country,
                checkable: false
            };
        });
        const geoIds = {};
        const topGeoNameIdsArr = [];
        const countryGeonameIdSet = [];

        for (const item of data) {
            const countryGeonameIds = [];
            if (item.children?.length > 0) {
                for (const firstItem of item.children) {
                    topGeoNameIdsArr.push(firstItem.geonameid);
                    geoIds[firstItem.geonameid] = firstItem.children;
                    countryGeonameIds.push(firstItem.geonameid);

                    if (firstItem.children?.length > 0) {
                        for (const secondItem of firstItem.children) {
                            countryGeonameIds.push(secondItem.geonameid);
                            if (secondItem.children?.length > 0) {
                                for (const thirdItem of secondItem.children) {
                                    countryGeonameIds.push(thirdItem.geonameid);
                                    if (thirdItem.children?.length > 0) {
                                        for(const fourthItem of thirdItem.children) {
                                            countryGeonameIds.push(fourthItem.geonameid);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            countryGeonameIdSet.push(countryGeonameIds);
        }
        for (const topGeonameId of topGeoNameIdsArr) {
            const thirdGeonameIds = [];
            const secondItem = geoIds[topGeonameId];
            if (secondItem?.length > 0) {
                for (const item of secondItem) {
                    thirdGeonameIds.push(item.geonameid);
                }
            } else {
                thirdGeonameIds.push(topGeonameId);
            }

            geoIds[topGeonameId] = thirdGeonameIds;
        }
        yield put({ type: normalActionTypes.NORMAL_UPDATE_ALL_LOCATIONS, payload: { locations: data, geoIds, countryGeonameIdSet }});
    } catch(err) {
        console.log("err", err);
    }
}



function *getHeatMapSetting() {
    try {
        yield put({ type: normalActionTypes.NORMAL_UPDATE_HEATMAP_LOADING, payload: true });
        const res = yield getMapSetting();
        yield put({ type: normalActionTypes.NORMAL_UPDATE_LOCAL_MAP_SETTING, payload: res.data });
        yield put({ type: normalActionTypes.NORMAL_UPDATE_LOCAL_MAP_SETTING_ORIGIN, payload: {
            mapSetting: res.data,
            update: true
        }});

        yield put({ type: normalActionTypes.NORMAL_UPDATE_HEATMAP_LOADING, payload: false });
    } catch(err) {
        console.log("err", err);
        yield put({ type: normalActionTypes.NORMAL_UPDATE_HEATMAP_LOADING, payload: false });
    }
}

function *getTargetCountryBoundries(action) {
    try {
        yield put({ type: normalActionTypes.NORMAL_UPDATE_HEATMAP_LOADING, payload: true });

        const {
            geonameId,
            stressType,
            startTime,
            endTime
        } = action.payload;
        const res = yield getContriesBoundries({geonameId});
        const dataRes = yield getTemperatureByRegionAndSetting({ geonameId, stressType, startTime, endTime });
        yield put({ type: normalActionTypes.NORMAL_UPDATE_EXCEL_NAME, payload: dataRes.data.excelName});
        res.data.forEach((item) => {
            const result = dataRes.data.data.filter((dataItem) => {
                return dataItem.geonameId === item.geonameId;
            });
            item.riskColor = result && result[0].data[0].riskColor;
        });
        const resultData = res.data;
        // console.log("resultData:======", resultData);
        // console.log("dataRes.data:======", dataRes.data);

        yield put({ type: normalActionTypes.NORMAL_UPDATE_COUNTRY_BOUNDRIES, payload: resultData });
        yield put({ type: normalActionTypes.NORMAL_UPDATE_HEATMAP_LOADING, payload: false });
        yield put({ type: normalActionTypes.NORMAL_UPDATE_REQUEST_AFTER_CHANGE_SETTING, payload: false });

    } catch(err) {
        console.log("err", err);
        yield put({ type: normalActionTypes.NORMAL_UPDATE_HEATMAP_LOADING, payload: false });
        yield put({ type: normalActionTypes.NORMAL_UPDATE_REQUEST_AFTER_CHANGE_SETTING, payload: false });

    }
}

function *updateTempAndDaysSetting(action) {
    try {
        yield put({ type: normalActionTypes.NORMAL_UPDATE_HEATMAP_LOADING, payload: true });

        const {
            mapSetting,
            heatMapParam
        } = action.payload;

        yield updateMapSetting(mapSetting);
        yield put({ type: normalActionTypes.NORMAL_UPDATE_HEATMAP_LOADING, payload: false });

        yield put({ type: normalActionTypes.NORMAL_UPDATE_REQUEST_AFTER_CHANGE_SETTING, payload: true });

        // if (heatMapParam.stressType &&
        //     heatMapParam.geonameId &&
        //     !heatMapParam.startDate &&
        //     !heatMapParam.endDate) {
        //     yield getTargetCountryBoundries({ payload: heatMapParam });
        // }
    } catch(err) {
        console.log("err:===", err);
        yield put({ type: normalActionTypes.NORMAL_UPDATE_HEATMAP_LOADING, payload: false });
        yield put({ type: normalActionTypes.NORMAL_UPDATE_REQUEST_AFTER_CHANGE_SETTING, payload: false });
    }
}


function* accurateSaga() {
    yield takeLatest(sagaActionTypes.SAGA_ADD_BOOKMARK, watchAddBookmark);
    yield takeLatest(sagaActionTypes.SAGA_GET_BOOKMARK, watchGetBookmark);
    yield takeLatest(
        sagaActionTypes.SAGA_GET_WEATHERFORCAST_DAILY_LIST,
        watchGetWeatherForcastDailyList
    );
    yield takeLatest(
        sagaActionTypes.SAGA_GET_LOCAL_HOURLY_WIDGET,
        watchGetLocalHourlyWidget
    );
    yield takeLatest(sagaActionTypes.SAGA_GET_CHART_DATA, watchGetChartData);
    yield takeLatest(sagaActionTypes.SAGA_GET_CROPS, watchGetCrops);
    yield takeLatest(sagaActionTypes.SAGA_GET_CORPS_SPRAY, watchGetCorpsSpray);
    yield takeLatest(
        sagaActionTypes.SAGA_GET_AGGREGATED_WEATHER,
        watchGetAggregateedWeather
    );
    yield takeLatest(
        sagaActionTypes.SAGA_GET_SERVER_WARNING,
        watchGetFiveDayCircleData
    );
    yield takeLatest(
        sagaActionTypes.SAGA_FIVE_GET_AGGREGATED_WEATHER,
        watchGetFiveDayCorpsSpray
    );
    yield takeLatest(
        sagaActionTypes.SAGA_GET_KEY_TURF_GROWTH_DATA,
        watchGetKeyTurfGrowthData
    );
    yield takeLatest(
        sagaActionTypes.SAGA_GET_GROUND_SPRAYING,
        watchGetCorpsGroundSpray
    );
    yield takeLatest(
        sagaActionTypes.SAGA_GET_GROUND_SPRAYING_WITH_NOZZLE,
        watchGetCorpsGroundSprayWithNozzle
    );
    yield takeLatest(sagaActionTypes.SAGA_GET_DISEASE, watchGetDisease);
    yield takeLatest(sagaActionTypes.SAGA_GET_SIGN_URL, watchGetSignUrl);
    yield takeLatest(sagaActionTypes.SAGA_GET_TOKEN, watchGetToken);
    yield takeLatest(sagaActionTypes.SAGA_COORDINATE_SEARCH, watchCoordinateSearch);
    yield takeLatest(sagaActionTypes.SAGA_GET_ALL_LOCATIONS, wathchGetAllLocations);
    yield takeLatest(sagaActionTypes.SAGA_GET_HEAT_MAP_SETTING, getHeatMapSetting);
    // yield takeLatest(sagaActionTypes.SAGA_GET_TEMP_REGION_SETTING, getRegionAndTempSetting);
    yield takeLatest(sagaActionTypes.SAGA_GET_COUNTRY_BOUNDARY, getTargetCountryBoundries);
    yield takeLatest(sagaActionTypes.SAGA_UPDATE_TEMP_AND_DAYS_SETTING, updateTempAndDaysSetting);

    yield fork(watchLocationSearch);
    yield fork(watchAutoCoordinateSearch);
    yield fork(watchGetUserInfo);
    yield fork(watchLanugage);
}

export default accurateSaga;
