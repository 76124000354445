// Crop array
export const CropOptions = [
    {
        CropName: "Pepper",
        CropValue: "Pepper",
        CropID: "Pepper",
    }
];

export const DiseaseOptions = [
    {
        "ModelName": "Hot Pepper White Fly",
        "ModelID": "HotPepperBemisiatebasi",
        "ModelDomain": "Pepper",
        "Description": "Pepper disease Type predication on both historical and Frecast for Bemisiatebasi",
        "PropertyType": "Output",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in the range of 0-10. 0 = no risk,10 = High"
    },
    {
        "ModelName": "Hot Pepper Thrips",
        "ModelID": "HotPepperThripstebasi",
        "ModelDomain": "Pepper",
        "Description": "Pepper disease Type predication on both historical and Frecast for HotPepperThripstebasi",
        "PropertyType": "Output",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in the range of 0-10. 0 = no risk,10 = High"
    },
    {
        "ModelName": "Hot Pepper Bud Mites",
        "ModelID": "HotPepperAculopslycopersici",
        "ModelDomain": "Pepper",
        "Description": "Pepper disease Type predication on both historical and Frecast for HotPepperAculopslycopersici",
        "PropertyType": "Output",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in the range of 0-10. 0 = no risk,10 = High"
    },
    {
        "ModelName": "Hot Pepper Fruit Borer",
        "ModelID": "HotPepperHelicoverpaarmigeraSpodopteralitura",
        "ModelDomain": "Pepper",
        "Description": "Pepper disease Type predication on both historical and Frecast for HotPepperHelicoverpaarmigeraSpodopteralitura",
        "PropertyType": "Output",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in the range of 0-10. 0 = no risk,10 = High"
    },
    {
        "ModelName": "Hot Pepper Chilli Aphid",
        "ModelID": "HotPepperAphisgossypii",
        "ModelDomain": "Pepper",
        "Description": "Pepper disease Type predication on both historical and Frecast for HotPepperAphisgossypii",
        "PropertyType": "Output",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in the range of 0-10. 0 = no risk,10 = High"
    },
    {
        "ModelName": "Hot Pepper Chilli Anthracnose",
        "ModelID": "HotPepperAnthracnose",
        "ModelDomain": "Pepper",
        "Description": "Pepper disease Type predication on both historical and Frecast for HotPepperAnthracnose",
        "PropertyType": "Output",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in the range of 0-10. 0 = no risk,10 = High"
    },
    {
        "ModelName": "Hot Pepper Chilli Powdery Mildew",
        "ModelID": "HotPepperLeveillulataurica",
        "ModelDomain": "Pepper",
        "Description": "Pepper disease Type predication on both historical and Frecast for HotPepperLeveillulataurica",
        "PropertyType": "Output",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in the range of 0-10. 0 = no risk,10 = High"
    },
    {
        "ModelName": "Hot Pepper Chilli Fusarium Wilt",
        "ModelID": "HotPepperFusariumoxysporum",
        "ModelDomain": "Pepper",
        "Description": "Pepper disease Type predication on both historical and Frecast for HotPepperFusariumoxysporum",
        "PropertyType": "Output",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in the range of 0-10. 0 = no risk,10 = High"
    },
    {
        "ModelName": "Hot Pepper Chilli Collar Rot",
        "ModelID": "HotPepperAtheliaRolfsii",
        "ModelDomain": "Pepper",
        "Description": "Pepper disease Type predication on both historical and Frecast for HotPepperAtheliaRolfsii",
        "PropertyType": "Output",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in the range of 0-10. 0 = no risk,10 = High"
    },
    {
        "ModelName": "Hot Pepper Chilli die back",
        "ModelID": "HotPepperColletotrichumcapsici",
        "ModelDomain": "Pepper",
        "Description": "Pepper disease Type predication on both historical and Frecast for HotPepperColletotrichumcapsici",
        "PropertyType": "Output",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in the range of 0-10. 0 = no risk,10 = High"
    },
    {
        "ModelName": "Hot Pepper Chilli Leaf Curl Virus",
        "ModelID": "HotPepperChilLeaf",
        "ModelDomain": "Pepper",
        "Description": "Pepper disease Type predication on both historical and Frecast for HotPepperChilLeaf",
        "PropertyType": "Output",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in the range of 0-10. 0 = no risk,10 = High"
    },
    {
        "ModelName": "Hot Pepper Phytophthora Blight",
        "ModelID": "HotPepperPhytophoracapsici",
        "ModelDomain": "Pepper",
        "Description": "Pepper disease Type predication on both historical and Frecast for HotPepperPhytophoracapsici",
        "PropertyType": "Output",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in the range of 0-10. 0 = no risk,10 = High"
    },

    {
        "ModelName": "Hot Pepper White Fly - Excarta",
        "ModelID": "HotPepperBemisiatebasi_excarta",
        "ModelDomain": "Pepper",
        "Description": "Pepper disease Type predication on both historical and Frecast for Bemisiatebasi",
        "PropertyType": "Output",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in the range of 0-10. 0 = no risk,10 = High"
    },
    {
        "ModelName": "Hot Pepper Thrips - Excarta",
        "ModelID": "HotPepperThripstebasi_excarta",
        "ModelDomain": "Pepper",
        "Description": "Pepper disease Type predication on both historical and Frecast for HotPepperThripstebasi",
        "PropertyType": "Output",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in the range of 0-10. 0 = no risk,10 = High"
    },
    {
        "ModelName": "Hot Pepper Bud Mites - Excarta",
        "ModelID": "HotPepperAculopslycopersici_excarta",
        "ModelDomain": "Pepper",
        "Description": "Pepper disease Type predication on both historical and Frecast for HotPepperAculopslycopersici",
        "PropertyType": "Output",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in the range of 0-10. 0 = no risk,10 = High"
    },
    {
        "ModelName": "Hot Pepper Fruit Borer - Excarta",
        "ModelID": "HotPepperHelicoverpaarmigeraSpodopteralitura_excarta",
        "ModelDomain": "Pepper",
        "Description": "Pepper disease Type predication on both historical and Frecast for HotPepperHelicoverpaarmigeraSpodopteralitura",
        "PropertyType": "Output",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in the range of 0-10. 0 = no risk,10 = High"
    },
    {
        "ModelName": "Hot Pepper Chilli Aphid - Excarta",
        "ModelID": "HotPepperAphisgossypii_excarta",
        "ModelDomain": "Pepper",
        "Description": "Pepper disease Type predication on both historical and Frecast for HotPepperAphisgossypii",
        "PropertyType": "Output",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in the range of 0-10. 0 = no risk,10 = High"
    },
    {
        "ModelName": "Hot Pepper Chilli Anthracnose - Excarta",
        "ModelID": "HotPepperAnthracnose_excarta",
        "ModelDomain": "Pepper",
        "Description": "Pepper disease Type predication on both historical and Frecast for HotPepperAnthracnose",
        "PropertyType": "Output",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in the range of 0-10. 0 = no risk,10 = High"
    },
    {
        "ModelName": "Hot Pepper Chilli Powdery Mildew - Excarta",
        "ModelID": "HotPepperLeveillulataurica_excarta",
        "ModelDomain": "Pepper",
        "Description": "Pepper disease Type predication on both historical and Frecast for HotPepperLeveillulataurica",
        "PropertyType": "Output",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in the range of 0-10. 0 = no risk,10 = High"
    },
    {
        "ModelName": "Hot Pepper Chilli Fusarium Wilt - Excarta",
        "ModelID": "HotPepperFusariumoxysporum_excarta",
        "ModelDomain": "Pepper",
        "Description": "Pepper disease Type predication on both historical and Frecast for HotPepperFusariumoxysporum",
        "PropertyType": "Output",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in the range of 0-10. 0 = no risk,10 = High"
    },
    {
        "ModelName": "Hot Pepper Chilli Collar Rot - Excarta",
        "ModelID": "HotPepperAtheliaRolfsii_excarta",
        "ModelDomain": "Pepper",
        "Description": "Pepper disease Type predication on both historical and Frecast for HotPepperAtheliaRolfsii",
        "PropertyType": "Output",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in the range of 0-10. 0 = no risk,10 = High"
    },
    {
        "ModelName": "Hot Pepper Chilli die back - Excarta",
        "ModelID": "HotPepperColletotrichumcapsici_excarta",
        "ModelDomain": "Pepper",
        "Description": "Pepper disease Type predication on both historical and Frecast for HotPepperColletotrichumcapsici",
        "PropertyType": "Output",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in the range of 0-10. 0 = no risk,10 = High"
    },
    {
        "ModelName": "Hot Pepper Chilli Leaf Curl Virus - Excarta",
        "ModelID": "HotPepperChilLeaf_excarta",
        "ModelDomain": "Pepper",
        "Description": "Pepper disease Type predication on both historical and Frecast for HotPepperChilLeaf",
        "PropertyType": "Output",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in the range of 0-10. 0 = no risk,10 = High"
    },
    {
        "ModelName": "Hot Pepper Phytophthora Blight - Excarta",
        "ModelID": "HotPepperPhytophoracapsici_excarta",
        "ModelDomain": "Pepper",
        "Description": "Pepper disease Type predication on both historical and Frecast for HotPepperPhytophoracapsici",
        "PropertyType": "Output",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in the range of 0-10. 0 = no risk,10 = High"
    },
];