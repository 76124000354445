import React, { useEffect, useRef, useState } from "react";
import GoogleMapReact from "google-map-react";
import { text } from "stream/consumers";
import QuantisMarker from "./components/QuantisMarker";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/redux/reducer/store";
import { normalActionTypes } from "@/redux/actions/quantisAction";

interface IProp{
    changeShowDeleteOrUpdateFieldModalCallback: (flag:boolean) => void
    changeShowMapAddFieldModalCallback: (flag:boolean) => void
    changeMarkerCallback: (crop:string, fieldName:string, bookmarkFieldName?:string,forecaseDay?:number) =>void
}


const SimpleMap = (prop: IProp)=>{
    /**
     * 一些基本概念：
     *      1. 当我们点击图标时，图标变成绿色，main 页面的 cropValue， fieldValue ，forecastDay 的值也变，重新获取天气
     *          1.1 因为监听了 fieldValue 和 forecastDay 的值，所以重新获取天气是自动的，
     *          1.2 只需要手动设置：cropValue 的值， bookMark 的值为空
     *          1.3 地图的中心点是当前 fieldValue 中的 经纬度
     *          1.4 同时打开 更改或删除 field 的 Modal
     *
     *      2. 双击地图：获取到双击点的 经纬度，打开新增 Field 的Modal
     */
    const dispatch = useDispatch();

    // get data from redux
    const {
        currentMapCenter,
        allFields
    } = useSelector((state: RootState) => state.quantisReducer);

    const {
        changeShowDeleteOrUpdateFieldModalCallback,
        changeShowMapAddFieldModalCallback,
        changeMarkerCallback
    } = prop;


    const defaultProps = {
        center: {
            lat: 57.99835602,
            lng: 31.01502627
        },
        zoom: 10
    };


    // this function is used to get map and maps instance
    const mapRef = useRef(null);
    const mapsRef = useRef(null);
    const handleApiLoaded = (map, maps) => {
        // maps.event.addDomListener(map, "dbClick",(event)=>{
        //     console.log("dd");
        //     console.log(`map dbCLick function: get value: lat:${event.latLng.lat()},lng is ${event.latLng.lng()}`);
        // });
        map.addListener("dblclick", (event) => {
            console.log(`map dbClick: lat:${event.latLng.lat()}, lng: ${event.latLng.lng()}`);
            console.log(`map dbClick map ${map?.getZoom()}`);
            dispatch({
                type: normalActionTypes.NORMAL_UPDATE_CURRENTMAPCENTER,
                payload: {
                    latitude: Number(event.latLng.lat()),
                    longitude: Number(event.latLng.lng()),
                    zoom: map?.getZoom(),
                    refreshClick: false
                }
            });
            changeShowMapAddFieldModalCallback(true);
        });
        mapRef.current = map;
        mapsRef.current = maps;
        // if (maps) map.mapTypeId = maps.MapTypeId.HYBRID;
        mapRef.current.mapTypeId = maps.MapTypeId.HYBRID;
        setInitFlag(true);

    };
    const [initFlag, setInitFlag] = useState(false);

    const changeMapCenterAndZoom = (lat?:number,lng?:number,zoom?:number)=>{
        if(lat && lng){
            mapRef.current?.setCenter({lat,lng});
        }
        if(zoom){
            mapRef.current?.setZoom(zoom);
        }
    };
    const [mapZoom,setMapZoom] = useState(10); // when we add new field , we will used this value


    console.log("test b mapsRef?.current",mapRef?.current);
    console.log("test b mapsRef?.current",mapsRef?.current);

    const [quantisOrder,setQuantisOrder] = useState<string[]>([]);
    const [quantisMarkers,setQuantisMarkers] = useState<{[key:string] : any}>({});
    const [switchMarker,setSwitchMarker] = useState(0);
    const childClickCurrentMarkerCallback = (latitude:number,longitude:number,fieldName:string)=>{
        console.log(`aaa current marker info: latitude is ${latitude}, longitude is ${longitude}`);
        console.log(`aaa current currentMapCenter info: latitude is ${currentMapCenter?.latitude}, longitude is ${currentMapCenter?.longitude}`);
        // TODO: 判断当前 Marker 的经纬度是不是等于当前 currentMapCenter, 是的话打开 更改的 Modal，不是的话切换地图中心点即可
        if(currentMapCenter?.latitude === latitude && currentMapCenter?.longitude === longitude){
            changeShowDeleteOrUpdateFieldModalCallback(true); // show UpdateOrDeleteFieldModal
        }
        else{
            dispatch({
                type: normalActionTypes.NORMAL_UPDATE_CURRENTMAPCENTER,
                payload: {
                    latitude: latitude,
                    longitude: longitude,
                    zoom: mapZoom,
                    refreshClick: false
                }
            });
            setSwitchMarker(switchMarker + 1);
            // TODO: 更改cropValue，fieldValue ， forecastDay 等值
            const tmpField = allFields?.filter((item)=> item?.fieldName === fieldName)[0];
            if(tmpField?.fieldName){
                const tmpCrop = tmpField?.crop;
                const tmpFieldValue = tmpField?.fieldName;
                const tmpForecastDayValue = tmpField?.forecastDays;
                const tmpBookMarkValue = null;
                changeMarkerCallback(tmpCrop,tmpFieldValue,tmpBookMarkValue,tmpForecastDayValue);
            }
        }
    };

    useEffect(()=>{
        if(initFlag){
            if(currentMapCenter){
                /**
                 * this check is for dbclick map, but not add a new field
                 */
                let currentCenterExistInAllFields = false;
                allFields.forEach((item)=>{
                    if(item?.latitude === currentMapCenter?.latitude?.toString() && item?.longitude === currentMapCenter?.longitude?.toString())
                        currentCenterExistInAllFields = true;
                });
                console.log(`lanxue map quantisReducer: refreshClick is ${currentMapCenter.refreshClick}, currentCenterExistInAllFields is ${currentCenterExistInAllFields}`);
                if(currentCenterExistInAllFields){
                    changeMapCenterAndZoom(currentMapCenter?.latitude,currentMapCenter?.longitude,currentMapCenter?.zoom);
                    setMapZoom(mapZoom);
                }
                else if(currentMapCenter?.refreshClick){
                    // mean we click the getCurrent Location button
                    changeMapCenterAndZoom(currentMapCenter?.latitude,currentMapCenter?.longitude,currentMapCenter?.zoom);
                    setMapZoom(mapZoom);
                    // reset refresh flag
                    dispatch({
                        type: normalActionTypes.NORMAL_UPDATE_CURRENTMAPCENTER,
                        payload: {
                            latitude: currentMapCenter?.latitude,
                            longitude: currentMapCenter?.longitude,
                            zoom: currentMapCenter?.zoom,
                            refreshClick: false
                        }
                    });
                }
                else{
                    return;
                }
            }
            if(allFields.length >= 1){
                const tmpQuantisOrder : string[] = [];
                const tmpQUantisMarkers : {[key:string]:any} = {};
                allFields.forEach((item,index)=>{
                    let tmpMarkerType = "Green";
                    if(item?.latitude === currentMapCenter?.latitude?.toString() && item?.longitude === currentMapCenter?.longitude?.toString()){
                        tmpMarkerType = "Red";
                    }
                    const tmpMarker = (
                        <QuantisMarker
                            key={(item?.latitude + index + item?.longitude)}
                            lat={Number(item?.latitude)}
                            lng={Number(item?.longitude)}
                            width={25}
                            height={25}
                            markerType={tmpMarkerType}
                            fieldName={item?.fieldName}
                            clickCurrentMarkerCallback={(latitude:number,longitude:number,fieldName:string)=>{
                                childClickCurrentMarkerCallback(latitude,longitude,fieldName);
                            }}
                        />
                    );
                    const tmpMarkerKey = index + tmpMarkerType + item?.fieldName;
                    tmpQUantisMarkers[tmpMarkerKey] = tmpMarker;
                    tmpQuantisOrder.push(tmpMarkerKey);
                });
                setQuantisOrder(tmpQuantisOrder);
                setQuantisMarkers(tmpQUantisMarkers);
            }
        }
    },[allFields,currentMapCenter,switchMarker,initFlag]);
    console.log("quantis google map quantisMarkers",quantisMarkers);
    console.log("quantis google map quantisOrder",quantisOrder);
    console.log("quantis google map currentMapCenter",currentMapCenter);
    console.log("quantis google map after change mapZoom",mapZoom);

    return (
    // Important! Always set the container height explicitly
        <div style={{ height: "400px", width: "100%" }}>
            <GoogleMapReact
                bootstrapURLKeys={{ key: "AIzaSyDlsxkoR8-fiIgncnDunmEtWEwDmr896H0" }}
                defaultCenter={defaultProps.center}
                defaultZoom={defaultProps.zoom}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
                onChange={(map:any) => {
                    console.log("test googme map onChange event: map",map);
                    console.log("test googme map onChange event: mapRef.current",mapRef.current);
                    setMapZoom(map?.zoom);
                }}
            >
                {
                    quantisOrder?.map((item) => quantisMarkers[item])
                }
            </GoogleMapReact>
        </div>
    );
};
export default SimpleMap;