import React, { useEffect } from "react";
import "./index.less";
import {
    WindDirectionSecondIcon,
    WindGustsSecondIcon,
    WindSpeedIconSecondIcon,
    NewWindDirectionIcon
} from "@/resource/IconComponents";
import IF from "@/components/IF";

interface IProps {
    include?: {
        [keyValue: string]: boolean
    }
    weatherData?: {
        [keyValu: string]: string
    }
    indent?: boolean
    justShowIcon?: boolean
}


const HourlySecond = (props: IProps) => {
    const {
        include,
        weatherData,
        indent,
        justShowIcon
    } = props;
    return (
        <div className={`ag-second-div ${indent && "indent-container-class"}` }>
            <IF show={!justShowIcon}>
                <IF show={include["we_wind_kmh"] || include["we_wind_mph"] || (!indent && Boolean(weatherData?.["we_wind_kmh"] || weatherData?.["we_wind_mph"]))}>
                    <div className="first-div">
                        <IF show={!indent}>
                            <div>
                                <WindSpeedIconSecondIcon />
                            </div>
                            <label className="second-label">
                                {weatherData?.["we_wind_kmh-name"]}
                                {weatherData?.["we_wind_mph-name"]}
                            </label>
                        </IF>
                        <label className="third-label">
                            {weatherData?.["we_wind_kmh"]}
                            {weatherData?.["we_wind_mph"]}
                        </label>
                    </div>
                </IF>
                <IF show={include["ResWindDirection"] || (!indent && Boolean(weatherData?.["ResWindDirection"]))}>
                    <div className="second-div">
                        <IF show={!indent}>
                            <div>
                                <NewWindDirectionIcon />
                            </div>
                            <label className="second-label">
                                {weatherData?.["ResWindDirection-name"]}
                            </label>
                        </IF>
                        <label className="third-label">
                            {weatherData?.["ResWindDirection"]}
                        </label>
                    </div>
                </IF>
                <IF show={include["we_windgusts_kmh"] || include["wind_gusts_mph"] || (!indent && Boolean(weatherData?.["we_windgusts_kmh"]||weatherData?.["wind_gusts_mph"]))}>
                    <div className="third-div">
                        <IF show={!indent}>
                            <div>
                                <WindGustsSecondIcon />
                            </div>
                            <label className="second-label">
                                {weatherData?.["we_windgusts_kmh-name"]}
                                {weatherData?.["wind_gusts_mph-name"]}
                            </label>
                        </IF>
                        <label className="third-label">
                            {weatherData?.["we_windgusts_kmh"]}
                            {weatherData?.["wind_gusts_mph"]}

                        </label>
                    </div>
                </IF>
            </IF>
            <IF show={justShowIcon}>
                <IF show={include["we_wind_kmh"] || include["we_wind_mph"] }>
                    <div className="up-div">
                        <WindSpeedIconSecondIcon />
                        <label className="second-label">
                            {weatherData?.["we_wind_kmh-name"]}
                            {weatherData?.["we_wind_mph-name"]}
                        </label>
                    </div>
                </IF>
                <IF show={include["ResWindDirection"]}>
                    <div className="up-div">
                        <NewWindDirectionIcon />
                        <label className="second-label">
                            {weatherData?.["ResWindDirection-name"]}
                        </label>
                    </div>
                </IF>
                <IF show={include["we_windgusts_kmh"] || include["wind_gusts_mph"]}>
                    <div className="up-div">
                        <WindGustsSecondIcon />
                        <label className="second-label">
                            {weatherData?.["we_windgusts_kmh-name"]}
                            {weatherData?.["wind_gusts_mph-name"]}
                        </label>
                    </div>
                </IF>
            </IF>
        </div>
    );
};

export default HourlySecond;