import React, { useState, useRef, useCallback, useEffect, MutableRefObject } from "react";
import { PlantIcon, RightArrowIcon, CollapseIcon } from "@/resource/IconComponents";
import {
    getRecentQueriesForSimulation,
    getSavedQueriesForSimulation,
    executeQueryByGuidForSimulation,
    getLocationsForSimulation
} from "@/util/http";
import QueryBookMarkComponent from "../MyQueries/components/QueryBookMark";
import QueryTabPaneComponent from "../CreateQuery/components/QueryTabPane";
import HeadComponent from "../CreateQuery/components/Head";
import SideBarComponent from "../CreateQuery/components/SideBar";
import "./index.less";
import { normalActionTypes } from "@/redux/actions/diseaseAction";
import { useDispatch, useSelector } from "react-redux";
import GoogleMapComponents from "../CreateQuery/components/GoogleMapComponents";
import IF from "@/components/IF";
import { RootState } from "@/redux/reducer/store";
import { normalActionTypes as simulationAction} from "@/redux/actions/simulationAction";

const MyQueries = () => {
    const dispatch = useDispatch();

    const [scroll, setScroll] = useState<any>({});
    const [recentQueries, setRecentQueries] = useState<any[] | null>(null);
    const [savedQueries, setSavedQueries] = useState<any[] | null>(null);
    const [bookmarkKey, setBookmarkKey] = useState<string>("");
    const [showRecentQuery, setShowRecentQuery] = useState<boolean>(false);
    const [showSavedQuery, setShowSavedQuery] = useState<boolean>(false);
    const [currentQueryId, setCurrentQueryId] = useState<number | null>(null);
    const [chartsData, setChartsData] = useState<any>(null);

    const {
        simulationIsMapClick,
        simulationLocationInfo,
        simulationClickCount
    } = useSelector((state: RootState) => state.simulationReducer);

    // set location option list
    const [locationOptions, setLocationOptions] = useState<any>(null);
    const [showGoogleMap,setShowGoogleMap] = useState(true);

    const callExecuteQuery = useCallback(async (queryId) => {
        setChartsData(null);
        dispatch({ type: normalActionTypes.NORMAL_SHOWLODDING, payload: true});
        const result = await executeQueryByGuidForSimulation({
            queryId:queryId
        });
        if (result == null) {
            alert("Cann`t find reliative data!");
        } else {
            setChartsData(result);
            setCurrentQueryId(queryId);
            setShowGoogleMap(false);
        }
        dispatch({ type: normalActionTypes.NORMAL_SHOWLODDING, payload: false});
    }, []);

    const loadRecentQueries = useCallback(async () => {
        const res = await getRecentQueriesForSimulation(
            {
                limit: 15
            }
        );
        setRecentQueries(res.data);
    }, []);
    const loadSavedQueries = useCallback(async () => {
        const res = await getSavedQueriesForSimulation();
        setSavedQueries(res.data);
    }, []);
    const showRecentList = () => {
        setShowRecentQuery(!showRecentQuery);
        setShowSavedQuery(false);
    };
    const showSavedList = () => {
        setShowSavedQuery(!showSavedQuery);
        setShowRecentQuery(false);
    };
    useEffect(() => {
        loadRecentQueries();
        loadSavedQueries();
        // init googlemap setting
        dispatch({
            type: simulationAction.NORMAL_SIMULATION_LOCATION_INFO,
            payload: "0#0#0#0"
        });
        dispatch({
            type: simulationAction.NORMAL_SIMULATION_IS_MAPCLICK,
            payload: null
        });
        dispatch({
            type: simulationAction.NORMAL_SIMULATION_MAPCOUNT,
            payload: 0
        });
    }, []);
    const refreshRecentQueries = useCallback(() => { loadRecentQueries(); }, []);
    const refreshSavedQueries = useCallback(() => { loadSavedQueries(); }, []);
    return (
        <div>
            <HeadComponent></HeadComponent>
            <SideBarComponent></SideBarComponent>
            <div className="div-left">
                <div style={{ marginTop: "8px" }} className="div-title"><span>My queries</span></div>
                <div style={{ marginTop: "8px" }}>
                    <div className="div-recent-queries">
                        <label>Recent queries</label>
                        <div className="div-number-queries" onClick={showRecentList}>
                            <label>{recentQueries?.length}</label>
                            <span className={showRecentQuery ? "query-icon-position" : ""}>
                                {
                                    // showRecentQuery ? <RightArrowIcon rotateDeg={90} /> : <RightArrowIcon rotateDeg={0} />
                                    showRecentQuery ? <CollapseIcon /> : <RightArrowIcon rotateDeg={0} />
                                }
                            </span>
                        </div>
                    </div>
                    {
                        showRecentQuery && recentQueries ?
                            <div ref={e => (setScroll(e))} className="loading-more">
                                {
                                    recentQueries.map((query, index) =>
                                        (<div key={index}>
                                            <QueryBookMarkComponent
                                                query={query}
                                                bookmarkKey={bookmarkKey}
                                                setBookmarkKey={setBookmarkKey}
                                                refreshQueries={refreshRecentQueries}
                                                executeQuery={callExecuteQuery} />
                                            <br />
                                        </div>))
                                }
                            </div>
                            :
                            null
                    }
                </div>
                <div style={{ marginTop: "8px"}}>
                    <div className="div-saved-queries">
                        <label>Saved queries</label>
                        <div className="div-number-queries" onClick={showSavedList}>
                            <label>{savedQueries?.length}</label>
                            <span className={showSavedQuery ? "query-icon-position" : ""}>
                                {
                                    showSavedQuery ? <CollapseIcon /> : <RightArrowIcon rotateDeg={0} />
                                }
                            </span>
                        </div>
                    </div>
                    {
                        savedQueries?.length == 0 ?
                            <div className="div-saved-queries" style={{ textAlign: "center" }}>
                                <div className="div-none-query">No saved queries yet</div><br />
                                <div><PlantIcon /></div><br />
                                <div>
                                    <a href="/simulation/environmental-characterization">
                                        <input className="btn-create-query" type="button" value="Create query" />
                                    </a>
                                </div>
                            </div>
                            : null
                    }
                    {
                        showSavedQuery && savedQueries ?
                            <div ref={e => (setScroll(e))} className="loading-more">
                                {
                                    savedQueries.map((query, index) =>
                                        (<div key={index}>
                                            <QueryBookMarkComponent
                                                query={query}
                                                bookmarkKey={bookmarkKey}
                                                setBookmarkKey={setBookmarkKey}
                                                refreshQueries={refreshSavedQueries}
                                                executeQuery={callExecuteQuery} />
                                            <br />
                                        </div>))
                                }
                            </div>
                            :
                            null
                    }
                </div>
            </div>
            <div className="div-right">
                <IF show={showGoogleMap}>
                    <GoogleMapComponents/>
                </IF>
                <IF show={!showGoogleMap}>
                    {
                        chartsData && currentQueryId ?
                            <QueryTabPaneComponent
                                showMapViewBtn={false}
                                chartsData={chartsData.data}
                                queryId={currentQueryId} /> : locationOptions
                    }
                </IF>
            </div>
        </div >
    );
};

export default MyQueries;