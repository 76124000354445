import React, { useEffect } from "react";
import "./index.less";
import {
    CloudCoverThirdIcon,
    EvaportalThirdIcon,
    PrecipltationRiskThirdIcon,
    PrecipltationThirdIcon,
    ShowerRiskThirdIcon,
    StormProbabilityThirdIcon,
} from "@/resource/IconComponents";
import IF from "@/components/IF";
import { useSelector } from "react-redux";
import { RootState } from "@/redux/reducer/store";

interface IProps {
    weatherData?: {
        [keyValu: string]: string
    }
    isFiveWeatherDay?: boolean
}

const DailyPreciptation = (props: IProps) => {

    const {
        currentLanguage
    } = useSelector((state: RootState) => state.commonReducer);

    const {
        weatherData,
        isFiveWeatherDay = false
    } = props;

    return (
        <div className="precipitation-container">
            <div className="title-div">
                {currentLanguage.txt_Precipitaion}
            </div>
            <div className="detail-div">
                <div>
                    <IF show={Boolean(weatherData["precipitation"]) || Boolean(weatherData["precipitationinch"])}>
                        <div className="unite-detail-div">
                            <div className="icon-div">
                                <PrecipltationThirdIcon />
                                <label className="number-label">{weatherData["precipitation"]}</label>
                                <label className="number-label">{weatherData["precipitationinch"]}</label>
                            </div>
                            <div className="text-div">
                                {weatherData["precipitation-name"]}
                                {weatherData["precipitationinch-name"]}

                            </div>
                        </div>
                    </IF>
                    <IF show={Boolean(weatherData["dd_precipitation_risk"])}>
                        <div className="unite-detail-div">
                            <div className="icon-div">
                                <PrecipltationRiskThirdIcon />
                                <label className="number-label">{weatherData["dd_precipitation_risk"]}</label>
                            </div>
                            <div className="text-div">
                                {weatherData["dd_precipitation_risk-name"]}
                            </div>
                        </div>
                    </IF>
                </div>
                <div>
                    <IF show={Boolean(weatherData["cloudiness"])}>
                        <div className="unite-detail-div">
                            <div className="icon-div">
                                <CloudCoverThirdIcon />
                                <label className="number-label">{weatherData["cloudiness"]}</label>
                            </div>
                            <div className="text-div">
                                {weatherData["cloudiness-name"]}
                            </div>
                        </div>
                    </IF>
                    <IF show={Boolean(weatherData["CRiskShower"])}>
                        <div className="unite-detail-div">
                            <div className="icon-div">
                                <ShowerRiskThirdIcon />
                                <label className="number-label">{weatherData["CRiskShower"]}</label>
                            </div>
                            <div className="text-div">
                                {weatherData["CRiskShower-name"]}
                            </div>
                        </div>
                    </IF>
                    <IF show={isFiveWeatherDay}>
                        <div className="unite-detail-div">
                            <div className="icon-div">
                                <StormProbabilityThirdIcon />
                                <label className="number-label">{weatherData["thunderstormprob"]}</label>
                            </div>
                            <div className="text-div">
                                {weatherData["thunderstormprob-name"]}
                            </div>
                        </div>
                    </IF>
                </div>
                <div>
                    <IF show={Boolean(weatherData["thunderstormprob"]) && !isFiveWeatherDay}>
                        <div className="unite-detail-div">
                            <div className="icon-div">
                                <StormProbabilityThirdIcon />
                                <label className="number-label">{weatherData["thunderstormprob"]}</label>
                            </div>
                            <div className="text-div">
                                {weatherData["thunderstormprob-name"]}
                            </div>
                        </div>
                    </IF>
                    <IF show={Boolean(weatherData["Ge_Evapotranspiration"])}>
                        <div className="unite-detail-div">
                            <div className="icon-div">
                                <EvaportalThirdIcon />
                                <label className="number-label">{weatherData["Ge_Evapotranspiration"]}</label>
                            </div>
                            <div className="text-div">
                                {weatherData["Ge_Evapotranspiration-name"]}
                            </div>
                        </div>
                    </IF>
                </div>
            </div>
        </div>
    );
};

export default DailyPreciptation;