import { sagaActionTypes } from "@/redux/actions/diseaseAction";
import { RootState } from "@/redux/reducer/store";
import { useEffect,useState } from "react";
import { useDispatch, useSelector } from "react-redux";

interface IProp{
    countryCode: string;
    language: string;
}

interface IWeatherChartTranslate{
    txt_SelectLocation?: string;
    txt_DownloadAsImage?: string;
    txt_SelYourStartAndEndDate?: string;
    txt_Legend?: string;
    gc_refreshchart?: string;
    ClearSelection?: string;
    gc_EmailPage?: string;
    txt_SelChartType?: string;
    txt_Temperature?: string;
    txt_Precipitaion?: string;
    Altitude?: string;
    Aggregation?: string;
    txt_Select?: string;
    txt_Cumulative?: string;
    txt_SelFromCbxToRefreshChart?: string;
    historicTemperaturTip?: string;
    historicPrecipitationsTip?: string;
    Cumulative_temperature?: string;
    Cumulative_precipitations?: string;
    txt_HistWeatherChart?: string;
    txt_SelectLocationToSeeHistWeather?: string;
     // for email
     gc_EmailHeader?: string;
     gc_EmailTo?: string;
     gc_EmailSubject?: string;
     gc_EmailMessageArea?: string;
     gc_SendEmailButton?: string;
     cancel?: string;
     gc_EmailSendFailed?: string;
     gc_EmailValid?: string;
     gc_EmailRequired?: string;
     gc_EmailSubjectRequired?: string;
     gc_EmailSendSuccess?: string;
     txt_LocationNameLngLat?: string;
     txt_ShareChartByEmail?: string;
     txt_HistWeatherChartMailBody?: string;
}

const useWeatherChartTranslate = (props:IProp)=>{
    const {
        countryCode,
        language
    } = props;
    const dispatch = useDispatch();
    const {
        translateText
    } = useSelector((state: RootState) => state.diseaseReducer);

    const [weacherChartTranslate,setWeatherChartTranslate] = useState<IWeatherChartTranslate>(null);

    const TranslateObj = {
        "txt_SelectLocationToSeeHistWeather": "txt_SelectLocationToSeeHistWeather", // Select your location to see historical weather
        "txt_HistWeatherChart": "txt_HistWeatherChart", // Historical Weather Chart
        "txt_SelectLocation": "txt_SelectLocation", // Select your location to see the accurate weather
        "txt_DownloadAsImage": "txt_DownloadAsImage", // Download as Image
        "txt_SelYourStartAndEndDate": "txt_SelYourStartAndEndDate", // Select your start and end date
        "txt_Legend": "txt_Legend", // Legend
        "gc_refreshchart": "gc_refreshchart", // refresh chart
        "ClearSelection": "ClearSelection", // clear selection
        "gc_EmailPage": "gc_EmailPage", // email this page
        "txt_SelChartType": "txt_SelChartType", // select chart type
        "txt_Temperature": "txt_Temperature", // temperaure
        "txt_Precipitaion": "txt_Precipitaion", // precipitation
        "Altitude": "Altitude", // Location elevation
        "Aggregation": "Aggregation", // aggretation
        "txt_Select": "txt_Select", // select
        "txt_Cumulative": "txt_Cumulative", // Cumulative
        "historicTemperaturTip": "historicTemperaturTip", // Air temperature (°C)
        "historicPrecipitationsTip": "historicPrecipitationsTip", // Precipitation (mm)
        "Cumulative_temperature": "Cumulative_temperature", // Cumulative temperature
        "Cumulative_precipitations": "Cumulative_precipitations", // Cumulative precipitations
        "txt_SelFromCbxToRefreshChart": "txt_SelFromCbxToRefreshChart", // Select from the checkboxes below to add or remove graph parameters, then click refresh chart to display changes
        // used for email
        "gc_EmailHeader": "gc_EmailHeader", // Welcome
        "gc_EmailTo": "gc_EmailTo", // To
        "gc_EmailSubject": "gc_EmailSubject", // Subject
        "gc_EmailMessageArea": "Message", // Message
        "gc_SendEmailButton": "gc_SendEmailButton", // Send Email
        "cancel": "cancel", // Cancel
        "gc_EmailSendFailed": "gc_EmailSendFailed", // Sorry! Eamil failed
        "gc_EmailValid": "gc_EmailValid", // Please enter a valid email id.
        "gc_EmailRequired": "gc_EmailRequired", // Email id is required.
        "gc_EmailSubjectRequired": "gc_EmailSubjectRequired", // Subject is required.
        "gc_EmailSendSuccess": "gc_EmailSendSuccess", // Email sent successfully!
        "txt_LocationNameLngLat": "txt_LocationNameLngLat", // Location name or long,lat
        // for email new content
        "txt_ShareChartByEmail": "txt_ShareChartByEmail", // share this chart by email
        "txt_HistWeatherChartMailBody": "txt_HistWeatherChartMailBody", // email content
    };

    // when get translatetext from api, assign to page item
    const setPageItemToTranslate = ()=>{
        console.log("translateText",translateText);
        const tmpTranslate = {...weacherChartTranslate};
        translateText.forEach((item)=>{
            if(TranslateObj[item.key]) tmpTranslate[TranslateObj[item.key]] = item.value;
        });
        setWeatherChartTranslate(tmpTranslate);
    };

    const getTranslateFunc = ()=>{
        // get translate text by saga, and put response to the redux store
        dispatch({
            type: sagaActionTypes.SAGA_TRANSLATETEXT,
            payload:{
                strCultureCode:language + "-" + countryCode,
                pubname: "glbsip03p"
            }
        });
    };

    useEffect(()=>{
        if(countryCode && language) getTranslateFunc();
    },[countryCode,language]);

    useEffect(()=>{
        if(translateText?.length > 0) setPageItemToTranslate();
    },[translateText]);

    return {
        weacherChartTranslate
    };
};

export default useWeatherChartTranslate;