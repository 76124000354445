// Crop array
export const CropOptions = [
    {
        CropName: "Cereal",
        CropValue: "Cereal",
        CropID: "Cereal",
    },
    {
        CropName: "Potatoes",
        CropValue: "Potato_V2",
        CropID: "Potato_V2",
    }
];

export const DiseaseOptions = [
    {
        "ModelName": "Brown / Leaf Rust",
        "ModelID": "CerealLeafBrownRustPucciniarecondita",
        "ModelDomain": "Cereal",
        "Description": "Cereal disease Type predication on both Histroical and Forecast for Pucciniarecondita",
        "PropertyType": "Output",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in the range of 0-10. 0 = no risk,10 = High"
    },
    {
        "ModelName": "Wheat - Septoria Leaf Blotch Risk",
        "ModelID": "WheatSeptoriaLeafBlotchRisk",
        "ModelDomain": "Cereal",
        "Description": "Ceral disease Type predication on both Histroical and Forecast for Septoria Leaf Blotch disease",
        "PropertyType": "Output",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in 4 numerical values. 0 > no risk,1 > Low, 2 > Medium, 3 > High"
    },
    {
        "ModelName": "Yellow/Stripe Rust",
        "ModelID": "CerealYellowStripeRustPucciniastriiformis",
        "ModelDomain": "Cereal",
        "Description": "Cereal disease Type predication on both Histroical and Forecast for Pucciniastriiformis",
        "PropertyType": "Output",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in the range of 0-10. 0 = no risk,10 = High"
    },

    {
        "ModelName": "Late Blight",
        "ModelID": "PotatoLateBlightPhytophthorainfestans",
        "ModelDomain": "Potato_V2",
        "Description": "Potato disease Type predication on both Histroical and Forecast for Phytophthorainfestans",
        "PropertyType": "Output",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in the range of 0-10. 0 = no risk,10 = High"
    },
];