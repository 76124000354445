import { sagaActionTypes } from "@/redux/actions/diseaseAction";
import { RootState } from "@/redux/reducer/store";
import { getSeries } from "@/util/http/HistWeather";
import { IGetSeriesResModel } from "@/util/http/HistWeather/responseType";
import { useEffect,useState } from "react";
import { useDispatch, useSelector } from "react-redux";

interface IProp{
    countryCode: string;
    language: string;
}

interface IWeatherChartTranslate{
    txt_DownloadAsImage?: string;
    temperaure?: string;
    precipitation?: string;
    selectLocation?: string;
    location_elevation?: string;
    refresh_chart?: string;
    clear_selection?: string;
    email_to_page?: string;
    aggregation?: string;
    select?: string;
    txt_HistWeather?: string;
    txt_SelOptionsAddRemoveGraph?: string;
    txt_AirTemperature?: string;
    txt_AccumulatedGDD?: string;
    txt_GDD?: string;
}

const useWeatherTranslate = (props:IProp)=>{
    const {
        countryCode,
        language
    } = props;
    const dispatch = useDispatch();
    const {
        translateText
    } = useSelector((state: RootState) => state.diseaseReducer);

    const [weacherTranslate,setWeatherTranslate] = useState<IWeatherChartTranslate>(null);

    const TranslateObj = {
        "txt_DownloadAsImage": "txt_DownloadAsImage", // Download as Image
        "txt_Temperature": "temperaure",
        "txt_Precipitaion": "precipitation",
        "txt_SelectLocation": "selectLocation",
        "gc_EmailPage": "email_to_page",
        "Aggregation": "aggregation",
        "txt_Select": "select",
        "txt_HistWeather": "txt_HistWeather", // Historical Weather
        "txt_SelOptionsAddRemoveGraph": "txt_SelOptionsAddRemoveGraph", // Select the options below to add or remove graph paramers
        "txt_AirTemperature": "txt_AirTemperature", // Air Temperature
        "txt_AccumulatedGDD": "txt_AccumulatedGDD", // Accumulated GDD,
        "txt_GDD": "txt_GDD", // Growing Degree Days (GDD)
    };

    // when get translatetext from api, assign to page item
    const setPageItemToTranslate = ()=>{
        console.log("translateText",translateText);
        const tmpTranslate = {...weacherTranslate};
        translateText.forEach((item)=>{
            if(TranslateObj[item.key]) tmpTranslate[TranslateObj[item.key]] = item.value;
        });
        setWeatherTranslate(tmpTranslate);
    };

    const getTranslateFunc = ()=>{
        // get translate text by saga, and put response to the redux store
        dispatch({
            type: sagaActionTypes.SAGA_TRANSLATETEXT,
            payload:{
                strCultureCode:language + "-" + countryCode,
                pubname: "glbsip03p"
            }
        });
    };

    useEffect(()=>{
        if(countryCode && language) getTranslateFunc();
    },[countryCode,language]);

    useEffect(()=>{
        if(translateText?.length > 0) setPageItemToTranslate();
    },[translateText]);

    return {
        weacherTranslate
    };
};

export default useWeatherTranslate;