// Crop array
export const CropOptions = [
    {
        CropName: "Apples",
        CropValue: "Apples",
        CropID: "Apples",
    },
    {
        CropName: "Barley",
        CropValue: "Barley",
        CropID: "Cereal",
    },
    {
        CropName: "Canola",
        CropValue: "Canola",
        CropID: "Cereal",
    },
    {
        CropName: "Grapes",
        CropValue: "Grapes",
        CropID: "Grapes",
    },
    {
        CropName: "Maize",
        CropValue: "Maize",
        CropID: "Cereal",
    },
    {
        CropName: "Oats",
        CropValue: "Oats",
        CropID: "Cereal",
    },
    {
        CropName: "Pears",
        CropValue: "Pears",
        CropID: "Cereal",
    },
    {
        CropName: "Potatoes",
        CropValue: "Potatoes",
        CropID: "Potato_V2",
    },
    {
        CropName: "Rye",
        CropValue: "Rye",
        CropID: "Cereal",
    },
    {
        CropName: "Sunflower",
        CropValue: "Sunflower",
        CropID: "Corn",
    },
    {
        CropName: "Triticale",
        CropValue: "Triticale",
        CropID: "Cereal",
    },
    {
        CropName: "Wheat",
        CropValue: "Wheat",
        CropID: "Cereal",
    }
];

export const DiseaseOptions = [
    {
        "ModelName": "Apple Scab / Fusi",
        "ModelID": "AppleScabFusicladiumVenturiainaequalis",
        "ModelDomain": "Apples",
        "Description": "Greencast disease Type predication on both Histroical and Forecast for Antracnose Foliar Blight",
        "PropertyType": "Apples",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in 5 numerical values. 0 > no risk,1 > Very low, 2 > Low, 3 > Medium, 4 > High"
    },
    {
        "ModelName": "Blossom or Fire Blight",
        "ModelID": "AppleBlossomFireBlightErwiniaamylovora",
        "ModelDomain": "Apples",
        "Description": "Greencast disease Type predication on both Histroical and Forecast for Antracnose Foliar Blight",
        "PropertyType": "Apples",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in 5 numerical values. 0 > no risk,1 > Very low, 2 > Low, 3 > Medium, 4 > High"
    },


    {
        "ModelName": "Yellow (Stripe) Rust",
        "ModelID": "CerealYellowStripeRustPucciniastriiformis_RU",
        "ModelDomain": "Barley",
        "Description": "Greencast disease Type predication on both Histroical and Forecast for Antracnose Foliar Blight",
        "PropertyType": "Cereal",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in 5 numerical values. 0 > no risk,1 > Very low, 2 > Low, 3 > Medium, 4 > High"
    },
    {
        "ModelName": "Powdery Mildew",
        "ModelID": "CerealPowderyMildewEryspihegraminis_RU",
        "ModelDomain": "Barley",
        "Description": "Greencast disease Type predication on both Histroical and Forecast for Antracnose Foliar Blight",
        "PropertyType": "Cereal",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in 5 numerical values. 0 > no risk,1 > Very low, 2 > Low, 3 > Medium, 4 > High"
    },
    {
        "ModelName": "Eyespot",
        "ModelID": "CeralPseudocercosporellaherpotrichoides_RU",
        "ModelDomain": "Barley",
        "Description": "Greencast disease Type predication on both Histroical and Forecast for Antracnose Foliar Blight",
        "PropertyType": "Cereal",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in 5 numerical values. 0 > no risk,1 > Very low, 2 > Low, 3 > Medium, 4 > High"
    },
    {
        "ModelName": "Septoria Leaf and Glume Blotch",
        "ModelID": "CerealSeptoriaLeafGlumeBlotchStagonosporaNodorum_RU",
        "ModelDomain": "Barley",
        "Description": "Greencast disease Type predication on both Histroical and Forecast for Antracnose Foliar Blight",
        "PropertyType": "Cereal",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in 5 numerical values. 0 > no risk,1 > Very low, 2 > Low, 3 > Medium, 4 > High"
    },
    {
        "ModelName": "Net Blotch",
        "ModelID": "BarleyNetBlotchPyrenophoraTeres_RU",
        "ModelDomain": "Barley",
        "Description": "Greencast disease Type predication on both Histroical and Forecast for Antracnose Foliar Blight",
        "PropertyType": "Cereal",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in 5 numerical values. 0 > no risk,1 > Very low, 2 > Low, 3 > Medium, 4 > High"
    },
    {
        "ModelName": "Brown Rust, Leaf Rust",
        "ModelID": "CerealLeafBrownRustPucciniarecondita",
        "ModelDomain": "Barley",
        "Description": "Greencast disease Type predication on both Histroical and Forecast for Antracnose Foliar Blight",
        "PropertyType": "Cereal",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in 5 numerical values. 0 > no risk,1 > Very low, 2 > Low, 3 > Medium, 4 > High"
    },


    {
        "ModelName": "Blackleg",
        "ModelID": "CanolaBlacklegLeptosphaeriamaculans_RU",
        "ModelDomain": "Canola",
        "Description": "Greencast disease Type predication on both Histroical and Forecast for Antracnose Foliar Blight",
        "PropertyType": "Cereal",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in 5 numerical values. 0 > no risk,1 > Very low, 2 > Low, 3 > Medium, 4 > High"
    },
    {
        "ModelName": "Sclerotinia Stem Rot",
        "ModelID": "CanolaSclerotiniaApothecia_RU",
        "ModelDomain": "Canola",
        "Description": "Greencast disease Type predication on both Histroical and Forecast for Antracnose Foliar Blight",
        "PropertyType": "Cereal",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in 5 numerical values. 0 > no risk,1 > Very low, 2 > Low, 3 > Medium, 4 > High"
    },
    {
        "ModelName": "Sclerotinia Stem Rot",
        "ModelID": "CanolaSclerotiniaInfection_RU",
        "ModelDomain": "Canola",
        "Description": "Greencast disease Type predication on both Histroical and Forecast for Antracnose Foliar Blight",
        "PropertyType": "Cereal",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in 5 numerical values. 0 > no risk,1 > Very low, 2 > Low, 3 > Medium, 4 > High"
    },



    {
        "ModelName": "Powdery Mildew",
        "ModelID": "GrapesPowderyMildewUncinulanecator",
        "ModelDomain": "Grapes",
        "Description": "Greencast disease Type predication on both Histroical and Forecast for Antracnose Foliar Blight",
        "PropertyType": "Grapes",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in 5 numerical values. 0 > no risk,1 > Very low, 2 > Low, 3 > Medium, 4 > High"
    },
    {
        "ModelName": "Botrytis greymould",
        "ModelID": "GrapesBotrytiscinerea",
        "ModelDomain": "Grapes",
        "Description": "Greencast disease Type predication on both Histroical and Forecast for Antracnose Foliar Blight",
        "PropertyType": "Grapes",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in 5 numerical values. 0 > no risk,1 > Very low, 2 > Low, 3 > Medium, 4 > High"
    },
    {
        "ModelName": "Downy Mildew",
        "ModelID": "GrapesDownyMildewPlasmoparaviticola",
        "ModelDomain": "Grapes",
        "Description": "Greencast disease Type predication on both Histroical and Forecast for Antracnose Foliar Blight",
        "PropertyType": "Grapes",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in 5 numerical values. 0 > no risk,1 > Very low, 2 > Low, 3 > Medium, 4 > High"
    },
    {
        "ModelName": "Anthracnose black spot",
        "ModelID": "GrapesAnthracnoseblackspot",
        "ModelDomain": "Grapes",
        "Description": "Greencast disease Type predication on both Histroical and Forecast for Antracnose Foliar Blight",
        "PropertyType": "Grapes",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in 5 numerical values. 0 > no risk,1 > Very low, 2 > Low, 3 > Medium, 4 > High"
    },



    {
        "ModelName": "Northern corn leaf blight (NCLB)",
        "ModelID": "CornNortherncornleafblightNCLB_RU",
        "ModelDomain": "Maize",
        "Description": "Greencast disease Type predication on both Histroical and Forecast for Antracnose Foliar Blight",
        "PropertyType": "Corn",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in 5 numerical values. 0 > no risk,1 > Very low, 2 > Low, 3 > Medium, 4 > High"
    },
    {
        "ModelName": "Fusarium Head Blight",
        "ModelID": "CornFusariumHeadBlight",
        "ModelDomain": "Maize",
        "Description": "Greencast disease Type predication on both Histroical and Forecast for Antracnose Foliar Blight",
        "PropertyType": "Corn",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in 5 numerical values. 0 > no risk,1 > Very low, 2 > Low, 3 > Medium, 4 > High"
    },



    {
        "ModelName": "Powdery Mildew",
        "ModelID": "CerealPowderyMildewEryspihegraminis_RU",
        "ModelDomain": "Oats",
        "Description": "Greencast disease Type predication on both Histroical and Forecast for Antracnose Foliar Blight",
        "PropertyType": "Cereal",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in 5 numerical values. 0 > no risk,1 > Very low, 2 > Low, 3 > Medium, 4 > High"
    },
    {
        "ModelName": "Eyespot",
        "ModelID": "CeralPseudocercosporellaherpotrichoides_RU",
        "ModelDomain": "Oats",
        "Description": "Greencast disease Type predication on both Histroical and Forecast for Antracnose Foliar Blight",
        "PropertyType": "Cereal",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in 5 numerical values. 0 > no risk,1 > Very low, 2 > Low, 3 > Medium, 4 > High"
    },
    {
        "ModelName": "Crown Rust",
        "ModelID": "OatsCrownRustPucciniaCoronata_RU",
        "ModelDomain": "Oats",
        "Description": "Greencast disease Type predication on both Histroical and Forecast for Antracnose Foliar Blight",
        "PropertyType": "Cereal",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in 5 numerical values. 0 > no risk,1 > Very low, 2 > Low, 3 > Medium, 4 > High"
    },


    {
        "ModelName": "Blossom or Fire Blight",
        "ModelID": "AppleBlossomFireBlightErwiniaamylovora",
        "ModelDomain": "Pears",
        "Description": "Greencast disease Type predication on both Histroical and Forecast for Antracnose Foliar Blight",
        "PropertyType": "Apples",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in 5 numerical values. 0 > no risk,1 > Very low, 2 > Low, 3 > Medium, 4 > High"
    },



    {
        "ModelName": "Alternariasolani",
        "ModelID": "PotatoAlternariasolani",
        "ModelDomain": "Potatoes",
        "Description": "Potato disease Type predication on both historical and Frecast for Alternariasolani for Russia",
        "PropertyType": "Output",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in the range of 0-10. 0 = no risk,10 = High"
    },
    {
        "ModelName": "Potato Early Blight",
        "ModelID": "PotatoEarlyBlightAlternariasolani",
        "ModelDomain": "Potatoes",
        "Description": "Greencast disease Type predication on both Histroical and Forecast for Antracnose Foliar Blight",
        "PropertyType": "Potato_V2",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in 5 numerical values. 0 > no risk,1 > Very low, 2 > Low, 3 > Medium, 4 > High"
    },
    {
        "ModelName": "PotatoPhytophotraInfestans",
        "ModelID": "PotatoPhytophotraInfestans_RU",
        "ModelDomain": "Potatoes",
        "Description": "Potato disease Type predication on both historical and Frecast for PhytophotraInfestans for Russia",
        "PropertyType": "Output",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in the range of 0-10. 0 = no risk,10 = High"
    },
    {
        "ModelName": "Potato Botrytis (Grey mould)",
        "ModelID": "PotatoBotrytiscinerea",
        "ModelDomain": "Potatoes",
        "Description": "Greencast disease Type predication on both Histroical and Forecast for Antracnose Foliar Blight",
        "PropertyType": "Potato_V2",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in 5 numerical values. 0 > no risk,1 > Very low, 2 > Low, 3 > Medium, 4 > High"
    },
    {
        "ModelName": "Sclerotinia rot",
        "ModelID": "PotatoSclerotiniarotSclerotiniasclerotiorum",
        "ModelDomain": "Potatoes",
        "Description": "Greencast disease Type predication on both Histroical and Forecast for Antracnose Foliar Blight",
        "PropertyType": "Potato_V2",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in 5 numerical values. 0 > no risk,1 > Very low, 2 > Low, 3 > Medium, 4 > High"
    },
    {
        "ModelName": "Sclerotinia wilt",
        "ModelID": "PotatoSclerotiniawiltSclerotiniarolfsii",
        "ModelDomain": "Potatoes",
        "Description": "Greencast disease Type predication on both Histroical and Forecast for Antracnose Foliar Blight",
        "PropertyType": "Potato_V2",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in 5 numerical values. 0 > no risk,1 > Very low, 2 > Low, 3 > Medium, 4 > High"
    },
    {
        "ModelName": "Silver scurf",
        "ModelID": "PotatoSilverscurfHelminthosporiumsolani",
        "ModelDomain": "Potatoes",
        "Description": "Greencast disease Type predication on both Histroical and Forecast for Antracnose Foliar Blight",
        "PropertyType": "Potato_V2",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in 5 numerical values. 0 > no risk,1 > Very low, 2 > Low, 3 > Medium, 4 > High"
    },
    {
        "ModelName": "Verticillium Wilt",
        "ModelID": "PotatoVerticilliumWiltVerticilliumdahliae",
        "ModelDomain": "Potatoes",
        "Description": "Greencast disease Type predication on both Histroical and Forecast for Antracnose Foliar Blight",
        "PropertyType": "Potato_V2",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in 5 numerical values. 0 > no risk,1 > Very low, 2 > Low, 3 > Medium, 4 > High"
    },





    {
        "ModelName": "Powdery Mildew",
        "ModelID": "CerealPowderyMildewEryspihegraminis_RU",
        "ModelDomain": "Rye",
        "Description": "Greencast disease Type predication on both Histroical and Forecast for Antracnose Foliar Blight",
        "PropertyType": "Cereal",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in 5 numerical values. 0 > no risk,1 > Very low, 2 > Low, 3 > Medium, 4 > High"
    },
    {
        "ModelName": "Eyespot",
        "ModelID": "CeralPseudocercosporellaherpotrichoides_RU",
        "ModelDomain": "Rye",
        "Description": "Greencast disease Type predication on both Histroical and Forecast for Antracnose Foliar Blight",
        "PropertyType": "Cereal",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in 5 numerical values. 0 > no risk,1 > Very low, 2 > Low, 3 > Medium, 4 > High"
    },
    {
        "ModelName": "Septoria Leaf Blotch (Spot)",
        "ModelID": "WheatSeptoriaLeafBlotchRisk_RU",
        "ModelDomain": "Rye",
        "Description": "Greencast disease Type predication on both Histroical and Forecast for Antracnose Foliar Blight",
        "PropertyType": "Cereal",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in 5 numerical values. 0 > no risk,1 > Very low, 2 > Low, 3 > Medium, 4 > High"
    },
    {
        "ModelName": "Fusarium Head Blight",
        "ModelID": "WheatFusariumHeadBlight_RU",
        "ModelDomain": "Rye",
        "Description": "Greencast disease Type predication on both Histroical and Forecast for Antracnose Foliar Blight",
        "PropertyType": "Cereal",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in 5 numerical values. 0 > no risk,1 > Very low, 2 > Low, 3 > Medium, 4 > High"
    },
    {
        "ModelName": "Septoria Leaf and Glume Blotch",
        "ModelID": "CerealSeptoriaLeafGlumeBlotchStagonosporaNodorum_RU",
        "ModelDomain": "Rye",
        "Description": "Greencast disease Type predication on both Histroical and Forecast for Antracnose Foliar Blight",
        "PropertyType": "Cereal",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in 5 numerical values. 0 > no risk,1 > Very low, 2 > Low, 3 > Medium, 4 > High"
    },

    {
        "ModelName": "Rust Aeciospore",
        "ModelID": "SunflowerRustAeciospore",
        "ModelDomain": "Sunflower",
        "Description": "Corn disease Type predication on both historical and Frecast for SunflowerRustUrediniospore",
        "PropertyType": "Corn",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in the range of 0-10. 0 = no risk,10 = High"
    },
    {
        "ModelName": "Rust Urediniospore",
        "ModelID": "SunflowerRustUrediniospore",
        "ModelDomain": "Sunflower",
        "Description": "Corn disease Type predication on both historical and Frecast for SunflowerRustUrediniospore",
        "PropertyType": "Corn",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in the range of 0-10. 0 = no risk,10 = High"
    },


    {
        "ModelName": "Yellow (Stripe) Rust",
        "ModelID": "CerealYellowStripeRustPucciniastriiformis_RU",
        "ModelDomain": "Triticale",
        "Description": "Greencast disease Type predication on both Histroical and Forecast for Antracnose Foliar Blight",
        "PropertyType": "Cereal",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in 5 numerical values. 0 > no risk,1 > Very low, 2 > Low, 3 > Medium, 4 > High"
    },
    {
        "ModelName": "Powdery Mildew",
        "ModelID": "CerealPowderyMildewEryspihegraminis_RU",
        "ModelDomain": "Triticale",
        "Description": "Greencast disease Type predication on both Histroical and Forecast for Antracnose Foliar Blight",
        "PropertyType": "Cereal",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in 5 numerical values. 0 > no risk,1 > Very low, 2 > Low, 3 > Medium, 4 > High"
    },
    {
        "ModelName": "Eyespot",
        "ModelID": "CeralPseudocercosporellaherpotrichoides_RU",
        "ModelDomain": "Triticale",
        "Description": "Greencast disease Type predication on both Histroical and Forecast for Antracnose Foliar Blight",
        "PropertyType": "Cereal",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in 5 numerical values. 0 > no risk,1 > Very low, 2 > Low, 3 > Medium, 4 > High"
    },
    {
        "ModelName": "Septoria Leaf Blotch (Spot)",
        "ModelID": "WheatSeptoriaLeafBlotchRisk_RU",
        "ModelDomain": "Triticale",
        "Description": "Greencast disease Type predication on both Histroical and Forecast for Antracnose Foliar Blight",
        "PropertyType": "Cereal",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in 5 numerical values. 0 > no risk,1 > Very low, 2 > Low, 3 > Medium, 4 > High"
    },
    {
        "ModelName": "Fusarium Head Blight",
        "ModelID": "WheatFusariumHeadBlight_RU",
        "ModelDomain": "Triticale",
        "Description": "Greencast disease Type predication on both Histroical and Forecast for Antracnose Foliar Blight",
        "PropertyType": "Cereal",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in 5 numerical values. 0 > no risk,1 > Very low, 2 > Low, 3 > Medium, 4 > High"
    },


    {
        "ModelName": "Yellow (Stripe) Rust",
        "ModelID": "CerealYellowStripeRustPucciniastriiformis_RU",
        "ModelDomain": "Wheat",
        "Description": "Greencast disease Type predication on both Histroical and Forecast for Antracnose Foliar Blight",
        "PropertyType": "Cereal",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in 5 numerical values. 0 > no risk,1 > Very low, 2 > Low, 3 > Medium, 4 > High"
    },
    {
        "ModelName": "Powdery Mildew",
        "ModelID": "CerealPowderyMildewEryspihegraminis_RU",
        "ModelDomain": "Wheat",
        "Description": "Greencast disease Type predication on both Histroical and Forecast for Antracnose Foliar Blight",
        "PropertyType": "Cereal",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in 5 numerical values. 0 > no risk,1 > Very low, 2 > Low, 3 > Medium, 4 > High"
    },
    {
        "ModelName": "Eyespot",
        "ModelID": "CeralPseudocercosporellaherpotrichoides_RU",
        "ModelDomain": "Wheat",
        "Description": "Greencast disease Type predication on both Histroical and Forecast for Antracnose Foliar Blight",
        "PropertyType": "Cereal",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in 5 numerical values. 0 > no risk,1 > Very low, 2 > Low, 3 > Medium, 4 > High"
    },
    {
        "ModelName": "Septoria Leaf Blotch (Spot)",
        "ModelID": "WheatSeptoriaLeafBlotchRisk_RU",
        "ModelDomain": "Wheat",
        "Description": "Greencast disease Type predication on both Histroical and Forecast for Antracnose Foliar Blight",
        "PropertyType": "Cereal",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in 5 numerical values. 0 > no risk,1 > Very low, 2 > Low, 3 > Medium, 4 > High"
    },
    {
        "ModelName": "Fusarium Head Blight",
        "ModelID": "WheatFusariumHeadBlight_RU",
        "ModelDomain": "Wheat",
        "Description": "Greencast disease Type predication on both Histroical and Forecast for Antracnose Foliar Blight",
        "PropertyType": "Cereal",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in 5 numerical values. 0 > no risk,1 > Very low, 2 > Low, 3 > Medium, 4 > High"
    },
    {
        "ModelName": "Septoria Leaf and Glume Blotch",
        "ModelID": "CerealSeptoriaLeafGlumeBlotchStagonosporaNodorum_RU",
        "ModelDomain": "Wheat",
        "Description": "Greencast disease Type predication on both Histroical and Forecast for Antracnose Foliar Blight",
        "PropertyType": "Cereal",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in 5 numerical values. 0 > no risk,1 > Very low, 2 > Low, 3 > Medium, 4 > High"
    },
    {
        "ModelName": "Black (Stem) Rust",
        "ModelID": "WheatBlackStemRustPucciniagraminis_RU",
        "ModelDomain": "Wheat",
        "Description": "Greencast disease Type predication on both Histroical and Forecast for Antracnose Foliar Blight",
        "PropertyType": "Cereal",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in 5 numerical values. 0 > no risk,1 > Very low, 2 > Low, 3 > Medium, 4 > High"
    },
    {
        "ModelName": "Brown Rust, Leaf Rust",
        "ModelID": "CerealLeafBrownRustPucciniarecondita",
        "ModelDomain": "Wheat",
        "Description": "Greencast disease Type predication on both Histroical and Forecast for Antracnose Foliar Blight",
        "PropertyType": "Cereal",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in 5 numerical values. 0 > no risk,1 > Very low, 2 > Low, 3 > Medium, 4 > High"
    },
    {
        "ModelName": "Pyrenophra Tritici Repentis ",
        "ModelID": "WheatPyrenophraTriticiRepentis_RU",
        "ModelDomain": "Wheat",
        "Description": "Cereal disease Type predication on both historical and Frecast for PyrenophraTriticiRepentis for Russia",
        "PropertyType": "Output",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in the range of 0-10. 0 = no risk,10 = High"
    }

];