import React from "react";
import "./index.less";
import {
    CloudCoverThirdIcon,
    EvaportalThirdIcon,
    PrecipltationRiskThirdIcon,
    PrecipltationThirdIcon,
    RelativeHumidityFourthIcon,
    ShowerRiskThirdIcon,
    StormProbabilityThirdIcon,
} from "@/resource/IconComponents";
import IF from "@/components/IF";

interface IProps {
    include?: {
        [keyValue: string]: boolean
    }
    weatherData?: {
        [keyValu: string]: string
    }
    indent?: boolean
    justShowIcon?: boolean
}


const HourlyThird = (props: IProps) => {
    const {
        include,
        weatherData,
        indent,
        justShowIcon
    } = props;

    return (
        <div className={`ag-third-div ${indent && "indent-container-class"}`}>
            <IF show={!justShowIcon}>
                <IF show={include["precipitationinch"] || include["precipitation"] || (!indent && Boolean(weatherData["precipitationinch"]))}>
                    <div className="first-div">
                        <IF show={!indent}>
                            <div>
                                <PrecipltationThirdIcon />
                            </div>
                            <label className="second-label">
                                {weatherData["precipitationinch-name"]}
                                {weatherData["precipitation-name"]}

                            </label>
                        </IF>
                        <label className="third-label">
                            {weatherData.precipitationinch}
                            {weatherData.precipitation}

                        </label>
                    </div>
                </IF>
                <IF show={include["dd_precipitation_risk"] || (!indent && Boolean(weatherData["dd_precipitation_risk"]))}>
                    <div className="second-div">
                        <IF show={!indent}>
                            <div>
                                <PrecipltationRiskThirdIcon />
                            </div>
                            <label className="second-label">
                                {weatherData["dd_precipitation_risk-name"]}
                            </label>
                        </IF>
                        <label className="third-label">
                            {weatherData["dd_precipitation_risk"]}
                        </label>
                    </div>
                </IF>
                <IF show={include["CRiskShower"] || (!indent && Boolean(weatherData["CRiskShower"]))}>
                    <div className="third-div">
                        <IF show={!indent}>
                            <div>
                                <ShowerRiskThirdIcon />
                            </div>
                            <label className="second-label">
                                {weatherData["CRiskShower-name"]}
                            </label>
                        </IF>
                        <label className="third-label">
                            {weatherData["CRiskShower"]}
                        </label>
                    </div>
                </IF>
                <IF show={include["cloudiness"] || (!indent && Boolean(weatherData["cloudiness"]))}>
                    <div>
                        <IF show={!indent}>
                            <div>
                                <CloudCoverThirdIcon />
                            </div>
                            <label className="second-label">
                                {weatherData["cloudiness-name"]}
                            </label>
                        </IF>
                        <label className="third-label">
                            {weatherData["cloudiness"]}
                        </label>
                    </div>
                </IF>
                <IF show={include["thunderstormprob"] || (!indent && Boolean(weatherData["thunderstormprob"]))}>
                    <div>
                        <IF show={!indent}>
                            <div>
                                <StormProbabilityThirdIcon />
                            </div>
                            <label className="second-label">
                                {weatherData["thunderstormprob-name"]}
                            </label>
                        </IF>
                        <label className="third-label">
                            {weatherData["thunderstormprob"]}
                        </label>
                    </div>
                </IF>
                <IF show={include["Ge_Evapotranspiration"] || (!indent && Boolean(weatherData["Ge_Evapotranspiration"]))}>
                    <div>
                        <IF show={!indent}>
                            <div>
                                <EvaportalThirdIcon />
                            </div>
                            <label className="second-label">
                                {weatherData["Ge_Evapotranspiration-name"]}
                            </label>
                        </IF>
                        <label className="third-label">
                            {weatherData["Ge_Evapotranspiration"]}
                        </label>
                    </div>
                </IF>
                <IF show={include["relhumidity"] || (!indent && Boolean(weatherData["relhumidity"]))}>
                    <div className="first-div">
                        <IF show={!indent}>
                            <div>
                                <RelativeHumidityFourthIcon />
                            </div>
                            <label className="second-label">
                                {weatherData["relhumidity-name"]}
                            </label>
                        </IF>
                        <label className="third-label">
                            {weatherData["relhumidity"]}
                        </label>
                    </div>
                </IF>
            </IF>
            <IF show={justShowIcon}>
                <IF show={include["precipitationinch"] || include["precipitation"]}>
                    <div>
                        <PrecipltationThirdIcon />
                    </div>
                </IF>
                <IF show={include["dd_precipitation_risk"]}>
                    <div>
                        <PrecipltationRiskThirdIcon />
                    </div>
                </IF>
                <IF show={include["CRiskShower"]}>
                    <div>
                        <ShowerRiskThirdIcon />
                    </div>
                </IF>
                <IF show={include["cloudiness"]}>
                    <div>
                        <CloudCoverThirdIcon />
                    </div>
                </IF>
                <IF show={include["thunderstormprob"]}>
                    <div>
                        <StormProbabilityThirdIcon />
                    </div>
                </IF>
                <IF show={include["Ge_Evapotranspiration"]}>
                    <div>
                        <EvaportalThirdIcon />
                    </div>
                </IF>
                <IF show={justShowIcon && include["relhumidity"]}>
                    <div>
                        <RelativeHumidityFourthIcon />
                    </div>
                </IF>
            </IF>
        </div>
    );
};

export default HourlyThird;