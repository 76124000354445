import React, { useEffect, useState } from "react";
import * as echarts from "echarts";
import { useDispatch, useSelector } from "react-redux";
import { List, Modal, Select, Spin, Tabs, TabsProps } from "antd";
import { useLocation } from "react-router-dom";
import ReactEcharts from "echarts-for-react";
import { RootState } from "@/redux/reducer/store";
import "./index.less";
import Search from "@/page/Weather/ForecastWeatherData/components/Search";
import {
    sagaActionTypes,
    searchTargetCoordinate,
    showAddBookmarkFailedFunc,
    showAddBookmarkSuccessFunc,
    updateParamModel,
    updateWeatherForcastdailyList
} from "@/redux/actions/commonAction";
import {
    BookMarkIcon,
    TurnLeftIcon,
    TurnRightIcon,
    WaterFallIcon,
    WindSpeedIcon
} from "@/resource/IconComponents";
import { ILocationModel, IWeatherForcastDailyListResModel } from "@/util/http/responseType";
import WeatherCard from "@/page/Weather/ForecastWeatherData/components/WeatherCard";
import IF from "@/components/IF";
import InfoCard from "../TurfForecastWeatherData/InfoCard";
import ForcastButton from "@/components/ForecastProviderButton";
import Sunny from "@/resource/images/png/sunny.png";
import { API_LOCAL_URL } from "@/util/http/config";
import { Formatter, transferDateToTargetFormater } from "@/util/commonFunction";
import useHourlyTabs from "./components/Hooks/useHourlyTabsForOrnamentals";
import {checkServiceTokenIsValid} from "util/http/index";

const OranmentalsWeather = () => {

    const dispatch = useDispatch();
    const urlLocation = useLocation();

    const {
        locations,
        bookmarks,
        weatherForcastDailyList,
        localDailyWidgetWeather,
        currentLanguage,
        chartData,
        cropsItems,
        cropsSpray,
        topInfoCardLoading,
        weatherListLoading,
        sprayLoading,
        paramModel,
        fiveWeather24HoursData,
        fiveWeather8HoursData,
        fiveWeatherDayData,
        keyTurfData,
        cropsSprayGround,
        cropsSprayGoundWithNoggle,
        disease,
        showAddBookSuccess,
        showAddBookFailed,
        searchCoordinateResult,
        serviceToken,
        searchByPlace,
        searchByPlaceResult
    } = useSelector((state: RootState) => state.commonReducer);

    console.log("lanxue serviceToken",serviceToken);
    // add google analysis
    useEffect(() => {
        console.log("just invoke once");
        const head = document.getElementsByTagName("head")[0];
        const googleAnalyticsCode = "G-ESSTQ0GEN8";
        const googleAnalyticsFirstScript = document.createElement("script");
        googleAnalyticsFirstScript.async = true;
        googleAnalyticsFirstScript.src =
          "https://www.googletagmanager.com/gtag/js?id=" + googleAnalyticsCode;

        const googleAnalyticsSecondScript = document.createElement("script");
        googleAnalyticsSecondScript.innerHTML =
          "    window.dataLayer = window.dataLayer || [];\n" +
          "    function gtag(){dataLayer.push(arguments);}\n" +
          "    gtag('js', new Date());\n" +
          "\n" +
          "    gtag('config', '" +
          googleAnalyticsCode +
          "',{\n" +
          "cookie_flags:'max-age=7200;secure;samesite-none'" +
          "});";

        head.insertBefore(googleAnalyticsSecondScript, head.firstChild);
        head.insertBefore(googleAnalyticsFirstScript, head.firstChild);
    }, []);

    const checkServiceTokenIsValidFunc = async()=>{
        try{
            const res = await checkServiceTokenIsValid(paramModel.security);
            setShowPageByToken(res.data);
            if(!res.data){
                const element1 = document.querySelector(".dark-theme") as HTMLElement;
                if(element1){
                    element1.style.backgroundColor = "white";
                }
            }
        }
        catch(error){
            console.log("error",error);
        }
    };

    const [showPageByToken,setShowPageByToken] = useState(true);

    useEffect(()=>{
        if(paramModel?.security === "no-value" || serviceToken === "no-value"){
            setShowPageByToken(false);
        }
        if(serviceToken && serviceToken!= "no-value"){
            checkServiceTokenIsValidFunc();
        }
    },[serviceToken,paramModel?.security]);
    // useEffect(()=>{
    //     if(serviceToken){
    //         checkServiceTokenIsValidFunc();

    //     }
    // },[serviceToken]);



    const [inputText, setInputText] = useState("");
    const [locationListShow, setLocationListShow] = useState(false);
    const [currentLocation, setCurrentLocation] = useState<ILocationModel>();
    const [showGraphicChart, setShowGraphicChart] = useState(false);
    const [currentWeatherDate, setCurrentWeatherDate] = useState<IWeatherForcastDailyListResModel>();
    const [keyTurfGrowTabs, setKeyTurfGrowTabs] = useState<TabsProps["items"]>();
    const [currentIndex, setCurrentIndex] = useState(0);
    const [date, setDate] = useState<string>();


    const coordinateButtonClicked = () => {
        setSelectPlaceName("");
        navigator.geolocation.getCurrentPosition(
            (position: GeolocationPosition) => {
                dispatch({ type: sagaActionTypes.SAGA_COORDINATE_SEARCH, payload: {
                    searchText:`${position.coords.longitude},${position.coords.latitude}`}
                });
            },
            (err) => {
                console.log("test get current location error", err);
            }
        );
        // require to connect to vpn
    };

    const addBookmarkButtonClicked = () => {
        dispatch({ type: sagaActionTypes.SAGA_ADD_BOOKMARK, payload: {
            // eslint-disable-next-line max-len
            securityStr: paramModel.security,
            currentLocation,
            emailid:paramModel.emailid
        }});
    };

    useEffect(() => {
        if(searchByPlaceResult){
            setCurrentLocation(locations[0]);
            setInputText(locations[0].AdminName._text.split(",")[0]);
        }
        else{
            if (locations.length > 0 && locations.length !== 1) {
                setLocationListShow(true);
            }
            if (locations.length === 1) {
                setCurrentLocation(locations[0]);
                setInputText(locations[0].AdminName._text.split(",")[0]);
            }
        }

    }, [locations,searchByPlaceResult]);

    useEffect(()=>{
        if(searchByPlace){
            if (paramModel.latitude) {
                dispatch({
                    type: sagaActionTypes.SAGA_COORDINATE_SEARCH,
                    payload: {searchText:`${paramModel.longitude},${paramModel.latitude}`}
                });
            }
        }
    },[searchByPlace]);


    useEffect(() => {
        if (currentLocation) {
            dispatch({
                type: sagaActionTypes.SAGA_GET_WEATHERFORCAST_DAILY_LIST,
                payload: {
                    ...currentLocation,
                    cultureCode:  `${paramModel.language}-${paramModel.countryCode}`,
                    numberOfDays: paramModel.numberOfDays,
                    isGreencastForecast: true
                }});
            dispatch({
                type: sagaActionTypes.SAGA_GET_LOCAL_HOURLY_WIDGET,
                payload: {
                    ...currentLocation,
                    cultureCode:  `${paramModel.language}-${paramModel.countryCode}`,
                    isNowcast: true,
                    isGreencastForecast: true
                }});
            dispatch({ type: sagaActionTypes.SAGA_GET_CHART_DATA, payload: {
                latitude: parseFloat(currentLocation.Latitude._text),
                longitude: parseFloat(currentLocation.Longitude._text),
                cultureCode: `${paramModel.language}-${paramModel.countryCode}`,
                numberOfDays: paramModel.numberOfDays,
                unit: paramModel.unit,
                isGreencastForecast: true
            }});

            dispatch({ type: sagaActionTypes.SAGA_FIVE_GET_AGGREGATED_WEATHER, payload: {
                cultureCode: `${paramModel.language}-${paramModel.countryCode}`,
                latitude: paramModel.latitude,
                longitude: paramModel.longitude,
                numberOfDays: paramModel.numberOfDays,
                unit: paramModel.unit,
                isForFiveDaysWeather: true,
                isGreencastForecast: true
            }});

            dispatch({ type: sagaActionTypes.SAGA_GET_SERVER_WARNING, payload: {
                cultureCode: `${paramModel.language}-${paramModel.countryCode}`,
                latitude: parseFloat(currentLocation.Latitude._text),
                longitude: parseFloat(currentLocation.Longitude._text),
                modelName: paramModel.modelName,
                numberOfDays: paramModel.numberOfDays,
                isGreencastForecast: true
            }});
            // request for sub circle
            dispatch({ type: sagaActionTypes.SAGA_GET_KEY_TURF_GROWTH_DATA, payload: {
                cultureCode: `${paramModel.language}-${paramModel.countryCode}`,
                latitude: parseFloat(currentLocation.Latitude._text),
                longitude: parseFloat(currentLocation.Longitude._text),
                modelName: paramModel.modelName,
                numberOfDays: paramModel.numberOfDays,
                unit: paramModel.unit,
                isGreencastForecast: true
            }});
            // request for chart
            dispatch({ type: sagaActionTypes.SAGA_GET_FROST_WARNING, payload: {
                cultureCode: `${paramModel.language}-${paramModel.countryCode}`,
                latitude: parseFloat(currentLocation.Latitude._text),
                longitude: parseFloat(currentLocation.Longitude._text),
                modelName: paramModel.modelName,
                numberOfDays: paramModel.numberOfDays
            }});
            dispatch({ type: sagaActionTypes.SAGA_GET_GROUND_SPRAYING, payload: {
                cultureCode: `${paramModel.language}-${paramModel.countryCode}`,
                latitude: parseFloat(currentLocation.Latitude._text),
                longitude: parseFloat(currentLocation.Longitude._text),
                modelName: "gc_sw_groundspraygeneric",
                numberOfDays: paramModel.numberOfDays,
                isGreencastForecast: true
            }});
            dispatch({ type: sagaActionTypes.SAGA_GET_GROUND_SPRAYING_WITH_NOZZLE, payload: {
                cultureCode: `${paramModel.language}-${paramModel.countryCode}`,
                latitude: parseFloat(currentLocation.Latitude._text),
                longitude: parseFloat(currentLocation.Longitude._text),
                modelName: "gc_sw_groundsprayspecialnozzle",
                numberOfDays: paramModel.numberOfDays,
                isGreencastForecast: true
            }});
            dispatch({ type: sagaActionTypes.SAGA_GET_DISEASE, payload: {
                cultureCode: `${paramModel.language}-${paramModel.countryCode}`,
                latitude: parseFloat(currentLocation.Latitude._text),
                longitude: parseFloat(currentLocation.Longitude._text),
                numberOfDays: paramModel.numberOfDays,
                isOranmentalWeather: true,
                isGreencastForecast: true
            }});
            dispatch({
                type: sagaActionTypes.SAGA_UPDATE_CURRENT_LANGUAGE,
                payload: {
                    strCultureCode: `${paramModel.language}-${paramModel.countryCode}`,
                }
            });
        }
    }, [currentLocation]);

    useEffect(() => {
        const securityReg = /(?<=securitykey=)[\w\\/+=]*/;
        const themeReg = /(?<=theme=)[\w]*/;
        // const modelNameReg = /(?<=[Uu]ser[Ss]praywdws=)[\w]*/;
        const countryCodeReg = /(?<=country=)[\w]*/;
        const latitudeReg = /(?<=[Ll]at=)[+-.\w]*/;
        const longitudeReg = /(?<=[Ll]ong=)[+-.\w]*/;
        const languageReg = /(?<=[Ll]anguage=)[\w]*/;
        // const cropListReg = /(?<=[Cc]ountry[Ss]praywdws=)[\w%|]*/;
        const numberOfDaysReg = /(?<=numberofforecast=)[\w]*/;
        const unitReg = /(?<=unit=)[\w]*/;
        const emailidReg=/emailid=([^&]+)/;
        const placenameReg=/placename=([^&]+)/;
        const headtitlebgReg=/headtitlebg=([^&]+)/;
        const tabletitlebgReg=/tabletitlebg=([^&]+)/;
        const buttonbgReg=/buttonbg=([^&]+)/;
        const forecastbtnbgReg=/forecastbtnbg=([^&]+)/;


        const securityRes = urlLocation.search.match(securityReg);
        const themeRes = urlLocation.search.match(themeReg);
        // const modelNameRes = urlLocation.search.match(modelNameReg);
        const countryCodeRes = urlLocation.search.match(countryCodeReg);
        const latitudeRes = urlLocation.search.match(latitudeReg);
        const longitudeRes = urlLocation.search.match(longitudeReg);
        const languageRes = urlLocation.search.match(languageReg);
        // const cropListRes = urlLocation.search.match(cropListReg);
        const numberOfDaysRes = urlLocation.search.match(numberOfDaysReg);
        const unitRes = urlLocation.search.match(unitReg);
        const emailidRes=urlLocation.search.match(emailidReg);
        const placenameRes=urlLocation.search.match(placenameReg);
        const headtitlebgRes=urlLocation.search.match(headtitlebgReg);
        const tabletitlebgRes=urlLocation.search.match(tabletitlebgReg);
        const buttonbgRes=urlLocation.search.match(buttonbgReg);
        const forecastbtnbgRes=urlLocation.search.match(forecastbtnbgReg);

        const security = securityRes ? securityRes[0] : "no-value";
        const theme = themeRes && themeRes[0] || "light";
        // const modelName = modelNameRes && modelNameRes[0];
        const countryCode = countryCodeRes && countryCodeRes[0];
        const latitude = parseFloat(latitudeRes[0] || "0");
        const longitude = parseFloat(longitudeRes[0] || "0");
        const language = languageRes[0];
        // let cropList = cropListRes[0];
        const numberOfDays = numberOfDaysRes[0];
        const unit = unitRes && unitRes[0] || "metric";
        const placename=placenameRes && placenameRes[1]||null;
        const headtitlebg=headtitlebgRes && headtitlebgRes[1]||null;
        const tabletitlebg=tabletitlebgRes && tabletitlebgRes[1]||null;
        const buttonbg=buttonbgRes && buttonbgRes[1]||null;
        const forecastbtnbg=forecastbtnbgRes && forecastbtnbgRes[1]||null;

        let emailid;

        if (emailidRes&&emailidRes!=null) {
            emailid = emailidRes[1];
            console.log("Email ID",emailid);
        } else {
            console.log("Email ID not found,should be seted default email");
            const countrycode=countryCode.toLowerCase();
            emailid="cehub-"+countrycode+"@syngentaprocessmail.com";
        }

        dispatch(updateParamModel({
            security,
            theme,
            // modelName,
            countryCode,
            latitude,
            longitude,
            language,
            // cropList,
            numberOfDays: parseFloat(numberOfDays),
            unit,
            emailid,
            placename,
            headtitlebg,
            tabletitlebg,
            buttonbg,
            forecastbtnbg
        }));

        if (securityRes) {
            dispatch({ type: sagaActionTypes.SAGA_GET_USERNAME, payload: {
                security, countryCode,
                emailid
            }});
        }
        if (theme) {
            const rootElement = document.getElementById("root");
            switch (theme) {
                case "dark": {
                    rootElement.className = "dark-theme";
                    break;
                }
                case "light": {
                    rootElement.className = "light-theme";
                    break;
                }
            }
        }
    }, [urlLocation]);
    //if it is hexadecimal color value
    function isHexColor(color) {
        const regex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;
        return regex.test(color);
    }
    //Encapsulate the logic of creating style objects into a function
    function createBgStyle(hexColor) {
        if (hexColor) {
            // Ensure that hexColor is a valid hexadecimal color value
            if (isHexColor(hexColor)) {
                return {
                    backgroundColor: hexColor,
                    color: "#FFFFFF",
                };
            } else {
                // If hexColor is not a valid color value, print the log
                console.log("Please provide hexadecimal color values");
            }
        }
        // If hexColor is not provided, return null
        return null;
    }
    const trueheadTitlebg="#"+paramModel.headtitlebg;
    const truetabletitlebg="#"+paramModel.tabletitlebg;
    // Creating Style Objects with Functions
    const headTitleStyle = createBgStyle("#" + paramModel.headtitlebg);
    const tabletitlebgStyle = createBgStyle("#" + paramModel.tabletitlebg);
    const buttonbgStyle = createBgStyle("#" + paramModel.buttonbg);
    const forecastbtnbgStyle = createBgStyle("#" + paramModel.forecastbtnbg);

    const iconColor="#FFFFFF";

    const diseaseLegendStyle = {
        display: disease?.lstLegend?.length > 0 ? "" : "none"
    };
    const combinedStyle = {
        ...tabletitlebgStyle,
        ...diseaseLegendStyle
    };

    // useEffect(() => {
    //     if (paramModel.latitude&&paramModel.longitude) {
    //         dispatch({
    //             type: sagaActionTypes.SAGA_COORDINATE_SEARCH,
    //             payload: { searchText: `${paramModel.longitude},${paramModel.latitude}` }
    //         });
    //     }
    // }, [paramModel]);

    useEffect(() => {
        if(!paramModel.placename){
            if (paramModel.latitude&&paramModel.longitude) {
                dispatch({
                    type: sagaActionTypes.SAGA_COORDINATE_SEARCH,
                    payload: { searchText: `${paramModel.longitude},${paramModel.latitude}` }
                });
            }
        }
        else{
            let currentCountryCode = paramModel.countryCode;
            if (currentCountryCode.toUpperCase() === "AU") {
                currentCountryCode = "AU;NZ";
            }
            dispatch({
                type: sagaActionTypes.SAGA_COORDINATE_SEARCH,
                payload: {searchText: `${paramModel.placename},${currentCountryCode}`,
                    searchFlag: true
                }
            });
        }
    }, [paramModel]);

    useEffect(() => {
        if (weatherForcastDailyList.length > 0 && !currentWeatherDate) {
            setCurrentWeatherDate(weatherForcastDailyList[0]);
        }
    }, [weatherForcastDailyList]);

    useEffect(() => {
        if (Object.keys(fiveWeather24HoursData.defaultShowIcon).length > 0) {
            let date;
            if (currentWeatherDate) {
                date = currentWeatherDate.date?.match(/\d\d\d\d\/\d\d\/\d\d/)[0];
                setDate(date);
            } else {
                date = Object.keys(fiveWeather24HoursData.hours24Data)[0];
                setDate(date);

            }
        }
    }, [currentWeatherDate, fiveWeather24HoursData]);

    const TFHoursTabItems = useHourlyTabs({
        style:tabletitlebgStyle,
        weather24HoursData: fiveWeather24HoursData,
        weather8HoursData: fiveWeather8HoursData,
        weatherDayData: fiveWeatherDayData,
        date,
        currentLanguage,
        theme: paramModel.theme,
        CountryCode:paramModel.countryCode
    });

    useEffect(() => {
        if (keyTurfData) {
            contructKeyTurfGrowDataItems(keyTurfData);
        }
    }, [keyTurfData, cropsSprayGround, cropsSprayGoundWithNoggle, currentIndex, disease, currentLanguage]);

    const contructKeyTurfGrowDataItems = (keyTurfData) => {
        const tabItems: TabsProps["items"] = [
            {
                key: "keyTurf",
                label: currentLanguage.txt_keyPlantTurfGrowTemp,
                children: (
                    <div className="key-turf-container">
                        {keyTurfData.map((item) => {
                            return (
                                <div key={item} className="detail">
                                    <div style={tabletitlebgStyle} className="title-div">
                                        {/* <label>{transferDateToTargetFormater(paramModel.countryCode, item.weatherDate, Formatter.seocnd)?.dayOfWeek}</label> */}
                                        <IF show={(paramModel.language.toLowerCase()=="fr")}>
                                            <label>{item?.formatDay}</label>
                                        </IF>
                                        <IF show={!(paramModel.language.toLowerCase()=="fr")}>
                                            <label>{transferDateToTargetFormater(paramModel.countryCode, item.weatherDate, Formatter.seocnd)?.dayOfWeek}</label>
                                        </IF>
                                        <label>{transferDateToTargetFormater(paramModel.countryCode, item.weatherDate, Formatter.seocnd)?.date}</label>
                                    </div>
                                    <InfoCard
                                        data={item}
                                        frostWarning={currentLanguage.txt_frostwarning}
                                    />
                                </div>
                            );
                        })}
                    </div>
                )
            },
            {
                key: "DiseaseRiskIndex",
                label: currentLanguage.txt_diseaseRiskIndex,
                children: (
                    <Spin spinning={disease?.lstLegend?.length > 0 ? false : true}>
                        <div className="disease-super-div">
                            <div className="disease-container">
                                <div>
                                    {/* <div className="head-title" style={{display:`${disease?.lstLegend?.length > 0 ?"":"none"}`}}> */}
                                    <div className="head-title" style={combinedStyle}>
                                        {/* Disease Risk */}
                                        {currentLanguage.txt_diseaseRiskIndex}
                                    </div>
                                    {
                                        disease && Object.keys(disease?.defaultShowIcon).map((item, index) => {
                                            return (
                                                <div
                                                    key={disease?.defaultShowIcon[item]}
                                                    className="left-title-div"
                                                    style={{backgroundColor: `${index % 2 > 0 ? "#FFFBFB" : "#F3F4F6"}`}}
                                                >
                                                    {disease?.defaultShowIcon[item]}
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                                {
                                    disease && Object.keys(disease?.diseaseData).map((keyString) => {
                                        return (
                                            <div style={{ flex: 1 }} key={keyString}>
                                                <div style={tabletitlebgStyle} className="head-title">
                                                    {/* {transferDateToTargetFormater(paramModel.countryCode, keyString, Formatter.seocnd).dayOfWeek} */}
                                                    <IF show={(paramModel.language.toLowerCase()=="fr")}>
                                                        <label>{disease?.formatDay[keyString]}</label>
                                                    </IF>
                                                    <IF show={!(paramModel.language.toLowerCase()=="fr")}>
                                                        <label>{transferDateToTargetFormater(paramModel.countryCode, keyString, Formatter.seocnd).dayOfWeek}</label>
                                                    </IF>
                                                    <label>
                                                        {transferDateToTargetFormater(paramModel.countryCode, keyString, Formatter.seocnd).date}
                                                    </label>
                                                </div>
                                                {
                                                    Object.keys(disease?.diseaseData[keyString]).map((propertyKey, index) => {
                                                        const riskValue = parseFloat(disease?.diseaseData[keyString][propertyKey]);
                                                        return (
                                                            <div
                                                                key={disease?.diseaseData[keyString][propertyKey]}
                                                                className="value-div"
                                                                style={{backgroundColor: `${index % 2 > 0 ? "#FFFBFB" : "#F3F4F6"}`}}
                                                            >
                                                                <IF show={!riskValue}>
                                                                    <div style={{ backgroundColor: "#23F900" }}/>
                                                                </IF>
                                                                <IF show={riskValue > 0 && riskValue <= 1}>
                                                                    <div style={{ backgroundColor: "#FAFF00" }}/>
                                                                </IF>
                                                                <IF show={riskValue > 1 && riskValue <= 2}>
                                                                    <div style={{ backgroundColor: "#FFB800" }}/>
                                                                </IF>
                                                                <IF show={riskValue > 2 && riskValue <= 3}>
                                                                    <div style={{ backgroundColor: "#FF6B00" }}/>
                                                                </IF>
                                                                <IF show={riskValue > 3}>
                                                                    <div style={{ backgroundColor: "#FF0000" }}/>
                                                                </IF>
                                                            </div>
                                                        );
                                                    })
                                                }
                                            </div>
                                        );
                                    })
                                }
                            </div>
                            <div className="bottom-div">
                                <div className="title">
                                    <label>{disease?.lstLegend[0]?.name}</label>
                                </div>
                                <div className="color-indicate">
                                    {
                                        disease && disease?.lstLegend.map((legend, index) => {
                                            if (index === 0) {
                                                return;
                                            }
                                            return (
                                                <div
                                                    key={legend.key}
                                                    className="color-indicate-div"
                                                >
                                                    <div
                                                        className="color-div"
                                                        style={{
                                                            backgroundColor: `${
                                                                legend.key === "txt_noRisk" ? "#23F900" :
                                                                    legend.key === "txt_lowRisk" ? "#FAFF00" : legend.key === "txt_mediumRisk" ? "#FFB800" :
                                                                        legend.key === "txt_highRisk" ? "#FF6B00" : legend.key === "txt_veryHighRisk" ? "#FF0000" : "" }`,
                                                        }} />
                                                    <label style={{ color: `${paramModel.theme === "light" ? "#2F3031" : "white"}`}}>{legend.name}</label>
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </Spin>
                )
            },
            {
                key: "GroundSpraying",
                label: currentLanguage.txt_groundSpraying,
                children: (
                    <div className="circle-container">
                        <div className="cirle-super-div">
                            <div>
                                <div className="title-div">
                                    {currentLanguage.txt_groundSpraying}
                                </div>
                                <div className="circle-middle-div">
                                    <div className="inner-circle">
                                        { cropsSprayGround.circleObj[Object.keys(cropsSprayGround?.circleObj) &&
                                        Object.keys(cropsSprayGround?.circleObj)[currentIndex] || 0]?.indexMap.map((item, index) => {
                                            let top = 0;
                                            let left = 0;
                                            const radius = 125;
                                            const rad = Math.PI / 180;
                                            const localDateMatch = Object.keys(cropsSprayGround?.circleObj) &&
                                            Object.keys(cropsSprayGround?.circleObj)[currentIndex].match(/\d\d\d\d\/\d\d\/\d\d/);
                                            const localDate = localDateMatch ? localDateMatch[0] : null;

                                            if (index <= 6) {
                                                const angle = index * 15;
                                                top = radius - Math.cos(angle * rad) * radius;
                                                left = Math.sin(angle * rad) * radius;
                                            }

                                            if (index > 6 && index <= 12) {
                                                const angle = (index - 6) * 15;
                                                top = Math.sin(angle * rad) *radius + radius;
                                                left = Math.cos(angle * rad) *radius;
                                            }

                                            if (index > 12 && index <18) {
                                                const angle = (index - 12) * 15;
                                                top = Math.cos(angle * rad) *radius + radius;
                                                left = -(Math.sin(angle * rad) * radius);
                                            }

                                            if (index >= 18) {
                                                const angle = (index - 18) * 15;
                                                top = radius - (Math.sin(angle * rad) * radius);
                                                left = -Math.cos((angle * rad)) * radius;
                                            }

                                            return (
                                                <div
                                                    style={{
                                                        top: `${top - 41}px`,
                                                        left: `${left + 84}px`,
                                                        backgroundColor:  cropsSprayGround.circleObj[localDate || 0]?.colorMap[item]
                                                    }}
                                                    className="circle-div"
                                                    key={`${item}-${index}`}
                                                >
                                                    <label>{item}</label>
                                                    {/* <label>{cropsSprayGround.circleObj[localDate || 0]?.srlMap[item]}</label> */}
                                                </div>
                                            );
                                        }) }
                                        <div className="innner-circle-text-div">
                                            <span className="innner-circle-span">{currentLanguage.txt_groundSpraying}</span>
                                            <div className="spray-div">
                                                <label>{cropsSprayGround?.restrictionLegend?.ResColLegRecomm}</label>
                                                <div style={{ backgroundColor: "#41C304" }} className="spray-circle" />
                                            </div>
                                            <div className="spray-div">
                                                <label>{cropsSprayGround?.restrictionLegend?.ResColLegRestrict}</label>
                                                <div style={{ backgroundColor: "#E17A00" }} className="spray-circle" />
                                            </div>
                                            <div className="spray-div">
                                                <label>{cropsSprayGround?.restrictionLegend?.ResColLegNotRec}</label>
                                                <div style={{ backgroundColor: "#EE1A1A" }} className="spray-circle" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="title-div">
                                    {currentLanguage.txt_groundSprayDriftReduNozzle}
                                </div>
                                <div className="circle-middle-div">
                                    <div className="inner-circle">
                                        { cropsSprayGoundWithNoggle.circleObj[Object.keys(cropsSprayGoundWithNoggle?.circleObj) &&
                                        Object.keys(cropsSprayGoundWithNoggle?.circleObj)[currentIndex] || 0]?.indexMap.map((item, index) => {
                                            let top = 0;
                                            let left = 0;
                                            const radius = 125;
                                            const rad = Math.PI / 180;
                                            const localDateMatch = Object.keys(cropsSprayGoundWithNoggle?.circleObj) &&
                                            Object.keys(cropsSprayGoundWithNoggle?.circleObj)[currentIndex].match(/\d\d\d\d\/\d\d\/\d\d/);

                                            const localDate = localDateMatch ? localDateMatch[0] : null;
                                            if (index <= 6) {
                                                const angle = index * 15;
                                                top = radius - Math.cos(angle * rad) * radius;
                                                left = Math.sin(angle * rad) * radius;
                                            }

                                            if (index > 6 && index <= 12) {
                                                const angle = (index - 6) * 15;
                                                top = Math.sin(angle * rad) *radius + radius;
                                                left = Math.cos(angle * rad) *radius;
                                            }

                                            if (index > 12 && index <18) {
                                                const angle = (index - 12) * 15;
                                                top = Math.cos(angle * rad) *radius + radius;
                                                left = -(Math.sin(angle * rad) * radius);
                                            }

                                            if (index >= 18) {
                                                const angle = (index - 18) * 15;
                                                top = radius - (Math.sin(angle * rad) * radius);
                                                left = -Math.cos((angle * rad)) * radius;
                                            }

                                            return (
                                                <div
                                                    style={{
                                                        top: `${top - 41}px`,
                                                        left: `${left + 84}px`,
                                                        backgroundColor:  cropsSprayGoundWithNoggle.circleObj[localDate || 0]?.colorMap[item]
                                                    }}
                                                    className="circle-div"
                                                    key={`${item}-${index}`}
                                                >
                                                    <label>{item}</label>
                                                    {/* <label>{cropsSprayGoundWithNoggle.circleObj[localDate || 0]?.srlMap[item]}</label> */}
                                                </div>
                                            );
                                        }) }
                                        <div className="innner-circle-text-div">
                                            <span className="innner-circle-span">{currentLanguage.txt_groundSpraying}</span>
                                            <div className="spray-div">
                                                <label>{cropsSprayGround?.restrictionLegend?.ResColLegRecomm}</label>
                                                <div style={{ backgroundColor: "#41C304" }} className="spray-circle" />
                                            </div>
                                            <div className="spray-div">
                                                <label>{cropsSprayGround?.restrictionLegend?.ResColLegRestrict}</label>
                                                <div style={{ backgroundColor: "#E17A00" }} className="spray-circle" />
                                            </div>
                                            <div className="spray-div">
                                                <label>{cropsSprayGround?.restrictionLegend?.ResColLegNotRec}</label>
                                                <div style={{ backgroundColor: "#EE1A1A" }} className="spray-circle" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="date-select-indicate" style={{
                            backgroundColor: `${((paramModel.tabletitlebg&&paramModel.headtitlebg) ? trueheadTitlebg : "#14803C")}`
                        }}>
                            <button
                                style={{ cursor: `${currentIndex === 0 ? "not-allowed" : "pointer"}`}}
                                onClick={() => {
                                    if(currentIndex > 0) {
                                        setCurrentIndex(currentIndex - 1);
                                    }
                                }}>
                                <TurnLeftIcon />
                            </button>
                            {Object.keys(cropsSprayGround?.circleObj).map((item, index) => {
                                return (
                                    <div key={item} className="date-select-indicate" style={{
                                        backgroundColor: `${((paramModel.tabletitlebg&&paramModel.headtitlebg) ? trueheadTitlebg : "#14803C")}`
                                    }}>
                                        <IF show={!(paramModel.tabletitlebg&&paramModel.headtitlebg)}>
                                            <div
                                                onClick={() => setCurrentIndex(index)}
                                                className="date-spray-ground-div"
                                                key={item}
                                                style={{
                                                    //key={`${item}-${index}`}
                                                    backgroundColor: `${(currentIndex === index ? "#90D6AA" : "#14803C")}`
                                                }}
                                            >
                                                {transferDateToTargetFormater(paramModel.countryCode, item, Formatter.first)?.date}
                                            </div>
                                        </IF>
                                        <IF show={!!(paramModel.tabletitlebg&&!!paramModel.headtitlebg)}>
                                            <div
                                                onClick={() => setCurrentIndex(index)}
                                                className="date-spray-ground-div"
                                                key={item}
                                                style={{
                                                    //key={`${item}-${index}`}
                                                    backgroundColor: `${(currentIndex === index ? truetabletitlebg : trueheadTitlebg)}`
                                                }}
                                            >
                                                {transferDateToTargetFormater(paramModel.countryCode, item, Formatter.first)?.date}
                                            </div>
                                        </IF>
                                    </div>
                                );
                            })}
                            <button
                                style={{ cursor: `${(currentIndex === Object.keys(cropsSprayGround?.circleObj).length - 1) ? "not-allowed" : "pointer"}`}}
                                onClick={() => {
                                    if(currentIndex < Object.keys(cropsSprayGround?.circleObj).length - 1) {
                                        setCurrentIndex(currentIndex + 1);
                                    }
                                }}>
                                <TurnRightIcon />
                            </button>
                        </div>
                    </div>
                )
            }
        ];
        setKeyTurfGrowTabs(tabItems);
    };

    const option = {
        title: {
            text: currentLanguage.txt_Temperature,
            top: 10,
            left: 6,
            subtext: paramModel.unit === "metric" ? "℃" : "°F",
        },
        legend: {
            show: true,
            left: 170,
            top: 12,
            itemStyle: {
                opacity: 0,
                itemWidth: 40,
                itemHeight: 10
            }
        },
        tooltip: {
            trigger: "axis"
        },
        grid: {
            left: "3%",
            right: "4%",
            bottom: "7%",
            containLabel: true
        },
        xAxis: [
            {
                type: "category",
                boundaryGap: false,
                position: "top",
                data: chartData?.date,
                splitLine: {
                    show: true,
                    lineStyle: {
                        type: "solid",
                        color: "#DBD4D4",
                        width: 1.5
                    },
                },
                color: "#868CA2",
                fontSize: "8px",
                axisLabel: {
                    interval: 24,
                    formatter: (val) => {
                        const target = val.split(" ");
                        return `${target[0]}${target[1]}${target[2]}`;
                    }
                }
            }
        ],
        yAxis: [
            {
                nameTextStyle: {
                    padding: [0, 0, 25, -5]
                },
                type: "value",
                splitLine: {
                    show: true,
                    lineStyle: {
                        type: "dashed",
                        color: "#DBD4D4",
                        width: 1.5
                    }
                }
            }
        ],
        series: [
            {
                name: " ",
                type: "line",
                stack: "Total",
                smooth: true,
                lineStyle: {
                    width: 1,
                    color: "rgba(235, 183, 0, 1)"
                },
                symbol: "none",
                showSymbol: false,
                areaStyle: {
                    opacity: 0.8,
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        {
                            offset: 0,
                            color: "rgba(235, 183, 0, 0.54)"
                        },
                        {
                            offset: 1,
                            color: "rgba(255, 255, 255, 0)"
                        }
                    ])
                },
                emphasis: {
                    focus: "series"
                },
                data: chartData?.temperature,
                label: {
                    formatter: "{name|{b}\n{value|{d}%}}",
                    rich: {
                        name: {
                            fontSize: 14,
                            color: "#666"
                        },
                        value: {
                            fontSize: 18,
                            color: "red"
                        }
                    }
                }
            },
        ]
    };

    const secondChartOption = {
        title: {
            text: currentLanguage.txt_Precipitaion,
            top: 10,
            left: 6,
            subtext: paramModel.unit === "metric" ? "mm" : "in",

        },
        legend: {
            show: true,
            left: 130,
            top: 11,
            itemWidth: 15,
            itemHeight: 15
        },
        grid: {
            left: "5%",
            right: "4%",
            bottom: "7%",
            containLabel: true
        },
        tooltip: {
            trigger: "axis"
        },
        xAxis: {
            data: chartData?.date,
            position: "top",
            splitLine: {
                show: true,
                lineStyle: {
                    type: "solid",
                    color: "#DBD4D4",
                    width: 1.5
                }
            },
            axisLabel: {
                interval: 24,
                formatter: (val) => {
                    const target = val.split(" ");
                    return `${target[0]}${target[1]}${target[2]}`;
                }
            }
        },
        yAxis: {
            nameTextStyle: {
                padding: [0, 0, 25, -5]
            },
            splitLine: {
                show: true,
                lineStyle: {
                    type: "dashed",
                    color: "#DBD4D4",
                    width: 1.5
                }
            }
        },
        series: [
            {
                name: " ",
                type: "bar",
                data: chartData?.precip,
                emphasis: {
                    focus: "series"
                },
                animationDelay: function (idx) {
                    return idx * 10;
                },
                itemStyle: {
                    normal: {
                        color: "#20AFFF"
                    }
                }
            },
        ],
        animationEasing: "elasticOut",
    };

    const inputTextOnchange=(text)=>{
        setInputText(text);
        setSelectPlaceName("");
    };
    const [selectPlaceName,setSelectPlaceName]=useState("");
    const placeNameOnchange=(item)=>{
        setSelectPlaceName(item);
        setInputText("");
    };

    return (
        <>
            {
                showPageByToken?
                    <div className="five-weather-container">
                        <Modal
                            title={(
                                <label
                                    style={{ fontSize: "22px", color: "green" }}
                                >
                            Search Location
                                </label>
                            )}
                            open={locationListShow}
                            onCancel={() => setLocationListShow(false)}
                            style={{
                                minWidth: "50%"
                            }}
                            footer={null}
                            destroyOnClose={true}
                        >
                            <List
                                dataSource={locations}
                                style={{
                                    height: "500px",
                                    overflow: "auto",
                                    borderTop: "1px solid lightgray",
                                }}
                                renderItem={(item: ILocationModel) => {
                                    return (
                                        <div style={{
                                            minHeight: "40px",
                                            borderBottom: "1px solid lightgray",
                                            borderLeft: "1px solid lightgray",
                                            borderRight: "1px solid lightgray",
                                            paddingLeft: "15px",
                                            display: "flex",
                                            alignItems: "center"
                                        }}>
                                            <label
                                                onClick={() => {
                                                    setCurrentLocation(item);
                                                    setLocationListShow(false);
                                                    setInputText(item.AdminName._text.split(",")[0]);
                                                    setSelectPlaceName("");
                                                }}
                                                style={{
                                                    color: "#337ab7",
                                                    fontSize: "20px"
                                                }}
                                            >
                                                {item.AdminName._text}
                                            </label>
                                        </div>
                                    );
                                } } />
                        </Modal>
                        <Modal
                            title="Warning"
                            open={showAddBookSuccess || showAddBookFailed}
                            onCancel={() => {
                                dispatch({type: sagaActionTypes.SAGA_GET_BOOKMARK, payload: {
                                    countryCode: paramModel.countryCode,
                                    emailid: paramModel.emailid
                                } });
                                dispatch(showAddBookmarkSuccessFunc(false));
                                dispatch(showAddBookmarkFailedFunc(false));

                            }}
                            onOk={() => {
                                dispatch({type: sagaActionTypes.SAGA_GET_BOOKMARK, payload: {
                                    countryCode: paramModel.countryCode,
                                    emailid: paramModel.emailid
                                } });
                                dispatch(showAddBookmarkSuccessFunc(false));
                                dispatch(showAddBookmarkFailedFunc(false));

                            }}
                        >
                            <label>{showAddBookSuccess && currentLanguage["Bookmark Added successfully"] || showAddBookFailed && currentLanguage["txt_addBookMarkFailed"]}</label>
                        </Modal>
                        <Modal
                            title="Warning"
                            open={searchCoordinateResult}
                            onCancel={() => {
                                dispatch(searchTargetCoordinate(false));
                            }}
                            onOk={() => {
                                dispatch(searchTargetCoordinate(false));
                            }}
                        >
                            <label>{currentLanguage.No_place_found}</label>
                        </Modal>
                        <div className="accurate-weather-left-container">
                            {/* left location-1 search */}
                            <div className="accurate-left">
                                <div style={headTitleStyle} className="head-title" >
                                    {currentLanguage.txt_OrnamentalsWeather}
                                </div>
                                <div className="ag-search-div">
                                    <label className="search-title">
                                        {currentLanguage.txt_SelectLocation}
                                    </label>
                                    <Search
                                        style={buttonbgStyle}
                                        iconColor={iconColor}
                                        // onChange={(text: string) => setInputText(text)}
                                        onChange={(text: string) => {
                                            inputTextOnchange(text);
                                        }}
                                        value={inputText}
                                        placeholder={currentLanguage.txt_LocationNameLngLat}
                                        searchButtonClick={() => {
                                            const strArr = inputText.split(",");
                                            if (strArr.length === 2) {
                                                const longitude = parseFloat(strArr[0]);
                                                const latitude = parseFloat(strArr[1]);
                                                if (typeof(latitude) === "number" && typeof(longitude) === "number") {
                                                    dispatch({
                                                        type: sagaActionTypes.SAGA_COORDINATE_SEARCH,
                                                        payload: {searchText:`${longitude},${latitude}`}

                                                    });
                                                }
                                            } else {
                                                let currentCountryCode = paramModel.countryCode;
                                                if (currentCountryCode.toUpperCase() === "AU") {
                                                    currentCountryCode = "AU;NZ";
                                                }
                                                dispatch({
                                                    type: sagaActionTypes.SAGA_COORDINATE_SEARCH,
                                                    payload: {searchText: `${inputText},${currentCountryCode}`}
                                                });
                                            }
                                        } }
                                        locationButtonClicked={() => coordinateButtonClicked()} />
                                </div>
                                <div className="ag-bookmark">
                                    <label className="bookmark-title">
                                        {currentLanguage.txt_BookmarkReminder}
                                    </label>
                                    <div className="select-div">
                                        <Select
                                            className="select"
                                            showSearch
                                            placeholder={currentLanguage.txt_Select}
                                            optionFilterProp="children"
                                            onChange={(option, item) => {
                                                placeNameOnchange(item);
                                            }}
                                            value={selectPlaceName}
                                            onSelect={(option, item) => {
                                                setCurrentLocation({
                                                    AdminName: { _text: item.bookmark.placeName },
                                                    AdminName1: { _text: item.bookmark.placeName },
                                                    AdminName2: { _text: item.bookmark.placeName },
                                                    AdminName3: { _text: item.bookmark.placeName },
                                                    AreaId: { _text: "unknow" },
                                                    CountryCode: { _text: item.bookmark.countryCode },
                                                    CountryName: { _text: item.bookmark.placeName },
                                                    Latitude: { _text: `${item.bookmark.latitude}` },
                                                    Longitude: { _text: `${item.bookmark.longitude}` },
                                                    AltName: { _text: item.bookmark.placeName },
                                                    Name: { _text: item.bookmark.placeName },
                                                    PlaceId: { _text: "unknow" },
                                                    PostalCode: "",
                                                    Score: { _text: "" }
                                                });
                                            }}
                                            size="large"
                                            filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
                                            options={bookmarks} />
                                        <button className="bookmark-button" onClick={addBookmarkButtonClicked}>
                                            <BookMarkIcon color={`#${paramModel.buttonbg}`} />
                                        </button>
                                    </div>
                                </div>
                                <div className="ag-weather-list">
                                    <label className="top-title">
                                        {currentLanguage.txt_WeatherConditions}
                                    </label>
                                    <label className="sub-title">
                                        {currentLanguage.txt_SelectWeatherConditions}
                                    </label>
                                    {/* left location-2 search */}
                                    <Spin spinning={weatherListLoading}>
                                        <div className="list-div">
                                            {weatherForcastDailyList.map((item, index) =>
                                                <WeatherCard
                                                    onClick={(data) => {
                                                        const newWeatherForcastDailyList = weatherForcastDailyList.map((item) => {
                                                            const newItem = { ...item };
                                                            if (newItem.date === data.date && newItem.selected === false) {
                                                                newItem.selected = true;
                                                                setCurrentWeatherDate(item);
                                                            } else {
                                                                newItem.selected = false;
                                                            }
                                                            return newItem;
                                                        });
                                                        dispatch(updateWeatherForcastdailyList(newWeatherForcastDailyList));
                                                    }}
                                                    weatherData={item}
                                                    key={`${item.date}-${index}`}
                                                />)
                                            }
                                        </div>
                                    </Spin>
                                </div>
                            </div>
                            <div className="accurate-right">
                                <div className="top-header-container-div">
                                    <Spin spinning={topInfoCardLoading}>
                                        <div style={headTitleStyle} className="top-header-top-div">
                                            <div className="left-title">
                                                <label style={headTitleStyle} className="main-lable">
                                                    {currentLocation?.AdminName._text.split(",")[0] || "Unknow"}
                                                </label>
                                                <label style={headTitleStyle} className="sub-lable">
                                                    {`${currentLocation?.Longitude._text || "0"}, ${currentLocation?.Latitude._text || "0"}`}

                                                </label>
                                            </div>
                                            <div style={tabletitlebgStyle} className="weather-card-top-container-div">
                                                <div className="date-div">
                                                    <label className="theme">{currentLanguage.txt_nowcast}</label>
                                                    <img style={{ height: "65px", width: "65px" }} src={localDailyWidgetWeather && localDailyWidgetWeather[0]?.imageName ? `${API_LOCAL_URL}api/weather-imgs/${localDailyWidgetWeather && localDailyWidgetWeather[0].imageName}` : Sunny} />
                                                </div>
                                                <div className="weather-icon-div">
                                                    <label className="theme">
                                                        {paramModel.unit === "metric" ? `${localDailyWidgetWeather && localDailyWidgetWeather[0]?.tempAir}℃` : `${localDailyWidgetWeather && localDailyWidgetWeather[0]?.tempAir_f}°F`}
                                                    </label>
                                                    <label className="theme">
                                                        {localDailyWidgetWeather && localDailyWidgetWeather[0]?.formatDay}, {
                                                            localDailyWidgetWeather &&localDailyWidgetWeather[0]?.localDateTime?.match(/(?<=\d\d\d\d\/)\d\d\/\d\d/)[0].split("/").reverse().join(".")}
                                                    </label>
                                                </div>
                                                <div className="water-fall-div">
                                                    <WaterFallIcon color="#F3F4F6"/>
                                                    <label className="number-lable">
                                                        {paramModel.unit === "metric" ? localDailyWidgetWeather && localDailyWidgetWeather[0]?.precip_Sum_in :
                                                            localDailyWidgetWeather && localDailyWidgetWeather[0]?.precip_Sum_in }
                                                        <label>{paramModel.unit === "metric" ? " mm" : " in"}</label>
                                                    </label>
                                                </div>
                                                <div className="wind-speed-div">
                                                    <WindSpeedIcon color="#F3F4F6"/>
                                                    <label className="number-lable">
                                                        {paramModel.unit === "metric" ? localDailyWidgetWeather && localDailyWidgetWeather[0]?.windSpeed_kph?.toFixed(2) :
                                                            localDailyWidgetWeather && localDailyWidgetWeather[0]?.windSpeed_mph?.toFixed(2)}
                                                        {paramModel.unit === "metric" ? " k/h" : " m/h"}
                                                    </label>
                                                    <label className="number-lable">
                                                        {localDailyWidgetWeather && localDailyWidgetWeather[0]?.windDirection}
                                                    </label>
                                                </div>
                                                {/* <div className="temperature-div">
                                            <TemperatureIcon color="#F3F4F6" />
                                            <label className="number-lable">
                                                {`${currentLanguage.txt_Max}:${"  "}${
                                                    paramModel.unit === "metric" ?
                                                        weatherForcastDailyList[0]?.tempAir_DailyMax || 0 : weatherForcastDailyList[0]?.tempAir_DailyMax_f}
                                                    ${paramModel.unit === "metric" ? "°" : "°F" }`}
                                            </label>
                                            <label className="number-lable">
                                                {`${currentLanguage.txt_Min}:${"  "}${
                                                    paramModel.unit === "metric" ?
                                                        weatherForcastDailyList[0]?.tempAir_DailyMin || 0 : weatherForcastDailyList[0]?.tempAir_DailyMin_f}
                                                    ${paramModel.unit === "metric" ? "°" : "°F" }`}
                                            </label>
                                        </div> */}
                                            </div>
                                            <div className="top-right-div">
                                                <button
                                                    style={buttonbgStyle}
                                                    className="graphic-button-chart"
                                                    onClick={() => setShowGraphicChart(!showGraphicChart)}
                                                >
                                                    {currentLanguage.lbl_seegraphicchart}
                                                </button>
                                            </div>
                                        </div>
                                        <IF show={showGraphicChart}>
                                            <div className="graphic-chart-div">
                                                <div className="first-div">
                                                    <ReactEcharts
                                                        option={option}
                                                        style={{
                                                            width: "auto",
                                                            height: "95%",
                                                            backgroundColor: "white",
                                                            borderRadius: "5px"
                                                        }}
                                                    />
                                                </div>
                                                <div className="second-div">
                                                    <ReactEcharts
                                                        option={secondChartOption}
                                                        style={{
                                                            width: "auto",
                                                            height: "95%",
                                                            backgroundColor: "white",
                                                            borderRadius: "5px"
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </IF>
                                    </Spin>
                                </div>
                                <div className="circle-round-div">
                                    <Spin spinning={sprayLoading}>
                                        <div className="circle-top-div">
                                            <label>{currentLanguage.txt_DetailedWeather}|&nbsp;
                                                {currentWeatherDate?.formatDay}, {currentWeatherDate?.date.match(/(?<=\d\d\d\d\/)\d\d\/\d\d/)[0].split("/").reverse().join(".")}</label>
                                        </div>
                                        <div className="circle-bottom-div">
                                            <div className="circle-left-div">
                                                <label>
                                                    {currentLanguage.txt_weatherSeverityWarning}:
                                                </label>
                                                <IF show={cropsItems.length === 0}>
                                                    <div className="down-container">
                                                        <label className="drop-label">{(cropsItems[0] as { label: string })?.label}</label>
                                                        {/* <DownOutlined style={{ color: "#0071CD" }} /> */}
                                                    </div>
                                                </IF>
                                            </div>
                                            <div className="circle-middle-div">
                                                <div className="inner-circle">
                                                    { cropsSpray.circleObj[currentWeatherDate?.date.match(/\d\d\d\d\/\d\d\/\d\d/)[0] || 0]?.indexMap.map((item, index) => {
                                                        let top = 0;
                                                        let left = 0;
                                                        const radius = 125;
                                                        const rad = Math.PI / 180;

                                                        const localDate = currentWeatherDate?.date.match(/\d\d\d\d\/\d\d\/\d\d/)[0];
                                                        if (index <= 6) {
                                                            const angle = index * 15;
                                                            top = radius - Math.cos(angle * rad) * radius;
                                                            left = Math.sin(angle * rad) * radius;
                                                        }

                                                        if (index > 6 && index <= 12) {
                                                            const angle = (index - 6) * 15;
                                                            top = Math.sin(angle * rad) *radius + radius;
                                                            left = Math.cos(angle * rad) *radius;
                                                        }

                                                        if (index > 12 && index <18) {
                                                            const angle = (index - 12) * 15;
                                                            top = Math.cos(angle * rad) *radius + radius;
                                                            left = -(Math.sin(angle * rad) * radius);
                                                        }

                                                        if (index >= 18) {
                                                            const angle = (index - 18) * 15;
                                                            top = radius - (Math.sin(angle * rad) * radius);
                                                            left = -Math.cos((angle * rad)) * radius;
                                                        }

                                                        return (
                                                            <div
                                                                style={{
                                                                    top: `${top - 41}px`,
                                                                    left: `${left + 84}px`,
                                                                    backgroundColor:  cropsSpray.circleObj[localDate || 0]?.colorMap[item]
                                                                }}
                                                                className="circle-div"
                                                                key={`${item}-${index}`}
                                                                title={cropsSpray.circleObj[localDate || 0].toolTip[item]}
                                                            >
                                                                <label>{item}</label>
                                                                <label>{cropsSpray.circleObj[localDate || 0]?.srlMap[item]}</label>
                                                            </div>
                                                        );
                                                    }) }
                                                    <div className="innner-circle-text-div">
                                                        <span className="innner-circle-span">{cropsSpray.restrictionLegend[0]?.name}</span>
                                                        <div className="spray-div">
                                                            <label>{ cropsSpray.restrictionLegend[1]?.name }</label>
                                                            <div style={{ backgroundColor: `${cropsSpray.restrictionLegend[1]?.keyText}`}} className="spray-circle" />
                                                        </div>
                                                        <div className="spray-div">
                                                            <label>{cropsSpray.restrictionLegend[2]?.name}</label>
                                                            <div style={{ backgroundColor: `${cropsSpray.restrictionLegend[2]?.keyText}`}} className="spray-circle" />
                                                        </div>
                                                        <div className="spray-div">
                                                            <label>{cropsSpray.restrictionLegend[3]?.name}</label>
                                                            <div style={{ backgroundColor: `${cropsSpray.restrictionLegend[3]?.keyText}`}} className="spray-circle" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="circle-right-div">
                                                <div className="properties-container">
                                                    <label className="title-label">
                                                        {cropsSpray.restrictionLegend[0]?.name}
                                                    </label>
                                                    {
                                                        cropsSpray.restrictionLegend.map((item, index) => {
                                                            if (index !== 0 && index > 3) {
                                                                return (
                                                                    <label
                                                                        key={item.keyText}
                                                                        className="serie-label">
                                                                        {item.keyText}: <label className="content-label">{item.name}</label>
                                                                    </label>
                                                                );
                                                            } else {
                                                                return null;
                                                            }
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </Spin>
                                </div>
                                <div className="table-detail-div">
                                    <IF show={Object.keys(fiveWeather24HoursData).length > 0}>
                                        <Tabs
                                            tabBarStyle={{
                                                marginLeft: "10px",
                                                fontFamily: "Noto-Sans-Regular",
                                                fontSize: "14px",
                                                color: "#14151C"
                                            }}
                                            items={TFHoursTabItems}
                                            destroyInactiveTabPane={true}
                                        />
                                    </IF>
                                </div>
                            </div>
                        </div>
                        <div className="table-detail-div bottom-tab">
                            <Tabs
                                tabBarStyle={{
                                    marginLeft: "10px",
                                    fontFamily: "Noto-Sans-Regular",
                                    fontSize: "14px",
                                    color: "#14151C"
                                }}
                                items={keyTurfGrowTabs}
                                destroyInactiveTabPane={true}
                            />
                        </div>
                        <div className="weather-indicate-icon-container">
                            <ForcastButton style={forecastbtnbgStyle} />
                        </div>
                    </div>
                    :
                    <div className="error_page">
                        <div className="error_content">
                            <span>There is an issue with accessing the page. </span>
                            <span>Please provide the correct token.</span>
                        </div>
                    </div>
            }
        </>
    );
};

export default OranmentalsWeather;