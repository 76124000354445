import { getHistoricDiseaseSeries } from "@/util/http/HistWeather";
import { IGetHistoricWeatherSeriesResModel } from "@/util/http/HistWeather/responseType";
import { useEffect,useState } from "react";
import {debounce} from "lodash";
import { useDispatch } from "react-redux";
import { normalActionTypes } from "@/redux/actions/diseaseAction";

interface IProp{
    pubname: string;
    servicepage: string;
    moduleName: string;
    culture: string;
    countryCode: string;
}

const useHistoricDiseaseSeries = (props:IProp)=>{
    const {
        pubname,
        servicepage,
        moduleName,
        culture,
        countryCode
    } = props;
    const dispatch = useDispatch();

    const [initListDisease,setInitListDisease] = useState<IGetHistoricWeatherSeriesResModel>(null);

    const getSeriesFunc = async()=>{
        try{
            dispatch({ type: normalActionTypes.NORMAL_SHOWLODDING, payload: true});
            const res = await getHistoricDiseaseSeries({
                pubname: pubname,
                // servicepage: servicepage,
                // moduleName: moduleName,
                culture,
                countryCode
            });
            setInitListDisease(res.data);
            dispatch({ type: normalActionTypes.NORMAL_SHOWLODDING, payload: false});
        }
        catch(error){
            console.log("========= useHistoricDiseaseSeries  getDropdownTranslata error======",error);
        }
    };

    useEffect(()=>{
        const myDebounceFunc = debounce(getSeriesFunc,500);
        if(countryCode && culture) {
            myDebounceFunc();
        }
        return()=>{
            myDebounceFunc.cancel();
        };
    },[culture,countryCode]);

    return {
        initListDisease
    };
};

export default useHistoricDiseaseSeries;