import React from "react";
import "./index.less";
import {
    AirPressureFourthIcon,
    IntensityIcon,
    RelativeHumidityFourthIcon,
    VisiblityFourthIcon
} from "@/resource/IconComponents";
import IF from "@/components/IF";
import { useSelector } from "react-redux";
import { RootState } from "@/redux/reducer/store";
interface IProps {
    weatherData?: {
        [keyValu: string]: string
    }
}


const DailyOther = (props: IProps) => {
    const {
        weatherData
    } = props;

    const {
        currentLanguage
    } = useSelector((state: RootState) => state.commonReducer);
    return (
        <div className="daily-other-container">
            <div className="up-div">
                {currentLanguage.txt_OtherPrams}
            </div>
            <div className="down-div">
                <IF show={Boolean(weatherData["relhumidity"])}>
                    <div>
                        <div className="icon-div">
                            <RelativeHumidityFourthIcon />
                            <label className="number-label">{weatherData["relhumidity"]}</label>
                        </div>
                        <div className="text-div">
                            {weatherData["relhumidity-name"]}
                        </div>
                    </div>
                </IF>
                <IF show={Boolean(weatherData["visibility"])}>
                    <div>
                        <div className="icon-div">
                            <VisiblityFourthIcon />
                            <label className="number-label">{weatherData["visibility"]}</label>
                        </div>
                        <div className="text-div">
                            {weatherData["visibility-name"]}
                        </div>
                    </div>
                </IF>
                <IF show={Boolean(weatherData["airpressure_hPa"])}>
                    <div>
                        <div className="icon-div">
                            <AirPressureFourthIcon />
                            <label className="number-label">{weatherData["airpressure_hPa"]}</label>
                        </div>
                        <div className="text-div">
                            {weatherData["airpressure_hPa-name"]}
                        </div>
                    </div>
                </IF>
                <IF show={Boolean(weatherData["gc_LightIntensity"])}>
                    <div>
                        <div className="icon-div">
                            <IntensityIcon />
                            <label className="number-label">{weatherData["gc_LightIntensity"]}</label>
                        </div>
                        <div className="text-div">
                            {weatherData["gc_LightIntensity-name"]}
                        </div>
                    </div>
                </IF>
                <IF show={Boolean(weatherData["gc_DailyLightIntegral"])}>
                    <div>
                        <div className="icon-div">
                            <IntensityIcon />
                            <label className="number-label">{weatherData["gc_DailyLightIntegral"]}</label>
                        </div>
                        <div className="text-div">
                            {weatherData["gc_DailyLightIntegral-name"]}
                        </div>
                    </div>
                </IF>
            </div>
        </div>
    );
};

export default DailyOther;