export const initListDisease = {
    "lstResultseries": [
        {
            "id": "AnthracnoseFoliarBlight",
            "text": "Anthracnose Foliar Risk",
            "isCheck": true,
            "color": "#ff4e00",
            "isSelectYear": false
        },
        {
            "id": "DollarSpotRiskNonIrrigated",
            "text": "Dollar Spot Risk",
            "isCheck": false,
            "color": "#00a0be",
            "isSelectYear": false
        },
        {
            "id": "DollarSpotRiskIrrigated",
            "text": "Dollar Spot Risk (Irrigated)",
            "isCheck": false,
            "color": "#82c8dc",
            "isSelectYear": false
        },
        {
            "id": "FusariumPatchHighRisk",
            "text": "Fusarium Patch High Risk",
            "isCheck": false,
            "color": "#eb8200",
            "isSelectYear": false
        },
        {
            "id": "FusariumPatchRisk",
            "text": "Fusarium Patch Risk",
            "isCheck": false,
            "color": "#ffb400",
            "isSelectYear": false
        },
        {
            "id": "PoaAnnuaGerminationRisk",
            "text": "Poa Annua Germination Risk",
            "isCheck": false,
            "color": "#5f7800",
            "isSelectYear": false
        },
        {
            "id": "PoaAnnuaSeedheadsRisk",
            "text": "Poa Annua Seedheads Risk",
            "isCheck": false,
            "color": "#aab400",
            "isSelectYear": false
        },
        {
            "id": "TakeAllPatchRiskNonIrrigated",
            "text": "Take All Patch Risk",
            "isCheck": false,
            "color": "#00492c",
            "isSelectYear": false
        },
        {
            "id": "TakeAllPatchRiskIrrigated",
            "text": "Take All Patch Risk (Irrigated)",
            "isCheck": false,
            "color": "#91b9a4",
            "isSelectYear": false
        }
    ],
    "lstParameters": null,
    "lstLegend": [
        {
            "key": "Rep_InsertLocation",
            "name": "Select a {location}...",
            "text": "Rep_InsertLocation"
        },
        {
            "key": "gc_refreshchart",
            "name": "Refresh Chart",
            "text": "refresh chart"
        },
        {
            "key": "Clear Selection",
            "name": "Clear Selection",
            "text": "Clear Selection"
        },
        {
            "key": "location",
            "name": "location",
            "text": "location"
        },
        {
            "key": "altabbr",
            "name": "Alt.",
            "text": "altabbr"
        },
        {
            "key": "graphseries",
            "name": "Graph series",
            "text": "graphseries"
        },
        {
            "key": "Start Date",
            "name": "Start Date",
            "text": "Start Date"
        },
        {
            "key": "End Date",
            "name": "End Date",
            "text": "End Date"
        },
        {
            "key": "meteraslabbr",
            "name": "m asl",
            "text": "meteraslabbr"
        },
        {
            "key": "to",
            "name": "to",
            "text": "to"
        },
        {
            "key": "days",
            "name": "days",
            "text": "days"
        },
        {
            "key": "gc_endDateError1",
            "name": "end date must be greater than start date",
            "text": ""
        },
        {
            "key": "gc_nodataError",
            "name": "No data for the chart has been found",
            "text": ""
        }
    ]
};

export const initChartTypeOptions = [
    {
        Color: null,
        ID: "we_tempandrain",
        Text: "Temperature & Rain",
        isCheck: false,
        isSelectYear: false,
        aliasName: "Temperature (°C)"
    },
    // {
    //     Color: null,
    //     ID: "we_tempandrain_rainfall",
    //     Text: "Rainfall",
    //     isCheck: false,
    //     isSelectYear: false,
    //     aliasName: "Rain fall mm"
    // },
    {
        Color: null,
        ID: "we_tempextremes",
        Text: "Temperature Extremes",
        isCheck: false,
        isSelectYear: false,
        aliasName: "Temperature (°C)"
    },
    {
        Color: null,
        ID: "we_tempaverage",
        Text: "Temperature Average",
        isCheck: false,
        isSelectYear: false,
        aliasName: "Temperature (°C)"
    },
    {
        Color: null,
        ID: "we_precipitation",
        Text: "Precipitation",
        isCheck: false,
        isSelectYear: false,
        aliasName: "Rain fall mm"
    }
];