import React from "react";
// import CornYieldRisk from "@/page/Weather/CornYieldRisk";
// import FiveDayWeather from "@/page/Weather/FiveDayWeather";
// import HistTemperatureRisk from "@/page/Weather/HistTemperatureRisk";
// import PrimoCalculator from "@/page/Weather/PrimoCalculator";
// import TwoDayWeather from "@/page/Weather/TwoDayWeather";
// import AccurateWeather from "@/page/Weather/AccurateWeather";
// import DiseaseMap from "@/page/Weather/DiseaseMap";
// import DiseaseRisk from "@/page/Weather/DiseaseRisk";

import { Navigate, createBrowserRouter, isRouteErrorResponse, useRouteError } from "react-router-dom";
// const CornYieldRisk = React.lazy(() => import("@/page/Weather/CornYieldRisk"));
// const FiveDayWeather = React.lazy(() => import("@/page/Weather/FiveDayWeather"));
// const HistTemperatureRisk = React.lazy(() => import("@/page/Weather/HistTemperatureRisk"));
// const PrimoCalculator = React.lazy(() => import("@/page/Weather/PrimoCalculator"));
// const TwoDayWeather = React.lazy(() => import("@/page/Weather/TwoDayWeather"));
// const AccurateWeather = React.lazy(() => import("@/page/Weather/AccurateWeather"));
// const DiseaseMap = React.lazy(() => import("@/page/Weather/DiseaseMap"));
// const DiseaseRisk = React.lazy(() => import("@/page/Weather/DiseaseRisk"));
// const HistDiseaseChart = React.lazy(() => import("@/page/Weather/HistDiseaseChart"));
// const HistWeatherChart = React.lazy(() => import("@/page/Weather/HistWeatherChart"));
// const HistWeatherGreenChart = React.lazy(() => import("@/page/Weather/HistWeatherGreenChart"));
// const HistWeather = React.lazy(() => import("@/page/Weather/HistWeather"));
// const SprayerSetupCalculators = React.lazy(() => import("@/page/Weather/SprayerSetupCalculators"));
// const UnitConversionsCalculators = React.lazy(() => import("@/page/Weather/UnitConversionsCalculators"));
// const AIS = React.lazy(() => import("@/page/Weather/AIS"));
// const DosatronCalculator = React.lazy(() => import("@/page/Weather/DosatronCalculator"));
// const Widgets = React.lazy(() => import("@/page/Weather/Widgets"));

import CornYieldRisk from "@/page/Weather/CornYieldRisk";
import TurfForecastWeatherData from "@/page/Weather/TurfForecastWeatherData";
import HistTemperatureRisk from "@/page/Weather/HistTemperatureRisk";
import PrimoCalculator from "@/page/Weather/PrimoCalculator";
import TwoDayWeather from "@/page/Weather/TwoDayWeather";
import ForecastWeatherData from "@/page/Weather/ForecastWeatherData";
import TurfDiseaseRiskMap from "@/page/Weather/TurfDiseaseRiskMap";
import DiseaseRiskmap from "@/page/Weather/DiseaseRiskmap";
import HistoricalDisease from "@/page/Weather/HistoricalDisease";
import HistoricalWeatherData from "@/page/Weather/HistoricalWeatherData";
import HistWeatherGreenChart from "@/page/Weather/HistWeatherGreenChart";
import HistWeather from "@/page/Weather/HistWeather";
import SprayerSetupCalculators from "@/page/Weather/SprayerSetupCalculators";
import UnitConversionsCalculators from "@/page/Weather/UnitConversionsCalculators";
import AIS from "@/page/Weather/AIS";
import DosatronCalculator from "@/page/Weather/DosatronCalculator";
import ForecastWeatherWidget from "@/page/Weather/ForecastWeatherWidget";
import ForecastWeatherMap from "@/page/Weather/ForecastWeatherMap";
import CreateQuery from "@/page/Simulation/CreateQuery";
import MyQueries from "@/page/Simulation/MyQueries";
import HeatMap from "@/page/HeatMap";
import OrnamentalsForecastWeatherData from "@/page/Weather/OrnamentalsForecastWeatherData";
import { uploadErrorLogInfo } from "@/util/http";
// for Quantis
import VisualizationBaseLayout from "@/page/Quantis/VisualizationBaseLayout";
import Article from "@/page/Quantis/Article";
import Login from "@/page/Quantis/Login";
import Main from "@/page/Quantis/Main";
import Report from "@/page/Quantis/Report";
import ReportEdit from "@/page/Quantis/ReportEdit";
import UserManageMent from "@/page/Quantis/UserManagement";
import SignUp from "@/page/Quantis/SignUp";
import ForgetPassword from "@/page/Quantis/ForgetPassword";

interface IProps {
    routerName: string
}

export function ProjectErrorBoundary(props: IProps) {
    const error = useRouteError();

    // We only care to handle 401's at this level, so if this is not a 401
    // ErrorResponse, re-throw to let the RootErrorBoundary handle it
    if (!isRouteErrorResponse(error) || error.status !== 401) {

        const appName = props.routerName;
        uploadErrorLogInfo({
            appName,
            content: error as any
        });
    }

    return (
        <div>
        </div>
    );
}

const routers = createBrowserRouter([
    {
        path: "/",
        element: <Navigate to="index" replace />,
        errorElement: <ProjectErrorBoundary routerName="index" />
    },
    {
        path: "/forecastweatherdata",
        element:  <ForecastWeatherData />,
        errorElement: <ProjectErrorBoundary routerName="forecastweatherdata" />
    },
    {
        path: "/primo-calculator",
        element: <PrimoCalculator />,
        errorElement: <ProjectErrorBoundary routerName="primo-calculator" />
    },
    {
        path: "/two-data-weather",
        element: <TwoDayWeather />,
        errorElement: <ProjectErrorBoundary routerName="two-data-weather" />
    },
    {
        path: "/turfforecastweatherdata",
        element: <TurfForecastWeatherData />,
        errorElement: <ProjectErrorBoundary routerName="turfforecastweatherdata" />
    },
    {
        path: "/turfdiseaseriskmap",
        element: <TurfDiseaseRiskMap />,
        errorElement: <ProjectErrorBoundary routerName="turfdiseaseriskmap" />
    },
    {
        path: "/diseaseriskmap",
        element: <DiseaseRiskmap />,
        errorElement: <ProjectErrorBoundary routerName="diseaseriskmap" />
    },
    {
        path: "/historicaldisease",
        element: <HistoricalDisease />,
        errorElement: <ProjectErrorBoundary routerName="historicaldisease" />
    },
    {
        path: "/histweathergreenchart",
        element: <HistWeatherGreenChart />,
        errorElement: <ProjectErrorBoundary routerName="histweathergreenchart" />
    },
    {
        path: "/historicalweatherdata",
        element: <HistoricalWeatherData />,
        errorElement: <ProjectErrorBoundary routerName="historicalweatherdata" />
    },
    {
        path: "/histweather",
        element: <HistWeather />,
        errorElement: <ProjectErrorBoundary routerName="histweather" />
    },
    {
        path: "/corn-yield-risk",
        element: <CornYieldRisk />,
        errorElement: <ProjectErrorBoundary routerName="corn-yield-risk" />
    },
    {
        path: "/hist-temperature-risk",
        element: <HistTemperatureRisk />,
        errorElement: <ProjectErrorBoundary routerName="hist-temperature-risk" />
    },
    {
        path: "/sparyer-setup-calculators/:language/:countryCode/:token/:param4?/:param5?",
        element: <SprayerSetupCalculators />,
        errorElement: <ProjectErrorBoundary routerName="sparyer-setup-calculators" />
    },
    {
        path: "/unit-conversions-calculators/:language/:countryCode/:token/:theme?",
        element: <UnitConversionsCalculators />,
        errorElement: <ProjectErrorBoundary routerName="unit-conversions-calculators" />
    },
    {
        path: "/ais",
        element: <AIS />,
        errorElement: <ProjectErrorBoundary routerName="ais" />
    },
    {
        path: "/dosatron-calculator/:param1?/:param2?",
        element: <DosatronCalculator />,
        errorElement: <ProjectErrorBoundary routerName="dosatron-calculator" />
    },
    {
        path: "/forecastweatherwidget",
        element: <ForecastWeatherWidget />,
        errorElement: <ProjectErrorBoundary routerName="forecastweatherwidget" />
    },
    {
        path: "/forecastweathermap",
        element: <ForecastWeatherMap />,
        errorElement: <ProjectErrorBoundary routerName="forecastweathermap" />
    },
    {
        path: "/simulation/environmental-characterization",
        element: <CreateQuery />,
        errorElement: <ProjectErrorBoundary routerName="simulation/environmental-characterization" />
    },
    {
        path: "/simulation/my-queries",
        element: <MyQueries />,
        errorElement: <ProjectErrorBoundary routerName="/simulation/my-queries" />
    },
    {
        path: "/heatMap",
        element: <HeatMap />,
        errorElement: <ProjectErrorBoundary routerName="heatMap" />
    },
    {
        path: "/ornamentalsforecastweatherdata",
        element: <OrnamentalsForecastWeatherData />,
        errorElement: <ProjectErrorBoundary routerName="ornamentalsforecastweatherdata" />
    },
    {
        path: "/cropstress",
        element: <VisualizationBaseLayout/>,
        errorElement: <ProjectErrorBoundary routerName="cropstress" />,
        children: [
            {
                path: "home",
                // index: true,
                element: <Main/>,
                errorElement: <ProjectErrorBoundary routerName="cropstress-home" />,
            },
            {
                path: "terms",
                element: <Article/>,
                errorElement: <ProjectErrorBoundary routerName="cropstress-terms" />,
            },
            {
                path: "login",
                element: <Login/>,
                errorElement: <ProjectErrorBoundary routerName="cropstress-login" />,
            },
            {
                path: "report",
                element: <Report/>,
                errorElement: <ProjectErrorBoundary routerName="cropstress-report" />,
            },
            {
                path: "reportEdit",
                element: <ReportEdit/>,
                errorElement: <ProjectErrorBoundary routerName="cropstress-reportEdit" />,
            },
            {
                path: "UserManagement",
                element: <UserManageMent/>,
                errorElement: <ProjectErrorBoundary routerName="cropstress-UserManagement" />,
            },
            {
                path: "signUp",
                element: <SignUp/>,
                errorElement: <ProjectErrorBoundary routerName="cropstress-signUp" />,
            },
            {
                path: "forgetPassword",
                element: <ForgetPassword/>,
                errorElement: <ProjectErrorBoundary routerName="cropstress-forgetPassword" />,
            }
        ]
    }
]);

export default routers;