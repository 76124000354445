// New South Wales & Australian Capital Territory
const AUNA = {
    initialLatitude: -35.5,
    initialLongitude: 147,
    initialZoom: 5,
    minimumZoom: 4,
    maximumZoom: 9
};
//  "Northern Territory",
const AUNT = {
    initialLatitude: -18.03,
    initialLongitude: 133.56,
    initialZoom: 5,
    minimumZoom: 4,
    maximumZoom: 9
};
//"Queensland",
const AUQ = {
    initialLatitude: -23.4,
    initialLongitude: 146,
    initialZoom: 5,
    minimumZoom: 4,
    maximumZoom: 9
};
// "South Australia",
const AUSA = {
    initialLatitude: -33.6,
    initialLongitude: 137.1,
    initialZoom: 5,
    minimumZoom: 4,
    maximumZoom: 9
};
//"Tasmania",
const AUT = {
    initialLatitude: -41.5,
    initialLongitude: 146.1,
    initialZoom: 6,
    minimumZoom: 4,
    maximumZoom: 9
};
//"Victoria",
const AUV = {
    initialLatitude: -37.1,
    initialLongitude: 145.5,
    initialZoom: 6,
    minimumZoom: 4,
    maximumZoom: 9
};
//"Western Australia"
const AUWA = {
    initialLatitude: -25.8,
    initialLongitude: 120.56,
    initialZoom: 4,
    minimumZoom: 4,
    maximumZoom: 9
};
//"New Zealand"
const AUNZ= {
    initialLatitude: -40.8,
    initialLongitude: 174.56,
    initialZoom: 5,
    minimumZoom: 4,
    maximumZoom: 9
};

// obj
export const AURegionObj = {
    "New South Wales & Australian Capital Territory": AUNA,
    "Northern Territory": AUNT,
    "Queensland": AUQ,
    "South Australia": AUSA,
    "Tasmania": AUT,
    "Victoria": AUV,
    "Western Australia": AUWA,
    "New Zealand": AUNZ
};