import { ISettingModel } from "@/util/http/Quantis/responseType";
import { Button, Input, Modal, Select } from "antd";
import React, { useEffect, useRef, useState } from "react";
import "./index.less";
import { useDispatch } from "react-redux";
import { normalActionTypes, sagaActionTypes } from "@/redux/actions/quantisAction";
import {checkIsEnableReportsByCrops,
    getSetting,
    disableReportByCrop,
    activeReportByCrop} from "@/util/http/Quantis/index";
import {debounce} from "lodash";

const {Option} = Select;

interface IProp{
    showSettingModal: boolean
    cropValue:string
    parentSetting: ISettingModel
    allCrop: string[]
    changeShowSettingModelCallback: ()=>void
}

const SettingModal = (props:IProp)=>{



    /**
     * setting 是和 crop 关联起来的，即 crop 不同，那么 setting 中计算出来的值也会不同
     *
     * 这里有两个概念，默认进入到这个页面，是用来修改现有的 setting 的
     * 默认情况是用来更改 setting 中存放的 crop 的
     * 当我们点击了 Add New Crop 时，
     */
    const {showSettingModal,cropValue,
        parentSetting,
        allCrop,
        changeShowSettingModelCallback
    } = props;
    const dispatch = useDispatch();
    /**
     * this is setting for modal, init data is from parent setting
     * when we add or update setting ,is process this object
     */
    const [subSetting,setSubSetting] = useState<ISettingModel>(null);
    /**
     * Antd is used defaultValue
     * so when subsetting or other state value changed, the  input can't be reder
     * so we set input's key with this value, when this value changed, the key will change, so the input will re render
     */
    const [awayMessage,setAwayMessage] = useState<boolean>(false);
    /**
     * this is a flag which used to check update setting or add a new setting
     * addNewSetting  -> 只有这个时候会将 addNewSetting 的值设为 true
     * handleSetting
     * settingHandleOK
     */
    const [addOrUpdateSettingFlag,setAddOrUpdateSettingFlag] = useState<string>("Update");
    /**
     * this is the flag whick used to show Active or Disable report button
     */
    const [checkIsEnable,setCheckIsEnable] = useState<boolean>(false);
    /**
     * settingCrop is just used in Setting Modal, the init value is dependence for main page's cropValue
     * settingCrop just change by Crop Select Option change'd
     * also can say: just used in update setting
     */
    const [settingCrop,setSettingCrop] = useState<string>(null);
    /**
     * when we click the Add New Crop, will change this value
     * also can say: just used in add setting
     */
    const [newAddCrop,setNewAddCrop] = useState<string>(null);
    const handleNewAddCropChange = (value:string) =>{
        // TODO: 老的提示完重复的后，会显示重复的值，但实际添加时不会加相同的，传的是新的少一位的
        const tmpCrop = allCrop.filter( item => item === value);
        if(tmpCrop?.length === 0){
            setNewAddCrop(value);
        }
        else{
            dispatch({
                type: normalActionTypes.NORMAL_UPDATE_SHOWERRORMESSAGE,
                payload: "This crop already exists"
            });
        }
    };
    const handleNewAddCropChangeDebounceFunc = debounce(handleNewAddCropChange,30);
    useEffect(()=>{
        return ()=>{
            handleNewAddCropChangeDebounceFunc.cancel();
        };
    },[]);

    // settingHandleOk, 需要调用获取天气的组合接口
    const settingHandleOk = (saveChangesFlag?:boolean)=>{
        /**
         * saveChangesFlag is true:  mean we click save Changes button
         * saveChangesFlag is false: mean we click close button
         */
        if(saveChangesFlag){
            const subSettingKeys = Object.keys(subSetting);
            // check all value should not be empty
            let flag = false;
            subSettingKeys?.forEach((item,index)=>{
                console.log(`ddddd item: ${item} , subSettingKeys[item]: ${subSetting[item]}`);
                if(subSetting[item] < -1 || subSetting[item] === null) {
                    console.log(`ddddd error item: ${item}, subSetting[item] < -1 is ${subSetting[item] < -1},subSetting[item] === null is ${subSetting[item] === null}`);
                    flag = true;
                }
            });
            if(flag){
                dispatch({
                    type: normalActionTypes.NORMAL_UPDATE_SHOWERRORMESSAGE,
                    payload: "Cannot submit null value"
                });
                return;
            }
            if(addOrUpdateSettingFlag === "Add"){
                /**
                 * add new setting, data form subSetting object and crop from settingCrop
                 * add will invoke three function
                 *      first is addSettings
                 *      second is uploadSetting getSetting and store to redux
                 *      third  is getAllCrop
                 * when we add success, we need invovke get weather function
                 */
                dispatch({
                    type: sagaActionTypes.SAGA_ADD_NEW_SETTING,
                    payload: Object.assign(subSetting, { crop: newAddCrop })
                });
            }
            if(addOrUpdateSettingFlag === "Update"){
                /**
                 * update current subSettingn for we current used settingCrop
                 * update will invoke two function:
                 *      first is  updateSettings
                 *      second is getSetting and store to redux
                 */
                dispatch({
                    type: sagaActionTypes.SAGA_UPDATE_SETTING,
                    payload: Object.assign(subSetting, { crop: settingCrop })
                });
            }
            /**
             * 原先的逻辑是当我们更改了 setting 后，会调用新的获取天气，
             * 但是获取天气时所用的并非 setting 中的 crop，而是原先 main 页面中的 所有值
             * 因此只有当 setting Modal 中操作的 crop 和 main 中的 cropValue 相同，同时更改了 setting，页面的天气才能看到变化
             */
        }
        else{
            setAwayMessage(!awayMessage);
            setSubSetting(parentSetting);
        }
        // close setting Modal
        changeShowSettingModelCallback();
    };

    const handleCancel = ()=>{
        console.log("dddd flag: handleCancel");
        /**
         * callback function, to set showSettingModel to false
         * when we open this Modal, all value will init when showSettingModel is true,
         * so we should do more nothing
         */
        changeShowSettingModelCallback();
    };
    // invoke when we click the Active or Disable Report Button
    const isDisable = async()=>{
        /**
         * when checkIsEnable is true, should shou Disable
         * checkIsEnable is true
         *      : invoke --  disableReportsByCrops, and page show - Active reports
         * checkIsEnable is false
         *      : invoke --  activeReportsByCrops,  and page show - Disable reports
         */
        if(checkIsEnable){
            const res = await disableReportByCrop(settingCrop);
            console.log("active res",res.data);
            if(res.data){
                dispatch({
                    type: normalActionTypes.NORMAL_UPDATE_SHOWSUCCESSMESSAGE,
                    payload: "Reports have been disabled for this crop"
                });
                setCheckIsEnable(false);
            }
        }
        else{
            const res = await activeReportByCrop(settingCrop);
            if(res.data){
                dispatch({
                    type: normalActionTypes.NORMAL_UPDATE_SHOWSUCCESSMESSAGE,
                    payload: "Reports have been activated for this crop"
                });
                setCheckIsEnable(true);
            }
        }
        console.log("current click value",checkIsEnable);
    };
    // invock in we click Add New Crop button, will init sutSetting to empty
    const addNewSetting = ()=>{
        setAddOrUpdateSettingFlag("Add");
        setSubSetting({
            criticalTemperature: null,
            periodHours: null,
            definitionTemperature: null,
            greenNozzleDefinition: null,
            orangeNozzleDefinition: null,
            redNozzleDefinition: null
        });
        setAwayMessage(!awayMessage);
    };
    // used in we chage crop option, just in udpate setting
    const settingCropOptionChange = async(value:any,init?:boolean)=>{
        // should invoke checkIsEnableReportsByCrops function
        if(!init) setSettingCrop(value);
        try{
            //get new setting
            dispatch({
                type: sagaActionTypes.SAGA_GET_SETTING,
                payload: value
            });
            //checkIsEnableReport
            const res = await checkIsEnableReportsByCrops(value);
            if(res.data === false){
                setCheckIsEnable(false);
            }
            else if(res.data === true){
                setCheckIsEnable(true);
            }
        }
        catch(error){
            console.log(error);
        }
    };

    // used in we change setting detail's value, to modify subSetting object, this is most important function
    const settingOnChange = (value:any,type:string)=>{
        /**
         * this function will invoke when input lose the blur
         * we will check the value, and set the value
         */
        console.log(`aa settingOnChange: value is ${value}, type is ${type}`);
        // console.log(`aa settingOnChange: subSettingRef.current is ${subSettingRef.current.criticalTemperature}`);
        const bar = false;
        const tmpSubsetting = {...subSetting};
        // const tmpSubsetting = subSettingRef.current;
        if(!value){
            dispatch({
                type: normalActionTypes.NORMAL_UPDATE_SHOWERRORMESSAGE,
                payload: "Cannot submit null"
            });
            return;
        }
        /**
         * a:   first button:   criticalTemperature
         *      second button:  periodHours
         *      third button:   definitionTemperature
         *
         * rules:
         *      definitionTemperature must large than criticalTemperature
         *      when change criticalTemperature, if criticalTemperature large than definitionTemperature, set criticalTemperature value: definitionTemperature -1
         *      when change definitionTemperature, if definitionTemperature samll than criticalTemperature, set definitionTemperature : criticalTemperature + 1
         */
        if(type === "criticalTemperature"){
            if (( tmpSubsetting.definitionTemperature > 0) && tmpSubsetting.definitionTemperature <= Number(value)) {
                Modal.confirm({
                    title: "Confirm",
                    content: `This value must be less than ${tmpSubsetting.definitionTemperature}`,
                    okText: "ok",
                });
                tmpSubsetting.criticalTemperature = tmpSubsetting.definitionTemperature - 1;
            } else {
                tmpSubsetting.criticalTemperature = Number(value);
            }
        }
        if ( type === "definitionTemperature") {
            if ( (tmpSubsetting.criticalTemperature > 0) && tmpSubsetting.criticalTemperature >= Number(value)) {
                Modal.confirm({
                    title: "Confirm",
                    content: `This value must be greater than ${tmpSubsetting.criticalTemperature}`,
                    okText: "ok",
                });
                tmpSubsetting.definitionTemperature = tmpSubsetting.criticalTemperature + 1;
            } else {
                tmpSubsetting.definitionTemperature = Number(value);
            }
        }

        /**
         * the value had limit, it is between 1 and 24
         */
        if (type == "periodHours") {
            tmpSubsetting.periodHours = Number(value);
        }

        /**
         * b: first button:   redNozzleDefinition
         *    second button:  definitionTemperature, this value is used in a
         *    third button:   orangeNozzleDefinition
         *    four button:    greenNozzleDefinition
         *
         * rules:
         *      first button(redNozzleDefinition) is
         */
        if (
            type === "greenNozzleDefinition" ||
            type === "orangeNozzleDefinition" ||
            type === "redNozzleDefinition"
        ) {
            if (type == "redNozzleDefinition") {
                tmpSubsetting.redNozzleDefinition = Number(value);
                tmpSubsetting.greenNozzleDefinition = Number(value) - 1;
                tmpSubsetting.orangeNozzleDefinition = Number(value) -1;
            }
            if (type === "orangeNozzleDefinition" || type === "greenNozzleDefinition") {
                tmpSubsetting.redNozzleDefinition = Number(value) + 1;
                tmpSubsetting.greenNozzleDefinition = Number(value);
                tmpSubsetting.orangeNozzleDefinition = Number(value);
            }
        }
        setSubSetting(tmpSubsetting);
        setAwayMessage(!awayMessage);
        console.log("settingOnChange type",value);
    };

    // used init subSetting and setting crop value,
    useEffect(()=>{
        if(parentSetting && showSettingModal){
            setSubSetting(parentSetting);
            setAwayMessage(!awayMessage);
        }
        setAddOrUpdateSettingFlag("Update");
        // if(cropValue && showSettingModal){
        //     setSettingCrop(cropValue);
        //     settingCropOptionChange(cropValue,true);// used to set checkDisbase status
        // }
        // if(showSettingModal){
        //     setNewAddCrop(null);
        //     setAddOrUpdateSettingFlag("Update");
        //     setAwayMessage(!awayMessage);
        // }
    },[parentSetting,showSettingModal]);

    useEffect(()=>{
        if(cropValue && showSettingModal){
            setSettingCrop(cropValue);
            settingCropOptionChange(cropValue,true);// used to set checkDisbase status
        }
    },[cropValue,showSettingModal]);

    return(
        <div className="setting-modal-container" id="setting-modal-container">
            <Modal
                title="Setting"
                getContainer = {()=> document.getElementById("setting-modal-container") as HTMLElement}
                open = {showSettingModal}
                onOk={()=>{settingHandleOk(null);}}
                onCancel={handleCancel}
                footer={[
                    <Button
                        key="close"
                        type="default"
                        onClick={(e) => settingHandleOk(false)}
                    >
                        Close
                    </Button>,
                    <Button
                        key="submit"
                        type="primary"
                        className="setting-modal-button"
                        onClick={(e) => settingHandleOk(true)}
                    >
                        Save changes
                    </Button>,
                ]}
            >
                {(() => {
                    if (subSetting != null) {
                        return (
                            <div style={{ fontSize: 15, lineHeight: "30px" }}>
                                <div style={{ margin: "10px 0" }}>
                                    <Button type="primary" className="setting-modal-button" onClick={(e) => addNewSetting()}>
                                            Add New Crop
                                    </Button>
                                </div>
                                {addOrUpdateSettingFlag === "Add" ? "Add" : "Choose"} a crop:&nbsp;&nbsp;&nbsp;&nbsp;
                                {addOrUpdateSettingFlag === "Add" ? (
                                    <Input
                                        onChange={(e) => handleNewAddCropChangeDebounceFunc(e.target.value)}
                                        // onChange={(e) => handleNewAddCropChangeDebounceFunc(e.target.value)}
                                        value={newAddCrop}
                                        style={{ width: 200,height: 32 }}
                                        size="small"
                                        className="add-crop-input"
                                    />
                                ) : (
                                    <Select
                                        defaultValue={cropValue}
                                        placeholder="Crop"
                                        value={settingCrop}
                                        onChange={(e) => settingCropOptionChange(e)}
                                        style={{ width: 200 }}
                                    >
                                        {allCrop
                                            ? allCrop.map((item: any, index: number) => (
                                                <Option key={index} value={item}>
                                                    {item}
                                                </Option>
                                            ))
                                            : []}
                                    </Select>
                                )}
                                <Button
                                    style={{ float: "right" }}
                                    type="primary"
                                    onClick={(e) => isDisable()}
                                    className="setting-modal-button"
                                >
                                    {checkIsEnable ? "Disable reports" : "Active reports"}
                                </Button>
                                <br />
                                <p>a. Heat event definition</p>
                                <p>
                  Temperature &gt;
                                    <Input
                                        key={awayMessage ? "a" : "b"}
                                        defaultValue={subSetting.criticalTemperature}
                                        type="number"
                                        min = {0}
                                        onBlur={(e) => settingOnChange(e.target.value, "criticalTemperature")}
                                        style={{ width: 55 }}
                                        size="small"
                                    />
                  ℃ during &nbsp;
                                    <Input
                                        key={awayMessage ? "c" : "d"}
                                        defaultValue={subSetting.periodHours}
                                        type="number"
                                        min={1}
                                        max={24}
                                        onBlur={(e) => settingOnChange(e.target.value, "periodHours")}
                                        style={{ width: 55 }}
                                        size="small"
                                    />{" "}
                  hours per day or anytime when temperature &gt;
                                    <Input
                                        key={awayMessage ? "e" : "f"}
                                        defaultValue={subSetting.definitionTemperature}
                                        type="number"
                                        min = {0}
                                        onBlur={(e) => settingOnChange(e.target.value, "definitionTemperature")}
                                        style={{ width: 55 }}
                                        size="small"
                                    />
                  ℃
                                </p>
                                <br />
                                <p>b. Add a line with the number of heat events/day</p>
                                <p>
                  If number of heat events &gt;=
                                    <Input
                                        key={awayMessage ? "ac" : "ad"}
                                        defaultValue={subSetting.redNozzleDefinition}
                                        type="number"
                                        min = {0}
                                        onBlur={(e) => settingOnChange(e.target.value, "redNozzleDefinition")}
                                        style={{ width: 55 }}
                                        size="small"
                                    />
                  &nbsp; or templature &gt;
                                    <Input
                                        key={awayMessage ? "e" : "f"}
                                        defaultValue={subSetting.definitionTemperature}
                                        type="number"
                                        min = {0}
                                        onBlur={(e) => settingOnChange(e.target.value, "definitionTemperature")}
                                        style={{ width: 55 }}
                                        size="small"
                                    />
                  ℃ is red nozzle (apply),&nbsp;
                                    <Input
                                        key={awayMessage ? "ab" : "ae"}
                                        defaultValue={subSetting.orangeNozzleDefinition}
                                        type="number"
                                        min = {-1}
                                        onBlur={(e) => settingOnChange(e.target.value, "orangeNozzleDefinition")}
                                        style={{ width: 55 }}
                                        size="small"
                                    />{" "}
                  is orange nozzle, &lt;
                                    <Input
                                        key={awayMessage ? "g" : "h"}
                                        defaultValue={subSetting.greenNozzleDefinition}
                                        type="number"
                                        min = {-1}
                                        onBlur={(e) => settingOnChange(e.target.value, "greenNozzleDefinition")}
                                        style={{ width: 55 }}
                                        size="small"
                                    />{" "}
                  is green nozzle(not apply)
                                </p>
                            </div>
                        );
                    }
                })()}
            </Modal>
        </div>
    );
};

export default React.memo(SettingModal);