import React, { useCallback, useEffect, useRef, useState } from "react";
import html2canvas from "html2canvas";
import { Download_Svg } from "./components/HistWeatherIcon";
import { Input, Spin } from "antd";
import "./index.less";
import { useLocation } from "react-router-dom";
import IF from "@/components/IF";
import EchartRander from "./components/EchartRander";
import useWeatherData from "./components/SelfHooks/useWeatherData";
import dayjs from "dayjs";
import { RootState } from "@/redux/reducer/store";
import { useDispatch , useSelector} from "react-redux";
import AgForcastButton from "@/components/ForecastProviderButton";
import AGForcastButton from "@/components/ForecastProviderButton";
import useWeatherTranslate from "./components/SelfHooks/useWeatherTranslate";
import useSeries from "./components/SelfHooks/useSeries";
const customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(customParseFormat);

interface ISearchObj{
    theme?: string;
    country?: string;
    language?: string;
    lat?: number;
    long?: number;
    startdate?: string;
    enddate?: string;
    unit?: string;
    gddmax?: number;
    gddmin?: number;
}
const CountryDateFormat = {
    "AE": "DD/MM/YYYY",
    "AU": "DD/MM/YYYY",
    "BY": "DD.MM.YY",
    "CH": "DD.mm.YY",
    "DE": "DD.MM.YYYY",
    "NZ": "DD-MM-YYYY",
    "US": "MM/DD/YYYY",
    "ES": "DD/MM/YYYY",
    "FR": "DD/MM/YYYY",
    "GB": "DD/MM/YYYY",
    "IL": "DD/MM/YYYY",
    "JP": "YYYY/MM/DD",
    "KR": "YYYY-MM-DD",
    "PL": "DD.MM.YYYY",
    "PT": "DD-MM-YYYY",
    "SE": "YYYY-MM-DD",
    "SI": "DD.MM.YYYY",
    "TR": "DD.MM.YYYY",
    "ZA": "DD/MM/YYYY"
};

const agWeather = ()=>{

    const urlLocation = useLocation();

    const {
        showLodding,
        isExecuteDone
    } = useSelector((state: RootState) => state.diseaseReducer);
    const {
        homeIndexLoading,
    } = useSelector((state: RootState) => state.commonReducer);

    // useEffect(() => {
    //     function handleResize() {
    //         const width = window.innerWidth;
    //         if (width <= 500) {
    //             setScreenSize("miniPhone");
    //         } else if (width <= 1000) {
    //             setScreenSize("phone");
    //         } else {
    //             setScreenSize("index");
    //         }
    //         console.log("lanxue index width",width);
    //     }

    //     window.addEventListener("resize", handleResize);
    //     handleResize(); // 初始化时调用一次

    //     return () => {
    //         window.removeEventListener("resize", handleResize);
    //     };
    // }, [window.innerWidth]);

    // add google analysis
    useEffect(() => {
        console.log("just invoke once");
        const head = document.getElementsByTagName("head")[0];
        const googleAnalyticsCode = "G-F8EZJMCB9J";
        const googleAnalyticsFirstScript = document.createElement("script");
        googleAnalyticsFirstScript.async = true;
        googleAnalyticsFirstScript.src =
          "https://www.googletagmanager.com/gtag/js?id=" + googleAnalyticsCode;

        const googleAnalyticsSecondScript = document.createElement("script");
        googleAnalyticsSecondScript.innerHTML =
          "    window.dataLayer = window.dataLayer || [];\n" +
          "    function gtag(){dataLayer.push(arguments);}\n" +
          "    gtag('js', new Date());\n" +
          "\n" +
          "    gtag('config', '" +
          googleAnalyticsCode +
          "',{\n" +
          "cookie_flags:'max-age=7200;secure;samesite-none'" +
          "});";

        head.insertBefore(googleAnalyticsSecondScript, head.firstChild);
        head.insertBefore(googleAnalyticsFirstScript, head.firstChild);
    }, []);

    // static value
    const pubname = "geotroll1";
    const servicepage = "HistoryData_ECMWF";
    const unit = "imperial";

    // set the search obj
    const [searchObj,setSearObj] = useState<ISearchObj>({
        theme: "light",
        country: "GB",
        language: "en",
        long: 0.1278,
        lat: 51.5074,
        startdate: dayjs().subtract(1,"day").subtract(1,"month").format("DD/MM/YYYY"),
        enddate: dayjs().subtract(2,"day").format("DD/MM/YYYY"),
        unit: "imperial",
        gddmax: 140,
        gddmin: 32
    });

    useEffect(()=>{
        document.body.style.backgroundColor = searchObj?.theme === "light" ? "white" : "black";
    },[searchObj?.theme]);

    const initSearchObj = ()=>{
        const searchStr = location?.search.replace("?","");
        const searchArr = searchStr?.split("&");
        const tmpSearchObj = {...searchObj};
        let tmpStartDate = "";
        let tmpEndDate = "";
        let tmpCountry = "";
        searchArr?.map((item)=>{
            const searchKeyValue = item?.split("=");
            if( searchKeyValue[0].toLowerCase() === "lat" ||
                searchKeyValue[0].toLowerCase() === "long" ||
                searchKeyValue[0].toLowerCase() === "gddmax" ||
                searchKeyValue[0].toLowerCase() === "gddmin"
            ){
                tmpSearchObj[searchKeyValue[0].toLowerCase()] = Number(searchKeyValue[1]);
            }
            else if(searchKeyValue[0].toLowerCase() === "startdate"){
                // tmpSearchObj[searchKeyValue[0].toLowerCase()] = dayjs(searchKeyValue[1],tmpFormat).format("DD/MM/YYYY");
                tmpSearchObj[searchKeyValue[0].toLowerCase()] = searchKeyValue[1];
                tmpStartDate = searchKeyValue[1];
            }
            else if(searchKeyValue[0].toLowerCase() === "enddate"){
                // tmpSearchObj[searchKeyValue[0].toLowerCase()] = dayjs(searchKeyValue[1],tmpFormat).format("DD/MM/YYYY");
                tmpSearchObj[searchKeyValue[0].toLowerCase()] = searchKeyValue[1];
                tmpEndDate = searchKeyValue[1];
            }
            else if(searchKeyValue[0].toLowerCase() === "country"){
                // tmpSearchObj[searchKeyValue[0].toLowerCase()] = dayjs(searchKeyValue[1],tmpFormat).format("DD/MM/YYYY");
                tmpSearchObj[searchKeyValue[0].toLowerCase()] = searchKeyValue[1];
                tmpCountry = searchKeyValue[1];
            }
            else{
                tmpSearchObj[searchKeyValue[0].toLowerCase()] = searchKeyValue[1];
            }
        });
        const tmpFormat = CountryDateFormat[tmpCountry] || "DD/MM/YYYY";
        tmpSearchObj.startdate = dayjs(tmpStartDate,[tmpFormat,"YYYY-MM-DD"]).format("DD/MM/YYYY");
        const tmpDate = dayjs(tmpEndDate,[tmpFormat,"YYYY-MM-DD"]);
        const tmpYesterday = dayjs().subtract(1,"day");
        const tmpFinalEndDate = tmpDate.isAfter(tmpYesterday) ? tmpYesterday : tmpDate;
        // tmpSearchObj.enddate = dayjs(tmpEndDate,[tmpFormat,"YYYY-MM-DD"]).format("DD/MM/YYYY");
        tmpSearchObj.enddate = tmpFinalEndDate.format("DD/MM/YYYY");
        setSearObj(tmpSearchObj);
    };

    // download the image
    const saveMapToDataUrl = ()=>{
        const element = document.getElementById("chartContentDiv");
        const element1 = document.querySelector(".rightLatLng") as HTMLElement;
        element1.style.display = "block";
        if(element){
            html2canvas(element,{
                useCORS: true
            }).then((canvas)=>{
                const dataUrl = canvas.toDataURL("image/png");
                console.log("test images",dataUrl);

                const link = document.createElement("a");
                link.href = dataUrl;
                link.download = searchObj.country + ".png";
                link.click();
                element1.style.display = "none";
            });
        }
    };

    // chart type
    const [chartOptions,setChartOptions] = useState([
        {
            chart_name: "Air Temperature",
            chart_id: "Air Temperature",
            chart_echart_y_name: "Air Temperature",
            isChecked: true,
            colorAndId: [
                {
                    risk_name: "Air Temperature Max",
                    risk_id: "tempair_c_daytimemax_AltAdj",
                    risk_color: "#E99921"
                },
                {
                    risk_name: "Air Temperature Min",
                    risk_id: "tempair_c_nighttimemin_AltAdj",
                    risk_color: "#31B4F2"
                }
            ]
        },
        {
            chart_name: "Accumulated GDD",
            chart_id: "Accumulated GDD",
            chart_echart_y_name: "Accumulated GDD",
            isChecked: false,
            colorAndId: [
                {
                    risk_name: "Daily GDD",
                    risk_id: "gdd_c_dailysum",
                    risk_color: "#14803C"
                },
                {
                    risk_name: "Daily Accumulative",
                    risk_id: "gdd_c_accumulated",
                    risk_color: "#005693"
                }
            ]
        }
    ]);

    const publicChangeChartOptions = (value:boolean,index:number) =>{
        const tmpChartOptions = [...chartOptions];
        tmpChartOptions[index].isChecked = value;

        setChartOptions(tmpChartOptions);
    };



    // color and id array, echart data used in Echarts components
    const [selectedType,setSelectedType] = useState<string[]>([]);
    const [colorArray,setColorArray] = useState<string[]>([]);
    const [idArray,setIdArray] = useState<string[]>([]);
    const [echartSeriesName,setEchartSeriesName] = useState<string[]>([]);
    const echartContainerRef = useRef(null);

    // get echart data and translate
    const {echartData} = useWeatherData({
        country: searchObj.country,
        language: searchObj.language,
        latitude: searchObj.lat,
        longitude: searchObj.long,
        unit: searchObj.unit,
        startdate: searchObj.startdate,
        enddate: searchObj.enddate,
        gddmax: searchObj.gddmax,
        gddmin: searchObj.gddmin,
        isExecute: isExecuteDone
    });
    const {weacherTranslate} = useWeatherTranslate({
        countryCode: searchObj.country,
        language: searchObj.language
    });
    const {seriesOptions} = useSeries({
        countryCode: searchObj.country,
        culture: searchObj.language + "-" + searchObj.country,
        unit: searchObj.unit,
        servicepage: servicepage,
        pubname: pubname
    });

    const TemperatureUnitObj = {
        "imperial": "°F",
        "metric": "℃"
    };

    const getSplitName = (text: string)=>{
        let tmpName = "";
        const tmpText = text?.split(" ");
        if(tmpText?.length >= 3){
            tmpName = tmpText[0] + " " + tmpText[1] + " " + tmpText[2];
        }
        return tmpName;
    };


    // =============== useEffuct =================
    useEffect(() => {
        // init the searchobj, and the value data is form the url
        initSearchObj();
    }, [urlLocation]);
    // change color and id array, which used in the Echart components
    useEffect(()=>{
        const tmpColorArray = [];
        const tmpIdArray = [];
        const tmpSelectedType = [];
        const tmpEchartSeriesName = [];
        const tmpSelectedObj = [];
        chartOptions?.forEach((item)=>{
            if(item?.isChecked){
                item?.colorAndId?.forEach((innerItem)=>{
                    tmpColorArray.push(innerItem.risk_color);
                    tmpIdArray.push(innerItem.risk_id);
                    tmpEchartSeriesName.push(innerItem.risk_name);
                });
                // change selected type
                if(item?.chart_id === "Air Temperature"){
                    tmpSelectedType.push(item?.chart_name + " " + TemperatureUnitObj[searchObj?.unit]);
                }
                else{
                    // tmpSelectedType.push(item?.chart_name);
                    tmpSelectedType.push(item?.chart_echart_y_name);
                }
            }
        });
        setColorArray(tmpColorArray);
        setIdArray(tmpIdArray);
        setEchartSeriesName(tmpEchartSeriesName);
        setSelectedType(tmpSelectedType);
    },[chartOptions]);
    useEffect(()=>{
        if(weacherTranslate || seriesOptions?.length > 0){
            const tmpChartOptions = [...chartOptions];
            tmpChartOptions[0].chart_name = weacherTranslate.txt_AirTemperature;
            tmpChartOptions[1].chart_name = weacherTranslate.txt_AccumulatedGDD;
            tmpChartOptions[1].chart_echart_y_name = weacherTranslate.txt_GDD;

            // setColorAndId
            // for Air Temperature Max and Air Temperature Min
            // tmpChartOptions[0].colorAndId[0].risk_name = seriesOptions[1]?.text;
            // tmpChartOptions[0].colorAndId[1].risk_name = seriesOptions[2]?.text;
            tmpChartOptions[0].colorAndId[0].risk_name = getSplitName(seriesOptions[1]?.text);
            tmpChartOptions[0].colorAndId[1].risk_name = getSplitName(seriesOptions[2]?.text);
            tmpChartOptions[1].colorAndId[0].risk_name = seriesOptions[3]?.text;
            tmpChartOptions[1].colorAndId[1].risk_name = seriesOptions[4]?.text;

            setChartOptions(tmpChartOptions);
        }
    },[weacherTranslate,seriesOptions]);

    // const [screenSize,setScreenSize] = useState("index");



    console.log("echart data",echartData);
    console.log("ddddd chartOptions",chartOptions);
    console.log("echart render width",echartContainerRef.current?.clientWidth);
    console.log("searchObj",searchObj);
    // console.log("lanxue index screenSize",screenSize);
    console.log("lanxue index window.innerWidth",window.innerWidth);


    return(
        // <div className={`${}`}>
        <div className="hist-weather-container">
            <div className={`bg-${searchObj.theme}`}>

                <Spin spinning={showLodding || homeIndexLoading}>
                    <div className={"hist-weather"}>
                        {/* title */}
                        <div className="hist-weather-title">
                            <div className="hist-weather-title-left">
                                <span>
                                    {/* Select the options below to add or remove graph parameters */}
                                    {weacherTranslate?.txt_SelOptionsAddRemoveGraph || "Select the options below to add or remove graph paramers"}
                                </span>
                            </div>
                            <div className="hist-weather-title-right"
                                style={{cursor:"pointer"}}
                                onClick={saveMapToDataUrl}
                            >
                                <Download_Svg
                                    width={18}
                                    height={18}
                                />
                                <span>
                                    {/* Download as Image */}
                                    {weacherTranslate?.txt_DownloadAsImage || "Download as Image"}
                                </span>
                            </div>
                        </div>
                        {/* Echarts content */}
                        <div id="chartContentDiv" className="hist-weather-echart-container">
                            <div className="hist-weather-echarts-select">
                                <div>
                                    {
                                        chartOptions?.map((item,index)=>{
                                            return(
                                                <>
                                                    <div className="hist-weather-echarts-select-item">
                                                        <Input
                                                            type="checkbox"
                                                            checked = {item?.isChecked}
                                                            className="item-checkbox"
                                                            onChange={(e)=>{
                                                                publicChangeChartOptions(e.target.checked,index);
                                                            }}
                                                        />
                                                        <span>{item?.chart_name}</span>
                                                    </div>
                                                </>
                                            );
                                        })
                                    }
                                </div>
                                <div>
                                    <span style={{display:"none"}} className="rightLatLng">{` long: ${searchObj?.long} , lat: ${searchObj?.lat}`}</span>
                                </div>
                            </div>
                            {/* Echart components */}
                            <div className="hist-weather-echart-content"
                                ref = {echartContainerRef}
                            >
                                <EchartRander
                                    selectedType={selectedType}
                                    selectedCountry={searchObj.country}
                                    echartData={echartData}
                                    colorArray={colorArray}
                                    idArray={idArray}
                                    echartSeriesName={echartSeriesName}
                                    width={echartContainerRef.current?.clientWidth}
                                />
                            </div>

                            {/* Echart markers */}
                            <div className="hist-weather-echarts-markers-list">
                                {/* Temperature */}
                                <IF show={chartOptions[0].isChecked}>
                                    <div className="markers-container">
                                        <div className="hist-weather-echarts-markers-list-item">
                                            <span>
                                                {/* Air Temperature Max */}
                                                {chartOptions[0]?.colorAndId[0]?.risk_name || "Air Temperature Max"}
                                            </span>
                                            <div className="air-max-item base-item"></div>
                                        </div>
                                        <div className="hist-weather-echarts-markers-list-item">
                                            <span>
                                                {/* Air Temperature Min */}
                                                {chartOptions[0]?.colorAndId[1]?.risk_name || "Air Temperature Min"}
                                            </span>
                                            <div className="air-min-item base-item"></div>
                                        </div>
                                    </div>
                                </IF>

                                {/* Gdd */}
                                <IF show={chartOptions[1].isChecked}>
                                    <div className="markers-container">
                                        <div className="hist-weather-echarts-markers-list-item">
                                            <span>
                                                {/* Daily GDD */}
                                                {chartOptions[1]?.colorAndId[0]?.risk_name || "Daily GDD"}
                                            </span>
                                            <div className="daily-gdd base-item"></div>
                                        </div>
                                        <div className="hist-weather-echarts-markers-list-item">
                                            <span>
                                                {/* Daily Accumulative */}
                                                {chartOptions[1]?.colorAndId[1]?.risk_name || "Daily Accumulative"}
                                            </span>
                                            <div className="daily-accumulative base-item"></div>
                                        </div>
                                    </div>
                                </IF>
                            </div>
                        </div>
                        {/* forcast button */}
                        <AGForcastButton/>
                    </div>
                </Spin>
            </div>
        </div>

    );
};

export default agWeather;


