import { Axios, AxiosResponse } from "axios";
import { http, ServerType } from "../http";
import {
    IGenerateLocationByCoordinateReqModel,
    IGetAltitudeReqModel,
    IGetDiseaseRiskInfoByNameReqModel,
    IGetDiseaseRisksLocationAndValueByNameReqModel,
    IGetDropdownTranslationReqModel,
    IGetHistoricCharInfoReqModel,
    IGetHistoricDiseaseCharInfoReqModel,
    IGetHistoricDiseaseReqModel,
    IGetHistoricalWeatherCharInfo,
    IGetHistoricalWeatherSeriesReqModel,
    IGetSeriesReqModel,
    IPostSendEmailReqModel } from "./requestType";
import {
    IDiseaseRiskInfoByNameResModel,
    IGenerateLocationByCoordinateResModel,
    IGetDiseaseRisksLocationAndValueByNameResModel,
    IGetDropdownTranslationResModel,
    IGetHistWeatherChatInfoResModel,
    IGetHistoricCharInfoResModel,
    IGetHistoricDiseaseCharInfoResModel,
    IGetHistoricWeatherSeriesResModel,
    IGetSeriesResModel,
    IPostSentEmailResModel } from "./responseType";
import { ILocationModel } from "../responseType";

//============= public function =================
const getDropdownTranslation = async (data: IGetDropdownTranslationReqModel) => {
    const url = "/api/Translation/GetDropdownTranslation";
    const res = await http.get<IGetDropdownTranslationReqModel,AxiosResponse<IGetDropdownTranslationResModel[]>>(url,
        data,
        ServerType.ServerLocal,
        false
    );
    return res;
};
const postSentEmail = async (data: IPostSendEmailReqModel) => {
    const url = "/api/Chart/PostSendEmail";
    const res = await http.post<IPostSendEmailReqModel,AxiosResponse<IPostSentEmailResModel>>(url,
        data,
        ServerType.ServerLocal,
        false
    );
    return res;
};
// const generateLocationByCoordinate = async (data: IGenerateLocationByCoordinateReqModel) => {
//     const url = "/api/LocationSearch/GenerateLocationByCoordinate";
//     const res = await http.get<IGenerateLocationByCoordinateReqModel,AxiosResponse<IGenerateLocationByCoordinateResModel>>(url,
//         data,
//         ServerType.ServerNormal,
//         false
//     );
//     return res;
// };
const generateLocationByCoordinate = async (data:IGenerateLocationByCoordinateReqModel) => {
    const url = "/api/LocationSearch/GetLocationByNameOrCoordinate";
    console.log("new search",data);
    const res = await http.get<IGenerateLocationByCoordinateReqModel,AxiosResponse<any[]>>(url,
        data,
        ServerType.ServerLocal,
        false
    );
    return res;
};

//============= Hist Disease =====================
const getHistoricDiseaseSeries = async (data: IGetHistoricDiseaseReqModel) => {
    const url = "/api/WeatherDiseaseMap/GetHistoricDiseaseSeries";
    const res = await http.get<IGetHistoricDiseaseReqModel,AxiosResponse<IGetHistoricWeatherSeriesResModel>>(url,
        data,
        ServerType.ServerLocal,
        false
    );
    return res;
};

const getHistoricDiseaseCharInfo= async (data: IGetHistoricDiseaseCharInfoReqModel) => {
    const url = "/api/WeatherDiseaseMap/GetHistoricalDiseaseCharInfo";
    const res = await http.get<IGetHistoricDiseaseCharInfoReqModel,AxiosResponse<IGetHistoricDiseaseCharInfoResModel>>(url,
        data,
        ServerType.ServerLocal,
        false
    );
    return res;
};

//============= Hist Weather Chart =================
const getSeries = async (data: IGetSeriesReqModel) => {
    const url = "/api/Chart/GetSeries";
    const res = await http.get<IGetSeriesReqModel,AxiosResponse<IGetSeriesResModel[]>>(url,
        data,
        ServerType.ServerLocal,
        false
    );
    return res;
};
const getHistoricalCharInfo = async (data:IGetHistoricCharInfoReqModel) => {
    const url = "/api/Chart/GetHistoricalCharInfo";
    const res = await http.get<IGetHistoricCharInfoReqModel,AxiosResponse<IGetHistoricCharInfoResModel>>(url,
        data,
        ServerType.ServerLocal,
        false
    );
    return res;
};
const getAltitude = async (data:IGetAltitudeReqModel) => {
    const url = "/api/Chart/GetAltitude";
    const res = await http.get<IGetAltitudeReqModel,AxiosResponse<number>>(url,
        data,
        ServerType.ServerLocal,
        false
    );
    return res;
};
//============= Hist Weather green Chart =================
const getHistoricalWeatherSeries = async (data: IGetHistoricalWeatherSeriesReqModel) => {
    const url = "/api/Chart/GetHistoricWeatherSeries";
    const res = await http.get<IGetHistoricalWeatherSeriesReqModel,AxiosResponse<IGetHistoricWeatherSeriesResModel>>(url,
        data,
        ServerType.ServerLocal,
        false
    );
    return res;
};
const getHistoricalWeatherCharInfo = async (data: IGetHistoricalWeatherCharInfo) => {
    const url = "/api/Chart/GetHistoricalWeatherCharInfo";
    const res = await http.get<IGetHistoricalWeatherCharInfo,AxiosResponse<IGetHistWeatherChatInfoResModel>>(url,
        data,
        ServerType.ServerLocal,
        false
    );
    return res;
};

//============= Disease Map and  Disease Map Prediction =================
const getDiseaseRiskInfoByName = async (data: IGetDiseaseRiskInfoByNameReqModel) => {
    const url = "/api/WeatherDiseaseMap/GetDiseaseRiskInfoByName";
    const res = await http.post<IGetDiseaseRiskInfoByNameReqModel,AxiosResponse<IDiseaseRiskInfoByNameResModel>>(url,
        data,
        ServerType.ServerLocal,
        false
    );
    return res;
};
const getDiseaseRisksLocationAndValueByName = async (data: IGetDiseaseRisksLocationAndValueByNameReqModel) => {
    // const url = "/api/Disease/GetLocation";
    const url = "/api/WeatherDiseaseMap/GetDiseasesByName";
    console.log("data pa", data);
    const res = await http.get<IGetDiseaseRisksLocationAndValueByNameReqModel, AxiosResponse<IGetDiseaseRisksLocationAndValueByNameResModel[]>>(url,
        data,
        ServerType.ServerLocal,
        false
    );
    return res;
};

export {
    postSentEmail,
    generateLocationByCoordinate,
    // hist disease
    getHistoricDiseaseSeries,
    getHistoricDiseaseCharInfo,
    // hist weather
    getDropdownTranslation,
    getSeries,
    getHistoricalCharInfo,
    getAltitude,
    // hist weather green chart
    getHistoricalWeatherSeries,
    getHistoricalWeatherCharInfo,
    // disease map and disease map prediction
    getDiseaseRiskInfoByName,
    getDiseaseRisksLocationAndValueByName
};

