import { IDiseaseMapDataModel, IDiseaseRiskGetLocationResModel, IDiseaseRiskInfoResModel,
    ILocationModel, ITranslateTextModel } from "@/util/http/responseType";
import {IReportsModel,IFieldsModel,ISettingModel,
    ICurrentGetWeatherDataModel,IGetGeoNamesLocationDataReqModel,IUserDataModel,
    IMapCenterModel} from "@/util/http/Quantis/responseType";

import { normalActionTypes } from "../actions/quantisAction";
// init dieseae and translate data
import {InitDiseaseDataFromXML,InitTranslateText,InitDiseaseRiskInfo} from "@/resource/js/diseaseInitData";


interface IQuantisModel {
    reports?: IReportsModel[]
    showSuccessMessage?: string
    showErrorMessage?: string
    mainPageFirstGetFieldFlag?: boolean // this is used to check whether completed first invoke get Fields function
    allFields?: IFieldsModel[]
    getAllFieldsStatus?: boolean
    allCropFromFields?: string[]
    allBookMarkFields?: IFieldsModel[]
    reportAddOrEditFlag?: string
    currentSelectedReport?: IReportsModel
    allCrop?: string[]
    setting?: ISettingModel
    currentGetWeatherData?: ICurrentGetWeatherDataModel
    placeName?: string
    geoLocationListData?: IGetGeoNamesLocationDataReqModel[]
    userList?:IUserDataModel[]
    getWeatherAfterAddOrUpdateSettingFlag: boolean
    currentMapCenter?: IMapCenterModel
    addNewFieldStatus?: string
    updateFieldStatus?: string
    deleteFieldStatus?: string
    unauthorizedFlag?: boolean
    mainInitDataCount?: number
}



const initialQuantis: IQuantisModel = {
    reports: [],
    showSuccessMessage: null,
    showErrorMessage: null,
    mainPageFirstGetFieldFlag: false,
    allFields: [],
    getAllFieldsStatus: false,
    allBookMarkFields: [],
    allCropFromFields:[],
    allCrop: [],
    reportAddOrEditFlag: null,
    currentSelectedReport: null,
    setting: null,
    currentGetWeatherData: null,
    placeName: null,
    geoLocationListData: [],
    userList:[],
    getWeatherAfterAddOrUpdateSettingFlag: false,
    currentMapCenter: null,
    addNewFieldStatus: null,
    deleteFieldStatus: null,
    updateFieldStatus: null,
    unauthorizedFlag: false,
    mainInitDataCount: 0
};

const quantisReducer = (state: IQuantisModel = initialQuantis, action) => {

    switch (action.type) {
        case normalActionTypes.NORMAL_REPORTS_DATA:
            console.log("quantisReducer:",action.payload);
            return {
                ...state,
                reports: action.payload
            };
        case normalActionTypes.NORMAL_UPDATE_USERLIST:
            return {
                ...state,
                userList: action.payload
            };
        case normalActionTypes.NORMAL_UPDATE_REPORTADDOREDITFLAG:
            console.log("quantisReducer:",action.payload);
            return {
                ...state,
                reportAddOrEditFlag: action.payload
            };
        case normalActionTypes.NORMAL_UPDATE_CURRENTSELECTEDREPORT:
            console.log("quantisReducer:",action.payload);
            return {
                ...state,
                currentSelectedReport: action.payload
            };
        case normalActionTypes.NORMAL_UPDATE_MAINPAGEFIRSTGETFIELDFLAG:
            console.log("quantisReducer:",action.payload);
            return {
                ...state,
                mainPageFirstGetFieldFlag: action.payload
            };
        case normalActionTypes.NORMAL_UPDATE_ALLFIELDS:
            console.log("quantisReducer:",action.payload);
            return {
                ...state,
                allFields: action.payload
            };
        case normalActionTypes.NORMAL_UPDATE_GETALLFIELDSSTATUS:
            console.log("quantisReducer:",action.payload);
            return {
                ...state,
                getAllFieldsStatus: action.payload
            };
        case normalActionTypes.NORMAL_UPDATE_ALLBOOKMARKFIELDS:
            console.log("quantisReducer:",action.payload);
            return {
                ...state,
                allBookMarkFields: action.payload
            };
        case normalActionTypes.NORMAL_UPDATE_CROPFROMFIELDS:
            console.log("quantisReducer:",action.payload);
            return {
                ...state,
                allCropFromFields: action.payload
            };
        case normalActionTypes.NORMAL_UPDATE_ALLCROP:
            console.log("quantisReducer:",action.payload);
            return {
                ...state,
                allCrop: action.payload
            };

        case normalActionTypes.NORMAL_UPDATE_SHOWSUCCESSMESSAGE:
            console.log("quantisReducer:",action.payload);
            return {
                ...state,
                showSuccessMessage: action.payload
            };
        case normalActionTypes.NORMAL_UPDATE_SHOWERRORMESSAGE:
            console.log("quantisReducer:",action.payload);
            return {
                ...state,
                showErrorMessage: action.payload
            };
        case normalActionTypes.NORMAL_UPDATE_REDUX_SETTING:
            console.log("lanxue quantisReducer:",action.payload);
            return {
                ...state,
                setting: action.payload
            };
        case normalActionTypes.NORMAL_UPDATE_CURRENTGETWEATHERDATA:
            console.log("lanxue quantisReducer:",action.payload);
            return {
                ...state,
                currentGetWeatherData: action.payload
            };
        case normalActionTypes.NORMAL_UPDATE_PLACENAME:
            console.log("lanxue quantisReducer:",action.payload);
            return {
                ...state,
                placeName: action.payload
            };
        case normalActionTypes.NORMAL_UPDATE_GEOLOCATIONLISTDATA:
            console.log("lanxue quantisReducer:",action.payload);
            return {
                ...state,
                geoLocationListData: action.payload
            };
        case normalActionTypes.NORMAL_UPDATE_GETWEATHERAFTERADDORUPDATESETTING:
            console.log("lanxue quantisReducer:",action.payload);
            return {
                ...state,
                getWeatherAfterAddOrUpdateSettingFlag: action.payload
            };
        case normalActionTypes.NORMAL_UPDATE_CURRENTMAPCENTER:
            console.log("lanxue map quantisReducer:",action.payload);
            return {
                ...state,
                currentMapCenter: action.payload
            };
        case normalActionTypes.NORMAL_UPDATE_ADDNEWFIELDSTATUS:
            console.log("lanxue quantisReducer:",action.payload);
            return {
                ...state,
                addNewFieldStatus: action.payload
            };
        case normalActionTypes.NORMAL_UPDATE_DELETEFIELDSTATUS:
            console.log("lanxue quantisReducer:",action.payload);
            return {
                ...state,
                deleteFieldStatus: action.payload
            };
        case normalActionTypes.NORMAL_UPDATE_UPDATEFIELDSTATUS:
            console.log("lanxue quantisReducer:",action.payload);
            return {
                ...state,
                updateFieldStatus: action.payload
            };
        case normalActionTypes.NORMAL_UPDATE_UNAUTHORIZEDFLAG:
            console.log("lanxue quantisReducer:",action.payload);
            return {
                ...state,
                unauthorizedFlag: action.payload
            };
        case normalActionTypes.NORMAL_UPDATE_MAININITDATACOUNT:
            console.log("lanxue aa quantisReducer action.payload:",action.payload);
            return {
                ...state,
                mainInitDataCount: action.payload
            };

        default:
            return state;
    }
};

export type {
    IQuantisModel
};

export default quantisReducer;