/* eslint-disable max-len */
import React, { useEffect, useState } from "react";
import "./index.less";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Layout, Menu, Row, Col ,Modal, message,notification} from "antd";
import store from "@/util/store";
import { Header } from "antd/es/layout/layout";
// init data
import {navMenus,notShowMenus,NavMenusType} from "./init";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/redux/reducer/store";
import { normalActionTypes } from "@/redux/actions/quantisAction";

const VisualizationBaseLayout = ()=>{
    const urlLocation = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const successMessage = useSelector((state: RootState) => state.quantisReducer.showSuccessMessage);
    const errorMessage = useSelector((state: RootState) => state.quantisReducer.showErrorMessage);
    const unAuthorizedFlag = useSelector((state: RootState)=> state.quantisReducer.unauthorizedFlag);

    const [finalShowMenu,setFinalShowMenu] = useState<NavMenusType[]>([]);


    const title = location.pathname === "/visualization/DiseaseRiskPrediction" ? "Disease Risk Visualization" : "Crop stress prediction tool";

    const logout = () => {
        console.log("ddd logout");
        store.remove("token");
        store.remove("user");
        store.remove("userRole");
        // navigate("/visualization/cropstress-login");
        navigate("/cropstress/login");
    };

    useEffect(() => {
        // if(store.get("quantis-token")){
        //     navigate("/visualization/cropstress-login");
        // }
        let tmpFinalShowMenu = [];
        if(store.get("quantisUserRole") != "admin"){
            tmpFinalShowMenu = navMenus.filter(item => item.admin === false);
        }
        else{
            tmpFinalShowMenu = [...navMenus];
        }
        setFinalShowMenu(tmpFinalShowMenu);
    }, [urlLocation]);

    useEffect(()=>{
        if(unAuthorizedFlag){
            // navigate("/visualization/cropstress-login");
            navigate("/cropstress/login");
            dispatch({
                type: normalActionTypes.NORMAL_UPDATE_UNAUTHORIZEDFLAG,
                payload: false
            });
        }
    },[unAuthorizedFlag]);


    // set common message notificaton
    useEffect(() => {
        if (successMessage && successMessage?.length > 0) {
            successNotification(successMessage);
        }
    }, [successMessage]);
    useEffect(() => {
        if (errorMessage && errorMessage?.length > 0) {
            errorNotification(errorMessage);
        }
    }, [errorMessage]);
    const errorNotification = (message?: string)=>{
        notification.error({
            message: message,
            placement: "bottomLeft",
            duration: 3
        });
        dispatch({
            type: normalActionTypes.NORMAL_UPDATE_SHOWERRORMESSAGE,
            payload: null
        });
    };
    const successNotification = (message?: string)=>{
        notification.success({
            message: message,
            placement: "bottomLeft",
            duration: 3
        });
        dispatch({
            type: normalActionTypes.NORMAL_UPDATE_SHOWSUCCESSMESSAGE,
            payload: null
        });
    };

    return(
        <HelmetProvider>
            <div>
                <Helmet>
                    <title>{title}</title>
                    <meta name="description" content='Crop stress prediction tool' />
                </Helmet>
                <div
                    className="visualizationBaseLayout-cotainer"
                >
                    {/* 导航栏 */}
                    <Layout className="layout" >
                        {(() => {
                            if (store.get("quantis-token") && notShowMenus.indexOf(urlLocation.pathname) == -1) {
                                return <div className={"contents"} style={{ background: "#fff" }} >
                                    <Header>
                                        <Menu mode="horizontal" defaultSelectedKeys={[urlLocation.pathname]} selectedKeys={[urlLocation.pathname]} style={{ display: "block" }}>
                                            {finalShowMenu?.map((item: NavMenusType, idx) => {
                                                return <Menu.Item key={item?.path + item?.title} style={{ float: "left" }}>
                                                    <Link to={item.path}>{item?.title}</Link>
                                                </Menu.Item>;
                                            })}
                                            <Menu.Item style={{ float: "right", backgroundColor: "#3a7bb9" }} key="logout" onClick={logout}>
                                                <a style={{ color: "#fff" }}>Log out</a>
                                            </Menu.Item>
                                            <Menu.Item style={{ float: "right", backgroundColor: "#3a7bb9" }} key="user">
                                                <a style={{ color: "#fff" }}>{store.get("quantisUser")}</a>
                                            </Menu.Item>
                                        </Menu>

                                    </Header>
                                </div>;

                            } else {
                                return "";
                            }
                        })()}
                        {/* <Content className='contents'>
                            <div className="site-layout-content">{props.children}</div>
                        </Content> */}
                        {/* <Footer style={{ textAlign: 'center' }}>Ant Design ©2018 Created by Ant UED</Footer> */}
                    </Layout>

                    {/* <h2>============== 下面是子组件的显示区域===============</h2> */}
                    <Outlet></Outlet>
                </div>

            </div>
        </HelmetProvider>

    );
};

export default VisualizationBaseLayout;