import { useEffect,useState } from "react";
import { zoomConfig } from "../initData/zoomWidthAndHeight";


const getSvgSize = (countryCode:string,zoom)=>{
    // get the match country config
    const matchZoomConfig = zoomConfig.find(item => item.countryCode === countryCode);

    if(!matchZoomConfig) return 10;

    // get the match zoom
    const matchCondition = matchZoomConfig.conditions.find((item,index)=>{
        if(zoom >= item.zoomRange[0] && zoom <= item.zoomRange[1]) return item;
    });
    if(!matchCondition) return 10;
    else return matchCondition.width;
};

interface IProp{
    countryCode: string;
    zoom: number;
}

const useSize = (props:IProp)=>{
    const {
        countryCode,
        zoom
    } = props;

    const [markerWidth,setMarkerWidth] = useState(10);
    const [markerHeight,setMarkerHeight] = useState(10);

    useEffect(()=>{
        const tmpSize = getSvgSize(countryCode,zoom);
        setMarkerWidth(tmpSize);
        setMarkerHeight(tmpSize);
    },[zoom]);

    return {
        markerWidth,markerHeight
    };
};

export default useSize;