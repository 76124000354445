import React, { useEffect, useRef, useState } from "react";
import GoogleaMap from "google-map-react";
import "./index.less";
import { useSelector } from "react-redux";
import { RootState } from "@/redux/reducer/store";

interface IProps {
    selectedRegion: string[]
}

const HeatGoogleMap = (props: IProps) => {
    const {
        selectedRegion
    } = props;
    const mapRef = useRef(null);
    const mapsRef = useRef(null);
    const polygonRef = useRef(null);
    const [zoom, setZoom] = useState(1);

    const handleGoogleApiLoaded = ({ map, maps }) => {
        mapRef.current = map;
        mapsRef.current = maps;
    };

    const defaultCenter = {
        lat: 10.99835602,
        lng: 77.01502627
    };

    const {
        coountryBoundary,
        riskColor,
        countryGeonameIdSet
    } = useSelector((state: RootState) => state.commonReducer);


    const setCountryCenterAndZoom = ()=>{
        const tmpCheckId = selectedRegion[0];
        const tmpAustriaIds = [...countryGeonameIdSet[0]];
        const tmpGermanyIds = [...countryGeonameIdSet[1]];
        const tmpSwitzerlandIds = [...countryGeonameIdSet[2]];
        // fur Austria
        tmpAustriaIds?.map((item)=>{
            if(item === tmpCheckId){
                mapRef.current.setCenter({ lat: 47.710574 ,lng: 14.309993 });
                mapRef.current.setZoom(7);
                setZoom(7);
                return;
            }
        });
        // for Germany
        tmpGermanyIds?.map((item)=>{
            if(item === tmpCheckId){
                mapRef.current.setCenter({ lat: 51.633227 ,lng: 10.360552 });
                mapRef.current.setZoom(6);
                setZoom(6);
                return;
            }
        });
        // for Switzerland
        tmpSwitzerlandIds?.map((item)=>{
            if(item === tmpCheckId){
                mapRef.current.setCenter({ lat: 46.937046 ,lng: 8.294888 });
                mapRef.current.setZoom(8);
                setZoom(8);
                return;
            }
        });
    };

    useEffect(() => {
        if (coountryBoundary) {
            const result = coountryBoundary.map((innerItem) => {
                const result = innerItem.coordinates.map((region) => {
                    const result = region.map((targetRegion) => {
                        const result = targetRegion.map((point) => {
                            return { lat: point.latitude, lng: point.longitude, color: innerItem.riskColor};
                        });
                        return result;
                    });
                    return result;
                });
                return result;
            });
            polygonRef.current?.forEach((item) => {
                item.setMap(null);
            });
            // console.log("result:====", result);

            result.forEach((item) => {
                item.forEach(region => {
                    const geodesicPolygon = new mapsRef.current.Polygon({
                        paths: region,
                        strokeColor: "blue",
                        strokeOpacity: 0.8,
                        strokeWeight: 2,
                        fillColor: region[0][0].color === "Amber" ? "rgba(255,191,000)" : region[0][0].color.toLowerCase(),
                        fillOpacity: 0.8
                    });
                    if (polygonRef.current?.length > 0) {
                        polygonRef.current.push(geodesicPolygon);
                    } else {
                        polygonRef.current = [geodesicPolygon];
                    }
                    geodesicPolygon.setMap(mapRef.current);
                });
            });
            setCountryCenterAndZoom();
            // mapRef.current.setCenter({ lat: coountryBoundary[0].latitude ,lng: coountryBoundary[0].longitude });
            // setZoom(coountryBoundary[0].zoom);
        }
    }, [coountryBoundary, riskColor]);

    useEffect(() => {
        if (selectedRegion.length === 0) {
            polygonRef.current?.forEach((item) => {
                item.setMap(null);
            });
        }
    }, [selectedRegion]);

    return (
        <div className="map-container">
            <GoogleaMap
                bootstrapURLKeys={{
                    key: "AIzaSyDlsxkoR8-fiIgncnDunmEtWEwDmr896H0",
                    libraries: ["visualization"]
                }}
                heatmapLibrary={true}
                defaultZoom={1}
                onGoogleApiLoaded={handleGoogleApiLoaded}
                defaultCenter={defaultCenter}
                zoom={zoom}
            >
            </GoogleaMap>
        </div>
    );
};

export default HeatGoogleMap;
