import React from "react";
import {
    TemperatureIcon,
    WaterFallIcon,
    WindSpeedIcon
} from "@/resource/IconComponents";
import "./index.less";
import { IWeatherForcastDailyListResModel } from "@/util/http/responseType";
import IF from "@/components/IF";
import { getYYYYmmDDFormatDate, transferDateToTargetFormater } from "@/util/commonFunction";
import { useSelector } from "react-redux";
import { RootState } from "@/redux/reducer/store";
import { API_LOCAL_URL } from "@/util/http/config";

interface IProps {
    weatherData: IWeatherForcastDailyListResModel
    onClick: (data: IWeatherForcastDailyListResModel) => void
}

const WeatherCard = (props: IProps) => {
    const {
        weatherData,
        onClick
    } = props;

    const {
        paramModel,
        currentLanguage
    } = useSelector((state: RootState) => state.commonReducer);

    return (
        <div
            className="weather-card-container"
            onClick={() => {
                if (!weatherData.selected) {
                    onClick(weatherData);
                }
            }}
            style={{ border: weatherData.selected ? "2px #0C612C solid" : null }}
        >
            <div className="date-div">
                <span className="today-monday">
                    {/* {transferDateToTargetFormater(paramModel.countryCode, weatherData.date).dayOfWeek} */}
                    {weatherData.formatDay}
                </span>
                <IF show={weatherData.date.match(/\d\d\d\d\/\d\d\/\d\d/)[0] === getYYYYmmDDFormatDate()}>
                    <span className="date-label">
                        {currentLanguage.txt_today}
                    </span>
                </IF>
                <span className="date-label">
                    {/* {transferDateFormatToDDmmYYYY(weatherData.date)} */}
                    {transferDateToTargetFormater(paramModel.countryCode, weatherData.date).date}
                </span>
            </div>
            <IF show={Boolean(weatherData.imageName)}>
                <div className="weather-icon-div"><img style={{ height: "40px", width: "40x" }} src={`${API_LOCAL_URL}api/weather-imgs/${weatherData.imageName}`} /></div>
            </IF>
            <div className="water-fall-div">
                <WaterFallIcon />
                <span className="number-lable">{paramModel.unit === "metric" ? weatherData.precip_DailySum : weatherData.precip_DailySum_in}
                    <span>{paramModel.unit === "metric" ? " mm" : " in"}</span></span>
            </div>
            <div className="wind-speed-div">
                <WindSpeedIcon />
                <span className="number-lable">{paramModel.unit === "metric" ? weatherData.windspeed_kph.toFixed(2) : weatherData.windspeed_mph.toFixed(2)}
                    <span>{paramModel.unit === "metric" ? " k/h" : " m/h" } </span></span></div>
            <div className="temperature-div">
                <TemperatureIcon /><span className="number-lable">{`
                ${paramModel.unit === "metric" ? weatherData.tempAir_DailyMax : weatherData.tempAir_DailyMax_f} ${paramModel.unit === "metric" ? " °" : " °F"} |
                ${paramModel.unit === "metric" ? weatherData.tempAir_DailyMin : weatherData.tempAir_DailyMin} ${paramModel.unit === "metric" ? " °" : " °F"}`}</span>
            </div>
        </div>
    );
};

export default WeatherCard;