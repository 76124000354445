import React, { useEffect, useRef, useState } from "react";
import "./index.less";
import RiskPicAndNotes from "./components/RiskPicAndNotes";
import DownloadImage from "@/resource/images/svg/diseaseMap/download.svg";
import { DiseaseMapDownloadSvg } from "@/components/IconComponents/iconComponents";
import MapRender from "./components/MapRender";
import { useLocation } from "react-router-dom";

import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);

// function

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/redux/reducer/store";

// get init data form the ts data file
import {countryOptions,initCropOptions,countryCenterAndZoomScape} from "./components/initDdata/init";

import * as BasicData from "./components/initDdata/CountryOptions/BasicCropOptions";
import * as RUData from "./components/initDdata/CountryOptions/RUCropOptions";
import * as PKData from "./components/initDdata/CountryOptions/PKCropOptions";
import * as MAData from "./components/initDdata/CountryOptions/MACropOptions";
import * as NLData from "./components/initDdata/CountryOptions/NLCropOptions";
import * as BEData from "./components/initDdata/CountryOptions/BECropOptions";
import html2canvas from "html2canvas";
import { Select } from "antd";
import AGForcastButton from "@/components/ForecastProviderButton";
import { sagaActionTypes } from "@/redux/actions/diseaseAction";
import { checkServiceTokenIsValid } from "@/util/http";
const {Option} = Select;

interface ISearchObj{
    theme?:string;
    forcastday?:number;
    startdate?:string;
    country?:string;
    language?:string;
    securitykey?: string;
}
interface IDiseaseOptionObj{
    ModelName: string;
    ModelID: string;
    ModelDomain: string;
    Description: string;
    PropertyType: string;
    PropertyName: string;
    PropertyDescription: string;
}
interface ICropOptionsModel{
    CropName: string;
    CropValue: string;
    CropID: string;
}
const CountryDateFormat = {
    "AE": "DD/MM/YYYY",
    "AU": "DD/MM/YYYY",
    "BY": "DD.MM.YY",
    "CH": "DD.mm.YY",
    "DE": "DD.MM.YYYY",
    "NZ": "DD-MM-YYYY",
    "US": "MM/DD/YYYY",
    "ES": "DD/MM/YYYY",
    "FR": "DD/MM/YYYY",
    "GB": "DD/MM/YYYY",
    "IL": "DD/MM/YYYY",
    "JP": "YYYY/MM/DD",
    "KR": "YYYY-MM-DD",
    "PL": "DD.MM.YYYY",
    "PT": "DD-MM-YYYY",
    "SE": "YYYY-MM-DD",
    "SI": "DD.MM.YYYY",
    "TR": "DD.MM.YYYY",
    "ZA": "DD/MM/YYYY",
    "MA": "DD/MM/YYYY"
};

const DiseaseRisk = () => {
    const location = useLocation();
    const dispatch = useDispatch();

    //const height = window.screen.height;
    const [searchObj,setSearObj] = useState<ISearchObj>({
        theme: "light",
        forcastday: 7,
        startdate: dayjs().format("DD/MM/YYYY"),
        country: "AU",
        securitykey: null
    });

    // get data from redux
    const {
        diseaseMapPredictionRiskInfo
    } = useSelector((state: RootState) => state.diseaseReducer);

    useEffect(()=>{
        document.body.style.backgroundColor = searchObj?.theme === "light" ? "white" : "black";
    },[searchObj?.theme]);

    // add google analysis
    useEffect(() => {
        console.log("just invoke once");
        const head = document.getElementsByTagName("head")[0];
        const googleAnalyticsCode = "G-CQZZ8EPKDD";
        const googleAnalyticsFirstScript = document.createElement("script");
        googleAnalyticsFirstScript.async = true;
        googleAnalyticsFirstScript.src =
          "https://www.googletagmanager.com/gtag/js?id=" + googleAnalyticsCode;

        const googleAnalyticsSecondScript = document.createElement("script");
        googleAnalyticsSecondScript.innerHTML =
          "    window.dataLayer = window.dataLayer || [];\n" +
          "    function gtag(){dataLayer.push(arguments);}\n" +
          "    gtag('js', new Date());\n" +
          "\n" +
          "    gtag('config', '" +
          googleAnalyticsCode +
          "',{\n" +
          "cookie_flags:'max-age=7200;secure;samesite-none'" +
          "});";

        head.insertBefore(googleAnalyticsSecondScript, head.firstChild);
        head.insertBefore(googleAnalyticsFirstScript, head.firstChild);
    }, []);

    // selected countryCode,Crop and disease
    const [allDiseaseOptions,setAllDiseaseOptions] = useState<IDiseaseOptionObj[]>([]);
    const [cropOptions,setCropOptions] = useState<ICropOptionsModel[]>([]);
    const [diseaseOptions,setDiseaseOptions] = useState<IDiseaseOptionObj[]>([]);

    const [selectedCountryCode,setSelectedCountryCode] = useState("AU");
    const [selectedCrop,setSelectedCrop] = useState("Cereal"); // crop is the show value
    const [selecetdCropID,setSelectedCropID] = useState("Cereal"); // cropID is used in request the api
    const [selectedDisease,setSelectedDisease] = useState("BarleyNetBlotchPyrenophoraTeres");
    const [currentSelectedDate,setCurrentSelectedDate] = useState(null);

    const childChangeSelectedDate = (value:string)=>{
        const tmpSearchObj = {...searchObj};
        tmpSearchObj.startdate = value;
        setSearObj(tmpSearchObj);
    };
    const childChangeCurrentSelectedDate = (value:string)=>{
        setCurrentSelectedDate(value);
    };

    const initSearchObj = ()=>{
        const searchStr = location?.search.replace("?","");
        const searchArr = searchStr?.split("&");
        const tmpSearchObj = {...searchObj};
        let tmpStartDate = "";
        let tmpCountry = "";
        let haveSecurityFlag = false;
        searchArr?.map((item)=>{
            const searchKeyValue = item?.split("=");
            if(searchKeyValue[0] === "forcastday"){
                tmpSearchObj[searchKeyValue[0]] = Number(searchKeyValue[1]);
            }
            else if(searchKeyValue[0].toLowerCase() === "startdate"){
                // tmpSearchObj[searchKeyValue[0].toLowerCase()] = dayjs(searchKeyValue[1],tmpFormat).format("DD/MM/YYYY");
                tmpSearchObj[searchKeyValue[0].toLowerCase()] = searchKeyValue[1];
                tmpStartDate = searchKeyValue[1];
            }
            else if(searchKeyValue[0].toLowerCase() === "country"){
                // tmpSearchObj[searchKeyValue[0].toLowerCase()] = dayjs(searchKeyValue[1],tmpFormat).format("DD/MM/YYYY");
                tmpSearchObj[searchKeyValue[0].toLowerCase()] = searchKeyValue[1];
                tmpCountry = searchKeyValue[1];
            }
            else if(searchKeyValue[0].toLowerCase() === "securitykey"){
                if(searchKeyValue[1]){
                    tmpSearchObj[searchKeyValue[0].toLowerCase()] = searchKeyValue[1];
                    haveSecurityFlag = true;
                }
            }
            else{
                tmpSearchObj[searchKeyValue[0]] = searchKeyValue[1];
            }
        });
        const tmpFormat = CountryDateFormat[tmpCountry] || "DD/MM/YYYY";
        if(tmpStartDate){
            tmpSearchObj.startdate = dayjs(tmpStartDate || tmpSearchObj.startdate,[tmpFormat,"YYYY-MM-DD"]).format("DD/MM/YYYY");
        }
        if(!haveSecurityFlag){
            tmpSearchObj.securitykey = "no-value";
        }
        setSearObj(tmpSearchObj);
    };

    const saveMapToDataUrl = ()=>{
        const element = document.getElementById("mapDiv");
        const element1 = document.querySelector(".download-item-img") as HTMLElement;
        element1.style.display = "none";
        console.log("test images",element);
        if(element){
            html2canvas(element,{
                useCORS: true
            }).then((canvas)=>{
                const dataUrl = canvas.toDataURL("image/png");
                console.log("test images",dataUrl);

                const link = document.createElement("a");
                link.href = dataUrl;
                link.download = searchObj.country + ".png";
                link.click();
                element1.style.display = "block";
            });
        }
    };


    // when country code change, the crop options, disease options also should change
    const publicChangeCropAndOptionsByCountry = (country:string)=>{
        let tmpData = null;
        let tmpCrop = "";
        switch (country){
            case "RU":
            case "BY":
                tmpData = RUData;
                tmpCrop = "Canola";
                break;
            case "PK":
                tmpData = PKData;
                tmpCrop = "Pepper";
                break;
            case "MA":
                tmpData = MAData;
                tmpCrop = "Potato_V2";
                break;
            case "NL":
                tmpData = NLData;
                tmpCrop = "Cereal";
                break;
            case "BE":
                tmpData = BEData;
                tmpCrop = "Cereal";
                break;
            default :
                tmpData = BasicData;
                tmpCrop = "Cereal";
                break;
        }
        // set the crop array
        setCropOptions(tmpData?.CropOptions);
        setSelectedCrop(tmpCrop);
        setAllDiseaseOptions(tmpData?.DiseaseOptions);
        console.log("dd dd tmpData",tmpData);
        console.log("dd dd tmpCrop",tmpCrop);
        console.log("dd dd CropOptions",tmpData?.CropOptions);
        console.log("dd dd DiseaseOptions",tmpData?.DiseaseOptions);
    };
    // when crop change, change the disease options and set the first disease to be default value
    const publicChangeDiseaseOptionsAndDefaultValueByCrop = ()=>{
        let tmpDiseaseValue = "";
        const tmpDiseaseOptions:IDiseaseOptionObj[] = allDiseaseOptions?.filter((item)=>{
            if(item?.ModelDomain === selectedCrop) return item;
        });
        tmpDiseaseValue = tmpDiseaseOptions[0]?.ModelID || "";
        // change disease options
        setDiseaseOptions(tmpDiseaseOptions);
        // set the first disease to the default
        setSelectedDisease(tmpDiseaseValue);
    };

    // =================== useEffect ===============
    useEffect(()=>{
        initSearchObj();
    },[]);
    useEffect(()=>{
        publicChangeCropAndOptionsByCountry(selectedCountryCode);
    },[selectedCountryCode]);
    useEffect(()=>{
        if(searchObj?.startdate){
            setCurrentSelectedDate(searchObj.startdate);
        }
    },[searchObj.startdate]);
    useEffect(()=>{
        if(searchObj?.country) setSelectedCountryCode(searchObj.country);
    },[searchObj.country]);
    useEffect(()=>{
        if(selectedCrop){
            publicChangeDiseaseOptionsAndDefaultValueByCrop();
        }
    },[selectedCrop,allDiseaseOptions]);
    useEffect(()=>{
        if(cropOptions?.length > 0){
            let tmpCropID = "";
            tmpCropID = cropOptions.filter((item)=>{
                if(item?.CropValue === selectedCrop) return item;
            })[0].CropID;
            console.log("gg selectedCrop",selectedCrop);
            console.log("gg cropOptions",cropOptions);
            console.log("gg tmpCropID",tmpCropID);
            setSelectedCropID(tmpCropID);
        }
    },[cropOptions,selectedCrop]);
    // when selected country or selected crop , should request the api
    useEffect(()=>{
        // when selected country or selected crop , should request the api
        const queryCrop = selecetdCropID === "Canola" ? "Cereal" : selecetdCropID;
        const tmpSearchDate = searchObj?.startdate === currentSelectedDate ? searchObj?.startdate : currentSelectedDate;
        console.log("my date",searchObj?.startdate);
        console.log("my date",currentSelectedDate);
        console.log("my date",tmpSearchDate);
        dispatch({
            type: sagaActionTypes.SAGA_DISEASEMAPPREDICTIONRISKINFO,
            payload:{
                strcountry: selectedCountryCode || "AU",
                strcrop: queryCrop,
                strStartDate: dayjs(tmpSearchDate,"DD/MM/YYYY").format("YYYY-MM-DD"),
                strDiseaseName: selectedDisease
            }
        });
    },[selectedCountryCode,selecetdCropID,searchObj.startdate,currentSelectedDate,selectedDisease]);
    /**
     * used for controll page whether hidden
     */
    const [showPageByToken,setShowPageByToken] = useState(true);
    const isFirstRender = useRef(true);
    const checkServiceTokenIsValidFunc = async()=>{
        try{
            const res = await checkServiceTokenIsValid(searchObj.securitykey);
            setShowPageByToken(res.data);
            if(!res.data){
                const element1 = document.querySelector(".dark-theme") as HTMLElement;
                if(element1){
                    element1.style.backgroundColor = "white";
                }
            }
        }
        catch(error){
            console.log("error",error);
        }
    };
    useEffect(()=>{
        if (isFirstRender.current) {
            isFirstRender.current = false; // first render
            return; // when first render, do nothing
        }
        // if(searchObj?.securitykey){
        //     if(searchObj.securitykey === "no-value"){
        //         setShowPageByToken(false);
        //     }
        //     else{
        //         checkServiceTokenIsValidFunc();
        //     }
        // }
    },[searchObj.securitykey]);

    return (
        <>
            {
                showPageByToken?
                    <div className="v3-prediction-all-container">
                        <div className={`v3-prediction-main-bg bg-${searchObj.theme}`}>
                            <div className="v3-diseasemap">
                                <div className="diseasemap-header">
                                    <h2>Disease Risk Prediction</h2>
                                </div>

                                <div id="mapDiv">
                                    {/* select disease map */}
                                    <div className="disease-select-container bg-header">
                                        <div style={{padding:"0px 25px"}} className="disease-select-container-flex">
                                            <div className="crop-item">
                                                <div className="disease-item">
                                                    <div className="disease-item-title base-font">Select country</div>
                                                    <div className="disease-select-item">
                                                        <Select className="base-button"
                                                            value={selectedCountryCode}
                                                            onChange={(e)=>{
                                                                setSelectedCountryCode(e);
                                                            }}
                                                        >
                                                            {countryOptions?.map((item, index) => {
                                                                return (
                                                                    <>
                                                                        <Option key={item.countryCode + index} value={item.countryCode}>
                                                                            {item.countryName}
                                                                        </Option>
                                                                    </>
                                                                );
                                                            })}
                                                        </Select>
                                                    </div>
                                                </div>
                                                <div className="disease-item">
                                                    <div className="disease-item-title base-font">Select crop</div>
                                                    <div className="disease-select-item">
                                                        <Select className="base-button"
                                                            value={selectedCrop}
                                                            onChange={(e)=>{
                                                                setSelectedCrop(e);
                                                            }}
                                                        >
                                                            {cropOptions?.map((item, index) => {
                                                                return (
                                                                    <>
                                                                        <Option key={item?.CropID + index} value={item?.CropValue}>
                                                                            {item?.CropName}
                                                                        </Option>
                                                                    </>
                                                                );
                                                            })}
                                                        </Select>
                                                    </div>
                                                </div>
                                                <div className="disease-item">
                                                    <div className="disease-item-title base-font">Select disease to view map</div>
                                                    <div className="disease-select-item">
                                                        <Select className="base-button"
                                                            value={selectedDisease}
                                                            onChange={(e)=>{
                                                                setSelectedDisease(e);
                                                            }}
                                                        >
                                                            {diseaseOptions ? diseaseOptions
                                                            // .sort((itemA,itemB)=>{
                                                            //     // if(itemA?.ModelName < itemB?.ModelName) return -1;
                                                            //     // else return 0;
                                                            // })
                                                                ?.map((item, index) => {
                                                                    return (
                                                                        <>
                                                                            <Option key={item?.ModelID + index} value={item?.ModelID}>
                                                                                {item?.ModelName}
                                                                            </Option>
                                                                        </>
                                                                    );
                                                                }) : []}
                                                        </Select>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="download-item">
                                                <button className="feedback-button">
                                                    <a
                                                        href="https://forms.office.com/r/yYTezwnJcS"
                                                        target="_blank" rel="noreferrer"
                                                    >
                                        FeedBack
                                                    </a>
                                                </button>
                                                <div
                                                    className="download-item-img"
                                                    onClick={()=>{
                                                        saveMapToDataUrl();
                                                    }}
                                                >
                                                    {/* <img src={DownloadImage} /> */}
                                                    <DiseaseMapDownloadSvg
                                                        theme={searchObj?.theme}
                                                    />
                                                    <span className="font-14">Download as Image</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* map Render */}
                                    <MapRender
                                        forcastDay={searchObj.forcastday}
                                        startDate={searchObj.startdate}
                                        selectedCountry={selectedCountryCode}
                                        selectedDisease={selectedDisease}
                                        selectedCrop={selecetdCropID}
                                        dateFormat={CountryDateFormat[searchObj.country] || "DD/MM/YYYY"}
                                        childChangeCurrentSelectedDate={childChangeCurrentSelectedDate}
                                    />
                                    {/* risk show components */}
                                    <RiskPicAndNotes
                                        selectedData={searchObj.startdate}
                                        dateFormat={CountryDateFormat[searchObj.country] || "DD/MM/YYYY"}
                                        forcastDay={searchObj?.forcastday}
                                        childChangeSelectedDate={childChangeSelectedDate}
                                    />
                                </div>
                                <div className="forecastButton">
                                    <AGForcastButton/>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="error_page">
                        <div className="error_content">
                            <span>There is an issue with accessing the page. </span>
                            <span>Please provide the correct token.</span>
                        </div>
                    </div>
            }
        </>

    );
};

export default DiseaseRisk;
