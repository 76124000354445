import { configureStore, combineReducers, } from "@reduxjs/toolkit";
import { ICommonModel } from "./commonReducer";
import { IDiseaseModel } from "./diseaseReducer";
import { ISimulationModel } from "./simulationReducer";
import { IQuantisModel } from "./quantisReducer";
import createSagaMiddleware from "redux-saga";
import rootSaga from "../sagas/rootSaga";
import commonReducer from "./commonReducer";
import diseaseReducer from "./diseaseReducer";
import simulationReducer from "./simulationReducer";
import quantisReducer from "./quantisReducer";
import thunk from "redux-thunk";

const sagaMiddleWare = createSagaMiddleware();

const rootReducer = combineReducers({
    commonReducer,
    diseaseReducer,
    simulationReducer,
    quantisReducer
});

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDedaultMiddleware) => getDedaultMiddleware().concat([thunk, sagaMiddleWare])
});

sagaMiddleWare.run(rootSaga);

export type RootState = {
    commonReducer: ICommonModel,
    diseaseReducer: IDiseaseModel,
    simulationReducer: ISimulationModel,
    quantisReducer: IQuantisModel
};

export default store;