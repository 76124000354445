import { dateJson } from "./cosntants";

function getYYYYmmDDFormatDate() {
    const date = new Date();
    let month: number | string = date.getMonth() + 1;
    let day: number | string = date.getDate();
    if (month >= 1 && month <= 9) {
        month = "0" + month;
    }

    if (day >= 0 && day <= 9) {
        day = "0" + day;
    }

    const dateString = `${date.getFullYear()}/${month}/${day}`;
    return dateString;
}

function transferDateFormatToDDmmYYYY(dateString) {
    const dateArrString = dateString.match(/\d\d\d\d\/\d\d\/\d\d/)[0];
    const dateArr = dateArrString.split("/");
    const yearString = dateArr[0];
    const monthString = dateArr[1];
    const dayString = dateArr[2];

    return `${dayString}/${monthString}/${yearString}`;
}

function transferTempretureToFar(temperature: number) {
    const far = 9.0/5.0*temperature + 32;
    return `${far}`;
}

enum RegionEnum {
    AE = "AE",
    AU = "AU",
    BY = "BY",
    DE = "DE",
    ES = "ES",
    FR = "FR",
    GB = "GB",
    IL = "IL",
    JP = "JP",
    KR = "KR",
    PL = "PL",
    PT = "PT",
    se = "se",
    SI = "SI",
    TR = "TR",
    UK = "UK",
    US = "US",
    ZA = "ZA"
}

const firstReg = /\d\d\d\d\/\d\d\/\d\d/;//"YYYY/MM/DD"
const seocndReg = /\d\d\/\d\d\/\d\d\d\d/;//DD/MM/YYYY"或"MM/DD/YYYY"

enum Formatter {
    first = "first",
    seocnd = "second"
}

//transferDateToTargetFormater(paramModel.countryCode, item.weatherDate, Formatter.seocnd)?.dayOfWeek
function transferDateToTargetFormater(region: RegionEnum | string, dateString: string, dateFormatterType: Formatter = Formatter.first) {
    if (!dateString) {
        return {
            date: "",
            dayOfWeek: ""
        };
    }

    // const dateFormatter = dateJson[region].dateFormat;
    let dateFormatter = null;
    if(dateJson[region]?.dateFormat){
        dateFormatter = dateJson[region].dateFormat;
    }
    else{
        dateFormatter = dateJson["GB"].dateFormat;
    }
    let date = "";
    let dayOfWeek = "";
    if (dateFormatter && region && dateString) {
        let targetFormatter;
        if (dateFormatterType === Formatter.first) {
            targetFormatter = firstReg;
        } else {
            targetFormatter = seocndReg;
        }

        const dateRegRes = dateString.match(targetFormatter);
        if (dateRegRes === null) {
            return {
                date: "",
                dayOfWeek: ""
            };
        }
        //const dateRes = dateRegRes[0].replace(/\//g, "-",);
        const dateRes = dateRegRes[0];
        const targetDate = new Date(dateRes);

        const opt = {
            "y": targetDate.getFullYear().toString(),
            "m": (targetDate.getMonth() + 1).toString().padStart(2, "0"),
            "d": targetDate.getDate().toString().padStart(2, "0")
        };
        const seprater = dateFormatter.match(/\W/)[0];
        const dateFormatterArr = dateFormatter.split(seprater);
        // const weekList = dateJson[region].dayNamesShort;
        let weekList = null;
        if(dateJson[region]?.dayNamesShort){
            weekList = dateJson[region].dayNamesShort;
        }
        else{
            weekList = dateJson["GB"].dayNamesShort;
        }
        // const firstDay = dateJson[region].firstDay;
        const dateIndex = targetDate.getDay();

        dayOfWeek = weekList[dateIndex];

        dateFormatterArr.forEach((dateFor, index) => {
            let cacheDateStr = "";
            switch (dateFor) {
                case "yyyy": {
                    cacheDateStr = opt.y;
                    break;
                }
                case "yy": {
                    cacheDateStr = opt.y.substring(2, 4);
                    break;
                }
                case "mm": {
                    cacheDateStr = opt.m;
                    break;
                }
                case "dd": {
                    cacheDateStr = opt.d;
                }
            }

            if (index === dateFormatterArr.length - 1) {
                if(region.toUpperCase()=="CA")
                    date = (date.concat(`${cacheDateStr}`)).substring(0,5);
                else
                    date = (date.concat(`${cacheDateStr}`));
            } else {
                date = date.concat(`${cacheDateStr}${seprater}`);
            }
        });
    }
    return {
        date,
        dayOfWeek
    };
}


export {
    getYYYYmmDDFormatDate,
    transferDateFormatToDDmmYYYY,
    transferTempretureToFar,
    transferDateToTargetFormater,
    Formatter
};