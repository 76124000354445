import React, { useState,useEffect } from "react";
import { Card, Col, Row ,Input,Space,Divider,Button ,Radio,InputNumber } from "antd";
import type { RadioChangeEvent } from "antd";
import { ResetValueIcon} from "@/resource/IconComponents";
import "./index.less";
import { useLocation } from "react-router-dom";

interface ISearchObj{
    theme?: string;
}
const AIS = () => {
    const urlLocation = useLocation();
    const [searchObj,setSearObj] = useState<ISearchObj>({
        theme: "light",
    });

    // add google analysis
    useEffect(() => {
        console.log("just invoke once");
        const head = document.getElementsByTagName("head")[0];
        const googleAnalyticsCode = "G-4XEE761MY8";
        const googleAnalyticsFirstScript = document.createElement("script");
        googleAnalyticsFirstScript.async = true;
        googleAnalyticsFirstScript.src =
          "https://www.googletagmanager.com/gtag/js?id=" + googleAnalyticsCode;

        const googleAnalyticsSecondScript = document.createElement("script");
        googleAnalyticsSecondScript.innerHTML =
          "    window.dataLayer = window.dataLayer || [];\n" +
          "    function gtag(){dataLayer.push(arguments);}\n" +
          "    gtag('js', new Date());\n" +
          "\n" +
          "    gtag('config', '" +
          googleAnalyticsCode +
          "',{\n" +
          "cookie_flags:'max-age=7200;secure;samesite-none'" +
          "});";

        head.insertBefore(googleAnalyticsSecondScript, head.firstChild);
        head.insertBefore(googleAnalyticsFirstScript, head.firstChild);
    }, []);

    const initSearchObj = ()=>{
        const searchStr = location?.search.replace("?","");
        const searchArr = searchStr?.split("&");
        const tmpSearchObj = {...searchObj};
        searchArr?.map((item)=>{
            const searchKeyValue = item?.split("=");
            tmpSearchObj[searchKeyValue[0].toLowerCase()] = searchKeyValue[1];
        });
        setSearObj(tmpSearchObj);
    };
    useEffect(() => {
        // init the searchobj, and the value data is form the url
        initSearchObj();
    }, [urlLocation]);
    //#region Water Consumption (l/ha)
    //XSAD flow rate
    //FDFDAAE swigging swi country
    //Speed
    //Water Consumption (l/ha)
    const [xsadFlowRate,setXsadFlowRate]=useState("");
    const [swiggingSwiCountry,setSwiggingSwiCountry]=useState("");
    const [speed,setSpeed]=useState("");
    const [waterConsumptionResult,setWaterConsumptionResult] =useState("");
    const WaterConsumptionCalculator = ()=>{
        setWaterConsumptionResult(((parseInt(xsadFlowRate)*600)/(parseInt(swiggingSwiCountry)*parseInt(speed))).toFixed(2).toString());
    };
    const WaterConsumptionReset =()=>{
        setXsadFlowRate("");
        setSwiggingSwiCountry("");
        setSpeed("");
        setWaterConsumptionResult("");
    };
    //#endregion

    //#region SDSD flow(l/min)
    //Water consumption
    //SDSE swiage sings
    //SDSDSpeed
    //CDSD flow(1/min)
    const [waterConsumption,setWaterConsumption]=useState("");
    const [sdseSwiageSings,setsdseSwiageSings]=useState("");
    const [sdseSpeed,setSdseSpeed]=useState("");
    const [cdsdFlowResult,setCdsdFlowResult] =useState("");
    const CdsdFlowCalculator = ()=>{
        setCdsdFlowResult(((parseInt(waterConsumption)*parseInt(sdseSwiageSings)*parseInt(sdseSpeed))/600).toFixed(2).toString());
    };
    const CdsdFlowReset =()=>{
        setWaterConsumption("");
        setsdseSwiageSings("");
        setSdseSpeed("");
        setCdsdFlowResult("");
    };
    //#endregion
    //#region Speed (km/h)
    //Water consumption
    //Sewecx swigging swiec sings
    //SDSD flow
    //Speed (km/h)
    const [speedWaterConsumption,setSpeedWaterConsumption]=useState("");
    const [speedSewecxSwigging,setSpeedSewecxSwigging]=useState("");
    const [speedSDSDFlow,setSpeedSDSDFlow]=useState("");
    const [speedResult,setSpeedResult] =useState("");
    const SpeedCalculator = ()=>{
        //(output*600)/(distance*dose)
        setSpeedResult(((parseInt(speedSDSDFlow)*600)/(parseInt(speedWaterConsumption)*parseInt(speedSewecxSwigging))).toFixed(2).toString());
    };
    const SpeedReset =()=>{
        setSpeedWaterConsumption("");
        setSpeedSewecxSwigging("");
        setSpeedSDSDFlow("");
        setSpeedResult("");
    };
    //#endregion
    //#region PoDFDoed povDFF on (ha)
    //Tank volume
    //Water consumption
    //PoF roped povFDF a (ha)
    const [tankVolume,setTankVolume]=useState("");
    const [poDFDoedWaterConsumption,setPoDFDoedWaterConsumption]=useState("");
    const [poFropedPovPDFaResult,setPoFropedPovPDFaResult] =useState("");
    const PoFropedPovPDFaCalculator = ()=>{
        setPoFropedPovPDFaResult(((parseInt(tankVolume)/parseInt(poDFDoedWaterConsumption))).toFixed(2).toString());
    };
    const PoDFDoedPovDFFonReset =()=>{
        setTankVolume("");
        setPoDFDoedWaterConsumption("");
        setPoFropedPovPDFaResult("");
    };
    //#endregion
    //#region Volume of chemicals per load
    //PovrFD /cargo
    //Dose per hectare
    //Volume of chemicals per load (l/load)
    const [povrFDCargo,setPovrFDCargo]=useState("");
    const [doesPerHectare,setDoesPerHectare]=useState("");
    const [volumeOfChemicalsPerLoadResults,setVolumeOfChemicalsPerLoadResults] =useState("");
    const VolumeOfChemicalsPerLoadResultsCalculator = ()=>{
        setVolumeOfChemicalsPerLoadResults(((parseInt(povrFDCargo)*parseInt(doesPerHectare))).toFixed(2).toString());
    };
    const VolumeOfChemicalsPerLoadReset =()=>{
        setPovrFDCargo("");
        setDoesPerHectare("");
        setVolumeOfChemicalsPerLoadResults("");
    };
    //#endregion
    //#region Required flow pressure
    //WEWX flow
    //Pressure
    //Flow rate
    //Required Flow Pressure
    const [wewxFlow,setWewxFlow]=useState("");
    const [pressure,setPressure]=useState("");
    const [flowRate,setFlowRate]=useState("");
    const [requiredFlowPressureResults,setRequiredFlowPressureResults] =useState("");
    const RequiredFlowPressureCalculator = ()=>{
        //pressure*Math.pow(newoutput/output,2)
        setRequiredFlowPressureResults((parseInt(pressure)*Math.pow(parseInt(flowRate)/parseInt(wewxFlow),2)).toFixed(2).toString());
    };
    const RequiredFlowPressureRest =()=>{
        setWewxFlow("");
        setPressure("");
        setFlowRate("");
        setRequiredFlowPressureResults("");
    };
    //#endregion
    //#region Water consumption in fruit plant
    //VSDSC
    //SD XCSEW
    //PovrSDC DF EWEW
    //Inter-line distance
    //DolSWDS type
    //Volume of crop o DSDSWD
    //Rare krSD FD.rare crop(l/ha)
    //Average coverage (l/ha)
    //Guest kDF or guest crop (l/ha)

    const [vsdsc,setVsdsc]=useState("");
    const [sdXcsew,setSdXcsew]=useState("");
    const [povrSDCDFEWEW,setPovrSDCDFEWEW]=useState("");
    const [interlineDistance,setInterlineDistance]=useState("");
    const [dolSWDSType,setDolSWDSType]=useState("");
    const [volumeOfFDRareCrop,setVolumeOfFDRareCrop]=useState("");
    const [rareKrSDFDRareResults,setRareKrSDFDRareResults] =useState("");
    const [averageCoverageResults,setAverageCoverageResults] =useState("");
    const [guestKDForGuestCropResults,setGuestKDForGuestCropResults] =useState("");
    const WaterConsumptioninFruitPlantCalculator = ()=>{
        setPovrSDCDFEWEW(((parseInt(vsdsc)*parseInt(sdXcsew))).toFixed(2).toString());
        setDolSWDSType(((10000/parseInt(interlineDistance))).toFixed(2).toString());
        setVolumeOfFDRareCrop(((parseInt(vsdsc)*parseInt(sdXcsew)*10000/parseInt(interlineDistance))).toFixed(2).toString());
        //Math.round(width*height*10000/spacing/17)
        setRareKrSDFDRareResults((Math.round(parseInt(vsdsc)*parseInt(sdXcsew)*10000/parseInt(interlineDistance)/17)).toFixed(2).toString());
        //Math.round(width*height*10000/spacing/14)
        setAverageCoverageResults((Math.round(parseInt(vsdsc)*parseInt(sdXcsew)*10000/parseInt(interlineDistance)/14)).toFixed(2).toString());
        //Math.round(width*height*10000/spacing/11)
        setGuestKDForGuestCropResults((Math.round(parseInt(vsdsc)*parseInt(sdXcsew)*10000/parseInt(interlineDistance)/11)).toFixed(2).toString());
    };
    const WaterConsumptioninFruitPlantRest =()=>{
        setVsdsc("");
        setSdXcsew("");
        setPovrSDCDFEWEW("");
        setInterlineDistance("");
        setDolSWDSType("");
        setVolumeOfFDRareCrop("");
        setRareKrSDFDRareResults("");
        setAverageCoverageResults("");
        setGuestKDForGuestCropResults("");
    };
    //#endregion
    //#region Effect on flow rate at pressure
    //DFF FD flow
    //Pressure
    //Pressure2
    //Flow rate (l/min)
    const [dffFDFlow,setDffFDFlow]=useState("");
    const [effectPressure,setEffectPressure]=useState("");
    const [effectPressure2,setEffectPressure2]=useState("");
    const [flowRateResult,setFlowRateResult] =useState("");
    const FlowRateCalculator = ()=>{
        //output*Math.sqrt(newpressure/pressure)
        setFlowRateResult((parseInt(dffFDFlow)*Math.sqrt(parseInt(effectPressure2)/parseInt(effectPressure))).toFixed(2).toString());
    };
    const FlowRateReset =()=>{
        setDffFDFlow("");
        setEffectPressure("");
        setEffectPressure2("");
        setFlowRateResult("");
    };
    //#endregion
    //#region Volume of chemicals per load
    //Tank volume
    //Water consumption
    //Dose per hectare
    //Volume of chemicals per load (l/load)
    const [chemicalsTankVolume,setChemicalsTankVolume]=useState("");
    const [chemicalsWaterConsumption,setChemicalsWaterConsumption]=useState("");
    const [chemicalsDoesPerHectare,setChemicalsDoesPerHectare]=useState("");
    const [volumeOfChemicalsPerLoadResult2,setVolumeOfChemicalsPerLoadResult2] =useState("");
    const VolumeOfChemicalsPerLoadCalculator2 = ()=>{
        //capacity/dose*rate
        setVolumeOfChemicalsPerLoadResult2((parseInt(chemicalsTankVolume)/parseInt(chemicalsWaterConsumption)*parseInt(chemicalsDoesPerHectare)).toFixed(2).toString());
    };
    const VolumeOfChemicalsPerLoadReset2 =()=>{
        setChemicalsTankVolume("");
        setChemicalsWaterConsumption("");
        setChemicalsDoesPerHectare("");
        setVolumeOfChemicalsPerLoadResult2("");
    };
    //#endregion
    //#region Return on means of force
    //PovDF FD surface
    //MDF yield yield
    //Danger of pDF b from strings (infested plants) Wireworm
    //MFD flight FD DFDFK (leaf DFD,DFD) aerialrisk
    //Value per tone (DFD) value
    //Sow density (50000 seeds in DFD) density
    //MoDFst losses (t/ha)
    //Value obtained
    const [povDFFD,setPovDFFD]=useState("");
    const [mdfYield,setMdfYield]=useState("");
    const [dangerOfPDFFromStrings,setDangerOfPDFFromStrings]=useState("");
    const [valuePerTone,setValuePerTone]=useState("");
    const [sowDensity,setSowDensity]=useState("");
    const [moDFstLosses,setMoDFstLosses] =useState("");
    const [valueObtained,setValueObtained] =useState("");
    const ReturnOnMeansOfForceCalculator = ()=>{
        //surface*yield*(Wireworm/100)*.85
        setMoDFstLosses((parseInt(povDFFD)*parseInt(mdfYield)*(parseInt(dangerOfPDFFromStrings)/100)*0.85).toFixed(2).toString());
        //surface*yield*(Wireworm/100)*.85*0.85*value-surface*35*density+surface*15*aerialrisk
        setValueObtained((parseInt(povDFFD)*parseInt(mdfYield)*(parseInt(dangerOfPDFFromStrings)/100)*0.85*0.85*parseInt(valuePerTone)
        -parseInt(povDFFD)*35*parseInt(sowDensity)
        +parseInt(povDFFD)*15*value).toFixed(2).toString());
    };
    const ReturnOnMeansOfForceRest =()=>{
        setPovDFFD("");
        setMdfYield("");
        setDangerOfPDFFromStrings("");
        setValue(0);
        setValuePerTone("");
        setSowDensity("");
        setMoDFstLosses("");
        setValueObtained("");
    };
    //#endregion
    const [value, setValue] = useState(1);

    const onChange = (e: RadioChangeEvent) => {
        console.log("radio checked", e.target.value);
        setValue(e.target.value);
    };
    return (
        <div className={`ais-container aisBg-${searchObj.theme}`}>
            <div className="row-space">
                <div className="response-setting">
                    <div className="module-tyle">
                        <div className="card">
                            <div className="cardHeader">
                                <p className="cardTitle">Poraba vode (l/ha)</p>
                                <p className="cardType">Kalkulator</p>
                            </div>
                            <div className="cardContent">
                                <div className="iconAndTitle" onClick={(e) => {WaterConsumptionReset();}}>
                                    <ResetValueIcon color={searchObj.theme=="dark"?"#FFFFFF":"#4D5165"}/>
                                    <label className={searchObj.theme=="dark"?"blackIconTitle":"iconTitle"}>Nov vnos</label>
                                </div>
                                <div className="rowStyle">
                                    <span className="columnTitle">Pretok XSAD</span>
                                    <Space.Compact size="large">
                                        <InputNumber addonAfter={"l/min"} value={xsadFlowRate}
                                            onChange={(value) => { setXsadFlowRate(value);
                                            }} />
                                    </Space.Compact>
                                </div>
                                <div className="rowStyle">
                                    <span className="columnTitle" style={{verticalAlign: "top"}}>Razmak med FDFDAAE letvi</span>
                                    <Space.Compact size="large">
                                        <InputNumber addonAfter={"m"} value={swiggingSwiCountry}
                                            onChange={(value) => { setSwiggingSwiCountry(value); }} type={"number"}/>
                                    </Space.Compact>
                                </div>
                                <div className="rowStyle">
                                    <span className="columnTitle">Hitrost</span>
                                    <Space.Compact size="large">
                                        <InputNumber addonAfter={"km/h"} value={speed}
                                            onChange={(value) => { setSpeed(value); }} type={"number"}/>
                                    </Space.Compact>
                                </div>
                                <div className="divider"></div>
                                <Button className="bottomButton" onClick={() => { WaterConsumptionCalculator(); }}>Kalkulator</Button>
                                <div className="rowStyle" style={{marginTop:"14px"}}>
                                    <Row>
                                        <Col span={13}>
                                            <p className="bottomTitle" >Poraba vode (l/ha)</p>
                                        </Col>
                                        <Col span={10}>
                                            <p className="numberStyle">{waterConsumptionResult}</p>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="module-tyle">
                        <div className="card">
                            <div className="cardHeader">
                                <p className="cardTitle">Pretok SDSD(l/min)</p>
                                <p className="cardType">Kalkulator</p>
                            </div>
                            <div className="cardContent">
                                <div className="iconAndTitle" onClick={(e) => {CdsdFlowReset();}}>
                                    <ResetValueIcon color={searchObj.theme=="dark"?"#FFFFFF":"#4D5165"}/>
                                    <label className={searchObj.theme=="dark"?"blackIconTitle":"iconTitle"}>Nov vnos</label>
                                </div>
                                <div className="rowStyle">
                                    <span className="columnTitle">Poraba vode</span>
                                    <Space.Compact size="large">
                                        <InputNumber addonAfter={"l/ha"} value={waterConsumption}
                                            onChange={(value) => { setWaterConsumption(value); }} />
                                    </Space.Compact>
                                </div>
                                <div className="rowStyle">
                                    <span className="columnTitle">Razmak med SDSE letvi</span>
                                    <Space.Compact size="large">
                                        <InputNumber addonAfter={"m"} value={sdseSwiageSings}
                                            onChange={(value) => { setsdseSwiageSings(value); }} type={"number"}/>
                                    </Space.Compact>
                                </div>
                                <div className="rowStyle">
                                    <span className="columnTitle">Hitrost</span>
                                    <Space.Compact size="large">
                                        <InputNumber addonAfter={"km/h"} value={sdseSpeed}
                                            onChange={(value) => { setSdseSpeed(value); }}/>
                                    </Space.Compact>
                                </div>
                                <div className="divider"></div>
                                <Button className="bottomButton" onClick={() => { CdsdFlowCalculator(); }}>Kalkulator</Button>
                                <div className="rowStyle" style={{marginTop:"14px"}}>
                                    <Row>
                                        <Col span={13}>
                                            <p className="bottomTitle" >Pretok CDSD(l/min)</p>
                                        </Col>
                                        <Col span={10}>
                                            <p className="numberStyle">{cdsdFlowResult}</p>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="module-tyle">
                        <div className="card">
                            <div className="cardHeader">
                                <p className="cardTitle">Hitrost (km/h)</p>
                                <p className="cardType">Kalkulator</p>
                            </div>
                            <div className="cardContent">
                                <div className="iconAndTitle" onClick={(e) => {SpeedReset();}}>
                                    <ResetValueIcon color={searchObj.theme=="dark"?"#FFFFFF":"#4D5165"}/>
                                    <label className={searchObj.theme=="dark"?"blackIconTitle":"iconTitle"}>Nov vnos</label>
                                </div>
                                <div className="rowStyle">
                                    <span className="columnTitle">Poraba vode</span>
                                    <Space.Compact size="large">
                                        <InputNumber addonAfter={"l/ha"} value={speedWaterConsumption}
                                            onChange={(value) => { setSpeedWaterConsumption(value); }}/>
                                    </Space.Compact>
                                </div>
                                <div className="rowStyle">
                                    <span className="columnTitle" style={{verticalAlign: "top"}}>Razmak med SEWECX letvi</span>
                                    <Space.Compact size="large">
                                        <InputNumber addonAfter={"m"} value={speedSewecxSwigging}
                                            onChange={(value) => { setSpeedSewecxSwigging(value); }}/>
                                    </Space.Compact>
                                </div>
                                <div className="rowStyle">
                                    <span className="columnTitle">Pretok SDSD</span>
                                    <Space.Compact size="large">
                                        <InputNumber addonAfter={"l/min"} value={speedSDSDFlow}
                                            onChange={(value) => { setSpeedSDSDFlow(value); }} type={"number"}/>
                                    </Space.Compact>
                                </div>
                                <div className="divider"></div>
                                <Button className="bottomButton" onClick={() => { SpeedCalculator(); }}>Kalkulator</Button>
                                <div className="rowStyle" style={{marginTop:"14px"}}>
                                    <Row>
                                        <Col span={13}>
                                            <p className="bottomTitle" >Hitrost (km/h)</p>
                                        </Col>
                                        <Col span={10}>
                                            <p className="numberStyle">{speedResult}</p>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="module-tyle">
                        <div className="card">
                            <div className="cardHeader">
                                <p className="cardTitle">PoDFDopljena povDFF na (ha)</p>
                                <p className="cardType">Kalkulator</p>
                            </div>
                            <div className="cardContent" style={{height:"315px"}}>
                                <div className="iconAndTitle" onClick={(e) => {PoDFDoedPovDFFonReset();}}>
                                    <ResetValueIcon color={searchObj.theme=="dark"?"#FFFFFF":"#4D5165"}/>
                                    <label className={searchObj.theme=="dark"?"blackIconTitle":"iconTitle"}>Nov vnos</label>
                                </div>
                                <div className="rowStyle">
                                    <span className="columnTitle">Volumen rezervoarja</span>
                                    <Space.Compact size="large">
                                        <InputNumber addonAfter={"l"} value={tankVolume}
                                            onChange={(value) => { setTankVolume(value); }} type={"number"}/>
                                    </Space.Compact>
                                </div>
                                <div className="rowStyle">
                                    <span className="columnTitle">Poraba vode</span>
                                    <Space.Compact size="large">
                                        <InputNumber addonAfter={"l/ha"} value={poDFDoedWaterConsumption}
                                            onChange={(value) => { setPoDFDoedWaterConsumption(value); }} type={"number"}/>
                                    </Space.Compact>
                                </div>
                                <div className="divider"></div>
                                <Button className="bottomButton" onClick={() => { PoFropedPovPDFaCalculator(); }}>Kalkulator</Button>
                                <div className="rowStyle" style={{marginTop:"14px"}}>
                                    <Row>
                                        <Col span={13}>
                                            <p className="bottomTitle" >PoF ropljena povFDF a (ha)</p>
                                        </Col>
                                        <Col span={10}>
                                            <p className="numberStyle">{poFropedPovPDFaResult}</p>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="module-tyle">
                        <div className="card">
                            <div className="cardHeader">
                                <p className="cardTitle">Prostornina kemikalij na tovor (l/load)</p>
                                <p className="cardType">Kalkulator</p>
                            </div>
                            <div className="cardContent" style={{height:"315px"}}>
                                <div className="iconAndTitle" onClick={(e) => {VolumeOfChemicalsPerLoadReset();}}>
                                    <ResetValueIcon color={searchObj.theme=="dark"?"#FFFFFF":"#4D5165"}/>
                                    <label className={searchObj.theme=="dark"?"blackIconTitle":"iconTitle"}>Nov vnos</label>
                                </div>
                                <div className="rowStyle">
                                    <span className="columnTitle">PovrFD /tovor</span>
                                    <Space.Compact size="large">
                                        <InputNumber addonAfter={"ha"} type={"number"} value={povrFDCargo}
                                            onChange={(value) => { setPovrFDCargo(value); }}/>
                                    </Space.Compact>
                                </div>
                                <div className="rowStyle">
                                    <span className="columnTitle" >Odmerek na hektar</span>
                                    <Space.Compact size="large">
                                        <InputNumber addonAfter={"l/ha"} type={"number"} value={doesPerHectare}
                                            onChange={(value) => { setDoesPerHectare(value); }}/>
                                    </Space.Compact>
                                </div>
                                <div className="divider"></div>
                                <Button className="bottomButton" onClick={() => { VolumeOfChemicalsPerLoadResultsCalculator(); }}>Kalkulator</Button>
                                <div className="rowStyle" style={{marginTop:"14px"}}>
                                    <Row>
                                        <Col span={13}>
                                            <p className="bottomTitle" style={{paddingTop: "inherit"}}>Prostornina kemikalij na tovor (l/load)</p>
                                        </Col>
                                        <Col span={10}>
                                            <p className="numberStyle">{volumeOfChemicalsPerLoadResults}</p>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row-space">
                <div className="response-setting">
                    <div className="module-tyle">
                        <div className="card">
                            <div className="cardHeader">
                                <p className="cardTitle">Potreben tlak za pretok</p>
                                <p className="cardType">Kalkulator</p>
                            </div>
                            <div className="cardContent">
                                <div className="iconAndTitle" onClick={(e) => {RequiredFlowPressureRest();}}>
                                    <ResetValueIcon color={searchObj.theme=="dark"?"#FFFFFF":"#4D5165"}/>
                                    <label className={searchObj.theme=="dark"?"blackIconTitle":"iconTitle"}>Nov vnos</label>
                                </div>
                                <div className="rowStyle">
                                    <span className="columnTitle">Pretok WEWX</span>
                                    <Space.Compact size="large">
                                        <InputNumber addonAfter={"l/min"} type={"number"} value={wewxFlow}
                                            onChange={(value) => { setWewxFlow(value); }}/>
                                    </Space.Compact>
                                </div>
                                <div className="rowStyle">
                                    <span className="columnTitle">Tlak</span>
                                    <Space.Compact size="large">
                                        <InputNumber addonAfter={"kPa"} type={"number"} value={pressure}
                                            onChange={(value) => { setPressure(value); }}/>
                                    </Space.Compact>
                                </div>
                                <div className="rowStyle">
                                    <span className="columnTitle">Pretok</span>
                                    <Space.Compact size="large">
                                        <InputNumber addonAfter={"l/min"} type={"number"} value={flowRate}
                                            onChange={(value) => { setFlowRate(value); }}/>
                                    </Space.Compact>
                                </div>
                                <div className="divider"></div>
                                <Button className="bottomButton" onClick={() => { RequiredFlowPressureCalculator(); }}>Kalkulator</Button>
                                <div className="rowStyle" style={{marginTop:"14px"}}>
                                    <Row>
                                        <Col span={13}>
                                            <p className="bottomTitle" >Tlak (2) (kPa)</p>
                                        </Col>
                                        <Col span={10}>
                                            <p className="numberStyle">{requiredFlowPressureResults}</p>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="module-tyle">
                        <div className="card">
                            <div className="cardHeader">
                                <p className="cardTitle">Poraba vode v sadjarstvu</p>
                                <p className="cardType">Kalkulator</p>
                            </div>
                            <div className="cardContent">
                                <div className="iconAndTitle" onClick={(e) => {WaterConsumptioninFruitPlantRest();}}>
                                    <ResetValueIcon color={searchObj.theme=="dark"?"#FFFFFF":"#4D5165"}/>
                                    <label className={searchObj.theme=="dark"?"blackIconTitle":"iconTitle"}>Nov vnos</label>
                                </div>
                                <div className="rowStyle">
                                    <span className="columnTitle">VSDSC</span>
                                    <Space.Compact size="large">
                                        <InputNumber addonAfter={"m"} type={"number"} value={vsdsc}
                                            onChange={(value) => { setVsdsc(value); }}/>
                                    </Space.Compact>
                                </div>
                                <div className="rowStyle">
                                    <span className="columnTitle">SD XCSEW</span>
                                    <Space.Compact size="large">
                                        <InputNumber addonAfter={"m"} type={"number"} value={sdXcsew}
                                            onChange={(value) => { setSdXcsew(value); }}/>
                                    </Space.Compact>
                                </div>
                                <div className="rowStyle">
                                    <span className="columnTitle">PovrSDC DF EWEW</span>
                                    <Space.Compact size="large">
                                        <InputNumber addonAfter={"m²"} readOnly={true} value={povrSDCDFEWEW}/>
                                    </Space.Compact>
                                </div>
                                <div className="rowStyle">
                                    <span className="columnTitle">Medvrstna razdalja</span>
                                    <Space.Compact size="large">
                                        <InputNumber addonAfter={"m"} type={"number"} value={interlineDistance}
                                            onChange={(value) => { setInterlineDistance(value); }}/>
                                    </Space.Compact>
                                </div>
                                <div className="rowStyle">
                                    <span className="columnTitle">DolSWDS vrste</span>
                                    <Space.Compact size="large">
                                        <Input addonAfter={"m"} readOnly={true} value={dolSWDSType} />
                                    </Space.Compact>
                                </div>
                                <div className="rowStyle">
                                    <span className="columnTitle" style={{verticalAlign: "top"}}>Prostornina posevka o DSDSWD</span>
                                    <Space.Compact size="large">
                                        <Input addonAfter={"m³"} readOnly={true} value={volumeOfFDRareCrop} />
                                    </Space.Compact>
                                </div>
                                <div className="divider"></div>
                                <Button className="bottomButton" onClick={() => { WaterConsumptioninFruitPlantCalculator(); }}>Kalkulator</Button>
                                <div className="rowStyle" style={{marginTop:"14px"}}>
                                    <Row>
                                        <Col span={13}>
                                            <p className="bottomTitle" style={{paddingTop: "inherit"}}>Redka krSD FD. redek posevek(l/ha)</p>
                                        </Col>
                                        <Col span={10}>
                                            <p className="numberStyle">{rareKrSDFDRareResults}</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={13}>
                                            <p className="bottomTitle" >Povprecna pokritost (l/ha)</p>
                                        </Col>
                                        <Col span={10}>
                                            <p className="numberStyle">{averageCoverageResults}</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={13}>
                                            <p className="bottomTitle" style={{paddingTop: "inherit"}}>Gosta kDF oz. gost posevek (l/ha)</p>
                                        </Col>
                                        <Col span={10}>
                                            <p className="numberStyle">{guestKDForGuestCropResults}</p>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="module-tyle">
                        <div className="card">
                            <div className="cardHeader">
                                <p className="cardTitle">Vpliv na pretok pri spremembi tlaka</p>
                                <p className="cardType">Kalkulator</p>
                            </div>
                            <div className="cardContent">
                                <div className="iconAndTitle" onClick={(e) => {FlowRateReset();}}>
                                    <ResetValueIcon color={searchObj.theme=="dark"?"#FFFFFF":"#4D5165"}/>
                                    <label className={searchObj.theme=="dark"?"blackIconTitle":"iconTitle"}>Nov vnos</label>
                                </div>
                                <div className="rowStyle">
                                    <span className="columnTitle">Pretok DFF FD</span>
                                    <Space.Compact size="large">
                                        <InputNumber addonAfter={"l/min"} type={"number"} value={dffFDFlow}
                                            onChange={(value) => { setDffFDFlow(value); }}/>
                                    </Space.Compact>
                                </div>
                                <div className="rowStyle">
                                    <span className="columnTitle">Tlak</span>
                                    <Space.Compact size="large">
                                        <InputNumber addonAfter={"kPa"} type={"number"} value={effectPressure}
                                            onChange={(value) => { setEffectPressure(value); }}/>
                                    </Space.Compact>
                                </div>
                                <div className="rowStyle">
                                    <span className="columnTitle">Tlak (2)</span>
                                    <Space.Compact size="large">
                                        <InputNumber addonAfter={"kPa"} type={"number"} value={effectPressure2}
                                            onChange={(value) => { setEffectPressure2(value); }}/>
                                    </Space.Compact>
                                </div>
                                <div className="divider"></div>
                                <Button className="bottomButton" onClick={() => { FlowRateCalculator(); }}>Kalkulator</Button>
                                <div className="rowStyle" style={{marginTop:"14px"}}>
                                    <Row>
                                        <Col span={13}>
                                            <p className="bottomTitle" >Pretok (l/min)</p>
                                        </Col>
                                        <Col span={10}>
                                            <p className="numberStyle">{flowRateResult}</p>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="module-tyle">
                        <div className="card">
                            <div className="cardHeader">
                                <p className="cardTitle">Prostornina kemikalij na tovor (l/load)</p>
                                <p className="cardType">Kalkulator</p>
                            </div>
                            <div className="cardContent">
                                <div className="iconAndTitle" onClick={(e) => {VolumeOfChemicalsPerLoadReset2();}}>
                                    <ResetValueIcon color={searchObj.theme=="dark"?"#FFFFFF":"#4D5165"}/>
                                    <label className={searchObj.theme=="dark"?"blackIconTitle":"iconTitle"}>Nov vnos</label>
                                </div>
                                <div className="rowStyle">
                                    <span className="columnTitle">Volumen rezervoarja</span>
                                    <Space.Compact size="large">
                                        <InputNumber addonAfter={"l"} type={"number"} value={chemicalsTankVolume}
                                            onChange={(value) => { setChemicalsTankVolume(value); }}/>
                                    </Space.Compact>
                                </div>
                                <div className="rowStyle">
                                    <span className="columnTitle">Poraba vode</span>
                                    <Space.Compact size="large">
                                        <InputNumber addonAfter={"l/ha"} type={"number"} value={chemicalsWaterConsumption}
                                            onChange={(value) => { setChemicalsWaterConsumption(value); }}/>
                                    </Space.Compact>
                                </div>
                                <div className="rowStyle">
                                    <span className="columnTitle">Odmerek na hektar</span>
                                    <Space.Compact size="large">
                                        <InputNumber addonAfter={"l/ha"} type={"number"} value={chemicalsDoesPerHectare}
                                            onChange={(value) => { setChemicalsDoesPerHectare(value); }}/>
                                    </Space.Compact>
                                </div>
                                <div className="divider"></div>
                                <Button className="bottomButton" onClick={() => { VolumeOfChemicalsPerLoadCalculator2(); }}>Kalkulator</Button>
                                <div className="rowStyle" style={{marginTop:"14px"}}>
                                    <Row>
                                        <Col span={13}>
                                            <p className="bottomTitle" style={{paddingTop: "inherit"}}>Prostornina kemikalij na tovor (l/load)
                                            </p>
                                        </Col>
                                        <Col span={10}>
                                            <p className="numberStyle">{volumeOfChemicalsPerLoadResult2}</p>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="module-tyle">
                        <div className="card">
                            <div className="cardHeader">
                                <p className="cardTitle">Donosnostprevoznega sredstva za sila</p>
                                <p className="cardType">Kalkulator</p>
                            </div>
                            <div className="cardContent">
                                <div className="iconAndTitle" onClick={(e) => {ReturnOnMeansOfForceRest();}}>
                                    <ResetValueIcon color={searchObj.theme=="dark"?"#FFFFFF":"#4D5165"}/>
                                    <label className={searchObj.theme=="dark"?"blackIconTitle":"iconTitle"}>Nov vnos</label>
                                </div>
                                <div className="rowStyle">
                                    <span className="columnTitle">PovDF FD</span>
                                    <Space.Compact size="large">
                                        <InputNumber addonAfter={" "} type={"number"} value={povDFFD}
                                            onChange={(value) => { setPovDFFD(value); }}/>
                                    </Space.Compact>
                                </div>
                                <div className="rowStyle">
                                    <span className="columnTitle">MDF pridelek</span>
                                    <Space.Compact size="large">
                                        <InputNumber addonAfter={"g/ha"} type={"number"} value={mdfYield}
                                            onChange={(value) => { setMdfYield(value); }}/>
                                    </Space.Compact>
                                </div>
                                <div className="rowStyle">
                                    <span className="columnTitle" style={{verticalAlign: "top"}}>Nevarnost pDF b od strun (napadenih rastlin)</span>
                                    <Space.Compact size="large">
                                        <InputNumber addonAfter={" "} type={"number"} value={dangerOfPDFFromStrings}
                                            onChange={(value) => { setDangerOfPDFFromStrings(value); }}/>
                                    </Space.Compact>
                                </div>
                                <div className="rowStyle">
                                    <span className="columnTitle" style={{verticalAlign: "top"}}>MFD nost let FD DFDFk (listne DFD,DFD)</span>
                                    <Radio.Group onChange={onChange} value={value} style={{marginLeft:"35px"}}>
                                        <Radio value={1}><label className={searchObj.theme=="dark"?"white":"black"}>Da</label></Radio>
                                        <Radio value={0}><label className={searchObj.theme=="dark"?"white":"black"}>Ne</label></Radio>
                                    </Radio.Group>
                                </div>
                                <div className="rowStyle">
                                    <span className="columnTitle">Vrednost na tono (DFD)</span>
                                    <Space.Compact size="large">
                                        <InputNumber addonAfter={" "} type={"number"} value={valuePerTone}
                                            onChange={(value) => { setValuePerTone(value); }}/>
                                    </Space.Compact>
                                </div>
                                <div className="rowStyle">
                                    <span className="columnTitle" style={{verticalAlign: "top"}}>Gostota setve (50000 semen v DFD)</span>
                                    <Space.Compact size="large">
                                        <InputNumber addonAfter={" "} type={"number"} value={sowDensity}
                                            onChange={(value) => { setSowDensity(value); }}/>
                                    </Space.Compact>
                                </div>
                                <div className="divider"></div>
                                <Button className="bottomButton" onClick={() => { ReturnOnMeansOfForceCalculator(); }}>Kalkulator</Button>
                                <div className="rowStyle" style={{marginTop:"14px"}}>
                                    <Row>
                                        <Col span={13}>
                                            <p className="bottomTitle">MoDFst izgub (t/ha)</p>
                                        </Col>
                                        <Col span={10}>
                                            <p className="numberStyle">{moDFstLosses}</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={13}>
                                            <p className="bottomTitle" >MoDFst izgub (t/ha)</p>
                                        </Col>
                                        <Col span={10}>
                                            <p className="numberStyle">{valueObtained}</p>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AIS;