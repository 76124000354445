import { IGetDiseaseRiskInfoReqModel, IGetLocationReqModel } from "@/util/http/requestType";
import { ILocationModel } from "@/util/http/responseType";
import { IUserModel } from "../reducer/commonReducer";

export const normalActionTypes = {
    NORMAL_DISEASE_DATA: "NORMAL_DISEASE_DATA",
    NORMAL_SIMULATION_LOCATION_INFO: "NORMAL_SIMULATION_LOCATION_INFO",
    NORMAL_SIMULATION_IS_MAPCLICK: "NORMAL_SIMULATION_IS_MAPCLICK",
    NORMAL_SIMULATION_MAPCOUNT: "NORMAL_SIMULATION_MAPCOUNT",
};

export const sagaActionTypes = {
    SAGA_DISEASE_DATA: "SAGA_DISEASE_DATA",
    SAGA_SIMULATION_LOCATIONS: "SAGA_SIMULATION_LOCATIONS",
};


export const updateSimulationExecuteData = (diseaseData:any) => {
    return {
        type: normalActionTypes.NORMAL_DISEASE_DATA,
        payload: diseaseData
    };
};
export const updateSimulationLocationInfo = (locationInfo:string) => {
    return {
        type: normalActionTypes.NORMAL_SIMULATION_LOCATION_INFO,
        payload: locationInfo
    };
};
export const updateSimulationIsMapClick = (isClick:boolean) => {
    return {
        type: normalActionTypes.NORMAL_SIMULATION_IS_MAPCLICK,
        payload: isClick
    };
};



