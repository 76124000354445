import React, { useEffect, useState } from "react";
import Search from "../ForecastWeatherData/components/Search";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/redux/reducer/store";
import { sagaActionTypes, updateParamModel } from "@/redux/actions/commonAction";
import { LeftSide, RightSide, TemperatureIcon, WaterFallIcon, WindSpeedIcon } from "@/resource/IconComponents";
import { useLocation } from "react-router-dom";
import { ILocationModel } from "@/util/http/responseType";
import { dateJson, fiveDayPubName } from "@/util/cosntants";
import { List, Modal, Spin } from "antd";
import "./index.less";
import IF from "@/components/IF";
import { API_LOCAL_URL } from "@/util/http/config";
import { getYYYYmmDDFormatDate, transferDateToTargetFormater } from "@/util/commonFunction";

const Widgets = () => {

    const dispatch = useDispatch();

    const {
        currentLanguage,
        paramModel,
        localDailyWidgetWeather,
        weatherForcastDailyList,
        locations,
        homeIndexLoading,
        searchByPlace,
        searchByPlaceResult
    } = useSelector((state: RootState) => state.commonReducer);

    // add google analysis
    useEffect(() => {
        console.log("just invoke once");
        const head = document.getElementsByTagName("head")[0];
        const googleAnalyticsCode = "G-BY888T2045";
        const googleAnalyticsFirstScript = document.createElement("script");
        googleAnalyticsFirstScript.async = true;
        googleAnalyticsFirstScript.src =
          "https://www.googletagmanager.com/gtag/js?id=" + googleAnalyticsCode;

        const googleAnalyticsSecondScript = document.createElement("script");
        googleAnalyticsSecondScript.innerHTML =
          "    window.dataLayer = window.dataLayer || [];\n" +
          "    function gtag(){dataLayer.push(arguments);}\n" +
          "    gtag('js', new Date());\n" +
          "\n" +
          "    gtag('config', '" +
          googleAnalyticsCode +
          "',{\n" +
          "cookie_flags:'max-age=7200;secure;samesite-none'" +
          "});";

        head.insertBefore(googleAnalyticsSecondScript, head.firstChild);
        head.insertBefore(googleAnalyticsFirstScript, head.firstChild);
    }, []);

    const [inputText, setInputText] = useState("");
    const [currentLocation, setCurrentLocation] = useState<ILocationModel>();
    const [locationListShow, setLocationListShow] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);

    const urlLocation = useLocation();

    useEffect(() => {
        if(searchByPlaceResult){
            setCurrentLocation(locations[0]);
            setInputText(locations[0].AdminName._text.split(",")[0]);
        }
        else{
            if (locations.length > 0 && locations.length !== 1) {
                setLocationListShow(true);
            }
            if (locations.length === 1) {
                setCurrentLocation(locations[0]);
                setInputText(locations[0].AdminName._text.split(",")[0]);
            }
        }
    }, [locations,searchByPlaceResult]);

    useEffect(()=>{
        if(searchByPlace){
            if (paramModel.latitude) {
                dispatch({
                    type: sagaActionTypes.SAGA_COORDINATE_SEARCH,
                    payload: {searchText:`${paramModel.longitude},${paramModel.latitude}`}
                });
            }
        }
    },[searchByPlace]);

    useEffect(() => {
        if(!paramModel.placename){
            if (paramModel.latitude) {
                dispatch({
                    type: sagaActionTypes.SAGA_COORDINATE_SEARCH,
                    payload: { searchText: `${paramModel.longitude},${paramModel.latitude}` }
                });
                dispatch({
                    type: sagaActionTypes.SAGA_UPDATE_CURRENT_LANGUAGE,
                    payload: { strCultureCode: `${paramModel.language}-${paramModel.countryCode}`, pubname: "glibsip03p" }});
            }
        }else{
            dispatch({
                type: sagaActionTypes.SAGA_COORDINATE_SEARCH,
                payload: {searchText: `${paramModel.placename},${paramModel.countryCode}`,
                    searchFlag: true
                }
            });
            dispatch({
                type: sagaActionTypes.SAGA_UPDATE_CURRENT_LANGUAGE,
                payload: { strCultureCode: `${paramModel.language}-${paramModel.countryCode}`, pubname: "glibsip03p" }});
        }
    }, [paramModel]);

    useEffect( () => {
        const themeReg = /(?<=theme=)[\w]*/;
        const modelNameReg = /(?<=[Uu]ser[Ss]praywdws=)[\w]*/;
        const countryCodeReg = /(?<=country=)[\w]*/;
        const latitudeReg = /(?<=[Ll]at=)[+-.\w]*/;
        const longitudeReg = /(?<=[Ll]ong=)[+-.\w]*/;
        const languageReg = /(?<=[Ll]anguage=)[\w]*/;
        const numberOfDaysReg = /(?<=numberofforecast=)[\w]*/;
        const unitReg = /(?<=unit=)[\w]*/;
        const placenameReg=/placename=([^&]+)/;

        const themeRes = urlLocation.search.match(themeReg);
        const modelNameRes = urlLocation.search.match(modelNameReg);
        const countryCodeRes = urlLocation.search.match(countryCodeReg);
        const latitudeRes = urlLocation.search.match(latitudeReg);
        const longitudeRes = urlLocation.search.match(longitudeReg);
        const languageRes = urlLocation.search.match(languageReg);
        const numberOfDaysRes = urlLocation.search.match(numberOfDaysReg);
        const unitRes = urlLocation.search.match(unitReg);
        const placenameRes=urlLocation.search.match(placenameReg);

        const theme = themeRes && themeRes[0];
        const modelName = modelNameRes && modelNameRes[0];
        const countryCode = countryCodeRes && countryCodeRes[0];
        const latitude = parseFloat(latitudeRes[0] || "0");
        const longitude = parseFloat(longitudeRes[0] || "0");
        const language = languageRes[0];
        const numberOfDays = numberOfDaysRes && numberOfDaysRes[0] || "1";
        const unit = unitRes && unitRes[0] || "metric";
        const placename=placenameRes && placenameRes[1]||null;

        dispatch(updateParamModel({
            theme,
            modelName,
            countryCode,
            latitude,
            longitude,
            language,
            numberOfDays: parseFloat(numberOfDays),
            unit,
            placename
        }));

        if (theme) {
            const rootElement = document.getElementById("root");
            switch (theme) {
                case "dark": {
                    rootElement.className = "dark-theme";
                    break;
                }
                case "light": {
                    rootElement.className = "light-theme";
                    break;
                }
            }
        }
    }, [urlLocation]);

    useEffect(() => {
        if (currentLocation) {
            dispatch({
                type: sagaActionTypes.SAGA_GET_WEATHERFORCAST_DAILY_LIST,
                payload: {
                    ...currentLocation,
                    cultureCode:  `${paramModel.language}-${paramModel.countryCode}`,
                    numberOfDays: paramModel.numberOfDays,
                    isGreencastForecast: true
                }});
            dispatch({
                type: sagaActionTypes.SAGA_GET_LOCAL_HOURLY_WIDGET,
                payload: {
                    ...currentLocation,
                    cultureCode:  `${paramModel.language}-${paramModel.countryCode}`,
                    numberOfDays: paramModel.numberOfDays,
                    isNowcast: false,
                    isGreencastForecast: true
                }});
        }
    }, [currentLocation]);

    useEffect(() => {
        if(!paramModel.placename){
            if (paramModel.latitude) {
                dispatch({
                    type: sagaActionTypes.SAGA_COORDINATE_PARAM_SEARCH,
                    payload: {
                        searchText:  `${paramModel.longitude},${ paramModel.latitude}`
                    }
                });
            }
        }else{
            dispatch({
                type: sagaActionTypes.SAGA_COORDINATE_SEARCH,
                payload: {searchText: `${paramModel.placename},${paramModel.countryCode}`,
                    searchFlag: true
                }
            });
        }
    }, [paramModel]);

    useEffect(()=>{
        if(paramModel?.theme?.toLowerCase() === "dark"){
            const rootElement = document.getElementById("root") as HTMLElement;
            const widgetElement = document.getElementById("home-view-widget-content") as HTMLElement;
            rootElement.style.backgroundColor = "black";
            widgetElement.style.backgroundColor = "#011B07";
        }
    },[paramModel.theme]);

    const coordinateButtonClicked = () => {
        navigator.geolocation.getCurrentPosition(
            (position: GeolocationPosition) => {
                dispatch({ type: sagaActionTypes.SAGA_COORDINATE_SEARCH, payload: {
                    searchText:  `${position.coords.longitude},${ position.coords.latitude}`
                }});
            },
            (err) => {
                console.log("test get current location error", err);
            }
        );
    };

    return (
        <div className="widget-content" >
            <Spin spinning={homeIndexLoading}>
                <div className="widget-container" id="home-view-widget-content">
                    <Modal
                        title={(
                            <label
                                style={{ fontSize: "22px", color: "green" }}
                            >
                        Search Location
                            </label>
                        )}
                        open={locationListShow}
                        onCancel={() => setLocationListShow(false)}
                        style={{
                            minWidth: "50%"
                        }}
                        footer={null}
                        destroyOnClose={true}
                    >
                        <List
                            dataSource={locations}
                            style={{
                                height: "500px",
                                overflow: "auto",
                                borderTop: "1px solid lightgray",
                            }}
                            renderItem={(item: ILocationModel) => {
                                return (
                                    <div style={{
                                        height: "40px",
                                        borderBottom: "1px solid lightgray",
                                        borderLeft: "1px solid lightgray",
                                        borderRight: "1px solid lightgray",
                                        paddingLeft: "15px",
                                        display: "flex",
                                        alignItems: "center"
                                    }}>
                                        <label
                                            onClick={() => {
                                                setCurrentLocation(item);
                                                setLocationListShow(false);
                                                setInputText(item.AdminName._text.split(",")[0]);
                                            }}
                                            style={{
                                                color: "#337ab7",
                                                fontSize: "20px"
                                            }}
                                        >
                                            {item.AdminName._text}
                                        </label>
                                    </div>
                                );
                            } } />
                    </Modal>
                    <div className="ag-weather-list">
                        <label className="top-title">
                            {currentLanguage.txt_AccurateWeather}
                        </label>
                    </div>
                    <div className="search-container">
                        <label className="indicate-label">{currentLanguage.txt_SelectLocation}</label>
                        <Search
                            onChange={(text: string) => setInputText(text)}
                            value={inputText}
                            placeholder={currentLanguage.txt_LocationNameLngLat}
                            searchButtonClick={() => {
                                const strArr = inputText.split(",");
                                if (strArr.length === 2) {
                                    const longitude = parseFloat(strArr[0]);
                                    const latitude = parseFloat(strArr[1]);
                                    if (typeof(latitude) === "number" && typeof(longitude) === "number") {
                                        dispatch({
                                            type: sagaActionTypes.SAGA_COORDINATE_SEARCH,
                                            payload: {searchText:`${longitude},${latitude}`}

                                        });
                                    }
                                } else {
                                    dispatch({
                                        type: sagaActionTypes.SAGA_COORDINATE_SEARCH,
                                        payload: {searchText: `${inputText},${paramModel.countryCode}`}
                                    });
                                }
                            } }
                            locationButtonClicked={() => coordinateButtonClicked()} />
                    </div>
                    <div className="weather-card-top-container-div">
                        <div className="date-div">
                            <button
                                style={{ width: "60px" }}
                                onClick={(() => {
                                    if (currentIndex > 0 && localDailyWidgetWeather.length > 1) {
                                        setCurrentIndex(currentIndex - 1);
                                    }
                                })}>
                                <IF show={localDailyWidgetWeather && localDailyWidgetWeather.length > 1 && currentIndex > 0}>
                                    <LeftSide />
                                </IF>

                            </button>
                            <img style={{objectFit: "none"}} alt="Icon" src={`${API_LOCAL_URL}api/weather-imgs/${localDailyWidgetWeather[currentIndex]?.imageName}`} />
                            <div className="weather-info-div">
                                <IF show={localDailyWidgetWeather[currentIndex]?.localDateTime.match(/\d\d\d\d\/\d\d\/\d\d/)[0] === getYYYYmmDDFormatDate()}>
                                    <label
                                        className="today-lable"
                                        style={{ color: `${paramModel.theme === "dark" ? "#C2C7D0" : "#868CA2"}` }}
                                    >
                                        {currentLanguage.txt_today}
                                    </label>
                                </IF>
                                <div className="weather-icon-div">
                                    <IF show={paramModel.language.toLowerCase()=="ja"}>
                                        <label style={{ color: `${paramModel.theme === "dark" ? "#F3F4F6" : "#232630"}` }}>
                                            {localDailyWidgetWeather[currentIndex]?.localDateTime.match(/(?<=\d\d\d\d\/)\d\d\/\d\d/)[0]}
                                            ({localDailyWidgetWeather[currentIndex]?.formatDay})
                                        </label>
                                    </IF>
                                    <IF show={paramModel.language.toLowerCase()!="ja"}>
                                        <label style={{ color: `${paramModel.theme === "dark" ? "#F3F4F6" : "#232630"}` }}>
                                            {localDailyWidgetWeather[currentIndex]?.formatDay},
                                            {localDailyWidgetWeather[currentIndex]?.localDateTime.match(/(?<=\d\d\d\d\/)\d\d\/\d\d/)[0].split("/").reverse().join(".")}
                                        </label>
                                    </IF>
                                    <label
                                        style={{ color: `${paramModel.theme === "dark" ? "#F3F4F6" : "#232630"}` }}
                                    >
                                        {paramModel.unit === "metric" ? `${localDailyWidgetWeather[currentIndex]?.tempAir || 0}℃` : `${localDailyWidgetWeather[currentIndex]?.tempAir_f || 0}°F`}
                                    </label>
                                </div>
                            </div>
                            <button
                                style={{ width: "60px" }}
                                onClick={(() => {
                                    if (currentIndex < localDailyWidgetWeather.length && localDailyWidgetWeather.length > 1) {
                                        setCurrentIndex(currentIndex + 1);
                                    }
                                })}>
                                <IF show={localDailyWidgetWeather.length > 1 && currentIndex < localDailyWidgetWeather.length - 1}>
                                    <RightSide />
                                </IF>
                            </button>
                        </div>
                        <div className="weather-container" style={{ backgroundColor: `${paramModel.theme === "dark" && "#DFFBE8" }` }}>
                            <div className="water-fall-div">
                                <WaterFallIcon color="#232630"/>
                                <span className="number-lable">
                                    {paramModel.unit === "metric" ? localDailyWidgetWeather[currentIndex]?.precip_Sum_mm : localDailyWidgetWeather[currentIndex]?.precip_Sum_in }
                                    <span>{paramModel.unit === "metric" ? " mm" : " in"}</span>
                                </span>
                            </div>
                            <div className="wind-speed-div">
                                <WindSpeedIcon color="#232630"/>
                                <span className="number-lable">
                                    {paramModel.unit === "metric" ? localDailyWidgetWeather[currentIndex]?.windSpeed_kph.toFixed(2) : localDailyWidgetWeather[currentIndex]?.windSpeed_mph.toFixed(2)}
                                    {paramModel.unit === "metric" ? " k/h" : " m/h"}
                                </span>
                                <span className="number-lable">
                                    {localDailyWidgetWeather[currentIndex]?.windDirection}
                                </span>
                            </div>
                            <div className="temperature-div">
                                {/* need get the min and max from list */}
                                <TemperatureIcon color="#232630" />
                                <span className="number-lable">
                                    {`${currentLanguage.txt_Max}: ${
                                        paramModel.unit === "metric" ?
                                            weatherForcastDailyList[currentIndex]?.tempAir_DailyMax || 0 : weatherForcastDailyList[currentIndex]?.tempAir_DailyMax_f} 
                                                ${paramModel.unit === "metric" ? "°" : "°F" }`}
                                </span>
                                <span className="number-lable">
                                    {`${currentLanguage.txt_Min}: ${
                                        paramModel.unit === "metric" ?
                                            weatherForcastDailyList[currentIndex]?.tempAir_DailyMin || 0 : weatherForcastDailyList[currentIndex]?.tempAir_DailyMin_f} 
                                                ${paramModel.unit === "metric" ? "°" : "°F" }`}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </Spin>
        </div>
    );
};

export default Widgets;

