import React from "react";
import "./index.less";
import { DewPointFirstIcon, GoundTempleIcon, TemperatureReduceIcon, TempratureFirstIcon } from "@/resource/IconComponents";
import IF from "@/components/IF";
import { IHour24DataModel } from "@/util/http/responseType";

interface IProps {
    include?: {
        [keyValue: string]: boolean
    }
    weatherData?: IHour24DataModel
    indent?: boolean
    justShowIcon?: boolean
    isFiveDayWeather?: boolean
}


const HourlyFirst = (props: IProps) => {

    const {
        include,
        weatherData,
        indent,
        justShowIcon = false,
        isFiveDayWeather
    } = props;

    return (
        <div className={`ag-first-div ${indent && "indent-container-class"}`}>
            <IF show={!justShowIcon}>
                <IF show={include["temperature_max"] || include["temperaturef_max"] || (!indent && Boolean(weatherData?.temperature_max || weatherData?.temperaturef_max))}>
                    <div className="up-div">
                        <IF show={!indent}>
                            <div>
                                <TempratureFirstIcon />
                            </div>
                            <label className="second-label">
                                {weatherData?.["temperature_max-name"]}
                                {weatherData?.["temperaturef_max-name"]}
                            </label>
                        </IF>
                        <label className="third-label">
                            {weatherData?.temperature_max}
                            {weatherData?.temperaturef_max}
                        </label>
                    </div>
                </IF>
                <IF show={isFiveDayWeather}>
                    <div className="up-div">
                        <IF show={!indent}>
                            <div>
                                <GoundTempleIcon />
                            </div>
                            <label className="second-label">
                                {weatherData?.["groundtemp-name"]}
                                {weatherData?.["groundtemp_f-name"]}

                            </label>
                        </IF>
                        <label className="third-label">
                            {weatherData?.["groundtemp"]}
                            {weatherData?.["groundtemp_f"]}

                        </label>
                    </div>
                </IF>
                <IF show={include["temperature_min"] || include["temperaturef_min"] || (!indent && Boolean(weatherData?.temperature_min || weatherData?.temperaturef_min))}>
                    <div className="up-div">
                        <IF show={!indent}>
                            <div>
                                <TemperatureReduceIcon />
                            </div>
                            <label className="second-label">
                                {weatherData?.["temperature_min-name"]}
                                {weatherData?.["temperaturef_min-name"]}
                            </label>
                        </IF>
                        <label className="third-label">
                            {weatherData?.temperature_min}
                            {weatherData?.temperaturef_min}
                        </label>
                    </div>
                </IF>
                <IF show={include["dewpoint"] ||include["dewpointf"] || (!indent && Boolean(weatherData?.["dewpoint"] || weatherData?.["dewpointf"]))}>
                    <div className="down-div">
                        <IF show={!indent}>
                            <div>
                                <DewPointFirstIcon />
                            </div>
                            <label className="second-label">
                                {weatherData?.["dewpoint-name"]}
                                {weatherData?.["dewpointf-name"]}
                            </label>
                        </IF>
                        <label className="third-label">
                            {weatherData?.["dewpoint"]}
                            {weatherData?.["dewpointf-name"]}
                        </label>
                    </div>
                </IF>
                <IF show={include["Ground Temperature(°C)"] || (!indent && Boolean(weatherData?.["groundtemp"]))}>
                    <div className="down-div">
                        <IF show={!indent}>
                            <div>
                                <GoundTempleIcon />
                            </div>
                            <label className="second-label">
                                {weatherData?.["groundtemp-name"]}
                            </label>
                        </IF>
                        <label className="third-label">
                            {weatherData?.["groundtemp"]}
                        </label>
                    </div>
                </IF>
            </IF>
            <IF show={justShowIcon}>
                <IF show={justShowIcon && (include["temperature_max"] || include["temperaturef_max"])}>
                    <div className="up-div">
                        <TempratureFirstIcon />
                        <label className="second-label" style={{whiteSpace:"nowrap"}}>
                            {weatherData?.["temperature_max-name"]}
                            {weatherData?.["temperaturef_max-name"]}
                        </label>
                    </div>
                </IF>
                <IF show={justShowIcon && (include["dewpoint"] ||include["dewpointf"])}>
                    <div className="up-div">
                        <DewPointFirstIcon />
                        <label className="second-label">
                            {weatherData?.["dewpoint-name"]}
                            {weatherData?.["dewpointf-name"]}
                        </label>
                    </div>
                </IF>
                <IF show={justShowIcon && include["groundtemp"]}>
                    <div className="up-div">
                        <GoundTempleIcon />
                        <label className="second-label">
                            {weatherData?.["groundtemp-name"]}
                        </label>
                    </div>
                </IF>
            </IF>
        </div>
    );
};

export default HourlyFirst;