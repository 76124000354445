import { sagaActionTypes } from "@/redux/actions/diseaseAction";
import { RootState } from "@/redux/reducer/store";
import { getSeries } from "@/util/http/HistWeather";
import { IGetSeriesResModel } from "@/util/http/HistWeather/responseType";
import { useEffect,useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {debounce} from "lodash";

interface IProp{
    countryCode: string;
    language: string;
}

interface IHistDiseaseTranslate{
    txt_SelectLocationToSeeHistWeather?: string;
    txt_DownloadAsImage?: string;
    txt_SelYourStartAndEndDate?: string;
    txt_SelFromCbxToRefreshChart?: string;
    txt_DiseaseRisks?: string;
    txt_SelectLocation?: string;
    txt_Legend?: string;
    gc_refreshchart?: string;
    ClearSelection?: string;
    email_to_page?: string;
    txt_Temperature?: string;
    txt_Precipitaion?: string;
    txt_HistoricDiseaseRisk?: string;
    txt_SelectDiseaseRiskMessage?: string;
    txt_HistoricDiseaseChart?: string;
    // for email
    gc_EmailHeader?: string;
    gc_EmailTo?: string;
    gc_EmailSubject?: string;
    gc_EmailMessageArea?: string;
    gc_SendEmailButton?: string;
    cancel?: string;
    gc_EmailSendFailed?: string;
    gc_EmailValid?: string;
    gc_EmailRequired?: string;
    gc_EmailSubjectRequired?: string;
    gc_EmailSendSuccess?: string;
    txt_LocationNameLngLat?: string;
    txt_ShareChartByEmail?: string;
    txt_HistDiseaseChartMailBody?: string;
}

const useHistDiseaseTranslate = (props:IProp)=>{
    const {
        countryCode,
        language
    } = props;
    const dispatch = useDispatch();
    const {
        translateText
    } = useSelector((state: RootState) => state.diseaseReducer);

    const [histDiseaseTranslate,setHistDiseaseTranslate] = useState<IHistDiseaseTranslate>(null);

    const TranslateObj = {
        "txt_SelectLocationToSeeHistWeather": "txt_SelectLocationToSeeHistWeather", // Select your location to see historical weather
        "txt_DownloadAsImage": "txt_DownloadAsImage", // Download as Image
        "txt_SelYourStartAndEndDate": "txt_SelYourStartAndEndDate", // Select your start and end date
        "txt_SelFromCbxToRefreshChart": "txt_SelFromCbxToRefreshChart", // Select from the checkboxes below to add or remove graph parameters, then click refresh chart to display changes
        "txt_SelectLocation": "txt_SelectLocation", // select you location to accumure weather
        "txt_DiseaseRisks": "txt_DiseaseRisks", // disesas risks
        "txt_Legend": "txt_Legend", // Legend
        "gc_refreshchart": "gc_refreshchart", // refresh chart
        "ClearSelection": "ClearSelection", // clear selection
        "gc_EmailPage": "email_to_page", // email this page
        "txt_Temperature": "temperaure",
        "txt_Precipitaion": "precipitation",
        "txt_HistoricDiseaseRisk": "txt_HistoricDiseaseRisk", // Historic Disease Risk
        "txt_SelectDiseaseRiskMessage": "txt_SelectDiseaseRiskMessage", // Select disease risk below to add or remove graph parameters, then click refresh chart to display changes
        "txt_HistoricDiseaseChart": "txt_HistoricDiseaseChart", // Historic Disease Chart
        // used for email
        "gc_EmailHeader": "gc_EmailHeader", // Welcome
        "gc_EmailTo": "gc_EmailTo", // To
        "gc_EmailSubject": "gc_EmailSubject", // Subject
        "gc_EmailMessageArea": "Message", // Message
        "gc_SendEmailButton": "gc_SendEmailButton", // Send Email
        "cancel": "cancel", // Cancel
        "gc_EmailSendFailed": "gc_EmailSendFailed", // Sorry! Eamil failed
        "gc_EmailValid": "gc_EmailValid", // Please enter a valid email id.
        "gc_EmailRequired": "gc_EmailRequired", // Email id is required.
        "gc_EmailSubjectRequired": "gc_EmailSubjectRequired", // Subject is required.
        "gc_EmailSendSuccess": "gc_EmailSendSuccess", // Email sent successfully!
        "txt_LocationNameLngLat": "txt_LocationNameLngLat", // Location name or long,lat
        // used for email new contnet
        "txt_ShareChartByEmail": "txt_ShareChartByEmail", // share this chart by email
        //"txt_HistoricDiseaseChart": "txt_HistoricDiseaseChart", // Historic Disease Chart
        "txt_HistDiseaseChartMailBody": "txt_HistDiseaseChartMailBody", // mail content
    };

    // when get translatetext from api, assign to page item
    const setPageItemToTranslate = ()=>{
        console.log("translateText",translateText);
        const tmpTranslate = {...histDiseaseTranslate};
        translateText.forEach((item)=>{
            if(TranslateObj[item.key]) tmpTranslate[TranslateObj[item.key]] = item.value;
        });
        setHistDiseaseTranslate(tmpTranslate);
    };

    const getTranslateFunc = ()=>{
        // get translate text by saga, and put response to the redux store
        dispatch({
            type: sagaActionTypes.SAGA_TRANSLATETEXT,
            payload:{
                strCultureCode:language + "-" + countryCode,
                pubname: "glbsip03p"
            }
        });
    };

    useEffect(()=>{
        if(countryCode && language) getTranslateFunc();
    },[countryCode,language]);

    useEffect(()=>{
        if(translateText?.length > 0) setPageItemToTranslate();
    },[translateText]);

    return {
        histDiseaseTranslate
    };
};

export default useHistDiseaseTranslate;