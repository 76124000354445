import "./index.less";
import React from "react";
import {Button} from "antd";
import IF from "@/components/IF";
import CEhub from "@/resource/images/png/CEhub.png";
import { useSelector } from "react-redux";
import { RootState } from "@/redux/reducer/store";

export default function HeadComponent(){
    const user:any ="user";
    const userSimplifyName = useSelector((state: RootState) => state.commonReducer.userSimplifyName);

    return (
        <div className="cehub-menu">
            <Button type="link" style={{
                width: "40px",
                height: "40px",
                marginLeft: "12px",
                padding: "12px",
                float: "left",
                marginTop: "8px",
                marginBottom: "8px"
            }}>
                {/*<MenuSvgComponent color={menuUnselectedColor} />*/}
            </Button>
            <img src={CEhub} alt="logo" draggable={false} className='logo-img' />
            <span>
                <IF show={user}>
                    {/*<MenuRightCornerIcon />*/}
                    <div className="div-user-simplify-name">
                        {/* {user?.userSimplifyName} */}
                        {userSimplifyName}
                    </div>
                </IF>
            </span>
        </div>
    );
}