import React, { useEffect, useState } from "react";
import "./index.less";
import { DatePicker, Modal, Select, Spin, Tree } from "antd";
import { DownloadXls, NewEmailIcon, NewPicIcon, SettingComponent, SoilProfileWarningAlertIcon } from "@/resource/IconComponents";
import HeatGoogleMap from "./HeatGoogleMap";
import { useDispatch, useSelector } from "react-redux";
import { normalActionTypes, sagaActionTypes } from "@/redux/actions/commonAction";
import { RootState } from "@/redux/reducer/store";
import SentEmailModal from "@/components/SentEmailModal";
import html2canvas from "html2canvas";
import { downloadHeatMapExcel } from "@/util/http";
import dayjs from "dayjs";
import IF from "@/components/IF";
const { confirm } = Modal;


enum stressTypes {
    heat = "Heat",
    cold = "Cold"
}
const { Option } = Select;

const HeatMap = () => {
    const [stressType, setStressType] = useState(stressTypes.heat);
    const [mapSettingModalOpen, setMapSettingModalOpen] = useState(false);
    const [checkedKeys, setCheckedKeys] = useState<string[]>([]);
    const [showEmailModal, setShowEmailModal] = useState(false);
    const [imageBase64Content, setImageBase64Content] = useState(null);
    const [startDate, setStartDate] = useState(dayjs().subtract(7, "day"));
    const [endDate, setEndDate] = useState(dayjs());
    const [startDateStatus, setStartDateStatus] = useState<"" | "warning" | "error">("");
    const [endDateStatus, setEndDateStatus] = useState<"" | "warning" | "error">("");
    const [showAmberValueWarning, setShowAmberValueWarning] = useState(false);
    const [showRedValueWarning, setShowRedValueWarning] = useState(false);
    const [downloadExcelEnable, setDownloadExcelEnable] = useState(false);
    const [currentSelectCountry, setCurrentSelectCountry] = useState(0);

    const dispatch = useDispatch();

    // add google analysis
    useEffect(() => {
        console.log("just invoke once");
        const head = document.getElementsByTagName("head")[0];
        const googleAnalyticsCode = "G-YDXV5VKQY9";
        const googleAnalyticsFirstScript = document.createElement("script");
        googleAnalyticsFirstScript.async = true;
        googleAnalyticsFirstScript.src =
          "https://www.googletagmanager.com/gtag/js?id=" + googleAnalyticsCode;

        const googleAnalyticsSecondScript = document.createElement("script");
        googleAnalyticsSecondScript.innerHTML =
          "    window.dataLayer = window.dataLayer || [];\n" +
          "    function gtag(){dataLayer.push(arguments);}\n" +
          "    gtag('js', new Date());\n" +
          "\n" +
          "    gtag('config', '" +
          googleAnalyticsCode +
          "',{\n" +
          "cookie_flags:'max-age=7200;secure;samesite-none'" +
          "});";

        head.insertBefore(googleAnalyticsSecondScript, head.firstChild);
        head.insertBefore(googleAnalyticsFirstScript, head.firstChild);
    }, []);

    const {
        heatMaplocations,
        mapSetting,
        heatMapLoading,
        geoIds,
        countryGeonameIdSet,
        excelName,
        changeSettingFlag
    } = useSelector((state: RootState) => state.commonReducer);

    const applyButtonClicked = () => {
        // request for map and excel datad
        let allGeonameIds = new Set<string>();

        for (const selectedKey of checkedKeys) {
            const result = geoIds[selectedKey];
            const newSet = new Set<string>(result);
            if (result?.length > 0) {
                allGeonameIds = new Set([...allGeonameIds, ...newSet]);
            } else {
                allGeonameIds.add(selectedKey);
            }
        }
        if (checkedKeys && checkedKeys.length > 0) {
            const validate = checktParam();
            if (validate) {
                dispatch({
                    type: sagaActionTypes.SAGA_GET_COUNTRY_BOUNDARY, payload: {
                        geonameId: [...allGeonameIds],
                        stressType: stressType.toLowerCase(),
                        startTime: startDate.format("YYYY-MM-DD"),
                        endTime: endDate.format("YYYY-MM-DD"),
                    }
                });
                setDownloadExcelEnable(true);
            }
        }
    };

    const clearLocationButtonClick = () => {
        setCheckedKeys([]);
    };

    useEffect(()=>{
        if(changeSettingFlag){
            applyButtonClicked();
        }
    },[changeSettingFlag]);

    useEffect(() => {
        setDownloadExcelEnable(false);
    }, [checkedKeys]);

    useEffect(() => {
        const {
            red_level,
            amber_level
        } = mapSetting;

        if (amber_level >= red_level) {
            setShowRedValueWarning(true);
            setShowAmberValueWarning(false);
        } else {
            setShowRedValueWarning(false);
            setShowAmberValueWarning(false);
        }
    }, [mapSetting.red_level]);

    useEffect(() => {
        const {
            red_level,
            amber_level
        } = mapSetting;

        if (amber_level >= red_level) {
            setShowAmberValueWarning(true);
            setShowRedValueWarning(false);
        } else {
            setShowAmberValueWarning(false);
            setShowRedValueWarning(false);
        }
    }, [mapSetting.amber_level]);

    useEffect(() => {
        dispatch({ type: sagaActionTypes.SAGA_GET_ALL_LOCATIONS });
        dispatch({ type: sagaActionTypes.SAGA_GET_HEAT_MAP_SETTING });
        dispatch({ type: sagaActionTypes.SAGA_GET_TEMP_REGION_SETTING });
    }, []);

    const warningModal = async (message) => {
        confirm({
            className: "soil-profile-warning-div",
            okButtonProps: { style: { borderRadius: "16px", width: "80px" } },
            title: "",
            icon: (
                <div style={{ height: "50px", display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                    <SoilProfileWarningAlertIcon />
                    <span style={{
                        color: "#14151C",
                        fontSize: "16px",
                        fontFamily: "Noto-Sans-Semibold",
                        fontWeight: 600,
                        marginLeft: "17px"
                    }}>
                        Warning
                    </span>
                </div>),
            content: message,
            okText: "OK",
            onCancel: () => {
                console.log("Cancel");
            },
            onOk: () => {
                console.log("ok");
            }
        });
    };

    console.log("lanxue countryGeonameIdSet",countryGeonameIdSet);
    console.log("lanxue geoIds",geoIds);
    console.log("lanxue countryGeonameIdSet",countryGeonameIdSet);
    console.log("lanxue heatMaplocations",heatMaplocations);

    const onCheck = (localCheckedKeys) => {
        console.log("lanxue localCheckedKeys",localCheckedKeys);
        const sampleGeo = localCheckedKeys[localCheckedKeys.length - 1];
        let newCheckedKeys = [...checkedKeys];

        let isEqualToBefore = true;
        countryGeonameIdSet.forEach((item, index) => {
            if (item.includes(sampleGeo)) {
                if(index !== currentSelectCountry) {
                    isEqualToBefore = false;
                    setCurrentSelectCountry(index);
                }
            }
        });

        if (isEqualToBefore) {
            setCheckedKeys(localCheckedKeys);
        } else {
            newCheckedKeys = localCheckedKeys.filter((item) => {
                return !newCheckedKeys.find((geoNameId) => {
                    return geoNameId === item;
                });
            });
            setCheckedKeys(newCheckedKeys);
        }
    };

    const disableStartTime = (date) => {
        const currentTime = dayjs();
        const result = date.diff(currentTime);
        return result > 0 ? true : false;
    };

    const disableEndTime = (date) => {
        const currentTime = dayjs();

        const result = date > startDate;
        const result1 = date < currentTime;
        return !(result && result1);
    };

    const getImageBase64Data = () => {
        const element = document.getElementById("heat-map-container");
        const element1 = document.querySelector(".right-menu-side") as HTMLElement;
        const bottom = document.querySelector(".bottom-setting") as HTMLElement;

        element1.style.visibility = "hidden";
        bottom.style.display = "block";
        if (element) {
            html2canvas(element, {
                useCORS: true
            }).then((canvas) => {
                const dataUrl = canvas.toDataURL("image/png");
                setImageBase64Content(dataUrl);
                element1.style.visibility = "visible";
                bottom.style.display = "none";

            });
        }
    };

    const downloadThisPage = () => {

        const element = document.getElementById("heat-map-container");
        const element1 = document.querySelector(".right-menu-side") as HTMLElement;
        const bottom = document.querySelector(".bottom-setting") as HTMLElement;

        element1.style.visibility = "hidden";
        bottom.style.display = "block";

        if (element) {
            html2canvas(element, {
                useCORS: true
            }).then((canvas) => {
                const dataUrl = canvas.toDataURL("image/png");
                //console.log("test images",dataUrl);

                const link = document.createElement("a");
                link.href = dataUrl;
                const timestamp = dayjs().valueOf();
                link.download = `${stressType}Map-${timestamp}.png`;
                link.click();
                element1.style.visibility = "visible";
                bottom.style.display = "none";
            });
        }
    };

    const localDownloadHeatMapExcel = () => {
        const validate = checktParam();
        if (!validate) {
            return;
        }
        downloadHeatMapExcel({excelName});
    };

    const checktParam = () => {
        let validate = false;
        if (checkedKeys.length === 0) {
            warningModal("Region selected cannot be null!");
        } else if (!startDate) {
            setStartDateStatus("error");
        } else if (!endDate) {
            setStartDateStatus("");
            setEndDateStatus("error");
        } else {
            validate = true;
            setStartDateStatus("");
            setEndDateStatus("");
        }
        return validate;
    };

    console.log("lanxue aa checkedKeys",checkedKeys);


    return (
        <div id="heat-map-container">
            <Spin spinning={heatMapLoading}>
                <SentEmailModal
                    open={showEmailModal}
                    id={"hist-heat-disease-sentEmailModal"}
                    welcomeStr={"Test"}
                    toStr={"To"}
                    // subjectStr={"Heat Stress Map"}
                    subjectStr={"Subject"}
                    messageStr={"Message Content"}
                    idRequiredStr={"required id"}
                    subjectRequiredStr={"subject required str"}
                    sendEmailStr={"Send"}
                    cancelStr={"Cancel"}
                    idValidStr={"invalid"}
                    emailFailedStr={"Failed"}
                    defaultTitle={"Shared Email"}
                    // defaultSubject={"Pls check"}
                    defaultSubject={`${stressType === stressTypes.cold ? "Cold Stress Map" : "Heat Stress Map"}`}
                    defaultMailContent={`Hi,

Attached is the ${stressType === stressTypes.cold ? "cold stress map" : "heat stress map"}.

Best Regards
CE Hub Team
                `}
                    sentEmailSuccessStr={"Send email successfully"}
                    emailFrom={"CEHub.dataplatform@syngenta.com"}
                    attachFileName={stressType === stressTypes.cold ? "Coldmap" : "Heatmap"}
                    cancelCallback={() => {
                        setShowEmailModal(false);
                    }}
                    imageBase64={imageBase64Content}
                />
                <Modal
                    open={mapSettingModalOpen}
                    title="Setting"
                    cancelText="Cancel"
                    okText="Save"
                    cancelButtonProps={{
                        ghost: true,
                        style: {
                            border: "0px"
                        }
                    }}
                    footer={null}
                    okButtonProps={{
                        ghost: true,
                        type: "primary",
                        style: {
                            borderRadius: "15px",
                            backgroundColor: "#59B948"
                        }
                    }}
                    className="modal-class-span"
                    width={"60%"}
                    onCancel={() => setMapSettingModalOpen(false)}
                    onOk={() => {
                        setMapSettingModalOpen(false);
                        applyButtonClicked();
                    }}
                >
                    <div>
                        <div className="text-div">
                            <label>
                                Boundary values temperature within
                            </label>
                            <input
                                className="input-textbox"
                                placeholder={"3"}
                                inputMode="numeric"
                                onChange={(e) => {
                                    const valueChange = e.target.value.replace(/[^\d]/g, "");

                                    if (Number.isNaN(valueChange)) {
                                        return dispatch({
                                            type: normalActionTypes.NORMAL_UPDATE_LOCAL_MAP_SETTING, payload: {
                                                boundary_day: ""
                                            }
                                        });
                                    }
                                    let value = valueChange;
                                    if (!valueChange) {
                                        value = "";
                                    }
                                    dispatch({
                                        type: normalActionTypes.NORMAL_UPDATE_LOCAL_MAP_SETTING, payload: {
                                            boundary_day: value
                                        }
                                    });
                                }}
                                value={mapSetting.boundary_day}
                            />
                            <label>
                                days
                            </label>
                        </div>
                        <div className="text-div">
                            <label>
                                It will be marked as
                                <div className="color-div" style={{ backgroundColor: "red" }} />
                                if at least
                                <input
                                    className="input-textbox"
                                    placeholder="3"
                                    value={mapSetting.red_count}
                                    inputMode="numeric"
                                    onChange={(e) => {
                                        const valueChange = e.target.value.replace(/[^\d]/g, "");

                                        if (Number.isNaN(valueChange)) {
                                            return dispatch({
                                                type: normalActionTypes.NORMAL_UPDATE_LOCAL_MAP_SETTING, payload: {
                                                    red_count: ""
                                                }
                                            });
                                        }
                                        let value = valueChange;
                                        if (!valueChange) {
                                            value = "";
                                        }
                                        dispatch({ type: normalActionTypes.NORMAL_UPDATE_LOCAL_MAP_SETTING, payload: { red_count: value } });
                                    }}
                                />
                                time(s) the {mapSetting.boundary_day}&nbsp;day&apos;s temperature difference is &gt;=
                            </label>
                            <input
                                className="input-textbox"
                                value={mapSetting.red_level}
                                inputMode="numeric"
                                style={{border: `${showRedValueWarning ? "1px solid red" : ""}`}}
                                onChange={(e) => {
                                    let valueChange = e.target.value.replace(/[^\d.]/g, "");
                                    valueChange = valueChange.replace(/\.{2,}/g, ".");
                                    valueChange = valueChange.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
                                    if (Number.isNaN(valueChange)) {
                                        return dispatch({
                                            type: normalActionTypes.NORMAL_UPDATE_LOCAL_MAP_SETTING, payload: {
                                                red_level: ""
                                            }
                                        });
                                    }
                                    let value = valueChange;
                                    if (!valueChange) {
                                        value = "0";
                                    }
                                    dispatch({
                                        type: normalActionTypes.NORMAL_UPDATE_LOCAL_MAP_SETTING, payload: {
                                            red_level: parseFloat(value)
                                        }
                                    });
                                }}
                            />
                            <label>
                                ℃
                            </label>
                        </div>
                        <div className="text-div">
                            <label>
                                It will be marked as
                            </label>
                            <div className="color-div" style={{ backgroundColor: "rgba(255,191,000)" }} />
                            <label>
                                if at least
                            </label>
                            <input
                                className="input-textbox"
                                placeholder="3"
                                value={mapSetting.amber_count}
                                inputMode="numeric"
                                onChange={(e) => {
                                    const valueChange = e.target.value.replace(/[^\d]/g, "");
                                    if (Number.isNaN(valueChange)) {
                                        return dispatch({
                                            type: normalActionTypes.NORMAL_UPDATE_LOCAL_MAP_SETTING, payload: {
                                                amber_count: ""
                                            }
                                        });
                                    }
                                    let value = valueChange;
                                    if (!valueChange) {
                                        value = "";
                                    }
                                    dispatch({ type: normalActionTypes.NORMAL_UPDATE_LOCAL_MAP_SETTING, payload: { amber_count: value } });
                                }}
                            />
                            <label>
                                time(s) the {mapSetting.boundary_day} day&apos;s temperature difference is &lt;
                            </label>
                            <input
                                className="input-textbox"
                                inputMode="numeric"
                                value={mapSetting.red_level}
                                style={{border: `${showRedValueWarning ? "1px solid red" : ""}`}}
                                onChange={(e) => {
                                    let valueChange = e.target.value.replace(/[^\d.]/g, "");
                                    valueChange = valueChange.replace(/\.{2,}/g, ".");
                                    valueChange = valueChange.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
                                    if (Number.isNaN(valueChange)) {
                                        return dispatch({
                                            type: normalActionTypes.NORMAL_UPDATE_LOCAL_MAP_SETTING, payload: {
                                                red_level: 0
                                            }
                                        });
                                    }
                                    let value = valueChange;
                                    if (!valueChange) {
                                        value = "0";
                                    }
                                    dispatch({
                                        type: normalActionTypes.NORMAL_UPDATE_LOCAL_MAP_SETTING, payload: {
                                            red_level: parseFloat(value)
                                        }
                                    });
                                }}
                            />
                            <label>℃ and &gt;= </label>
                            <input
                                className="input-textbox"
                                inputMode="numeric"
                                value={mapSetting.amber_level}
                                style={{border: `${showAmberValueWarning ? "1px solid red" : ""}`}}
                                onChange={(e) => {
                                    let valueChange = e.target.value.replace(/[^\d.]/g, "");
                                    valueChange = valueChange.replace(/\.{2,}/g, ".");
                                    valueChange = valueChange.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
                                    if (Number.isNaN(valueChange)) {
                                        return dispatch({
                                            type: normalActionTypes.NORMAL_UPDATE_LOCAL_MAP_SETTING, payload: {
                                                amber_level: 0
                                            }
                                        });
                                    }
                                    let value = valueChange;
                                    if (!valueChange) {
                                        value = "0";
                                    }
                                    dispatch({
                                        type: normalActionTypes.NORMAL_UPDATE_LOCAL_MAP_SETTING, payload: {
                                            amber_level: parseFloat(value)
                                        }
                                    });
                                }}
                            />
                            <label>
                                ℃
                            </label>
                        </div>
                        <div className="text-div">
                            <label>
                                Otherwise it will be marked as
                            </label>
                            <div className="color-div" style={{ backgroundColor: "green" }} />
                        </div>
                        <div className="foot-div">
                            <button className="cancel-button" onClick={() => {
                                dispatch({
                                    type: normalActionTypes.NORMAL_UPDATE_LOCAL_MAP_SETTING_ORIGIN, payload: {
                                        mapSetting,
                                        update: false
                                    }
                                });
                                setMapSettingModalOpen(false);
                            }}>
                                Cancel
                            </button>
                            <button style={{
                                backgroundColor: `${showAmberValueWarning || showRedValueWarning ? "lightgray" : "#59B948"}`
                            }} className="ok-button" onClick={() => {
                                if (showRedValueWarning || showAmberValueWarning) {
                                    return;
                                }
                                setMapSettingModalOpen(false);
                                dispatch({
                                    type: sagaActionTypes.SAGA_UPDATE_TEMP_AND_DAYS_SETTING, payload: {
                                        mapSetting,
                                        heatMapParam: {
                                            geonameId: checkedKeys[0],
                                            stressType: stressType.toLowerCase(),
                                            startTime: startDate.format("YYYY-MM-DD"),
                                            endTime: endDate.format("YYYY-MM-DD"),
                                        }
                                    }
                                });
                                dispatch({
                                    type: normalActionTypes.NORMAL_UPDATE_LOCAL_MAP_SETTING_ORIGIN, payload: {
                                        mapSetting,
                                        update: true
                                    }
                                });
                                // applyButtonClicked();

                            }}>
                                OK
                            </button>
                        </div>
                    </div>
                </Modal>
                <div className="up-div">
                    <div className="menu-div">
                        <div className="menu-item">
                            <label className="title-label">
                                Select start date
                            </label>
                            <DatePicker
                                disabledDate={disableStartTime}
                                onChange={(value) => {
                                    setStartDate(value);
                                    setEndDate(null);
                                }}
                                status={startDateStatus}
                                value={startDate}
                                format={"DD-MM-YYYY"}
                            />
                            <IF show={startDateStatus === "error"}>
                                <label className="error-label">
                                    Start date selected cannot be null!
                                </label>
                            </IF>
                        </div>
                        <div className="menu-item">
                            <label className="title-label">
                                Select end date
                            </label>
                            <DatePicker
                                onChange={(value) => {
                                    setEndDate(value);
                                }}
                                disabledDate={disableEndTime}
                                value={endDate}
                                status={endDateStatus}
                                format={"DD-MM-YYYY"}
                            />
                            <IF show={endDateStatus === "error"}>
                                <label className="error-label">
                                    End date selected cannot be null!
                                </label>
                            </IF>
                        </div>
                        <div className="menu-item">
                            <label className="title-label">
                                Stress Type
                            </label>
                            <Select
                                className="ag-aggregation-select"
                                value={stressType}
                                onChange={(e) => {
                                    setStressType(e);
                                }}
                            >
                                <Option key={stressTypes.cold} value={stressTypes.cold}>{stressTypes.cold}</Option>
                                <Option key={stressTypes.heat} value={stressTypes.heat}>{stressTypes.heat}</Option>
                            </Select>
                        </div>
                        <button className="apply-button" onClick={applyButtonClicked}>
                            <label>
                                Apply
                            </label>
                        </button>
                        <button className="clear-location-button" onClick={clearLocationButtonClick}>
                            <label>
                                Clear Locations
                            </label>
                        </button>
                        <div
                            className="right-menu-side">
                            <div
                                className="last-div"
                                style={{ marginLeft: "150px" }}
                                onClick={() => setMapSettingModalOpen(true)}
                            >
                                <SettingComponent />
                                <label>
                                    Settings
                                </label>
                            </div>
                            <div className="right-title"
                                onClick={() => {
                                    if (downloadExcelEnable) {
                                        getImageBase64Data();
                                        setShowEmailModal(true);
                                    }
                                }}
                                title="Email this page"
                            >
                                <NewEmailIcon enable={downloadExcelEnable} />
                            </div>
                            <div className="right-title"
                                onClick={() => {
                                    if (downloadExcelEnable) {
                                        downloadThisPage();
                                    }
                                }}
                                title="Download this page as image"
                            >
                                <NewPicIcon enable={downloadExcelEnable} />
                            </div>
                            <div className="right-title"
                                onClick={() => {
                                    if (downloadExcelEnable) {
                                        localDownloadHeatMapExcel();
                                    }
                                }}
                                title="Download raw data"
                            >
                                <DownloadXls enable={downloadExcelEnable} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="down-div">
                    <div className="location-div">
                        <Tree
                            checkable
                            treeData={heatMaplocations}
                            onCheck={onCheck}
                            // checkStrictly={true}
                            // multiple={false}
                            checkedKeys={checkedKeys}
                            showLine
                        />
                    </div>
                    <div className="map-div">
                        <HeatGoogleMap
                            selectedRegion={checkedKeys}
                        />
                    </div>
                </div>
            </Spin>
            <div className="bottom-setting" style={{ display: "none", paddingLeft: "20px", paddingBottom: "20px" }}>
                <div className="text-div">
                    <label>
                        Boundary values temperature within
                    </label>
                    &nbsp;
                    <label style={{ color: "black", margin: "0px 10px" }}>{mapSetting.boundary_day}</label>
                    &nbsp;
                    <label>days</label>
                </div>
                <div className="text-div">
                    <label>
                                It will be marked as
                        <div className="color-div" style={{ backgroundColor: "red" }} />
                                if at least&nbsp;
                        <label style={{ color: "black", margin: "0px 10px" }}>{mapSetting.red_count}</label>&nbsp;

                                time(s) the {mapSetting.boundary_day}&nbsp;day&apos;s temperature difference is &gt;=
                    </label>
                    <label style={{ color: "black", margin: "0px 10px" }}>{mapSetting.red_level}</label>
                    <label>
                                ℃
                    </label>
                </div>
                <div className="text-div">
                    <label>
                                It will be marked as
                    </label>
                    <div className="color-div" style={{ backgroundColor: "rgba(255,191,000)" }} />
                    <label>
                                if at least
                    </label>&nbsp;
                    <label style={{ color: "black", margin: "0px 10px" }}>{mapSetting.amber_count}</label>
                    &nbsp;
                    <label>
                                time(s) the {mapSetting.boundary_day} day&apos;s temperature difference is &lt;
                    </label>&nbsp;
                    <label style={{ color: "black" }}>{mapSetting.red_level}</label>
                    &nbsp;
                    <label>℃ and &gt;= </label>
                    <label style={{ color: "black", margin: "0px 10px" }}>{mapSetting.amber_level}</label>
                    <label>
                        ℃
                    </label>
                </div>
                <div className="text-div">
                    <label>
                                Otherwise it will be marked as
                    </label>
                    <div className="color-div" style={{ backgroundColor: "green", margin: "0px 10px" }} />
                </div>
            </div>
        </div>
    );
};

export default HeatMap;

