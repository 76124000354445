import React from "react";

interface IProps {
  show: boolean,
  children: (() => JSX.Element) | JSX.Element | JSX.Element[]
  key?: string
}

const IfComponent = (props: IProps): JSX.Element => {
    const {
        children
    } = props;

    if (props.show) {
        if (typeof children === "function") {
            return children();
        } else {
            return <>{children}</>;
        }
    } else {
        return <></>;
    }
};

export default IfComponent;