import { Button, Col, Input, Modal, Radio, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import "./index.less";
const {Option} = Select;
import {checkIsEnableReportsByCrops} from "@/util/http/Quantis/index";
import { IFieldsModel } from "@/util/http/Quantis/responseType";
import {addField} from "@/util/http/Quantis/index";
import { useDispatch, useSelector } from "react-redux";
import { normalActionTypes, sagaActionTypes } from "@/redux/actions/quantisAction";
import { RootState } from "@/redux/reducer/store";

interface IProp{
    showMapAddFieldModal: boolean
    forecastDay: number
    changeMapAddFieldModalCallback: (flag:boolean)=>void
    addFieldCallBack: (crop:string, fieldName:string, bookmarkFieldName?:string) =>void
}

const MapAddFieldModal = (props:IProp)=>{
    const {
        showMapAddFieldModal,
        forecastDay,
        changeMapAddFieldModalCallback,
        addFieldCallBack
    } = props;

    const dispatch = useDispatch();

    const {
        allCrop,
        allFields,
        currentMapCenter,
        addNewFieldStatus
    } = useSelector((state: RootState) => state.quantisReducer);



    const [modalCrop,setModalCrop] = useState(null);
    const [modalFieldName,setModalFieldName] = useState(null);
    const [modalEnableReport,setModalEnableReport] = useState(null);
    const [modalReportDisabled,setModalReportDisabled] = useState(false);
    const [modalIsBookMark,setModalIsBookMark] = useState("N");

    const publicChangeModalState = (type:string,value:any) => {
        console.log(`current type is ${type}, value is ${value}`);
        switch (type) {
            case "Crop":
                setModalCrop(value);
                handleChangeCropCallback(value);
                break;
            case "FieldName":
                setModalFieldName(value);
                break;
            case "EnableReport":
                setModalEnableReport(value);
                break;
            case "IsBookMark":
                setModalIsBookMark(value);
                break;
            default:
                console.log("current value is not exist.");
        }
    };

    const handleChangeCropCallback = async(crop:string) =>{
        try{
            const res = await checkIsEnableReportsByCrops(crop);
            if(res.data === false){
                setModalEnableReport("N");
                setModalReportDisabled(true);
            }
            else{
                setModalEnableReport("Y");
                setModalReportDisabled(false);
            }
        }
        catch(error){
            console.log(`handleChangeCropCallback have error: error is ${error}`);
        }
    };

    const handleCancel = ()=>{
        changeMapAddFieldModalCallback(false);
    };
    const handleOk = ()=>{
        changeMapAddFieldModalCallback(false);
    };
    const saveButtonClick = ()=>{
        // close the Modal
        changeMapAddFieldModalCallback(false);
        if(!modalFieldName){
            dispatch({
                type: normalActionTypes.NORMAL_UPDATE_SHOWERRORMESSAGE,
                payload: "Please input the field name."
            });
            return;
        }
        // check current fieldName whethre can add
        const tmpField = allFields.filter((item)=> item.fieldName === modalFieldName)[0];
        if(tmpField?.fieldName){
            dispatch({
                type: normalActionTypes.NORMAL_UPDATE_SHOWERRORMESSAGE,
                payload: "Duplicate current field name."
            });
            return;
        }
        else{
            console.log("check value modalCrop",modalCrop);
            console.log("check value modalFieldName",modalFieldName);
            console.log("check value modalCrop",modalEnableReport);
            console.log("check value modalCrop",modalIsBookMark);
            console.log("check value forecastDay",forecastDay);
            addNewField();
        }
    };
    const addNewField = async()=>{
        try{
            console.log(`add new Field: currentMapCenter: lat is ${currentMapCenter?.latitude}, lng is ${currentMapCenter?.longitude}, zoom is ${currentMapCenter?.zoom}`);
            dispatch({
                type: sagaActionTypes.SAGA_ADD_NEWFIELDS,
                payload: {
                    crop: modalCrop,
                    fieldName: modalFieldName,
                    latitude: currentMapCenter?.latitude?.toString(),
                    longitude: currentMapCenter?.longitude?.toString(),
                    mapZoom: currentMapCenter?.zoom,
                    isBookMark: modalIsBookMark,
                    isAddToReport: modalEnableReport,
                    forecastdays: forecastDay
                }
            });
        }
        catch(error){
            console.log(`addNewField have error, error message is ${error}`);
        }
    };

    useEffect(()=>{
        if(addNewFieldStatus === "Success"){
            let tmpBookMarkValue = null;
            if(modalIsBookMark === "N"){
                // change bookmark value to empty
            }
            else{
                // change bookmark value to modalFieldName
                tmpBookMarkValue = modalFieldName;
            }
            // change crop and field value, and invoke getFields function
            addFieldCallBack(modalCrop,modalFieldName,tmpBookMarkValue);
            dispatch({
                type: normalActionTypes.NORMAL_UPDATE_ADDNEWFIELDSTATUS,
                payload: null
            });
        }
        else if(addNewFieldStatus === "Faild"){
            dispatch({
                type: normalActionTypes.NORMAL_UPDATE_SHOWERRORMESSAGE,
                payload: "Current field name add failed."
            });
            dispatch({
                type: normalActionTypes.NORMAL_UPDATE_ADDNEWFIELDSTATUS,
                payload: null
            });
        }
    },[addNewFieldStatus]);

    useEffect(()=>{
        if(showMapAddFieldModal && allCrop?.length >= 1){
            const tmpCrop = allCrop[0];
            if(tmpCrop){
                // init crop value
                setModalCrop(tmpCrop);
                // init enable report
                handleChangeCropCallback(tmpCrop);
                // init other properties
                setModalFieldName(null);
                setModalIsBookMark("N");
            }
        }
    },[allCrop,showMapAddFieldModal]);

    return(
        <div className="map-add-field-modal-container" id="map-add-field-modal-container">
            <Modal
                title="Pin the targeted field"
                getContainer = {()=> document.getElementById("map-add-field-modal-container") as HTMLElement}
                open = {showMapAddFieldModal}
                onCancel={handleCancel}
                footer={[
                    <Button
                        key="submit"
                        type="primary"
                        //   loading={confirmLoading}
                        onClick={handleOk}
                    >
                      Close
                    </Button>,
                    <Button
                        key="submit"
                        type="primary"
                        //   loading={confirmLoading}
                        onClick={saveButtonClick}
                    >
                  Save
                    </Button>,
                ]}
            >
                <div style={{ fontSize: 12 }}>
                    <Row style={{ height: 40 }}>
                        <Col span={6}>Crop:</Col>
                        <Col span={18}>
                            <Select
                                value={modalCrop}
                                placeholder="Crop"
                                dropdownMatchSelectWidth={false}
                                onChange={(item) => publicChangeModalState("Crop",item)}
                            >
                                {allCrop
                                    ? allCrop.map((item: any, index: number) => (
                                        <Option key={index + item} value={item}>
                                            {item}
                                        </Option>
                                    ))
                                    : []}
                            </Select>
                        </Col>
                    </Row>
                    <Row style={{ height: 40 }}>
                        <Col span={6}>Field Name:</Col>
                        <Col span={18}>
                            <Input
                                placeholder="Field name"
                                onChange={(e)=>{publicChangeModalState("FieldName",e.target.value);}}
                                value={modalFieldName}
                            />
                        </Col>
                    </Row>
                    <Row style={{ height: 40 }}>
                        <Col span={6}>Enable Report</Col>
                        <Col span={18}>
                            <Radio.Group
                                value={modalEnableReport}
                                onChange={(e)=>{publicChangeModalState("EnableReport",e.target.value);}}
                            >
                                <Radio key={"enable-report-Y"} value="Y" disabled={modalReportDisabled}>
                  Yes
                                </Radio>
                                <Radio key={"enable-report-N"} value="N" disabled={modalReportDisabled}>
                  No
                                </Radio>
                            </Radio.Group>
                        </Col>
                    </Row>
                    <Row
                        style={{
                            height: 40,
                            display: modalReportDisabled && modalEnableReport === "N" ? "block" : "none",
                            marginTop: "-18px",
                            marginBottom: "-22px",
                            marginLeft: "31px",
                        }}
                    >
                        <Col span={16} offset={8}>
                            <p style={{ color: "red", fontSize: "12px" }}>
                Reports have been disabled for this crop
                            </p>
                        </Col>
                    </Row>
                    <Row style={{ height: 40 }}>
                        <Col span={6}>Add to Bookmark</Col>
                        <Col span={18}>
                            <Radio.Group
                                value={modalIsBookMark}
                                onChange={(e)=>{publicChangeModalState("IsBookMark",e.target.value);}}
                            >
                                <Radio key={"isBookmark-Y"} value="Y">Yes</Radio>
                                <Radio key={"isBookmark-N"} value="N">No</Radio>
                            </Radio.Group>
                        </Col>
                    </Row>
                </div>
            </Modal>
        </div>
    );
};

export default MapAddFieldModal;