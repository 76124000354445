import { IExecuteQueryResModel , ISimulationLocationModel} from "@/util/http/simulation/responseType";
import { normalActionTypes } from "../actions/simulationAction";
// init simulation data
import {InitDiseaseDataFromXML,InitTranslateText,InitDiseaseRiskInfo} from "@/resource/js/diseaseInitData";
import {simulationInitData,simulationLocations} from "@/resource/js/simulationInitData";


interface ISimulationModel {
    simulationData?: IExecuteQueryResModel;
    simulationLocations?: ISimulationLocationModel[];
    simulationLocationInfo?: string;
    simulationIsMapClick?: boolean;
    simulationClickCount?: number;
}



const initialCommon: ISimulationModel = {
    simulationData: simulationInitData,
    simulationLocations: simulationLocations,
    simulationLocationInfo: null,
    simulationIsMapClick: null,
    simulationClickCount: 0
};

const commonReducer = (state: ISimulationModel = initialCommon, action) => {
    console.log("actionType", action.type);
    console.log("action.payload", action.payload);

    switch (action.type) {
        case normalActionTypes.NORMAL_SIMULATION_IS_MAPCLICK:
            console.log("commonReducer:",action.payload);
            return {
                ...state,
                simulationIsMapClick: action.payload
            };
        case normalActionTypes.NORMAL_SIMULATION_LOCATION_INFO:
            console.log("commonReducer:",action.payload);
            return {
                ...state,
                simulationLocationInfo: action.payload
            };
        case normalActionTypes.NORMAL_SIMULATION_MAPCOUNT:
            console.log("commonReducer:",action.payload);
            return {
                ...state,
                simulationClickCount: action.payload
            };
        default:
            return state;
    }
};

export type {
    ISimulationModel
};

export default commonReducer;