import React, { useEffect, useRef, useState } from "react";
import "./index.less";
import MapImage from "@/resource/images/png/map.png";
import ArrowLeft from "@/resource/images/svg/diseaseMap/Vectorleft.svg";
import ArrowRight from "@/resource/images/svg/diseaseMap/Vectorright.svg";
import { useTrackedEffect ,useWhyDidYouUpdate } from "ahooks";

import GoogleMap from "google-maps-react-markers";
import DiseaseMarker from "./components/DiseaseMarker";

// AU Region
import { AURegionObj } from "./components/initData/AURegion";

import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);


// init data
import { IDiseaseRiskGetLocationResModel, IDiseaseRiskInfoResModel } from "@/util/http/responseType";
import { getDiseaseRisksLocationAndValue } from "@/util/http";

import { useSelector } from "react-redux";
import { RootState } from "@/redux/reducer/store";
import useSize from "./components/selfHooks/useSize";

interface ITimeType{
    index:number,
    week: string;
    day: string;
}

interface ICenterObj{
    center?: {
        lat?: number;
        lng?: number;
    },
    zoom?: number;
    minimumZoom?: number;
    maximumZoom?: number;
}

interface IProps{
    forcastDay:number;
    countrycode: string;
    weekOjb: string[];
    region?: string;
    startDate?:string;
    selectedDisease?:string;
    selectedCountry?:string;
    selectedCrop?:string;
    dateFormat?: string;
    currentSelectedDate?: string;
    childChangeCurrentSelectedDate?: (value:string)=>void;
    backgroundColor1?:any;
    backgroundColor2?:any;
}

const jobForcastData = 6;

const MapRender = (props:IProps)=>{
    const {
        forcastDay,
        countrycode,
        region,
        startDate,
        selectedCountry,
        selectedDisease,
        dateFormat,
        weekOjb,
        childChangeCurrentSelectedDate,
        backgroundColor1,
        backgroundColor2
    } = props;
    const [markers,setMarkers] = useState<IDiseaseRiskInfoResModel[]>( []);
    const [finalForcastDay,setFinalForcastDay] = useState(7);
    const [defaultProps,setDefaultProps] = useState<ICenterObj>({
        center: {
            lat: 55,
            lng: -4
        },
        zoom: 5,
        minimumZoom: 5,
        maximumZoom: 9
    });

    const {
        diseaseData,
        translateText,
        diseaseRiskInfo
    } = useSelector((state: RootState) => state.diseaseReducer);


    // const weekOjb = ["Sun","Mon","Tus","Wed","Thu","Fri","Sat"];
    const [forcastDayArray,setForcastDayArray] = useState<ITimeType[]>([]);

    const testRef = useRef(null);
    const [clientWidth, setClientWidth] = useState(null);

    useEffect(() => {
        // 当testRef.current存在时，设置clientWidth状态
        if (testRef.current) {
            setClientWidth(testRef.current.clientWidth);
        }

        // 监听resize事件来更新clientWidth状态
        const handleResize = () => {
            if (testRef.current) {
                setClientWidth(testRef.current.clientWidth);
            }
        };

        window.addEventListener("resize", handleResize);

        // 清除effect
        return () => window.removeEventListener("resize", handleResize);
    }, []); // 空依赖数组意味着这个effect只在组件挂载和卸载时运行

    /**
     * currentIndex mean current selected index
     * righIndex mean the rightmost index
     * rightIndex 表示可视范围内最大的index，而不是数组最右边的
     * viewItemCount 表示页面最多同时呈现多少个 Item
     */
    const [currentIndex,setCurrentIndex] = useState(0);
    const [rightIndex,setRightIndex] = useState(0);
    const [viewItemCount,setViewItemCount] = useState(1);

    useEffect(()=>{
        if(forcastDay <=7){
            setRightIndex(forcastDay -1);
        }
        else{
            setRightIndex(6);
        }
    },[]);
    const initForcastDay = ()=>{
        // const tmpArray = [...forcastDayArray];
        const tmpArray:ITimeType[] = [];
        console.log("dd tmpArray",tmpArray);
        console.log("dd forcastDay",forcastDay);
        // acculate the end date
        const tmpCurrentDate = dayjs();
        const tmpJobEndDate = dayjs().add(jobForcastData,"day");
        const tmpStartDate = startDate ? dayjs(startDate,"DD/MM/YYYY") : dayjs();
        const tmpDiff = tmpJobEndDate.diff(tmpStartDate,"day") + 1;
        const tmpFinalDay = tmpDiff < forcastDay ? tmpDiff : forcastDay;

        for(let i = 0 ; i < tmpFinalDay; i++){
            tmpArray.push({
                index: i,
                week: weekOjb[tmpStartDate.add(i, "day").day()],
                // day: dayjs().add(i, "day").format("DD-MM-YYYY")
                day: tmpStartDate.add(i, "day").format(dateFormat || "DD/MM/YYYY")
            });
        }
        setForcastDayArray(tmpArray);
        setFinalForcastDay(tmpFinalDay);
    };
    useEffect(()=>{
        setCurrentIndex(0);
    },[startDate]);
    useEffect(()=>{
        initForcastDay();
    },[forcastDay,startDate,dateFormat,weekOjb]);

    console.log("filter format",dateFormat);
    console.log("filter forcastDayArray",forcastDayArray);

    const [testMarkers,setTestMarkers] = useState<{[key:number]: any}>({});
    const [testMarkersOrder,setTestMarkerOrder] = useState<number[]>([]);



    useWhyDidYouUpdate("Index of change render",{props,diseaseRiskInfo,currentIndex,forcastDayArray});

    useTrackedEffect((changes)=>{
        console.log("Index of change dependencies",changes);
    },[diseaseRiskInfo,currentIndex,startDate,forcastDayArray]);


    // set map setting
    useEffect(()=>{
        const tmpSetting = {...defaultProps};
        if(countrycode === "AU" && region){
            const tmpRURegionObj = {...AURegionObj[region]};
            tmpSetting.center.lat = tmpRURegionObj?.initialLatitude;
            tmpSetting.center.lng = tmpRURegionObj?.initialLongitude;
            tmpSetting.zoom = tmpRURegionObj?.initialZoom;
            tmpSetting.minimumZoom = tmpRURegionObj?.minimumZoom;
            tmpSetting.maximumZoom = tmpRURegionObj?.maximumZoom;
            mapRef.current?.setCenter(tmpSetting.center);
            mapRef.current?.setZoom(tmpSetting.zoom);
            console.log("current countrycdoe latest mapRef",mapRef.current);
            console.log("current countrycdoe latest mapRef zoom",tmpSetting.zoom);

            // setDefaultProps(tmpSetting);
        }
        else if(diseaseData?.mapSetting?.length > 0){
            // const tmpSetting = {...defaultProps};
            tmpSetting.center.lat = diseaseData.mapSetting[0].initialLatitude;
            tmpSetting.center.lng = diseaseData.mapSetting[0].initialLogitude;
            tmpSetting.zoom = diseaseData.mapSetting[0].initialZoom;
            tmpSetting.minimumZoom = diseaseData.mapSetting[0].minimumZoom;
            tmpSetting.maximumZoom = diseaseData.mapSetting[0].maximumZoom;
            mapRef.current?.setCenter(tmpSetting.center);
            mapRef.current?.setZoom(tmpSetting.zoom);
            console.log("current countrycdoe latest mapRef",mapRef.current);
            console.log("current countrycdoe latest mapRef zoom",tmpSetting.zoom);

            // setDefaultProps(tmpSetting);
        }
        setDefaultProps(tmpSetting);
        console.log("lanxue map tmpSetting",tmpSetting);
        console.log("lanxue map mapRef?.current",mapRef?.current);
    },[diseaseData,countrycode,region]);



    console.log("current countrycdoe latest diseaseRiskInfo",diseaseRiskInfo);
    console.log("current countrycdoe latest markers",markers);
    console.log("current countrycdoe latest center",defaultProps);
    console.log("current countrycdoe latest diseaseData",diseaseData);
    console.log("a new diseaseData",diseaseData);
    console.log("a new translateText",translateText);
    console.log("a new diseaseRiskInfo",diseaseRiskInfo);
    console.log("a new diseaseRiskInfo length",diseaseRiskInfo.length);
    console.log("a new diseaseRiskInfo date",startDate);

    const [itemWidth,setItemWidth] = useState(10);
    // 监听 client，动态调整 item 的 宽度
    useEffect(()=>{
        if(clientWidth > 0 && finalForcastDay >= 1){
            let tmpItemWidth = null;
            let tmpRightIndex = 0;
            let tmpViewItemCount = 1;
            const minWidth = 130;
            const tmpWidth = Math.ceil(clientWidth / finalForcastDay);
            // 不对，应该从 finalForcastDay 的数量开始判断
            if(finalForcastDay === 1){
                tmpItemWidth = clientWidth;
                tmpRightIndex = 0;
                tmpViewItemCount = 1;
            }
            if(finalForcastDay === 2){
                if(clientWidth >= 130 * 2){
                    tmpItemWidth = clientWidth / 2;
                    tmpRightIndex = finalForcastDay -1;
                    tmpViewItemCount = 2;
                }
                else{
                    tmpItemWidth = clientWidth;
                    tmpRightIndex = 0;
                    tmpViewItemCount = 1;
                }
            }
            if(finalForcastDay === 3){
                if(clientWidth >= 130 * 3){
                    tmpItemWidth = clientWidth / 3;
                    tmpRightIndex = finalForcastDay -1;
                    tmpViewItemCount = 3;
                }
                else if(clientWidth >= 130 * 2){
                    tmpItemWidth = clientWidth / 2;
                    tmpRightIndex = finalForcastDay -2;
                    tmpViewItemCount = 2;
                }
                else{
                    tmpItemWidth = clientWidth;
                    tmpRightIndex = 0;
                    tmpViewItemCount = 1;
                }
            }
            if(finalForcastDay === 4){
                if(clientWidth >= 130 * 4){
                    tmpItemWidth = clientWidth / 4;
                    tmpRightIndex = finalForcastDay -1;
                    tmpViewItemCount = 4;
                }
                else if(clientWidth >= 130 * 3) {
                    tmpItemWidth = clientWidth / 3;
                    tmpRightIndex = finalForcastDay -2;
                    tmpViewItemCount = 3;
                }
                else if(clientWidth >= 130 * 2) {
                    tmpItemWidth = clientWidth / 2;
                    tmpRightIndex = finalForcastDay - 3;
                    tmpViewItemCount = 2;
                }
                else{
                    tmpItemWidth = clientWidth;
                    tmpRightIndex = 0;
                    tmpViewItemCount = 1;
                }
            }
            if(finalForcastDay === 5){
                if(clientWidth >= 130 * 5) {
                    tmpItemWidth = clientWidth / 5;
                    tmpRightIndex = finalForcastDay - 1;
                    tmpViewItemCount = 5;
                }
                else if(clientWidth >= 130 * 4) {
                    tmpItemWidth = clientWidth / 4;
                    tmpRightIndex = finalForcastDay - 2;
                    tmpViewItemCount = 4;
                }
                else if(clientWidth >= 130 * 3) {
                    tmpItemWidth = clientWidth / 3;
                    tmpRightIndex = finalForcastDay - 3;
                    tmpViewItemCount = 3;
                }
                else if(clientWidth >= 130 * 2) {
                    tmpItemWidth = clientWidth / 2;
                    tmpRightIndex = finalForcastDay - 4;
                    tmpViewItemCount = 2;
                }
                else{
                    tmpItemWidth = clientWidth;
                    tmpRightIndex = 0;
                    tmpViewItemCount = 1;
                }
            }
            if(finalForcastDay === 6){
                if(clientWidth >= 130 * 6) {
                    tmpItemWidth = clientWidth / 6;
                    tmpRightIndex = finalForcastDay -1;
                    tmpViewItemCount = 6;
                }
                else if(clientWidth >= 130 * 5) {
                    tmpItemWidth = clientWidth / 5;
                    tmpRightIndex = finalForcastDay - 2;
                    tmpViewItemCount = 5;
                }
                else if(clientWidth >= 130 * 4) {
                    tmpItemWidth = clientWidth / 4;
                    tmpRightIndex = finalForcastDay - 3;
                    tmpViewItemCount = 4;
                }
                else if(clientWidth >= 130 * 3) {
                    tmpItemWidth = clientWidth / 3;
                    tmpRightIndex = finalForcastDay - 4;
                    tmpViewItemCount = 3;
                }
                else if(clientWidth >= 130 * 2) {
                    tmpItemWidth = clientWidth / 2;
                    tmpRightIndex = finalForcastDay - 5;
                    tmpViewItemCount = 2;
                }
                else{
                    tmpItemWidth = clientWidth;
                    tmpRightIndex = 0;
                    tmpViewItemCount = 1;
                }
            }
            if(finalForcastDay === 7){
                if(clientWidth >= 130 * 7) {
                    tmpItemWidth = clientWidth / 7;
                    tmpRightIndex = finalForcastDay - 1;
                    tmpViewItemCount = 7;
                }
                else if(clientWidth >= 130 * 6) {
                    tmpItemWidth = clientWidth / 6;
                    tmpRightIndex = finalForcastDay - 2;
                    tmpViewItemCount = 6;
                }
                else if(clientWidth >= 130 * 5) {
                    tmpItemWidth = clientWidth / 5;
                    tmpRightIndex = finalForcastDay - 3;
                    tmpViewItemCount = 5;
                }
                else if(clientWidth >= 130 * 4) {
                    tmpItemWidth = clientWidth / 4;
                    tmpRightIndex = finalForcastDay - 4;
                    tmpViewItemCount = 4;
                }
                else if(clientWidth >= 130 * 3) {
                    tmpItemWidth = clientWidth / 3;
                    tmpRightIndex = finalForcastDay - 5;
                    tmpViewItemCount = 3;
                }
                else if(clientWidth >= 130 * 2) {
                    tmpItemWidth = clientWidth / 2;
                    tmpRightIndex = finalForcastDay - 6;
                    tmpViewItemCount = 2;
                }
                else{
                    tmpItemWidth = clientWidth;
                    tmpRightIndex = 0;
                    tmpViewItemCount = 1;
                }
            }
            setItemWidth(tmpItemWidth);
            setRightIndex(tmpRightIndex);
            setViewItemCount(tmpViewItemCount);
        }
    },[clientWidth,finalForcastDay]);


    // const width = (forcastDay >= 7? 1226/7 : 1226/forcastDay);
    const width = (finalForcastDay >= 7? 1226/7 : 1226/finalForcastDay);
    const divRef = useRef<any>(null);
    const leftArrow = ()=>{
        // 该方法能执行的话，表示允许向右移动
        let tmpCurrentIndex = currentIndex;
        let tmpRightIndex = rightIndex;
        if(currentIndex <= 0){
            tmpCurrentIndex = 0;
        }
        else{
            tmpCurrentIndex = tmpCurrentIndex -1;
        }
        // if forcast day less 7, mean you dont't move the date item
        // if(finalForcastDay<= 7)
        // return;
        /**
         * whether to move date item depends on the current wether more lean 7
         */
        if(tmpRightIndex > (viewItemCount -1)){
            const leftMoveWidth = itemWidth * (rightIndex - (viewItemCount ));
            divRef.current.style.transform = `translateX(-${leftMoveWidth}px)`;
            tmpRightIndex = tmpRightIndex -1;
        }
        setCurrentIndex(tmpCurrentIndex);
        setRightIndex(tmpRightIndex);
    };
    const rithtArrow = ()=>{
        // 该方法能执行的话，表示允许向右移动
        let tmpCurrentIndex = currentIndex;
        let tmpRightIndex = rightIndex;
        if(currentIndex >= forcastDay -1){
            tmpCurrentIndex = forcastDay -1;
        }
        else tmpCurrentIndex = tmpCurrentIndex + 1;
        // 如果移动后的 tmpCurrentIndex 大于当前页面最右边的 index，就表明更改 translate， 否则只需要更改 currentIndex 的值了
        if(tmpCurrentIndex > rightIndex){
            // 需要右移
            tmpRightIndex = tmpRightIndex + 1; // 最右边的 rightIndex 加一
            // 设置左移
            const leftMoveWidth = itemWidth * (tmpCurrentIndex - (viewItemCount - 1));
            divRef.current.style.transform = `translateX(-${leftMoveWidth}px)`;
            divRef.current.style.scrolling = "auto";
        }
        setCurrentIndex(tmpCurrentIndex);
        setRightIndex(tmpRightIndex);
    };
    const mapRef = useRef(null);
    const mapsRef = useRef(null);

    const [mapOptions,setMapOptions] = useState({
        zoomControl: false,
        mapTypeControl: false,
        streetViewControl: false,
        maxZoom: 10,
    });

    useEffect(()=>{
        if(defaultProps?.maximumZoom){
            const tmpMapOptions = {...mapOptions};
            tmpMapOptions.maxZoom = defaultProps.maximumZoom;
            setMapOptions(tmpMapOptions);
        }
    },[defaultProps]);

    const onGoogleApiLoaded = ({map,maps})=>{
        console.log("Test",map);
        console.log("Test",maps);
        mapRef.current = map;
        mapsRef.current = maps;
    };

    const wantCallback = (params:any)=>{
        console.log("Params ",params);
    };


    // ===== google map useEffect ===============


    const [changeZoom,setChangeZoom] = useState(defaultProps.zoom);
    const {markerWidth,markerHeight} = useSize({
        countryCode: selectedCountry,
        zoom: changeZoom
    });

    console.log("test change height",markerHeight);

    const changeFirstToUp = (str: string): string => {
        const reg = /\w/;
        return str?.replace(reg, function (m) {
            return m.toLowerCase();
        });
    };

    const publicGetRiskLevel = (selectedDisease: string,tmpValue?: number,index?:number) =>{
        const value = Number(tmpValue);
        let tmpRiskLevel = "";
        // if(selectedDisease.toLowerCase() === "DollarSpotSmithModelRisk" || selectedDisease.toLowerCase() === "CornGrayLeafSpot"){
        if(selectedDisease.toLowerCase() === "dollarspotsmithmodelrisk"){
            if(value <= 15) tmpRiskLevel = "NO";
            else if(value > 15 && value <= 25) tmpRiskLevel = "LOW";
            else if(value > 25 && value <= 35) tmpRiskLevel = "MEDIUM";
            else if(value > 35 && value <= 50) tmpRiskLevel = "HIGH";
            else if(value > 50) tmpRiskLevel = "VERYHITH";
        }
        else if(selectedDisease.toLowerCase() === "corngrayleafspot"){
            if(value <= 2) tmpRiskLevel = "NO";
            else if(value > 2 && value <= 4) tmpRiskLevel = "LOW";
            else if(value > 4 && value <= 6) tmpRiskLevel = "MEDIUM";
            else if(value > 6 && value <= 8) tmpRiskLevel = "HIGH";
            else if(value > 10) tmpRiskLevel = "VERYHITH";
        }
        else if(selectedDisease.toLowerCase()==="soiltemperaturerisk"){
            if(value < 4 || !value) tmpRiskLevel = "NO";
            else if(value >= 4 && value < 8) tmpRiskLevel = "LOW";
            else if(value >= 8 && value < 11) tmpRiskLevel = "MEDIUM";
            else if(value >= 11 && value < 14) tmpRiskLevel = "HIGH";
            else if(value >= 14 && value < 17) tmpRiskLevel = "VERYHITH";
            else if(value >= 17) tmpRiskLevel = "SOHITH";
        }
        else{
            if(value === 0 || !value) tmpRiskLevel = "NO";
            else if(value > 0 && value <= 1) tmpRiskLevel = "LOW";
            else if(value > 1 && value <= 2) tmpRiskLevel = "MEDIUM";
            else if(value > 2 && value <= 3) tmpRiskLevel = "HIGH";
            else if(value > 3) tmpRiskLevel = "VERYHITH";
        }
        console.log("fffff a index",index);
        console.log("fffff a selectedDisease",selectedDisease);
        console.log("fffff a value",value);
        console.log("fffff a tmpRiskLevel",tmpRiskLevel);
        return tmpRiskLevel;
    };

    // set dirkinfo
    useEffect(()=>{
        if(forcastDayArray?.length > 0){
            const tmpCurrentDate = dayjs(forcastDayArray[currentIndex].day,dateFormat || "DD/MM/YYYY").format("YYYY-MM-DD");
            childChangeCurrentSelectedDate(tmpCurrentDate);
        }
    },[currentIndex]);
    useEffect(()=>{
        if(diseaseRiskInfo?.length > 0){
            let currentDate = startDate;
            if(forcastDayArray.length != 0){
                console.log("a b new diseaseRiskInfo filter day",forcastDayArray[currentIndex].day);
                currentDate = dayjs(forcastDayArray[currentIndex].day,dateFormat || "DD/MM/YYYY").format("DD/MM/YYYY");
                console.log("a b new diseaseRiskInfo filter currentDate",currentDate);
            }
            const tmpRiskInfo = diseaseRiskInfo.filter((item,index)=>{
                if(dayjs(item?.datetimeUtc).format("DD/MM/YYYY") === currentDate) return item;
            });
            setMarkers(tmpRiskInfo);
            console.log("filter markers",tmpRiskInfo);

            const tmpMarkers: {[key:number]:any} = {};
            const tmpMarkersOrder:number[] = [];
            tmpRiskInfo.forEach((item,index)=>{
                const tmpRiskLevel = publicGetRiskLevel(changeFirstToUp(selectedDisease),item[changeFirstToUp(selectedDisease)],index);
                const marker = (
                    <DiseaseMarker
                        key = {(item?.longitude + item?.latitude) + index }
                        lng = {item?.longitude}
                        lat = {item?.latitude}
                        width={markerWidth}
                        height={markerHeight}
                        riskLevel={tmpRiskLevel}
                        disease={selectedDisease}
                    />
                );
                tmpMarkers[index] = marker;
                tmpMarkersOrder.push(index);
            });
            setTestMarkers(tmpMarkers);
            setTestMarkerOrder(tmpMarkersOrder);
        }
    },[diseaseRiskInfo,startDate,forcastDayArray,markerHeight,selectedDisease]);
    const [bg,setBg] = useState("");

    return(
        <div className='v3-disease-map-container' id="v3-disease-map-container"
            //style={{background:`url(${MapImage})`}}
            ref={testRef}
        >

            <div style={{width:"1226",height:"400px"}} className="day-container">
                <div className=''>
                    <div className={`${currentIndex === 0 ? "left-arrow disabled" : "left-arrow"}`}
                        onClick={()=>{leftArrow();}}
                    >
                        <img src={ArrowRight}/>
                    </div>
                    <div className={`${currentIndex === forcastDayArray?.length -1 ? "right-arrow disabled" : "right-arrow"}`}
                        onClick={()=>{rithtArrow();}}
                    >
                        <img src={ArrowLeft}/>
                    </div>
                    <div className='day-item-container'
                        ref = {divRef}
                        id = 'day-item-container'
                    >

                        {
                            forcastDayArray.map((item,index)=>{
                                return(
                                    <>
                                        <div
                                            key={item.day + index}
                                            className={index == currentIndex ? "day-item active" : "day-item"}
                                            style={{width: itemWidth.toString()+"px",backgroundColor:`${index == currentIndex ? backgroundColor1 : backgroundColor2}`}}
                                            onClick={(e)=>{
                                                console.log("e",e);
                                                setCurrentIndex(index);
                                            }}
                                        >
                                            {`${item.week}  ${item.day}`}
                                        </div>
                                    </>
                                );
                            })
                        }
                    </div>
                </div>
                <GoogleMap
                    apiKey={"AIzaSyDlsxkoR8-fiIgncnDunmEtWEwDmr896H0"}
                    defaultCenter={defaultProps.center}
                    defaultZoom={defaultProps.zoom}
                    center = {defaultProps?.center}
                    zoom = {defaultProps?.zoom}
                    options={mapOptions}
                    onGoogleApiLoaded = {onGoogleApiLoaded}
                    onChange={(map:any) => {
                        console.log("test change size Map moved", mapRef.current?.zoom);
                        setChangeZoom(map?.zoom);
                        // if(mapRef.current?.zoom > defaultProps.maximumZoom){
                        //     mapRef.current.setZoom(defaultProps.maximumZoom);
                        // }
                        // else if(mapRef.current?.zoom < defaultProps.minimumZoom){
                        //     mapRef.current.setZoom(defaultProps.minimumZoom);
                        // }else{
                        //     setChangeZoom(mapRef.current?.zoom);
                        // }
                    }}
                    yesIWantToUseGoogleMapApiInternals = {wantCallback}
                >
                    {/* {markers.map((item,index)=>{
                        console.log("test markers item",item);
                        console.log("test markers selectedDisease",changeFirstToUp(selectedDisease));
                        console.log("test markers value",item[changeFirstToUp(selectedDisease)]);

                        const tmpRiskLevel = publicGetRiskLevel(changeFirstToUp(selectedDisease),item[changeFirstToUp(selectedDisease)],index);

                        return(
                            <DiseaseMarker
                                key = {(item?.longitude + item?.latitude) + index }
                                lng = {item?.longitude}
                                lat = {item?.latitude}
                                width={markerWidth}
                                height={markerHeight}
                                riskLevel={tmpRiskLevel}
                            />
                        );
                    })} */}
                    {
                        testMarkersOrder.map((index)=> testMarkers[index])
                    }
                </GoogleMap>
            </div>
        </div>
    );
};

export default React.memo(MapRender);
