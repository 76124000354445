import {
    fork,
    put,
    take,
    takeLatest,
    delay
} from "redux-saga/effects";
import {
    sagaActionTypes,
    updateDiseaseData,
    updateDiseaseRiskInfo,
    updateShowLodding,
    updateTranslateText,
    updateDiseaseMapPredictionRiskInfo
} from "../actions/diseaseAction";
import {
    getTranslatedText,
    diseaseData,
} from "@/util/http";
import { getDiseaseRiskInfoByName, getDiseaseRisksLocationAndValueByName } from "@/util/http/HistWeather";



function *fetchDiseaseData(action){
    try{
        yield delay(500);
        const res = yield diseaseData(action.payload);
        yield put(updateDiseaseData(res.data));
    }
    catch(error){
        console.log("current countrycdoe have error",error);
    }
}

function *watchDiseaseData() {
    yield takeLatest(sagaActionTypes.SAGA_DISEASE_DATA,fetchDiseaseData);
}

function *fetchTranslateText(action){
    try{
        yield delay(500);
        console.log("fff action ",action.payload);
        const res = yield getTranslatedText(action.payload);
        yield put(updateTranslateText(res.data));
    }
    catch(error){
        console.log("current countrycdoe have error",error);
    }
}

function *watchTranslateText() {
    yield takeLatest(sagaActionTypes.SAGA_TRANSLATETEXT,fetchTranslateText);
}

function *fetchDiseaseRiskInfo(action){
    try{
        yield delay(500);
        console.log("current countrycdoe latest action",action);
        yield put(updateShowLodding(true));
        // const res = yield getDiseaseRiskInfo(action.payload);
        const res = yield getDiseaseRiskInfoByName(action.payload);
        console.log("current countrycdoe latest res",res.data);
        yield put(updateDiseaseRiskInfo(res.data));
        yield put(updateShowLodding(false));
    }
    catch(error){
        console.log("current countrycdoe have error",error);
    }
}

function *watchDiseaseRiskInfo() {
    console.log("current countrycdoe saga");
    yield takeLatest(sagaActionTypes.SAGA_DISEASERISKINFO,fetchDiseaseRiskInfo);
}

// used in disease map prediciton to get Location info
function *fetchDiseaseMapPredictionRiskInfo(action){
    try{
        yield delay(500);
        yield put(updateShowLodding(true));
        // const res = yield getDiseaseRisksLocationAndValue(action.payload);
        const res = yield getDiseaseRisksLocationAndValueByName(action.payload);
        yield put(updateDiseaseMapPredictionRiskInfo(res.data));
        yield put(updateShowLodding(false));
    }
    catch(error){
        console.log("current countrycdoe have error",error);
    }
}

function *watchDiseaseMapPredictionRiskInfo() {
    console.log("current countrycdoe saga");
    yield takeLatest(sagaActionTypes.SAGA_DISEASEMAPPREDICTIONRISKINFO,fetchDiseaseMapPredictionRiskInfo);
}

function *rootSaga() {
    // yield fork(watchTranslateText);
    // yield fork(watchDiseaseData);
    // yield fork(watchDiseaseRiskInfo);
    // yield fork(watchDiseaseMapPredictionRiskInfo);
}

export default rootSaga;