import { getHistoricalWeatherSeries } from "@/util/http/HistWeather";
import { IGetHistoricWeatherSeriesResModel } from "@/util/http/HistWeather/responseType";
import { useEffect,useState } from "react";
import {debounce} from "lodash";
import { normalActionTypes } from "@/redux/actions/diseaseAction";
import { useDispatch } from "react-redux";

interface IProp{
    countryCode: string;
    language: string;
    servicepage: string;
    pubname: string;
    moduleName: string;
    view: string;
    scale: string;
}

let count = 0;

const useHistoricWeatherSeries = (props:IProp)=>{
    const {
        countryCode,
        language,
        servicepage,
        pubname,
        moduleName,
        view,
        scale
    } = props;

    console.log("final count",count);

    const dispatch = useDispatch();
    const [initFlag,setInitFlag] = useState(true);

    const [weatherGreenChartSeries,setWearherGreenChartSeries] = useState<IGetHistoricWeatherSeriesResModel>(null);
    const [initWeatherGreenChartSeries,setInitWearherGreenChartSeries] = useState<IGetHistoricWeatherSeriesResModel>(null);
    const [initWeatherGreenChartCategorySeries,setInitWearherGreenChartCategorySeries] = useState<IGetHistoricWeatherSeriesResModel>(null);

    const getCategorySeriesFunc = async()=>{
        try{
            dispatch({ type: normalActionTypes.NORMAL_SHOWLODDING, payload: true});
            const res = await getHistoricalWeatherSeries({
                pubname,
                // servicepage,
                // moduleName,
                culture: language + "-" + countryCode,
                countryCode,
                view: null,
                scale
            });
            setInitWearherGreenChartCategorySeries(res.data);
            setInitFlag(false);
            dispatch({ type: normalActionTypes.NORMAL_SHOWLODDING, payload: false});
        }
        catch(error){
            dispatch({ type: normalActionTypes.NORMAL_SHOWLODDING, payload: false});
            console.log("========= useAggretation  getDropdownTranslata error======",error);
        }
    };
    const getSeriesFunc = async()=>{
        try{
            console.log("get weather series");
            dispatch({ type: normalActionTypes.NORMAL_SHOWLODDING, payload: true});
            const res = await getHistoricalWeatherSeries({
                pubname,
                // servicepage,
                // moduleName,
                culture: language + "-" + countryCode,
                countryCode,
                view: view === "we_tempandrain_rainfall" ? "we_tempandrain" : view,
                scale
            });
            console.log("final count after func",count);
            setWearherGreenChartSeries(res.data);
            if(count < 1){
                const tmpListResultSeries2 = JSON.parse(JSON.stringify(res.data));
                setInitWearherGreenChartSeries(tmpListResultSeries2);
            }
            count++;
            dispatch({ type: normalActionTypes.NORMAL_SHOWLODDING, payload: false});
        }
        catch(error){
            dispatch({ type: normalActionTypes.NORMAL_SHOWLODDING, payload: false});
            console.log("========= useAggretation  getDropdownTranslata error======",error);
        }
    };

    useEffect(()=>{
        const getWeatherGreenChartSeriesDebounce = debounce(getSeriesFunc,500);
        if(countryCode && language && view){
            getWeatherGreenChartSeriesDebounce();
        }
        return ()=>{
            getWeatherGreenChartSeriesDebounce.cancel();
        };
    },[countryCode,language,view]);

    useEffect(()=>{
        const getWeatherGreenChartCategorySeriesDebounce = debounce(getCategorySeriesFunc,500);
        if(countryCode && language && initFlag){
            getWeatherGreenChartCategorySeriesDebounce();
        }
        return ()=>{
            getWeatherGreenChartCategorySeriesDebounce.cancel();
        };
    },[countryCode,language]);

    return {
        weatherGreenChartSeries,initWeatherGreenChartSeries,initWeatherGreenChartCategorySeries
    };
};

export default useHistoricWeatherSeries;