import React, { useEffect, useRef, useState } from "react";
import "./index.less";
import {article,articleTab2} from "./init";
import { Tabs } from "antd";
import E from "wangeditor";
import TabPane from "antd/es/tabs/TabPane";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { sagaActionTypes } from "@/redux/actions/quantisAction";

/**
 * main and signup page used this components
 * main will provide on param
 * signup page will provide updateKey variable
 */
interface IProp{
    aletc?: string
}

const Article = (props: IProp)=>{
    const {aletc} = props;
    const dispatch = useDispatch();
    // const history = useHistory();
    const location = useLocation();


    // const query = location?.query;
    const inputEl:any = useRef();
    // const inputEl2:any = createRef();
    const [editors, setEditors] = useState({} as any);
    useEffect(() => {
        // dispatch({
        //     type: "article/getArticle",
        //     payload: {
        //         id: query?.id,
        //     },
        // });
        dispatch({
            type: sagaActionTypes.SAGA_GET_ARTICLE,
            payload:{
                id: "3"
            }
        });
        console.log(inputEl);
        const editor:any = new E(inputEl.current);
        editor.config.lang = "en";
        editor.config.height = "100vh";
        editor.config.menus = [];
        editor.config.fontNames = [];
        editor.config.zIndex = 10;
        editor.create();
        editor.$textElem.attr("contenteditable", false);
        setEditors(editor);
        editor.txt.html(escape2Html(article));
    }, []);
    useEffect(() => {
        if(aletc == "regulations"&&editors.txt!=undefined){
            callback(1);
        }
        if(aletc == "regulationsEd"&&editors.txt!=undefined){
            callback(2);
        }
    }, [editors,aletc]);
    function escape2Html(str) {
    // console.log(str)
        const arrEntities={"lt":"<","gt":">","nbsp":" ","amp":"&","quot":"\"","#39":"\""};
        if(str!=null) return str.replace(/&(lt|gt|nbsp|amp|quot|#39);/ig,function(all,t){
            return arrEntities[t];
        });
    }

    const callback =(key) =>{
    // console.log(editors);
        if(key==1){
            editors.txt.html(escape2Html(article));
        }else{
            editors.txt.html(escape2Html(articleTab2));
        }
    };

    return(
        <div>
            {
                aletc ? <div ref={inputEl} style={{ textAlign: "left" }} />
                    :
                    <div
                        className={"flex flex-auto over-hidden padding-xs"}
                        style={{position:"relative"}}
                    >
                        <Tabs defaultActiveKey="1" onChange={callback} style={{position:"absolute",top:0,zIndex:9999,background:"#fff",width:"100%"}}>
                            <TabPane tab="Privacy Notice" key="1">
                            </TabPane>
                            <TabPane tab="Terms & Conditions" key="2">
                            </TabPane>
                        </Tabs>
                        <div style={{height:50}}> </div>
                        <div ref={inputEl} style={{ textAlign: "left" }} />

                    </div>
            }
        </div>
    );
};

export default Article;