//const API_BASE_URL = "https://services.cehub.syngenta-ais.com/";
const API_LOCAL_URL = "https://visualization.cehub.syngenta-ais.com/";
const API_SIMULATION_URL = "https://visualization.cehub.syngenta-ais.com/";
const API_QUANTIS_PUBLIC_URL = "https://services.cehub.syngenta-ais.com";
// const API_LOCAL_URL = "https://localhost:7054/";
const TIME_OUT = 60000;

export {
    API_LOCAL_URL,
    //API_BASE_URL,
    API_SIMULATION_URL,
    API_QUANTIS_PUBLIC_URL,
    TIME_OUT
};
