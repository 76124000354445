export const InitDiseaseDataFromXML = {
    "title": "Disease Risk Maps",
    "titleExplain": "Predictive turf pest and disease maps updated daily with a 5 day forecast of disease risks for your location. Also includes soil temperature maps and grass growing potential.",
    "help": "Help",
    "helpTitle": "Click on the arrow in the top right corner of the map to show the diseases and turf management forecasts available on GreenCast. Click on the required disease or subject to "+
    "display on the map.Click on 'Animate' to automatically display disease risk over the coming days and watch any progression of disease risk; animation takes a few moments to load and then "+
    "scrolls through. You can 'Pause' the animation at any required point, and then click again to resume.Use the '+' button to zoom in on any specific area of the map; use the arrows to move " +
    "around the map, or click and drag on the image to locate the area you would like to view.",
    "mapSetting": [
        {
            "mapWidth": "600",
            "mapHeight": "450",
            "initialLatitude": 55,
            "initialLogitude": -4,
            "dayStart": 0,
            "dayEnd": 4,
            "animate": "Animate",
            "pause": "Pause",
            "animationFlag": 1,
            "animationInterval": 1,
            "minimumZoom": 5,
            "maximumZoom": 9,
            "initialZoom": 5,
            "isMovementRestricted": 1,
            "boundsLatitude": 55,
            "boundsLongitude": -4,
            "boundsZoom": 5,
            "startWithOverlay": 10,
            "storeImgURL": "http://ais.syngenta-ais.com/images",
            "overlays": [
                {
                    "imageFolderName": 10,
                    "overlaysName": "Fusarium Patch Risk",
                    "diseasename": "FusariumPatchRisk"
                },
                {
                    "imageFolderName": 11,
                    "overlaysName": "Poa Annua Germination Risk",
                    "diseasename": "PoaAnnuaGerminationRisk"
                },
                {
                    "imageFolderName": 12,
                    "overlaysName": "Poa Annua Seedheads Risk",
                    "diseasename": "PoaAnnuaSeedheadsRisk"
                },
                {
                    "imageFolderName": 14,
                    "overlaysName": "Take All Patch Risk",
                    "diseasename": "TakeAllPatchRiskNonIrrigated"
                },
                {
                    "imageFolderName": 15,
                    "overlaysName": "Take All Patch Risk (Irrigated)",
                    "diseasename": "TakeAllPatchRiskIrrigated"
                },
                {
                    "imageFolderName": 7,
                    "overlaysName": "Anthracnose Foliar Risk",
                    "diseasename": "AnthracnoseFoliarBlight"
                },
                {
                    "imageFolderName": 8,
                    "overlaysName": "Dollar Spot Risk",
                    "diseasename": "DollarSpotRiskNonIrrigated"
                },
                {
                    "imageFolderName": 9,
                    "overlaysName": "Dollar Spot Risk (Irrigated)",
                    "diseasename": "DollarSpotRiskIrrigated"
                },
                {
                    "imageFolderName": 17,
                    "overlaysName": "Soil Temperature",
                    "diseasename": "TempSoil005cm_dAVG"
                },
                {
                    "imageFolderName": 16,
                    "overlaysName": "Grass Growing Potential",
                    "diseasename": "GrassGrowingPotential_Index"
                }
            ],
            "baseURLForOverlay": "http://maptiles.syngenta-ais.com/GB_IE/",
            "overlayOpacity": 0.6
        }
    ],
    "disRiskLegendTitle": "Disease Risk Color Legend",
    "disRiskLegend": [
        {
            "color": "img/Pin_01.svg",
            "textDescription": "Low Risk"
        },
        {
            "color": "img/Pin_02.svg",
            "textDescription": "Some Risk"
        },
        {
            "color": "img/Pin_03.svg",
            "textDescription": "Medium Risk"
        },
        {
            "color": "img/Pin_04.svg",
            "textDescription": "Medium/High Risk"
        },
        {
            "color": "img/Pin_05.svg",
            "textDescription": "High Risk"
        }
    ],
    "soilTemperatureTitle": "Soil Temperature (degrees Celsius)",
    "soilTemperature": [
        {
            "color": "img/Pin_06.svg",
            "textDescription": "< 4"
        },
        {
            "color": "img/Pin_07.svg",
            "textDescription": "5-7"
        },
        {
            "color": "img/Pin_08.svg",
            "textDescription": "8-10"
        },
        {
            "color": "img/Pin_09.svg",
            "textDescription": "11-13"
        },
        {
            "color": "img/Pin_10.svg",
            "textDescription": "14-16"
        },
        {
            "color": "img/Pin_11.svg",
            "textDescription": "> 17"
        }
    ],
    "strWeekday": [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday"
    ],
    "strRegion": [
        "New South Wales & Australian Capital Territory",
        "New Zealand",
        "Northern Territory",
        "Queensland",
        "South Australia",
        "Tasmania",
        "Victoria",
        "Western Australia"
    ]
};

export const InitTranslateText = [
    {
        "key": "txt_SelectLocation",
        "value": "Select your location to see the accurate weather"
    },
    {
        "key": "txt_DisplayMore",
        "value": "Display more"
    },
    {
        "key": "txt_SelectSprayWindow",
        "value": "Select spray window"
    },
    {
        "key": "txt_OrderForecast",
        "value": "Order forecast"
    },
    {
        "key": "ResDistanceFrom",
        "value": ",{Elevation} m asl, {Dist}km {Dir} from <strong>{CityName}</strong>"
    },
    {
        "key": "Search_Location",
        "value": "Search Location"
    },
    {
        "key": "cancel",
        "value": "Cancel"
    },
    {
        "key": "Add_bookmark",
        "value": "Add bookmark"
    },
    {
        "key": "My_Bookmarks",
        "value": "My Bookmarks"
    },
    {
        "key": "Input_your_location",
        "value": "Input your location"
    },
    {
        "key": "Forecast for",
        "value": "Forecast for"
    },
    {
        "key": "No_place_found",
        "value": "No place found,please enter a valid place name or longtitude,latitude"
    },
    {
        "key": "no_weather_data",
        "value": "Currently no weather data available for this location"
    },
    {
        "key": "Bookmark Added successfully",
        "value": "Bookmark Added successfully"
    },
    {
        "key": "txt_DisplayLess",
        "value": "Display Less"
    },
    {
        "key": "Apply",
        "value": "Apply To Chart"
    },
    {
        "key": "StartDate",
        "value": "Start Date"
    },
    {
        "key": "EndDate",
        "value": "End Date"
    },
    {
        "key": "Duration",
        "value": "Duration"
    },
    {
        "key": "Aggregation",
        "value": "Aggregation"
    },
    {
        "key": "Altitude",
        "value": "Location elevation"
    },
    {
        "key": "Series",
        "value": "Weather variables"
    },
    {
        "key": "ClearSelection",
        "value": "Clear Selection"
    },
    {
        "key": "Excel",
        "value": "Excel"
    },
    {
        "key": "lblMasl1",
        "value": "meter"
    },
    {
        "key": "lblMasl2",
        "value": "ft asl"
    },
    {
        "key": "NoDataTooltip",
        "value": ""
    },
    {
        "key": "tipForSelectOptions",
        "value": "Please select at least one series."
    },
    {
        "key": "historicStartDateTip",
        "value": "Start Date cannot be empty"
    },
    {
        "key": "historicTemperaturTip",
        "value": "Air temperature (°C)"
    },
    {
        "key": "historicPrecipitationsTip",
        "value": "Precipitation (mm)"
    },
    {
        "key": "Cumulative_temperature",
        "value": "Cumulative temperature"
    },
    {
        "key": "Cumulative_precipitations",
        "value": "Cumulative precipitations"
    },
    {
        "key": "rs_hour",
        "value": "Hour"
    },
    {
        "key": "txt_SelectLocation_Historic",
        "value": "Select your location to see the accurate weather"
    },
    {
        "key": "Forecast_for_historic",
        "value": "Forecast for"
    },
    {
        "key": "gc_tooltip",
        "value": "This is a kind of toggle input, please enter only one value."
    },
    {
        "key": "gc_SelectDisease",
        "value": "Select Disease"
    },
    {
        "key": "gc_Weekday",
        "value": "Weekday"
    },
    {
        "key": "gc_EndDateEmpty",
        "value": "End Date cannot be empty"
    },
    {
        "key": "gc_SelectLocationHistoricDisease",
        "value": "Select your location to see the historical disease"
    },
    {
        "key": "gc_WidgetRisk",
        "value": "Risk or Potential High! (hover or click for more details)"
    },
    {
        "key": "gc_ExportImage",
        "value": "Export Image"
    },
    {
        "key": "gc_refreshchart",
        "value": "Refresh Chart"
    },
    {
        "key": "lbl_weatherstations",
        "value": "Other weather stations"
    },
    {
        "key": "lbl_displaycharterrormsg",
        "value": "You must be logged in to display the graphic chart"
    },
    {
        "key": "lbl_seegraphicchart",
        "value": "See graphic chart"
    },
    {
        "key": "Ge_Evapotranspiration",
        "value": "Evapotranspiration (mm)"
    },
    {
        "key": "gc_WeeklyView",
        "value": "Weekly view"
    },
    {
        "key": "gc_HourlyView",
        "value": "Hourly view"
    },
    {
        "key": "gc_Hours",
        "value": "Hours"
    },
    {
        "key": "gc_KeyTurfGrowthTemperatures",
        "value": "Key Turf Growth Temperatures"
    },
    {
        "key": "gc_DiseaseForecastsKeyToColor",
        "value": "Diseases Forecasts - Key to color"
    },
    {
        "key": "gc_KeyTurfGrowthTemperaturesKeyToColor",
        "value": ""
    },
    {
        "key": "gc_SlightGrowth",
        "value": ""
    },
    {
        "key": "gc_ModerateGrowth",
        "value": ""
    },
    {
        "key": "gc_StrongGrowth",
        "value": ""
    },
    {
        "key": "gc_EmailPage",
        "value": "Email this page"
    },
    {
        "key": "gc_EmailHeader",
        "value": "Welcome!"
    },
    {
        "key": "gc_EmailTo",
        "value": "To:"
    },
    {
        "key": "gc_EmailSubject",
        "value": "Subject:"
    },
    {
        "key": "gc_EmailMessageArea",
        "value": "Message:"
    },
    {
        "key": "gc_SendEmailButton",
        "value": "Send Email"
    },
    {
        "key": "gc_EmailIsSending",
        "value": "Sending.."
    },
    {
        "key": "gc_EmailSendFailed",
        "value": "Sorry! Email failed."
    },
    {
        "key": "gc_EmailValid",
        "value": "Please enter a valid email id."
    },
    {
        "key": "gc_EmailRequired",
        "value": "Email id is required."
    },
    {
        "key": "gc_EmailSubjectRequired",
        "value": "Subject is required."
    },
    {
        "key": "gc_EmailSendSuccess",
        "value": "Email sent successfully!"
    },
    {
        "key": "close",
        "value": "Close"
    },
    {
        "key": "HistChartAuthError",
        "value": ""
    },
    {
        "key": "gc_SelectRegion",
        "value": "Select Region"
    }
];

export const InitDiseaseRiskInfo = [
    {
        "georeferenceId": "17874",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "6.25600614941025",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.833,
        "longitude": -1.333,
        "region": null
    },
    {
        "georeferenceId": "17837",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "9.83901671800548",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.817,
        "longitude": -0.917,
        "region": null
    },
    {
        "georeferenceId": "17823",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "16.459170881051",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.217,
        "longitude": -5.317,
        "region": null
    },
    {
        "georeferenceId": "17771",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "8.70822517944079",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.133,
        "longitude": -4.567,
        "region": null
    },
    {
        "georeferenceId": "17881",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "9.69500524762383",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.5,
        "longitude": 0.05,
        "region": null
    },
    {
        "georeferenceId": "17680",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "13.4963577644915",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "2",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.2,
        "longitude": -2.217,
        "region": null
    },
    {
        "georeferenceId": "17669",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "11.9322817013053",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "2",
        "poaAnnuaSeedheadsRisk": "2",
        "takeAllPatchRiskNonIrrigated": "2",
        "takeAllPatchRiskIrrigated": "2",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.2,
        "longitude": -3.833,
        "region": null
    },
    {
        "georeferenceId": "17899",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "8.15225228619481",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.6,
        "longitude": -2.433,
        "region": null
    },
    {
        "georeferenceId": "17817",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "7.15302165262952",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.433,
        "longitude": 0.367,
        "region": null
    },
    {
        "georeferenceId": "17854",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "9.94189828649029",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.483,
        "longitude": -6.1,
        "region": null
    },
    {
        "georeferenceId": "4482347",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "6.40564257537903",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.64729,
        "longitude": -0.39027,
        "region": null
    },
    {
        "georeferenceId": "17922",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "12.0988362445633",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.6,
        "longitude": -2.45,
        "region": null
    },
    {
        "georeferenceId": "17708",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "17.2035635460415",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.083,
        "longitude": -4.633,
        "region": null
    },
    {
        "georeferenceId": "17707",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "9.11672601861533",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.433,
        "longitude": -2.783,
        "region": null
    },
    {
        "georeferenceId": "17900",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "10.7820469539575",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.9,
        "longitude": -3.633,
        "region": null
    },
    {
        "georeferenceId": "17681",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "16.8086931822886",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "2",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.5,
        "longitude": -1.767,
        "region": null
    },
    {
        "georeferenceId": "17849",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "10.831558997077",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.517,
        "longitude": -6.75,
        "region": null
    },
    {
        "georeferenceId": "17786",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "10.3298253938809",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.717,
        "longitude": -4.367,
        "region": null
    },
    {
        "georeferenceId": "17797",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "5.60998872478498",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.883,
        "longitude": 0.9,
        "region": null
    },
    {
        "georeferenceId": "17654",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "14.3216565855153",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "2",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.417,
        "longitude": -6.2,
        "region": null
    },
    {
        "georeferenceId": "17706",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "10.252733197475",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.383,
        "longitude": -2.867,
        "region": null
    },
    {
        "georeferenceId": "17763",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "6.48827065729632",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.733,
        "longitude": -0.65,
        "region": null
    },
    {
        "georeferenceId": "17921",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "13.233986492674",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.467,
        "longitude": -3.533,
        "region": null
    },
    {
        "georeferenceId": "17872",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "6.67193999124949",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.033,
        "longitude": -1.7,
        "region": null
    },
    {
        "georeferenceId": "17766",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "8.32945660216304",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.65,
        "longitude": 0.567,
        "region": null
    },
    {
        "georeferenceId": "17682",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "11.3466952381313",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.65,
        "longitude": -3.383,
        "region": null
    },
    {
        "georeferenceId": "17657",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "14.4490188105057",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "2",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.033,
        "longitude": -7.45,
        "region": null
    },
    {
        "georeferenceId": "17901",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "8.53153020580777",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.467,
        "longitude": -2.567,
        "region": null
    },
    {
        "georeferenceId": "4482345",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "5.02470473655022",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.03516,
        "longitude": 0.09752,
        "region": null
    },
    {
        "georeferenceId": "17780",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "6.82561864004724",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.367,
        "longitude": -1.333,
        "region": null
    },
    {
        "georeferenceId": "17746",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "6.71141390911783",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53,
        "longitude": -1.25,
        "region": null
    },
    {
        "georeferenceId": "17772",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "9.86803154096854",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.35,
        "longitude": -3.95,
        "region": null
    },
    {
        "georeferenceId": "17640",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "19.331690089143",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "2",
        "poaAnnuaSeedheadsRisk": "3",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 60.75,
        "longitude": -0.85,
        "region": null
    },
    {
        "georeferenceId": "17920",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "14.1916925781808",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.45,
        "longitude": -4.45,
        "region": null
    },
    {
        "georeferenceId": "17838",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "11.7203111496914",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.8,
        "longitude": -1.217,
        "region": null
    },
    {
        "georeferenceId": "17683",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "14.4163609695125",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "2",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.5,
        "longitude": -6.883,
        "region": null
    },
    {
        "georeferenceId": "17726",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "5.37941299558117",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.3,
        "longitude": -1.533,
        "region": null
    },
    {
        "georeferenceId": "17705",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "9.77611559093164",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.983,
        "longitude": -2.433,
        "region": null
    },
    {
        "georeferenceId": "4482344",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "13.0217056385869",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.68495,
        "longitude": -2.76495,
        "region": null
    },
    {
        "georeferenceId": "17834",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "10.9369665143115",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.783,
        "longitude": -1.833,
        "region": null
    },
    {
        "georeferenceId": "17651",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "14.1265724661047",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "2",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.467,
        "longitude": -7.367,
        "region": null
    },
    {
        "georeferenceId": "17826",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "16.9182426949361",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.417,
        "longitude": -5.083,
        "region": null
    },
    {
        "georeferenceId": "17903",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "12.1952326417153",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.067,
        "longitude": -2.833,
        "region": null
    },
    {
        "georeferenceId": "17851",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "10.1733831089908",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.867,
        "longitude": -6.45,
        "region": null
    },
    {
        "georeferenceId": "17919",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "16.4531798732203",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.517,
        "longitude": -4.833,
        "region": null
    },
    {
        "georeferenceId": "17685",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "16.5333183204441",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "2",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.433,
        "longitude": -5.7,
        "region": null
    },
    {
        "georeferenceId": "17864",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "7.03010188480942",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52,
        "longitude": -0.733,
        "region": null
    },
    {
        "georeferenceId": "17805",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "9.67754978382283",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.5,
        "longitude": -1.983,
        "region": null
    },
    {
        "georeferenceId": "17704",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "10.6934768025575",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "2",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.6,
        "longitude": -3.55,
        "region": null
    },
    {
        "georeferenceId": "17904",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "9.85797517591005",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.567,
        "longitude": -2.417,
        "region": null
    },
    {
        "georeferenceId": "51522",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "5.70671436213268",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.817,
        "longitude": 1.133,
        "region": null
    },
    {
        "georeferenceId": "17665",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "11.9965388831196",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "2",
        "takeAllPatchRiskIrrigated": "2",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.267,
        "longitude": -4.483,
        "region": null
    },
    {
        "georeferenceId": "17725",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "5.53326923931588",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.917,
        "longitude": -1.383,
        "region": null
    },
    {
        "georeferenceId": "17843",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "12.2239504880421",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.433,
        "longitude": -8.05,
        "region": null
    },
    {
        "georeferenceId": "17747",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "8.26179400818081",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.833,
        "longitude": -1.2,
        "region": null
    },
    {
        "georeferenceId": "17686",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "16.3869736576076",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "2",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "2",
        "takeAllPatchRiskIrrigated": "2",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.233,
        "longitude": -5.083,
        "region": null
    },
    {
        "georeferenceId": "17869",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "10.4094737647177",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.45,
        "longitude": -3.017,
        "region": null
    },
    {
        "georeferenceId": "17830",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "12.3822373445344",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.717,
        "longitude": -3.533,
        "region": null
    },
    {
        "georeferenceId": "17821",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "7.18072855165113",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.35,
        "longitude": 1.417,
        "region": null
    },
    {
        "georeferenceId": "17778",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "7.46446230318487",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.45,
        "longitude": -1.733,
        "region": null
    },
    {
        "georeferenceId": "17703",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "10.3401832085825",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "2",
        "takeAllPatchRiskIrrigated": "2",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.317,
        "longitude": -3.2,
        "region": null
    },
    {
        "georeferenceId": "17917",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "6.84725044568563",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.283,
        "longitude": 1.083,
        "region": null
    },
    {
        "georeferenceId": "17818",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "6.59980433848987",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.2,
        "longitude": 0.783,
        "region": null
    },
    {
        "georeferenceId": "17773",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "9.95196306487761",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.067,
        "longitude": -3.617,
        "region": null
    },
    {
        "georeferenceId": "17702",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "9.80552041256029",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.95,
        "longitude": -3.35,
        "region": null
    },
    {
        "georeferenceId": "17687",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "16.467344726825",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "2",
        "poaAnnuaSeedheadsRisk": "2",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.7,
        "longitude": -5.3,
        "region": null
    },
    {
        "georeferenceId": "17882",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "27.769238115957",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 49.717,
        "longitude": -2.2,
        "region": null
    },
    {
        "georeferenceId": "17906",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "12.568071201189",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.767,
        "longitude": -2.717,
        "region": null
    },
    {
        "georeferenceId": "17800",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "15.8152516515398",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.083,
        "longitude": -3.6,
        "region": null
    },
    {
        "georeferenceId": "17839",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "10.7494833565709",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.833,
        "longitude": -0.283,
        "region": null
    },
    {
        "georeferenceId": "17666",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "12.7696844901672",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.683,
        "longitude": -4.167,
        "region": null
    },
    {
        "georeferenceId": "17785",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "18.3083353147119",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.7,
        "longitude": -5.05,
        "region": null
    },
    {
        "georeferenceId": "17916",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "8.79269599959331",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.083,
        "longitude": 1.183,
        "region": null
    },
    {
        "georeferenceId": "17701",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "8.4122907640398",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.617,
        "longitude": -2.8,
        "region": null
    },
    {
        "georeferenceId": "17907",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "7.29313972470695",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.05,
        "longitude": -3,
        "region": null
    },
    {
        "georeferenceId": "17688",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "16.8849993874158",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.617,
        "longitude": -4.85,
        "region": null
    },
    {
        "georeferenceId": "4482342",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "6.55926936241753",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.85426,
        "longitude": -1.50702,
        "region": null
    },
    {
        "georeferenceId": "17842",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "28.8368593722276",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 49.217,
        "longitude": -2.2,
        "region": null
    },
    {
        "georeferenceId": "17700",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "10.6906825384923",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.433,
        "longitude": -3.367,
        "region": null
    },
    {
        "georeferenceId": "17740",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "10.8237771039148",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.2,
        "longitude": -2.9,
        "region": null
    },
    {
        "georeferenceId": "17777",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "7.45852297708514",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.15,
        "longitude": -2.033,
        "region": null
    },
    {
        "georeferenceId": "17774",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "8.04924392894232",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.083,
        "longitude": -2.8,
        "region": null
    },
    {
        "georeferenceId": "17863",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "12.3361703767888",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.383,
        "longitude": -2.35,
        "region": null
    },
    {
        "georeferenceId": "17915",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "7.94782033108085",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.267,
        "longitude": 0.517,
        "region": null
    },
    {
        "georeferenceId": "17871",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "6.00727071728702",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.583,
        "longitude": -0.35,
        "region": null
    },
    {
        "georeferenceId": "17689",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "14.5353183116005",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.85,
        "longitude": -4.95,
        "region": null
    },
    {
        "georeferenceId": "17793",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "9.71000618625078",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.533,
        "longitude": -0.417,
        "region": null
    },
    {
        "georeferenceId": "17908",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "6.74242393716508",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53,
        "longitude": -2.183,
        "region": null
    },
    {
        "georeferenceId": "17748",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "7.2569116702161",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.4,
        "longitude": -0.767,
        "region": null
    },
    {
        "georeferenceId": "17802",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "13.3738662710093",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.4,
        "longitude": -3.433,
        "region": null
    },
    {
        "georeferenceId": "17936",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "10.3079870316083",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.117,
        "longitude": -3.15,
        "region": null
    },
    {
        "georeferenceId": "17787",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "10.1630419787808",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.567,
        "longitude": -3.983,
        "region": null
    },
    {
        "georeferenceId": "17699",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "11.2118631209972",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "2",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.667,
        "longitude": -3.783,
        "region": null
    },
    {
        "georeferenceId": "17733",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "5.01558346241239",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.1,
        "longitude": -0.167,
        "region": null
    },
    {
        "georeferenceId": "17914",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "7.70641788750714",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.2,
        "longitude": 0.183,
        "region": null
    },
    {
        "georeferenceId": "17690",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "15.4045178359897",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.467,
        "longitude": -4.617,
        "region": null
    },
    {
        "georeferenceId": "17792",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "8.89826929948166",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.683,
        "longitude": -0.8,
        "region": null
    },
    {
        "georeferenceId": "17909",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "8.32422859478917",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.367,
        "longitude": -1.5,
        "region": null
    },
    {
        "georeferenceId": "17791",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "8.56769596089531",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.617,
        "longitude": -1.083,
        "region": null
    },
    {
        "georeferenceId": "17698",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "10.8960389937914",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.05,
        "longitude": -3.65,
        "region": null
    },
    {
        "georeferenceId": "17841",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "28.2241197437824",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 49.433,
        "longitude": -2.6,
        "region": null
    },
    {
        "georeferenceId": "17668",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "13.0780201531505",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "2",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.817,
        "longitude": -3.967,
        "region": null
    },
    {
        "georeferenceId": "17697",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "13.8018338317485",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.8,
        "longitude": -4,
        "region": null
    },
    {
        "georeferenceId": "17910",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "5.60363778577832",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.933,
        "longitude": 1.283,
        "region": null
    },
    {
        "georeferenceId": "17759",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "9.34851300845222",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.95,
        "longitude": -3.417,
        "region": null
    },
    {
        "georeferenceId": "17691",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "14.8255644982699",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.95,
        "longitude": -4.767,
        "region": null
    },
    {
        "georeferenceId": "17855",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "11.6317708043337",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.35,
        "longitude": -6.667,
        "region": null
    },
    {
        "georeferenceId": "17831",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "13.7355700789298",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.867,
        "longitude": -3.233,
        "region": null
    },
    {
        "georeferenceId": "17724",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "6.96966186745374",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.417,
        "longitude": -1.6,
        "region": null
    },
    {
        "georeferenceId": "17776",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "8.04590216919592",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.117,
        "longitude": -2.3,
        "region": null
    },
    {
        "georeferenceId": "17764",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "6.61895521268128",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.617,
        "longitude": -0.467,
        "region": null
    },
    {
        "georeferenceId": "17913",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "10.2277828556205",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.483,
        "longitude": -0.6,
        "region": null
    },
    {
        "georeferenceId": "17840",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "10.6509120571992",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.8,
        "longitude": 0.25,
        "region": null
    },
    {
        "georeferenceId": "17820",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "8.68043349988483",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.133,
        "longitude": 1.3,
        "region": null
    },
    {
        "georeferenceId": "17653",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "13.1726906648614",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "2",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 58.217,
        "longitude": -6.317,
        "region": null
    },
    {
        "georeferenceId": "17693",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "13.6400151393942",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.867,
        "longitude": -4.433,
        "region": null
    },
    {
        "georeferenceId": "17862",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "7.46680987932795",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.9,
        "longitude": -2.083,
        "region": null
    },
    {
        "georeferenceId": "17775",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "6.47872664666899",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.2,
        "longitude": -2.217,
        "region": null
    },
    {
        "georeferenceId": "17801",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "11.218904460549",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.5,
        "longitude": -3.2,
        "region": null
    },
    {
        "georeferenceId": "17762",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "7.36831461142568",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.8,
        "longitude": -2.667,
        "region": null
    },
    {
        "georeferenceId": "4482346",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "15.4045178359897",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.46997,
        "longitude": -4.564,
        "region": null
    },
    {
        "georeferenceId": "17749",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "5.8953839654312",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.167,
        "longitude": -0.517,
        "region": null
    },
    {
        "georeferenceId": "17912",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "13.5973370980863",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.35,
        "longitude": -2.983,
        "region": null
    },
    {
        "georeferenceId": "17696",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "0",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "2",
        "poaAnnuaSeedheadsRisk": "2",
        "takeAllPatchRiskNonIrrigated": "2",
        "takeAllPatchRiskIrrigated": "2",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.417,
        "longitude": -4.317,
        "region": null
    },
    {
        "georeferenceId": "17784",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "6.34954253522679",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.117,
        "longitude": 0.967,
        "region": null
    },
    {
        "georeferenceId": "17694",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "12.8467465026144",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.317,
        "longitude": -3.733,
        "region": null
    },
    {
        "georeferenceId": "17884",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "8.14239550628995",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.25,
        "longitude": -0.883,
        "region": null
    },
    {
        "georeferenceId": "17911",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "16.9940568891572",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.083,
        "longitude": -4.15,
        "region": null
    },
    {
        "georeferenceId": "17930",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "10.5099985657323",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.95,
        "longitude": -3.983,
        "region": null
    },
    {
        "georeferenceId": "17738",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "12.7952926886466",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.5,
        "longitude": -3.067,
        "region": null
    },
    {
        "georeferenceId": "17658",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "14.0130409515834",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "2",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.25,
        "longitude": -5.8,
        "region": null
    },
    {
        "georeferenceId": "17876",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "7.48931646804556",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.867,
        "longitude": -2.25,
        "region": null
    },
    {
        "georeferenceId": "17767",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "9.00387714713119",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.95,
        "longitude": 1.133,
        "region": null
    },
    {
        "georeferenceId": "17737",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "9.01942820669555",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.217,
        "longitude": -3.217,
        "region": null
    },
    {
        "georeferenceId": "17861",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "6.28560430549907",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.367,
        "longitude": -1.483,
        "region": null
    },
    {
        "georeferenceId": "17750",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "6.46548117569468",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.033,
        "longitude": -0.5,
        "region": null
    },
    {
        "georeferenceId": "17741",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "10.6310663974791",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.5,
        "longitude": -2.217,
        "region": null
    },
    {
        "georeferenceId": "17723",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "6.72599974243913",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "2",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.817,
        "longitude": -2.083,
        "region": null
    },
    {
        "georeferenceId": "17735",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "12.2181662470772",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.1,
        "longitude": -3.933,
        "region": null
    },
    {
        "georeferenceId": "17885",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "9.92104053370743",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.05,
        "longitude": -7.15,
        "region": null
    },
    {
        "georeferenceId": "17935",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "10.3818399741188",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56,
        "longitude": -3.783,
        "region": null
    },
    {
        "georeferenceId": "17732",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "4.35380710560849",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.483,
        "longitude": -0.6,
        "region": null
    },
    {
        "georeferenceId": "17671",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "15.6504440634379",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.65,
        "longitude": -3.567,
        "region": null
    },
    {
        "georeferenceId": "17809",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "8.36598710892054",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.233,
        "longitude": -0.95,
        "region": null
    },
    {
        "georeferenceId": "17761",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "14.6588999944691",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.567,
        "longitude": -3.717,
        "region": null
    },
    {
        "georeferenceId": "17751",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "5.1280477332406",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.867,
        "longitude": -0.433,
        "region": null
    },
    {
        "georeferenceId": "17877",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "13.9080928412208",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.383,
        "longitude": -2.717,
        "region": null
    },
    {
        "georeferenceId": "17848",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "12.0862787398242",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "2",
        "takeAllPatchRiskIrrigated": "2",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.717,
        "longitude": -6.817,
        "region": null
    },
    {
        "georeferenceId": "17868",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "13.8163719482057",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "2",
        "poaAnnuaSeedheadsRisk": "3",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 60.433,
        "longitude": -1.3,
        "region": null
    },
    {
        "georeferenceId": "17758",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "13.4710647993705",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.8,
        "longitude": -4.117,
        "region": null
    },
    {
        "georeferenceId": "17813",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "9.69165366942413",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.15,
        "longitude": -0.183,
        "region": null
    },
    {
        "georeferenceId": "17860",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "5.98325093150082",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.567,
        "longitude": -1.167,
        "region": null
    },
    {
        "georeferenceId": "17722",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "8.94584236668654",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.283,
        "longitude": -2.283,
        "region": null
    },
    {
        "georeferenceId": "17886",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "6.85571275034928",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.567,
        "longitude": 0.7,
        "region": null
    },
    {
        "georeferenceId": "17768",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "7.31750192713442",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.633,
        "longitude": 1.3,
        "region": null
    },
    {
        "georeferenceId": "17752",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "4.81998593067446",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.483,
        "longitude": -0.083,
        "region": null
    },
    {
        "georeferenceId": "17853",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "8.54737808427163",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.65,
        "longitude": -6.217,
        "region": null
    },
    {
        "georeferenceId": "17721",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "10.393328276435",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "2",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "2",
        "takeAllPatchRiskIrrigated": "2",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.683,
        "longitude": -2.45,
        "region": null
    },
    {
        "georeferenceId": "17825",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "17.9141806724862",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.15,
        "longitude": -5.067,
        "region": null
    },
    {
        "georeferenceId": "17856",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "8.30190600824838",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.6,
        "longitude": -5.883,
        "region": null
    },
    {
        "georeferenceId": "17783",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "8.22722460004357",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.25,
        "longitude": 0.7,
        "region": null
    },
    {
        "georeferenceId": "17731",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "4.43336343184008",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.367,
        "longitude": -0.667,
        "region": null
    },
    {
        "georeferenceId": "17859",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "8.43715134244695",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.867,
        "longitude": -1.65,
        "region": null
    },
    {
        "georeferenceId": "17719",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "12.6735447372684",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.5,
        "longitude": -2.683,
        "region": null
    },
    {
        "georeferenceId": "17891",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "14.6034809337356",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "2",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "2",
        "takeAllPatchRiskIrrigated": "2",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 58.033,
        "longitude": -4.4,
        "region": null
    },
    {
        "georeferenceId": "17753",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "5.14321374573971",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.083,
        "longitude": -0.167,
        "region": null
    },
    {
        "georeferenceId": "17850",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "10.3700607900914",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.2,
        "longitude": -6.65,
        "region": null
    },
    {
        "georeferenceId": "17655",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "13.6975404755761",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "2",
        "poaAnnuaSeedheadsRisk": "2",
        "takeAllPatchRiskNonIrrigated": "2",
        "takeAllPatchRiskIrrigated": "2",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.717,
        "longitude": -4.883,
        "region": null
    },
    {
        "georeferenceId": "17718",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "10.6432398842824",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.05,
        "longitude": -2.55,
        "region": null
    },
    {
        "georeferenceId": "17893",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "13.700682709133",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "2",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.683,
        "longitude": -2.017,
        "region": null
    },
    {
        "georeferenceId": "17742",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "8.97802632255654",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.967,
        "longitude": -2.033,
        "region": null
    },
    {
        "georeferenceId": "17847",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "9.92104053370743",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.067,
        "longitude": -7.017,
        "region": null
    },
    {
        "georeferenceId": "17873",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "5.99000480771174",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.533,
        "longitude": -1.567,
        "region": null
    },
    {
        "georeferenceId": "17858",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "9.20626908468642",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.85,
        "longitude": -5.8,
        "region": null
    },
    {
        "georeferenceId": "17757",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "20.4625007789657",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.783,
        "longitude": -4.733,
        "region": null
    },
    {
        "georeferenceId": "17754",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "5.4357082483769",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.083,
        "longitude": 0.267,
        "region": null
    },
    {
        "georeferenceId": "17811",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "8.3259043360467",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.283,
        "longitude": -0.767,
        "region": null
    },
    {
        "georeferenceId": "17717",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "13.0872352547993",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.933,
        "longitude": -2.95,
        "region": null
    },
    {
        "georeferenceId": "17730",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "5.38984140889009",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.683,
        "longitude": -1.217,
        "region": null
    },
    {
        "georeferenceId": "17852",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "9.9128506576234",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.183,
        "longitude": -6.167,
        "region": null
    },
    {
        "georeferenceId": "17642",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "13.9803141445929",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "2",
        "poaAnnuaSeedheadsRisk": "3",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 60.133,
        "longitude": -1.183,
        "region": null
    },
    {
        "georeferenceId": "17929",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "15.5468387159653",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "2",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "2",
        "takeAllPatchRiskIrrigated": "2",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 58.283,
        "longitude": -4.433,
        "region": null
    },
    {
        "georeferenceId": "17769",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "6.47796505540932",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.8,
        "longitude": 1.25,
        "region": null
    },
    {
        "georeferenceId": "17835",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "9.98235453128911",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.9,
        "longitude": -1.4,
        "region": null
    },
    {
        "georeferenceId": "17857",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "8.74132348934903",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.667,
        "longitude": -5.667,
        "region": null
    },
    {
        "georeferenceId": "17756",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "12.9087074090048",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53,
        "longitude": -4.083,
        "region": null
    },
    {
        "georeferenceId": "17667",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "12.9957118315265",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.533,
        "longitude": -4.05,
        "region": null
    },
    {
        "georeferenceId": "17894",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "16.108648519377",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.633,
        "longitude": -6.183,
        "region": null
    },
    {
        "georeferenceId": "17867",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "7.34026304015317",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.2,
        "longitude": -1.7,
        "region": null
    },
    {
        "georeferenceId": "4482343",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "7.2569116702161",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.39455,
        "longitude": -0.78437,
        "region": null
    },
    {
        "georeferenceId": "17672",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "15.5656170477715",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.717,
        "longitude": -3.317,
        "region": null
    },
    {
        "georeferenceId": "17878",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "11.5971057512473",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.333,
        "longitude": -2.85,
        "region": null
    },
    {
        "georeferenceId": "17808",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "10.967484309752",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.15,
        "longitude": -1.567,
        "region": null
    },
    {
        "georeferenceId": "17827",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "14.2803323719058",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.467,
        "longitude": -4.717,
        "region": null
    },
    {
        "georeferenceId": "17715",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "13.2576491005271",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.767,
        "longitude": -3.317,
        "region": null
    },
    {
        "georeferenceId": "17649",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "20.8345129962355",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "2",
        "poaAnnuaSeedheadsRisk": "3",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 58.95,
        "longitude": -2.9,
        "region": null
    },
    {
        "georeferenceId": "17931",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "10.3079870316083",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.067,
        "longitude": -3.467,
        "region": null
    },
    {
        "georeferenceId": "17895",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "16.0966739043906",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "2",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.417,
        "longitude": -5.467,
        "region": null
    },
    {
        "georeferenceId": "17743",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "10.0233080930615",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.817,
        "longitude": -1.867,
        "region": null
    },
    {
        "georeferenceId": "17927",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "11.7982843261128",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.183,
        "longitude": -6.333,
        "region": null
    },
    {
        "georeferenceId": "17673",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "13.6977659758013",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "2",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "2",
        "takeAllPatchRiskIrrigated": "2",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.35,
        "longitude": -3.35,
        "region": null
    },
    {
        "georeferenceId": "17932",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "11.4537014314983",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.75,
        "longitude": -2.483,
        "region": null
    },
    {
        "georeferenceId": "17816",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "12.5606188246915",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.3,
        "longitude": -0.083,
        "region": null
    },
    {
        "georeferenceId": "17656",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "13.9043880965739",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.867,
        "longitude": -5.633,
        "region": null
    },
    {
        "georeferenceId": "17765",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "5.54644990152875",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.867,
        "longitude": 0.15,
        "region": null
    },
    {
        "georeferenceId": "17659",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "16.630815899211",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.767,
        "longitude": -6.05,
        "region": null
    },
    {
        "georeferenceId": "17714",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "14.6255885340135",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.117,
        "longitude": -3.217,
        "region": null
    },
    {
        "georeferenceId": "17824",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "16.8279149788891",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.083,
        "longitude": -5.25,
        "region": null
    },
    {
        "georeferenceId": "17845",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "11.5763553531243",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.6,
        "longitude": -7.3,
        "region": null
    },
    {
        "georeferenceId": "17729",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "6.6466991902276",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.05,
        "longitude": -1.25,
        "region": null
    },
    {
        "georeferenceId": "17674",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "0",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "3",
        "poaAnnuaSeedheadsRisk": "4",
        "takeAllPatchRiskNonIrrigated": "2",
        "takeAllPatchRiskIrrigated": "2",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.883,
        "longitude": -3.417,
        "region": null
    },
    {
        "georeferenceId": "17879",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "8.05980169391595",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.867,
        "longitude": -0.367,
        "region": null
    },
    {
        "georeferenceId": "17933",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "12.0096216657162",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.317,
        "longitude": 0.033,
        "region": null
    },
    {
        "georeferenceId": "17788",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "10.7185117772446",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.517,
        "longitude": -2.583,
        "region": null
    },
    {
        "georeferenceId": "17736",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "9.46851980233001",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.217,
        "longitude": -3.5,
        "region": null
    },
    {
        "georeferenceId": "17713",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "14.41089116261",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.317,
        "longitude": -3.4,
        "region": null
    },
    {
        "georeferenceId": "17926",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "10.8120645964904",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "2",
        "takeAllPatchRiskIrrigated": "2",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.717,
        "longitude": -6.517,
        "region": null
    },
    {
        "georeferenceId": "17782",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "9.47965283475959",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.35,
        "longitude": 0.517,
        "region": null
    },
    {
        "georeferenceId": "17866",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "12.3035896679247",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.133,
        "longitude": -3.017,
        "region": null
    },
    {
        "georeferenceId": "17896",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "15.3524385514792",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.6,
        "longitude": -4.5,
        "region": null
    },
    {
        "georeferenceId": "17675",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "15.7053991706503",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "2",
        "poaAnnuaSeedheadsRisk": "2",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 58.617,
        "longitude": -3.55,
        "region": null
    },
    {
        "georeferenceId": "17870",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "9.6763246454427",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.783,
        "longitude": -1.75,
        "region": null
    },
    {
        "georeferenceId": "17770",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "6.60845670791463",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.683,
        "longitude": 1.683,
        "region": null
    },
    {
        "georeferenceId": "17712",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "12.9777490517799",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.617,
        "longitude": -3.167,
        "region": null
    },
    {
        "georeferenceId": "17836",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "23.2811146427115",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.583,
        "longitude": -1.3,
        "region": null
    },
    {
        "georeferenceId": "17794",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "8.38976210468423",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.883,
        "longitude": 0.233,
        "region": null
    },
    {
        "georeferenceId": "17744",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "9.70450404869163",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.65,
        "longitude": -1.783,
        "region": null
    },
    {
        "georeferenceId": "17925",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "11.7421809915178",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.817,
        "longitude": -7.467,
        "region": null
    },
    {
        "georeferenceId": "17734",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "13.5840631813783",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.25,
        "longitude": -4.533,
        "region": null
    },
    {
        "georeferenceId": "17728",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "5.37941299558117",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.2,
        "longitude": -1.383,
        "region": null
    },
    {
        "georeferenceId": "17676",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "17.2973937983169",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "2",
        "poaAnnuaSeedheadsRisk": "3",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 58.45,
        "longitude": -3.083,
        "region": null
    },
    {
        "georeferenceId": "17880",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "15.0405174948753",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.417,
        "longitude": -4.117,
        "region": null
    },
    {
        "georeferenceId": "17897",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "12.2527260525707",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.767,
        "longitude": -4.167,
        "region": null
    },
    {
        "georeferenceId": "17660",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "0",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "4",
        "poaAnnuaSeedheadsRisk": "2",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.817,
        "longitude": -4.967,
        "region": null
    },
    {
        "georeferenceId": "17711",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "13.8042118332005",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.517,
        "longitude": -3.6,
        "region": null
    },
    {
        "georeferenceId": "17663",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "14.7125269850218",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "2",
        "poaAnnuaSeedheadsRisk": "2",
        "takeAllPatchRiskNonIrrigated": "2",
        "takeAllPatchRiskIrrigated": "2",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.867,
        "longitude": -4.7,
        "region": null
    },
    {
        "georeferenceId": "17898",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "12.6609817249795",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.783,
        "longitude": -4,
        "region": null
    },
    {
        "georeferenceId": "17760",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "9.67110594595965",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "2",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.75,
        "longitude": -3.467,
        "region": null
    },
    {
        "georeferenceId": "17924",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "10.5212371888487",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.867,
        "longitude": 0.6,
        "region": null
    },
    {
        "georeferenceId": "17806",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "10.8880528574105",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.2,
        "longitude": -1.8,
        "region": null
    },
    {
        "georeferenceId": "17678",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "12.3984474525183",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "2",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.083,
        "longitude": -2.833,
        "region": null
    },
    {
        "georeferenceId": "17739",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "13.1913957079135",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.767,
        "longitude": -3.033,
        "region": null
    },
    {
        "georeferenceId": "17844",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "11.9330034683686",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.4,
        "longitude": -7.65,
        "region": null
    },
    {
        "georeferenceId": "17789",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "7.88642396523811",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.867,
        "longitude": -1.683,
        "region": null
    },
    {
        "georeferenceId": "17810",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "8.42474264034021",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.433,
        "longitude": -1,
        "region": null
    },
    {
        "georeferenceId": "17727",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "6.5548410552976",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.133,
        "longitude": -1.417,
        "region": null
    },
    {
        "georeferenceId": "17790",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "9.29346266373809",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.75,
        "longitude": -1.583,
        "region": null
    },
    {
        "georeferenceId": "17710",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "10.4735444953841",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.933,
        "longitude": -3.15,
        "region": null
    },
    {
        "georeferenceId": "4482348",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "6.63888537078555",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.65863,
        "longitude": -0.49627,
        "region": null
    },
    {
        "georeferenceId": "17923",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "11.7412275577004",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.067,
        "longitude": -1.317,
        "region": null
    },
    {
        "georeferenceId": "17679",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "10.9612959209555",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.717,
        "longitude": -2.467,
        "region": null
    },
    {
        "georeferenceId": "17865",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "16.2768941323871",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.833,
        "longitude": -4.533,
        "region": null
    },
    {
        "georeferenceId": "17832",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "12.0751812772221",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.95,
        "longitude": -2.633,
        "region": null
    },
    {
        "georeferenceId": "17745",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "8.42205060332153",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.8,
        "longitude": -1.55,
        "region": null
    },
    {
        "georeferenceId": "17781",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-05",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "8.29283775883917",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.217,
        "longitude": -0.483,
        "region": null
    },
    {
        "georeferenceId": "17769",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "6.13180903442972",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.8,
        "longitude": 1.25,
        "region": null
    },
    {
        "georeferenceId": "17784",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "6.48346055092505",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.117,
        "longitude": 0.967,
        "region": null
    },
    {
        "georeferenceId": "17737",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "8.60952542698686",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.217,
        "longitude": -3.217,
        "region": null
    },
    {
        "georeferenceId": "17762",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "7.37067858479223",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.8,
        "longitude": -2.667,
        "region": null
    },
    {
        "georeferenceId": "17871",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "6.18592045965291",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.583,
        "longitude": -0.35,
        "region": null
    },
    {
        "georeferenceId": "17738",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "11.3592920677094",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.5,
        "longitude": -3.067,
        "region": null
    },
    {
        "georeferenceId": "17852",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "12.0253275042745",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.183,
        "longitude": -6.167,
        "region": null
    },
    {
        "georeferenceId": "17870",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "8.48574064704591",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.783,
        "longitude": -1.75,
        "region": null
    },
    {
        "georeferenceId": "17739",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "12.3695024870523",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.767,
        "longitude": -3.033,
        "region": null
    },
    {
        "georeferenceId": "17931",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "10.8420067727869",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.067,
        "longitude": -3.467,
        "region": null
    },
    {
        "georeferenceId": "17659",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "16.0551702461488",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.767,
        "longitude": -6.05,
        "region": null
    },
    {
        "georeferenceId": "17869",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "10.922834686216",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.45,
        "longitude": -3.017,
        "region": null
    },
    {
        "georeferenceId": "17740",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "9.71650654992671",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.2,
        "longitude": -2.9,
        "region": null
    },
    {
        "georeferenceId": "17657",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "14.8647605278307",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "2",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.033,
        "longitude": -7.45,
        "region": null
    },
    {
        "georeferenceId": "17761",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "14.2528363343247",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.567,
        "longitude": -3.717,
        "region": null
    },
    {
        "georeferenceId": "17668",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "12.4184114144752",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.817,
        "longitude": -3.967,
        "region": null
    },
    {
        "georeferenceId": "17868",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "11.5859491185809",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "3",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 60.433,
        "longitude": -1.3,
        "region": null
    },
    {
        "georeferenceId": "17741",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "9.35213466869456",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.5,
        "longitude": -2.217,
        "region": null
    },
    {
        "georeferenceId": "17742",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "8.39783590340417",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.967,
        "longitude": -2.033,
        "region": null
    },
    {
        "georeferenceId": "17853",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "9.98913200716396",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.65,
        "longitude": -6.217,
        "region": null
    },
    {
        "georeferenceId": "17867",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "7.18691171683629",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.2,
        "longitude": -1.7,
        "region": null
    },
    {
        "georeferenceId": "17743",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "9.15622234592682",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.817,
        "longitude": -1.867,
        "region": null
    },
    {
        "georeferenceId": "17866",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "12.5389697791023",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.133,
        "longitude": -3.017,
        "region": null
    },
    {
        "georeferenceId": "17760",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "9.50826770853785",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.75,
        "longitude": -3.467,
        "region": null
    },
    {
        "georeferenceId": "17744",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "8.70397352832039",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.65,
        "longitude": -1.783,
        "region": null
    },
    {
        "georeferenceId": "4482348",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "6.26799591281056",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.65863,
        "longitude": -0.49627,
        "region": null
    },
    {
        "georeferenceId": "17865",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "14.9905147252311",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.833,
        "longitude": -4.533,
        "region": null
    },
    {
        "georeferenceId": "17745",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "7.89845655413362",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.8,
        "longitude": -1.55,
        "region": null
    },
    {
        "georeferenceId": "17854",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "11.8487531442319",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.483,
        "longitude": -6.1,
        "region": null
    },
    {
        "georeferenceId": "17669",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "10.4816535444311",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "2",
        "takeAllPatchRiskNonIrrigated": "2",
        "takeAllPatchRiskIrrigated": "2",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.2,
        "longitude": -3.833,
        "region": null
    },
    {
        "georeferenceId": "17864",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "5.73037031675168",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52,
        "longitude": -0.733,
        "region": null
    },
    {
        "georeferenceId": "17746",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "6.21172949261493",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53,
        "longitude": -1.25,
        "region": null
    },
    {
        "georeferenceId": "4482342",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "5.87343626791218",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.85426,
        "longitude": -1.50702,
        "region": null
    },
    {
        "georeferenceId": "17824",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "17.1621399538483",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.083,
        "longitude": -5.25,
        "region": null
    },
    {
        "georeferenceId": "17747",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "7.34009320045396",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.833,
        "longitude": -1.2,
        "region": null
    },
    {
        "georeferenceId": "17863",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "12.0303068141298",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.383,
        "longitude": -2.35,
        "region": null
    },
    {
        "georeferenceId": "17759",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "9.40436527165668",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.95,
        "longitude": -3.417,
        "region": null
    },
    {
        "georeferenceId": "17748",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "6.76387797920178",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.4,
        "longitude": -0.767,
        "region": null
    },
    {
        "georeferenceId": "17930",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "10.4662185612381",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.95,
        "longitude": -3.983,
        "region": null
    },
    {
        "georeferenceId": "17862",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "7.67988376162655",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.9,
        "longitude": -2.083,
        "region": null
    },
    {
        "georeferenceId": "17855",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "13.4518384218073",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.35,
        "longitude": -6.667,
        "region": null
    },
    {
        "georeferenceId": "17749",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "5.58289426578799",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.167,
        "longitude": -0.517,
        "region": null
    },
    {
        "georeferenceId": "51522",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "5.73131579976791",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.817,
        "longitude": 1.133,
        "region": null
    },
    {
        "georeferenceId": "17783",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "7.50625468099707",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.25,
        "longitude": 0.7,
        "region": null
    },
    {
        "georeferenceId": "17861",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "6.07213187342795",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.367,
        "longitude": -1.483,
        "region": null
    },
    {
        "georeferenceId": "17750",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "5.95329497417506",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.033,
        "longitude": -0.5,
        "region": null
    },
    {
        "georeferenceId": "17793",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "9.17195753428142",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.533,
        "longitude": -0.417,
        "region": null
    },
    {
        "georeferenceId": "17658",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "12.9835007446146",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.25,
        "longitude": -5.8,
        "region": null
    },
    {
        "georeferenceId": "17758",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "14.3362862050518",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.8,
        "longitude": -4.117,
        "region": null
    },
    {
        "georeferenceId": "17860",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "6.4043979906473",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.567,
        "longitude": -1.167,
        "region": null
    },
    {
        "georeferenceId": "17751",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "5.31853387406569",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.867,
        "longitude": -0.433,
        "region": null
    },
    {
        "georeferenceId": "17671",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "14.421943602741",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.65,
        "longitude": -3.567,
        "region": null
    },
    {
        "georeferenceId": "17752",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "4.72426576316086",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.483,
        "longitude": -0.083,
        "region": null
    },
    {
        "georeferenceId": "17859",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "7.74377221893007",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.867,
        "longitude": -1.65,
        "region": null
    },
    {
        "georeferenceId": "17753",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "4.8775717310929",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.083,
        "longitude": -0.167,
        "region": null
    },
    {
        "georeferenceId": "17757",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "19.6709201125373",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.783,
        "longitude": -4.733,
        "region": null
    },
    {
        "georeferenceId": "17858",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "10.4713670602793",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.85,
        "longitude": -5.8,
        "region": null
    },
    {
        "georeferenceId": "17754",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "5.43238261970367",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.083,
        "longitude": 0.267,
        "region": null
    },
    {
        "georeferenceId": "17929",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "14.3247479739993",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 58.283,
        "longitude": -4.433,
        "region": null
    },
    {
        "georeferenceId": "17856",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "9.55936197882646",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.6,
        "longitude": -5.883,
        "region": null
    },
    {
        "georeferenceId": "17857",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "10.4696908935563",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.667,
        "longitude": -5.667,
        "region": null
    },
    {
        "georeferenceId": "17756",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "13.4410118561768",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53,
        "longitude": -4.083,
        "region": null
    },
    {
        "georeferenceId": "17835",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "9.29861308032803",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.9,
        "longitude": -1.4,
        "region": null
    },
    {
        "georeferenceId": "17672",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "14.4468924028203",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.717,
        "longitude": -3.317,
        "region": null
    },
    {
        "georeferenceId": "17788",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "11.0045950679525",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.517,
        "longitude": -2.583,
        "region": null
    },
    {
        "georeferenceId": "17927",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "14.0729367885167",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.183,
        "longitude": -6.333,
        "region": null
    },
    {
        "georeferenceId": "17673",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "13.3239135364906",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "2",
        "takeAllPatchRiskIrrigated": "2",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.35,
        "longitude": -3.35,
        "region": null
    },
    {
        "georeferenceId": "17665",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "11.1307895965928",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "2",
        "takeAllPatchRiskIrrigated": "2",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.267,
        "longitude": -4.483,
        "region": null
    },
    {
        "georeferenceId": "17663",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "13.4574795407034",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "2",
        "takeAllPatchRiskNonIrrigated": "2",
        "takeAllPatchRiskIrrigated": "2",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.867,
        "longitude": -4.7,
        "region": null
    },
    {
        "georeferenceId": "17649",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "20.1659060494592",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "3",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 58.95,
        "longitude": -2.9,
        "region": null
    },
    {
        "georeferenceId": "17926",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "12.3809713844829",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.717,
        "longitude": -6.517,
        "region": null
    },
    {
        "georeferenceId": "17674",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "0",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "3",
        "poaAnnuaSeedheadsRisk": "4",
        "takeAllPatchRiskNonIrrigated": "2",
        "takeAllPatchRiskIrrigated": "2",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.883,
        "longitude": -3.417,
        "region": null
    },
    {
        "georeferenceId": "17782",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "9.86598715471952",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.35,
        "longitude": 0.517,
        "region": null
    },
    {
        "georeferenceId": "17789",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "8.37250973728684",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.867,
        "longitude": -1.683,
        "region": null
    },
    {
        "georeferenceId": "17675",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "14.9683257179723",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "2",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 58.617,
        "longitude": -3.55,
        "region": null
    },
    {
        "georeferenceId": "17925",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "13.5400991155941",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.817,
        "longitude": -7.467,
        "region": null
    },
    {
        "georeferenceId": "17836",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "22.1826114789835",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.583,
        "longitude": -1.3,
        "region": null
    },
    {
        "georeferenceId": "17794",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "8.27383741426682",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.883,
        "longitude": 0.233,
        "region": null
    },
    {
        "georeferenceId": "17676",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "17.3781011365122",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "3",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 58.45,
        "longitude": -3.083,
        "region": null
    },
    {
        "georeferenceId": "17924",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "10.6463619572567",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.867,
        "longitude": 0.6,
        "region": null
    },
    {
        "georeferenceId": "17678",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "11.8976110317225",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "2",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.083,
        "longitude": -2.833,
        "region": null
    },
    {
        "georeferenceId": "17781",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "8.24080591853799",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.217,
        "longitude": -0.483,
        "region": null
    },
    {
        "georeferenceId": "17830",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "13.1013956434861",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.717,
        "longitude": -3.533,
        "region": null
    },
    {
        "georeferenceId": "17923",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "12.6552874997188",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.067,
        "longitude": -1.317,
        "region": null
    },
    {
        "georeferenceId": "17679",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "12.0208333653342",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.717,
        "longitude": -2.467,
        "region": null
    },
    {
        "georeferenceId": "17823",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "16.1287510756903",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.217,
        "longitude": -5.317,
        "region": null
    },
    {
        "georeferenceId": "4482347",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "6.05376068001711",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.64729,
        "longitude": -0.39027,
        "region": null
    },
    {
        "georeferenceId": "17922",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "13.4427777716227",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.6,
        "longitude": -2.45,
        "region": null
    },
    {
        "georeferenceId": "17680",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "13.1745535575937",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "2",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.2,
        "longitude": -2.217,
        "region": null
    },
    {
        "georeferenceId": "17837",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "10.5798812485623",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.817,
        "longitude": -0.917,
        "region": null
    },
    {
        "georeferenceId": "17681",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "16.455341103658",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "2",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.5,
        "longitude": -1.767,
        "region": null
    },
    {
        "georeferenceId": "17921",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "13.9963588820863",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.467,
        "longitude": -3.533,
        "region": null
    },
    {
        "georeferenceId": "17780",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "6.77347920447819",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.367,
        "longitude": -1.333,
        "region": null
    },
    {
        "georeferenceId": "17797",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "5.52180686682719",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.883,
        "longitude": 0.9,
        "region": null
    },
    {
        "georeferenceId": "17682",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "12.1753199042447",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.65,
        "longitude": -3.383,
        "region": null
    },
    {
        "georeferenceId": "17826",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "16.9682458071181",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.417,
        "longitude": -5.083,
        "region": null
    },
    {
        "georeferenceId": "17920",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "14.886194619019",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.45,
        "longitude": -4.45,
        "region": null
    },
    {
        "georeferenceId": "17683",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "15.2492184614652",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.5,
        "longitude": -6.883,
        "region": null
    },
    {
        "georeferenceId": "17935",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "10.1349139742532",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56,
        "longitude": -3.783,
        "region": null
    },
    {
        "georeferenceId": "17838",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "12.2859324163243",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.8,
        "longitude": -1.217,
        "region": null
    },
    {
        "georeferenceId": "17919",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "15.4739057086365",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.517,
        "longitude": -4.833,
        "region": null
    },
    {
        "georeferenceId": "17685",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "17.3986718018279",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "2",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.433,
        "longitude": -5.7,
        "region": null
    },
    {
        "georeferenceId": "17651",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "14.3685730758589",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "2",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.467,
        "longitude": -7.367,
        "region": null
    },
    {
        "georeferenceId": "17778",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "7.28693682274214",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.45,
        "longitude": -1.733,
        "region": null
    },
    {
        "georeferenceId": "17821",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "7.32328258110705",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.35,
        "longitude": 1.417,
        "region": null
    },
    {
        "georeferenceId": "17917",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "6.59890949782664",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.283,
        "longitude": 1.083,
        "region": null
    },
    {
        "georeferenceId": "17686",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "15.97659242795",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "2",
        "takeAllPatchRiskIrrigated": "2",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.233,
        "longitude": -5.083,
        "region": null
    },
    {
        "georeferenceId": "17687",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "15.8599214692406",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "2",
        "takeAllPatchRiskNonIrrigated": "2",
        "takeAllPatchRiskIrrigated": "2",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.7,
        "longitude": -5.3,
        "region": null
    },
    {
        "georeferenceId": "17800",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "15.092625050404",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.083,
        "longitude": -3.6,
        "region": null
    },
    {
        "georeferenceId": "17916",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "9.41835445413502",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.083,
        "longitude": 1.183,
        "region": null
    },
    {
        "georeferenceId": "17688",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "16.8749187876908",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.617,
        "longitude": -4.85,
        "region": null
    },
    {
        "georeferenceId": "17777",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "7.87641855339272",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.15,
        "longitude": -2.033,
        "region": null
    },
    {
        "georeferenceId": "17915",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "7.62261938146992",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.267,
        "longitude": 0.517,
        "region": null
    },
    {
        "georeferenceId": "17839",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "11.784165631492",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.833,
        "longitude": -0.283,
        "region": null
    },
    {
        "georeferenceId": "17689",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "15.7962481735405",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.85,
        "longitude": -4.95,
        "region": null
    },
    {
        "georeferenceId": "17787",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "11.0757049486256",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.567,
        "longitude": -3.983,
        "region": null
    },
    {
        "georeferenceId": "17914",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "7.39609641204593",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.2,
        "longitude": 0.183,
        "region": null
    },
    {
        "georeferenceId": "17690",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "14.4615122538599",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.467,
        "longitude": -4.617,
        "region": null
    },
    {
        "georeferenceId": "17776",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "8.62145704479211",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.117,
        "longitude": -2.3,
        "region": null
    },
    {
        "georeferenceId": "17913",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "10.2750918420214",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.483,
        "longitude": -0.6,
        "region": null
    },
    {
        "georeferenceId": "17691",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "14.3720575674052",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.95,
        "longitude": -4.767,
        "region": null
    },
    {
        "georeferenceId": "17820",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "9.18084296038329",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.133,
        "longitude": 1.3,
        "region": null
    },
    {
        "georeferenceId": "4482346",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "14.4615122538599",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.46997,
        "longitude": -4.564,
        "region": null
    },
    {
        "georeferenceId": "17693",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "13.2691091052936",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.867,
        "longitude": -4.433,
        "region": null
    },
    {
        "georeferenceId": "17840",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "10.5548159595864",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.8,
        "longitude": 0.25,
        "region": null
    },
    {
        "georeferenceId": "17912",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "13.179671263348",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.35,
        "longitude": -2.983,
        "region": null
    },
    {
        "georeferenceId": "17801",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "11.1110256761376",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.5,
        "longitude": -3.2,
        "region": null
    },
    {
        "georeferenceId": "17694",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "13.3887477347553",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.317,
        "longitude": -3.733,
        "region": null
    },
    {
        "georeferenceId": "17911",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "15.5565223051235",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.083,
        "longitude": -4.15,
        "region": null
    },
    {
        "georeferenceId": "17775",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "6.65942383443692",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.2,
        "longitude": -2.217,
        "region": null
    },
    {
        "georeferenceId": "17696",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "0",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "2",
        "poaAnnuaSeedheadsRisk": "2",
        "takeAllPatchRiskNonIrrigated": "2",
        "takeAllPatchRiskIrrigated": "2",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.417,
        "longitude": -4.317,
        "region": null
    },
    {
        "georeferenceId": "17791",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "8.89126866474714",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.617,
        "longitude": -1.083,
        "region": null
    },
    {
        "georeferenceId": "17910",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "5.70102729598444",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.933,
        "longitude": 1.283,
        "region": null
    },
    {
        "georeferenceId": "17697",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "14.9999371424568",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.8,
        "longitude": -4,
        "region": null
    },
    {
        "georeferenceId": "17831",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "13.5717107119934",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.867,
        "longitude": -3.233,
        "region": null
    },
    {
        "georeferenceId": "17653",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "13.0164965383154",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 58.217,
        "longitude": -6.317,
        "region": null
    },
    {
        "georeferenceId": "17841",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "24.6561979128012",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 49.433,
        "longitude": -2.6,
        "region": null
    },
    {
        "georeferenceId": "17909",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "7.74509124066077",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.367,
        "longitude": -1.5,
        "region": null
    },
    {
        "georeferenceId": "17698",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "11.8514777804765",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.05,
        "longitude": -3.65,
        "region": null
    },
    {
        "georeferenceId": "17699",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "11.0091678469363",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.667,
        "longitude": -3.783,
        "region": null
    },
    {
        "georeferenceId": "17818",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "6.35268254132622",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.2,
        "longitude": 0.783,
        "region": null
    },
    {
        "georeferenceId": "17774",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "8.19470733002644",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.083,
        "longitude": -2.8,
        "region": null
    },
    {
        "georeferenceId": "17908",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "6.13019073366955",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53,
        "longitude": -2.183,
        "region": null
    },
    {
        "georeferenceId": "17802",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "12.8978810023787",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.4,
        "longitude": -3.433,
        "region": null
    },
    {
        "georeferenceId": "17700",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "11.7232005299357",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.433,
        "longitude": -3.367,
        "region": null
    },
    {
        "georeferenceId": "17842",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "25.1153892062305",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 49.217,
        "longitude": -2.2,
        "region": null
    },
    {
        "georeferenceId": "17907",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "6.8773705103713",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.05,
        "longitude": -3,
        "region": null
    },
    {
        "georeferenceId": "17701",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "8.57292318875975",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.617,
        "longitude": -2.8,
        "region": null
    },
    {
        "georeferenceId": "17773",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "10.1255057973477",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.067,
        "longitude": -3.617,
        "region": null
    },
    {
        "georeferenceId": "17640",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "16.3260546807366",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "3",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 60.75,
        "longitude": -0.85,
        "region": null
    },
    {
        "georeferenceId": "17906",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "11.6465513606017",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.767,
        "longitude": -2.717,
        "region": null
    },
    {
        "georeferenceId": "17702",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "9.61927702069558",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.95,
        "longitude": -3.35,
        "region": null
    },
    {
        "georeferenceId": "17786",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "11.215654374865",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.717,
        "longitude": -4.367,
        "region": null
    },
    {
        "georeferenceId": "17904",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "9.76287415810982",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.567,
        "longitude": -2.417,
        "region": null
    },
    {
        "georeferenceId": "17703",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "10.9249544463217",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "2",
        "takeAllPatchRiskIrrigated": "2",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.317,
        "longitude": -3.2,
        "region": null
    },
    {
        "georeferenceId": "17704",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "10.1449206756409",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.6,
        "longitude": -3.55,
        "region": null
    },
    {
        "georeferenceId": "4482345",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "4.72123169215742",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.03516,
        "longitude": 0.09752,
        "region": null
    },
    {
        "georeferenceId": "17903",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "11.7087478576028",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.067,
        "longitude": -2.833,
        "region": null
    },
    {
        "georeferenceId": "17772",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "10.2224401193588",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.35,
        "longitude": -3.95,
        "region": null
    },
    {
        "georeferenceId": "17805",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "9.94546940640702",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.5,
        "longitude": -1.983,
        "region": null
    },
    {
        "georeferenceId": "17705",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "9.04267154688251",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.983,
        "longitude": -2.433,
        "region": null
    },
    {
        "georeferenceId": "17901",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "8.82675200715139",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.467,
        "longitude": -2.567,
        "region": null
    },
    {
        "georeferenceId": "17843",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "13.8901452647798",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.433,
        "longitude": -8.05,
        "region": null
    },
    {
        "georeferenceId": "17706",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "10.5370448954394",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.383,
        "longitude": -2.867,
        "region": null
    },
    {
        "georeferenceId": "17827",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "14.7086786841033",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.467,
        "longitude": -4.717,
        "region": null
    },
    {
        "georeferenceId": "17900",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "10.5344461020977",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.9,
        "longitude": -3.633,
        "region": null
    },
    {
        "georeferenceId": "17707",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "9.31860146023746",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.433,
        "longitude": -2.783,
        "region": null
    },
    {
        "georeferenceId": "17933",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "10.5292836041193",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.317,
        "longitude": 0.033,
        "region": null
    },
    {
        "georeferenceId": "17660",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "0",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "4",
        "poaAnnuaSeedheadsRisk": "2",
        "takeAllPatchRiskNonIrrigated": "2",
        "takeAllPatchRiskIrrigated": "2",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.817,
        "longitude": -4.967,
        "region": null
    },
    {
        "georeferenceId": "17654",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "13.9597338062463",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.417,
        "longitude": -6.2,
        "region": null
    },
    {
        "georeferenceId": "17817",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "2",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "6.41637509853837",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.433,
        "longitude": 0.367,
        "region": null
    },
    {
        "georeferenceId": "17899",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "8.93962642836587",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.6,
        "longitude": -2.433,
        "region": null
    },
    {
        "georeferenceId": "17708",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "17.6733967858084",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.083,
        "longitude": -4.633,
        "region": null
    },
    {
        "georeferenceId": "17771",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "9.29027571524083",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.133,
        "longitude": -4.567,
        "region": null
    },
    {
        "georeferenceId": "17710",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "10.6611253335713",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.933,
        "longitude": -3.15,
        "region": null
    },
    {
        "georeferenceId": "17898",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "12.0196087191594",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.783,
        "longitude": -4,
        "region": null
    },
    {
        "georeferenceId": "17844",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "13.8150817312604",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.4,
        "longitude": -7.65,
        "region": null
    },
    {
        "georeferenceId": "17806",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "10.6758758239497",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.2,
        "longitude": -1.8,
        "region": null
    },
    {
        "georeferenceId": "17711",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "14.3875892315888",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.517,
        "longitude": -3.6,
        "region": null
    },
    {
        "georeferenceId": "17897",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "11.518137840982",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.767,
        "longitude": -4.167,
        "region": null
    },
    {
        "georeferenceId": "17712",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "12.4400316224219",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.617,
        "longitude": -3.167,
        "region": null
    },
    {
        "georeferenceId": "17770",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "6.59470022347176",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.683,
        "longitude": 1.683,
        "region": null
    },
    {
        "georeferenceId": "17832",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "12.2938601011263",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.95,
        "longitude": -2.633,
        "region": null
    },
    {
        "georeferenceId": "17896",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "14.7995279565788",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.6,
        "longitude": -4.5,
        "region": null
    },
    {
        "georeferenceId": "17713",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "14.7247248508101",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.317,
        "longitude": -3.4,
        "region": null
    },
    {
        "georeferenceId": "17845",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "13.5874641142178",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.6,
        "longitude": -7.3,
        "region": null
    },
    {
        "georeferenceId": "17895",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "15.2602841750245",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.417,
        "longitude": -5.467,
        "region": null
    },
    {
        "georeferenceId": "17714",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "14.5182489171807",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.117,
        "longitude": -3.217,
        "region": null
    },
    {
        "georeferenceId": "17816",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "11.3165092674337",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.3,
        "longitude": -0.083,
        "region": null
    },
    {
        "georeferenceId": "17936",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "10.3276112158502",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.117,
        "longitude": -3.15,
        "region": null
    },
    {
        "georeferenceId": "17715",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "13.080904181636",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.767,
        "longitude": -3.317,
        "region": null
    },
    {
        "georeferenceId": "17894",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "16.4442058792272",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.633,
        "longitude": -6.183,
        "region": null
    },
    {
        "georeferenceId": "17808",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "11.1140166412856",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.15,
        "longitude": -1.567,
        "region": null
    },
    {
        "georeferenceId": "17717",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "12.6743465225867",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.933,
        "longitude": -2.95,
        "region": null
    },
    {
        "georeferenceId": "4482344",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "12.1400398566345",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.68495,
        "longitude": -2.76495,
        "region": null
    },
    {
        "georeferenceId": "17893",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "14.1846931217134",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "2",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.683,
        "longitude": -2.017,
        "region": null
    },
    {
        "georeferenceId": "17847",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "11.0933456444655",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.067,
        "longitude": -7.017,
        "region": null
    },
    {
        "georeferenceId": "17718",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "10.6633820763475",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.05,
        "longitude": -2.55,
        "region": null
    },
    {
        "georeferenceId": "17825",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "19.504761606086",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.15,
        "longitude": -5.067,
        "region": null
    },
    {
        "georeferenceId": "17891",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "14.8391294534845",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 58.033,
        "longitude": -4.4,
        "region": null
    },
    {
        "georeferenceId": "17719",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "12.1868841433301",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.5,
        "longitude": -2.683,
        "region": null
    },
    {
        "georeferenceId": "17785",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "17.4664079197875",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.7,
        "longitude": -5.05,
        "region": null
    },
    {
        "georeferenceId": "17655",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "12.2281487583912",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "2",
        "takeAllPatchRiskNonIrrigated": "2",
        "takeAllPatchRiskIrrigated": "2",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.717,
        "longitude": -4.883,
        "region": null
    },
    {
        "georeferenceId": "17768",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "7.49267636924769",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.633,
        "longitude": 1.3,
        "region": null
    },
    {
        "georeferenceId": "17886",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "2",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "6.29552372203802",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.567,
        "longitude": 0.7,
        "region": null
    },
    {
        "georeferenceId": "17721",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "10.912266828454",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "2",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "2",
        "takeAllPatchRiskIrrigated": "2",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.683,
        "longitude": -2.45,
        "region": null
    },
    {
        "georeferenceId": "17813",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "9.32957918166886",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.15,
        "longitude": -0.183,
        "region": null
    },
    {
        "georeferenceId": "17722",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "9.14635554887077",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.283,
        "longitude": -2.283,
        "region": null
    },
    {
        "georeferenceId": "17885",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "11.3258413278678",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.05,
        "longitude": -7.15,
        "region": null
    },
    {
        "georeferenceId": "17809",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "8.47238717550842",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.233,
        "longitude": -0.95,
        "region": null
    },
    {
        "georeferenceId": "17723",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "6.05917022188812",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.817,
        "longitude": -2.083,
        "region": null
    },
    {
        "georeferenceId": "17767",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "8.65178353842729",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.95,
        "longitude": 1.133,
        "region": null
    },
    {
        "georeferenceId": "17884",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "8.20540580463582",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.25,
        "longitude": -0.883,
        "region": null
    },
    {
        "georeferenceId": "17848",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "14.227136329013",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.717,
        "longitude": -6.817,
        "region": null
    },
    {
        "georeferenceId": "17724",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "7.24235589089861",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.417,
        "longitude": -1.6,
        "region": null
    },
    {
        "georeferenceId": "17882",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "25.040830158599",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 49.717,
        "longitude": -2.2,
        "region": null
    },
    {
        "georeferenceId": "17725",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "5.53342834807692",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.917,
        "longitude": -1.383,
        "region": null
    },
    {
        "georeferenceId": "17766",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "7.92314372829522",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.65,
        "longitude": 0.567,
        "region": null
    },
    {
        "georeferenceId": "17792",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "9.48585927911785",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.683,
        "longitude": -0.8,
        "region": null
    },
    {
        "georeferenceId": "17881",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "8.70943040782882",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.5,
        "longitude": 0.05,
        "region": null
    },
    {
        "georeferenceId": "17726",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "5.45586748045325",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.3,
        "longitude": -1.533,
        "region": null
    },
    {
        "georeferenceId": "17849",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "12.4951082718722",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.517,
        "longitude": -6.75,
        "region": null
    },
    {
        "georeferenceId": "17811",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "8.41625843954481",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.283,
        "longitude": -0.767,
        "region": null
    },
    {
        "georeferenceId": "17727",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "6.31704906222306",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.133,
        "longitude": -1.417,
        "region": null
    },
    {
        "georeferenceId": "17810",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "8.69332505835603",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.433,
        "longitude": -1,
        "region": null
    },
    {
        "georeferenceId": "17880",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "17.0451268121697",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.417,
        "longitude": -4.117,
        "region": null
    },
    {
        "georeferenceId": "17642",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "11.8148869253882",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "3",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 60.133,
        "longitude": -1.183,
        "region": null
    },
    {
        "georeferenceId": "17728",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "5.37951840908515",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.2,
        "longitude": -1.383,
        "region": null
    },
    {
        "georeferenceId": "17879",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "7.97364457100886",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.867,
        "longitude": -0.367,
        "region": null
    },
    {
        "georeferenceId": "17765",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "5.43855064746764",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.867,
        "longitude": 0.15,
        "region": null
    },
    {
        "georeferenceId": "17729",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "6.30510524954539",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.05,
        "longitude": -1.25,
        "region": null
    },
    {
        "georeferenceId": "17834",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "11.2515542686005",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.783,
        "longitude": -1.833,
        "region": null
    },
    {
        "georeferenceId": "17666",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "12.5987648096765",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.683,
        "longitude": -4.167,
        "region": null
    },
    {
        "georeferenceId": "17878",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "10.864195815989",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.333,
        "longitude": -2.85,
        "region": null
    },
    {
        "georeferenceId": "17730",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "5.24003687364737",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.683,
        "longitude": -1.217,
        "region": null
    },
    {
        "georeferenceId": "17656",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "12.2493232016326",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.867,
        "longitude": -5.633,
        "region": null
    },
    {
        "georeferenceId": "17850",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "11.8822765623468",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.2,
        "longitude": -6.65,
        "region": null
    },
    {
        "georeferenceId": "17877",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "13.2680296184909",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.383,
        "longitude": -2.717,
        "region": null
    },
    {
        "georeferenceId": "17731",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "4.73437892660246",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.367,
        "longitude": -0.667,
        "region": null
    },
    {
        "georeferenceId": "17790",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "9.34013078419217",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.75,
        "longitude": -1.583,
        "region": null
    },
    {
        "georeferenceId": "17732",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "4.31857602044598",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.483,
        "longitude": -0.6,
        "region": null
    },
    {
        "georeferenceId": "17764",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "6.24910465069557",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.617,
        "longitude": -0.467,
        "region": null
    },
    {
        "georeferenceId": "17876",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "7.88107136593923",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.867,
        "longitude": -2.25,
        "region": null
    },
    {
        "georeferenceId": "17733",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "5.09788331807595",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.1,
        "longitude": -0.167,
        "region": null
    },
    {
        "georeferenceId": "17932",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "10.1824223433724",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.75,
        "longitude": -2.483,
        "region": null
    },
    {
        "georeferenceId": "17851",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "11.7147350709557",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.867,
        "longitude": -6.45,
        "region": null
    },
    {
        "georeferenceId": "17874",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "5.9031273239597",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.833,
        "longitude": -1.333,
        "region": null
    },
    {
        "georeferenceId": "17734",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "14.5602590817123",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.25,
        "longitude": -4.533,
        "region": null
    },
    {
        "georeferenceId": "17763",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "6.10675438221202",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.733,
        "longitude": -0.65,
        "region": null
    },
    {
        "georeferenceId": "17873",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "6.41159483661898",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.533,
        "longitude": -1.567,
        "region": null
    },
    {
        "georeferenceId": "17735",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "12.9023112907841",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.1,
        "longitude": -3.933,
        "region": null
    },
    {
        "georeferenceId": "17667",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "11.9317345603883",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.533,
        "longitude": -4.05,
        "region": null
    },
    {
        "georeferenceId": "4482343",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "6.76387797920178",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.39455,
        "longitude": -0.78437,
        "region": null
    },
    {
        "georeferenceId": "17872",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "6.38540167060671",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.033,
        "longitude": -1.7,
        "region": null
    },
    {
        "georeferenceId": "17736",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-06",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "9.25430606939474",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.217,
        "longitude": -3.5,
        "region": null
    },
    {
        "georeferenceId": "17837",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "12.6338058976744",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.817,
        "longitude": -0.917,
        "region": null
    },
    {
        "georeferenceId": "17728",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "5.94375023726691",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.2,
        "longitude": -1.383,
        "region": null
    },
    {
        "georeferenceId": "17708",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "20.1172696157181",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.083,
        "longitude": -4.633,
        "region": null
    },
    {
        "georeferenceId": "17830",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "15.5732940971415",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.717,
        "longitude": -3.533,
        "region": null
    },
    {
        "georeferenceId": "17792",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "11.7747930924789",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.683,
        "longitude": -0.8,
        "region": null
    },
    {
        "georeferenceId": "17654",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "16.1581417907141",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.417,
        "longitude": -6.2,
        "region": null
    },
    {
        "georeferenceId": "17680",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "17.3978763747687",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "2",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.2,
        "longitude": -2.217,
        "region": null
    },
    {
        "georeferenceId": "17922",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "16.5169377967096",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.6,
        "longitude": -2.45,
        "region": null
    },
    {
        "georeferenceId": "17679",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "16.9729361107372",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.717,
        "longitude": -2.467,
        "region": null
    },
    {
        "georeferenceId": "17898",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "14.6387126646956",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.783,
        "longitude": -4,
        "region": null
    },
    {
        "georeferenceId": "17923",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "15.2518187321106",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.067,
        "longitude": -1.317,
        "region": null
    },
    {
        "georeferenceId": "17765",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "6.70209942997441",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.867,
        "longitude": 0.15,
        "region": null
    },
    {
        "georeferenceId": "17710",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "11.5926137192877",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.933,
        "longitude": -3.15,
        "region": null
    },
    {
        "georeferenceId": "17665",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "13.6594843865604",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.267,
        "longitude": -4.483,
        "region": null
    },
    {
        "georeferenceId": "17678",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "15.822330974403",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "2",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.083,
        "longitude": -2.833,
        "region": null
    },
    {
        "georeferenceId": "17781",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "9.7418672332962",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.217,
        "longitude": -0.483,
        "region": null
    },
    {
        "georeferenceId": "4482343",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "7.40531628606154",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.39455,
        "longitude": -0.78437,
        "region": null
    },
    {
        "georeferenceId": "17924",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "2",
        "dollarSpotRiskIrrigated": "3",
        "dollarSpotSmithModelRisk": "13.3544097580277",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.867,
        "longitude": 0.6,
        "region": null
    },
    {
        "georeferenceId": "17879",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "9.33681650249984",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.867,
        "longitude": -0.367,
        "region": null
    },
    {
        "georeferenceId": "17739",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "13.1415922637162",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.767,
        "longitude": -3.033,
        "region": null
    },
    {
        "georeferenceId": "17836",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "21.9680129926988",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.583,
        "longitude": -1.3,
        "region": null
    },
    {
        "georeferenceId": "17866",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "14.3657671569122",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.133,
        "longitude": -3.017,
        "region": null
    },
    {
        "georeferenceId": "17711",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "16.9960669388767",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.517,
        "longitude": -3.6,
        "region": null
    },
    {
        "georeferenceId": "17806",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "12.3961439514564",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.2,
        "longitude": -1.8,
        "region": null
    },
    {
        "georeferenceId": "17844",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "17.4695486617675",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.4,
        "longitude": -7.65,
        "region": null
    },
    {
        "georeferenceId": "17794",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "9.78502132834257",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.883,
        "longitude": 0.233,
        "region": null
    },
    {
        "georeferenceId": "17897",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "13.5239756480351",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.767,
        "longitude": -4.167,
        "region": null
    },
    {
        "georeferenceId": "17763",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "6.91871776098761",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.733,
        "longitude": -0.65,
        "region": null
    },
    {
        "georeferenceId": "17676",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "21.2823357993362",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "3",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 58.45,
        "longitude": -3.083,
        "region": null
    },
    {
        "georeferenceId": "17770",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "2",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "9.23630067719423",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.683,
        "longitude": 1.683,
        "region": null
    },
    {
        "georeferenceId": "17823",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "18.2073577820817",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.217,
        "longitude": -5.317,
        "region": null
    },
    {
        "georeferenceId": "17760",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "10.7087805741057",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.75,
        "longitude": -3.467,
        "region": null
    },
    {
        "georeferenceId": "17712",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "13.619186292719",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.617,
        "longitude": -3.167,
        "region": null
    },
    {
        "georeferenceId": "17825",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "24.0120040633136",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.15,
        "longitude": -5.067,
        "region": null
    },
    {
        "georeferenceId": "17852",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "16.9233913020589",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.183,
        "longitude": -6.167,
        "region": null
    },
    {
        "georeferenceId": "17675",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "18.8889826212178",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 58.617,
        "longitude": -3.55,
        "region": null
    },
    {
        "georeferenceId": "17729",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "6.94030695099573",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.05,
        "longitude": -1.25,
        "region": null
    },
    {
        "georeferenceId": "17896",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "17.5557860910706",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.6,
        "longitude": -4.5,
        "region": null
    },
    {
        "georeferenceId": "17667",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "14.7183429562139",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.533,
        "longitude": -4.05,
        "region": null
    },
    {
        "georeferenceId": "17925",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "17.443932003538",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.817,
        "longitude": -7.467,
        "region": null
    },
    {
        "georeferenceId": "17649",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "20.3795384294041",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "3",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 58.95,
        "longitude": -2.9,
        "region": null
    },
    {
        "georeferenceId": "17663",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "15.5052737969196",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "2",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.867,
        "longitude": -4.7,
        "region": null
    },
    {
        "georeferenceId": "17713",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "16.6148509500955",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.317,
        "longitude": -3.4,
        "region": null
    },
    {
        "georeferenceId": "17674",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "0",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "2",
        "poaAnnuaSeedheadsRisk": "4",
        "takeAllPatchRiskNonIrrigated": "2",
        "takeAllPatchRiskIrrigated": "2",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.883,
        "longitude": -3.417,
        "region": null
    },
    {
        "georeferenceId": "17926",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "16.3251279330833",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.717,
        "longitude": -6.517,
        "region": null
    },
    {
        "georeferenceId": "17743",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "9.49644550412933",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.817,
        "longitude": -1.867,
        "region": null
    },
    {
        "georeferenceId": "17845",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "17.8716762205437",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.6,
        "longitude": -7.3,
        "region": null
    },
    {
        "georeferenceId": "17816",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "12.1070209040729",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.3,
        "longitude": -0.083,
        "region": null
    },
    {
        "georeferenceId": "17895",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "17.3175056944899",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.417,
        "longitude": -5.467,
        "region": null
    },
    {
        "georeferenceId": "17734",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "17.8677443334771",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.25,
        "longitude": -4.533,
        "region": null
    },
    {
        "georeferenceId": "17782",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "2",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "12.3517352976829",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.35,
        "longitude": 0.517,
        "region": null
    },
    {
        "georeferenceId": "17878",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "2",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "11.8698354582795",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.333,
        "longitude": -2.85,
        "region": null
    },
    {
        "georeferenceId": "17714",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "16.156448887743",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.117,
        "longitude": -3.217,
        "region": null
    },
    {
        "georeferenceId": "17870",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "2",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "8.63663486727784",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.783,
        "longitude": -1.75,
        "region": null
    },
    {
        "georeferenceId": "17853",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "14.3815962686963",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.65,
        "longitude": -6.217,
        "region": null
    },
    {
        "georeferenceId": "17673",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "16.0284631598927",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.35,
        "longitude": -3.35,
        "region": null
    },
    {
        "georeferenceId": "17927",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "18.9470992153615",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.183,
        "longitude": -6.333,
        "region": null
    },
    {
        "georeferenceId": "17769",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "2",
        "brownPatchRiskIrrigated": "2",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "7.97463788683649",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.8,
        "longitude": 1.25,
        "region": null
    },
    {
        "georeferenceId": "17832",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "13.6957057218389",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.95,
        "longitude": -2.633,
        "region": null
    },
    {
        "georeferenceId": "17894",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "19.0601565526006",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.633,
        "longitude": -6.183,
        "region": null
    },
    {
        "georeferenceId": "17835",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "9.78287789308129",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.9,
        "longitude": -1.4,
        "region": null
    },
    {
        "georeferenceId": "17834",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "13.0841449871472",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.783,
        "longitude": -1.833,
        "region": null
    },
    {
        "georeferenceId": "17715",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "14.5094456589484",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.767,
        "longitude": -3.317,
        "region": null
    },
    {
        "georeferenceId": "17672",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "18.1687951810808",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.717,
        "longitude": -3.317,
        "region": null
    },
    {
        "georeferenceId": "17856",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "13.4788056591608",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.6,
        "longitude": -5.883,
        "region": null
    },
    {
        "georeferenceId": "4482344",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "13.104880925459",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.68495,
        "longitude": -2.76495,
        "region": null
    },
    {
        "georeferenceId": "17872",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "7.47466160765213",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.033,
        "longitude": -1.7,
        "region": null
    },
    {
        "georeferenceId": "17756",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "15.1118702190119",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53,
        "longitude": -4.083,
        "region": null
    },
    {
        "georeferenceId": "17857",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "15.4913655495348",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.667,
        "longitude": -5.667,
        "region": null
    },
    {
        "georeferenceId": "17730",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "5.98513898340134",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.683,
        "longitude": -1.217,
        "region": null
    },
    {
        "georeferenceId": "17754",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "6.89612092346936",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.083,
        "longitude": 0.267,
        "region": null
    },
    {
        "georeferenceId": "17742",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "9.42833314429859",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.967,
        "longitude": -2.033,
        "region": null
    },
    {
        "georeferenceId": "17717",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "14.1046394883646",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.933,
        "longitude": -2.95,
        "region": null
    },
    {
        "georeferenceId": "17808",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "12.737776653628",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.15,
        "longitude": -1.567,
        "region": null
    },
    {
        "georeferenceId": "17858",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "14.4455523963503",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.85,
        "longitude": -5.8,
        "region": null
    },
    {
        "georeferenceId": "17867",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "7.7996370776445",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.2,
        "longitude": -1.7,
        "region": null
    },
    {
        "georeferenceId": "17893",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "19.2067210017247",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "2",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.683,
        "longitude": -2.017,
        "region": null
    },
    {
        "georeferenceId": "4482348",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "7.155779714166",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.65863,
        "longitude": -0.49627,
        "region": null
    },
    {
        "georeferenceId": "17757",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "20.8149383477546",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.783,
        "longitude": -4.733,
        "region": null
    },
    {
        "georeferenceId": "17931",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "14.2960216215867",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.067,
        "longitude": -3.467,
        "region": null
    },
    {
        "georeferenceId": "17929",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "16.4110015993371",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 58.283,
        "longitude": -4.433,
        "region": null
    },
    {
        "georeferenceId": "17666",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "16.0107982494113",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.683,
        "longitude": -4.167,
        "region": null
    },
    {
        "georeferenceId": "17718",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "11.8141344776062",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.05,
        "longitude": -2.55,
        "region": null
    },
    {
        "georeferenceId": "17877",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "14.2428159904751",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.383,
        "longitude": -2.717,
        "region": null
    },
    {
        "georeferenceId": "17936",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "13.1110217936656",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.117,
        "longitude": -3.15,
        "region": null
    },
    {
        "georeferenceId": "17753",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "5.84158326757853",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.083,
        "longitude": -0.167,
        "region": null
    },
    {
        "georeferenceId": "17785",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "19.2913846198804",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.7,
        "longitude": -5.05,
        "region": null
    },
    {
        "georeferenceId": "17891",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "18.6889112704819",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 58.033,
        "longitude": -4.4,
        "region": null
    },
    {
        "georeferenceId": "17873",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "7.77073090418034",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.533,
        "longitude": -1.567,
        "region": null
    },
    {
        "georeferenceId": "4482347",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "6.94193786091347",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.64729,
        "longitude": -0.39027,
        "region": null
    },
    {
        "georeferenceId": "51522",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "2",
        "brownPatchRiskIrrigated": "2",
        "dollarSpotRiskIrrigated": "3",
        "dollarSpotSmithModelRisk": "7.83862777057078",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.817,
        "longitude": 1.133,
        "region": null
    },
    {
        "georeferenceId": "17790",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "10.8449262355918",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.75,
        "longitude": -1.583,
        "region": null
    },
    {
        "georeferenceId": "17719",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "13.0262547115009",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.5,
        "longitude": -2.683,
        "region": null
    },
    {
        "georeferenceId": "17871",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "7.16353319643193",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.583,
        "longitude": -0.35,
        "region": null
    },
    {
        "georeferenceId": "17768",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "2",
        "brownPatchRiskIrrigated": "2",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "10.1595791792981",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.633,
        "longitude": 1.3,
        "region": null
    },
    {
        "georeferenceId": "17731",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "5.72903191739739",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.367,
        "longitude": -0.667,
        "region": null
    },
    {
        "georeferenceId": "17788",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "12.7807468359466",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.517,
        "longitude": -2.583,
        "region": null
    },
    {
        "georeferenceId": "17793",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "2",
        "brownPatchRiskIrrigated": "3",
        "dollarSpotRiskIrrigated": "3",
        "dollarSpotSmithModelRisk": "10.0676920993434",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.533,
        "longitude": -0.417,
        "region": null
    },
    {
        "georeferenceId": "17886",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "2",
        "brownPatchRiskIrrigated": "3",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "7.73036647140275",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.567,
        "longitude": 0.7,
        "region": null
    },
    {
        "georeferenceId": "17850",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "15.9026109029411",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.2,
        "longitude": -6.65,
        "region": null
    },
    {
        "georeferenceId": "17752",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "5.71739094996307",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.483,
        "longitude": -0.083,
        "region": null
    },
    {
        "georeferenceId": "17656",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "13.586791507156",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.867,
        "longitude": -5.633,
        "region": null
    },
    {
        "georeferenceId": "17721",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "12.3493905964139",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.683,
        "longitude": -2.45,
        "region": null
    },
    {
        "georeferenceId": "17813",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "2",
        "dollarSpotRiskIrrigated": "3",
        "dollarSpotSmithModelRisk": "10.5323607921305",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.15,
        "longitude": -0.183,
        "region": null
    },
    {
        "georeferenceId": "17859",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "8.26403061783242",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.867,
        "longitude": -1.65,
        "region": null
    },
    {
        "georeferenceId": "17655",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "13.8136854442205",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "2",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.717,
        "longitude": -4.883,
        "region": null
    },
    {
        "georeferenceId": "17671",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "18.1566068130432",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.65,
        "longitude": -3.567,
        "region": null
    },
    {
        "georeferenceId": "17847",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "14.4190272289162",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.067,
        "longitude": -7.017,
        "region": null
    },
    {
        "georeferenceId": "17657",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "17.3043810054457",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "2",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.033,
        "longitude": -7.45,
        "region": null
    },
    {
        "georeferenceId": "17885",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "15.3055533497188",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.05,
        "longitude": -7.15,
        "region": null
    },
    {
        "georeferenceId": "17855",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "17.7177099762557",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.35,
        "longitude": -6.667,
        "region": null
    },
    {
        "georeferenceId": "17764",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "7.155779714166",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.617,
        "longitude": -0.467,
        "region": null
    },
    {
        "georeferenceId": "17722",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "10.6492415637081",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.283,
        "longitude": -2.283,
        "region": null
    },
    {
        "georeferenceId": "17658",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "14.2445125920747",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.25,
        "longitude": -5.8,
        "region": null
    },
    {
        "georeferenceId": "17751",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "6.28285432808954",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.867,
        "longitude": -0.433,
        "region": null
    },
    {
        "georeferenceId": "17860",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "7.71615223097657",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.567,
        "longitude": -1.167,
        "region": null
    },
    {
        "georeferenceId": "17784",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "2",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "8.65190977471122",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.117,
        "longitude": 0.967,
        "region": null
    },
    {
        "georeferenceId": "17758",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "16.3163802869144",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.8,
        "longitude": -4.117,
        "region": null
    },
    {
        "georeferenceId": "17767",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "2",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "10.601444937031",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.95,
        "longitude": 1.133,
        "region": null
    },
    {
        "georeferenceId": "17876",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "8.99346019763419",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.867,
        "longitude": -2.25,
        "region": null
    },
    {
        "georeferenceId": "17750",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "6.65641676222733",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.033,
        "longitude": -0.5,
        "region": null
    },
    {
        "georeferenceId": "17668",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "16.0277491571022",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.817,
        "longitude": -3.967,
        "region": null
    },
    {
        "georeferenceId": "17723",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "6.80531288198266",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.817,
        "longitude": -2.083,
        "region": null
    },
    {
        "georeferenceId": "17809",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "10.0416285987488",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.233,
        "longitude": -0.95,
        "region": null
    },
    {
        "georeferenceId": "17738",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "3",
        "dollarSpotSmithModelRisk": "11.6766116129604",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.5,
        "longitude": -3.067,
        "region": null
    },
    {
        "georeferenceId": "17861",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "6.75318168390331",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.367,
        "longitude": -1.483,
        "region": null
    },
    {
        "georeferenceId": "17884",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "9.64115958920534",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.25,
        "longitude": -0.883,
        "region": null
    },
    {
        "georeferenceId": "17732",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "5.31709327266631",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.483,
        "longitude": -0.6,
        "region": null
    },
    {
        "georeferenceId": "17801",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "12.8707277534267",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.5,
        "longitude": -3.2,
        "region": null
    },
    {
        "georeferenceId": "17642",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "13.6545441055834",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "3",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 60.133,
        "longitude": -1.183,
        "region": null
    },
    {
        "georeferenceId": "17775",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "7.49128850939709",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.2,
        "longitude": -2.217,
        "region": null
    },
    {
        "georeferenceId": "17911",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "15.9324110954893",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.083,
        "longitude": -4.15,
        "region": null
    },
    {
        "georeferenceId": "17749",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "6.16757103412596",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.167,
        "longitude": -0.517,
        "region": null
    },
    {
        "georeferenceId": "17694",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "17.8037807360891",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.317,
        "longitude": -3.733,
        "region": null
    },
    {
        "georeferenceId": "17640",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "16.6192400854096",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "3",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 60.75,
        "longitude": -0.85,
        "region": null
    },
    {
        "georeferenceId": "17840",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "2",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "12.5953644512423",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.8,
        "longitude": 0.25,
        "region": null
    },
    {
        "georeferenceId": "17862",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "8.96553492557966",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.9,
        "longitude": -2.083,
        "region": null
    },
    {
        "georeferenceId": "17696",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "14.6932359513527",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "2",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.417,
        "longitude": -4.317,
        "region": null
    },
    {
        "georeferenceId": "17737",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "9.40170044878291",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.217,
        "longitude": -3.217,
        "region": null
    },
    {
        "georeferenceId": "17693",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "16.3623066795059",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.867,
        "longitude": -4.433,
        "region": null
    },
    {
        "georeferenceId": "17912",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "14.0755735796902",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.35,
        "longitude": -2.983,
        "region": null
    },
    {
        "georeferenceId": "17691",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "16.7386105568113",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.95,
        "longitude": -4.767,
        "region": null
    },
    {
        "georeferenceId": "17910",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "2",
        "brownPatchRiskIrrigated": "2",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "7.70601842819912",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.933,
        "longitude": 1.283,
        "region": null
    },
    {
        "georeferenceId": "17724",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "9.38939144357002",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.417,
        "longitude": -1.6,
        "region": null
    },
    {
        "georeferenceId": "17913",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "12.1816472446848",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.483,
        "longitude": -0.6,
        "region": null
    },
    {
        "georeferenceId": "17820",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "2",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "12.0312729231937",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.133,
        "longitude": 1.3,
        "region": null
    },
    {
        "georeferenceId": "17748",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "7.40531628606154",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.4,
        "longitude": -0.767,
        "region": null
    },
    {
        "georeferenceId": "17697",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "18.5851453829126",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.8,
        "longitude": -4,
        "region": null
    },
    {
        "georeferenceId": "17848",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "19.7421872405925",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.717,
        "longitude": -6.817,
        "region": null
    },
    {
        "georeferenceId": "4482346",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "16.7743719099109",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.46997,
        "longitude": -4.564,
        "region": null
    },
    {
        "georeferenceId": "17759",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "10.4407418281511",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.95,
        "longitude": -3.417,
        "region": null
    },
    {
        "georeferenceId": "17783",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "2",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "8.67027936138183",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.25,
        "longitude": 0.7,
        "region": null
    },
    {
        "georeferenceId": "17831",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "15.0220462401047",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.867,
        "longitude": -3.233,
        "region": null
    },
    {
        "georeferenceId": "17909",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "8.38736413526072",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.367,
        "longitude": -1.5,
        "region": null
    },
    {
        "georeferenceId": "17882",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "23.5431679885563",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 49.717,
        "longitude": -2.2,
        "region": null
    },
    {
        "georeferenceId": "17776",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "10.6177622069626",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.117,
        "longitude": -2.3,
        "region": null
    },
    {
        "georeferenceId": "17741",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "2",
        "dollarSpotRiskIrrigated": "3",
        "dollarSpotSmithModelRisk": "9.71950354361393",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.5,
        "longitude": -2.217,
        "region": null
    },
    {
        "georeferenceId": "17698",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "14.6003633912802",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.05,
        "longitude": -3.65,
        "region": null
    },
    {
        "georeferenceId": "17791",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "10.7337389534659",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.617,
        "longitude": -1.083,
        "region": null
    },
    {
        "georeferenceId": "17841",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "24.3748724741746",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "2",
        "takeAllPatchRiskIrrigated": "2",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 49.433,
        "longitude": -2.6,
        "region": null
    },
    {
        "georeferenceId": "17653",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "15.7324716952033",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 58.217,
        "longitude": -6.317,
        "region": null
    },
    {
        "georeferenceId": "17690",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "16.7743719099109",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.467,
        "longitude": -4.617,
        "region": null
    },
    {
        "georeferenceId": "17774",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "9.96051859861418",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.083,
        "longitude": -2.8,
        "region": null
    },
    {
        "georeferenceId": "17818",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "2",
        "dollarSpotRiskIrrigated": "3",
        "dollarSpotSmithModelRisk": "7.68029301799363",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.2,
        "longitude": 0.783,
        "region": null
    },
    {
        "georeferenceId": "17908",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "6.71399254956126",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53,
        "longitude": -2.183,
        "region": null
    },
    {
        "georeferenceId": "17914",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "8.65212880050897",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.2,
        "longitude": 0.183,
        "region": null
    },
    {
        "georeferenceId": "17747",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "7.66979634808855",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.833,
        "longitude": -1.2,
        "region": null
    },
    {
        "georeferenceId": "17699",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "14.2460802215488",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.667,
        "longitude": -3.783,
        "region": null
    },
    {
        "georeferenceId": "17863",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "13.2785608300908",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.383,
        "longitude": -2.35,
        "region": null
    },
    {
        "georeferenceId": "17735",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "15.0179735556817",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.1,
        "longitude": -3.933,
        "region": null
    },
    {
        "georeferenceId": "17839",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "14.2132938236048",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.833,
        "longitude": -0.283,
        "region": null
    },
    {
        "georeferenceId": "17725",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "6.99763098531211",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.917,
        "longitude": -1.383,
        "region": null
    },
    {
        "georeferenceId": "17689",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "19.6771921402653",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.85,
        "longitude": -4.95,
        "region": null
    },
    {
        "georeferenceId": "17915",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "2",
        "brownPatchRiskIrrigated": "3",
        "dollarSpotRiskIrrigated": "3",
        "dollarSpotSmithModelRisk": "9.16431968560997",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.267,
        "longitude": 0.517,
        "region": null
    },
    {
        "georeferenceId": "17854",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "16.1067835302968",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.483,
        "longitude": -6.1,
        "region": null
    },
    {
        "georeferenceId": "17827",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "16.7043170262888",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.467,
        "longitude": -4.717,
        "region": null
    },
    {
        "georeferenceId": "17766",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "2",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "9.17563976655705",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.65,
        "longitude": 0.567,
        "region": null
    },
    {
        "georeferenceId": "17700",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "16.7700288382833",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.433,
        "longitude": -3.367,
        "region": null
    },
    {
        "georeferenceId": "17802",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "14.247416175497",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.4,
        "longitude": -3.433,
        "region": null
    },
    {
        "georeferenceId": "17688",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "19.7383523466875",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.617,
        "longitude": -4.85,
        "region": null
    },
    {
        "georeferenceId": "17669",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "12.453368134442",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "2",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.2,
        "longitude": -3.833,
        "region": null
    },
    {
        "georeferenceId": "17907",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "7.81925145803903",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.05,
        "longitude": -3,
        "region": null
    },
    {
        "georeferenceId": "17868",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "13.3347011741377",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "3",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 60.433,
        "longitude": -1.3,
        "region": null
    },
    {
        "georeferenceId": "17800",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "16.2502709254098",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.083,
        "longitude": -3.6,
        "region": null
    },
    {
        "georeferenceId": "17773",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "11.7754003021817",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.067,
        "longitude": -3.617,
        "region": null
    },
    {
        "georeferenceId": "17777",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "9.53347676968234",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.15,
        "longitude": -2.033,
        "region": null
    },
    {
        "georeferenceId": "17761",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "14.3697744446828",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.567,
        "longitude": -3.717,
        "region": null
    },
    {
        "georeferenceId": "17701",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "11.0009071245803",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.617,
        "longitude": -2.8,
        "region": null
    },
    {
        "georeferenceId": "17789",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "10.119012096919",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.867,
        "longitude": -1.683,
        "region": null
    },
    {
        "georeferenceId": "17881",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "2",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "3",
        "dollarSpotSmithModelRisk": "9.36436081972742",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.5,
        "longitude": 0.05,
        "region": null
    },
    {
        "georeferenceId": "17932",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "10.8717669801511",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.75,
        "longitude": -2.483,
        "region": null
    },
    {
        "georeferenceId": "17786",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "13.6631106231942",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.717,
        "longitude": -4.367,
        "region": null
    },
    {
        "georeferenceId": "17906",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "12.3798904652178",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.767,
        "longitude": -2.717,
        "region": null
    },
    {
        "georeferenceId": "17746",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "6.95317372982858",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53,
        "longitude": -1.25,
        "region": null
    },
    {
        "georeferenceId": "17687",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "17.9838038625486",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "2",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.7,
        "longitude": -5.3,
        "region": null
    },
    {
        "georeferenceId": "17916",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "2",
        "brownPatchRiskIrrigated": "3",
        "dollarSpotRiskIrrigated": "3",
        "dollarSpotSmithModelRisk": "12.2108604574476",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.083,
        "longitude": 1.183,
        "region": null
    },
    {
        "georeferenceId": "17740",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "10.4087158954023",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.2,
        "longitude": -2.9,
        "region": null
    },
    {
        "georeferenceId": "17702",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "12.0914555477124",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.95,
        "longitude": -3.35,
        "region": null
    },
    {
        "georeferenceId": "17933",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "2",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "10.9144027573648",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.317,
        "longitude": 0.033,
        "region": null
    },
    {
        "georeferenceId": "17787",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "13.5040845132052",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.567,
        "longitude": -3.983,
        "region": null
    },
    {
        "georeferenceId": "17838",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "3",
        "dollarSpotSmithModelRisk": "14.8346938572002",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.8,
        "longitude": -1.217,
        "region": null
    },
    {
        "georeferenceId": "17842",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "23.8207985041061",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "2",
        "takeAllPatchRiskIrrigated": "2",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 49.217,
        "longitude": -2.2,
        "region": null
    },
    {
        "georeferenceId": "17726",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "6.1496130912829",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.3,
        "longitude": -1.533,
        "region": null
    },
    {
        "georeferenceId": "17904",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "10.6422899508754",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.567,
        "longitude": -2.417,
        "region": null
    },
    {
        "georeferenceId": "17811",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "9.95811000659219",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.283,
        "longitude": -0.767,
        "region": null
    },
    {
        "georeferenceId": "17651",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "17.7139038861353",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "2",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.467,
        "longitude": -7.367,
        "region": null
    },
    {
        "georeferenceId": "17864",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "5.5384590800318",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52,
        "longitude": -0.733,
        "region": null
    },
    {
        "georeferenceId": "17703",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "14.2500610814825",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.317,
        "longitude": -3.2,
        "region": null
    },
    {
        "georeferenceId": "17686",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "18.0321618038383",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.233,
        "longitude": -5.083,
        "region": null
    },
    {
        "georeferenceId": "17917",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "2",
        "brownPatchRiskIrrigated": "2",
        "dollarSpotRiskIrrigated": "3",
        "dollarSpotSmithModelRisk": "8.4875089561807",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.283,
        "longitude": 1.083,
        "region": null
    },
    {
        "georeferenceId": "4482345",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "5.58784152789201",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.03516,
        "longitude": 0.09752,
        "region": null
    },
    {
        "georeferenceId": "17778",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "8.127212605066",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.45,
        "longitude": -1.733,
        "region": null
    },
    {
        "georeferenceId": "17660",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "0",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "2",
        "poaAnnuaSeedheadsRisk": "2",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.817,
        "longitude": -4.967,
        "region": null
    },
    {
        "georeferenceId": "17817",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "2",
        "brownPatchRiskIrrigated": "2",
        "dollarSpotRiskIrrigated": "3",
        "dollarSpotSmithModelRisk": "7.53860179304615",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.433,
        "longitude": 0.367,
        "region": null
    },
    {
        "georeferenceId": "17903",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "12.1500214654747",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.067,
        "longitude": -2.833,
        "region": null
    },
    {
        "georeferenceId": "17821",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "2",
        "dollarSpotRiskIrrigated": "3",
        "dollarSpotSmithModelRisk": "9.88952831862709",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.35,
        "longitude": 1.417,
        "region": null
    },
    {
        "georeferenceId": "4482342",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "6.61701577657286",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.85426,
        "longitude": -1.50702,
        "region": null
    },
    {
        "georeferenceId": "17704",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "12.4767233550107",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.6,
        "longitude": -3.55,
        "region": null
    },
    {
        "georeferenceId": "17685",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "20.9768909145162",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "2",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.433,
        "longitude": -5.7,
        "region": null
    },
    {
        "georeferenceId": "17772",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "12.2746319848174",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.35,
        "longitude": -3.95,
        "region": null
    },
    {
        "georeferenceId": "17745",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "2",
        "brownPatchRiskIrrigated": "2",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "8.47942431157645",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.8,
        "longitude": -1.55,
        "region": null
    },
    {
        "georeferenceId": "17659",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "18.0176847653329",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.767,
        "longitude": -6.05,
        "region": null
    },
    {
        "georeferenceId": "17919",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "17.0458560941706",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.517,
        "longitude": -4.833,
        "region": null
    },
    {
        "georeferenceId": "17733",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "6.08435210914716",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.1,
        "longitude": -0.167,
        "region": null
    },
    {
        "georeferenceId": "17880",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "20.150505050997",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.417,
        "longitude": -4.117,
        "region": null
    },
    {
        "georeferenceId": "17683",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "19.2852162881442",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.5,
        "longitude": -6.883,
        "region": null
    },
    {
        "georeferenceId": "17865",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "15.8565443167521",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.833,
        "longitude": -4.533,
        "region": null
    },
    {
        "georeferenceId": "17705",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "9.36798031650516",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.983,
        "longitude": -2.433,
        "region": null
    },
    {
        "georeferenceId": "17805",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "11.8157110252751",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.5,
        "longitude": -1.983,
        "region": null
    },
    {
        "georeferenceId": "17920",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "16.636804487679",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.45,
        "longitude": -4.45,
        "region": null
    },
    {
        "georeferenceId": "17797",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "2",
        "dollarSpotRiskIrrigated": "3",
        "dollarSpotSmithModelRisk": "7.35266540130297",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.883,
        "longitude": 0.9,
        "region": null
    },
    {
        "georeferenceId": "17901",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "10.4626783759537",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.467,
        "longitude": -2.567,
        "region": null
    },
    {
        "georeferenceId": "17762",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "8.50837009663368",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.8,
        "longitude": -2.667,
        "region": null
    },
    {
        "georeferenceId": "17682",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "17.2591037901525",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.65,
        "longitude": -3.383,
        "region": null
    },
    {
        "georeferenceId": "17727",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "7.26986927092732",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.133,
        "longitude": -1.417,
        "region": null
    },
    {
        "georeferenceId": "17780",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "7.77443786493096",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.367,
        "longitude": -1.333,
        "region": null
    },
    {
        "georeferenceId": "17930",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "13.4510814061644",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.95,
        "longitude": -3.983,
        "region": null
    },
    {
        "georeferenceId": "17706",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "14.0464876865626",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.383,
        "longitude": -2.867,
        "region": null
    },
    {
        "georeferenceId": "17849",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "16.6300975062444",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.517,
        "longitude": -6.75,
        "region": null
    },
    {
        "georeferenceId": "17843",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "17.3161945043238",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.433,
        "longitude": -8.05,
        "region": null
    },
    {
        "georeferenceId": "17935",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "12.8979696999023",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56,
        "longitude": -3.783,
        "region": null
    },
    {
        "georeferenceId": "17681",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "20.1733036129576",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "2",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.5,
        "longitude": -1.767,
        "region": null
    },
    {
        "georeferenceId": "17900",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "13.6113737022463",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.9,
        "longitude": -3.633,
        "region": null
    },
    {
        "georeferenceId": "17869",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "14.9807173951271",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.45,
        "longitude": -3.017,
        "region": null
    },
    {
        "georeferenceId": "17921",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "16.4632752182953",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.467,
        "longitude": -3.533,
        "region": null
    },
    {
        "georeferenceId": "17826",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "19.7673095183117",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.417,
        "longitude": -5.083,
        "region": null
    },
    {
        "georeferenceId": "17736",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "10.5159723255898",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.217,
        "longitude": -3.5,
        "region": null
    },
    {
        "georeferenceId": "17707",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "11.5111845544438",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.433,
        "longitude": -2.783,
        "region": null
    },
    {
        "georeferenceId": "17824",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "19.9471381925791",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.083,
        "longitude": -5.25,
        "region": null
    },
    {
        "georeferenceId": "17874",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "6.71330229721703",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.833,
        "longitude": -1.333,
        "region": null
    },
    {
        "georeferenceId": "17810",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "10.5697039933658",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.433,
        "longitude": -1,
        "region": null
    },
    {
        "georeferenceId": "17771",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "11.4720904094184",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.133,
        "longitude": -4.567,
        "region": null
    },
    {
        "georeferenceId": "17744",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "9.03218416416818",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.65,
        "longitude": -1.783,
        "region": null
    },
    {
        "georeferenceId": "17899",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "11.8014088012525",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.6,
        "longitude": -2.433,
        "region": null
    },
    {
        "georeferenceId": "17851",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-07",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "16.2286587962266",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.867,
        "longitude": -6.45,
        "region": null
    },
    {
        "georeferenceId": "17869",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "20.3128998531625",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.45,
        "longitude": -3.017,
        "region": null
    },
    {
        "georeferenceId": "17826",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "22.0590278652045",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.417,
        "longitude": -5.083,
        "region": null
    },
    {
        "georeferenceId": "17790",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "3",
        "dollarSpotSmithModelRisk": "13.226380310991",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.75,
        "longitude": -1.583,
        "region": null
    },
    {
        "georeferenceId": "17791",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "2",
        "dollarSpotRiskIrrigated": "3",
        "dollarSpotSmithModelRisk": "14.0702667684414",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.617,
        "longitude": -1.083,
        "region": null
    },
    {
        "georeferenceId": "17933",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "2",
        "brownPatchRiskIrrigated": "3",
        "dollarSpotRiskIrrigated": "3",
        "dollarSpotSmithModelRisk": "13.311216490141",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.317,
        "longitude": 0.033,
        "region": null
    },
    {
        "georeferenceId": "17825",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "29.9296559126098",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.15,
        "longitude": -5.067,
        "region": null
    },
    {
        "georeferenceId": "17792",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "2",
        "dollarSpotRiskIrrigated": "3",
        "dollarSpotSmithModelRisk": "16.0565556074643",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.683,
        "longitude": -0.8,
        "region": null
    },
    {
        "georeferenceId": "17824",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "23.4899429378298",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.083,
        "longitude": -5.25,
        "region": null
    },
    {
        "georeferenceId": "17793",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "3",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "3",
        "dollarSpotSmithModelRisk": "12.5723219755002",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.533,
        "longitude": -0.417,
        "region": null
    },
    {
        "georeferenceId": "17823",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "20.971737767003",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.217,
        "longitude": -5.317,
        "region": null
    },
    {
        "georeferenceId": "17794",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "2",
        "brownPatchRiskIrrigated": "2",
        "dollarSpotRiskIrrigated": "3",
        "dollarSpotSmithModelRisk": "12.913448799708",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.883,
        "longitude": 0.233,
        "region": null
    },
    {
        "georeferenceId": "17821",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "2",
        "brownPatchRiskIrrigated": "3",
        "dollarSpotRiskIrrigated": "3",
        "dollarSpotSmithModelRisk": "15.0797908732816",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "3",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.35,
        "longitude": 1.417,
        "region": null
    },
    {
        "georeferenceId": "17797",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "2",
        "brownPatchRiskIrrigated": "2",
        "dollarSpotRiskIrrigated": "3",
        "dollarSpotSmithModelRisk": "11.0920473949946",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.883,
        "longitude": 0.9,
        "region": null
    },
    {
        "georeferenceId": "17665",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "15.5464577665979",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.267,
        "longitude": -4.483,
        "region": null
    },
    {
        "georeferenceId": "17800",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "18.3862602372752",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.083,
        "longitude": -3.6,
        "region": null
    },
    {
        "georeferenceId": "17820",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "2",
        "brownPatchRiskIrrigated": "3",
        "dollarSpotRiskIrrigated": "3",
        "dollarSpotSmithModelRisk": "16.9001400902241",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "3",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.133,
        "longitude": 1.3,
        "region": null
    },
    {
        "georeferenceId": "17801",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "14.9663612880375",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.5,
        "longitude": -3.2,
        "region": null
    },
    {
        "georeferenceId": "17818",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "2",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "3",
        "dollarSpotSmithModelRisk": "10.9720756948125",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.2,
        "longitude": 0.783,
        "region": null
    },
    {
        "georeferenceId": "17802",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "2",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "16.1517263664359",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.4,
        "longitude": -3.433,
        "region": null
    },
    {
        "georeferenceId": "17817",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "2",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "10.0110481737622",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.433,
        "longitude": 0.367,
        "region": null
    },
    {
        "georeferenceId": "17805",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "14.7831533066124",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.5,
        "longitude": -1.983,
        "region": null
    },
    {
        "georeferenceId": "17816",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "2",
        "brownPatchRiskIrrigated": "3",
        "dollarSpotRiskIrrigated": "3",
        "dollarSpotSmithModelRisk": "14.9514055653154",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.3,
        "longitude": -0.083,
        "region": null
    },
    {
        "georeferenceId": "17806",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "14.5588963602267",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.2,
        "longitude": -1.8,
        "region": null
    },
    {
        "georeferenceId": "17808",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "14.9929295315624",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.15,
        "longitude": -1.567,
        "region": null
    },
    {
        "georeferenceId": "17813",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "2",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "3",
        "dollarSpotSmithModelRisk": "12.9616301025467",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.15,
        "longitude": -0.183,
        "region": null
    },
    {
        "georeferenceId": "17809",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "2",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "3",
        "dollarSpotSmithModelRisk": "12.6485304043857",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.233,
        "longitude": -0.95,
        "region": null
    },
    {
        "georeferenceId": "17811",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "2",
        "brownPatchRiskIrrigated": "2",
        "dollarSpotRiskIrrigated": "3",
        "dollarSpotSmithModelRisk": "12.5595261915191",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.283,
        "longitude": -0.767,
        "region": null
    },
    {
        "georeferenceId": "17810",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "2",
        "brownPatchRiskIrrigated": "2",
        "dollarSpotRiskIrrigated": "3",
        "dollarSpotSmithModelRisk": "13.43306382583",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.433,
        "longitude": -1,
        "region": null
    },
    {
        "georeferenceId": "17666",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "18.3284866408697",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.683,
        "longitude": -4.167,
        "region": null
    },
    {
        "georeferenceId": "17932",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "11.8618870513315",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.75,
        "longitude": -2.483,
        "region": null
    },
    {
        "georeferenceId": "17667",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "15.8935649863516",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.533,
        "longitude": -4.05,
        "region": null
    },
    {
        "georeferenceId": "17931",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "19.8746185756791",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.067,
        "longitude": -3.467,
        "region": null
    },
    {
        "georeferenceId": "17668",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "18.4202754811597",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.817,
        "longitude": -3.967,
        "region": null
    },
    {
        "georeferenceId": "17930",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "18.5188377156598",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.95,
        "longitude": -3.983,
        "region": null
    },
    {
        "georeferenceId": "17669",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "13.4726473768049",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "2",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.2,
        "longitude": -3.833,
        "region": null
    },
    {
        "georeferenceId": "4482347",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "2",
        "dollarSpotRiskIrrigated": "3",
        "dollarSpotSmithModelRisk": "9.46378041480282",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.64729,
        "longitude": -0.39027,
        "region": null
    },
    {
        "georeferenceId": "17929",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "16.0371547486368",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 58.283,
        "longitude": -4.433,
        "region": null
    },
    {
        "georeferenceId": "17671",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "19.6035316751499",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.65,
        "longitude": -3.567,
        "region": null
    },
    {
        "georeferenceId": "17672",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "19.6802411036445",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.717,
        "longitude": -3.317,
        "region": null
    },
    {
        "georeferenceId": "17927",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "22.8772023235954",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.183,
        "longitude": -6.333,
        "region": null
    },
    {
        "georeferenceId": "17673",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "17.7691013896845",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.35,
        "longitude": -3.35,
        "region": null
    },
    {
        "georeferenceId": "17926",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "22.1394616504331",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.717,
        "longitude": -6.517,
        "region": null
    },
    {
        "georeferenceId": "17674",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "0",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "2",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.883,
        "longitude": -3.417,
        "region": null
    },
    {
        "georeferenceId": "17925",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "22.728619946135",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.817,
        "longitude": -7.467,
        "region": null
    },
    {
        "georeferenceId": "17675",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "19.5894133840397",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 58.617,
        "longitude": -3.55,
        "region": null
    },
    {
        "georeferenceId": "17649",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "20.9849431672524",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "3",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 58.95,
        "longitude": -2.9,
        "region": null
    },
    {
        "georeferenceId": "17924",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "2",
        "brownPatchRiskIrrigated": "3",
        "dollarSpotRiskIrrigated": "3",
        "dollarSpotSmithModelRisk": "17.4639942682453",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "3",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.867,
        "longitude": 0.6,
        "region": null
    },
    {
        "georeferenceId": "17676",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "22.7583441057322",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "4",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 58.45,
        "longitude": -3.083,
        "region": null
    },
    {
        "georeferenceId": "17678",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "19.0440458428302",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "2",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.083,
        "longitude": -2.833,
        "region": null
    },
    {
        "georeferenceId": "17640",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "17.953837329048",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "3",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 60.75,
        "longitude": -0.85,
        "region": null
    },
    {
        "georeferenceId": "17923",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "2",
        "dollarSpotRiskIrrigated": "3",
        "dollarSpotSmithModelRisk": "18.9676169791588",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.067,
        "longitude": -1.317,
        "region": null
    },
    {
        "georeferenceId": "17679",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "22.6367115362863",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.717,
        "longitude": -2.467,
        "region": null
    },
    {
        "georeferenceId": "17922",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "2",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "21.0132200950336",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.6,
        "longitude": -2.45,
        "region": null
    },
    {
        "georeferenceId": "17680",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "21.438243199576",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "2",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.2,
        "longitude": -2.217,
        "region": null
    },
    {
        "georeferenceId": "17921",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "19.1658656075024",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.467,
        "longitude": -3.533,
        "region": null
    },
    {
        "georeferenceId": "17681",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "22.7146055156677",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "2",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.5,
        "longitude": -1.767,
        "region": null
    },
    {
        "georeferenceId": "17920",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "19.5432092452526",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.45,
        "longitude": -4.45,
        "region": null
    },
    {
        "georeferenceId": "17682",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "22.9195224592482",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.65,
        "longitude": -3.383,
        "region": null
    },
    {
        "georeferenceId": "4482346",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "20.0545880971828",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.46997,
        "longitude": -4.564,
        "region": null
    },
    {
        "georeferenceId": "17683",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "21.5601584878676",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.5,
        "longitude": -6.883,
        "region": null
    },
    {
        "georeferenceId": "17919",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "18.8063044414105",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.517,
        "longitude": -4.833,
        "region": null
    },
    {
        "georeferenceId": "17685",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "25.1192385164069",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "2",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.433,
        "longitude": -5.7,
        "region": null
    },
    {
        "georeferenceId": "17917",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "2",
        "brownPatchRiskIrrigated": "3",
        "dollarSpotRiskIrrigated": "3",
        "dollarSpotSmithModelRisk": "12.8711845404511",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.283,
        "longitude": 1.083,
        "region": null
    },
    {
        "georeferenceId": "17686",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "20.5368308991243",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.233,
        "longitude": -5.083,
        "region": null
    },
    {
        "georeferenceId": "17916",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "2",
        "brownPatchRiskIrrigated": "3",
        "dollarSpotRiskIrrigated": "3",
        "dollarSpotSmithModelRisk": "17.069338024077",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "3",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.083,
        "longitude": 1.183,
        "region": null
    },
    {
        "georeferenceId": "17687",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "20.1762778900979",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "2",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.7,
        "longitude": -5.3,
        "region": null
    },
    {
        "georeferenceId": "17651",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "18.978819588369",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "2",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.467,
        "longitude": -7.367,
        "region": null
    },
    {
        "georeferenceId": "17915",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "2",
        "brownPatchRiskIrrigated": "3",
        "dollarSpotRiskIrrigated": "3",
        "dollarSpotSmithModelRisk": "12.3987717378042",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.267,
        "longitude": 0.517,
        "region": null
    },
    {
        "georeferenceId": "17688",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "23.4508946311929",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.617,
        "longitude": -4.85,
        "region": null
    },
    {
        "georeferenceId": "17689",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "25.6552204422032",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.85,
        "longitude": -4.95,
        "region": null
    },
    {
        "georeferenceId": "17914",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "2",
        "brownPatchRiskIrrigated": "2",
        "dollarSpotRiskIrrigated": "3",
        "dollarSpotSmithModelRisk": "11.1632111801048",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.2,
        "longitude": 0.183,
        "region": null
    },
    {
        "georeferenceId": "17690",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "20.0545880971828",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.467,
        "longitude": -4.617,
        "region": null
    },
    {
        "georeferenceId": "17913",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "2",
        "brownPatchRiskIrrigated": "2",
        "dollarSpotRiskIrrigated": "3",
        "dollarSpotSmithModelRisk": "15.6919149539947",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.483,
        "longitude": -0.6,
        "region": null
    },
    {
        "georeferenceId": "17691",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "19.8642938917376",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.95,
        "longitude": -4.767,
        "region": null
    },
    {
        "georeferenceId": "17912",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "2",
        "brownPatchRiskIrrigated": "2",
        "dollarSpotRiskIrrigated": "3",
        "dollarSpotSmithModelRisk": "15.5396768889808",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.35,
        "longitude": -2.983,
        "region": null
    },
    {
        "georeferenceId": "17693",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "19.1318730914151",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.867,
        "longitude": -4.433,
        "region": null
    },
    {
        "georeferenceId": "17911",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "16.6242418327196",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.083,
        "longitude": -4.15,
        "region": null
    },
    {
        "georeferenceId": "17694",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "22.8881654199684",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.317,
        "longitude": -3.733,
        "region": null
    },
    {
        "georeferenceId": "17696",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "17.7447434153432",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "2",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.417,
        "longitude": -4.317,
        "region": null
    },
    {
        "georeferenceId": "17910",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "2",
        "brownPatchRiskIrrigated": "3",
        "dollarSpotRiskIrrigated": "3",
        "dollarSpotSmithModelRisk": "11.9794967608053",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.933,
        "longitude": 1.283,
        "region": null
    },
    {
        "georeferenceId": "17697",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "25.2638798819927",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.8,
        "longitude": -4,
        "region": null
    },
    {
        "georeferenceId": "17909",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "3",
        "dollarSpotSmithModelRisk": "10.3989956203038",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.367,
        "longitude": -1.5,
        "region": null
    },
    {
        "georeferenceId": "17698",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "20.3692371483634",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.05,
        "longitude": -3.65,
        "region": null
    },
    {
        "georeferenceId": "17908",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "8.25676540083977",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53,
        "longitude": -2.183,
        "region": null
    },
    {
        "georeferenceId": "17699",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "18.177250320063",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.667,
        "longitude": -3.783,
        "region": null
    },
    {
        "georeferenceId": "17653",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "16.435373151269",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 58.217,
        "longitude": -6.317,
        "region": null
    },
    {
        "georeferenceId": "17907",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "9.35556566945424",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.05,
        "longitude": -3,
        "region": null
    },
    {
        "georeferenceId": "17700",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "23.2670757579962",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.433,
        "longitude": -3.367,
        "region": null
    },
    {
        "georeferenceId": "17701",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "15.8534717567815",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.617,
        "longitude": -2.8,
        "region": null
    },
    {
        "georeferenceId": "17906",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "3",
        "dollarSpotSmithModelRisk": "13.3987137301091",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.767,
        "longitude": -2.717,
        "region": null
    },
    {
        "georeferenceId": "17702",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "15.8855403005137",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.95,
        "longitude": -3.35,
        "region": null
    },
    {
        "georeferenceId": "4482345",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "3",
        "dollarSpotSmithModelRisk": "7.62443869225336",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.03516,
        "longitude": 0.09752,
        "region": null
    },
    {
        "georeferenceId": "17904",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "13.7221109281804",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.567,
        "longitude": -2.417,
        "region": null
    },
    {
        "georeferenceId": "17703",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "19.1922034620207",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.317,
        "longitude": -3.2,
        "region": null
    },
    {
        "georeferenceId": "17903",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "13.5289648501085",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.067,
        "longitude": -2.833,
        "region": null
    },
    {
        "georeferenceId": "17704",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "15.9134161905932",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.6,
        "longitude": -3.55,
        "region": null
    },
    {
        "georeferenceId": "17901",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "14.976383762689",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.467,
        "longitude": -2.567,
        "region": null
    },
    {
        "georeferenceId": "17705",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "12.9599154896676",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.983,
        "longitude": -2.433,
        "region": null
    },
    {
        "georeferenceId": "17706",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "18.76227432007",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.383,
        "longitude": -2.867,
        "region": null
    },
    {
        "georeferenceId": "17900",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "17.6189067518205",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.9,
        "longitude": -3.633,
        "region": null
    },
    {
        "georeferenceId": "17707",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "16.1450358520351",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.433,
        "longitude": -2.783,
        "region": null
    },
    {
        "georeferenceId": "17899",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "17.1501038502054",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.6,
        "longitude": -2.433,
        "region": null
    },
    {
        "georeferenceId": "17708",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "23.5427183569253",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.083,
        "longitude": -4.633,
        "region": null
    },
    {
        "georeferenceId": "17898",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "17.6193544373858",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.783,
        "longitude": -4,
        "region": null
    },
    {
        "georeferenceId": "17710",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "16.0362443865854",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.933,
        "longitude": -3.15,
        "region": null
    },
    {
        "georeferenceId": "17654",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "3",
        "dollarSpotSmithModelRisk": "17.9789103336502",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.417,
        "longitude": -6.2,
        "region": null
    },
    {
        "georeferenceId": "17897",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "16.5368700052213",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.767,
        "longitude": -4.167,
        "region": null
    },
    {
        "georeferenceId": "17711",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "20.7483559013305",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.517,
        "longitude": -3.6,
        "region": null
    },
    {
        "georeferenceId": "17712",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "15.7084723798761",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.617,
        "longitude": -3.167,
        "region": null
    },
    {
        "georeferenceId": "17896",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "21.8244240098336",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.6,
        "longitude": -4.5,
        "region": null
    },
    {
        "georeferenceId": "17713",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "19.7858698748604",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.317,
        "longitude": -3.4,
        "region": null
    },
    {
        "georeferenceId": "17895",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "18.8994989338325",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.417,
        "longitude": -5.467,
        "region": null
    },
    {
        "georeferenceId": "17714",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "19.2415337159974",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.117,
        "longitude": -3.217,
        "region": null
    },
    {
        "georeferenceId": "4482344",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "3",
        "dollarSpotSmithModelRisk": "14.3559414767746",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.68495,
        "longitude": -2.76495,
        "region": null
    },
    {
        "georeferenceId": "17894",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "22.1653817659016",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.633,
        "longitude": -6.183,
        "region": null
    },
    {
        "georeferenceId": "17715",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "18.4826843280596",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.767,
        "longitude": -3.317,
        "region": null
    },
    {
        "georeferenceId": "17893",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "22.5704616824806",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.683,
        "longitude": -2.017,
        "region": null
    },
    {
        "georeferenceId": "17717",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "17.5841311063252",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.933,
        "longitude": -2.95,
        "region": null
    },
    {
        "georeferenceId": "17718",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "16.3632153218212",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.05,
        "longitude": -2.55,
        "region": null
    },
    {
        "georeferenceId": "17891",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "20.6586030405937",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 58.033,
        "longitude": -4.4,
        "region": null
    },
    {
        "georeferenceId": "17719",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "15.1952129051155",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.5,
        "longitude": -2.683,
        "region": null
    },
    {
        "georeferenceId": "17886",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "2",
        "brownPatchRiskIrrigated": "3",
        "dollarSpotRiskIrrigated": "3",
        "dollarSpotSmithModelRisk": "11.0646823763378",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.567,
        "longitude": 0.7,
        "region": null
    },
    {
        "georeferenceId": "17721",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "16.5632166534509",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.683,
        "longitude": -2.45,
        "region": null
    },
    {
        "georeferenceId": "17885",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "20.6622849278916",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.05,
        "longitude": -7.15,
        "region": null
    },
    {
        "georeferenceId": "17722",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "14.6315740707903",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.283,
        "longitude": -2.283,
        "region": null
    },
    {
        "georeferenceId": "17655",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "13.8408851571239",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "2",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.717,
        "longitude": -4.883,
        "region": null
    },
    {
        "georeferenceId": "17884",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "3",
        "dollarSpotSmithModelRisk": "12.8112815805154",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.25,
        "longitude": -0.883,
        "region": null
    },
    {
        "georeferenceId": "17723",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "8.79935478717779",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.817,
        "longitude": -2.083,
        "region": null
    },
    {
        "georeferenceId": "17724",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "14.8652430789414",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.417,
        "longitude": -1.6,
        "region": null
    },
    {
        "georeferenceId": "4482348",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "2",
        "dollarSpotRiskIrrigated": "3",
        "dollarSpotSmithModelRisk": "9.17145168781491",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.65863,
        "longitude": -0.49627,
        "region": null
    },
    {
        "georeferenceId": "17882",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "2",
        "brownPatchRiskIrrigated": "3",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "24.5619701172528",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "3",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 49.717,
        "longitude": -2.2,
        "region": null
    },
    {
        "georeferenceId": "17725",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "10.5744367026901",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.917,
        "longitude": -1.383,
        "region": null
    },
    {
        "georeferenceId": "17881",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "3",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "11.4201376475827",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.5,
        "longitude": 0.05,
        "region": null
    },
    {
        "georeferenceId": "17726",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "3",
        "dollarSpotSmithModelRisk": "8.29393438587313",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.3,
        "longitude": -1.533,
        "region": null
    },
    {
        "georeferenceId": "17880",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "25.2046264005913",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.417,
        "longitude": -4.117,
        "region": null
    },
    {
        "georeferenceId": "17727",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "3",
        "dollarSpotSmithModelRisk": "9.66008566131226",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.133,
        "longitude": -1.417,
        "region": null
    },
    {
        "georeferenceId": "4482343",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "3",
        "dollarSpotSmithModelRisk": "9.71446189425129",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.39455,
        "longitude": -0.78437,
        "region": null
    },
    {
        "georeferenceId": "17879",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "2",
        "brownPatchRiskIrrigated": "3",
        "dollarSpotRiskIrrigated": "3",
        "dollarSpotSmithModelRisk": "12.0803041513238",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.867,
        "longitude": -0.367,
        "region": null
    },
    {
        "georeferenceId": "17728",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "3",
        "dollarSpotSmithModelRisk": "8.04621682569999",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.2,
        "longitude": -1.383,
        "region": null
    },
    {
        "georeferenceId": "17729",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "3",
        "dollarSpotSmithModelRisk": "9.34146608161096",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.05,
        "longitude": -1.25,
        "region": null
    },
    {
        "georeferenceId": "17878",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "2",
        "brownPatchRiskIrrigated": "3",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "13.6097835908728",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.333,
        "longitude": -2.85,
        "region": null
    },
    {
        "georeferenceId": "17730",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "8.28691629403363",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.683,
        "longitude": -1.217,
        "region": null
    },
    {
        "georeferenceId": "17877",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "2",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "15.8714335212321",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.383,
        "longitude": -2.717,
        "region": null
    },
    {
        "georeferenceId": "17731",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "7.97130051023157",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.367,
        "longitude": -0.667,
        "region": null
    },
    {
        "georeferenceId": "17876",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "11.0860211279037",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.867,
        "longitude": -2.25,
        "region": null
    },
    {
        "georeferenceId": "17732",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "7.59793501991609",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.483,
        "longitude": -0.6,
        "region": null
    },
    {
        "georeferenceId": "17656",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "13.1983877238962",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.867,
        "longitude": -5.633,
        "region": null
    },
    {
        "georeferenceId": "17874",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "3",
        "dollarSpotSmithModelRisk": "8.74475929321719",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.833,
        "longitude": -1.333,
        "region": null
    },
    {
        "georeferenceId": "17733",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "8.37027962460978",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.1,
        "longitude": -0.167,
        "region": null
    },
    {
        "georeferenceId": "17734",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "21.0099635277581",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.25,
        "longitude": -4.533,
        "region": null
    },
    {
        "georeferenceId": "17873",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "3",
        "dollarSpotSmithModelRisk": "11.2311251233483",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.533,
        "longitude": -1.567,
        "region": null
    },
    {
        "georeferenceId": "17735",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "17.4254972073447",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.1,
        "longitude": -3.933,
        "region": null
    },
    {
        "georeferenceId": "17872",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "2",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "11.1147793551918",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.033,
        "longitude": -1.7,
        "region": null
    },
    {
        "georeferenceId": "17736",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "11.8405292069029",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.217,
        "longitude": -3.5,
        "region": null
    },
    {
        "georeferenceId": "17871",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "2",
        "dollarSpotRiskIrrigated": "3",
        "dollarSpotSmithModelRisk": "9.43845649679921",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.583,
        "longitude": -0.35,
        "region": null
    },
    {
        "georeferenceId": "17737",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "10.0290675160276",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.217,
        "longitude": -3.217,
        "region": null
    },
    {
        "georeferenceId": "17870",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "10.2892954339922",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.783,
        "longitude": -1.75,
        "region": null
    },
    {
        "georeferenceId": "17738",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "12.3422038592492",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.5,
        "longitude": -3.067,
        "region": null
    },
    {
        "georeferenceId": "4482342",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "3",
        "dollarSpotSmithModelRisk": "8.60175273543551",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.85426,
        "longitude": -1.50702,
        "region": null
    },
    {
        "georeferenceId": "17739",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "14.3163969625489",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.767,
        "longitude": -3.033,
        "region": null
    },
    {
        "georeferenceId": "17740",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "11.9296868929957",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.2,
        "longitude": -2.9,
        "region": null
    },
    {
        "georeferenceId": "17868",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "15.1117871028299",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "3",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 60.433,
        "longitude": -1.3,
        "region": null
    },
    {
        "georeferenceId": "17741",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "2",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "10.5598127393611",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.5,
        "longitude": -2.217,
        "region": null
    },
    {
        "georeferenceId": "17867",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "2",
        "dollarSpotRiskIrrigated": "3",
        "dollarSpotSmithModelRisk": "9.49220229050056",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.2,
        "longitude": -1.7,
        "region": null
    },
    {
        "georeferenceId": "17742",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "11.6291650505285",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.967,
        "longitude": -2.033,
        "region": null
    },
    {
        "georeferenceId": "17657",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "18.9070163370856",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "2",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.033,
        "longitude": -7.45,
        "region": null
    },
    {
        "georeferenceId": "17866",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "2",
        "dollarSpotRiskIrrigated": "3",
        "dollarSpotSmithModelRisk": "17.1064293687346",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.133,
        "longitude": -3.017,
        "region": null
    },
    {
        "georeferenceId": "17743",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "11.089357116543",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.817,
        "longitude": -1.867,
        "region": null
    },
    {
        "georeferenceId": "17744",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "3",
        "dollarSpotSmithModelRisk": "11.0211159150785",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.65,
        "longitude": -1.783,
        "region": null
    },
    {
        "georeferenceId": "17865",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "17.9075234295596",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.833,
        "longitude": -4.533,
        "region": null
    },
    {
        "georeferenceId": "17745",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "2",
        "brownPatchRiskIrrigated": "2",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "10.6174306114837",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.8,
        "longitude": -1.55,
        "region": null
    },
    {
        "georeferenceId": "17864",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "5.61266279467159",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52,
        "longitude": -0.733,
        "region": null
    },
    {
        "georeferenceId": "17746",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "2",
        "brownPatchRiskIrrigated": "3",
        "dollarSpotRiskIrrigated": "3",
        "dollarSpotSmithModelRisk": "8.90735023551515",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53,
        "longitude": -1.25,
        "region": null
    },
    {
        "georeferenceId": "17863",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "14.970983872834",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.383,
        "longitude": -2.35,
        "region": null
    },
    {
        "georeferenceId": "17747",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "3",
        "dollarSpotSmithModelRisk": "9.93767677905195",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.833,
        "longitude": -1.2,
        "region": null
    },
    {
        "georeferenceId": "17862",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "3",
        "dollarSpotSmithModelRisk": "11.4533605873077",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.9,
        "longitude": -2.083,
        "region": null
    },
    {
        "georeferenceId": "17748",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "3",
        "dollarSpotSmithModelRisk": "9.71446189425129",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.4,
        "longitude": -0.767,
        "region": null
    },
    {
        "georeferenceId": "17749",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "2",
        "dollarSpotRiskIrrigated": "3",
        "dollarSpotSmithModelRisk": "7.73939863083652",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.167,
        "longitude": -0.517,
        "region": null
    },
    {
        "georeferenceId": "17861",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "3",
        "dollarSpotSmithModelRisk": "8.54768644805758",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.367,
        "longitude": -1.483,
        "region": null
    },
    {
        "georeferenceId": "17750",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "2",
        "dollarSpotRiskIrrigated": "3",
        "dollarSpotSmithModelRisk": "8.51928650966513",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.033,
        "longitude": -0.5,
        "region": null
    },
    {
        "georeferenceId": "17860",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "3",
        "dollarSpotSmithModelRisk": "11.1351774791056",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.567,
        "longitude": -1.167,
        "region": null
    },
    {
        "georeferenceId": "17751",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "3",
        "dollarSpotSmithModelRisk": "8.74825083496706",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.867,
        "longitude": -0.433,
        "region": null
    },
    {
        "georeferenceId": "17859",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "9.83134755030619",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.867,
        "longitude": -1.65,
        "region": null
    },
    {
        "georeferenceId": "17752",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "3",
        "dollarSpotSmithModelRisk": "7.96664347853949",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.483,
        "longitude": -0.083,
        "region": null
    },
    {
        "georeferenceId": "17658",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "15.2033919838449",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.25,
        "longitude": -5.8,
        "region": null
    },
    {
        "georeferenceId": "17858",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "20.7467171525077",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.85,
        "longitude": -5.8,
        "region": null
    },
    {
        "georeferenceId": "17753",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "2",
        "dollarSpotRiskIrrigated": "3",
        "dollarSpotSmithModelRisk": "7.74718139637007",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.083,
        "longitude": -0.167,
        "region": null
    },
    {
        "georeferenceId": "17754",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "2",
        "dollarSpotRiskIrrigated": "3",
        "dollarSpotSmithModelRisk": "9.57986261681026",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.083,
        "longitude": 0.267,
        "region": null
    },
    {
        "georeferenceId": "17857",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "22.608112965355",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.667,
        "longitude": -5.667,
        "region": null
    },
    {
        "georeferenceId": "17756",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "16.8523904272095",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53,
        "longitude": -4.083,
        "region": null
    },
    {
        "georeferenceId": "51522",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "2",
        "brownPatchRiskIrrigated": "3",
        "dollarSpotRiskIrrigated": "3",
        "dollarSpotSmithModelRisk": "11.9157446985598",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.817,
        "longitude": 1.133,
        "region": null
    },
    {
        "georeferenceId": "17856",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "19.2359171086484",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.6,
        "longitude": -5.883,
        "region": null
    },
    {
        "georeferenceId": "17757",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "23.5268125827748",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.783,
        "longitude": -4.733,
        "region": null
    },
    {
        "georeferenceId": "17855",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "21.4603247636911",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.35,
        "longitude": -6.667,
        "region": null
    },
    {
        "georeferenceId": "17758",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "18.4549207457999",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.8,
        "longitude": -4.117,
        "region": null
    },
    {
        "georeferenceId": "17854",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "20.660438873237",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.483,
        "longitude": -6.1,
        "region": null
    },
    {
        "georeferenceId": "17759",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "11.341744628558",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.95,
        "longitude": -3.417,
        "region": null
    },
    {
        "georeferenceId": "17760",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "12.8461258609913",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.75,
        "longitude": -3.467,
        "region": null
    },
    {
        "georeferenceId": "17853",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "20.6237949693616",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.65,
        "longitude": -6.217,
        "region": null
    },
    {
        "georeferenceId": "17761",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "14.46689561946",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.567,
        "longitude": -3.717,
        "region": null
    },
    {
        "georeferenceId": "17852",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "24.1348455491988",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.183,
        "longitude": -6.167,
        "region": null
    },
    {
        "georeferenceId": "17762",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "10.5556679707271",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.8,
        "longitude": -2.667,
        "region": null
    },
    {
        "georeferenceId": "17851",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "22.3662303389215",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.867,
        "longitude": -6.45,
        "region": null
    },
    {
        "georeferenceId": "17763",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "3",
        "dollarSpotSmithModelRisk": "9.31337736704102",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.733,
        "longitude": -0.65,
        "region": null
    },
    {
        "georeferenceId": "17659",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "19.0078421895822",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.767,
        "longitude": -6.05,
        "region": null
    },
    {
        "georeferenceId": "17850",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "22.65368748874",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.2,
        "longitude": -6.65,
        "region": null
    },
    {
        "georeferenceId": "17764",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "2",
        "dollarSpotRiskIrrigated": "3",
        "dollarSpotSmithModelRisk": "9.19831052986602",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.617,
        "longitude": -0.467,
        "region": null
    },
    {
        "georeferenceId": "17765",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "2",
        "dollarSpotRiskIrrigated": "3",
        "dollarSpotSmithModelRisk": "9.19275207295933",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.867,
        "longitude": 0.15,
        "region": null
    },
    {
        "georeferenceId": "17849",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "21.4805642533323",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.517,
        "longitude": -6.75,
        "region": null
    },
    {
        "georeferenceId": "17766",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "2",
        "brownPatchRiskIrrigated": "2",
        "dollarSpotRiskIrrigated": "3",
        "dollarSpotSmithModelRisk": "11.5521134849163",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.65,
        "longitude": 0.567,
        "region": null
    },
    {
        "georeferenceId": "17848",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "25.9969710955013",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.717,
        "longitude": -6.817,
        "region": null
    },
    {
        "georeferenceId": "17767",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "2",
        "brownPatchRiskIrrigated": "3",
        "dollarSpotRiskIrrigated": "3",
        "dollarSpotSmithModelRisk": "13.7036868888773",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.95,
        "longitude": 1.133,
        "region": null
    },
    {
        "georeferenceId": "17936",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "17.4799150626093",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.117,
        "longitude": -3.15,
        "region": null
    },
    {
        "georeferenceId": "17847",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "19.6893626213351",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.067,
        "longitude": -7.017,
        "region": null
    },
    {
        "georeferenceId": "17768",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "2",
        "brownPatchRiskIrrigated": "3",
        "dollarSpotRiskIrrigated": "3",
        "dollarSpotSmithModelRisk": "14.7040146573395",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.633,
        "longitude": 1.3,
        "region": null
    },
    {
        "georeferenceId": "17845",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "23.4948156615684",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.6,
        "longitude": -7.3,
        "region": null
    },
    {
        "georeferenceId": "17769",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "2",
        "brownPatchRiskIrrigated": "3",
        "dollarSpotRiskIrrigated": "3",
        "dollarSpotSmithModelRisk": "11.5694773067263",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.8,
        "longitude": 1.25,
        "region": null
    },
    {
        "georeferenceId": "17770",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "2",
        "brownPatchRiskIrrigated": "2",
        "dollarSpotRiskIrrigated": "3",
        "dollarSpotSmithModelRisk": "13.7033928672485",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.683,
        "longitude": 1.683,
        "region": null
    },
    {
        "georeferenceId": "17844",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "20.1092043219604",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.4,
        "longitude": -7.65,
        "region": null
    },
    {
        "georeferenceId": "17771",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "14.1520294881889",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.133,
        "longitude": -4.567,
        "region": null
    },
    {
        "georeferenceId": "17843",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "19.7547076058578",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.433,
        "longitude": -8.05,
        "region": null
    },
    {
        "georeferenceId": "17772",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "14.9144615691137",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.35,
        "longitude": -3.95,
        "region": null
    },
    {
        "georeferenceId": "17842",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "25.3330562889495",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 49.217,
        "longitude": -2.2,
        "region": null
    },
    {
        "georeferenceId": "17773",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "14.642191311189",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.067,
        "longitude": -3.617,
        "region": null
    },
    {
        "georeferenceId": "17660",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "0",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "2",
        "poaAnnuaSeedheadsRisk": "2",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.817,
        "longitude": -4.967,
        "region": null
    },
    {
        "georeferenceId": "17841",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "25.8126425435111",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 49.433,
        "longitude": -2.6,
        "region": null
    },
    {
        "georeferenceId": "17774",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "12.8955475562274",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.083,
        "longitude": -2.8,
        "region": null
    },
    {
        "georeferenceId": "17775",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "9.31577281395214",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.2,
        "longitude": -2.217,
        "region": null
    },
    {
        "georeferenceId": "17840",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "2",
        "brownPatchRiskIrrigated": "2",
        "dollarSpotRiskIrrigated": "3",
        "dollarSpotSmithModelRisk": "16.0494501851914",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.8,
        "longitude": 0.25,
        "region": null
    },
    {
        "georeferenceId": "17776",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "14.1840789307171",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.117,
        "longitude": -2.3,
        "region": null
    },
    {
        "georeferenceId": "17839",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "2",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "3",
        "dollarSpotSmithModelRisk": "18.1264755548375",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.833,
        "longitude": -0.283,
        "region": null
    },
    {
        "georeferenceId": "17777",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "12.6880556273708",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.15,
        "longitude": -2.033,
        "region": null
    },
    {
        "georeferenceId": "17838",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "2",
        "brownPatchRiskIrrigated": "3",
        "dollarSpotRiskIrrigated": "3",
        "dollarSpotSmithModelRisk": "18.3439557513294",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.8,
        "longitude": -1.217,
        "region": null
    },
    {
        "georeferenceId": "17778",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "3",
        "dollarSpotSmithModelRisk": "10.0458059253584",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.45,
        "longitude": -1.733,
        "region": null
    },
    {
        "georeferenceId": "17935",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "17.5539754314897",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56,
        "longitude": -3.783,
        "region": null
    },
    {
        "georeferenceId": "17837",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "2",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "3",
        "dollarSpotSmithModelRisk": "16.2219955850008",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "3",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.817,
        "longitude": -0.917,
        "region": null
    },
    {
        "georeferenceId": "17780",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "3",
        "dollarSpotSmithModelRisk": "9.99143130274263",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.367,
        "longitude": -1.333,
        "region": null
    },
    {
        "georeferenceId": "17781",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "2",
        "dollarSpotRiskIrrigated": "3",
        "dollarSpotSmithModelRisk": "12.6481500674027",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.217,
        "longitude": -0.483,
        "region": null
    },
    {
        "georeferenceId": "17836",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "2",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "24.0044660881449",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.583,
        "longitude": -1.3,
        "region": null
    },
    {
        "georeferenceId": "17782",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "2",
        "brownPatchRiskIrrigated": "2",
        "dollarSpotRiskIrrigated": "3",
        "dollarSpotSmithModelRisk": "16.7423023138112",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.35,
        "longitude": 0.517,
        "region": null
    },
    {
        "georeferenceId": "17835",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "3",
        "dollarSpotSmithModelRisk": "10.630466968235",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.9,
        "longitude": -1.4,
        "region": null
    },
    {
        "georeferenceId": "17783",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "2",
        "dollarSpotRiskIrrigated": "3",
        "dollarSpotSmithModelRisk": "11.1576112356026",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.25,
        "longitude": 0.7,
        "region": null
    },
    {
        "georeferenceId": "17834",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "2",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "15.1821688066432",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.783,
        "longitude": -1.833,
        "region": null
    },
    {
        "georeferenceId": "17784",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "2",
        "brownPatchRiskIrrigated": "3",
        "dollarSpotRiskIrrigated": "3",
        "dollarSpotSmithModelRisk": "12.6941834697773",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.117,
        "longitude": 0.967,
        "region": null
    },
    {
        "georeferenceId": "17663",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "16.0661774257844",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "2",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.867,
        "longitude": -4.7,
        "region": null
    },
    {
        "georeferenceId": "17832",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "2",
        "dollarSpotRiskIrrigated": "3",
        "dollarSpotSmithModelRisk": "16.2889622718572",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.95,
        "longitude": -2.633,
        "region": null
    },
    {
        "georeferenceId": "17785",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "21.8499791218991",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.7,
        "longitude": -5.05,
        "region": null
    },
    {
        "georeferenceId": "17786",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "16.7121512553817",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.717,
        "longitude": -4.367,
        "region": null
    },
    {
        "georeferenceId": "17642",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "15.630785136869",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "3",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 60.133,
        "longitude": -1.183,
        "region": null
    },
    {
        "georeferenceId": "17831",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "17.3633874833688",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.867,
        "longitude": -3.233,
        "region": null
    },
    {
        "georeferenceId": "17787",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "17.0348643284659",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.567,
        "longitude": -3.983,
        "region": null
    },
    {
        "georeferenceId": "17830",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "19.630745704784",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.717,
        "longitude": -3.533,
        "region": null
    },
    {
        "georeferenceId": "17788",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "2",
        "dollarSpotRiskIrrigated": "3",
        "dollarSpotSmithModelRisk": "15.7394039537049",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.517,
        "longitude": -2.583,
        "region": null
    },
    {
        "georeferenceId": "17827",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "19.9201511649726",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.467,
        "longitude": -4.717,
        "region": null
    },
    {
        "georeferenceId": "17789",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-08",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "13.3127311580542",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.867,
        "longitude": -1.683,
        "region": null
    },
    {
        "georeferenceId": "17735",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "19.8657215012802",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.1,
        "longitude": -3.933,
        "region": null
    },
    {
        "georeferenceId": "17734",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "23.5612923799069",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.25,
        "longitude": -4.533,
        "region": null
    },
    {
        "georeferenceId": "17873",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "15.3347987009649",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.533,
        "longitude": -1.567,
        "region": null
    },
    {
        "georeferenceId": "17826",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "22.4397693868201",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.417,
        "longitude": -5.083,
        "region": null
    },
    {
        "georeferenceId": "17763",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "11.5750730295281",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.733,
        "longitude": -0.65,
        "region": null
    },
    {
        "georeferenceId": "17784",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "15.8563420787275",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.117,
        "longitude": 0.967,
        "region": null
    },
    {
        "georeferenceId": "17656",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "16.8572244733412",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.867,
        "longitude": -5.633,
        "region": null
    },
    {
        "georeferenceId": "17733",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "13.0611366333082",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.1,
        "longitude": -0.167,
        "region": null
    },
    {
        "georeferenceId": "17850",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "26.2543949347399",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.2,
        "longitude": -6.65,
        "region": null
    },
    {
        "georeferenceId": "17788",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "18.1016462907675",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.517,
        "longitude": -2.583,
        "region": null
    },
    {
        "georeferenceId": "17874",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "11.7797767380277",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.833,
        "longitude": -1.333,
        "region": null
    },
    {
        "georeferenceId": "17732",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "2",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "11.5409719801967",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.483,
        "longitude": -0.6,
        "region": null
    },
    {
        "georeferenceId": "17876",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "11.6997031474374",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.867,
        "longitude": -2.25,
        "region": null
    },
    {
        "georeferenceId": "17810",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "14.1443032051644",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.433,
        "longitude": -1,
        "region": null
    },
    {
        "georeferenceId": "17764",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "11.6045831858227",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.617,
        "longitude": -0.467,
        "region": null
    },
    {
        "georeferenceId": "17659",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "21.2219898121406",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.767,
        "longitude": -6.05,
        "region": null
    },
    {
        "georeferenceId": "17731",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "2",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "12.7746974817314",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.367,
        "longitude": -0.667,
        "region": null
    },
    {
        "georeferenceId": "17877",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "16.1896005973463",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.383,
        "longitude": -2.717,
        "region": null
    },
    {
        "georeferenceId": "17730",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "9.55342168160279",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.683,
        "longitude": -1.217,
        "region": null
    },
    {
        "georeferenceId": "17849",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "23.1313727734242",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.517,
        "longitude": -6.75,
        "region": null
    },
    {
        "georeferenceId": "17729",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "11.5402369502725",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.05,
        "longitude": -1.25,
        "region": null
    },
    {
        "georeferenceId": "17878",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "2",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "15.2915366880259",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.333,
        "longitude": -2.85,
        "region": null
    },
    {
        "georeferenceId": "17728",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "10.8255643131105",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.2,
        "longitude": -1.383,
        "region": null
    },
    {
        "georeferenceId": "17811",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "12.9514303605329",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.283,
        "longitude": -0.767,
        "region": null
    },
    {
        "georeferenceId": "17765",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "2",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "13.9867849034744",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.867,
        "longitude": 0.15,
        "region": null
    },
    {
        "georeferenceId": "17879",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "12.5694005169044",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.867,
        "longitude": -0.367,
        "region": null
    },
    {
        "georeferenceId": "17832",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "17.9516304830001",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.95,
        "longitude": -2.633,
        "region": null
    },
    {
        "georeferenceId": "17727",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "12.3692761197881",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.133,
        "longitude": -1.417,
        "region": null
    },
    {
        "georeferenceId": "17880",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "31.1673312026759",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.417,
        "longitude": -4.117,
        "region": null
    },
    {
        "georeferenceId": "4482343",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "2",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "11.6935060481617",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.39455,
        "longitude": -0.78437,
        "region": null
    },
    {
        "georeferenceId": "17726",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "11.2140595617821",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.3,
        "longitude": -1.533,
        "region": null
    },
    {
        "georeferenceId": "17881",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "2",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "10.2542906642137",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.5,
        "longitude": 0.05,
        "region": null
    },
    {
        "georeferenceId": "17725",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "13.1603819231369",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.917,
        "longitude": -1.383,
        "region": null
    },
    {
        "georeferenceId": "17792",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "18.8223342536802",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.683,
        "longitude": -0.8,
        "region": null
    },
    {
        "georeferenceId": "17766",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "12.6219802756458",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.65,
        "longitude": 0.567,
        "region": null
    },
    {
        "georeferenceId": "17936",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "20.2912760048525",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.117,
        "longitude": -3.15,
        "region": null
    },
    {
        "georeferenceId": "17848",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "27.8202372056488",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.717,
        "longitude": -6.817,
        "region": null
    },
    {
        "georeferenceId": "17809",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "13.4558119506439",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.233,
        "longitude": -0.95,
        "region": null
    },
    {
        "georeferenceId": "17724",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "19.3463545194064",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.417,
        "longitude": -1.6,
        "region": null
    },
    {
        "georeferenceId": "17882",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "25.1165859879312",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 49.717,
        "longitude": -2.2,
        "region": null
    },
    {
        "georeferenceId": "17655",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "16.0032268929345",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "2",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.717,
        "longitude": -4.883,
        "region": null
    },
    {
        "georeferenceId": "17723",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "9.54347218265536",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.817,
        "longitude": -2.083,
        "region": null
    },
    {
        "georeferenceId": "17884",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "16.2483024642877",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.25,
        "longitude": -0.883,
        "region": null
    },
    {
        "georeferenceId": "17932",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "11.8152393266414",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.75,
        "longitude": -2.483,
        "region": null
    },
    {
        "georeferenceId": "17767",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "2",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "15.6312626172703",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.95,
        "longitude": 1.133,
        "region": null
    },
    {
        "georeferenceId": "17808",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "15.4491910534037",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.15,
        "longitude": -1.567,
        "region": null
    },
    {
        "georeferenceId": "17722",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "16.1586881033286",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.283,
        "longitude": -2.283,
        "region": null
    },
    {
        "georeferenceId": "17847",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "24.3813582985475",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.067,
        "longitude": -7.017,
        "region": null
    },
    {
        "georeferenceId": "17791",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "15.3731644525538",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.617,
        "longitude": -1.083,
        "region": null
    },
    {
        "georeferenceId": "17885",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "25.0651619122992",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.05,
        "longitude": -7.15,
        "region": null
    },
    {
        "georeferenceId": "17721",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "20.2959344915552",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.683,
        "longitude": -2.45,
        "region": null
    },
    {
        "georeferenceId": "17886",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "12.4574731564716",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.567,
        "longitude": 0.7,
        "region": null
    },
    {
        "georeferenceId": "17719",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "15.9890370314401",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.5,
        "longitude": -2.683,
        "region": null
    },
    {
        "georeferenceId": "17785",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "27.3904906390419",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.7,
        "longitude": -5.05,
        "region": null
    },
    {
        "georeferenceId": "17768",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "2",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "18.316106965024",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.633,
        "longitude": 1.3,
        "region": null
    },
    {
        "georeferenceId": "17718",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "20.1372210927668",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.05,
        "longitude": -2.55,
        "region": null
    },
    {
        "georeferenceId": "17891",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "26.9436384476454",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 58.033,
        "longitude": -4.4,
        "region": null
    },
    {
        "georeferenceId": "17717",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "18.2469078608964",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.933,
        "longitude": -2.95,
        "region": null
    },
    {
        "georeferenceId": "17845",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "26.8869860645723",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.6,
        "longitude": -7.3,
        "region": null
    },
    {
        "georeferenceId": "17663",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "18.4966875240379",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "2",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.867,
        "longitude": -4.7,
        "region": null
    },
    {
        "georeferenceId": "17893",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "28.1394283444241",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.683,
        "longitude": -2.017,
        "region": null
    },
    {
        "georeferenceId": "17715",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "19.7635272974835",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.767,
        "longitude": -3.317,
        "region": null
    },
    {
        "georeferenceId": "17894",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "23.6348522121688",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.633,
        "longitude": -6.183,
        "region": null
    },
    {
        "georeferenceId": "17813",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "13.3538482733191",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.15,
        "longitude": -0.183,
        "region": null
    },
    {
        "georeferenceId": "17769",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "2",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "13.9458353144096",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.8,
        "longitude": 1.25,
        "region": null
    },
    {
        "georeferenceId": "17714",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "20.0097675661378",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.117,
        "longitude": -3.217,
        "region": null
    },
    {
        "georeferenceId": "17895",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "21.6133915572976",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.417,
        "longitude": -5.467,
        "region": null
    },
    {
        "georeferenceId": "4482344",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "14.3041830323293",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.68495,
        "longitude": -2.76495,
        "region": null
    },
    {
        "georeferenceId": "17713",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "21.2119605873905",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.317,
        "longitude": -3.4,
        "region": null
    },
    {
        "georeferenceId": "17844",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "20.3709679999884",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.4,
        "longitude": -7.65,
        "region": null
    },
    {
        "georeferenceId": "4482348",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "11.6692408750135",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.65863,
        "longitude": -0.49627,
        "region": null
    },
    {
        "georeferenceId": "17712",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "15.4244409334517",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.617,
        "longitude": -3.167,
        "region": null
    },
    {
        "georeferenceId": "17896",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "26.6479935467326",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.6,
        "longitude": -4.5,
        "region": null
    },
    {
        "georeferenceId": "17806",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "15.3613032408969",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.2,
        "longitude": -1.8,
        "region": null
    },
    {
        "georeferenceId": "17770",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "2",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "19.2074272456801",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.683,
        "longitude": 1.683,
        "region": null
    },
    {
        "georeferenceId": "17654",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "22.994248401357",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.417,
        "longitude": -6.2,
        "region": null
    },
    {
        "georeferenceId": "17816",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "14.3935554364515",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.3,
        "longitude": -0.083,
        "region": null
    },
    {
        "georeferenceId": "17711",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "23.6582514358369",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.517,
        "longitude": -3.6,
        "region": null
    },
    {
        "georeferenceId": "17897",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "18.7586099900572",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.767,
        "longitude": -4.167,
        "region": null
    },
    {
        "georeferenceId": "17710",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "18.3545588720744",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.933,
        "longitude": -3.15,
        "region": null
    },
    {
        "georeferenceId": "17898",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "20.1190865445137",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.783,
        "longitude": -4,
        "region": null
    },
    {
        "georeferenceId": "17708",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "25.4484499051532",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.083,
        "longitude": -4.633,
        "region": null
    },
    {
        "georeferenceId": "17899",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "20.6440546871918",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.6,
        "longitude": -2.433,
        "region": null
    },
    {
        "georeferenceId": "17831",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "19.3474779990953",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.867,
        "longitude": -3.233,
        "region": null
    },
    {
        "georeferenceId": "17771",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "19.3842056073248",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.133,
        "longitude": -4.567,
        "region": null
    },
    {
        "georeferenceId": "17707",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "18.9952938072474",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.433,
        "longitude": -2.783,
        "region": null
    },
    {
        "georeferenceId": "17843",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "21.0376079217991",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.433,
        "longitude": -8.05,
        "region": null
    },
    {
        "georeferenceId": "17805",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "16.5202111964662",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.5,
        "longitude": -1.983,
        "region": null
    },
    {
        "georeferenceId": "17706",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "21.3184466186172",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.383,
        "longitude": -2.867,
        "region": null
    },
    {
        "georeferenceId": "17900",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "21.229614339709",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.9,
        "longitude": -3.633,
        "region": null
    },
    {
        "georeferenceId": "17705",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "14.2659803800641",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.983,
        "longitude": -2.433,
        "region": null
    },
    {
        "georeferenceId": "17901",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "17.1846626260315",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.467,
        "longitude": -2.567,
        "region": null
    },
    {
        "georeferenceId": "17704",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "18.6422133926052",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.6,
        "longitude": -3.55,
        "region": null
    },
    {
        "georeferenceId": "17817",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "11.5441575802889",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.433,
        "longitude": 0.367,
        "region": null
    },
    {
        "georeferenceId": "17772",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "19.436389632453",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.35,
        "longitude": -3.95,
        "region": null
    },
    {
        "georeferenceId": "17903",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "14.8398180462979",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.067,
        "longitude": -2.833,
        "region": null
    },
    {
        "georeferenceId": "17703",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "22.60477358359",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.317,
        "longitude": -3.2,
        "region": null
    },
    {
        "georeferenceId": "17842",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "25.7198631546397",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 49.217,
        "longitude": -2.2,
        "region": null
    },
    {
        "georeferenceId": "17802",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "17.1072677724704",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.4,
        "longitude": -3.433,
        "region": null
    },
    {
        "georeferenceId": "17904",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "15.9433119654461",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.567,
        "longitude": -2.417,
        "region": null
    },
    {
        "georeferenceId": "17702",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "17.6290604070175",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.95,
        "longitude": -3.35,
        "region": null
    },
    {
        "georeferenceId": "17701",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "18.6262024577721",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.617,
        "longitude": -2.8,
        "region": null
    },
    {
        "georeferenceId": "17906",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "13.1214827247421",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.767,
        "longitude": -2.717,
        "region": null
    },
    {
        "georeferenceId": "17827",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "24.1515630113742",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.467,
        "longitude": -4.717,
        "region": null
    },
    {
        "georeferenceId": "17773",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "18.9521244932573",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.067,
        "longitude": -3.617,
        "region": null
    },
    {
        "georeferenceId": "17786",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "21.9556627701327",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.717,
        "longitude": -4.367,
        "region": null
    },
    {
        "georeferenceId": "17653",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "23.7389710719572",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 58.217,
        "longitude": -6.317,
        "region": null
    },
    {
        "georeferenceId": "17700",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "28.2268661682617",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.433,
        "longitude": -3.367,
        "region": null
    },
    {
        "georeferenceId": "17841",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "26.3353285392042",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 49.433,
        "longitude": -2.6,
        "region": null
    },
    {
        "georeferenceId": "17789",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "16.3547262867733",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.867,
        "longitude": -1.683,
        "region": null
    },
    {
        "georeferenceId": "17907",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "9.97113430081842",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.05,
        "longitude": -3,
        "region": null
    },
    {
        "georeferenceId": "17699",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "21.7123608921033",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.667,
        "longitude": -3.783,
        "region": null
    },
    {
        "georeferenceId": "17908",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "9.01310381754889",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53,
        "longitude": -2.183,
        "region": null
    },
    {
        "georeferenceId": "17825",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "37.8480202140177",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.15,
        "longitude": -5.067,
        "region": null
    },
    {
        "georeferenceId": "17774",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "16.2616974093604",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.083,
        "longitude": -2.8,
        "region": null
    },
    {
        "georeferenceId": "17660",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "0",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "2",
        "poaAnnuaSeedheadsRisk": "2",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.817,
        "longitude": -4.967,
        "region": null
    },
    {
        "georeferenceId": "17698",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "23.4671947125733",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.05,
        "longitude": -3.65,
        "region": null
    },
    {
        "georeferenceId": "17909",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "13.0499671735949",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.367,
        "longitude": -1.5,
        "region": null
    },
    {
        "georeferenceId": "17697",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "29.3873706946795",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.8,
        "longitude": -4,
        "region": null
    },
    {
        "georeferenceId": "17840",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "19.5637276732411",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.8,
        "longitude": 0.25,
        "region": null
    },
    {
        "georeferenceId": "17696",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "19.0896414778109",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "2",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.417,
        "longitude": -4.317,
        "region": null
    },
    {
        "georeferenceId": "17910",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "2",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "18.316106965024",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.933,
        "longitude": 1.283,
        "region": null
    },
    {
        "georeferenceId": "4482345",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "2",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "11.2276872936945",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.03516,
        "longitude": 0.09752,
        "region": null
    },
    {
        "georeferenceId": "17818",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "16.1804845905839",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.2,
        "longitude": 0.783,
        "region": null
    },
    {
        "georeferenceId": "17775",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "10.9431231032641",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.2,
        "longitude": -2.217,
        "region": null
    },
    {
        "georeferenceId": "17694",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "25.2205495516348",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.317,
        "longitude": -3.733,
        "region": null
    },
    {
        "georeferenceId": "17642",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "23.2892138099896",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "3",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 60.133,
        "longitude": -1.183,
        "region": null
    },
    {
        "georeferenceId": "17911",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "16.9232565124025",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.083,
        "longitude": -4.15,
        "region": null
    },
    {
        "georeferenceId": "17693",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "21.2537049308117",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.867,
        "longitude": -4.433,
        "region": null
    },
    {
        "georeferenceId": "17912",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "14.814964423457",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.35,
        "longitude": -2.983,
        "region": null
    },
    {
        "georeferenceId": "17691",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "22.0739176583255",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.95,
        "longitude": -4.767,
        "region": null
    },
    {
        "georeferenceId": "17913",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "16.4181906334439",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.483,
        "longitude": -0.6,
        "region": null
    },
    {
        "georeferenceId": "17690",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "22.6606665021114",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.467,
        "longitude": -4.617,
        "region": null
    },
    {
        "georeferenceId": "17801",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "15.247444292688",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.5,
        "longitude": -3.2,
        "region": null
    },
    {
        "georeferenceId": "17776",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "18.1240724275538",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.117,
        "longitude": -2.3,
        "region": null
    },
    {
        "georeferenceId": "17640",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "22.961966937367",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "3",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 60.75,
        "longitude": -0.85,
        "region": null
    },
    {
        "georeferenceId": "17839",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "22.1119216335018",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.833,
        "longitude": -0.283,
        "region": null
    },
    {
        "georeferenceId": "17665",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "17.7128586654702",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.267,
        "longitude": -4.483,
        "region": null
    },
    {
        "georeferenceId": "17689",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "30.4307722085047",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.85,
        "longitude": -4.95,
        "region": null
    },
    {
        "georeferenceId": "17914",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "12.1040672479186",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.2,
        "longitude": 0.183,
        "region": null
    },
    {
        "georeferenceId": "17651",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "23.6595518613438",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "2",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.467,
        "longitude": -7.367,
        "region": null
    },
    {
        "georeferenceId": "17688",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "27.527693006486",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.617,
        "longitude": -4.85,
        "region": null
    },
    {
        "georeferenceId": "17915",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "14.487256632409",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.267,
        "longitude": 0.517,
        "region": null
    },
    {
        "georeferenceId": "17800",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "20.5126519647292",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.083,
        "longitude": -3.6,
        "region": null
    },
    {
        "georeferenceId": "17777",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "15.2728933641425",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.15,
        "longitude": -2.033,
        "region": null
    },
    {
        "georeferenceId": "17935",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "20.6999413713892",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56,
        "longitude": -3.783,
        "region": null
    },
    {
        "georeferenceId": "17687",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "20.9746851626372",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.7,
        "longitude": -5.3,
        "region": null
    },
    {
        "georeferenceId": "17838",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "20.3503424038846",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.8,
        "longitude": -1.217,
        "region": null
    },
    {
        "georeferenceId": "17820",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "23.8133482348262",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.133,
        "longitude": 1.3,
        "region": null
    },
    {
        "georeferenceId": "17916",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "24.199076585735",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.083,
        "longitude": 1.183,
        "region": null
    },
    {
        "georeferenceId": "17686",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "24.9216374883601",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.233,
        "longitude": -5.083,
        "region": null
    },
    {
        "georeferenceId": "17917",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "20.5772950175923",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.283,
        "longitude": 1.083,
        "region": null
    },
    {
        "georeferenceId": "17685",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "29.9421949453084",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "2",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.433,
        "longitude": -5.7,
        "region": null
    },
    {
        "georeferenceId": "17933",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "12.3536760037809",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.317,
        "longitude": 0.033,
        "region": null
    },
    {
        "georeferenceId": "17778",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "12.0895206628928",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.45,
        "longitude": -1.733,
        "region": null
    },
    {
        "georeferenceId": "17787",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "22.6594046831424",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.567,
        "longitude": -3.983,
        "region": null
    },
    {
        "georeferenceId": "17683",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "26.1722304464318",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.5,
        "longitude": -6.883,
        "region": null
    },
    {
        "georeferenceId": "17919",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "19.7023252772717",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.517,
        "longitude": -4.833,
        "region": null
    },
    {
        "georeferenceId": "17837",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "18.7403291390783",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.817,
        "longitude": -0.917,
        "region": null
    },
    {
        "georeferenceId": "17797",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "15.490932284681",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.883,
        "longitude": 0.9,
        "region": null
    },
    {
        "georeferenceId": "17682",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "25.1934011482994",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.65,
        "longitude": -3.383,
        "region": null
    },
    {
        "georeferenceId": "17920",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "22.4581537153649",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.45,
        "longitude": -4.45,
        "region": null
    },
    {
        "georeferenceId": "4482346",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "22.6606665021114",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.46997,
        "longitude": -4.564,
        "region": null
    },
    {
        "georeferenceId": "17830",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "23.7433162058579",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.717,
        "longitude": -3.533,
        "region": null
    },
    {
        "georeferenceId": "17780",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "13.5860217070845",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.367,
        "longitude": -1.333,
        "region": null
    },
    {
        "georeferenceId": "17681",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "27.1634019225074",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.5,
        "longitude": -1.767,
        "region": null
    },
    {
        "georeferenceId": "17921",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "21.0340199978037",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.467,
        "longitude": -3.533,
        "region": null
    },
    {
        "georeferenceId": "17680",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "21.9503170706499",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "2",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.2,
        "longitude": -2.217,
        "region": null
    },
    {
        "georeferenceId": "17922",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "25.7675775011701",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.6,
        "longitude": -2.45,
        "region": null
    },
    {
        "georeferenceId": "17836",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "25.4643898512179",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.583,
        "longitude": -1.3,
        "region": null
    },
    {
        "georeferenceId": "17679",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "24.3031238100328",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.717,
        "longitude": -2.467,
        "region": null
    },
    {
        "georeferenceId": "17678",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "17.8778684486482",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "2",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.083,
        "longitude": -2.833,
        "region": null
    },
    {
        "georeferenceId": "17923",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "21.8960906420534",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.067,
        "longitude": -1.317,
        "region": null
    },
    {
        "georeferenceId": "17790",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "13.5123680802972",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.75,
        "longitude": -1.583,
        "region": null
    },
    {
        "georeferenceId": "17781",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "14.9194591900027",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.217,
        "longitude": -0.483,
        "region": null
    },
    {
        "georeferenceId": "17649",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "29.4990526093581",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "3",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 58.95,
        "longitude": -2.9,
        "region": null
    },
    {
        "georeferenceId": "17821",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "20.6169388118073",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.35,
        "longitude": 1.417,
        "region": null
    },
    {
        "georeferenceId": "17676",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "28.869634373037",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "4",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 58.45,
        "longitude": -3.083,
        "region": null
    },
    {
        "georeferenceId": "17924",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "24.9431985074657",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.867,
        "longitude": 0.6,
        "region": null
    },
    {
        "georeferenceId": "17675",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "24.6785966474688",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 58.617,
        "longitude": -3.55,
        "region": null
    },
    {
        "georeferenceId": "17925",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "26.1050443639103",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.817,
        "longitude": -7.467,
        "region": null
    },
    {
        "georeferenceId": "17674",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "0",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "2",
        "poaAnnuaSeedheadsRisk": "2",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.883,
        "longitude": -3.417,
        "region": null
    },
    {
        "georeferenceId": "17926",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "24.0628658437365",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.717,
        "longitude": -6.517,
        "region": null
    },
    {
        "georeferenceId": "17794",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "13.8018746810901",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.883,
        "longitude": 0.233,
        "region": null
    },
    {
        "georeferenceId": "17782",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "20.7362092849516",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.35,
        "longitude": 0.517,
        "region": null
    },
    {
        "georeferenceId": "17673",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "18.9068224583073",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.35,
        "longitude": -3.35,
        "region": null
    },
    {
        "georeferenceId": "17835",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "9.09174460475171",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.9,
        "longitude": -1.4,
        "region": null
    },
    {
        "georeferenceId": "17823",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "22.5163017496386",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.217,
        "longitude": -5.317,
        "region": null
    },
    {
        "georeferenceId": "17672",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "22.0153512896809",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.717,
        "longitude": -3.317,
        "region": null
    },
    {
        "georeferenceId": "17927",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "24.6955530600323",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.183,
        "longitude": -6.333,
        "region": null
    },
    {
        "georeferenceId": "17671",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "21.8079738348019",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.65,
        "longitude": -3.567,
        "region": null
    },
    {
        "georeferenceId": "17856",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "22.2071364481992",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.6,
        "longitude": -5.883,
        "region": null
    },
    {
        "georeferenceId": "17929",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "19.4161040507522",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 58.283,
        "longitude": -4.433,
        "region": null
    },
    {
        "georeferenceId": "17756",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "16.9381083092777",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53,
        "longitude": -4.083,
        "region": null
    },
    {
        "georeferenceId": "17754",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "2",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "14.7357844703398",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.083,
        "longitude": 0.267,
        "region": null
    },
    {
        "georeferenceId": "17857",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "28.0705660210084",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.667,
        "longitude": -5.667,
        "region": null
    },
    {
        "georeferenceId": "17658",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "18.6709869186962",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.25,
        "longitude": -5.8,
        "region": null
    },
    {
        "georeferenceId": "17753",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "10.8058866283158",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.083,
        "longitude": -0.167,
        "region": null
    },
    {
        "georeferenceId": "17669",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "12.9273680730553",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "2",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.2,
        "longitude": -3.833,
        "region": null
    },
    {
        "georeferenceId": "17757",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "25.7427955329605",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.783,
        "longitude": -4.733,
        "region": null
    },
    {
        "georeferenceId": "17858",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "23.8874490807065",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.85,
        "longitude": -5.8,
        "region": null
    },
    {
        "georeferenceId": "17752",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "2",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "11.4170858935828",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.483,
        "longitude": -0.083,
        "region": null
    },
    {
        "georeferenceId": "17855",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "22.2162592862941",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.35,
        "longitude": -6.667,
        "region": null
    },
    {
        "georeferenceId": "17793",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "2",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "12.1549710643652",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.533,
        "longitude": -0.417,
        "region": null
    },
    {
        "georeferenceId": "17859",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "11.496942184421",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.867,
        "longitude": -1.65,
        "region": null
    },
    {
        "georeferenceId": "17751",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "13.5682802631017",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.867,
        "longitude": -0.433,
        "region": null
    },
    {
        "georeferenceId": "17860",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "15.284698638137",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.567,
        "longitude": -1.167,
        "region": null
    },
    {
        "georeferenceId": "17750",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "11.3391608262552",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.033,
        "longitude": -0.5,
        "region": null
    },
    {
        "georeferenceId": "17783",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "12.379164941886",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.25,
        "longitude": 0.7,
        "region": null
    },
    {
        "georeferenceId": "17758",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "19.2402229466395",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.8,
        "longitude": -4.117,
        "region": null
    },
    {
        "georeferenceId": "17749",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "2",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "10.5436406985693",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.167,
        "longitude": -0.517,
        "region": null
    },
    {
        "georeferenceId": "17861",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "9.730309905539",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.367,
        "longitude": -1.483,
        "region": null
    },
    {
        "georeferenceId": "51522",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "2",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "18.092753022934",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.817,
        "longitude": 1.133,
        "region": null
    },
    {
        "georeferenceId": "17854",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "23.00232373924",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.483,
        "longitude": -6.1,
        "region": null
    },
    {
        "georeferenceId": "17930",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "22.4080398355392",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.95,
        "longitude": -3.983,
        "region": null
    },
    {
        "georeferenceId": "17748",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "2",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "11.6935060481617",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.4,
        "longitude": -0.767,
        "region": null
    },
    {
        "georeferenceId": "17862",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "12.837406202104",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.9,
        "longitude": -2.083,
        "region": null
    },
    {
        "georeferenceId": "17747",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "10.3269619922546",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.833,
        "longitude": -1.2,
        "region": null
    },
    {
        "georeferenceId": "4482347",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "12.3947926932452",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.64729,
        "longitude": -0.39027,
        "region": null
    },
    {
        "georeferenceId": "17759",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "11.4238839866438",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.95,
        "longitude": -3.417,
        "region": null
    },
    {
        "georeferenceId": "17863",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "14.5378747822247",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.383,
        "longitude": -2.35,
        "region": null
    },
    {
        "georeferenceId": "17746",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "2",
        "brownPatchRiskIrrigated": "2",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "12.0790666552148",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53,
        "longitude": -1.25,
        "region": null
    },
    {
        "georeferenceId": "17864",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "3.65168922720805",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52,
        "longitude": -0.733,
        "region": null
    },
    {
        "georeferenceId": "17745",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "2",
        "brownPatchRiskIrrigated": "2",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "12.687238347681",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.8,
        "longitude": -1.55,
        "region": null
    },
    {
        "georeferenceId": "17853",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "24.1779515886999",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.65,
        "longitude": -6.217,
        "region": null
    },
    {
        "georeferenceId": "17744",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "11.2768189401625",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.65,
        "longitude": -1.783,
        "region": null
    },
    {
        "georeferenceId": "17865",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "19.9794377114538",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.833,
        "longitude": -4.533,
        "region": null
    },
    {
        "georeferenceId": "17657",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "23.2176862021753",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "2",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.033,
        "longitude": -7.45,
        "region": null
    },
    {
        "georeferenceId": "17834",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "16.6796973011609",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.783,
        "longitude": -1.833,
        "region": null
    },
    {
        "georeferenceId": "17760",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "16.2842602082801",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.75,
        "longitude": -3.467,
        "region": null
    },
    {
        "georeferenceId": "17743",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "12.5926286996402",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.817,
        "longitude": -1.867,
        "region": null
    },
    {
        "georeferenceId": "17824",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "26.4780483105771",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.083,
        "longitude": -5.25,
        "region": null
    },
    {
        "georeferenceId": "17866",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "19.2309016505513",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.133,
        "longitude": -3.017,
        "region": null
    },
    {
        "georeferenceId": "17742",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "13.6778558074119",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.967,
        "longitude": -2.033,
        "region": null
    },
    {
        "georeferenceId": "17867",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "10.2786115244039",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.2,
        "longitude": -1.7,
        "region": null
    },
    {
        "georeferenceId": "17741",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "2",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "9.08361497382414",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.5,
        "longitude": -2.217,
        "region": null
    },
    {
        "georeferenceId": "17868",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "23.2365169191222",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "3",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 60.433,
        "longitude": -1.3,
        "region": null
    },
    {
        "georeferenceId": "17740",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "12.6177458102941",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.2,
        "longitude": -2.9,
        "region": null
    },
    {
        "georeferenceId": "17668",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "22.6446871325344",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.817,
        "longitude": -3.967,
        "region": null
    },
    {
        "georeferenceId": "17761",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "13.2288286775886",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.567,
        "longitude": -3.717,
        "region": null
    },
    {
        "georeferenceId": "17739",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "15.3850331580406",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.767,
        "longitude": -3.033,
        "region": null
    },
    {
        "georeferenceId": "17852",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "28.7810038851885",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.183,
        "longitude": -6.167,
        "region": null
    },
    {
        "georeferenceId": "17931",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "25.0552460376878",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.067,
        "longitude": -3.467,
        "region": null
    },
    {
        "georeferenceId": "17869",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "23.5980524863919",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.45,
        "longitude": -3.017,
        "region": null
    },
    {
        "georeferenceId": "17738",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "11.8849338005928",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.5,
        "longitude": -3.067,
        "region": null
    },
    {
        "georeferenceId": "17870",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "2",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "9.86286089727547",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.783,
        "longitude": -1.75,
        "region": null
    },
    {
        "georeferenceId": "4482342",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "10.3052016098141",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.85426,
        "longitude": -1.50702,
        "region": null
    },
    {
        "georeferenceId": "17737",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "9.54222914719949",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.217,
        "longitude": -3.217,
        "region": null
    },
    {
        "georeferenceId": "17667",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "18.0611206203407",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.533,
        "longitude": -4.05,
        "region": null
    },
    {
        "georeferenceId": "17762",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "13.3274893504176",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.8,
        "longitude": -2.667,
        "region": null
    },
    {
        "georeferenceId": "17871",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "2",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "14.3088907827918",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.583,
        "longitude": -0.35,
        "region": null
    },
    {
        "georeferenceId": "17736",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "13.3607921572923",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.217,
        "longitude": -3.5,
        "region": null
    },
    {
        "georeferenceId": "17851",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "25.3120915964539",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.867,
        "longitude": -6.45,
        "region": null
    },
    {
        "georeferenceId": "17666",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "23.404751275739",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.683,
        "longitude": -4.167,
        "region": null
    },
    {
        "georeferenceId": "17872",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-09",
        "anthracnoseFoliarBlight": "2",
        "brownPatchRiskIrrigated": "2",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "13.5185397555013",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "1",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.033,
        "longitude": -1.7,
        "region": null
    },
    {
        "georeferenceId": "17789",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "19.1579419133521",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.867,
        "longitude": -1.683,
        "region": null
    },
    {
        "georeferenceId": "17640",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "30.9043249278133",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 60.75,
        "longitude": -0.85,
        "region": null
    },
    {
        "georeferenceId": "17642",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "30.6991969049237",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 60.133,
        "longitude": -1.183,
        "region": null
    },
    {
        "georeferenceId": "17649",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "32.50358707129",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 58.95,
        "longitude": -2.9,
        "region": null
    },
    {
        "georeferenceId": "17651",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "27.2099609885196",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.467,
        "longitude": -7.367,
        "region": null
    },
    {
        "georeferenceId": "17653",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "27.8568789994051",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 58.217,
        "longitude": -6.317,
        "region": null
    },
    {
        "georeferenceId": "17654",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "26.4131567852071",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.417,
        "longitude": -6.2,
        "region": null
    },
    {
        "georeferenceId": "17655",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "18.2061292757461",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.717,
        "longitude": -4.883,
        "region": null
    },
    {
        "georeferenceId": "17656",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "19.2107793943455",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.867,
        "longitude": -5.633,
        "region": null
    },
    {
        "georeferenceId": "17657",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "28.0705660210084",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.033,
        "longitude": -7.45,
        "region": null
    },
    {
        "georeferenceId": "17658",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "20.4559689163509",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.25,
        "longitude": -5.8,
        "region": null
    },
    {
        "georeferenceId": "17659",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "24.0975278247687",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.767,
        "longitude": -6.05,
        "region": null
    },
    {
        "georeferenceId": "17660",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "0",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "3",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.817,
        "longitude": -4.967,
        "region": null
    },
    {
        "georeferenceId": "17663",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "20.1603893395433",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.867,
        "longitude": -4.7,
        "region": null
    },
    {
        "georeferenceId": "17665",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "20.9699118721195",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.267,
        "longitude": -4.483,
        "region": null
    },
    {
        "georeferenceId": "17666",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "26.4855243820648",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.683,
        "longitude": -4.167,
        "region": null
    },
    {
        "georeferenceId": "17667",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "18.7864231455133",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.533,
        "longitude": -4.05,
        "region": null
    },
    {
        "georeferenceId": "17668",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "24.5774675367482",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.817,
        "longitude": -3.967,
        "region": null
    },
    {
        "georeferenceId": "17669",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "13.2010288356624",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.2,
        "longitude": -3.833,
        "region": null
    },
    {
        "georeferenceId": "17671",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "20.8021632661698",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.65,
        "longitude": -3.567,
        "region": null
    },
    {
        "georeferenceId": "17672",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "21.131051524935",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.717,
        "longitude": -3.317,
        "region": null
    },
    {
        "georeferenceId": "17673",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "18.6331149694021",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.35,
        "longitude": -3.35,
        "region": null
    },
    {
        "georeferenceId": "17674",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "18.7882540692346",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "2",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.883,
        "longitude": -3.417,
        "region": null
    },
    {
        "georeferenceId": "17675",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "29.0365000220568",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 58.617,
        "longitude": -3.55,
        "region": null
    },
    {
        "georeferenceId": "17676",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "30.6558134300239",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 58.45,
        "longitude": -3.083,
        "region": null
    },
    {
        "georeferenceId": "17678",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "18.2705489263273",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.083,
        "longitude": -2.833,
        "region": null
    },
    {
        "georeferenceId": "17679",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "27.1919015425484",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.717,
        "longitude": -2.467,
        "region": null
    },
    {
        "georeferenceId": "17680",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "22.6526758209789",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.2,
        "longitude": -2.217,
        "region": null
    },
    {
        "georeferenceId": "17681",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "32.185325297447",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.5,
        "longitude": -1.767,
        "region": null
    },
    {
        "georeferenceId": "17682",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "28.462303642475",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.65,
        "longitude": -3.383,
        "region": null
    },
    {
        "georeferenceId": "17683",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "30.7073670297124",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.5,
        "longitude": -6.883,
        "region": null
    },
    {
        "georeferenceId": "17685",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "33.260998895303",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.433,
        "longitude": -5.7,
        "region": null
    },
    {
        "georeferenceId": "17686",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "27.8660440754652",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.233,
        "longitude": -5.083,
        "region": null
    },
    {
        "georeferenceId": "17687",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "24.8861787631895",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.7,
        "longitude": -5.3,
        "region": null
    },
    {
        "georeferenceId": "17688",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "31.5765682704787",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.617,
        "longitude": -4.85,
        "region": null
    },
    {
        "georeferenceId": "17689",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "34.2435396262636",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.85,
        "longitude": -4.95,
        "region": null
    },
    {
        "georeferenceId": "17690",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "24.9189432366053",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.467,
        "longitude": -4.617,
        "region": null
    },
    {
        "georeferenceId": "17691",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "27.2189935274435",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.95,
        "longitude": -4.767,
        "region": null
    },
    {
        "georeferenceId": "17693",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "25.3465899727038",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.867,
        "longitude": -4.433,
        "region": null
    },
    {
        "georeferenceId": "17694",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "28.6694624951102",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.317,
        "longitude": -3.733,
        "region": null
    },
    {
        "georeferenceId": "17696",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "23.041027925383",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "2",
        "takeAllPatchRiskIrrigated": "2",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.417,
        "longitude": -4.317,
        "region": null
    },
    {
        "georeferenceId": "17697",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "33.3889827556996",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.8,
        "longitude": -4,
        "region": null
    },
    {
        "georeferenceId": "17698",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "28.2453463240689",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.05,
        "longitude": -3.65,
        "region": null
    },
    {
        "georeferenceId": "17699",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "25.7905376513737",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.667,
        "longitude": -3.783,
        "region": null
    },
    {
        "georeferenceId": "17700",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "31.4719171655852",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.433,
        "longitude": -3.367,
        "region": null
    },
    {
        "georeferenceId": "17701",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "21.7834287843649",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.617,
        "longitude": -2.8,
        "region": null
    },
    {
        "georeferenceId": "17702",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "22.1640465463402",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.95,
        "longitude": -3.35,
        "region": null
    },
    {
        "georeferenceId": "17703",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "26.8690618521599",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.317,
        "longitude": -3.2,
        "region": null
    },
    {
        "georeferenceId": "17704",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "21.5224503416565",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.6,
        "longitude": -3.55,
        "region": null
    },
    {
        "georeferenceId": "17705",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "15.2248176820678",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.983,
        "longitude": -2.433,
        "region": null
    },
    {
        "georeferenceId": "17706",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "23.7103069250937",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.383,
        "longitude": -2.867,
        "region": null
    },
    {
        "georeferenceId": "17707",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "22.1980162871973",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.433,
        "longitude": -2.783,
        "region": null
    },
    {
        "georeferenceId": "17708",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "28.6292336347847",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.083,
        "longitude": -4.633,
        "region": null
    },
    {
        "georeferenceId": "17710",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "20.6503461936793",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.933,
        "longitude": -3.15,
        "region": null
    },
    {
        "georeferenceId": "17711",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "26.2999585204113",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.517,
        "longitude": -3.6,
        "region": null
    },
    {
        "georeferenceId": "17712",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "16.8350354729395",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.617,
        "longitude": -3.167,
        "region": null
    },
    {
        "georeferenceId": "17713",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "22.692649151807",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.317,
        "longitude": -3.4,
        "region": null
    },
    {
        "georeferenceId": "17714",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "22.2295336477283",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.117,
        "longitude": -3.217,
        "region": null
    },
    {
        "georeferenceId": "17715",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "21.2348322745632",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.767,
        "longitude": -3.317,
        "region": null
    },
    {
        "georeferenceId": "17717",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "20.7622564613324",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.933,
        "longitude": -2.95,
        "region": null
    },
    {
        "georeferenceId": "17718",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "22.6194727784876",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.05,
        "longitude": -2.55,
        "region": null
    },
    {
        "georeferenceId": "17719",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "18.6629706021992",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.5,
        "longitude": -2.683,
        "region": null
    },
    {
        "georeferenceId": "17721",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "22.6564606320719",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.683,
        "longitude": -2.45,
        "region": null
    },
    {
        "georeferenceId": "17722",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "19.3096814501114",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.283,
        "longitude": -2.283,
        "region": null
    },
    {
        "georeferenceId": "17723",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "10.8093568752432",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.817,
        "longitude": -2.083,
        "region": null
    },
    {
        "georeferenceId": "17724",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "23.9110200797842",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.417,
        "longitude": -1.6,
        "region": null
    },
    {
        "georeferenceId": "17725",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "15.3662965220423",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.917,
        "longitude": -1.383,
        "region": null
    },
    {
        "georeferenceId": "17726",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "13.7388942866883",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.3,
        "longitude": -1.533,
        "region": null
    },
    {
        "georeferenceId": "17727",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "13.0002121905663",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.133,
        "longitude": -1.417,
        "region": null
    },
    {
        "georeferenceId": "17728",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "13.2315838350232",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.2,
        "longitude": -1.383,
        "region": null
    },
    {
        "georeferenceId": "17729",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "11.8794054769558",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.05,
        "longitude": -1.25,
        "region": null
    },
    {
        "georeferenceId": "17730",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "10.5665256390545",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.683,
        "longitude": -1.217,
        "region": null
    },
    {
        "georeferenceId": "17731",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "15.6081713284866",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.367,
        "longitude": -0.667,
        "region": null
    },
    {
        "georeferenceId": "17732",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "13.687776729228",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.483,
        "longitude": -0.6,
        "region": null
    },
    {
        "georeferenceId": "17733",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "16.4650108640617",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.1,
        "longitude": -0.167,
        "region": null
    },
    {
        "georeferenceId": "17734",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "25.4097659771629",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.25,
        "longitude": -4.533,
        "region": null
    },
    {
        "georeferenceId": "17735",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "22.7377313856556",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.1,
        "longitude": -3.933,
        "region": null
    },
    {
        "georeferenceId": "17736",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "15.5563964056256",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.217,
        "longitude": -3.5,
        "region": null
    },
    {
        "georeferenceId": "17737",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "10.4410866141216",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.217,
        "longitude": -3.217,
        "region": null
    },
    {
        "georeferenceId": "17738",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "12.7293039924063",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.5,
        "longitude": -3.067,
        "region": null
    },
    {
        "georeferenceId": "17739",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "17.0922981736711",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.767,
        "longitude": -3.033,
        "region": null
    },
    {
        "georeferenceId": "17740",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "15.2034562168613",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.2,
        "longitude": -2.9,
        "region": null
    },
    {
        "georeferenceId": "17741",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "9.81049797939399",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.5,
        "longitude": -2.217,
        "region": null
    },
    {
        "georeferenceId": "17742",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "14.0058520620158",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.967,
        "longitude": -2.033,
        "region": null
    },
    {
        "georeferenceId": "17743",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "14.5925276787567",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.817,
        "longitude": -1.867,
        "region": null
    },
    {
        "georeferenceId": "17744",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "12.542786124257",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.65,
        "longitude": -1.783,
        "region": null
    },
    {
        "georeferenceId": "17745",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "15.5365445388553",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.8,
        "longitude": -1.55,
        "region": null
    },
    {
        "georeferenceId": "17746",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "14.4768534378755",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53,
        "longitude": -1.25,
        "region": null
    },
    {
        "georeferenceId": "17747",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "10.9276956146598",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.833,
        "longitude": -1.2,
        "region": null
    },
    {
        "georeferenceId": "17748",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "13.6181749678633",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.4,
        "longitude": -0.767,
        "region": null
    },
    {
        "georeferenceId": "17749",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "12.9395296367636",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.167,
        "longitude": -0.517,
        "region": null
    },
    {
        "georeferenceId": "17750",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "13.6753056915162",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.033,
        "longitude": -0.5,
        "region": null
    },
    {
        "georeferenceId": "17751",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "16.6313898062047",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.867,
        "longitude": -0.433,
        "region": null
    },
    {
        "georeferenceId": "17752",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "14.3684371957829",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.483,
        "longitude": -0.083,
        "region": null
    },
    {
        "georeferenceId": "17753",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "13.2525389272364",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.083,
        "longitude": -0.167,
        "region": null
    },
    {
        "georeferenceId": "17754",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "18.2515625543531",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.083,
        "longitude": 0.267,
        "region": null
    },
    {
        "georeferenceId": "17756",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "18.0891966690706",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53,
        "longitude": -4.083,
        "region": null
    },
    {
        "georeferenceId": "17757",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "28.3437059828781",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.783,
        "longitude": -4.733,
        "region": null
    },
    {
        "georeferenceId": "17758",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "20.2191713832371",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.8,
        "longitude": -4.117,
        "region": null
    },
    {
        "georeferenceId": "17759",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "12.6720919543981",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.95,
        "longitude": -3.417,
        "region": null
    },
    {
        "georeferenceId": "17760",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "19.0337296884749",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.75,
        "longitude": -3.467,
        "region": null
    },
    {
        "georeferenceId": "17761",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "14.5152273743149",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.567,
        "longitude": -3.717,
        "region": null
    },
    {
        "georeferenceId": "17762",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "16.5192182657077",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.8,
        "longitude": -2.667,
        "region": null
    },
    {
        "georeferenceId": "17763",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "12.740239210902",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.733,
        "longitude": -0.65,
        "region": null
    },
    {
        "georeferenceId": "17764",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "13.6359713597938",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.617,
        "longitude": -0.467,
        "region": null
    },
    {
        "georeferenceId": "17765",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "16.9074035493397",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.867,
        "longitude": 0.15,
        "region": null
    },
    {
        "georeferenceId": "17766",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "13.3249944738136",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.65,
        "longitude": 0.567,
        "region": null
    },
    {
        "georeferenceId": "17767",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "16.6104359600032",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.95,
        "longitude": 1.133,
        "region": null
    },
    {
        "georeferenceId": "17768",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "19.2887491649391",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.633,
        "longitude": 1.3,
        "region": null
    },
    {
        "georeferenceId": "17769",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "16.9543221012615",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.8,
        "longitude": 1.25,
        "region": null
    },
    {
        "georeferenceId": "17770",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "24.0826058341796",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.683,
        "longitude": 1.683,
        "region": null
    },
    {
        "georeferenceId": "17771",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "23.3707789618658",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.133,
        "longitude": -4.567,
        "region": null
    },
    {
        "georeferenceId": "17772",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "21.9556627701327",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.35,
        "longitude": -3.95,
        "region": null
    },
    {
        "georeferenceId": "17773",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "22.832313665348",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.067,
        "longitude": -3.617,
        "region": null
    },
    {
        "georeferenceId": "17774",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "20.2583012098664",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.083,
        "longitude": -2.8,
        "region": null
    },
    {
        "georeferenceId": "17775",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "13.9121706890044",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.2,
        "longitude": -2.217,
        "region": null
    },
    {
        "georeferenceId": "17776",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "22.5905008421748",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.117,
        "longitude": -2.3,
        "region": null
    },
    {
        "georeferenceId": "17777",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "19.0071139001505",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.15,
        "longitude": -2.033,
        "region": null
    },
    {
        "georeferenceId": "17778",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "15.2974442938636",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.45,
        "longitude": -1.733,
        "region": null
    },
    {
        "georeferenceId": "17780",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "16.8276443179084",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.367,
        "longitude": -1.333,
        "region": null
    },
    {
        "georeferenceId": "17781",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "16.4316981547109",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.217,
        "longitude": -0.483,
        "region": null
    },
    {
        "georeferenceId": "17782",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "22.3938553706923",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.35,
        "longitude": 0.517,
        "region": null
    },
    {
        "georeferenceId": "17783",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "14.8140557969203",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.25,
        "longitude": 0.7,
        "region": null
    },
    {
        "georeferenceId": "17784",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "16.9553358732656",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.117,
        "longitude": 0.967,
        "region": null
    },
    {
        "georeferenceId": "17785",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "32.6701908645212",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.7,
        "longitude": -5.05,
        "region": null
    },
    {
        "georeferenceId": "17786",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "26.7696729246171",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.717,
        "longitude": -4.367,
        "region": null
    },
    {
        "georeferenceId": "17787",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "27.7278005252388",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.567,
        "longitude": -3.983,
        "region": null
    },
    {
        "georeferenceId": "17788",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "21.4689901725015",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.517,
        "longitude": -2.583,
        "region": null
    },
    {
        "georeferenceId": "17790",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "16.0769194862078",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.75,
        "longitude": -1.583,
        "region": null
    },
    {
        "georeferenceId": "17791",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "17.7815255345367",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.617,
        "longitude": -1.083,
        "region": null
    },
    {
        "georeferenceId": "17792",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "22.0702023931679",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.683,
        "longitude": -0.8,
        "region": null
    },
    {
        "georeferenceId": "17793",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "13.8524907113897",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.533,
        "longitude": -0.417,
        "region": null
    },
    {
        "georeferenceId": "17794",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "14.511654146484",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.883,
        "longitude": 0.233,
        "region": null
    },
    {
        "georeferenceId": "17797",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "20.4266957873948",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.883,
        "longitude": 0.9,
        "region": null
    },
    {
        "georeferenceId": "17800",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "24.8041698711763",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.083,
        "longitude": -3.6,
        "region": null
    },
    {
        "georeferenceId": "17801",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "18.4478930333987",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.5,
        "longitude": -3.2,
        "region": null
    },
    {
        "georeferenceId": "17802",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "20.8096768040732",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.4,
        "longitude": -3.433,
        "region": null
    },
    {
        "georeferenceId": "17805",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "19.3452310893082",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.5,
        "longitude": -1.983,
        "region": null
    },
    {
        "georeferenceId": "17806",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "19.0900121724975",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.2,
        "longitude": -1.8,
        "region": null
    },
    {
        "georeferenceId": "17808",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "19.1568268255484",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.15,
        "longitude": -1.567,
        "region": null
    },
    {
        "georeferenceId": "17809",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "16.4254374620055",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.233,
        "longitude": -0.95,
        "region": null
    },
    {
        "georeferenceId": "17810",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "16.9479026654412",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.433,
        "longitude": -1,
        "region": null
    },
    {
        "georeferenceId": "17811",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "15.5933190519303",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.283,
        "longitude": -0.767,
        "region": null
    },
    {
        "georeferenceId": "17813",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "16.3912023607063",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.15,
        "longitude": -0.183,
        "region": null
    },
    {
        "georeferenceId": "17816",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "16.6250681647276",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.3,
        "longitude": -0.083,
        "region": null
    },
    {
        "georeferenceId": "17817",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "14.5229718737783",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.433,
        "longitude": 0.367,
        "region": null
    },
    {
        "georeferenceId": "17818",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "20.958777297928",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.2,
        "longitude": 0.783,
        "region": null
    },
    {
        "georeferenceId": "17820",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "28.2224903638835",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.133,
        "longitude": 1.3,
        "region": null
    },
    {
        "georeferenceId": "17821",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "23.9678304679557",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.35,
        "longitude": 1.417,
        "region": null
    },
    {
        "georeferenceId": "17823",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "27.2085349697518",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.217,
        "longitude": -5.317,
        "region": null
    },
    {
        "georeferenceId": "17824",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "31.4393168471847",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.083,
        "longitude": -5.25,
        "region": null
    },
    {
        "georeferenceId": "17825",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "44.1742794039294",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.15,
        "longitude": -5.067,
        "region": null
    },
    {
        "georeferenceId": "17826",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "26.0745000786423",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.417,
        "longitude": -5.083,
        "region": null
    },
    {
        "georeferenceId": "17827",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "29.2963140262444",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.467,
        "longitude": -4.717,
        "region": null
    },
    {
        "georeferenceId": "17830",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "29.0726137915384",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.717,
        "longitude": -3.533,
        "region": null
    },
    {
        "georeferenceId": "17831",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "23.93023786823",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.867,
        "longitude": -3.233,
        "region": null
    },
    {
        "georeferenceId": "17832",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "21.8792672202581",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.95,
        "longitude": -2.633,
        "region": null
    },
    {
        "georeferenceId": "17834",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "20.524002548062",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.783,
        "longitude": -1.833,
        "region": null
    },
    {
        "georeferenceId": "17835",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "11.2231431097736",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.9,
        "longitude": -1.4,
        "region": null
    },
    {
        "georeferenceId": "17836",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "28.9836140686346",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.583,
        "longitude": -1.3,
        "region": null
    },
    {
        "georeferenceId": "17837",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "22.8606576639509",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.817,
        "longitude": -0.917,
        "region": null
    },
    {
        "georeferenceId": "17838",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "22.8860613552945",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.8,
        "longitude": -1.217,
        "region": null
    },
    {
        "georeferenceId": "17839",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "26.2590419505247",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.833,
        "longitude": -0.283,
        "region": null
    },
    {
        "georeferenceId": "17840",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "23.2639260586281",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.8,
        "longitude": 0.25,
        "region": null
    },
    {
        "georeferenceId": "17841",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "29.0023894670875",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 49.433,
        "longitude": -2.6,
        "region": null
    },
    {
        "georeferenceId": "17842",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "27.3771278660487",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 49.217,
        "longitude": -2.2,
        "region": null
    },
    {
        "georeferenceId": "17843",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "25.8456964474185",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.433,
        "longitude": -8.05,
        "region": null
    },
    {
        "georeferenceId": "17844",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "25.4124953351672",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.4,
        "longitude": -7.65,
        "region": null
    },
    {
        "georeferenceId": "17845",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "31.7338955279811",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.6,
        "longitude": -7.3,
        "region": null
    },
    {
        "georeferenceId": "17847",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "28.118079735987",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.067,
        "longitude": -7.017,
        "region": null
    },
    {
        "georeferenceId": "17848",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "31.7255773297358",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.717,
        "longitude": -6.817,
        "region": null
    },
    {
        "georeferenceId": "17849",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "28.4036995583493",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.517,
        "longitude": -6.75,
        "region": null
    },
    {
        "georeferenceId": "17850",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "30.4902514862065",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.2,
        "longitude": -6.65,
        "region": null
    },
    {
        "georeferenceId": "17851",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "29.0864726791039",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.867,
        "longitude": -6.45,
        "region": null
    },
    {
        "georeferenceId": "17852",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "32.5051666815732",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.183,
        "longitude": -6.167,
        "region": null
    },
    {
        "georeferenceId": "17853",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "28.050702365637",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.65,
        "longitude": -6.217,
        "region": null
    },
    {
        "georeferenceId": "17854",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "27.1814495068917",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.483,
        "longitude": -6.1,
        "region": null
    },
    {
        "georeferenceId": "17855",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "27.5851860444309",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.35,
        "longitude": -6.667,
        "region": null
    },
    {
        "georeferenceId": "17856",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "26.7621458448474",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.6,
        "longitude": -5.883,
        "region": null
    },
    {
        "georeferenceId": "17857",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "33.0025882022122",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.667,
        "longitude": -5.667,
        "region": null
    },
    {
        "georeferenceId": "17858",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "29.7186432567866",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.85,
        "longitude": -5.8,
        "region": null
    },
    {
        "georeferenceId": "17859",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "14.028703478215",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.867,
        "longitude": -1.65,
        "region": null
    },
    {
        "georeferenceId": "17860",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "18.287757361594",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.567,
        "longitude": -1.167,
        "region": null
    },
    {
        "georeferenceId": "17861",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "12.2906682877576",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.367,
        "longitude": -1.483,
        "region": null
    },
    {
        "georeferenceId": "17862",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "15.5296170127428",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.9,
        "longitude": -2.083,
        "region": null
    },
    {
        "georeferenceId": "17863",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "17.327559946426",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.383,
        "longitude": -2.35,
        "region": null
    },
    {
        "georeferenceId": "17864",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "3.92439211327486",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52,
        "longitude": -0.733,
        "region": null
    },
    {
        "georeferenceId": "17865",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "24.1225582575681",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.833,
        "longitude": -4.533,
        "region": null
    },
    {
        "georeferenceId": "17866",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "23.2206812858115",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.133,
        "longitude": -3.017,
        "region": null
    },
    {
        "georeferenceId": "17867",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "12.5643901591629",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.2,
        "longitude": -1.7,
        "region": null
    },
    {
        "georeferenceId": "17868",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "28.5131526973414",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 60.433,
        "longitude": -1.3,
        "region": null
    },
    {
        "georeferenceId": "17869",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "26.2822849724055",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.45,
        "longitude": -3.017,
        "region": null
    },
    {
        "georeferenceId": "17870",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "10.0530859983014",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.783,
        "longitude": -1.75,
        "region": null
    },
    {
        "georeferenceId": "17871",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "17.3940143389857",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.583,
        "longitude": -0.35,
        "region": null
    },
    {
        "georeferenceId": "17872",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "2",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "16.4129218265572",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.033,
        "longitude": -1.7,
        "region": null
    },
    {
        "georeferenceId": "17873",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "18.1871949504245",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.533,
        "longitude": -1.567,
        "region": null
    },
    {
        "georeferenceId": "17874",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "14.5850514018545",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.833,
        "longitude": -1.333,
        "region": null
    },
    {
        "georeferenceId": "17876",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "13.8659572742855",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.867,
        "longitude": -2.25,
        "region": null
    },
    {
        "georeferenceId": "17877",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "19.1052153902952",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.383,
        "longitude": -2.717,
        "region": null
    },
    {
        "georeferenceId": "17878",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "17.7604829341989",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.333,
        "longitude": -2.85,
        "region": null
    },
    {
        "georeferenceId": "17879",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "13.8533499512041",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.867,
        "longitude": -0.367,
        "region": null
    },
    {
        "georeferenceId": "17880",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "37.6416132527737",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.417,
        "longitude": -4.117,
        "region": null
    },
    {
        "georeferenceId": "17881",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "11.3957433963323",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.5,
        "longitude": 0.05,
        "region": null
    },
    {
        "georeferenceId": "17882",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "28.6851707303818",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 49.717,
        "longitude": -2.2,
        "region": null
    },
    {
        "georeferenceId": "17884",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "19.1568268255484",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.25,
        "longitude": -0.883,
        "region": null
    },
    {
        "georeferenceId": "17885",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "28.8666774120019",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.05,
        "longitude": -7.15,
        "region": null
    },
    {
        "georeferenceId": "17886",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "14.6134781593131",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.567,
        "longitude": 0.7,
        "region": null
    },
    {
        "georeferenceId": "17891",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "31.4506990078727",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 58.033,
        "longitude": -4.4,
        "region": null
    },
    {
        "georeferenceId": "17893",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "31.9427505373062",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.683,
        "longitude": -2.017,
        "region": null
    },
    {
        "georeferenceId": "17894",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "27.6835750951508",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.633,
        "longitude": -6.183,
        "region": null
    },
    {
        "georeferenceId": "17895",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "23.5025586485843",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.417,
        "longitude": -5.467,
        "region": null
    },
    {
        "georeferenceId": "17896",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "29.5245146599627",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.6,
        "longitude": -4.5,
        "region": null
    },
    {
        "georeferenceId": "17897",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "21.9322308761089",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.767,
        "longitude": -4.167,
        "region": null
    },
    {
        "georeferenceId": "17898",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "23.8490712556938",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.783,
        "longitude": -4,
        "region": null
    },
    {
        "georeferenceId": "17899",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "24.8906653439473",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.6,
        "longitude": -2.433,
        "region": null
    },
    {
        "georeferenceId": "17900",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "24.1058693323082",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.9,
        "longitude": -3.633,
        "region": null
    },
    {
        "georeferenceId": "17901",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "20.0339794001308",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.467,
        "longitude": -2.567,
        "region": null
    },
    {
        "georeferenceId": "17903",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "16.083072902183",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.067,
        "longitude": -2.833,
        "region": null
    },
    {
        "georeferenceId": "17904",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "18.3635519756839",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.567,
        "longitude": -2.417,
        "region": null
    },
    {
        "georeferenceId": "17906",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "14.3395226275524",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.767,
        "longitude": -2.717,
        "region": null
    },
    {
        "georeferenceId": "17907",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "11.870615002002",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.05,
        "longitude": -3,
        "region": null
    },
    {
        "georeferenceId": "17908",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "10.844577320531",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53,
        "longitude": -2.183,
        "region": null
    },
    {
        "georeferenceId": "17909",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "15.998388295269",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.367,
        "longitude": -1.5,
        "region": null
    },
    {
        "georeferenceId": "17910",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "24.6522851022011",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.933,
        "longitude": 1.283,
        "region": null
    },
    {
        "georeferenceId": "17911",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "19.95527534412",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.083,
        "longitude": -4.15,
        "region": null
    },
    {
        "georeferenceId": "17912",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "17.3678217853172",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.35,
        "longitude": -2.983,
        "region": null
    },
    {
        "georeferenceId": "17913",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "19.1698392648963",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.483,
        "longitude": -0.6,
        "region": null
    },
    {
        "georeferenceId": "17914",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "12.9942415942072",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.2,
        "longitude": 0.183,
        "region": null
    },
    {
        "georeferenceId": "17915",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "17.090257670617",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.267,
        "longitude": 0.517,
        "region": null
    },
    {
        "georeferenceId": "17916",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "28.940161832407",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.083,
        "longitude": 1.183,
        "region": null
    },
    {
        "georeferenceId": "17917",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "26.5477214696836",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.283,
        "longitude": 1.083,
        "region": null
    },
    {
        "georeferenceId": "17919",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "22.82047574598",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.517,
        "longitude": -4.833,
        "region": null
    },
    {
        "georeferenceId": "17920",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "27.2189935274435",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.45,
        "longitude": -4.45,
        "region": null
    },
    {
        "georeferenceId": "17921",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "27.892102287191",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.467,
        "longitude": -3.533,
        "region": null
    },
    {
        "georeferenceId": "17922",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "29.785356625975",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.6,
        "longitude": -2.45,
        "region": null
    },
    {
        "georeferenceId": "17923",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "27.1382428440407",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.067,
        "longitude": -1.317,
        "region": null
    },
    {
        "georeferenceId": "17924",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "30.7932263682705",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.867,
        "longitude": 0.6,
        "region": null
    },
    {
        "georeferenceId": "17925",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "30.3267139763786",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.817,
        "longitude": -7.467,
        "region": null
    },
    {
        "georeferenceId": "17926",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "29.4661206856798",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.717,
        "longitude": -6.517,
        "region": null
    },
    {
        "georeferenceId": "17927",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "31.160638164571",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.183,
        "longitude": -6.333,
        "region": null
    },
    {
        "georeferenceId": "17929",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "21.4892288769797",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 58.283,
        "longitude": -4.433,
        "region": null
    },
    {
        "georeferenceId": "17930",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "27.1709999894947",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.95,
        "longitude": -3.983,
        "region": null
    },
    {
        "georeferenceId": "17931",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "29.4945606321924",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.067,
        "longitude": -3.467,
        "region": null
    },
    {
        "georeferenceId": "17932",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "12.3799459274343",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.75,
        "longitude": -2.483,
        "region": null
    },
    {
        "georeferenceId": "17933",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "14.5907330825589",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.317,
        "longitude": 0.033,
        "region": null
    },
    {
        "georeferenceId": "17935",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "25.4370682537901",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56,
        "longitude": -3.783,
        "region": null
    },
    {
        "georeferenceId": "17936",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "24.7210023560369",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.117,
        "longitude": -3.15,
        "region": null
    },
    {
        "georeferenceId": "51522",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "23.9499033722345",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.817,
        "longitude": 1.133,
        "region": null
    },
    {
        "georeferenceId": "4482342",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "12.2906682877576",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.85426,
        "longitude": -1.50702,
        "region": null
    },
    {
        "georeferenceId": "4482343",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "13.6181749678633",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.39455,
        "longitude": -0.78437,
        "region": null
    },
    {
        "georeferenceId": "4482344",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "14.9801852439876",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.68495,
        "longitude": -2.76495,
        "region": null
    },
    {
        "georeferenceId": "4482345",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "13.5443743505768",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.03516,
        "longitude": 0.09752,
        "region": null
    },
    {
        "georeferenceId": "4482346",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "24.9189432366053",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.46997,
        "longitude": -4.564,
        "region": null
    },
    {
        "georeferenceId": "4482347",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "14.2439791003469",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.64729,
        "longitude": -0.39027,
        "region": null
    },
    {
        "georeferenceId": "4482348",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-10",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "13.8307382173874",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.65863,
        "longitude": -0.49627,
        "region": null
    },
    {
        "georeferenceId": "17658",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "23.4835782893437",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.25,
        "longitude": -5.8,
        "region": null
    },
    {
        "georeferenceId": "17856",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "28.6091318865755",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.6,
        "longitude": -5.883,
        "region": null
    },
    {
        "georeferenceId": "17930",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "30.0202869854333",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.95,
        "longitude": -3.983,
        "region": null
    },
    {
        "georeferenceId": "17882",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "32.2235770331694",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 49.717,
        "longitude": -2.2,
        "region": null
    },
    {
        "georeferenceId": "17724",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "29.153842666827",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.417,
        "longitude": -1.6,
        "region": null
    },
    {
        "georeferenceId": "17881",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "14.0078756121834",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.5,
        "longitude": 0.05,
        "region": null
    },
    {
        "georeferenceId": "17725",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "17.3199975896178",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.917,
        "longitude": -1.383,
        "region": null
    },
    {
        "georeferenceId": "17830",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "32.1392453508139",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.717,
        "longitude": -3.533,
        "region": null
    },
    {
        "georeferenceId": "17754",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "22.3530064862468",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.083,
        "longitude": 0.267,
        "region": null
    },
    {
        "georeferenceId": "17726",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "16.4316981547109",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.3,
        "longitude": -1.533,
        "region": null
    },
    {
        "georeferenceId": "17880",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "41.3932513003609",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.417,
        "longitude": -4.117,
        "region": null
    },
    {
        "georeferenceId": "17857",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "35.8037537062935",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.667,
        "longitude": -5.667,
        "region": null
    },
    {
        "georeferenceId": "17836",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "32.5204382989084",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.583,
        "longitude": -1.3,
        "region": null
    },
    {
        "georeferenceId": "4482343",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "16.7853630165452",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.39455,
        "longitude": -0.78437,
        "region": null
    },
    {
        "georeferenceId": "17727",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "14.9310394177019",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.133,
        "longitude": -1.417,
        "region": null
    },
    {
        "georeferenceId": "17879",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "16.2000239643381",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.867,
        "longitude": -0.367,
        "region": null
    },
    {
        "georeferenceId": "17789",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "22.5917599449587",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.867,
        "longitude": -1.683,
        "region": null
    },
    {
        "georeferenceId": "17753",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "16.4037046842926",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.083,
        "longitude": -0.167,
        "region": null
    },
    {
        "georeferenceId": "17640",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "36.7898243640167",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 60.75,
        "longitude": -0.85,
        "region": null
    },
    {
        "georeferenceId": "17728",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "15.7917665650142",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.2,
        "longitude": -1.383,
        "region": null
    },
    {
        "georeferenceId": "17878",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "20.6031944649559",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.333,
        "longitude": -2.85,
        "region": null
    },
    {
        "georeferenceId": "17858",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "30.6139935719371",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.85,
        "longitude": -5.8,
        "region": null
    },
    {
        "georeferenceId": "4482347",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "17.180564330919",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.64729,
        "longitude": -0.39027,
        "region": null
    },
    {
        "georeferenceId": "17729",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "13.9576484609764",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.05,
        "longitude": -1.25,
        "region": null
    },
    {
        "georeferenceId": "17877",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "21.7822020552092",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.383,
        "longitude": -2.717,
        "region": null
    },
    {
        "georeferenceId": "17669",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "16.3288056339395",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.2,
        "longitude": -3.833,
        "region": null
    },
    {
        "georeferenceId": "17752",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "17.1600844743855",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.483,
        "longitude": -0.083,
        "region": null
    },
    {
        "georeferenceId": "17730",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "12.3528964418231",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.683,
        "longitude": -1.217,
        "region": null
    },
    {
        "georeferenceId": "17731",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "19.0012031501943",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.367,
        "longitude": -0.667,
        "region": null
    },
    {
        "georeferenceId": "17929",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "24.3813582985475",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 58.283,
        "longitude": -4.433,
        "region": null
    },
    {
        "georeferenceId": "51522",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "27.7706252355637",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.817,
        "longitude": 1.133,
        "region": null
    },
    {
        "georeferenceId": "17859",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "17.1966204119956",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.867,
        "longitude": -1.65,
        "region": null
    },
    {
        "georeferenceId": "17823",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "30.4790623669898",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.217,
        "longitude": -5.317,
        "region": null
    },
    {
        "georeferenceId": "17876",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "16.0902002884565",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.867,
        "longitude": -2.25,
        "region": null
    },
    {
        "georeferenceId": "17732",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "16.6387120054132",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.483,
        "longitude": -0.6,
        "region": null
    },
    {
        "georeferenceId": "17751",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "20.5228281337733",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.867,
        "longitude": -0.433,
        "region": null
    },
    {
        "georeferenceId": "17874",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "17.7815255345367",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.833,
        "longitude": -1.333,
        "region": null
    },
    {
        "georeferenceId": "17656",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "22.431834006614",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.867,
        "longitude": -5.633,
        "region": null
    },
    {
        "georeferenceId": "17780",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "20.3982331890812",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.367,
        "longitude": -1.333,
        "region": null
    },
    {
        "georeferenceId": "17750",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "16.8531883760999",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.033,
        "longitude": -0.5,
        "region": null
    },
    {
        "georeferenceId": "17671",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "24.7549621394542",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.65,
        "longitude": -3.567,
        "region": null
    },
    {
        "georeferenceId": "17860",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "21.7797487464343",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.567,
        "longitude": -1.167,
        "region": null
    },
    {
        "georeferenceId": "17927",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "30.8915142648059",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.183,
        "longitude": -6.333,
        "region": null
    },
    {
        "georeferenceId": "17733",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "20.2877826629247",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.1,
        "longitude": -0.167,
        "region": null
    },
    {
        "georeferenceId": "17873",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "21.1046649090204",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.533,
        "longitude": -1.567,
        "region": null
    },
    {
        "georeferenceId": "17734",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "27.4674053602034",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.25,
        "longitude": -4.533,
        "region": null
    },
    {
        "georeferenceId": "17872",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "20.658999330919",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.033,
        "longitude": -1.7,
        "region": null
    },
    {
        "georeferenceId": "17642",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "36.0075608058558",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 60.133,
        "longitude": -1.183,
        "region": null
    },
    {
        "georeferenceId": "17749",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "15.8422578940013",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.167,
        "longitude": -0.517,
        "region": null
    },
    {
        "georeferenceId": "17735",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "25.7804335409709",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.1,
        "longitude": -3.933,
        "region": null
    },
    {
        "georeferenceId": "17736",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "18.0668041639893",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.217,
        "longitude": -3.5,
        "region": null
    },
    {
        "georeferenceId": "17861",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "15.1040944849169",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.367,
        "longitude": -1.483,
        "region": null
    },
    {
        "georeferenceId": "17837",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "26.5402341800307",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.817,
        "longitude": -0.917,
        "region": null
    },
    {
        "georeferenceId": "17871",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "21.2882695732853",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.583,
        "longitude": -0.35,
        "region": null
    },
    {
        "georeferenceId": "17737",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "11.6808727958523",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.217,
        "longitude": -3.217,
        "region": null
    },
    {
        "georeferenceId": "17870",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "11.9613005503918",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.783,
        "longitude": -1.75,
        "region": null
    },
    {
        "georeferenceId": "17794",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "16.4172026286358",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.883,
        "longitude": 0.233,
        "region": null
    },
    {
        "georeferenceId": "17748",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "16.7853630165452",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.4,
        "longitude": -0.767,
        "region": null
    },
    {
        "georeferenceId": "17738",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "14.4468675819572",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.5,
        "longitude": -3.067,
        "region": null
    },
    {
        "georeferenceId": "17869",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "30.788111964418",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.45,
        "longitude": -3.017,
        "region": null
    },
    {
        "georeferenceId": "4482342",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "15.4354021743398",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.85426,
        "longitude": -1.50702,
        "region": null
    },
    {
        "georeferenceId": "17862",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "18.3455691829053",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.9,
        "longitude": -2.083,
        "region": null
    },
    {
        "georeferenceId": "17672",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "24.5312286781705",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.717,
        "longitude": -3.317,
        "region": null
    },
    {
        "georeferenceId": "17739",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "20.1399230285675",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.767,
        "longitude": -3.033,
        "region": null
    },
    {
        "georeferenceId": "17868",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "33.0896747756513",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 60.433,
        "longitude": -1.3,
        "region": null
    },
    {
        "georeferenceId": "17926",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "30.1126354957344",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.717,
        "longitude": -6.517,
        "region": null
    },
    {
        "georeferenceId": "17747",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "13.4770669997572",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.833,
        "longitude": -1.2,
        "region": null
    },
    {
        "georeferenceId": "17740",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "17.9304304814277",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.2,
        "longitude": -2.9,
        "region": null
    },
    {
        "georeferenceId": "17741",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "11.82399429634",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.5,
        "longitude": -2.217,
        "region": null
    },
    {
        "georeferenceId": "17821",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "27.9945492385091",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.35,
        "longitude": 1.417,
        "region": null
    },
    {
        "georeferenceId": "17867",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "15.3978472736929",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.2,
        "longitude": -1.7,
        "region": null
    },
    {
        "georeferenceId": "17863",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "20.8096768040732",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.383,
        "longitude": -2.35,
        "region": null
    },
    {
        "georeferenceId": "17778",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "18.3829887338239",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.45,
        "longitude": -1.733,
        "region": null
    },
    {
        "georeferenceId": "17742",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "16.3340527148815",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.967,
        "longitude": -2.033,
        "region": null
    },
    {
        "georeferenceId": "17866",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "25.7010685729378",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.133,
        "longitude": -3.017,
        "region": null
    },
    {
        "georeferenceId": "17746",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "17.3406283458937",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53,
        "longitude": -1.25,
        "region": null
    },
    {
        "georeferenceId": "17743",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "17.3079719449922",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.817,
        "longitude": -1.867,
        "region": null
    },
    {
        "georeferenceId": "17865",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "27.7961469228721",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.833,
        "longitude": -4.533,
        "region": null
    },
    {
        "georeferenceId": "17673",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "20.3612370074232",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.35,
        "longitude": -3.35,
        "region": null
    },
    {
        "georeferenceId": "17745",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "18.8142679890431",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.8,
        "longitude": -1.55,
        "region": null
    },
    {
        "georeferenceId": "17674",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "20.8678754738306",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "2",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.883,
        "longitude": -3.417,
        "region": null
    },
    {
        "georeferenceId": "17864",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "4.37160322838454",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52,
        "longitude": -0.733,
        "region": null
    },
    {
        "georeferenceId": "17797",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "23.7215960133935",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.883,
        "longitude": 0.9,
        "region": null
    },
    {
        "georeferenceId": "17657",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "29.6269908084971",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.033,
        "longitude": -7.45,
        "region": null
    },
    {
        "georeferenceId": "17744",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "14.6996358772083",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.65,
        "longitude": -1.783,
        "region": null
    },
    {
        "georeferenceId": "17935",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "28.0187447464437",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56,
        "longitude": -3.783,
        "region": null
    },
    {
        "georeferenceId": "17838",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "26.417821835251",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.8,
        "longitude": -1.217,
        "region": null
    },
    {
        "georeferenceId": "17825",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "47.2434977362661",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.15,
        "longitude": -5.067,
        "region": null
    },
    {
        "georeferenceId": "17925",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "30.5207789812035",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.817,
        "longitude": -7.467,
        "region": null
    },
    {
        "georeferenceId": "17675",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "32.9172079444703",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 58.617,
        "longitude": -3.55,
        "region": null
    },
    {
        "georeferenceId": "17777",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "22.4635874959627",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.15,
        "longitude": -2.033,
        "region": null
    },
    {
        "georeferenceId": "17924",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "33.6730161883042",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.867,
        "longitude": 0.6,
        "region": null
    },
    {
        "georeferenceId": "17649",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "34.9653189301716",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 58.95,
        "longitude": -2.9,
        "region": null
    },
    {
        "georeferenceId": "17786",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "30.0601334457943",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.717,
        "longitude": -4.367,
        "region": null
    },
    {
        "georeferenceId": "17776",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "26.0176384004563",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.117,
        "longitude": -2.3,
        "region": null
    },
    {
        "georeferenceId": "17820",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "32.2120466020852",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.133,
        "longitude": 1.3,
        "region": null
    },
    {
        "georeferenceId": "17839",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "30.434328961573",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.833,
        "longitude": -0.283,
        "region": null
    },
    {
        "georeferenceId": "17827",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "32.2597546946982",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.467,
        "longitude": -4.717,
        "region": null
    },
    {
        "georeferenceId": "17676",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "34.7021832212758",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 58.45,
        "longitude": -3.083,
        "region": null
    },
    {
        "georeferenceId": "17923",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "31.4770935000901",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.067,
        "longitude": -1.317,
        "region": null
    },
    {
        "georeferenceId": "17678",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "21.792426321215",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.083,
        "longitude": -2.833,
        "region": null
    },
    {
        "georeferenceId": "17922",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "32.0764650765674",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.6,
        "longitude": -2.45,
        "region": null
    },
    {
        "georeferenceId": "17831",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "26.1138417041187",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.867,
        "longitude": -3.233,
        "region": null
    },
    {
        "georeferenceId": "17775",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "16.438949772731",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.2,
        "longitude": -2.217,
        "region": null
    },
    {
        "georeferenceId": "17679",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "31.1297574178294",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.717,
        "longitude": -2.467,
        "region": null
    },
    {
        "georeferenceId": "17680",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "27.4975389272963",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.2,
        "longitude": -2.217,
        "region": null
    },
    {
        "georeferenceId": "17840",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "27.6960691722016",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.8,
        "longitude": 0.25,
        "region": null
    },
    {
        "georeferenceId": "17826",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "27.7278005252388",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.417,
        "longitude": -5.083,
        "region": null
    },
    {
        "georeferenceId": "17921",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "30.3444657881842",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.467,
        "longitude": -3.533,
        "region": null
    },
    {
        "georeferenceId": "17681",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "35.7480580692109",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.5,
        "longitude": -1.767,
        "region": null
    },
    {
        "georeferenceId": "17920",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "28.8169289250294",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.45,
        "longitude": -4.45,
        "region": null
    },
    {
        "georeferenceId": "17665",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "25.8139707518913",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.267,
        "longitude": -4.483,
        "region": null
    },
    {
        "georeferenceId": "17774",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "23.3694895496857",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.083,
        "longitude": -2.8,
        "region": null
    },
    {
        "georeferenceId": "17682",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "32.5868339631247",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.65,
        "longitude": -3.383,
        "region": null
    },
    {
        "georeferenceId": "17919",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "24.855236097371",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.517,
        "longitude": -4.833,
        "region": null
    },
    {
        "georeferenceId": "4482346",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "27.5157246480744",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.46997,
        "longitude": -4.564,
        "region": null
    },
    {
        "georeferenceId": "17841",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "30.6094055293234",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 49.433,
        "longitude": -2.6,
        "region": null
    },
    {
        "georeferenceId": "17800",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "28.9880602233407",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.083,
        "longitude": -3.6,
        "region": null
    },
    {
        "georeferenceId": "17683",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "32.4820030537768",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.5,
        "longitude": -6.883,
        "region": null
    },
    {
        "georeferenceId": "17917",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "32.240352433252",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.283,
        "longitude": 1.083,
        "region": null
    },
    {
        "georeferenceId": "17818",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "25.1952104387323",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.2,
        "longitude": 0.783,
        "region": null
    },
    {
        "georeferenceId": "17773",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "27.0017852732796",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.067,
        "longitude": -3.617,
        "region": null
    },
    {
        "georeferenceId": "17685",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "34.9325810231083",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.433,
        "longitude": -5.7,
        "region": null
    },
    {
        "georeferenceId": "17686",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "29.1255955385489",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.233,
        "longitude": -5.083,
        "region": null
    },
    {
        "georeferenceId": "17933",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "18.4912609908391",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.317,
        "longitude": 0.033,
        "region": null
    },
    {
        "georeferenceId": "17660",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "0",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "3",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.817,
        "longitude": -4.967,
        "region": null
    },
    {
        "georeferenceId": "17842",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "29.6415040516887",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 49.217,
        "longitude": -2.2,
        "region": null
    },
    {
        "georeferenceId": "17785",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "35.8247184344503",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.7,
        "longitude": -5.05,
        "region": null
    },
    {
        "georeferenceId": "17916",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "32.6754703105929",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.083,
        "longitude": 1.183,
        "region": null
    },
    {
        "georeferenceId": "17687",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "25.2305088226809",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.7,
        "longitude": -5.3,
        "region": null
    },
    {
        "georeferenceId": "17772",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "26.6850707564556",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.35,
        "longitude": -3.95,
        "region": null
    },
    {
        "georeferenceId": "17915",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "20.8536116660106",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.267,
        "longitude": 0.517,
        "region": null
    },
    {
        "georeferenceId": "17688",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "33.6574732851487",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.617,
        "longitude": -4.85,
        "region": null
    },
    {
        "georeferenceId": "17801",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "21.3720372385217",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.5,
        "longitude": -3.2,
        "region": null
    },
    {
        "georeferenceId": "17771",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "27.3723564315608",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.133,
        "longitude": -4.567,
        "region": null
    },
    {
        "georeferenceId": "17914",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "14.9426270551539",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.2,
        "longitude": 0.183,
        "region": null
    },
    {
        "georeferenceId": "17843",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "26.7160717978591",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.433,
        "longitude": -8.05,
        "region": null
    },
    {
        "georeferenceId": "17802",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "24.7732955427412",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.4,
        "longitude": -3.433,
        "region": null
    },
    {
        "georeferenceId": "17651",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "29.7677921166909",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.467,
        "longitude": -7.367,
        "region": null
    },
    {
        "georeferenceId": "17689",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "36.6754847386563",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.85,
        "longitude": -4.95,
        "region": null
    },
    {
        "georeferenceId": "17913",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "22.495372898611",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.483,
        "longitude": -0.6,
        "region": null
    },
    {
        "georeferenceId": "17690",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "27.5157246480744",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.467,
        "longitude": -4.617,
        "region": null
    },
    {
        "georeferenceId": "17663",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "22.7470084596153",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.867,
        "longitude": -4.7,
        "region": null
    },
    {
        "georeferenceId": "17770",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "26.2283814351614",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.683,
        "longitude": 1.683,
        "region": null
    },
    {
        "georeferenceId": "17691",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "28.4315274207635",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.95,
        "longitude": -4.767,
        "region": null
    },
    {
        "georeferenceId": "17912",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "20.0851653573177",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.35,
        "longitude": -2.983,
        "region": null
    },
    {
        "georeferenceId": "17844",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "25.2205495516348",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.4,
        "longitude": -7.65,
        "region": null
    },
    {
        "georeferenceId": "17832",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "24.4039320810551",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.95,
        "longitude": -2.633,
        "region": null
    },
    {
        "georeferenceId": "17693",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "27.6413132717946",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.867,
        "longitude": -4.433,
        "region": null
    },
    {
        "georeferenceId": "17911",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "23.7611369804105",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.083,
        "longitude": -4.15,
        "region": null
    },
    {
        "georeferenceId": "17694",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "32.1638519117129",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.317,
        "longitude": -3.733,
        "region": null
    },
    {
        "georeferenceId": "17791",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "20.9925921101132",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.617,
        "longitude": -1.083,
        "region": null
    },
    {
        "georeferenceId": "17769",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "20.0524412445361",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.8,
        "longitude": 1.25,
        "region": null
    },
    {
        "georeferenceId": "17910",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "1",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "28.0526399056621",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.933,
        "longitude": 1.283,
        "region": null
    },
    {
        "georeferenceId": "17696",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "24.6277743945808",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.417,
        "longitude": -4.317,
        "region": null
    },
    {
        "georeferenceId": "17817",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "17.561204008367",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.433,
        "longitude": 0.367,
        "region": null
    },
    {
        "georeferenceId": "17845",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "32.1539033855216",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.6,
        "longitude": -7.3,
        "region": null
    },
    {
        "georeferenceId": "17805",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "22.6249342019923",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.5,
        "longitude": -1.983,
        "region": null
    },
    {
        "georeferenceId": "17909",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "19.2532785425758",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.367,
        "longitude": -1.5,
        "region": null
    },
    {
        "georeferenceId": "17697",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "35.7375849674179",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.8,
        "longitude": -4,
        "region": null
    },
    {
        "georeferenceId": "17788",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "25.2336782280366",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.517,
        "longitude": -2.583,
        "region": null
    },
    {
        "georeferenceId": "17768",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "22.7647266519595",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.633,
        "longitude": 1.3,
        "region": null
    },
    {
        "georeferenceId": "17698",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "31.6274070513389",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.05,
        "longitude": -3.65,
        "region": null
    },
    {
        "georeferenceId": "17908",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "14.0579640130775",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53,
        "longitude": -2.183,
        "region": null
    },
    {
        "georeferenceId": "17784",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "19.5697711314729",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.117,
        "longitude": 0.967,
        "region": null
    },
    {
        "georeferenceId": "17659",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "24.5774675367482",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.767,
        "longitude": -6.05,
        "region": null
    },
    {
        "georeferenceId": "17847",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "28.9554644645267",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.067,
        "longitude": -7.017,
        "region": null
    },
    {
        "georeferenceId": "17816",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "20.9361239122741",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.3,
        "longitude": -0.083,
        "region": null
    },
    {
        "georeferenceId": "4482345",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "16.8595792192507",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.03516,
        "longitude": 0.09752,
        "region": null
    },
    {
        "georeferenceId": "17699",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "29.2868695283666",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.667,
        "longitude": -3.783,
        "region": null
    },
    {
        "georeferenceId": "17767",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "20.3332310822213",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.95,
        "longitude": 1.133,
        "region": null
    },
    {
        "georeferenceId": "17907",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "14.2225917017526",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.05,
        "longitude": -3,
        "region": null
    },
    {
        "georeferenceId": "4482348",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "17.0922981736711",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.65863,
        "longitude": -0.49627,
        "region": null
    },
    {
        "georeferenceId": "17806",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "22.7179211287257",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.2,
        "longitude": -1.8,
        "region": null
    },
    {
        "georeferenceId": "17766",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "15.4147377214531",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.65,
        "longitude": 0.567,
        "region": null
    },
    {
        "georeferenceId": "17700",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "36.5507191079311",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.433,
        "longitude": -3.367,
        "region": null
    },
    {
        "georeferenceId": "17848",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "32.2613281190563",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.717,
        "longitude": -6.817,
        "region": null
    },
    {
        "georeferenceId": "17824",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "34.4811746156593",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.083,
        "longitude": -5.25,
        "region": null
    },
    {
        "georeferenceId": "17906",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "16.063647547346",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.767,
        "longitude": -2.717,
        "region": null
    },
    {
        "georeferenceId": "17701",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "23.9993344275908",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.617,
        "longitude": -2.8,
        "region": null
    },
    {
        "georeferenceId": "17904",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "20.6924571015457",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.567,
        "longitude": -2.417,
        "region": null
    },
    {
        "georeferenceId": "17653",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "30.6655079441577",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 58.217,
        "longitude": -6.317,
        "region": null
    },
    {
        "georeferenceId": "17813",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "20.2009817022393",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.15,
        "longitude": -0.183,
        "region": null
    },
    {
        "georeferenceId": "17765",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "21.1438537264181",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.867,
        "longitude": 0.15,
        "region": null
    },
    {
        "georeferenceId": "17702",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "27.0363325432616",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.95,
        "longitude": -3.35,
        "region": null
    },
    {
        "georeferenceId": "17936",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "30.1828879165975",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.117,
        "longitude": -3.15,
        "region": null
    },
    {
        "georeferenceId": "17849",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "28.3544309150013",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.517,
        "longitude": -6.75,
        "region": null
    },
    {
        "georeferenceId": "17834",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "23.5068738484282",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.783,
        "longitude": -1.833,
        "region": null
    },
    {
        "georeferenceId": "17703",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "30.0742634781995",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.317,
        "longitude": -3.2,
        "region": null
    },
    {
        "georeferenceId": "17903",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "18.087063118206",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.067,
        "longitude": -2.833,
        "region": null
    },
    {
        "georeferenceId": "17704",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "23.3978680386374",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.6,
        "longitude": -3.55,
        "region": null
    },
    {
        "georeferenceId": "17792",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "26.4280868041533",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.683,
        "longitude": -0.8,
        "region": null
    },
    {
        "georeferenceId": "17764",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "16.6114332826284",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.617,
        "longitude": -0.467,
        "region": null
    },
    {
        "georeferenceId": "17901",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "21.6186779308875",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.467,
        "longitude": -2.567,
        "region": null
    },
    {
        "georeferenceId": "17705",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "16.9861098862718",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.983,
        "longitude": -2.433,
        "region": null
    },
    {
        "georeferenceId": "17808",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "22.195529435192",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.15,
        "longitude": -1.567,
        "region": null
    },
    {
        "georeferenceId": "17850",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "31.5340635366979",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.2,
        "longitude": -6.65,
        "region": null
    },
    {
        "georeferenceId": "17811",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "18.3455691829053",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.283,
        "longitude": -0.767,
        "region": null
    },
    {
        "georeferenceId": "17900",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "26.6761504568024",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.9,
        "longitude": -3.633,
        "region": null
    },
    {
        "georeferenceId": "17706",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "28.5395766193935",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.383,
        "longitude": -2.867,
        "region": null
    },
    {
        "georeferenceId": "17783",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "17.9785119221082",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.25,
        "longitude": 0.7,
        "region": null
    },
    {
        "georeferenceId": "17763",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "16.0309910538002",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.733,
        "longitude": -0.65,
        "region": null
    },
    {
        "georeferenceId": "17899",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "29.9170287921853",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.6,
        "longitude": -2.433,
        "region": null
    },
    {
        "georeferenceId": "17707",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "24.3716249575258",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.433,
        "longitude": -2.783,
        "region": null
    },
    {
        "georeferenceId": "17809",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "19.631798029199",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.233,
        "longitude": -0.95,
        "region": null
    },
    {
        "georeferenceId": "17708",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "29.6870723056452",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.083,
        "longitude": -4.633,
        "region": null
    },
    {
        "georeferenceId": "17851",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "30.265895177448",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.867,
        "longitude": -6.45,
        "region": null
    },
    {
        "georeferenceId": "17810",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "19.6605925378722",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.433,
        "longitude": -1,
        "region": null
    },
    {
        "georeferenceId": "17898",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "27.102191204824",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.783,
        "longitude": -4,
        "region": null
    },
    {
        "georeferenceId": "17710",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "23.0495404531252",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.933,
        "longitude": -3.15,
        "region": null
    },
    {
        "georeferenceId": "17762",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "20.3441188649711",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.8,
        "longitude": -2.667,
        "region": null
    },
    {
        "georeferenceId": "17897",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "23.4400499326964",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.767,
        "longitude": -4.167,
        "region": null
    },
    {
        "georeferenceId": "17711",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "29.6965916626855",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.517,
        "longitude": -3.6,
        "region": null
    },
    {
        "georeferenceId": "17790",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "18.5060964310728",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.75,
        "longitude": -1.583,
        "region": null
    },
    {
        "georeferenceId": "17761",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "16.5881749136763",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.567,
        "longitude": -3.717,
        "region": null
    },
    {
        "georeferenceId": "17896",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "30.4678755540728",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.6,
        "longitude": -4.5,
        "region": null
    },
    {
        "georeferenceId": "17852",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "33.7572235204014",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.183,
        "longitude": -6.167,
        "region": null
    },
    {
        "georeferenceId": "17782",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "25.5610807839693",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.35,
        "longitude": 0.517,
        "region": null
    },
    {
        "georeferenceId": "4482344",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "16.4119340765547",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.68495,
        "longitude": -2.76495,
        "region": null
    },
    {
        "georeferenceId": "17712",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "19.112263919186",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.617,
        "longitude": -3.167,
        "region": null
    },
    {
        "georeferenceId": "17895",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "25.347952387636",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.417,
        "longitude": -5.467,
        "region": null
    },
    {
        "georeferenceId": "17713",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "25.3997599848748",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.317,
        "longitude": -3.4,
        "region": null
    },
    {
        "georeferenceId": "17932",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "14.9627705695516",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.75,
        "longitude": -2.483,
        "region": null
    },
    {
        "georeferenceId": "17760",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "22.5108589198497",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.75,
        "longitude": -3.467,
        "region": null
    },
    {
        "georeferenceId": "17714",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "25.3997599848748",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.117,
        "longitude": -3.217,
        "region": null
    },
    {
        "georeferenceId": "17894",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "28.621388043795",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.633,
        "longitude": -6.183,
        "region": null
    },
    {
        "georeferenceId": "17853",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "29.9502506739901",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.65,
        "longitude": -6.217,
        "region": null
    },
    {
        "georeferenceId": "17835",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "12.9181857924311",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.9,
        "longitude": -1.4,
        "region": null
    },
    {
        "georeferenceId": "17654",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "30.1828879165975",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.417,
        "longitude": -6.2,
        "region": null
    },
    {
        "georeferenceId": "17715",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "24.0063391539713",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.767,
        "longitude": -3.317,
        "region": null
    },
    {
        "georeferenceId": "17893",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "34.4161397449474",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.683,
        "longitude": -2.017,
        "region": null
    },
    {
        "georeferenceId": "17787",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "32.2466443883928",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.567,
        "longitude": -3.983,
        "region": null
    },
    {
        "georeferenceId": "17759",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "14.3533837681173",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.95,
        "longitude": -3.417,
        "region": null
    },
    {
        "georeferenceId": "17717",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "24.7281491832492",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.933,
        "longitude": -2.95,
        "region": null
    },
    {
        "georeferenceId": "17891",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "34.4486498983683",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 58.033,
        "longitude": -4.4,
        "region": null
    },
    {
        "georeferenceId": "17718",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "26.226523963807",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.05,
        "longitude": -2.55,
        "region": null
    },
    {
        "georeferenceId": "17854",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "27.7489672043755",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.483,
        "longitude": -6.1,
        "region": null
    },
    {
        "georeferenceId": "17666",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "30.088902144312",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.683,
        "longitude": -4.167,
        "region": null
    },
    {
        "georeferenceId": "17886",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "17.7230054271366",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.567,
        "longitude": 0.7,
        "region": null
    },
    {
        "georeferenceId": "17719",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "22.2071364481992",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.5,
        "longitude": -2.683,
        "region": null
    },
    {
        "georeferenceId": "17931",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "32.3095993024128",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.067,
        "longitude": -3.467,
        "region": null
    },
    {
        "georeferenceId": "17758",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "22.5745565084329",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.8,
        "longitude": -4.117,
        "region": null
    },
    {
        "georeferenceId": "17721",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "25.1689841624315",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "2",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.683,
        "longitude": -2.45,
        "region": null
    },
    {
        "georeferenceId": "17885",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "30.8495159136401",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.05,
        "longitude": -7.15,
        "region": null
    },
    {
        "georeferenceId": "17667",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "23.0682757674267",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.533,
        "longitude": -4.05,
        "region": null
    },
    {
        "georeferenceId": "17668",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "29.5654804027581",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.817,
        "longitude": -3.967,
        "region": null
    },
    {
        "georeferenceId": "17855",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "27.6139604231213",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.35,
        "longitude": -6.667,
        "region": null
    },
    {
        "georeferenceId": "17793",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "16.5831943730025",
        "poaAnnuaGerminationRisk": "2",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.533,
        "longitude": -0.417,
        "region": null
    },
    {
        "georeferenceId": "17655",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "21.6699636046575",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.717,
        "longitude": -4.883,
        "region": null
    },
    {
        "georeferenceId": "17722",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "22.9674865106226",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.283,
        "longitude": -2.283,
        "region": null
    },
    {
        "georeferenceId": "17757",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "30.7574354480568",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.783,
        "longitude": -4.733,
        "region": null
    },
    {
        "georeferenceId": "17884",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "22.9047033417324",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.25,
        "longitude": -0.883,
        "region": null
    },
    {
        "georeferenceId": "17723",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "12.0508034688151",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.817,
        "longitude": -2.083,
        "region": null
    },
    {
        "georeferenceId": "17781",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "19.3962097589294",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "4",
        "takeAllPatchRiskIrrigated": "4",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.217,
        "longitude": -0.483,
        "region": null
    },
    {
        "georeferenceId": "17756",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-11",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "19.8718352016517",
        "poaAnnuaGerminationRisk": "4",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "3",
        "takeAllPatchRiskIrrigated": "3",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53,
        "longitude": -4.083,
        "region": null
    },
    {
        "georeferenceId": "17842",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "31.4672588581155",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 49.217,
        "longitude": -2.2,
        "region": null
    },
    {
        "georeferenceId": "17788",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "28.7146375336064",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.517,
        "longitude": -2.583,
        "region": null
    },
    {
        "georeferenceId": "17827",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "35.1532891856765",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.467,
        "longitude": -4.717,
        "region": null
    },
    {
        "georeferenceId": "17787",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "36.2490248533031",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.567,
        "longitude": -3.983,
        "region": null
    },
    {
        "georeferenceId": "17830",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "33.8813083283369",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.717,
        "longitude": -3.533,
        "region": null
    },
    {
        "georeferenceId": "17786",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "32.6501329752541",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.717,
        "longitude": -4.367,
        "region": null
    },
    {
        "georeferenceId": "17663",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "21.6133915572975",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.867,
        "longitude": -4.7,
        "region": null
    },
    {
        "georeferenceId": "17831",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "28.3544309150013",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.867,
        "longitude": -3.233,
        "region": null
    },
    {
        "georeferenceId": "17785",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "37.8835939638234",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.7,
        "longitude": -5.05,
        "region": null
    },
    {
        "georeferenceId": "17832",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "27.5324812537143",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.95,
        "longitude": -2.633,
        "region": null
    },
    {
        "georeferenceId": "17784",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "20.8160055455112",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.117,
        "longitude": 0.967,
        "region": null
    },
    {
        "georeferenceId": "17783",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "20.5463258911142",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.25,
        "longitude": 0.7,
        "region": null
    },
    {
        "georeferenceId": "17834",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "26.5608274717325",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.783,
        "longitude": -1.833,
        "region": null
    },
    {
        "georeferenceId": "17782",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "29.2794146059265",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.35,
        "longitude": 0.517,
        "region": null
    },
    {
        "georeferenceId": "17835",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "14.8944955132893",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.9,
        "longitude": -1.4,
        "region": null
    },
    {
        "georeferenceId": "17781",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "22.2453043077486",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.217,
        "longitude": -0.483,
        "region": null
    },
    {
        "georeferenceId": "17660",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "0",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "3",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.817,
        "longitude": -4.967,
        "region": null
    },
    {
        "georeferenceId": "17836",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "37.0211869501404",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.583,
        "longitude": -1.3,
        "region": null
    },
    {
        "georeferenceId": "17780",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "23.2827829064032",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.367,
        "longitude": -1.333,
        "region": null
    },
    {
        "georeferenceId": "17837",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "31.4051837530051",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.817,
        "longitude": -0.917,
        "region": null
    },
    {
        "georeferenceId": "17778",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "20.4068078519997",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.45,
        "longitude": -1.733,
        "region": null
    },
    {
        "georeferenceId": "17777",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "24.9445464846071",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.15,
        "longitude": -2.033,
        "region": null
    },
    {
        "georeferenceId": "17838",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "29.0617273997063",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.8,
        "longitude": -1.217,
        "region": null
    },
    {
        "georeferenceId": "17776",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "28.5381082379184",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.117,
        "longitude": -2.3,
        "region": null
    },
    {
        "georeferenceId": "17935",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "27.3904906390419",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56,
        "longitude": -3.783,
        "region": null
    },
    {
        "georeferenceId": "17839",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "36.1337435661206",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.833,
        "longitude": -0.283,
        "region": null
    },
    {
        "georeferenceId": "17775",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "18.0881298691275",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.2,
        "longitude": -2.217,
        "region": null
    },
    {
        "georeferenceId": "17840",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "32.3395253802096",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.8,
        "longitude": 0.25,
        "region": null
    },
    {
        "georeferenceId": "17774",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "24.9005377251048",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.083,
        "longitude": -2.8,
        "region": null
    },
    {
        "georeferenceId": "17841",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "31.9709313355015",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 49.433,
        "longitude": -2.6,
        "region": null
    },
    {
        "georeferenceId": "17773",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "29.8421056075849",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.067,
        "longitude": -3.617,
        "region": null
    },
    {
        "georeferenceId": "17772",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "29.8184945019738",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.35,
        "longitude": -3.95,
        "region": null
    },
    {
        "georeferenceId": "17789",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "25.5665610586309",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.867,
        "longitude": -1.683,
        "region": null
    },
    {
        "georeferenceId": "17771",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "29.0864726791039",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.133,
        "longitude": -4.567,
        "region": null
    },
    {
        "georeferenceId": "17843",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "25.8369578526037",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.433,
        "longitude": -8.05,
        "region": null
    },
    {
        "georeferenceId": "17770",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "27.3470764815602",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.683,
        "longitude": 1.683,
        "region": null
    },
    {
        "georeferenceId": "17844",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "23.8351261869446",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.4,
        "longitude": -7.65,
        "region": null
    },
    {
        "georeferenceId": "17769",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "21.8612231706679",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.8,
        "longitude": 1.25,
        "region": null
    },
    {
        "georeferenceId": "17659",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "24.4974758950195",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.767,
        "longitude": -6.05,
        "region": null
    },
    {
        "georeferenceId": "17845",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "31.0064023934354",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.6,
        "longitude": -7.3,
        "region": null
    },
    {
        "georeferenceId": "17768",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "24.8866273969294",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.633,
        "longitude": 1.3,
        "region": null
    },
    {
        "georeferenceId": "17767",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "22.6728666465574",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.95,
        "longitude": 1.133,
        "region": null
    },
    {
        "georeferenceId": "17847",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "28.2069352824865",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.067,
        "longitude": -7.017,
        "region": null
    },
    {
        "georeferenceId": "17766",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "17.5480046666259",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.65,
        "longitude": 0.567,
        "region": null
    },
    {
        "georeferenceId": "17848",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "29.5999771159824",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.717,
        "longitude": -6.817,
        "region": null
    },
    {
        "georeferenceId": "17765",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "24.3146051949406",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.867,
        "longitude": 0.15,
        "region": null
    },
    {
        "georeferenceId": "17849",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "27.1349210403312",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.517,
        "longitude": -6.75,
        "region": null
    },
    {
        "georeferenceId": "17764",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "18.9325939147767",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.617,
        "longitude": -0.467,
        "region": null
    },
    {
        "georeferenceId": "17936",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "30.9530322113708",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.117,
        "longitude": -3.15,
        "region": null
    },
    {
        "georeferenceId": "17850",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "30.6655079441577",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.2,
        "longitude": -6.65,
        "region": null
    },
    {
        "georeferenceId": "17763",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "18.3829887338238",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.733,
        "longitude": -0.65,
        "region": null
    },
    {
        "georeferenceId": "17762",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "22.3646721791488",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.8,
        "longitude": -2.667,
        "region": null
    },
    {
        "georeferenceId": "17851",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "28.1884703852638",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.867,
        "longitude": -6.45,
        "region": null
    },
    {
        "georeferenceId": "17761",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "18.7447152928997",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.567,
        "longitude": -3.717,
        "region": null
    },
    {
        "georeferenceId": "17852",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "32.0200182126026",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.183,
        "longitude": -6.167,
        "region": null
    },
    {
        "georeferenceId": "17760",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "24.9414012802675",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.75,
        "longitude": -3.467,
        "region": null
    },
    {
        "georeferenceId": "17853",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "28.1977019159992",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.65,
        "longitude": -6.217,
        "region": null
    },
    {
        "georeferenceId": "17759",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "15.5582881384168",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.95,
        "longitude": -3.417,
        "region": null
    },
    {
        "georeferenceId": "17854",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "27.1244827472571",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.483,
        "longitude": -6.1,
        "region": null
    },
    {
        "georeferenceId": "17758",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "24.9827593778412",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.8,
        "longitude": -4.117,
        "region": null
    },
    {
        "georeferenceId": "17757",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "32.1125558291461",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.783,
        "longitude": -4.733,
        "region": null
    },
    {
        "georeferenceId": "17658",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "23.9827038079421",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.25,
        "longitude": -5.8,
        "region": null
    },
    {
        "georeferenceId": "17855",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "26.4897302656532",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.35,
        "longitude": -6.667,
        "region": null
    },
    {
        "georeferenceId": "17756",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "22.0075234097016",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53,
        "longitude": -4.083,
        "region": null
    },
    {
        "georeferenceId": "17856",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "27.0571689890433",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.6,
        "longitude": -5.883,
        "region": null
    },
    {
        "georeferenceId": "17754",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "25.115231824884",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.083,
        "longitude": 0.267,
        "region": null
    },
    {
        "georeferenceId": "17857",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "33.8200438470163",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.667,
        "longitude": -5.667,
        "region": null
    },
    {
        "georeferenceId": "17753",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "18.4901758319143",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.083,
        "longitude": -0.167,
        "region": null
    },
    {
        "georeferenceId": "17858",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "29.0770680089997",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.85,
        "longitude": -5.8,
        "region": null
    },
    {
        "georeferenceId": "17752",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "19.1029899516166",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.483,
        "longitude": -0.083,
        "region": null
    },
    {
        "georeferenceId": "17751",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "22.9891492125736",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.867,
        "longitude": -0.433,
        "region": null
    },
    {
        "georeferenceId": "17859",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "19.91773305324",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.867,
        "longitude": -1.65,
        "region": null
    },
    {
        "georeferenceId": "17750",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "19.2980918006359",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.033,
        "longitude": -0.5,
        "region": null
    },
    {
        "georeferenceId": "51522",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "29.6400024909166",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.817,
        "longitude": 1.133,
        "region": null
    },
    {
        "georeferenceId": "17860",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "24.4850485703484",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.567,
        "longitude": -1.167,
        "region": null
    },
    {
        "georeferenceId": "17749",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "18.172558150401",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.167,
        "longitude": -0.517,
        "region": null
    },
    {
        "georeferenceId": "17861",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "17.1481468325836",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.367,
        "longitude": -1.483,
        "region": null
    },
    {
        "georeferenceId": "17748",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "18.6149283947771",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.4,
        "longitude": -0.767,
        "region": null
    },
    {
        "georeferenceId": "17862",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "20.7847711746294",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.9,
        "longitude": -2.083,
        "region": null
    },
    {
        "georeferenceId": "17747",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "15.5554506095977",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.833,
        "longitude": -1.2,
        "region": null
    },
    {
        "georeferenceId": "17746",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "19.4958360861533",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53,
        "longitude": -1.25,
        "region": null
    },
    {
        "georeferenceId": "17863",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "23.6214265466953",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.383,
        "longitude": -2.35,
        "region": null
    },
    {
        "georeferenceId": "17745",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "21.9634773984299",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.8,
        "longitude": -1.55,
        "region": null
    },
    {
        "georeferenceId": "17657",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "30.9951083219774",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.033,
        "longitude": -7.45,
        "region": null
    },
    {
        "georeferenceId": "17864",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "4.88088217921917",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52,
        "longitude": -0.733,
        "region": null
    },
    {
        "georeferenceId": "17744",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "16.1167884928924",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.65,
        "longitude": -1.783,
        "region": null
    },
    {
        "georeferenceId": "17865",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "30.7671478402281",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.833,
        "longitude": -4.533,
        "region": null
    },
    {
        "georeferenceId": "17743",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "20.0424394970734",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.817,
        "longitude": -1.867,
        "region": null
    },
    {
        "georeferenceId": "17866",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "27.9196243815194",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.133,
        "longitude": -3.017,
        "region": null
    },
    {
        "georeferenceId": "17742",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "18.1397479531817",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.967,
        "longitude": -2.033,
        "region": null
    },
    {
        "georeferenceId": "17741",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "12.8484206145273",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.5,
        "longitude": -2.217,
        "region": null
    },
    {
        "georeferenceId": "17867",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "17.5292611430949",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.2,
        "longitude": -1.7,
        "region": null
    },
    {
        "georeferenceId": "17740",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "19.6712090787663",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.2,
        "longitude": -2.9,
        "region": null
    },
    {
        "georeferenceId": "17640",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "37.206595502867",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 60.75,
        "longitude": -0.85,
        "region": null
    },
    {
        "georeferenceId": "17868",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "32.4720032265464",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 60.433,
        "longitude": -1.3,
        "region": null
    },
    {
        "georeferenceId": "17739",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "23.2652114146628",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.767,
        "longitude": -3.033,
        "region": null
    },
    {
        "georeferenceId": "17869",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "29.5669797747119",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.45,
        "longitude": -3.017,
        "region": null
    },
    {
        "georeferenceId": "17738",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "15.6261991324104",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.5,
        "longitude": -3.067,
        "region": null
    },
    {
        "georeferenceId": "4482342",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "16.8414208266835",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.85426,
        "longitude": -1.50702,
        "region": null
    },
    {
        "georeferenceId": "17870",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "13.5238717523621",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.783,
        "longitude": -1.75,
        "region": null
    },
    {
        "georeferenceId": "17737",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "12.4292333715428",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.217,
        "longitude": -3.217,
        "region": null
    },
    {
        "georeferenceId": "17736",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "19.4518023852296",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.217,
        "longitude": -3.5,
        "region": null
    },
    {
        "georeferenceId": "17871",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "24.9234337643089",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.583,
        "longitude": -0.35,
        "region": null
    },
    {
        "georeferenceId": "17735",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "28.1209903291491",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.1,
        "longitude": -3.933,
        "region": null
    },
    {
        "georeferenceId": "17872",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "22.4568998986024",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.033,
        "longitude": -1.7,
        "region": null
    },
    {
        "georeferenceId": "17734",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "28.5797299453285",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.25,
        "longitude": -4.533,
        "region": null
    },
    {
        "georeferenceId": "17656",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "22.6859133132271",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.867,
        "longitude": -5.633,
        "region": null
    },
    {
        "georeferenceId": "17873",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "23.3600353712273",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.533,
        "longitude": -1.567,
        "region": null
    },
    {
        "georeferenceId": "17733",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "22.4464533594755",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.1,
        "longitude": -0.167,
        "region": null
    },
    {
        "georeferenceId": "17874",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "19.9782866249301",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.833,
        "longitude": -1.333,
        "region": null
    },
    {
        "georeferenceId": "17732",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "17.9806354790176",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.483,
        "longitude": -0.6,
        "region": null
    },
    {
        "georeferenceId": "17731",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "21.6109520083585",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.367,
        "longitude": -0.667,
        "region": null
    },
    {
        "georeferenceId": "17876",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "18.0309501655853",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.867,
        "longitude": -2.25,
        "region": null
    },
    {
        "georeferenceId": "17730",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "13.3713527096162",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.683,
        "longitude": -1.217,
        "region": null
    },
    {
        "georeferenceId": "17877",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "24.5410050767349",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.383,
        "longitude": -2.717,
        "region": null
    },
    {
        "georeferenceId": "17729",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "15.8048561702073",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.05,
        "longitude": -1.25,
        "region": null
    },
    {
        "georeferenceId": "17655",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "21.6133915572976",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.717,
        "longitude": -4.883,
        "region": null
    },
    {
        "georeferenceId": "17878",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "22.7778106073128",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.333,
        "longitude": -2.85,
        "region": null
    },
    {
        "georeferenceId": "17728",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "17.923721227925",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.2,
        "longitude": -1.383,
        "region": null
    },
    {
        "georeferenceId": "17879",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "18.5129745099429",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.867,
        "longitude": -0.367,
        "region": null
    },
    {
        "georeferenceId": "17727",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "16.2277373206748",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.133,
        "longitude": -1.417,
        "region": null
    },
    {
        "georeferenceId": "17726",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "18.3035428099001",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.3,
        "longitude": -1.533,
        "region": null
    },
    {
        "georeferenceId": "17880",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "44.8535904573852",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.417,
        "longitude": -4.117,
        "region": null
    },
    {
        "georeferenceId": "17725",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "17.3069414827108",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.917,
        "longitude": -1.383,
        "region": null
    },
    {
        "georeferenceId": "4482343",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "18.6149283947771",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.39455,
        "longitude": -0.78437,
        "region": null
    },
    {
        "georeferenceId": "17881",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "16.2381804840356",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.5,
        "longitude": 0.05,
        "region": null
    },
    {
        "georeferenceId": "17724",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "30.9627787490917",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.417,
        "longitude": -1.6,
        "region": null
    },
    {
        "georeferenceId": "17882",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "36.8517972239547",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 49.717,
        "longitude": -2.2,
        "region": null
    },
    {
        "georeferenceId": "17723",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "12.8033394757543",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.817,
        "longitude": -2.083,
        "region": null
    },
    {
        "georeferenceId": "17884",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "26.3735251876765",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.25,
        "longitude": -0.883,
        "region": null
    },
    {
        "georeferenceId": "17722",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "24.8310380649256",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.283,
        "longitude": -2.283,
        "region": null
    },
    {
        "georeferenceId": "17721",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "27.9389480634277",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.683,
        "longitude": -2.45,
        "region": null
    },
    {
        "georeferenceId": "17885",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "29.1161834124997",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.05,
        "longitude": -7.15,
        "region": null
    },
    {
        "georeferenceId": "17719",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "24.6870738751371",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.5,
        "longitude": -2.683,
        "region": null
    },
    {
        "georeferenceId": "17886",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "19.3122992003792",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.567,
        "longitude": 0.7,
        "region": null
    },
    {
        "georeferenceId": "17718",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "28.8292381541427",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.05,
        "longitude": -2.55,
        "region": null
    },
    {
        "georeferenceId": "17891",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "35.5933056638884",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 58.033,
        "longitude": -4.4,
        "region": null
    },
    {
        "georeferenceId": "17717",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "26.9601762448175",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.933,
        "longitude": -2.95,
        "region": null
    },
    {
        "georeferenceId": "17654",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "30.5513236069753",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.417,
        "longitude": -6.2,
        "region": null
    },
    {
        "georeferenceId": "17893",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "34.4605739382805",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.683,
        "longitude": -2.017,
        "region": null
    },
    {
        "georeferenceId": "17715",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "25.6113455005481",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.767,
        "longitude": -3.317,
        "region": null
    },
    {
        "georeferenceId": "17714",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "27.9027228784167",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.117,
        "longitude": -3.217,
        "region": null
    },
    {
        "georeferenceId": "17894",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "28.1700128326969",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.633,
        "longitude": -6.183,
        "region": null
    },
    {
        "georeferenceId": "17713",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "27.1890507376557",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.317,
        "longitude": -3.4,
        "region": null
    },
    {
        "georeferenceId": "17895",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "24.1959950745624",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.417,
        "longitude": -5.467,
        "region": null
    },
    {
        "georeferenceId": "17712",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "20.4782373280414",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.617,
        "longitude": -3.167,
        "region": null
    },
    {
        "georeferenceId": "17896",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "30.3972486091178",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.6,
        "longitude": -4.5,
        "region": null
    },
    {
        "georeferenceId": "17711",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "31.0567398586957",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.517,
        "longitude": -3.6,
        "region": null
    },
    {
        "georeferenceId": "4482344",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "18.1094751829153",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.68495,
        "longitude": -2.76495,
        "region": null
    },
    {
        "georeferenceId": "17897",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "22.7031767279104",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.767,
        "longitude": -4.167,
        "region": null
    },
    {
        "georeferenceId": "17710",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "25.3266134442601",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.933,
        "longitude": -3.15,
        "region": null
    },
    {
        "georeferenceId": "17708",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "28.6989196557182",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.083,
        "longitude": -4.633,
        "region": null
    },
    {
        "georeferenceId": "17898",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "26.2149166888039",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.783,
        "longitude": -4,
        "region": null
    },
    {
        "georeferenceId": "17707",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "25.5304967626224",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.433,
        "longitude": -2.783,
        "region": null
    },
    {
        "georeferenceId": "17899",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "31.9411853239225",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.6,
        "longitude": -2.433,
        "region": null
    },
    {
        "georeferenceId": "17706",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "27.8843797779856",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.383,
        "longitude": -2.867,
        "region": null
    },
    {
        "georeferenceId": "17900",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "25.9927001465765",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.9,
        "longitude": -3.633,
        "region": null
    },
    {
        "georeferenceId": "17705",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "18.6342065993921",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.983,
        "longitude": -2.433,
        "region": null
    },
    {
        "georeferenceId": "17901",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "22.5930190974376",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.467,
        "longitude": -2.567,
        "region": null
    },
    {
        "georeferenceId": "17704",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "23.1826210086133",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.6,
        "longitude": -3.55,
        "region": null
    },
    {
        "georeferenceId": "17703",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "29.9024379793397",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.317,
        "longitude": -3.2,
        "region": null
    },
    {
        "georeferenceId": "17653",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "30.2258938418501",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 58.217,
        "longitude": -6.317,
        "region": null
    },
    {
        "georeferenceId": "17903",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "20.6192956669554",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.067,
        "longitude": -2.833,
        "region": null
    },
    {
        "georeferenceId": "17702",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "27.018345561308",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.95,
        "longitude": -3.35,
        "region": null
    },
    {
        "georeferenceId": "17904",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "22.9849004945662",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.567,
        "longitude": -2.417,
        "region": null
    },
    {
        "georeferenceId": "17701",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "24.3366951268778",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.617,
        "longitude": -2.8,
        "region": null
    },
    {
        "georeferenceId": "17906",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "17.7892460488098",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.767,
        "longitude": -2.717,
        "region": null
    },
    {
        "georeferenceId": "17700",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "34.4058479030428",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.433,
        "longitude": -3.367,
        "region": null
    },
    {
        "georeferenceId": "17907",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "15.2053127586626",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.05,
        "longitude": -3,
        "region": null
    },
    {
        "georeferenceId": "17699",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "28.5919785513565",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.667,
        "longitude": -3.783,
        "region": null
    },
    {
        "georeferenceId": "17698",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "31.9740633486726",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.05,
        "longitude": -3.65,
        "region": null
    },
    {
        "georeferenceId": "17908",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "15.5762689603655",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53,
        "longitude": -2.183,
        "region": null
    },
    {
        "georeferenceId": "17697",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "34.5050353172848",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.8,
        "longitude": -4,
        "region": null
    },
    {
        "georeferenceId": "4482345",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "19.3879562984207",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.03516,
        "longitude": 0.09752,
        "region": null
    },
    {
        "georeferenceId": "17909",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "21.9959912286186",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.367,
        "longitude": -1.5,
        "region": null
    },
    {
        "georeferenceId": "17696",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "22.8187849675748",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.417,
        "longitude": -4.317,
        "region": null
    },
    {
        "georeferenceId": "17910",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "30.0954656223276",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.933,
        "longitude": 1.283,
        "region": null
    },
    {
        "georeferenceId": "17694",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "30.1540681153856",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.317,
        "longitude": -3.733,
        "region": null
    },
    {
        "georeferenceId": "17911",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "27.0079354907475",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.083,
        "longitude": -4.15,
        "region": null
    },
    {
        "georeferenceId": "17693",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "25.6589280718495",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.867,
        "longitude": -4.433,
        "region": null
    },
    {
        "georeferenceId": "17691",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "26.8379482968329",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.95,
        "longitude": -4.767,
        "region": null
    },
    {
        "georeferenceId": "17912",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "22.2034051496644",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.35,
        "longitude": -2.983,
        "region": null
    },
    {
        "georeferenceId": "17690",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "26.8945353626831",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.467,
        "longitude": -4.617,
        "region": null
    },
    {
        "georeferenceId": "17651",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "29.1756584896051",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.467,
        "longitude": -7.367,
        "region": null
    },
    {
        "georeferenceId": "17913",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "25.5537749510445",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.483,
        "longitude": -0.6,
        "region": null
    },
    {
        "georeferenceId": "17689",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "35.0204597494998",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.85,
        "longitude": -4.95,
        "region": null
    },
    {
        "georeferenceId": "17914",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "17.167591506818",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.2,
        "longitude": 0.183,
        "region": null
    },
    {
        "georeferenceId": "17688",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "33.0779856836449",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.617,
        "longitude": -4.85,
        "region": null
    },
    {
        "georeferenceId": "17915",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "23.6873060218859",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.267,
        "longitude": 0.517,
        "region": null
    },
    {
        "georeferenceId": "17687",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "24.6701213772307",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.7,
        "longitude": -5.3,
        "region": null
    },
    {
        "georeferenceId": "17686",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "28.0996502392342",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.233,
        "longitude": -5.083,
        "region": null
    },
    {
        "georeferenceId": "17916",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "36.9384085280136",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.083,
        "longitude": 1.183,
        "region": null
    },
    {
        "georeferenceId": "17685",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "33.5086555602247",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.433,
        "longitude": -5.7,
        "region": null
    },
    {
        "georeferenceId": "4482348",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "19.4649669465748",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.65863,
        "longitude": -0.49627,
        "region": null
    },
    {
        "georeferenceId": "17917",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "35.6857907305498",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.283,
        "longitude": 1.083,
        "region": null
    },
    {
        "georeferenceId": "17683",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "31.2549278759782",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.5,
        "longitude": -6.883,
        "region": null
    },
    {
        "georeferenceId": "17919",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "25.9271958267739",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.517,
        "longitude": -4.833,
        "region": null
    },
    {
        "georeferenceId": "17682",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "30.3475095446931",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.65,
        "longitude": -3.383,
        "region": null
    },
    {
        "georeferenceId": "4482346",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "26.8945353626831",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.46997,
        "longitude": -4.564,
        "region": null
    },
    {
        "georeferenceId": "17920",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "31.3788219466789",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.45,
        "longitude": -4.45,
        "region": null
    },
    {
        "georeferenceId": "17681",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "35.5603012774832",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.5,
        "longitude": -1.767,
        "region": null
    },
    {
        "georeferenceId": "17680",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "27.2865592758802",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.2,
        "longitude": -2.217,
        "region": null
    },
    {
        "georeferenceId": "17921",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "31.2616319694959",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.467,
        "longitude": -3.533,
        "region": null
    },
    {
        "georeferenceId": "17679",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "30.68643350937",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.717,
        "longitude": -2.467,
        "region": null
    },
    {
        "georeferenceId": "17922",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "33.8710938230214",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.6,
        "longitude": -2.45,
        "region": null
    },
    {
        "georeferenceId": "17678",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "20.1360632032694",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.083,
        "longitude": -2.833,
        "region": null
    },
    {
        "georeferenceId": "17649",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "35.2967623411055",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 58.95,
        "longitude": -2.9,
        "region": null
    },
    {
        "georeferenceId": "17923",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "35.7326244998557",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.067,
        "longitude": -1.317,
        "region": null
    },
    {
        "georeferenceId": "17676",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "35.1757234810807",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 58.45,
        "longitude": -3.083,
        "region": null
    },
    {
        "georeferenceId": "17924",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "37.8446329189796",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.867,
        "longitude": 0.6,
        "region": null
    },
    {
        "georeferenceId": "17675",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "31.9443157913898",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 58.617,
        "longitude": -3.55,
        "region": null
    },
    {
        "georeferenceId": "17674",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "18.0259846866989",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "2",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.883,
        "longitude": -3.417,
        "region": null
    },
    {
        "georeferenceId": "17925",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "29.8295451713514",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.817,
        "longitude": -7.467,
        "region": null
    },
    {
        "georeferenceId": "17673",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "20.8045357462317",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.35,
        "longitude": -3.35,
        "region": null
    },
    {
        "georeferenceId": "17926",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "29.4566442524756",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.717,
        "longitude": -6.517,
        "region": null
    },
    {
        "georeferenceId": "17672",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "24.7746373687476",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.717,
        "longitude": -3.317,
        "region": null
    },
    {
        "georeferenceId": "17642",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "35.9185742907432",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 60.133,
        "longitude": -1.183,
        "region": null
    },
    {
        "georeferenceId": "17927",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "29.5574844975136",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.183,
        "longitude": -6.333,
        "region": null
    },
    {
        "georeferenceId": "17671",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "24.855236097371",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.65,
        "longitude": -3.567,
        "region": null
    },
    {
        "georeferenceId": "17929",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "25.2205495516348",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 58.283,
        "longitude": -4.433,
        "region": null
    },
    {
        "georeferenceId": "17669",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "15.9890370314401",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.2,
        "longitude": -3.833,
        "region": null
    },
    {
        "georeferenceId": "17668",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "29.0256216191997",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.817,
        "longitude": -3.967,
        "region": null
    },
    {
        "georeferenceId": "17930",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "29.6870723056452",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.95,
        "longitude": -3.983,
        "region": null
    },
    {
        "georeferenceId": "17667",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "23.6227255743877",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.533,
        "longitude": -4.05,
        "region": null
    },
    {
        "georeferenceId": "4482347",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "19.5625946820748",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.64729,
        "longitude": -0.39027,
        "region": null
    },
    {
        "georeferenceId": "17931",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "31.763538590743",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.067,
        "longitude": -3.467,
        "region": null
    },
    {
        "georeferenceId": "17666",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "30.7446585813414",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.683,
        "longitude": -4.167,
        "region": null
    },
    {
        "georeferenceId": "17810",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "22.4702765077632",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.433,
        "longitude": -1,
        "region": null
    },
    {
        "georeferenceId": "17809",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "23.0669980209983",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.233,
        "longitude": -0.95,
        "region": null
    },
    {
        "georeferenceId": "17932",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "16.8424292169691",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.75,
        "longitude": -2.483,
        "region": null
    },
    {
        "georeferenceId": "17811",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "21.1855000782",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.283,
        "longitude": -0.767,
        "region": null
    },
    {
        "georeferenceId": "17808",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "25.3997599848748",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.15,
        "longitude": -1.567,
        "region": null
    },
    {
        "georeferenceId": "17813",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "24.3773761588934",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.15,
        "longitude": -0.183,
        "region": null
    },
    {
        "georeferenceId": "17806",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "25.7038184383335",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.2,
        "longitude": -1.8,
        "region": null
    },
    {
        "georeferenceId": "17816",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "24.7366378423488",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.3,
        "longitude": -0.083,
        "region": null
    },
    {
        "georeferenceId": "17805",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "25.6026587842635",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.5,
        "longitude": -1.983,
        "region": null
    },
    {
        "georeferenceId": "17802",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "27.7475237055936",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.4,
        "longitude": -3.433,
        "region": null
    },
    {
        "georeferenceId": "17817",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "19.8370823886862",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.433,
        "longitude": 0.367,
        "region": null
    },
    {
        "georeferenceId": "17801",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "23.3085139418799",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.5,
        "longitude": -3.2,
        "region": null
    },
    {
        "georeferenceId": "17665",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "26.4117573731777",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.267,
        "longitude": -4.483,
        "region": null
    },
    {
        "georeferenceId": "17818",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "29.1102398353499",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.2,
        "longitude": 0.783,
        "region": null
    },
    {
        "georeferenceId": "17800",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "31.6730956610035",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.083,
        "longitude": -3.6,
        "region": null
    },
    {
        "georeferenceId": "17820",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "36.4472560205935",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.133,
        "longitude": 1.3,
        "region": null
    },
    {
        "georeferenceId": "17797",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "25.2681058893038",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.883,
        "longitude": 0.9,
        "region": null
    },
    {
        "georeferenceId": "17821",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "30.5660929785488",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.35,
        "longitude": 1.417,
        "region": null
    },
    {
        "georeferenceId": "17794",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "18.5415940216472",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.883,
        "longitude": 0.233,
        "region": null
    },
    {
        "georeferenceId": "17793",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "19.2521592271891",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.533,
        "longitude": -0.417,
        "region": null
    },
    {
        "georeferenceId": "17823",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "32.2796877056113",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.217,
        "longitude": -5.317,
        "region": null
    },
    {
        "georeferenceId": "17792",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "30.5177254605345",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.683,
        "longitude": -0.8,
        "region": null
    },
    {
        "georeferenceId": "17824",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "36.2490248533031",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.083,
        "longitude": -5.25,
        "region": null
    },
    {
        "georeferenceId": "17791",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "24.2629679573519",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.617,
        "longitude": -1.083,
        "region": null
    },
    {
        "georeferenceId": "17825",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "49.1745749967805",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.15,
        "longitude": -5.067,
        "region": null
    },
    {
        "georeferenceId": "17790",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "20.6267603573263",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.75,
        "longitude": -1.583,
        "region": null
    },
    {
        "georeferenceId": "17933",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "22.3463423218274",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.317,
        "longitude": 0.033,
        "region": null
    },
    {
        "georeferenceId": "17826",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-12",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "28.0201968830059",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.417,
        "longitude": -5.083,
        "region": null
    },
    {
        "georeferenceId": "17862",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "20.1642526505216",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.9,
        "longitude": -2.083,
        "region": null
    },
    {
        "georeferenceId": "17823",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "33.0025882022122",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.217,
        "longitude": -5.317,
        "region": null
    },
    {
        "georeferenceId": "17756",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "22.4765487408857",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53,
        "longitude": -4.083,
        "region": null
    },
    {
        "georeferenceId": "17722",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "23.1582682169612",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.283,
        "longitude": -2.283,
        "region": null
    },
    {
        "georeferenceId": "17884",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "25.7327036409386",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.25,
        "longitude": -0.883,
        "region": null
    },
    {
        "georeferenceId": "17721",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "27.4277369174712",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.683,
        "longitude": -2.45,
        "region": null
    },
    {
        "georeferenceId": "17855",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "25.8834324634263",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.35,
        "longitude": -6.667,
        "region": null
    },
    {
        "georeferenceId": "17757",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "32.2928050430708",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.783,
        "longitude": -4.733,
        "region": null
    },
    {
        "georeferenceId": "17885",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "28.2375643116905",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.05,
        "longitude": -7.15,
        "region": null
    },
    {
        "georeferenceId": "17719",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "24.4287352770649",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.5,
        "longitude": -2.683,
        "region": null
    },
    {
        "georeferenceId": "17931",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "28.0308472887599",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.067,
        "longitude": -3.467,
        "region": null
    },
    {
        "georeferenceId": "17854",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "26.2367411053658",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.483,
        "longitude": -6.1,
        "region": null
    },
    {
        "georeferenceId": "17886",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "18.0511777730887",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.567,
        "longitude": 0.7,
        "region": null
    },
    {
        "georeferenceId": "17718",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "27.694146780082",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.05,
        "longitude": -2.55,
        "region": null
    },
    {
        "georeferenceId": "17658",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "22.3842636202851",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.25,
        "longitude": -5.8,
        "region": null
    },
    {
        "georeferenceId": "17654",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "28.4930999152637",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.417,
        "longitude": -6.2,
        "region": null
    },
    {
        "georeferenceId": "17758",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "25.835578250482",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.8,
        "longitude": -4.117,
        "region": null
    },
    {
        "georeferenceId": "17666",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "29.2948226711574",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.683,
        "longitude": -4.167,
        "region": null
    },
    {
        "georeferenceId": "17891",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "34.2008595135972",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 58.033,
        "longitude": -4.4,
        "region": null
    },
    {
        "georeferenceId": "17717",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "26.5355553089592",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.933,
        "longitude": -2.95,
        "region": null
    },
    {
        "georeferenceId": "17715",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "23.9468435668092",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.767,
        "longitude": -3.317,
        "region": null
    },
    {
        "georeferenceId": "17835",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "14.9627705695516",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.9,
        "longitude": -1.4,
        "region": null
    },
    {
        "georeferenceId": "17759",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "15.9372018669206",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.95,
        "longitude": -3.417,
        "region": null
    },
    {
        "georeferenceId": "17893",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "33.1316664419094",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.683,
        "longitude": -2.017,
        "region": null
    },
    {
        "georeferenceId": "4482347",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "17.445110364183",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.64729,
        "longitude": -0.39027,
        "region": null
    },
    {
        "georeferenceId": "17714",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "28.2945002779462",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.117,
        "longitude": -3.217,
        "region": null
    },
    {
        "georeferenceId": "17660",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "0",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "3",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.817,
        "longitude": -4.967,
        "region": null
    },
    {
        "georeferenceId": "17853",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "26.2367411053658",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.65,
        "longitude": -6.217,
        "region": null
    },
    {
        "georeferenceId": "17792",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "30.4887255614485",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.683,
        "longitude": -0.8,
        "region": null
    },
    {
        "georeferenceId": "17894",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "27.3089456526727",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.633,
        "longitude": -6.183,
        "region": null
    },
    {
        "georeferenceId": "17713",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "27.1619774332824",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.317,
        "longitude": -3.4,
        "region": null
    },
    {
        "georeferenceId": "17810",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "22.5812689402507",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.433,
        "longitude": -1,
        "region": null
    },
    {
        "georeferenceId": "17760",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "24.5463387657575",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.75,
        "longitude": -3.467,
        "region": null
    },
    {
        "georeferenceId": "17895",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "23.4086237145089",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.417,
        "longitude": -5.467,
        "region": null
    },
    {
        "georeferenceId": "17712",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "20.9396995655231",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.617,
        "longitude": -3.167,
        "region": null
    },
    {
        "georeferenceId": "17782",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "28.8528806402971",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.35,
        "longitude": 0.517,
        "region": null
    },
    {
        "georeferenceId": "17852",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "30.9576487814043",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.183,
        "longitude": -6.167,
        "region": null
    },
    {
        "georeferenceId": "17896",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "28.0996502392342",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.6,
        "longitude": -4.5,
        "region": null
    },
    {
        "georeferenceId": "17711",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "31.9657116749406",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.517,
        "longitude": -3.6,
        "region": null
    },
    {
        "georeferenceId": "17809",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "23.3871157957048",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.233,
        "longitude": -0.95,
        "region": null
    },
    {
        "georeferenceId": "17761",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "18.9079263973243",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.567,
        "longitude": -3.717,
        "region": null
    },
    {
        "georeferenceId": "17710",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "23.9993344275908",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.933,
        "longitude": -3.15,
        "region": null
    },
    {
        "georeferenceId": "17897",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "20.1909245771536",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.767,
        "longitude": -4.167,
        "region": null
    },
    {
        "georeferenceId": "17787",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "36.3839049500302",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.567,
        "longitude": -3.983,
        "region": null
    },
    {
        "georeferenceId": "17708",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "27.2204198893742",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.083,
        "longitude": -4.633,
        "region": null
    },
    {
        "georeferenceId": "4482344",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "17.8824495939718",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.68495,
        "longitude": -2.76495,
        "region": null
    },
    {
        "georeferenceId": "17851",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "27.2503842960613",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.867,
        "longitude": -6.45,
        "region": null
    },
    {
        "georeferenceId": "17898",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "24.6674453828037",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.783,
        "longitude": -4,
        "region": null
    },
    {
        "georeferenceId": "17936",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "28.4329925002277",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.117,
        "longitude": -3.15,
        "region": null
    },
    {
        "georeferenceId": "17707",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "23.6855707292708",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.433,
        "longitude": -2.783,
        "region": null
    },
    {
        "georeferenceId": "17811",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "21.0579479711047",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.283,
        "longitude": -0.767,
        "region": null
    },
    {
        "georeferenceId": "17762",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "21.7099132661675",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.8,
        "longitude": -2.667,
        "region": null
    },
    {
        "georeferenceId": "17899",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "29.8994196999742",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.6,
        "longitude": -2.433,
        "region": null
    },
    {
        "georeferenceId": "17706",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "24.9288231110714",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.383,
        "longitude": -2.867,
        "region": null
    },
    {
        "georeferenceId": "17850",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "28.621388043795",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.2,
        "longitude": -6.65,
        "region": null
    },
    {
        "georeferenceId": "17808",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "25.7689547337825",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.15,
        "longitude": -1.567,
        "region": null
    },
    {
        "georeferenceId": "17900",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "23.4766789751199",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.9,
        "longitude": -3.633,
        "region": null
    },
    {
        "georeferenceId": "17705",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "16.4231313741858",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.983,
        "longitude": -2.433,
        "region": null
    },
    {
        "georeferenceId": "17763",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "16.4732649516404",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.733,
        "longitude": -0.65,
        "region": null
    },
    {
        "georeferenceId": "17704",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "21.8440093055222",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.6,
        "longitude": -3.55,
        "region": null
    },
    {
        "georeferenceId": "17653",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "29.2381841433699",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 58.217,
        "longitude": -6.317,
        "region": null
    },
    {
        "georeferenceId": "17834",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "26.7696729246171",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.783,
        "longitude": -1.833,
        "region": null
    },
    {
        "georeferenceId": "17901",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "19.6818299480457",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.467,
        "longitude": -2.567,
        "region": null
    },
    {
        "georeferenceId": "17764",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "17.9526909969189",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.617,
        "longitude": -0.467,
        "region": null
    },
    {
        "georeferenceId": "17703",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "28.9209169444609",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.317,
        "longitude": -3.2,
        "region": null
    },
    {
        "georeferenceId": "17932",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "16.2430775815431",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.75,
        "longitude": -2.483,
        "region": null
    },
    {
        "georeferenceId": "17789",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "25.0381247695394",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.867,
        "longitude": -1.683,
        "region": null
    },
    {
        "georeferenceId": "17903",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "20.9786635143898",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.067,
        "longitude": -2.833,
        "region": null
    },
    {
        "georeferenceId": "17849",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "26.2469608111359",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.517,
        "longitude": -6.75,
        "region": null
    },
    {
        "georeferenceId": "17702",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "24.6055061751705",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.95,
        "longitude": -3.35,
        "region": null
    },
    {
        "georeferenceId": "17813",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "24.7549621394542",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.15,
        "longitude": -0.183,
        "region": null
    },
    {
        "georeferenceId": "17765",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "23.1100427308399",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.867,
        "longitude": 0.15,
        "region": null
    },
    {
        "georeferenceId": "17904",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "22.6035139673058",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.567,
        "longitude": -2.417,
        "region": null
    },
    {
        "georeferenceId": "17701",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "21.6288466655409",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.617,
        "longitude": -2.8,
        "region": null
    },
    {
        "georeferenceId": "17806",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "25.8806700760777",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.2,
        "longitude": -1.8,
        "region": null
    },
    {
        "georeferenceId": "17848",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "27.9374985032621",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.717,
        "longitude": -6.817,
        "region": null
    },
    {
        "georeferenceId": "17824",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "36.9221974143945",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.083,
        "longitude": -5.25,
        "region": null
    },
    {
        "georeferenceId": "17906",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "17.363344601438",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.767,
        "longitude": -2.717,
        "region": null
    },
    {
        "georeferenceId": "17700",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "30.8915142648059",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.433,
        "longitude": -3.367,
        "region": null
    },
    {
        "georeferenceId": "17783",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "19.4803968811641",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.25,
        "longitude": 0.7,
        "region": null
    },
    {
        "georeferenceId": "17699",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "27.9955168135947",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.667,
        "longitude": -3.783,
        "region": null
    },
    {
        "georeferenceId": "17766",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "16.9424983253975",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.65,
        "longitude": 0.567,
        "region": null
    },
    {
        "georeferenceId": "17907",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "14.467347377237",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.05,
        "longitude": -3,
        "region": null
    },
    {
        "georeferenceId": "17805",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "25.5117930324467",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.5,
        "longitude": -1.983,
        "region": null
    },
    {
        "georeferenceId": "17698",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "30.0934460037901",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.05,
        "longitude": -3.65,
        "region": null
    },
    {
        "georeferenceId": "17847",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "26.4897302656532",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.067,
        "longitude": -7.017,
        "region": null
    },
    {
        "georeferenceId": "17767",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "21.871474156493",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.95,
        "longitude": 1.133,
        "region": null
    },
    {
        "georeferenceId": "17908",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "14.3421760254881",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53,
        "longitude": -2.183,
        "region": null
    },
    {
        "georeferenceId": "17697",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "32.9723475548896",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.8,
        "longitude": -4,
        "region": null
    },
    {
        "georeferenceId": "17816",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "24.451338740499",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.3,
        "longitude": -0.083,
        "region": null
    },
    {
        "georeferenceId": "17845",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "29.1379826450484",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.6,
        "longitude": -7.3,
        "region": null
    },
    {
        "georeferenceId": "17909",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "20.5016972007072",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.367,
        "longitude": -1.5,
        "region": null
    },
    {
        "georeferenceId": "17696",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "20.156526581868",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "2",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.417,
        "longitude": -4.317,
        "region": null
    },
    {
        "georeferenceId": "17768",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "23.7763571194989",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.633,
        "longitude": 1.3,
        "region": null
    },
    {
        "georeferenceId": "4482345",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "17.6126864484055",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.03516,
        "longitude": 0.09752,
        "region": null
    },
    {
        "georeferenceId": "17910",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "27.7537792010198",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.933,
        "longitude": 1.283,
        "region": null
    },
    {
        "georeferenceId": "17694",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "27.2204198893742",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.317,
        "longitude": -3.733,
        "region": null
    },
    {
        "georeferenceId": "17769",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "19.9771355881682",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.8,
        "longitude": 1.25,
        "region": null
    },
    {
        "georeferenceId": "17693",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "23.5543772622474",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.867,
        "longitude": -4.433,
        "region": null
    },
    {
        "georeferenceId": "17802",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "27.9911628866778",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.4,
        "longitude": -3.433,
        "region": null
    },
    {
        "georeferenceId": "17911",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "27.7581104373528",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.083,
        "longitude": -4.15,
        "region": null
    },
    {
        "georeferenceId": "17791",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "24.380030869691",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.617,
        "longitude": -1.083,
        "region": null
    },
    {
        "georeferenceId": "17844",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "23.0895788363692",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.4,
        "longitude": -7.65,
        "region": null
    },
    {
        "georeferenceId": "17691",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "24.3645478183471",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.95,
        "longitude": -4.767,
        "region": null
    },
    {
        "georeferenceId": "17665",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "24.4708511309958",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.267,
        "longitude": -4.483,
        "region": null
    },
    {
        "georeferenceId": "17651",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "28.0032581500646",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.467,
        "longitude": -7.367,
        "region": null
    },
    {
        "georeferenceId": "17659",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "23.817267256505",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.767,
        "longitude": -6.05,
        "region": null
    },
    {
        "georeferenceId": "17832",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "27.3890587190903",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.95,
        "longitude": -2.633,
        "region": null
    },
    {
        "georeferenceId": "17912",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "22.0792849437763",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.35,
        "longitude": -2.983,
        "region": null
    },
    {
        "georeferenceId": "17690",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "26.2734510647982",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.467,
        "longitude": -4.617,
        "region": null
    },
    {
        "georeferenceId": "17826",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "29.1052874006574",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.417,
        "longitude": -5.083,
        "region": null
    },
    {
        "georeferenceId": "17770",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "25.215118320225",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.683,
        "longitude": 1.683,
        "region": null
    },
    {
        "georeferenceId": "17817",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "18.6080210870443",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.433,
        "longitude": 0.367,
        "region": null
    },
    {
        "georeferenceId": "17913",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "25.8691621942532",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.483,
        "longitude": -0.6,
        "region": null
    },
    {
        "georeferenceId": "17689",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "34.1037092466568",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.85,
        "longitude": -4.95,
        "region": null
    },
    {
        "georeferenceId": "17784",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "19.5184467563767",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.117,
        "longitude": 0.967,
        "region": null
    },
    {
        "georeferenceId": "17843",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "25.3865742388291",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.433,
        "longitude": -8.05,
        "region": null
    },
    {
        "georeferenceId": "17914",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "16.7873744873102",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.2,
        "longitude": 0.183,
        "region": null
    },
    {
        "georeferenceId": "17688",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "30.7799298992442",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.617,
        "longitude": -4.85,
        "region": null
    },
    {
        "georeferenceId": "17801",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "23.3871157957049",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.5,
        "longitude": -3.2,
        "region": null
    },
    {
        "georeferenceId": "17771",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "29.7091198181546",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.133,
        "longitude": -4.567,
        "region": null
    },
    {
        "georeferenceId": "17687",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "23.1420429544886",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.7,
        "longitude": -5.3,
        "region": null
    },
    {
        "georeferenceId": "17915",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "22.5297034615617",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.267,
        "longitude": 0.517,
        "region": null
    },
    {
        "georeferenceId": "17686",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "26.255788983866",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.233,
        "longitude": -5.083,
        "region": null
    },
    {
        "georeferenceId": "17649",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "34.2024798107831",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 58.95,
        "longitude": -2.9,
        "region": null
    },
    {
        "georeferenceId": "17842",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "30.7124739725025",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 49.217,
        "longitude": -2.2,
        "region": null
    },
    {
        "georeferenceId": "17785",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "39.0509277574347",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.7,
        "longitude": -5.05,
        "region": null
    },
    {
        "georeferenceId": "17916",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "36.9065481046347",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.083,
        "longitude": 1.183,
        "region": null
    },
    {
        "georeferenceId": "17772",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "31.0161581874433",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.35,
        "longitude": -3.95,
        "region": null
    },
    {
        "georeferenceId": "17685",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "32.2513637770174",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.433,
        "longitude": -5.7,
        "region": null
    },
    {
        "georeferenceId": "17827",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "35.3192383287698",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.467,
        "longitude": -4.717,
        "region": null
    },
    {
        "georeferenceId": "17818",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "28.9727473898864",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.2,
        "longitude": 0.783,
        "region": null
    },
    {
        "georeferenceId": "17917",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "34.2592134128333",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.283,
        "longitude": 1.083,
        "region": null
    },
    {
        "georeferenceId": "17683",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "30.3089680307759",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.5,
        "longitude": -6.883,
        "region": null
    },
    {
        "georeferenceId": "17841",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "31.6258501094988",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 49.433,
        "longitude": -2.6,
        "region": null
    },
    {
        "georeferenceId": "17773",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "29.8024250049395",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.067,
        "longitude": -3.617,
        "region": null
    },
    {
        "georeferenceId": "17800",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "31.7157009855453",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.083,
        "longitude": -3.6,
        "region": null
    },
    {
        "georeferenceId": "17919",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "26.6109491546385",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.517,
        "longitude": -4.833,
        "region": null
    },
    {
        "georeferenceId": "17682",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "27.1244827472571",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.65,
        "longitude": -3.383,
        "region": null
    },
    {
        "georeferenceId": "17933",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "21.7744339280843",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.317,
        "longitude": 0.033,
        "region": null
    },
    {
        "georeferenceId": "17681",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "33.8963640204404",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.5,
        "longitude": -1.767,
        "region": null
    },
    {
        "georeferenceId": "17774",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "23.9206276508712",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.083,
        "longitude": -2.8,
        "region": null
    },
    {
        "georeferenceId": "17920",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "31.369003952641",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.45,
        "longitude": -4.45,
        "region": null
    },
    {
        "georeferenceId": "17831",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "28.1593302394794",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.867,
        "longitude": -3.233,
        "region": null
    },
    {
        "georeferenceId": "17680",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "24.7491510104542",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.2,
        "longitude": -2.217,
        "region": null
    },
    {
        "georeferenceId": "4482346",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "26.2734510647982",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.46997,
        "longitude": -4.564,
        "region": null
    },
    {
        "georeferenceId": "17840",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "32.4257090932088",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.8,
        "longitude": 0.25,
        "region": null
    },
    {
        "georeferenceId": "17921",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "31.168875861642",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.467,
        "longitude": -3.533,
        "region": null
    },
    {
        "georeferenceId": "17935",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "23.2502186734315",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56,
        "longitude": -3.783,
        "region": null
    },
    {
        "georeferenceId": "17679",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "27.8279488055432",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.717,
        "longitude": -2.467,
        "region": null
    },
    {
        "georeferenceId": "17820",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "35.7690083560481",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.133,
        "longitude": 1.3,
        "region": null
    },
    {
        "georeferenceId": "17775",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "16.9306811142337",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.2,
        "longitude": -2.217,
        "region": null
    },
    {
        "georeferenceId": "17922",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "32.8938937904204",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.6,
        "longitude": -2.45,
        "region": null
    },
    {
        "georeferenceId": "17678",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "18.0767537198456",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.083,
        "longitude": -2.833,
        "region": null
    },
    {
        "georeferenceId": "17797",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "23.6365849398466",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.883,
        "longitude": 0.9,
        "region": null
    },
    {
        "georeferenceId": "17825",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "49.5495121901492",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.15,
        "longitude": -5.067,
        "region": null
    },
    {
        "georeferenceId": "17786",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "32.778507142961",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.717,
        "longitude": -4.367,
        "region": null
    },
    {
        "georeferenceId": "17923",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "36.503422117381",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.067,
        "longitude": -1.317,
        "region": null
    },
    {
        "georeferenceId": "17676",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "34.0287788588641",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 58.45,
        "longitude": -3.083,
        "region": null
    },
    {
        "georeferenceId": "17839",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "36.4928534067313",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.833,
        "longitude": -0.283,
        "region": null
    },
    {
        "georeferenceId": "17675",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "30.6058373174291",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 58.617,
        "longitude": -3.55,
        "region": null
    },
    {
        "georeferenceId": "17776",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "27.3618610998617",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.117,
        "longitude": -2.3,
        "region": null
    },
    {
        "georeferenceId": "17794",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "18.080308167388",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.883,
        "longitude": 0.233,
        "region": null
    },
    {
        "georeferenceId": "17924",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "37.6647131995399",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.867,
        "longitude": 0.6,
        "region": null
    },
    {
        "georeferenceId": "17674",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "0",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "2",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.883,
        "longitude": -3.417,
        "region": null
    },
    {
        "georeferenceId": "17838",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "28.9337460263854",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.8,
        "longitude": -1.217,
        "region": null
    },
    {
        "georeferenceId": "17925",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "28.2083933502422",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.817,
        "longitude": -7.467,
        "region": null
    },
    {
        "georeferenceId": "17744",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "14.1524657313496",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.65,
        "longitude": -1.783,
        "region": null
    },
    {
        "georeferenceId": "17777",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "23.7268076107742",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.15,
        "longitude": -2.033,
        "region": null
    },
    {
        "georeferenceId": "17864",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "4.72874634755931",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52,
        "longitude": -0.733,
        "region": null
    },
    {
        "georeferenceId": "17745",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "20.8270842566345",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.8,
        "longitude": -1.55,
        "region": null
    },
    {
        "georeferenceId": "17865",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "31.4408688286737",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.833,
        "longitude": -4.533,
        "region": null
    },
    {
        "georeferenceId": "17743",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "19.3179095741994",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.817,
        "longitude": -1.867,
        "region": null
    },
    {
        "georeferenceId": "17673",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "20.9095192706129",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.35,
        "longitude": -3.35,
        "region": null
    },
    {
        "georeferenceId": "17742",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "16.5682599396153",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.967,
        "longitude": -2.033,
        "region": null
    },
    {
        "georeferenceId": "17821",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "29.0414455468591",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.35,
        "longitude": 1.417,
        "region": null
    },
    {
        "georeferenceId": "17866",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "27.8525382934987",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.133,
        "longitude": -3.017,
        "region": null
    },
    {
        "georeferenceId": "17863",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "23.3531612753416",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.383,
        "longitude": -2.35,
        "region": null
    },
    {
        "georeferenceId": "17741",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "11.7200496343284",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.5,
        "longitude": -2.217,
        "region": null
    },
    {
        "georeferenceId": "17642",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "34.5305315113515",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 60.133,
        "longitude": -1.183,
        "region": null
    },
    {
        "georeferenceId": "17656",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "22.611492376336",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.867,
        "longitude": -5.633,
        "region": null
    },
    {
        "georeferenceId": "17657",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "29.3779090391986",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.033,
        "longitude": -7.45,
        "region": null
    },
    {
        "georeferenceId": "17867",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "16.4868088458039",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.2,
        "longitude": -1.7,
        "region": null
    },
    {
        "georeferenceId": "17746",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "18.0746213127353",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53,
        "longitude": -1.25,
        "region": null
    },
    {
        "georeferenceId": "17740",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "18.6469459259439",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.2,
        "longitude": -2.9,
        "region": null
    },
    {
        "georeferenceId": "17926",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "27.3647232142793",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.717,
        "longitude": -6.517,
        "region": null
    },
    {
        "georeferenceId": "17868",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "30.9043249278133",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 60.433,
        "longitude": -1.3,
        "region": null
    },
    {
        "georeferenceId": "17739",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "23.404751275739",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.767,
        "longitude": -3.033,
        "region": null
    },
    {
        "georeferenceId": "17723",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "11.8791542423887",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.817,
        "longitude": -2.083,
        "region": null
    },
    {
        "georeferenceId": "17747",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "13.2602663091516",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.833,
        "longitude": -1.2,
        "region": null
    },
    {
        "georeferenceId": "17672",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "24.6322296662586",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.717,
        "longitude": -3.317,
        "region": null
    },
    {
        "georeferenceId": "17869",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "26.3483673001649",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.45,
        "longitude": -3.017,
        "region": null
    },
    {
        "georeferenceId": "17738",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "15.2297745808427",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.5,
        "longitude": -3.067,
        "region": null
    },
    {
        "georeferenceId": "17790",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "20.235048960884",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.75,
        "longitude": -1.583,
        "region": null
    },
    {
        "georeferenceId": "17737",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "12.419310222398",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.217,
        "longitude": -3.217,
        "region": null
    },
    {
        "georeferenceId": "17837",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "31.6045760339435",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.817,
        "longitude": -0.917,
        "region": null
    },
    {
        "georeferenceId": "17748",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "15.953606948209",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.4,
        "longitude": -0.767,
        "region": null
    },
    {
        "georeferenceId": "17870",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "12.2091458246844",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.783,
        "longitude": -1.75,
        "region": null
    },
    {
        "georeferenceId": "17736",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "19.5588184034911",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.217,
        "longitude": -3.5,
        "region": null
    },
    {
        "georeferenceId": "17778",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "19.3310052669242",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.45,
        "longitude": -1.733,
        "region": null
    },
    {
        "georeferenceId": "4482342",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "15.1614244864514",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.85426,
        "longitude": -1.50702,
        "region": null
    },
    {
        "georeferenceId": "17861",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "16.1044627194352",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.367,
        "longitude": -1.483,
        "region": null
    },
    {
        "georeferenceId": "17871",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "23.4456494521102",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.583,
        "longitude": -0.35,
        "region": null
    },
    {
        "georeferenceId": "51522",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "28.0647513804975",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.817,
        "longitude": 1.133,
        "region": null
    },
    {
        "georeferenceId": "17735",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "28.386620419281",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.1,
        "longitude": -3.933,
        "region": null
    },
    {
        "georeferenceId": "17927",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "29.81045913054",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.183,
        "longitude": -6.333,
        "region": null
    },
    {
        "georeferenceId": "17749",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "16.9158345034607",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.167,
        "longitude": -0.517,
        "region": null
    },
    {
        "georeferenceId": "17872",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "19.7646690664569",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.033,
        "longitude": -1.7,
        "region": null
    },
    {
        "georeferenceId": "17734",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "28.8775204821939",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.25,
        "longitude": -4.533,
        "region": null
    },
    {
        "georeferenceId": "17671",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "25.0196605632131",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.65,
        "longitude": -3.567,
        "region": null
    },
    {
        "georeferenceId": "17793",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "19.1709549305171",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.533,
        "longitude": -0.417,
        "region": null
    },
    {
        "georeferenceId": "17873",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "22.1719142730804",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.533,
        "longitude": -1.567,
        "region": null
    },
    {
        "georeferenceId": "17733",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "20.2656685758868",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.1,
        "longitude": -0.167,
        "region": null
    },
    {
        "georeferenceId": "17860",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "23.3694895496857",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.567,
        "longitude": -1.167,
        "region": null
    },
    {
        "georeferenceId": "17732",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "15.7805994816947",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.483,
        "longitude": -0.6,
        "region": null
    },
    {
        "georeferenceId": "17750",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "18.2458338318311",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.033,
        "longitude": -0.5,
        "region": null
    },
    {
        "georeferenceId": "17669",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "15.4965885445638",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.2,
        "longitude": -3.833,
        "region": null
    },
    {
        "georeferenceId": "17874",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "18.4717356630192",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.833,
        "longitude": -1.333,
        "region": null
    },
    {
        "georeferenceId": "4482348",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "18.7344821623703",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.65863,
        "longitude": -0.49627,
        "region": null
    },
    {
        "georeferenceId": "17731",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "19.9732991583501",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.367,
        "longitude": -0.667,
        "region": null
    },
    {
        "georeferenceId": "17780",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "22.5163017496386",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.367,
        "longitude": -1.333,
        "region": null
    },
    {
        "georeferenceId": "17859",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "19.077041130831",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.867,
        "longitude": -1.65,
        "region": null
    },
    {
        "georeferenceId": "17876",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "17.4395807675327",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.867,
        "longitude": -2.25,
        "region": null
    },
    {
        "georeferenceId": "17751",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "20.6192956669554",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.867,
        "longitude": -0.433,
        "region": null
    },
    {
        "georeferenceId": "17730",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "11.9469022206192",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.683,
        "longitude": -1.217,
        "region": null
    },
    {
        "georeferenceId": "17929",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "24.8323819860948",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 58.283,
        "longitude": -4.433,
        "region": null
    },
    {
        "georeferenceId": "17655",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "21.0715160128923",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.717,
        "longitude": -4.883,
        "region": null
    },
    {
        "georeferenceId": "17877",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "24.4079171585879",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.383,
        "longitude": -2.717,
        "region": null
    },
    {
        "georeferenceId": "17729",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "13.5691246507042",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.05,
        "longitude": -1.25,
        "region": null
    },
    {
        "georeferenceId": "17663",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "20.7594927377787",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.867,
        "longitude": -4.7,
        "region": null
    },
    {
        "georeferenceId": "17858",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "27.3075163924661",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.85,
        "longitude": -5.8,
        "region": null
    },
    {
        "georeferenceId": "17640",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "35.5636010990363",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 60.75,
        "longitude": -0.85,
        "region": null
    },
    {
        "georeferenceId": "17878",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "22.6686591758466",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.333,
        "longitude": -2.85,
        "region": null
    },
    {
        "georeferenceId": "17728",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "16.8424292169691",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.2,
        "longitude": -1.383,
        "region": null
    },
    {
        "georeferenceId": "17668",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "26.8007363226293",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.817,
        "longitude": -3.967,
        "region": null
    },
    {
        "georeferenceId": "17752",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "16.8615977815925",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.483,
        "longitude": -0.083,
        "region": null
    },
    {
        "georeferenceId": "17727",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "14.4243380184797",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.133,
        "longitude": -1.417,
        "region": null
    },
    {
        "georeferenceId": "17753",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "17.1955952006428",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.083,
        "longitude": -0.167,
        "region": null
    },
    {
        "georeferenceId": "17830",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "33.0429309435773",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.717,
        "longitude": -3.533,
        "region": null
    },
    {
        "georeferenceId": "17879",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "17.9025469942309",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.867,
        "longitude": -0.367,
        "region": null
    },
    {
        "georeferenceId": "17836",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "37.180245519337",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.583,
        "longitude": -1.3,
        "region": null
    },
    {
        "georeferenceId": "17726",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "17.2592499127639",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.3,
        "longitude": -1.533,
        "region": null
    },
    {
        "georeferenceId": "17857",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "31.8077706328718",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.667,
        "longitude": -5.667,
        "region": null
    },
    {
        "georeferenceId": "17788",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "28.7751009538544",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.517,
        "longitude": -2.583,
        "region": null
    },
    {
        "georeferenceId": "17880",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "44.9379025363442",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.417,
        "longitude": -4.117,
        "region": null
    },
    {
        "georeferenceId": "17725",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "14.3888245079183",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.917,
        "longitude": -1.383,
        "region": null
    },
    {
        "georeferenceId": "17930",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "25.4397995442258",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.95,
        "longitude": -3.983,
        "region": null
    },
    {
        "georeferenceId": "17754",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "23.7433162058579",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.083,
        "longitude": 0.267,
        "region": null
    },
    {
        "georeferenceId": "17781",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "21.6097323086829",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.217,
        "longitude": -0.483,
        "region": null
    },
    {
        "georeferenceId": "17881",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "15.6372772416884",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.5,
        "longitude": 0.05,
        "region": null
    },
    {
        "georeferenceId": "17724",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "27.1140469754096",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.417,
        "longitude": -1.6,
        "region": null
    },
    {
        "georeferenceId": "4482343",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "15.953606948209",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.39455,
        "longitude": -0.78437,
        "region": null
    },
    {
        "georeferenceId": "17856",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "25.0295670579596",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.6,
        "longitude": -5.883,
        "region": null
    },
    {
        "georeferenceId": "17667",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "23.1394817973406",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.533,
        "longitude": -4.05,
        "region": null
    },
    {
        "georeferenceId": "17882",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-13",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "37.8480202140177",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 49.717,
        "longitude": -2.2,
        "region": null
    },
    {
        "georeferenceId": "17868",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "26.5294735643612",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 60.433,
        "longitude": -1.3,
        "region": null
    },
    {
        "georeferenceId": "17739",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "24.8014841236177",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.767,
        "longitude": -3.033,
        "region": null
    },
    {
        "georeferenceId": "17731",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "21.319654348874",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.367,
        "longitude": -0.667,
        "region": null
    },
    {
        "georeferenceId": "17722",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "24.5632339400916",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.283,
        "longitude": -2.283,
        "region": null
    },
    {
        "georeferenceId": "17672",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "24.4988076467826",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.717,
        "longitude": -3.317,
        "region": null
    },
    {
        "georeferenceId": "4482343",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "17.2722775566062",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.39455,
        "longitude": -0.78437,
        "region": null
    },
    {
        "georeferenceId": "17756",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "24.472181904498",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53,
        "longitude": -4.083,
        "region": null
    },
    {
        "georeferenceId": "17781",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "23.1800567150581",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.217,
        "longitude": -0.483,
        "region": null
    },
    {
        "georeferenceId": "17916",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "36.6704683752985",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.083,
        "longitude": 1.183,
        "region": null
    },
    {
        "georeferenceId": "17884",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "26.7903793183309",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.25,
        "longitude": -0.883,
        "region": null
    },
    {
        "georeferenceId": "17823",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "33.5493071456256",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.217,
        "longitude": -5.317,
        "region": null
    },
    {
        "georeferenceId": "17855",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "26.5280702113168",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.35,
        "longitude": -6.667,
        "region": null
    },
    {
        "georeferenceId": "17721",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "29.5539866836681",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "2",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.683,
        "longitude": -2.45,
        "region": null
    },
    {
        "georeferenceId": "17725",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "15.9230594510342",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.917,
        "longitude": -1.383,
        "region": null
    },
    {
        "georeferenceId": "17835",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "15.7605149038902",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.9,
        "longitude": -1.4,
        "region": null
    },
    {
        "georeferenceId": "17685",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "33.5204206121342",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.433,
        "longitude": -5.7,
        "region": null
    },
    {
        "georeferenceId": "17738",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "16.4201667863976",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.5,
        "longitude": -3.067,
        "region": null
    },
    {
        "georeferenceId": "17933",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "23.1719377588101",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.317,
        "longitude": 0.033,
        "region": null
    },
    {
        "georeferenceId": "51522",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "25.4343371565624",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.817,
        "longitude": 1.133,
        "region": null
    },
    {
        "georeferenceId": "17885",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "30.1651896943735",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.05,
        "longitude": -7.15,
        "region": null
    },
    {
        "georeferenceId": "17757",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "33.9625400953735",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.783,
        "longitude": -4.733,
        "region": null
    },
    {
        "georeferenceId": "17719",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "25.5980876045647",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.5,
        "longitude": -2.683,
        "region": null
    },
    {
        "georeferenceId": "17772",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "32.7367439452862",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.35,
        "longitude": -3.95,
        "region": null
    },
    {
        "georeferenceId": "17752",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "18.0096770525521",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.483,
        "longitude": -0.083,
        "region": null
    },
    {
        "georeferenceId": "17854",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "27.2879878509615",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.483,
        "longitude": -6.1,
        "region": null
    },
    {
        "georeferenceId": "17654",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "29.588975630961",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.417,
        "longitude": -6.2,
        "region": null
    },
    {
        "georeferenceId": "17841",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "32.3826022963197",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 49.433,
        "longitude": -2.6,
        "region": null
    },
    {
        "georeferenceId": "17931",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "28.3544309150013",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.067,
        "longitude": -3.467,
        "region": null
    },
    {
        "georeferenceId": "17818",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "25.5090566570281",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.2,
        "longitude": 0.783,
        "region": null
    },
    {
        "georeferenceId": "17886",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "18.6815579341366",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.567,
        "longitude": 0.7,
        "region": null
    },
    {
        "georeferenceId": "17718",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "29.4297209262325",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.05,
        "longitude": -2.55,
        "region": null
    },
    {
        "georeferenceId": "17655",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "21.3551032939205",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.717,
        "longitude": -4.883,
        "region": null
    },
    {
        "georeferenceId": "17666",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "27.8949985662267",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.683,
        "longitude": -4.167,
        "region": null
    },
    {
        "georeferenceId": "17917",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "29.9759365867717",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.283,
        "longitude": 1.083,
        "region": null
    },
    {
        "georeferenceId": "17717",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "28.0996502392342",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.933,
        "longitude": -2.95,
        "region": null
    },
    {
        "georeferenceId": "17683",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "30.4933034643303",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.5,
        "longitude": -6.883,
        "region": null
    },
    {
        "georeferenceId": "17724",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "28.1977019159992",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.417,
        "longitude": -1.6,
        "region": null
    },
    {
        "georeferenceId": "17800",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "32.7282889027194",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.083,
        "longitude": -3.6,
        "region": null
    },
    {
        "georeferenceId": "17869",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "27.0107743502894",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.45,
        "longitude": -3.017,
        "region": null
    },
    {
        "georeferenceId": "17891",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "33.9523136759652",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 58.033,
        "longitude": -4.4,
        "region": null
    },
    {
        "georeferenceId": "17682",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "27.6441934928666",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.65,
        "longitude": -3.383,
        "region": null
    },
    {
        "georeferenceId": "17792",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "32.4688457184145",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.683,
        "longitude": -0.8,
        "region": null
    },
    {
        "georeferenceId": "17758",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "28.0216490655303",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.8,
        "longitude": -4.117,
        "region": null
    },
    {
        "georeferenceId": "17715",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "24.5507841040079",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.767,
        "longitude": -3.317,
        "region": null
    },
    {
        "georeferenceId": "17935",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "23.8159608666886",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56,
        "longitude": -3.783,
        "region": null
    },
    {
        "georeferenceId": "17826",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "30.9109876329171",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.417,
        "longitude": -5.083,
        "region": null
    },
    {
        "georeferenceId": "17882",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "39.1520820587306",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 49.717,
        "longitude": -2.2,
        "region": null
    },
    {
        "georeferenceId": "17665",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "24.3380209556191",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.267,
        "longitude": -4.483,
        "region": null
    },
    {
        "georeferenceId": "17747",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "15.2483747430769",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.833,
        "longitude": -1.2,
        "region": null
    },
    {
        "georeferenceId": "17893",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "30.267414805916",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.683,
        "longitude": -2.017,
        "region": null
    },
    {
        "georeferenceId": "17919",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "28.7672315012837",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.517,
        "longitude": -4.833,
        "region": null
    },
    {
        "georeferenceId": "17853",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "28.0812281029015",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.65,
        "longitude": -6.217,
        "region": null
    },
    {
        "georeferenceId": "17714",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "29.6570227569696",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.117,
        "longitude": -3.217,
        "region": null
    },
    {
        "georeferenceId": "17737",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "14.0605738629886",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.217,
        "longitude": -3.217,
        "region": null
    },
    {
        "georeferenceId": "17876",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "19.436389632453",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.867,
        "longitude": -2.25,
        "region": null
    },
    {
        "georeferenceId": "17773",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "31.2157504117871",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.067,
        "longitude": -3.617,
        "region": null
    },
    {
        "georeferenceId": "17658",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "22.7989249309726",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.25,
        "longitude": -5.8,
        "region": null
    },
    {
        "georeferenceId": "17681",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "30.9966482891954",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.5,
        "longitude": -1.767,
        "region": null
    },
    {
        "georeferenceId": "17667",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "23.2609270869904",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.533,
        "longitude": -4.05,
        "region": null
    },
    {
        "georeferenceId": "17894",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "28.1025596705222",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.633,
        "longitude": -6.183,
        "region": null
    },
    {
        "georeferenceId": "17759",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "18.176841143126",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.95,
        "longitude": -3.417,
        "region": null
    },
    {
        "georeferenceId": "17713",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "27.2579975881973",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.317,
        "longitude": -3.4,
        "region": null
    },
    {
        "georeferenceId": "17831",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "28.6601381649697",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.867,
        "longitude": -3.233,
        "region": null
    },
    {
        "georeferenceId": "17730",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "13.3335896018356",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.683,
        "longitude": -1.217,
        "region": null
    },
    {
        "georeferenceId": "17797",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "22.3871825391549",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.883,
        "longitude": 0.9,
        "region": null
    },
    {
        "georeferenceId": "4482348",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "19.9637105027125",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.65863,
        "longitude": -0.49627,
        "region": null
    },
    {
        "georeferenceId": "17760",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "25.5072325139711",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.75,
        "longitude": -3.467,
        "region": null
    },
    {
        "georeferenceId": "17810",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "24.5565638556023",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.433,
        "longitude": -1,
        "region": null
    },
    {
        "georeferenceId": "17671",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "24.9571299497991",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.65,
        "longitude": -3.567,
        "region": null
    },
    {
        "georeferenceId": "17895",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "24.0032744140927",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.417,
        "longitude": -5.467,
        "region": null
    },
    {
        "georeferenceId": "17712",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "22.4752941948133",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.617,
        "longitude": -3.167,
        "region": null
    },
    {
        "georeferenceId": "17920",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "31.9855487879642",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.45,
        "longitude": -4.45,
        "region": null
    },
    {
        "georeferenceId": "17879",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "19.5493801208711",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.867,
        "longitude": -0.367,
        "region": null
    },
    {
        "georeferenceId": "17852",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "33.1247545726439",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.183,
        "longitude": -6.167,
        "region": null
    },
    {
        "georeferenceId": "17711",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "32.9405305115174",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.517,
        "longitude": -3.6,
        "region": null
    },
    {
        "georeferenceId": "17840",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "32.20366219423",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.8,
        "longitude": 0.25,
        "region": null
    },
    {
        "georeferenceId": "17936",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "30.246650288607",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.117,
        "longitude": -3.15,
        "region": null
    },
    {
        "georeferenceId": "17809",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "24.5494504455103",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.233,
        "longitude": -0.95,
        "region": null
    },
    {
        "georeferenceId": "17680",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "24.5703500434656",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.2,
        "longitude": -2.217,
        "region": null
    },
    {
        "georeferenceId": "17896",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "28.2760006352195",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.6,
        "longitude": -4.5,
        "region": null
    },
    {
        "georeferenceId": "17870",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "14.1588819042562",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.783,
        "longitude": -1.75,
        "region": null
    },
    {
        "georeferenceId": "17785",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "39.7546158846419",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.7,
        "longitude": -5.05,
        "region": null
    },
    {
        "georeferenceId": "17830",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "33.1923093589303",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.717,
        "longitude": -3.533,
        "region": null
    },
    {
        "georeferenceId": "17710",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "24.5325616686286",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.933,
        "longitude": -3.15,
        "region": null
    },
    {
        "georeferenceId": "17861",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "18.3772280332388",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.367,
        "longitude": -1.483,
        "region": null
    },
    {
        "georeferenceId": "17789",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "26.3292762381446",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.867,
        "longitude": -1.683,
        "region": null
    },
    {
        "georeferenceId": "17761",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "21.0539585899315",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.567,
        "longitude": -3.717,
        "region": null
    },
    {
        "georeferenceId": "17782",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "31.0115368112612",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.35,
        "longitude": 0.517,
        "region": null
    },
    {
        "georeferenceId": "17788",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "30.672142017836",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.517,
        "longitude": -2.583,
        "region": null
    },
    {
        "georeferenceId": "17897",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "20.2458975308863",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.767,
        "longitude": -4.167,
        "region": null
    },
    {
        "georeferenceId": "17736",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "20.5424082134734",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.217,
        "longitude": -3.5,
        "region": null
    },
    {
        "georeferenceId": "17851",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "29.0582640524581",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.867,
        "longitude": -6.45,
        "region": null
    },
    {
        "georeferenceId": "17708",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "27.8568789994051",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.083,
        "longitude": -4.633,
        "region": null
    },
    {
        "georeferenceId": "17921",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "31.3969120897518",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.467,
        "longitude": -3.533,
        "region": null
    },
    {
        "georeferenceId": "17834",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "27.1501085116633",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.783,
        "longitude": -1.833,
        "region": null
    },
    {
        "georeferenceId": "17930",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "25.7717093431748",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.95,
        "longitude": -3.983,
        "region": null
    },
    {
        "georeferenceId": "17679",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "28.2283248611915",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.717,
        "longitude": -2.467,
        "region": null
    },
    {
        "georeferenceId": "4482347",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "18.0164114185409",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.64729,
        "longitude": -0.39027,
        "region": null
    },
    {
        "georeferenceId": "17751",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "21.5200182393973",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.867,
        "longitude": -0.433,
        "region": null
    },
    {
        "georeferenceId": "17898",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "25.329336903573",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.783,
        "longitude": -4,
        "region": null
    },
    {
        "georeferenceId": "17857",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "33.4648220607267",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.667,
        "longitude": -5.667,
        "region": null
    },
    {
        "georeferenceId": "17707",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "23.6773292866322",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.433,
        "longitude": -2.783,
        "region": null
    },
    {
        "georeferenceId": "17774",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "24.5592317427876",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.083,
        "longitude": -2.8,
        "region": null
    },
    {
        "georeferenceId": "4482346",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "27.4220046704881",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.46997,
        "longitude": -4.564,
        "region": null
    },
    {
        "georeferenceId": "17858",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "28.6709349236053",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.85,
        "longitude": -5.8,
        "region": null
    },
    {
        "georeferenceId": "4482344",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "20.1580716185423",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.68495,
        "longitude": -2.76495,
        "region": null
    },
    {
        "georeferenceId": "17762",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "23.0614616924446",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.8,
        "longitude": -2.667,
        "region": null
    },
    {
        "georeferenceId": "17811",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "22.6249342019923",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.283,
        "longitude": -0.767,
        "region": null
    },
    {
        "georeferenceId": "17820",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "35.6802826716095",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.133,
        "longitude": 1.3,
        "region": null
    },
    {
        "georeferenceId": "17899",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "31.4620833983236",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.6,
        "longitude": -2.433,
        "region": null
    },
    {
        "georeferenceId": "17706",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "25.4944659145533",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.383,
        "longitude": -2.867,
        "region": null
    },
    {
        "georeferenceId": "17793",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "20.9687186741919",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.533,
        "longitude": -0.417,
        "region": null
    },
    {
        "georeferenceId": "17808",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "26.8841554232237",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.15,
        "longitude": -1.567,
        "region": null
    },
    {
        "georeferenceId": "17850",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "29.729672597096",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.2,
        "longitude": -6.65,
        "region": null
    },
    {
        "georeferenceId": "17705",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "17.3110636229099",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.983,
        "longitude": -2.433,
        "region": null
    },
    {
        "georeferenceId": "17653",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "29.2287506383092",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 58.217,
        "longitude": -6.317,
        "region": null
    },
    {
        "georeferenceId": "17922",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "33.0429309435773",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.6,
        "longitude": -2.45,
        "region": null
    },
    {
        "georeferenceId": "17678",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "18.3509625866922",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.083,
        "longitude": -2.833,
        "region": null
    },
    {
        "georeferenceId": "17929",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "23.8103004133454",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 58.283,
        "longitude": -4.433,
        "region": null
    },
    {
        "georeferenceId": "17900",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "23.8351261869446",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.9,
        "longitude": -3.633,
        "region": null
    },
    {
        "georeferenceId": "17871",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "24.907719282444",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.583,
        "longitude": -0.35,
        "region": null
    },
    {
        "georeferenceId": "17787",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "37.8497139059946",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.567,
        "longitude": -3.983,
        "region": null
    },
    {
        "georeferenceId": "17775",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "18.82343440465",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.2,
        "longitude": -2.217,
        "region": null
    },
    {
        "georeferenceId": "17704",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "21.8362252897998",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.6,
        "longitude": -3.55,
        "region": null
    },
    {
        "georeferenceId": "17676",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "32.147097432004",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 58.45,
        "longitude": -3.083,
        "region": null
    },
    {
        "georeferenceId": "17726",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "18.4865589910959",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.3,
        "longitude": -1.533,
        "region": null
    },
    {
        "georeferenceId": "17763",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "17.6985210232107",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.733,
        "longitude": -0.65,
        "region": null
    },
    {
        "georeferenceId": "17839",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "37.1785638640554",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.833,
        "longitude": -0.283,
        "region": null
    },
    {
        "georeferenceId": "17735",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "29.4895700131247",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.1,
        "longitude": -3.933,
        "region": null
    },
    {
        "georeferenceId": "17901",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "19.8355558424255",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.467,
        "longitude": -2.567,
        "region": null
    },
    {
        "georeferenceId": "17806",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "26.8751929688491",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.2,
        "longitude": -1.8,
        "region": null
    },
    {
        "georeferenceId": "17877",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "25.5117930324467",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.383,
        "longitude": -2.717,
        "region": null
    },
    {
        "georeferenceId": "17703",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "30.10758495426",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.317,
        "longitude": -3.2,
        "region": null
    },
    {
        "georeferenceId": "17923",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "38.1765804362769",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.067,
        "longitude": -1.317,
        "region": null
    },
    {
        "georeferenceId": "17729",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "15.2610955655053",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.05,
        "longitude": -1.25,
        "region": null
    },
    {
        "georeferenceId": "17660",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "0",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "3",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.817,
        "longitude": -4.967,
        "region": null
    },
    {
        "georeferenceId": "17849",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "26.6226685127182",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.517,
        "longitude": -6.75,
        "region": null
    },
    {
        "georeferenceId": "17794",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "20.0632165384618",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.883,
        "longitude": 0.233,
        "region": null
    },
    {
        "georeferenceId": "17748",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "17.2722775566062",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.4,
        "longitude": -0.767,
        "region": null
    },
    {
        "georeferenceId": "17903",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "22.4489602139131",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.067,
        "longitude": -2.833,
        "region": null
    },
    {
        "georeferenceId": "17675",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "28.1806978887994",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 58.617,
        "longitude": -3.55,
        "region": null
    },
    {
        "georeferenceId": "17702",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "26.3395191173735",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.95,
        "longitude": -3.35,
        "region": null
    },
    {
        "georeferenceId": "4482342",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "17.2733063861013",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.85426,
        "longitude": -1.50702,
        "region": null
    },
    {
        "georeferenceId": "17754",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "25.0994368416424",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.083,
        "longitude": 0.267,
        "region": null
    },
    {
        "georeferenceId": "17764",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "19.0233757179647",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.617,
        "longitude": -0.467,
        "region": null
    },
    {
        "georeferenceId": "17776",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "28.6773159696579",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.117,
        "longitude": -2.3,
        "region": null
    },
    {
        "georeferenceId": "17765",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "24.3702978878113",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.867,
        "longitude": 0.15,
        "region": null
    },
    {
        "georeferenceId": "17813",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "25.427055175254",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.15,
        "longitude": -0.183,
        "region": null
    },
    {
        "georeferenceId": "17927",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "30.216277738751",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.183,
        "longitude": -6.333,
        "region": null
    },
    {
        "georeferenceId": "17904",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "23.8970501553587",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.567,
        "longitude": -2.417,
        "region": null
    },
    {
        "georeferenceId": "17701",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "21.7936534657438",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.617,
        "longitude": -2.8,
        "region": null
    },
    {
        "georeferenceId": "17780",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "23.3707789618658",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.367,
        "longitude": -1.333,
        "region": null
    },
    {
        "georeferenceId": "17924",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "33.7405884102006",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.867,
        "longitude": 0.6,
        "region": null
    },
    {
        "georeferenceId": "17848",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "29.7216510244825",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.717,
        "longitude": -6.817,
        "region": null
    },
    {
        "georeferenceId": "17700",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "31.8390130281695",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.433,
        "longitude": -3.367,
        "region": null
    },
    {
        "georeferenceId": "17651",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "27.8674913618857",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.467,
        "longitude": -7.367,
        "region": null
    },
    {
        "georeferenceId": "17663",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "20.8555923209395",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.867,
        "longitude": -4.7,
        "region": null
    },
    {
        "georeferenceId": "17838",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "30.2117233480304",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.8,
        "longitude": -1.217,
        "region": null
    },
    {
        "georeferenceId": "17674",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "0",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "2",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.883,
        "longitude": -3.417,
        "region": null
    },
    {
        "georeferenceId": "17906",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "19.1884401585552",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.767,
        "longitude": -2.717,
        "region": null
    },
    {
        "georeferenceId": "17872",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "21.1246525525375",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.033,
        "longitude": -1.7,
        "region": null
    },
    {
        "georeferenceId": "17786",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "33.6033687198467",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.717,
        "longitude": -4.367,
        "region": null
    },
    {
        "georeferenceId": "17640",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "31.6746538512594",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 60.75,
        "longitude": -0.85,
        "region": null
    },
    {
        "georeferenceId": "17699",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "29.1597914845811",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.667,
        "longitude": -3.783,
        "region": null
    },
    {
        "georeferenceId": "17642",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "31.3834731574729",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 60.133,
        "longitude": -1.183,
        "region": null
    },
    {
        "georeferenceId": "17734",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "30.531976689159",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.25,
        "longitude": -4.533,
        "region": null
    },
    {
        "georeferenceId": "17766",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "18.058989650855",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.65,
        "longitude": 0.567,
        "region": null
    },
    {
        "georeferenceId": "17805",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "26.9705747087845",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.5,
        "longitude": -1.983,
        "region": null
    },
    {
        "georeferenceId": "17825",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "49.3677337039267",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.15,
        "longitude": -5.067,
        "region": null
    },
    {
        "georeferenceId": "17907",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "16.2430775815431",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.05,
        "longitude": -3,
        "region": null
    },
    {
        "georeferenceId": "17856",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "26.4705735895092",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.6,
        "longitude": -5.883,
        "region": null
    },
    {
        "georeferenceId": "17847",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "27.3451691517862",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.067,
        "longitude": -7.017,
        "region": null
    },
    {
        "georeferenceId": "17698",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "29.8200012727894",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.05,
        "longitude": -3.65,
        "region": null
    },
    {
        "georeferenceId": "17925",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "28.8090526602058",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.817,
        "longitude": -7.467,
        "region": null
    },
    {
        "georeferenceId": "17932",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "17.91913181676",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.75,
        "longitude": -2.483,
        "region": null
    },
    {
        "georeferenceId": "17744",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "15.9423470868661",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.65,
        "longitude": -1.783,
        "region": null
    },
    {
        "georeferenceId": "17728",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "18.046562902132",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.2,
        "longitude": -1.383,
        "region": null
    },
    {
        "georeferenceId": "17783",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "20.0401319324443",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.25,
        "longitude": 0.7,
        "region": null
    },
    {
        "georeferenceId": "17749",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "17.7681962865102",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.167,
        "longitude": -0.517,
        "region": null
    },
    {
        "georeferenceId": "17908",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "17.0545801664944",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53,
        "longitude": -2.183,
        "region": null
    },
    {
        "georeferenceId": "17767",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "23.3342647647864",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.95,
        "longitude": 1.133,
        "region": null
    },
    {
        "georeferenceId": "17697",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "33.6488994035196",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.8,
        "longitude": -4,
        "region": null
    },
    {
        "georeferenceId": "17864",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "5.27961528677983",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52,
        "longitude": -0.733,
        "region": null
    },
    {
        "georeferenceId": "17824",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "37.4975068401694",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.083,
        "longitude": -5.25,
        "region": null
    },
    {
        "georeferenceId": "17845",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "29.7502335765878",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.6,
        "longitude": -7.3,
        "region": null
    },
    {
        "georeferenceId": "17743",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "20.3476194312147",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.817,
        "longitude": -1.867,
        "region": null
    },
    {
        "georeferenceId": "17733",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "20.794651728737",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.1,
        "longitude": -0.167,
        "region": null
    },
    {
        "georeferenceId": "17816",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "26.1124525229724",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.3,
        "longitude": -0.083,
        "region": null
    },
    {
        "georeferenceId": "17836",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "37.8141526031359",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.583,
        "longitude": -1.3,
        "region": null
    },
    {
        "georeferenceId": "17909",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "21.447147932029",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.367,
        "longitude": -1.5,
        "region": null
    },
    {
        "georeferenceId": "17696",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "0",
        "dollarSpotSmithModelRisk": "20.2738137788181",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "2",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.417,
        "longitude": -4.317,
        "region": null
    },
    {
        "georeferenceId": "17673",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "20.6004464099347",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.35,
        "longitude": -3.35,
        "region": null
    },
    {
        "georeferenceId": "17791",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "26.5341517503907",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.617,
        "longitude": -1.083,
        "region": null
    },
    {
        "georeferenceId": "17860",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "25.1635602988684",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.567,
        "longitude": -1.167,
        "region": null
    },
    {
        "georeferenceId": "17694",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "27.8477157740017",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.317,
        "longitude": -3.733,
        "region": null
    },
    {
        "georeferenceId": "17865",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "32.4488518697978",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.833,
        "longitude": -4.533,
        "region": null
    },
    {
        "georeferenceId": "17768",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "25.3983957327416",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.633,
        "longitude": 1.3,
        "region": null
    },
    {
        "georeferenceId": "17832",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "27.8525382934987",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.95,
        "longitude": -2.633,
        "region": null
    },
    {
        "georeferenceId": "17778",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "20.2203328417586",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.45,
        "longitude": -1.733,
        "region": null
    },
    {
        "georeferenceId": "17910",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "24.8525466479349",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.933,
        "longitude": 1.283,
        "region": null
    },
    {
        "georeferenceId": "17657",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "29.6900782279197",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.033,
        "longitude": -7.45,
        "region": null
    },
    {
        "georeferenceId": "17802",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "29.3545115371101",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.4,
        "longitude": -3.433,
        "region": null
    },
    {
        "georeferenceId": "17745",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "22.0648366903984",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.8,
        "longitude": -1.55,
        "region": null
    },
    {
        "georeferenceId": "17693",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "24.0808507207169",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.867,
        "longitude": -4.433,
        "region": null
    },
    {
        "georeferenceId": "17742",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "18.2705489263273",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.967,
        "longitude": -2.033,
        "region": null
    },
    {
        "georeferenceId": "4482345",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "17.7892460488098",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.03516,
        "longitude": 0.09752,
        "region": null
    },
    {
        "georeferenceId": "17844",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "23.8268489534946",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.4,
        "longitude": -7.65,
        "region": null
    },
    {
        "georeferenceId": "17873",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "23.9730791607382",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.533,
        "longitude": -1.567,
        "region": null
    },
    {
        "georeferenceId": "17656",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "22.8242803199997",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.867,
        "longitude": -5.633,
        "region": null
    },
    {
        "georeferenceId": "17911",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "29.3545115371101",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.083,
        "longitude": -4.15,
        "region": null
    },
    {
        "georeferenceId": "17863",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "25.5478399716744",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.383,
        "longitude": -2.35,
        "region": null
    },
    {
        "georeferenceId": "17668",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "26.0217962839037",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.817,
        "longitude": -3.967,
        "region": null
    },
    {
        "georeferenceId": "17691",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "25.3120915964539",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.95,
        "longitude": -4.767,
        "region": null
    },
    {
        "georeferenceId": "17669",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "15.5541896211989",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 57.2,
        "longitude": -3.833,
        "region": null
    },
    {
        "georeferenceId": "17866",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "28.1933288567923",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.133,
        "longitude": -3.017,
        "region": null
    },
    {
        "georeferenceId": "17777",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "24.380030869691",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.15,
        "longitude": -2.033,
        "region": null
    },
    {
        "georeferenceId": "17723",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "12.9999407492082",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.817,
        "longitude": -2.083,
        "region": null
    },
    {
        "georeferenceId": "17741",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "13.9248228895824",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.5,
        "longitude": -2.217,
        "region": null
    },
    {
        "georeferenceId": "17732",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "17.0471123813354",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.483,
        "longitude": -0.6,
        "region": null
    },
    {
        "georeferenceId": "17912",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "23.9621452721692",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.35,
        "longitude": -2.983,
        "region": null
    },
    {
        "georeferenceId": "17769",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "20.2338868714525",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.8,
        "longitude": 1.25,
        "region": null
    },
    {
        "georeferenceId": "17690",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "27.4220046704881",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.467,
        "longitude": -4.617,
        "region": null
    },
    {
        "georeferenceId": "17878",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "24.2030389212186",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.333,
        "longitude": -2.85,
        "region": null
    },
    {
        "georeferenceId": "17881",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "17.2637058629534",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.5,
        "longitude": 0.05,
        "region": null
    },
    {
        "georeferenceId": "17750",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "18.9336990072165",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.033,
        "longitude": -0.5,
        "region": null
    },
    {
        "georeferenceId": "17837",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "32.8605268242628",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.817,
        "longitude": -0.917,
        "region": null
    },
    {
        "georeferenceId": "17770",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "23.532776571308",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.683,
        "longitude": 1.683,
        "region": null
    },
    {
        "georeferenceId": "17817",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "17.7881930967526",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.433,
        "longitude": 0.367,
        "region": null
    },
    {
        "georeferenceId": "17880",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "45.356320880857",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.417,
        "longitude": -4.117,
        "region": null
    },
    {
        "georeferenceId": "17913",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "27.9500628878062",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.483,
        "longitude": -0.6,
        "region": null
    },
    {
        "georeferenceId": "17689",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "34.8791396643334",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.85,
        "longitude": -4.95,
        "region": null
    },
    {
        "georeferenceId": "17867",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "18.5517458449846",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.2,
        "longitude": -1.7,
        "region": null
    },
    {
        "georeferenceId": "17753",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "18.2097035079443",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.083,
        "longitude": -0.167,
        "region": null
    },
    {
        "georeferenceId": "17843",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "26.3688651566881",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.433,
        "longitude": -8.05,
        "region": null
    },
    {
        "georeferenceId": "17688",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "29.7803375858348",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.617,
        "longitude": -4.85,
        "region": null
    },
    {
        "georeferenceId": "17740",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "19.91773305324",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.2,
        "longitude": -2.9,
        "region": null
    },
    {
        "georeferenceId": "17659",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "24.5170131552689",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.767,
        "longitude": -6.05,
        "region": null
    },
    {
        "georeferenceId": "17801",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "25.731327674185",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.5,
        "longitude": -3.2,
        "region": null
    },
    {
        "georeferenceId": "17874",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "19.0911242895572",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.833,
        "longitude": -1.333,
        "region": null
    },
    {
        "georeferenceId": "17914",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "18.239032788168",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.2,
        "longitude": 0.183,
        "region": null
    },
    {
        "georeferenceId": "17821",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "29.6224875352119",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.35,
        "longitude": 1.417,
        "region": null
    },
    {
        "georeferenceId": "17746",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "18.8384747515706",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53,
        "longitude": -1.25,
        "region": null
    },
    {
        "georeferenceId": "17727",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "16.0135531937073",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.133,
        "longitude": -1.417,
        "region": null
    },
    {
        "georeferenceId": "17687",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "24.0834834236632",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 55.7,
        "longitude": -5.3,
        "region": null
    },
    {
        "georeferenceId": "17862",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "21.5764131591833",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.9,
        "longitude": -2.083,
        "region": null
    },
    {
        "georeferenceId": "17649",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "31.3199390155418",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 58.95,
        "longitude": -2.9,
        "region": null
    },
    {
        "georeferenceId": "17771",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "30.8720476827356",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.133,
        "longitude": -4.567,
        "region": null
    },
    {
        "georeferenceId": "17827",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "35.0652570123539",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 50.467,
        "longitude": -4.717,
        "region": null
    },
    {
        "georeferenceId": "17926",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "27.9544129035617",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 54.717,
        "longitude": -6.517,
        "region": null
    },
    {
        "georeferenceId": "17915",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "23.2031424868514",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.267,
        "longitude": 0.517,
        "region": null
    },
    {
        "georeferenceId": "17784",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "20.5561225085875",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "1",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 52.117,
        "longitude": 0.967,
        "region": null
    },
    {
        "georeferenceId": "17842",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "1",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "31.9808499349979",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "0",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 49.217,
        "longitude": -2.2,
        "region": null
    },
    {
        "georeferenceId": "17686",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "26.0042437063308",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 56.233,
        "longitude": -5.083,
        "region": null
    },
    {
        "georeferenceId": "17859",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "1",
        "dollarSpotSmithModelRisk": "20.5906341435943",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 53.867,
        "longitude": -1.65,
        "region": null
    },
    {
        "georeferenceId": "17790",
        "countryCode": "GB",
        "datetimeUtc": "2023-07-14",
        "anthracnoseFoliarBlight": "0",
        "brownPatchRiskIrrigated": "0",
        "dollarSpotRiskIrrigated": "2",
        "dollarSpotSmithModelRisk": "21.5200182393973",
        "poaAnnuaGerminationRisk": "0",
        "pythiumBlightRiskIrrigated": "0",
        "fusariumPatchRisk": "1",
        "poaAnnuaSeedheadsRisk": "0",
        "takeAllPatchRiskNonIrrigated": "0",
        "takeAllPatchRiskIrrigated": "0",
        "grassGrowingPotentialIndex": "0",
        "cornGrayLeafSpot": null,
        "cornGrayLeafSpot_US": null,
        "latitude": 51.75,
        "longitude": -1.583,
        "region": null
    }
];