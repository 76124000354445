import React, { useState } from "react";
import {
    menuUnselectedColor,
    menuSelectedColor,
    CharacterizationSvgComponent,
    MyQueriesSvgComponent,
} from "@/resource/IconComponents";
import { Link } from "react-router-dom";
import "./index.less";

export default function SideBarComponent() {
    //sidler
    const simulationRoot: string = process.env.SIMULATION_ROOT || "simulation";
    const isDebug: boolean = JSON.parse(process.env.IsDebug || "false");
  enum IType {
    Characterization = "CreateQuery", //CreateQuery//Characterization
    MyQueries = "MyQueries", //MyQueries
  }
  const [show, setShow] = useState<boolean>(false);
  const [selectedMenu, setSelectedMenu] = useState<string>(IType.Characterization);
  const [hoveredMenu, setHoveredMenu] = useState<string | undefined>(undefined);
  const selectMenu = (menuName: string) => {
      setSelectedMenu(menuName);
  };
  const mouseOverMenuItem = (menuName: string) => {
      setHoveredMenu(menuName);
  };

  const mouseOutMenuItem = () => {
      setHoveredMenu(undefined);
  };
  return (
      <div className="sidebar">
          <div className="menu-div-hard-width">
              <div className={`menuDiv  ${show ? "show" : "hide"}`}
                  onMouseEnter={() => { setShow(true); }} onMouseLeave={() => { setShow(false); }}>
                  <ul className="nav">
                      <li className="liclass" onClick={() =>{
                          console.log("9999CreateQueryonClick");
                          console.log("9999CreateQueryonClick:::",IType.Characterization);
                          setSelectedMenu(IType.Characterization);
                      }}
                      onMouseOver={() => mouseOverMenuItem(IType.Characterization)}
                      onMouseOut={() => mouseOutMenuItem()}>
                          <Link to={`/${simulationRoot}/environmental-characterization`}>
                              <div className={`hover-bottom-div
                            ${selectedMenu !== IType.Characterization && "hover-bottom-hover-div"}
                            ${selectedMenu === IType.Characterization && show ? "selected-background-color" : "transparent"}`} >
                                  <div className={`menu-item-back ${selectedMenu === IType.Characterization && !show ? "menu-item-border-radius" : undefined}`}>
                                      <CharacterizationSvgComponent style={{ marginTop: "-8px", marginLeft: "-8px" }} className="query-menu-icon"
                                          color={selectedMenu === IType.Characterization ? menuSelectedColor : menuUnselectedColor}
                                      />
                                  </div>
                                  <span className={`query-span ${selectedMenu === IType.Characterization && "selected-span"}
                                  ${hoveredMenu === IType.Characterization && selectedMenu !== IType.Characterization && "hover-span"}`}>Create query</span>
                              </div>
                          </Link>
                      </li>
                      <li className="liclass" onClick={() =>{
                          console.log("9999MyQuerysonClick");
                          setSelectedMenu(IType.MyQueries);
                      }
                      }
                      onMouseOver={() => mouseOverMenuItem(IType.MyQueries)}
                      onMouseOut={() => mouseOutMenuItem()}>
                          <Link to={`/${simulationRoot}/my-queries`}>
                              <div className={`hover-bottom-div
                            ${selectedMenu !== IType.MyQueries && "hover-bottom-hover-div"}
                            ${selectedMenu === IType.MyQueries && show ? "selected-background-color" : "transparent"}`} >
                                  <div className={`menu-item-back ${selectedMenu === IType.MyQueries && !show ? "menu-item-border-radius" : undefined}`}>
                                      <MyQueriesSvgComponent style={{ marginTop: "-8px", marginLeft: "-8px" }} className="query-menu-icon"
                                          color={selectedMenu === IType.MyQueries ? menuSelectedColor : menuUnselectedColor}
                                      />
                                  </div>
                                  <span className={`query-span ${selectedMenu === IType.MyQueries && "selected-span"}
                                  ${hoveredMenu === IType.MyQueries && selectedMenu !== IType.MyQueries && "hover-span"}`}>My Queries</span>
                              </div>
                          </Link>
                      </li>
                  </ul>
              </div>
          </div>
      </div>
  );
}
