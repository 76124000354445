import "./index.less";
import React, { useState, useRef, useCallback, useEffect, MutableRefObject } from "react";
import moment from "moment";
import echarts from "echarts/lib/echarts";
import ReactEcharts from "echarts-for-react";
import { DownloadIcon } from "@/resource/IconComponents";
import IF from "@/components/IF";

enum ChartTypeEnum {
  Line = "Line",
  Bar = "Bar",
  Point = "Point"
}
enum ChartIconColorEnum {
  Selected = "#59B948",
  UnSelect = "#696F88"
}
enum LegendColorEnum {
  Brown = "#C17E19",
  Teal = "#0FC6C2"
}

interface IProps {
  chartData: any,
  fieldKey: string,
  waters: string[]
}
export default function QueryChartComponent(props: IProps = {
    chartData: {},
    fieldKey: "",
    waters: []
}) {
    const { chartData, fieldKey, waters } = props;
    const [chartOption, setChartOption] = useState<any>(null);
    const echartRef: MutableRefObject<any> = useRef(null);

    //const waterColors: any[] = [{ water: 'Irrigated', color: '#00C78C' }, { water: 'Rainfed', color: '#A0522D' }];
    const waterColors: any[] = [{ water: "Irrigated", color: "#1000ff" }, { water: "Rainfed", color: "#00a748" }];
    const metadatas: any[] = [
        { fieldKey: "yield", code: "tn/ha", name: "Yield (tn/ha)" },
        { fieldKey: "dapR1", code: "days", name: "Days from planting" },
        { fieldKey: "dapR6", code: "days", name: "Days from planting" }
    ];
    // k line chart
    const splitData = (_rawData: any, _waters: string[]) => {
        const { plantDayOfYear, data } = _rawData;
        const categoryData = plantDayOfYear.map(x => {
            const firstDayOfYear = new Date(`${new Date().getFullYear()}-01-01 00:00:00`);
            return new Date(firstDayOfYear.setDate(firstDayOfYear.getDate() + x - 1));
        });

        const seriesData = data.map(x => {
            const values = categoryData.map((cd, index) => {
                return [
                    cd,
                    x.percentiles["p25"][fieldKey][index],
                    x.percentiles["p50"][fieldKey][index],
                    x.percentiles["p75"][fieldKey][index]
                ];
            });
            return {
                name: x.water,
                type: "custom",
                color: waterColors.filter(wc => wc.water == x.water)[0].color,
                renderItem: renderItem,
                dimensions: ["-", "p25", "p50", "p75"],
                encode: {
                    x: 0,
                    y: [1, 2, 3],
                    tooltip: [1, 2, 3]
                },
                data: values
            };
        });
        return { categoryData, seriesData };
    };

    const renderItem = (params, api) => {
        const seriesIndex = params.seriesIndex;
        const distance = 8;
        const xValue = api.value(0);
        const p25Value = api.coord([xValue, api.value(1)]);
        const p50Value = api.coord([xValue, api.value(2)]);
        const p75Value = api.coord([xValue, api.value(3)]);
        const halfWidth = 3;
        const dotRadius = 5;
        const style = api.style({
            stroke: api.visual("color")
        });
        return {
            type: "group",
            children: [
                {
                    type: "line",
                    shape: {
                        x1: p25Value[0] + seriesIndex * distance,
                        y1: p25Value[1],
                        x2: p75Value[0] + seriesIndex * distance,
                        y2: p75Value[1]
                    },
                    style: style
                },
                {
                    type: "line",
                    shape: {
                        x1: p25Value[0] - halfWidth + seriesIndex * distance,
                        y1: p25Value[1],
                        x2: p25Value[0] + halfWidth + seriesIndex * distance,
                        y2: p25Value[1]
                    },
                    style: style
                },
                {
                    type: "line",
                    shape: {
                        x1: p75Value[0] - halfWidth + seriesIndex * distance,
                        y1: p75Value[1],
                        x2: p75Value[0] + halfWidth + seriesIndex * distance,
                        y2: p75Value[1]
                    },
                    style: style
                }, {
                    type: "circle",
                    shape: {
                        cx: p50Value[0] + seriesIndex * distance,
                        cy: p50Value[1],
                        r: dotRadius
                    },
                    style: style
                }
            ]
        };
    };


    const setToolTip = (params, code) => {
        let result = `<div><label style='font-size:14px;color:#a3a9b9;margin-left:5px;'>Planting date</label><label style="font-size:14px;color:#fff;padding-left:5px;">${moment(params[0].axisValueLabel).format("MMM DD")}</label></div><table style="width:100%;"><tr style="font-size:12px;color:#fff;"><td>&nbsp;</td><td>P25</td><td>P50</td><td>P75</td></tr>`;
        params.forEach(function (item) {
            result += `<tr style="color:#fff;font-size:12px;"><td><i style="display:inline-block;margin-right:5px;border-radius:12px;width:10px;height:10px;background-color:${item.color};"></i></td><td>${item.value[1]}<label style='color:#a3a9b9;margin-left:5px;'>${code}</label></td><td>${item.value[2]}<label style='color:#a3a9b9;margin-left:5px;'>${code}</label></td><td>${item.value[3]}<label style='color:#a3a9b9;margin-left:5px;'>${code}</label></td></tr>`;
        });
        result += "</table>";
        return result;
    };

    useEffect(() => {
        setChartOption(null);
        if (waters == null || waters.length == 0) {
            return;
        }
        const kdata = splitData(chartData, waters);
        const xAxis = [
            {
                name: "Planting date",
                nameLocation: "center",
                nameTextStyle: { padding: [10, 0, 0, 0] },
                type: "time",
                data: kdata.categoryData,
                boundaryGap: false,
                axisLine: { onZero: false },
                axisLabel: {
                    formatter: "{MMM} {dd}",
                    showMinLabel: false,
                    hideOverlap: true
                },
                axisTick: { interval: 3 },
                splitLine: { show: false },
                min: "dataMin",
                max: "dataMax",
                axisPointer: {
                    z: 100
                }
            }
        ];
        const yAxis: Array<any> = [];
        if (fieldKey === "yield") {
            yAxis.push({
                name: metadatas.filter(x => x.fieldKey == fieldKey)[0].name,
                scale: false,
                splitArea: {
                    show: true
                },
                min: 0,
                max: function (value) {
                    return Math.ceil(value.max + 1);
                }
            });
        } else {
            yAxis.push({
                name: metadatas.filter(x => x.fieldKey == fieldKey)[0].name,
                scale: true,
                splitArea: {
                    show: true
                },
                boundaryGap: ["10%", "10%"],
                min: function (value) {
                    return Math.floor(value.min - 10);
                },
                max: function (value) {
                    return Math.ceil(value.max + 10);
                }
            });
        }
        const kchartOption = {
            animation: false,
            legend: {
                top: 10,
                right: 30
            },
            tooltip: {
                trigger: "axis",
                axisPointer: {
                    type: "cross",
                    label: {
                        show: false,
                        backgroundColor: "#232630"
                    }
                },
                backgroundColor: "#232630",
                color: "#fff",
                formatter: (params) => {
                    return setToolTip(params, metadatas.filter(x => x.fieldKey == fieldKey)[0].code);
                }
            },
            axisPointer: {
                link: [{ xAxisIndex: "all" }]
            },
            toolbox: {
                show: false,
                feature: {
                    dataZoom: {
                        yAxisIndex: true,
                        xAxisIndex: true,
                    },
                    brush: {
                        type: ["lineX", "clear"]
                    }
                }
            },
            grid: [
                {
                    left: "10%",
                    right: "8%",
                    bottom: 70
                }
            ],
            xAxis: xAxis,
            yAxis: yAxis,
            dataZoom: [
                {
                    type: "inside",
                    start: 0,
                    end: 100,
                    minSpan: 20
                },
                {
                    show: true,
                    type: "slider",
                    bottom: 20,
                    height: 10,
                    start: 0,
                    end: 100,
                    labelFormatter: function (value) {
                        return moment(value).format("MMM DD");
                    },
                    minValueSpan: 100
                }
            ],
            series: kdata.seriesData
        };
        setChartOption(kchartOption);
    }, [chartData, waters]);

    const downloadImg = useCallback(() => {
        const echartInstance = echartRef.current.getEchartsInstance();
        const base64String = echartInstance.getDataURL({ pixelRatio: 1, backgroundColor: "#FFFFFF", excludeComponents: ["toolbox", "dataZoom"], type: "png" });
        const parts = base64String.split(";base64,");
        const contentType = parts[0].split(":")[1];
        const raw = window.atob(parts[1]);
        const rawLength = raw.length;
        const uInt8Array = new Uint8Array(rawLength);
        for (let i = 0; i < rawLength; ++i) {
            uInt8Array[i] = raw.charCodeAt(i);
        }
        const blob = new Blob([uInt8Array], { type: contentType });
        const objectUrl = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.setAttribute("style", "display:none");
        a.setAttribute("href", objectUrl);
        a.setAttribute("download", "chart.png");
        a.click();
        URL.revokeObjectURL(objectUrl);
    }, [echartRef]);

    return (
        <div className="div-chart-details">
            <div className="div-chart">
                <div className="div-chart-head">
                    <div>Cultivar: <strong className="div-count">{chartData.cultivar}</strong></div>
                    <div>Soil: <strong className="div-count">{chartData.soilTexture}</strong></div>
                    <div>Rooting: <strong className="div-count">{chartData.soilDepth}cm</strong></div>
                    <IF show={chartData.season}><div>Season: <strong className="div-count">{chartData.season}</strong></div></IF>
                    <div className="div-chart-icons">
                        <div className="div-download-icon" onClick={() => { downloadImg(); }}><DownloadIcon /></div>
                    </div>
                </div>
                <div className="div-chart-canvas">
                    {chartOption ? <ReactEcharts ref={echartRef} option={chartOption} style={{ width: "auto", height: "360px" }} /> : undefined}
                </div>
            </div>
        </div>
    );
}
