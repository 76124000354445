import React, { useCallback, useEffect, useState } from "react";
import { Card, Col, Row, Input, Space, Divider, Button, Select, InputNumber, theme } from "antd";
import { ResetValueIcon } from "@/resource/IconComponents";
import "./index.less";
import { useParams } from "react-router-dom";

enum themeEnum {
    LightTheme = "light-theme",
    DarkTheme = "dark-theme"
}

const DosatronCalculator = () => {
    const routerParams = useParams();

    // add google analysis
    useEffect(() => {
        console.log("just invoke once");
        const head = document.getElementsByTagName("head")[0];
        const googleAnalyticsCode = "G-JJJXKGP8MV";
        const googleAnalyticsFirstScript = document.createElement("script");
        googleAnalyticsFirstScript.async = true;
        googleAnalyticsFirstScript.src =
          "https://www.googletagmanager.com/gtag/js?id=" + googleAnalyticsCode;

        const googleAnalyticsSecondScript = document.createElement("script");
        googleAnalyticsSecondScript.innerHTML =
          "    window.dataLayer = window.dataLayer || [];\n" +
          "    function gtag(){dataLayer.push(arguments);}\n" +
          "    gtag('js', new Date());\n" +
          "\n" +
          "    gtag('config', '" +
          googleAnalyticsCode +
          "',{\n" +
          "cookie_flags:'max-age=7200;secure;samesite-none'" +
          "});";

        head.insertBefore(googleAnalyticsSecondScript, head.firstChild);
        head.insertBefore(googleAnalyticsFirstScript, head.firstChild);
    }, []);

    const validLanguages = ["fr"];
    const allDetails: any = {
        fr: {
            title: "Dosatron Calculator",
            typeOfPots: "Type de pots",
            pottery1: "Poterie 1",
            pottery2: "Poterie 2 :(nécessité de goutteurs différents)",
            volume: "Volume",
            pottery1VolumeUnit: "L",
            pottery2VolumeUnit: "L",
            indicatedDiameter: "Diamètre indicatif",
            indicatedDiameter1Unit: "cm",
            indicatedDiameter2Unit: "cm",
            numberOfPots: "Nombre de pots",
            numberOfPots1Unit: "pots",
            numberOfPots2Unit: "pots",
            dosatron: "Réglage du Dosatron",
            dosatronPercentage: "%",
            productUsed: "Produit utilisé",
            productUsedList: [{
                value: "none",
                label: "Choose"
            }, {
                value: "Flagship Pro",
                label: "Flagship Pro"
            }],
            target: "Cible",
            targetList: [{
                value: "none",
                label: "Choose"
            }, {
                value: "pucerons",
                label: "Pucerons"
            }, {
                value: "autres",
                label: "Autres"
            }],
            totalVolume: "Le volume total de la bouillie mère est de",
            totalVolumeUnit: "L",
            from: "Composée de",
            product: "Flagship Pro",
            productUnit: "L",
            water: "+ eau",
            waterUnit: "L",
        }
    };

    const [language, setLanguage] = useState<string>("fr");
    const [currentTheme, setCurrentTheme] = useState<string>(themeEnum.LightTheme.toString());
    const [details, setDetails] = useState<any>(undefined);
    const defaultExpressions = {
        pottery1VolumeValue: undefined,
        pottery2VolumeValue: undefined,
        indicatedDiameter1Value: undefined,
        indicatedDiameter2Value: undefined,
        numberOfPots1Value: undefined,
        numberOfPots2Value: undefined,
        dosatronValue: undefined,
        productUsedValue: "none",
        targetValue: "none",
        totalVolumeValue: undefined,
        productValue: undefined,
        waterValue: undefined
    };
    const [expressions, setExpressions] = useState<{
        pottery1VolumeValue: undefined | number,
        pottery2VolumeValue: undefined | number,
        indicatedDiameter1Value: string | number,
        indicatedDiameter2Value: string | number,
        numberOfPots1Value: undefined | number,
        numberOfPots2Value: undefined | number,
        dosatronValue: undefined | number,
        productUsedValue: string,
        targetValue: string,
        totalVolumeValue: string,
        productValue: string,
        waterValue: string
    }>(defaultExpressions);
    const calcDiameterByPottery = (value: number) => Math.floor(Math.pow(value * 1000 / 0.8, (1 / 3)) * 10) / 10;
    const formatCalculateResult = (value: number) => {
        if (value == 0) return 0;
        if (Math.abs(value) > 1E6 || Math.abs(value) < 1E-6)
            return Number(value).toExponential(4);
        if (Math.abs(value) > 1)
            return Number(value).toFixed(2);
        if (Math.abs(value) < 1)
            return Math.round(value * 1E6) / 1E6;
        return value;
    };
    const handleValueChange = (value: number, key: string) => {
        const newExpressions = { ...expressions };
        switch (key) {
            case "pottery1VolumeValue":
                newExpressions.pottery1VolumeValue = value >= 0 ? value : undefined;
                newExpressions.indicatedDiameter1Value = value >= 0 ? formatCalculateResult(calcDiameterByPottery(value)) : undefined;
                break;
            case "pottery2VolumeValue":
                newExpressions.pottery2VolumeValue = value >= 0 ? value : undefined;
                newExpressions.indicatedDiameter2Value = value >= 0 ? formatCalculateResult(calcDiameterByPottery(value)) : undefined;
                break;
            default:
                newExpressions[key] = value;
        }
        setExpressions(newExpressions);
    };
    const handleChange = (value: string, key: string) => {
        const newExpressions = { ...expressions };
        switch (key) {
            case "productUsed":
                newExpressions.productUsedValue = value;
                break;
            case "target":
                newExpressions.targetValue = value;
                break;
        }
        setExpressions(newExpressions);
    };
    const handleReset = () => {
        setExpressions(defaultExpressions);
    };
    const handleCalculator = useCallback(() => {
        const newExpressions = { ...expressions };
        if (expressions.numberOfPots1Value == undefined) {
            newExpressions.totalVolumeValue = "NaN";
            newExpressions.productValue = "NaN";
            newExpressions.waterValue = "NaN";
        } else {
            const volumePottery1 = newExpressions.pottery1VolumeValue == undefined ? 0 : newExpressions.pottery1VolumeValue;
            const volumePottery2 = newExpressions.pottery2VolumeValue == undefined ? 0 : newExpressions.pottery2VolumeValue;
            const dosatron = newExpressions.dosatronValue == undefined ? 0 : newExpressions.dosatronValue / 100.0;
            const pottery1NumberOfPots = parseInt(`${newExpressions.numberOfPots1Value}`);
            const pottery2NumberOfPots = newExpressions.numberOfPots2Value == undefined ? 0 : parseInt(`${newExpressions.numberOfPots2Value}`);
            const divisorValue = newExpressions.targetValue == "autres" ? 2000 : 4000;
            const totalVolume = ((pottery1NumberOfPots * volumePottery1) + (pottery2NumberOfPots * volumePottery2)) * dosatron / 20;
            newExpressions.totalVolumeValue = totalVolume.toFixed(3);
            const product = ((pottery1NumberOfPots * volumePottery1) + (pottery2NumberOfPots * volumePottery2)) / divisorValue;
            newExpressions.productValue = product.toFixed(3);
            newExpressions.waterValue = (totalVolume - product).toFixed(3);
        }
        setExpressions(newExpressions);
    }, [expressions]);

    useEffect(() => {
        const { param1, param2 } = routerParams;
        let _language = "fr";
        let _theme = themeEnum.LightTheme.toString();
        if (param1) {
            if (validLanguages.indexOf(param1) > -1) _language = param1;
            else if (param1.match(/dark/ig)) _theme = themeEnum.DarkTheme.toString();
        }
        if (param2) {
            if (validLanguages.indexOf(param2) > -1) _language = param2;
            else if (param2.match(/dark/ig)) _theme = themeEnum.DarkTheme.toString();
        }
        setLanguage(_language);
        setCurrentTheme(_theme);
    }, []);
    useEffect(() => {
        setDetails(allDetails[language]);
    }, [language]);

    return (
        <div className={`${currentTheme}`}>
            {
                (details == undefined || details == null) ? null :
                    <div className="Dosatron-container">
                        <div className="pageTitleDiv">
                            <p className="pageTitle">
                                {details.title}
                            </p>
                        </div>
                        <div className="content">
                            <div className="rowStyle">
                                <Row>
                                    <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                                        <span className="spanStyle">{details.typeOfPots}</span>
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Row gutter={[16, 24]}>
                                            <Col className="gutter-row" xs={24} sm={12} md={12} lg={9} xl={10}>
                                                <div className="">
                                                    <span className="ml">{details.pottery1}</span>
                                                </div>
                                            </Col>
                                            <Col className="gutter-row" xs={24} sm={12} md={12} lg={13} xl={14}>
                                                <div className="">
                                                    <span >{details.pottery2}</span>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                            <div className="rowStyle">
                                <Row>
                                    <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                                        <span className="spanStyle2">{details.volume}</span>
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Row gutter={[16, 24]}>
                                            <Col className="gutter-row" xs={24} sm={12} md={12} lg={9} xl={10}>
                                                <Space.Compact size="large">
                                                    <InputNumber type="number" addonAfter={details.pottery1VolumeUnit} value={expressions.pottery1VolumeValue}
                                                        onChange={(value) => handleValueChange(value, "pottery1VolumeValue")} />
                                                </Space.Compact>
                                            </Col>
                                            <Col className="gutter-row" xs={24} sm={12} md={12} lg={9} xl={10}>
                                                <Space.Compact size="large">
                                                    <InputNumber type="number" addonAfter={details.pottery2VolumeUnit} value={expressions.pottery2VolumeValue}
                                                        onChange={(value) => handleValueChange(value, "pottery2VolumeValue")} />
                                                </Space.Compact>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                            <div className="rowStyle">
                                <Row>
                                    <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                                        <span className="spanStyle2">{details.indicatedDiameter}</span>
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Row gutter={[16, 24]}>
                                            <Col className="gutter-row" xs={24} sm={12} md={12} lg={9} xl={10}>
                                                <Space.Compact size="large">
                                                    <Input type="text" className="blackInput" addonAfter={details.indicatedDiameter1Unit} disabled value={expressions.indicatedDiameter1Value} />
                                                </Space.Compact>
                                            </Col>
                                            <Col className="gutter-row" xs={24} sm={12} md={12} lg={9} xl={10}>
                                                <Space.Compact size="large">
                                                    <Input type="text" className="blackInput" addonAfter={details.indicatedDiameter2Unit} disabled value={expressions.indicatedDiameter2Value} />
                                                </Space.Compact>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                            <div className="rowStyle">
                                <Row>
                                    <Col xs={12} sm={8} md={6} lg={6}>
                                        <span className="spanStyle">{details.numberOfPots}</span>
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Row gutter={[16, 24]}>
                                            <Col className="gutter-row" xs={24} sm={12} md={12} lg={9} xl={10}>
                                                <Space.Compact size="large">
                                                    <InputNumber type="number" required addonAfter={details.numberOfPots1Unit} value={expressions.numberOfPots1Value}
                                                        onChange={(value) => handleValueChange(value, "numberOfPots1Value")} />
                                                </Space.Compact>
                                            </Col>
                                            <Col className="gutter-row" xs={24} sm={12} md={12} lg={9} xl={10}>
                                                <Space.Compact size="large">
                                                    <InputNumber type="number" addonAfter={details.numberOfPots2Unit} value={expressions.numberOfPots2Value}
                                                        onChange={(value) => handleValueChange(value, "numberOfPots2Value")} />
                                                </Space.Compact>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                            <div className="rowStyle">
                                <Row>
                                    <Col xs={12} sm={8} md={6} lg={6}>
                                        <span className="spanStyle">{details.dosatron}</span>
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Row gutter={[16, 24]}>
                                            <Col className="gutter-row" xs={24} sm={12} md={12} lg={9} xl={10}>
                                                <Space.Compact size="large">
                                                    <InputNumber type="number" addonAfter={details.dosatronPercentage} value={expressions.dosatronValue}
                                                        onChange={(value) => handleValueChange(value, "dosatronValue")} />
                                                </Space.Compact>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                            <div className="rowStyle">
                                <Row>
                                    <Col xs={12} sm={8} md={6} lg={6}>
                                        <span className="spanStyle">{details.productUsed}</span>
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Row gutter={[16, 24]}>
                                            <Col className="gutter-row" xs={24} sm={12} md={12} lg={9} xl={10}>
                                                <Select
                                                    defaultValue={expressions.productUsedValue}
                                                    value={expressions.productUsedValue}
                                                    style={{ width: "100%"}}
                                                    onChange={(value) => handleChange(value, "productUsed")}
                                                    options={details.productUsedList}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                            <div className="rowStyle">
                                <Row>
                                    <Col xs={12} sm={8} md={6} lg={6}>
                                        <span className="spanStyle">{details.target}</span>
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Row gutter={[16, 24]}>
                                            <Col className="gutter-row" xs={24} sm={12} md={12} lg={9} xl={10}>
                                                <Select
                                                    defaultValue={expressions.targetValue}
                                                    value={expressions.targetValue}
                                                    style={{ width: "100%"}}
                                                    onChange={(value) => handleChange(value, "target")}
                                                    options={details.targetList}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                            <Divider></Divider>
                            <div className="rowStyle" style={{ marginTop: "-15px" }}>
                                <Row>
                                    <Col xs={12} sm={8} md={6} lg={6}>
                                        <div className="iconAndTitle" onClick={(e) => handleReset()}>
                                            <ResetValueIcon />
                                            <span className="iconTitle">Reset Values</span>
                                        </div>
                                    </Col>
                                    <Col xs={12} sm={12} md={6} lg={6}>
                                        <Button className="bottomButton" onClick={(e) => { handleCalculator(); }}><p>Calculate</p></Button>
                                    </Col>
                                </Row>
                            </div>
                            <div className="rowStyle">
                                <Row>
                                    <Col span={12}>
                                        <span className="spanStyle">{details.totalVolume}</span>
                                    </Col>
                                    <Col span={12}>
                                        <Row>
                                            <Col span={8}>
                                                <Space.Compact size="large">
                                                    <Input type="text" className="whiteInput" addonAfter={details.totalVolumeUnit} disabled value={expressions.totalVolumeValue} />
                                                </Space.Compact>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                            <div className="rowStyle">
                                <Row>
                                    <Col span={12}>
                                        <span className="spanStyle">{details.from} {details.product}</span>
                                    </Col>
                                    <Col span={12}>
                                        <Row>
                                            <Col span={8}>
                                                <Space.Compact size="large">
                                                    <Input type="text" className="whiteInput" addonAfter={details.productUnit} disabled value={expressions.productValue} />
                                                </Space.Compact>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                            <div className="rowStyle">
                                <Row>
                                    <Col span={12}>
                                        <span className="spanStyle">{details.water}</span>
                                    </Col>
                                    <Col span={12}>
                                        <Row>
                                            <Col span={6}>
                                                <Space.Compact size="large">
                                                    <Input type="text" className="whiteInput" addonAfter={details.waterUnit} disabled value={expressions.waterValue} />
                                                </Space.Compact>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
            }
        </div>
    );
};

export default DosatronCalculator;