import { Button, List, Modal } from "antd";
import React from "react";
import "./index.less";
import { IGetGeoNamesLocationDataReqModel } from "@/util/http/Quantis/responseType";

interface IProp{
    showLocationSearchModal: boolean
    geoLocationListData: IGetGeoNamesLocationDataReqModel[]
    changeShowLocationSearchModalCallback: ()=>void
    geoLicationDataItemClickCallback: (palcename:string,latitude:string,longitude:string) => void
}

const LocationSearchModal = (props:IProp)=>{
    const {
        showLocationSearchModal,
        geoLocationListData,
        changeShowLocationSearchModalCallback,
        geoLicationDataItemClickCallback
    } = props;

    const closeLocationSearchModal = ()=>{
        changeShowLocationSearchModalCallback();
    };

    const searchSignItem = (currnetSelectLocation: IGetGeoNamesLocationDataReqModel)=>{
        console.log(`currentSelectLocation: placeID is${currnetSelectLocation.placeId}, 
                lat: ${currnetSelectLocation?.latitude}, long: ${currnetSelectLocation.longitude} `);
        /**
         * when we slected a item,
         * we should change map center, center 's lat and long is current seleted value
         * and change searchCityName and showSearchCityName
         *
         * all should get weather data, crop, fieldName and forecast use already selected which in main page
         *      we just need change the lat and long
         */
        // TODO: it also should removeAllMarker, change CityCenter
        changeShowLocationSearchModalCallback();
        geoLicationDataItemClickCallback(
            currnetSelectLocation?.name,
            currnetSelectLocation?.latitude?.toString(),
            currnetSelectLocation?.longitude?.toString()
        );
    };
    return(
        <div className="location-search-modal-container" id="location-search-modal-container">
            <Modal
                title="Search Location"
                getContainer = {()=> document.getElementById("location-search-modal-container") as HTMLElement}
                open = {showLocationSearchModal}
                onCancel={closeLocationSearchModal}
                footer={[
                    <Button
                        key="submit"
                        type="primary"
                        className="modal-button"
                        //   loading={confirmLoading}
                        onClick={closeLocationSearchModal}
                    >
                      Cancel
                    </Button>,
                ]}
            >
                <List
                    size="small"
                    bordered
                    dataSource={geoLocationListData}
                    renderItem={(item) => (
                        <List.Item>
                            <a onClick={(e) => searchSignItem(item)}>{item?.adminName}</a>
                        </List.Item>
                    )}
                    // renderItem={(item: { adminName1: string; adminName: string }) => (
                    //     <List.Item>
                    //         <a onClick={(e) => searchSignItem(item)}>{item.adminName}</a>
                    //     </List.Item>
                    // )}
                />
            </Modal>
        </div>
    );
};

export default LocationSearchModal;