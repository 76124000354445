/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/display-name */

import React from "react";

/* eslint-disable max-len */
interface ISvgProps{
    width?: number;
    height?: number;
    color?: string;
}


export const Eamil_Svg = React.memo((props:ISvgProps)=>{
    const {width,height} = props;

    return(
        <svg width={width} height={height} viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.25385 11.4318C0.905896 11.4318 0.60993 11.3098 0.365958 11.0656C0.121986 10.8215 0 10.5253 0 10.177V1.81622C0 1.46791 0.121986 1.17282 0.365958 0.930928C0.60993 0.689053 0.905896 0.568115 1.25385 0.568115H13.5762C13.9242 0.568115 14.2202 0.690178 14.4641 0.934303C14.7081 1.17844 14.8301 1.47466 14.8301 1.82297V10.1837C14.8301 10.532 14.7081 10.8271 14.4641 11.069C14.2202 11.3109 13.9242 11.4318 13.5762 11.4318H1.25385ZM7.41504 6.57045L0.999166 2.7892V10.1762C0.999166 10.251 1.02321 10.3125 1.07129 10.3605C1.11937 10.4086 1.18081 10.4327 1.2556 10.4327H13.5745C13.6493 10.4327 13.7107 10.4086 13.7588 10.3605C13.8069 10.3125 13.8309 10.251 13.8309 10.1762V2.7892L7.41504 6.57045ZM7.41504 5.24597L13.6859 1.56728H1.14421L7.41504 5.24597ZM0.999166 2.7892V1.56728V10.1762C0.999166 10.251 1.02321 10.3125 1.07129 10.3605C1.11937 10.4086 1.18081 10.4327 1.2556 10.4327H0.999166V2.7892Z" fill="#F3F4F6"/>
        </svg>

    );
});

export const Download_Svg = React.memo((props:ISvgProps)=>{
    const {width,height} = props;

    return(
        <svg width={width} height={height} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M3 15.375L15 15.375L15 16.875L3 16.875L3 15.375ZM9.75 11.7525L12.4425 9.0675L13.5 10.125L9 14.625L4.5 10.125L5.5575 9.06L8.25 11.7525L8.25 1.125L9.75 1.125L9.75 11.7525Z" fill="#696F88"/>
        </svg>


    );
});

// risk color svg
export const Risk_Color_Svg = React.memo((props:ISvgProps)=>{
    const {width,height,color} = props;

    return(
        <svg width={width || 16} height={height || 16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="8" cy="8" r="8" fill={color || "#FF7A00"}/>
        </svg>

    );
});
export const Risk_Color_Svg_1 = React.memo((props:ISvgProps)=>{
    const {width,height} = props;

    return(
        <svg width={width || 16} height={height || 16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="8" cy="8" r="8" fill="#FF7A00"/>
        </svg>

    );
});

export const Risk_Color_Svg_2 = React.memo((props:ISvgProps)=>{
    const {width,height} = props;

    return(
        <svg width={width || 16} height={height || 16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="8" cy="8" r="8" fill="#0047FF"/>
        </svg>
    );
});

export const Risk_Color_Svg_3 = React.memo((props:ISvgProps)=>{
    const {width,height} = props;

    return(
        <svg width={width || 16} height={height || 16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="8" cy="8" r="8" fill="#5BC0FA"/>
        </svg>
    );
});

export const Risk_Color_Svg_4 = React.memo((props:ISvgProps)=>{
    const {width,height} = props;

    return(
        <svg width={width || 16} height={height || 16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="8" cy="8" r="8" fill="#FFAA2B"/>
        </svg>

    );
});

export const Risk_Color_Svg_5 = React.memo((props:ISvgProps)=>{
    const {width,height} = props;

    return(
        <svg width={width || 16} height={height || 16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="8" cy="8" r="8" fill="#FFD600"/>
        </svg>

    );
});

export const Risk_Color_Svg_6 = React.memo((props:ISvgProps)=>{
    const {width,height} = props;

    return(
        <svg width={width || 16} height={height || 16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="8" cy="8" r="8" fill="#10D300"/>
        </svg>

    );
});

export const Risk_Color_Svg_7 = React.memo((props:ISvgProps)=>{
    const {width,height} = props;

    return(
        <svg width={width || 16} height={height || 16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="8" cy="8" r="8" fill="#65FF57"/>
        </svg>

    );
});

export const Risk_Color_Svg_8 = React.memo((props:ISvgProps)=>{
    const {width,height} = props;

    return(
        <svg width={width || 16} height={height || 16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="8" cy="8" r="8" fill="#14580E"/>
        </svg>
    );
});

export const Risk_Color_Svg_9 = React.memo((props:ISvgProps)=>{
    const {width,height} = props;

    return(
        <svg width={width || 16} height={height || 16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="8" cy="8" r="8" fill="#8B8B8B"/>
        </svg>
    );
});

// new
export const Risk_Color_Svg_10 = React.memo((props:ISvgProps)=>{
    const {width,height} = props;

    return(
        <svg width={width || 16} height={height || 16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="8" cy="8" r="8" fill="#dc1b1b"/>
        </svg>
    );
});
export const Risk_Color_Svg_11 = React.memo((props:ISvgProps)=>{
    const {width,height} = props;

    return(
        <svg width={width || 16} height={height || 16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="8" cy="8" r="8" fill="#e72ec9"/>
        </svg>
    );
});
export const Risk_Color_Svg_12 = React.memo((props:ISvgProps)=>{
    const {width,height} = props;

    return(
        <svg width={width || 16} height={height || 16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="8" cy="8" r="8" fill="#9f86e1"/>
        </svg>
    );
});
export const Risk_Color_Svg_13 = React.memo((props:ISvgProps)=>{
    const {width,height} = props;

    return(
        <svg width={width || 16} height={height || 16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="8" cy="8" r="8" fill="#0bd6a7"/>
        </svg>
    );
});
export const Risk_Color_Svg_14 = React.memo((props:ISvgProps)=>{
    const {width,height} = props;

    return(
        <svg width={width || 16} height={height || 16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="8" cy="8" r="8" fill="#a92d6b"/>
        </svg>
    );
});

