import IF from "@/components/IF";
import { CheckBoxSelected, CheckBoxUnseleccted, ScrollLeftIcon, ScrollRightIcon } from "@/resource/IconComponents";
import { IDailyDataModel, IHour24DataModel, IHour8DataModel } from "@/util/http/responseType";
import React, { useEffect, useRef, useState } from "react";
import HourlySecond from "../Hourly/HourlySecond";
import HourlyFirst from "../Hourly/HourlyFirst";
import HourlyThird from "../Hourly/HourlyThird";
import HourlyFourth from "../Hourly/HourlyFourth";
import DailyTemperature from "../../../ForecastWeatherData/components/Daily/DailyTemperature";
import DailyWind from "../../../ForecastWeatherData/components/Daily/DailyWind";
import DailyOther from "../../../ForecastWeatherData/components/Daily/DailyOther";
import DailyPreciptation from "../../../ForecastWeatherData/components/Daily/DailyPreciptation";
import { ILanguage } from "@/util/en";
import "../../index.less";

interface IProps {
    style?:any
    weather24HoursData: {
        hours24Data: IHour24DataModel
        defaultShowIcon: { [keyText: string]: boolean}
    }
    weather8HoursData: {
        hours8Data: IHour8DataModel
        defaultShowIcon: { [keyText: string]: boolean}
    }
    weatherDayData: {
        hourDay: IDailyDataModel
        defaultShowIcon: { [keyText: string]: boolean}
    }
    date: string
    currentLanguage: ILanguage
    theme: string,
    CountryCode:string
}
const getWidth = () => window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

//get windowWidth
const useWindowWidth = () => {
    const [width, setWidth] = useState(getWidth);

    useEffect(() => {
        const handleResize = () => {
            setWidth(getWidth());
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return width;
};
const useHourlyTabs = (props: IProps) => {
    const {
        style,
        weather24HoursData,
        weather8HoursData,
        weatherDayData,
        date,
        currentLanguage,
        theme,
        CountryCode
    } = props;
    const [showDetailView, setShowDetailView] = useState(true);
    const [index, setIndex] = useState(0);

    const windowWidth = useWindowWidth();
    useEffect(() => {
        const date = new Date();
        const hour = date.getHours();
        let tmpIndex;
        if((CountryCode?.toUpperCase() == "CA"||CountryCode?.toUpperCase() == "US") && (windowWidth<=1300 && windowWidth>=1140) ){
            tmpIndex = Math.floor(hour / 3);
        }else
        {
            tmpIndex = Math.floor(hour / 4);
        }

        setIndex(tmpIndex);
    }, [CountryCode]);

    const divRef = useRef(null);

    useEffect(() => {
        // if(showDetailView) return;
        if(divRef.current){
            requestAnimationFrame(() => {
                const targetEle = document.getElementById("scroll-div");
                if (targetEle) {
                    console.log("lanxue aa targetEle.scrollLeft before", targetEle.scrollLeft);
                    const { width } = targetEle.getBoundingClientRect();
                    const targetLeft = index * width;
                    console.log("lanxue aa targetLeft", targetLeft);
                    setTimeout(() => {
                        targetEle.scrollLeft = targetLeft;
                    }, 2);

                }
            });
        }

    }, [showDetailView]);

    const [houlyClassName,setHoulyClassName] = useState("");
    useEffect(()=>{
        const tmpClassName = showDetailView ?"noShowDetailViewStyleForOrnam" :
            ((CountryCode?.toUpperCase() == "CA"||CountryCode?.toUpperCase() == "US")? "showDetailViewStyleForCAorUS"
                : "showDetailViewStyle") ;
        setHoulyClassName(tmpClassName);
    },[showDetailView,CountryCode]);

    const [flag,setFlag] = useState(false);
    useEffect(()=>{
        if((CountryCode.toUpperCase()=="CA"||CountryCode.toUpperCase()=="US") && (windowWidth<=1300 && windowWidth>=1140)){
            setFlag(true);
        }
        else{
            setFlag(false);
        }
    },[CountryCode,windowWidth]);
    console.log("lanxue data",weather24HoursData.hours24Data[date]);
    console.log("lanxue data showDetailView",showDetailView);
    return [
        {
            key: "hourly",
            label: currentLanguage.txt_Hourly,
            children: (
                <div className="index-hourly-component">
                    <div className="show-detail-control">
                        <button
                            className="detail-button"
                            onClick={() => {
                                setShowDetailView(!showDetailView);
                            }}
                        >
                            {
                                showDetailView ? <CheckBoxUnseleccted /> : <CheckBoxSelected />
                            }
                        </button>
                        <label style={{ color: `${theme === "light" ? "#2F3031" : "white"}` }}>
                            {currentLanguage.txt_ShowDetailView}
                        </label>
                        <IF show={!showDetailView}>
                            <div className={houlyClassName=="showDetailViewStyleForCAorUS"?"isMobileModeForCAorUS":"isMobileMode"}>
                                <span style={{
                                    marginLeft: "20px",
                                    color: theme === "light" ? "#2F3031" : "white"
                                }}>
                                    <span style={{
                                        width: "20px",
                                        display: "inline-block",
                                        color: theme === "light" ? "#2F3031" : "white"
                                    }}>

                                        {flag?`${index * 3}`.padStart(2, "0"):`${index * 4}`.padStart(2, "0")}
                                    </span>
                                    &nbsp;
                                        -
                                    &nbsp;

                                    <span style={{
                                        width: "20px",
                                        display: "inline-block",
                                        color: theme === "light" ? "#2F3031" : "white"
                                    }}>
                                        {flag?`${(index + 1) * 3 - 1}`.padStart(2, "0"):`${(index + 1) * 4 - 1}`.padStart(2, "0")}
                                    </span>
                                 &nbsp;&nbsp;{currentLanguage.txt_of}&nbsp;&nbsp;{24}&nbsp;{currentLanguage.txt_Hours}
                                </span>
                                <button style={{ padding: "0 10px" }} onClick={() => {
                                    const targetEle = document.getElementById("scroll-div");
                                    const { width } = targetEle.getBoundingClientRect();
                                    console.log("left width",width);
                                    console.log("left targetEle",targetEle);
                                    console.log("left scrollLeft",targetEle.scrollLeft);
                                    if (targetEle!.scrollLeft > 0) {
                                        const targetLeft = targetEle!.scrollLeft - width;
                                        console.log("left targetLeft",targetLeft);
                                        const index = Math.round(targetLeft/width);
                                        setIndex(index);
                                        targetEle!.scrollLeft = targetLeft;
                                    }
                                    <ScrollRightIcon color={theme === "light" ? "#232630" : "white"} />;
                                }}>
                                    <ScrollLeftIcon color={theme === "light" ? "#232630" : "white"} />
                                </button>
                                <button style={{ padding: "0 10px" }} onClick={() => {
                                    const targetEle = document.getElementById("scroll-div");
                                    const { width } = targetEle.getBoundingClientRect();
                                    console.log("right targetEle",targetEle);
                                    console.log("right scrollLeft",targetEle.scrollLeft);
                                    const targetLeft = targetEle!.scrollLeft + width;
                                    const index = Math.round(targetLeft/width);
                                    console.log("right index",index);

                                    // if(index < 6 ){
                                    //     setIndex(index);
                                    //     targetEle!.scrollLeft = targetLeft;
                                    // }

                                    if(flag){
                                        if(index < 8 )
                                        {
                                            setIndex(index);
                                              targetEle!.scrollLeft = targetLeft;
                                        }
                                    }else
                                    {
                                        if(index < 6 )
                                        {
                                            setIndex(index);
                                          targetEle!.scrollLeft = targetLeft;
                                        }
                                    }

                                }}>
                                    <ScrollRightIcon color={theme === "light" ? "#232630" : "white"} />
                                </button>
                            </div>
                        </IF>
                    </div>

                    <div
                        id="scroll-div"
                        className={`list-container ${showDetailView && "hourly-component-show"}`}
                        ref={divRef}
                    >
                        {
                            <IF show={showDetailView}>
                                <div
                                    style={{borderRight:"1px solid #C2C7D0"}}
                                    className={`${showDetailView ? "noShowDetailViewStyleForOrnam" : "showDetailViewStyle"}` }
                                    key={"show-detail"}
                                >
                                    <div style={style} className="title-div">
                                        {currentLanguage.txt_Hour}
                                    </div>
                                    <div className={`${showDetailView ? "single-hour-component" : "single-our-compoennt-not-show" } `}
                                    >

                                        <HourlyFirst
                                            weatherData={weather24HoursData.hours24Data?.[date]?.[0]}
                                            include={weather24HoursData.defaultShowIcon}
                                            indent={true}
                                            justShowIcon={true}
                                        />
                                        <HourlySecond
                                            weatherData={weather24HoursData.hours24Data?.[date]?.[0]}
                                            include={weather24HoursData.defaultShowIcon}
                                            indent={true}
                                            justShowIcon={true}
                                        />
                                        <HourlyThird
                                            weatherData={weather24HoursData.hours24Data?.[date]?.[0]}
                                            include={weather24HoursData.defaultShowIcon}
                                            indent={true}
                                            justShowIcon={true}
                                        />
                                        <HourlyFourth
                                            weatherData={weather24HoursData.hours24Data?.[date]?.[0]}
                                            include={weather24HoursData.defaultShowIcon}
                                            indent={true}
                                            justShowIcon={true}
                                        />

                                    </div>
                                </div>
                            </IF>
                        }
                        <div className={`${((windowWidth<1130.98)&&showDetailView) ? "scrollable-divs-mobile" : "scrollable-divs" } `}>
                            {/* hourly-navi */}
                            {
                                weather24HoursData.hours24Data[date]?.map((item, index) => (
                                    <>
                                        <IF show={showDetailView &&(windowWidth>1130) && (index === 8||index === 16)}>
                                            <div
                                                style={{borderRight:"1px solid #C2C7D0",width:"170px"}}
                                                key={"show-detail"}
                                                className={`${showDetailView ? "noShowDetailViewStyleForOrnam" : "showDetailViewStyle"}` }
                                            >

                                                <div style={style} className="title-div">
                                                    {currentLanguage.txt_Hour}
                                                </div>
                                                <div className={`${showDetailView ? "single-hour-component" : "single-our-compoennt-not-show" } `}>

                                                    <HourlyFirst
                                                        weatherData={item}
                                                        include={weather24HoursData.defaultShowIcon}
                                                        indent={true}
                                                        justShowIcon={true}
                                                    />
                                                    <HourlySecond
                                                        weatherData={item}
                                                        include={weather24HoursData.defaultShowIcon}
                                                        indent={true}
                                                        justShowIcon={true}
                                                    />
                                                    <HourlyThird
                                                        weatherData={item}
                                                        include={weather24HoursData.defaultShowIcon}
                                                        indent={true}
                                                        justShowIcon={true}
                                                    />
                                                    <HourlyFourth
                                                        weatherData={item}
                                                        include={weather24HoursData.defaultShowIcon}
                                                        indent={true}
                                                        justShowIcon={true}
                                                    />

                                                </div>
                                            </div>
                                        </IF>
                                        <div
                                            style={{borderRight:"1px solid #C2C7D0"}}
                                            // className={`${showDetailView ? "noShowDetailViewStyleForOrnam" : "showDetailViewStyleForCAorUS"}` }
                                            className={houlyClassName}
                                            key={`${item?.temperaturef_max}=${item.temperature_max}=${index}`}
                                        >
                                            <div className="title-div" style={style}>
                                                {parseFloat(index) < 10 ? "0" + index : index}
                                            </div>
                                            <div className={`${showDetailView ? "single-hour-component" : "single-our-compoennt-not-show" } `}>
                                                <HourlyFirst
                                                    weatherData={item}
                                                    include={weather24HoursData.defaultShowIcon}
                                                    indent={showDetailView}
                                                />
                                                <HourlySecond
                                                    weatherData={item}
                                                    include={weather24HoursData.defaultShowIcon}
                                                    indent={showDetailView}
                                                />
                                                <HourlyThird
                                                    weatherData={item}
                                                    include={weather24HoursData.defaultShowIcon}
                                                    indent={showDetailView}
                                                />
                                                <HourlyFourth
                                                    weatherData={item}
                                                    include={weather24HoursData.defaultShowIcon}
                                                    indent={showDetailView}
                                                />
                                            </div>
                                        </div>
                                    </>
                                ))
                            }
                        </div>
                    </div>
                </div>
            )
        },
        {
            key: "8hourly",
            label: currentLanguage.txt_8Hourly,
            children:  (
                <div className="eight-hourly-component">
                    {
                        weather8HoursData.hours8Data && weather8HoursData.hours8Data[date]?.map((item, index) => {
                            return (
                                <div className="single-container" key={`${item?.temperaturef_max}=${item.temperature_max}=${index}`}>
                                    <div style={style} className="title-div">
                                        {index === 0 ? "0 - 8" : index === 1 ? "8 - 16" : index === 2 ? "16 - 24" : "default"}
                                    </div>
                                    <div className="single-hour-component">
                                        <HourlyFirst
                                            weatherData={item}
                                            include={weather8HoursData?.defaultShowIcon}
                                        />
                                        <HourlySecond
                                            weatherData={item}
                                            include={weather8HoursData?.defaultShowIcon}
                                        />
                                        <HourlyThird
                                            weatherData={item}
                                            include={weather24HoursData?.defaultShowIcon}
                                        />
                                        <HourlyFourth
                                            weatherData={item}
                                            include={weather8HoursData?.defaultShowIcon}
                                        />
                                    </div>
                                </div>
                            );
                        })
                    }
                </div>
            )
        },
        {
            key: "daily",
            label: currentLanguage.txt_Daily,
            children: (
                <div className="daily-div">
                    {
                        weatherDayData && weatherDayData.hourDay[date] && (
                            <>
                                <div className="daily-left">
                                    <DailyTemperature weatherData={weatherDayData && weatherDayData.hourDay[date][0]} />
                                    <DailyWind weatherData={weatherDayData && weatherDayData.hourDay[date][0]} />
                                    <DailyOther weatherData={weatherDayData && weatherDayData.hourDay[date][0]} />
                                </div>
                                <div className="daily-right">
                                    <DailyPreciptation weatherData={weatherDayData && weatherDayData.hourDay[date][0]} />
                                </div>
                            </>
                        )
                    }
                </div>
            )
        },
    ];
};

export default useHourlyTabs;