// Crop array
export const CropOptions = [
    {
        CropName: "Apples",
        CropValue: "Apples",
        CropID: "Apples",
    },
    {
        CropName: "Canola/OSR",
        CropValue: "Canola",
        CropID: "Cereal",
    },
    {
        CropName: "Cereal",
        CropValue: "Cereal",
        CropID: "Cereal",
    },
    {
        CropName: "Corn",
        CropValue: "Corn",
        CropID: "Corn",
    },
    {
        CropName: "Grapes",
        CropValue: "Grapes",
        CropID: "Grapes",
    },
    {
        CropName: "Potato_V2",
        CropValue: "Potato_V2",
        CropID: "Potato_V2",
    }
];

export const DiseaseOptions = [
    {
        "ModelName": "Anthracnose Foliar Blight",
        "ModelID": "AnthracnoseFoliarBlight",
        "ModelDomain": "Turf",
        "Description": "Greencast disease Type predication on both Histroical and Forecast for Antracnose Foliar Blight",
        "PropertyType": "Output",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in 5 numerical values. 0 > no risk,1 > Very low, 2 > Low, 3 > Medium, 4 > High"
    },
    {
        "ModelName": "Irrigated Brown Patch Risk",
        "ModelID": "BrownPatchRiskIrrigated",
        "ModelDomain": "Turf",
        "Description": "Greencast disease Type predication on both Histroical and Forecast for Irrigated Brown Patch Risk",
        "PropertyType": "Output",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in 5 numerical values. 0 > no risk,1 > Very low, 2 > Low, 3 > Medium, 4 > High"
    },
    {
        "ModelName": "Non-Irrigated Brown Patch Risk",
        "ModelID": "BrownPatchRiskNonIrrigated",
        "ModelDomain": "Turf",
        "Description": "Greencast disease Type predication on both Histroical and Forecast for Non-Irrigated Brown Patch Risk",
        "PropertyType": "Output",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in 5 numerical values. 0 > no risk,1 > Very low, 2 > Low, 3 > Medium, 4 > High"
    },
    {
        "ModelName": "Crab grass Germination Risk",
        "ModelID": "CrabgrassGerminationRisk",
        "ModelDomain": "Turf",
        "Description": "Greencast disease Type predication on both Histroical and Forecast for Crab grass Germination Risk",
        "PropertyType": "Output",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in 5 numerical values. 0 > no risk,1 > Very low, 2 > Low, 3 > Medium, 4 > High"
    },
    {
        "ModelName": "Poa Annua Germination Risk",
        "ModelID": "PoaAnnuaGerminationRisk",
        "ModelDomain": "Turf",
        "Description": "Greencast disease Type predication on both Histroical and Forecast for Poa Annua Germination Risk",
        "PropertyType": "Output",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in 5 numerical values. 0 > no risk,1 > Very low, 2 > Low, 3 > Medium, 4 > High"
    },
    {
        "ModelName": "Irrigated Dollar Spot Risk",
        "ModelID": "DollarSpotRiskIrrigated",
        "ModelDomain": "Turf",
        "Description": "Greencast disease Type predication on both Histroical and Forecast for Irrigated Dollar Spot Risk",
        "PropertyType": "Output",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in 5 numerical values. 0 > no risk,1 > Very low, 2 > Low, 3 > Medium, 4 > High"
    },
    {
        "ModelName": "Non-Irrigated Dollar Spot Risk",
        "ModelID": "DollarSpotRiskNonIrrigated",
        "ModelDomain": "Turf",
        "Description": "Greencast disease Type predication on both Histroical and Forecast for Non-Irrigated Dollar Spot Risk",
        "PropertyType": "Output",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in 5 numerical values. 0 > no risk,1 > Very low, 2 > Low, 3 > Medium, 4 > High"
    },
    {
        "ModelName": "Fusarium Patch High Risk",
        "ModelID": "FusariumPatchHighRisk",
        "ModelDomain": "Turf",
        "Description": "Greencast disease Type predication on both Histroical and Forecast for Fusarium Patch High Risk",
        "PropertyType": "Output",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in 5 numerical values. 0 > no risk,1 > Very low, 2 > Low, 3 > Medium, 4 > High"
    },
    {
        "ModelName": "Fusarium Patch Risk",
        "ModelID": "FusariumPatchRisk",
        "ModelDomain": "Turf",
        "Description": "Greencast disease Type predication on both Histroical and Forecast for Fusarium Patch Risk",
        "PropertyType": "Output",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in 5 numerical values. 0 > no risk,1 > Very low, 2 > Low, 3 > Medium, 4 > High"
    },
    {
        "ModelName": "Gray Leaf Spot",
        "ModelID": "GrayLeafSpot",
        "ModelDomain": "Turf",
        "Description": "Greencast disease Type predication on both Histroical and Forecast for Gray Leaf Spot",
        "PropertyType": "Output",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in 5 numerical values. 0 > no risk,1 > Very low, 2 > Low, 3 > Medium, 4 > High"
    },
    {
        "ModelName": "Pythium Root Rot",
        "ModelID": "PythiumRootRotRisk",
        "ModelDomain": "Turf",
        "Description": "Greencast disease Type predication on both Histroical and Forecast for Pythium Root Rot",
        "PropertyType": "Output",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in 5 numerical values. 0 > no risk,1 > Very low, 2 > Low, 3 > Medium, 4 > High"
    },
    {
        "ModelName": "Irrigated Pythium Blight Risk",
        "ModelID": "PythiumBlightRiskIrrigated",
        "ModelDomain": "Turf",
        "Description": "Greencast disease Type predication on both Histroical and Forecast for Irrigated Pythium Blight Risk",
        "PropertyType": "Output",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in 5 numerical values. 0 > no risk,1 > Very low, 2 > Low, 3 > Medium, 4 > High"
    },
    {
        "ModelName": "Non-Irrigated Pythium Blight Risk",
        "ModelID": "PythiumBlightRiskNonIrrigated",
        "ModelDomain": "Turf",
        "Description": "Greencast disease Type predication on both Histroical and Forecast for Non-Irrigated Pythium Bligh tRisk",
        "PropertyType": "Output",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in 5 numerical values. 0 > no risk,1 > Very low, 2 > Low, 3 > Medium, 4 > High"
    },
    {
        "ModelName": "Rusk Risk",
        "ModelID": "RustRisk",
        "ModelDomain": "Turf",
        "Description": "Greencast disease Type predication on both Histroical and Forecast for Rust Risk",
        "PropertyType": "Output",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in 5 numerical values. 0 > no risk,1 > Very low, 2 > Low, 3 > Medium, 4 > High"
    },
    {
        "ModelName": "Irrigated Take All Patch Risk",
        "ModelID": "TakeAllPatchRiskIrrigated",
        "ModelDomain": "Turf",
        "Description": "Greencast disease Type predication on both Histroical and Forecast for Irrigated Take All Patch Risk",
        "PropertyType": "Output",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in 5 numerical values. 0 > no risk,1 > Very low, 2 > Low, 3 > Medium, 4 > High"
    },
    {
        "ModelName": "Non-Irrigated Take All Patch Risk",
        "ModelID": "TakeAllPatchRiskNonIrrigated",
        "ModelDomain": "Turf",
        "Description": "Greencast disease Type predication on both Histroical and Forecast for Non-Irrigated Take All Patch Risk",
        "PropertyType": "Output",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in 5 numerical values. 0 > no risk,1 > Very low, 2 > Low, 3 > Medium, 4 > High"
    },
    {
        "ModelName": "Smith-Kerns Dollar Spot",
        "ModelID": "DollarSpotSmithModelRisk",
        "ModelDomain": "Turf",
        "Description": "Greencast disease Type predication on both Histroical and Forecast for Smith-Kerns Dollar Spot",
        "PropertyType": "Output",
        "PropertyName": "RiskIndicator (pct)",
        "PropertyDescription": "Indicator for the expected disease risk classified in percent"
    },
    {
        "ModelName": "Grass Growing potential Index",
        "ModelID": "GrassGrowingPotentialIndex",
        "ModelDomain": "Turf",
        "Description": "Grass potential Indicator",
        "PropertyType": "Output",
        "PropertyName": "GrowthPotential (class)",
        "PropertyDescription": "Indicator for the expected Grass Growth Potential. 0 > no growth, 1 > growth"
    },
    {
        "ModelName": "Poa Annua Seedheads Risk",
        "ModelID": "PoaAnnuaSeedheadsRisk",
        "ModelDomain": "Turf",
        "Description": "Greencast disease Type predication on both Histroical and Forecast for Poa Annua Seedheads Risk",
        "PropertyType": "Output",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in 5 numerical values. 0 > no risk,1 > Very low, 2 > Low, 3 > Medium, 4 > High"
    },
    {
        "ModelName": "Botrytis",
        "ModelID": "PotatoBotrytiscinerea",
        "ModelDomain": "Potato_V2",
        "Description": "Potato disease Type predication on both Histroical and Forecast for Botrytiscinerea",
        "PropertyType": "Output",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in the range of 0-10. 0 = no risk,10 = High"
    },
    {
        "ModelName": "Early Blight",
        "ModelID": "PotatoEarlyBlightAlternariasolani",
        "ModelDomain": "Potato_V2",
        "Description": "Potato disease Type predication on both Histroical and Forecast for Alternariasolani",
        "PropertyType": "Output",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in the range of 0-10. 0 = no risk,10 = High"
    },
    {
        "ModelName": "Late Blight",
        "ModelID": "PotatoLateBlightPhytophthorainfestans",
        "ModelDomain": "Potato_V2",
        "Description": "Potato disease Type predication on both Histroical and Forecast for Phytophthorainfestans",
        "PropertyType": "Output",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in the range of 0-10. 0 = no risk,10 = High"
    },
    {
        "ModelName": "Silver scurf",
        "ModelID": "PotatoSilverscurfHelminthosporiumsolani",
        "ModelDomain": "Potato_V2",
        "Description": "Potato disease Type predication on both Histroical and Forecast for Helminthosporiumsolani",
        "PropertyType": "Output",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in the range of 0-10. 0 = no risk,10 = High"
    },
    {
        "ModelName": "Southern blight",
        "ModelID": "PotatoSclerotiniawiltSclerotiniarolfsii",
        "ModelDomain": "Potato_V2",
        "Description": "Potato disease Type predication on both Histroical and Forecast for Sclerotiniarolfsii",
        "PropertyType": "Output",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in the range of 0-10. 0 = no risk,10 = High"
    },
    {
        "ModelName": "Verticillium Wilt",
        "ModelID": "PotatoVerticilliumWiltVerticilliumdahliae",
        "ModelDomain": "Potato_V2",
        "Description": "Potato disease Type predication on both Histroical and Forecast for Verticilliumdahliae",
        "PropertyType": "Output",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in the range of 0-10. 0 = no risk,10 = High"
    },
    {
        "ModelName": "White mold",
        "ModelID": "PotatoSclerotiniarotSclerotiniasclerotiorum",
        "ModelDomain": "Potato_V2",
        "Description": "Potato disease Type predication on both Histroical and Forecast for Sclerotiniasclerotiorum",
        "PropertyType": "Output",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in the range of 0-10. 0 = no risk,10 = High"
    },

    {
        "ModelName": "Anthracnose black spot",
        "ModelID": "GrapesAnthracnoseblackspot",
        "ModelDomain": "Grapes",
        "Description": "Grapes disease Type predication on both Histroical and Forecast for Anthracnose",
        "PropertyType": "Output",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in the range of 0-10. 0 = no risk,10 = High"
    },
    {
        "ModelName": "Botrytis",
        "ModelID": "GrapesBotrytiscinerea",
        "ModelDomain": "Grapes",
        "Description": "Grapes disease Type predication on both Histroical and Forecast for Botrytiscineria",
        "PropertyType": "Output",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in the range of 0-10. 0 = no risk,10 = High"
    },
    {
        "ModelName": "Downy Mildew",
        "ModelID": "GrapesDownyMildewPlasmoparaviticola",
        "ModelDomain": "Grapes",
        "Description": "Grapes disease Type predication on both Histroical and Forecast for Plasmoparaviticola",
        "PropertyType": "Output",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in the range of 0-10. 0 = no risk,10 = High"
    },
    {
        "ModelName": "Powdery Mildew",
        "ModelID": "GrapesPowderyMildewUncinulanecator",
        "ModelDomain": "Grapes",
        "Description": "Grapes disease Type predication on both Histroical and Forecast for Uncinulanecator",
        "PropertyType": "Output",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in the range of 0-10. 0 = no risk,10 = High"
    },

    {
        "ModelName": "Barley - Net Blotch ",
        "ModelID": "BarleyNetBlotchPyrenophoraTeres",
        "ModelDomain": "Cereal",
        "Description": "Cereal disease Type predication on both Histroical and Forecast for Pyrenophorateres",
        "PropertyType": "Output",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in the range of 0-10. 0 = no risk,10 = High"
    },
    {
        "ModelName": "Brown / Leaf Rust",
        "ModelID": "CerealLeafBrownRustPucciniarecondita",
        "ModelDomain": "Cereal",
        "Description": "Cereal disease Type predication on both Histroical and Forecast for Pucciniarecondita",
        "PropertyType": "Output",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in the range of 0-10. 0 = no risk,10 = High"
    },
    {
        "ModelName": "Oats - Crow Rust",
        "ModelID": "OatsCrownRustPucciniaCoronata",
        "ModelDomain": "Cereal",
        "Description": "Cereal disease Type predication on both Histroical and Forecast for Pucinniacoronata",
        "PropertyType": "Output",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in the range of 0-10. 0 = no risk,10 = High"
    },
    {
        "ModelName": "Powdery Mildew",
        "ModelID": "CerealPowderyMildewEryspihegraminis",
        "ModelDomain": "Cereal",
        "Description": "Cereal disease Type predication on both Histroical and Forecast for Eryspihegraminis",
        "PropertyType": "Output",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in the range of 0-10. 0 = no risk,10 = High"
    },
    {
        "ModelName": "Septoria nodorum blotch",
        "ModelID": "CerealSeptoriaLeafGlumeBlotchStagonosporaNodorum",
        "ModelDomain": "Cereal",
        "Description": "Cereal disease Type predication on both Histroical and Forecast for Stagonosporanodorum",
        "PropertyType": "Output",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in the range of 0-10. 0 = no risk,10 = High"
    },
    {
        "ModelName": "Septoria Tritici",
        "ModelID": "CerealSeptoriaTriticiLeafBlotchSpot",
        "ModelDomain": "Cereal",
        "Description": "Cereal disease Type predication on both Histroical and Forecast for SeptoriaTritici",
        "PropertyType": "Output",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in the range of 0-10. 0 = no risk,10 = High"
    },
    {
        "ModelName": "Wheat - Black/stem Rust",
        "ModelID": "WheatBlackStemRustPucciniagraminis",
        "ModelDomain": "Cereal",
        "Description": "Cereal disease Type predication on both Histroical and Forecast for Pucinniagraminis",
        "PropertyType": "Output",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in the range of 0-10. 0 = no risk,10 = High"
    },
    {
        "ModelName": "Wheat - Fusarium Head Blight",
        "ModelID": "WheatFusariumHeadBlight",
        "ModelDomain": "Cereal",
        "Description": "Ceral disease Type predication on both Histroical and Forecast for WheatFusariumHeadBlight",
        "PropertyType": "Output",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in the range of 0-10. 0 = no risk,10 = High"
    },
    {
        "ModelName": "Wheat - Karnal or Partial Bunt",
        "ModelID": "WheatKarnalPartialBuntTilletiaindicavar",
        "ModelDomain": "Cereal",
        "Description": "Cereal disease Type predication on both Histroical and Forecast for Tilletiaindica",
        "PropertyType": "Output",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in the range of 0-10. 0 = no risk,10 = High"
    },
    {
        "ModelName": "Wheat - Septoria Leaf Blotch Risk",
        "ModelID": "WheatSeptoriaLeafBlotchRisk",
        "ModelDomain": "Cereal",
        "Description": "Ceral disease Type predication on both Histroical and Forecast for Septoria Leaf Blotch disease",
        "PropertyType": "Output",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in 4 numerical values. 0 > no risk,1 > Low, 2 > Medium, 3 > High"
    },
    {
        "ModelName": "Yellow/Stripe Rust",
        "ModelID": "CerealYellowStripeRustPucciniastriiformis",
        "ModelDomain": "Cereal",
        "Description": "Cereal disease Type predication on both Histroical and Forecast for Pucciniastriiformis",
        "PropertyType": "Output",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in the range of 0-10. 0 = no risk,10 = High"
    },

    {
        "ModelName": "Blackleg",
        "ModelID": "CanolaBlacklegLeptosphaeriamaculans",
        "ModelDomain": "Canola",
        "Description": "Cereal disease Type predication on both Histroical and Forecast for Leptosphaeriamaculans",
        "PropertyType": "Cereal",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in the range of 0-10. 0 = no risk,10 = High"
    },
    {
        "ModelName": "Sclerotinia Stem Rot - infection",
        "ModelID": "CanolaSclerotiniaApothecia",
        "ModelDomain": "Canola",
        "Description": "Cereal disease Type predication on both Histroical and Forecast for Sclerotiniasclerotioruminfection",
        "PropertyType": "Cereal",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in the range of 0-10. 0 = no risk,10 = High"
    },
    {
        "ModelName": "Sclerotinia Stem Rot - Apothecia on sclerotiums  ",
        "ModelID": "CanolaSclerotiniaInfection",
        "ModelDomain": "Canola",
        "Description": "Cereal disease Type predication on both Histroical and Forecast for Sclerotiniasclerotioruminfection",
        "PropertyType": "Cereal",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in the range of 0-10. 0 = no risk,10 = High"
    },
    {
        "ModelName": "White leaf spot",
        "ModelID": "CanolaWhiteLeafSpotPseudocercosporellacapsellae",
        "ModelDomain": "Canola",
        "Description": "Cereal disease Type predication on both Histroical and Forecast for Pseudocercosporellacapsellae",
        "PropertyType": "Cereal",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in the range of 0-10. 0 = no risk,10 = High"
    },

    {
        "ModelName": "Blossom Fire Blight",
        "ModelID": "AppleBlossomFireBlightErwiniaamylovora",
        "ModelDomain": "Apples",
        "Description": "Apples disease Type predication on both Histroical and Forecast for Erwiniaamylovora",
        "PropertyType": "Output",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in the range of 0-10. 0 = no risk,10 = High"
    },
    {
        "ModelName": "Scab / Fusicladium",
        "ModelID": "AppleScabFusicladiumVenturiainaequalis",
        "ModelDomain": "Apples",
        "Description": "Apples disease Type predication on both Histroical and Forecast for Erwiniaamylovora",
        "PropertyType": "Output",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in the range of 0-10. 0 = no risk,10 = High"
    },

    {
        "ModelName": "Fusarium Head Blight",
        "ModelID": "CornFusariumHeadBlight",
        "ModelDomain": "Corn",
        "Description": "Corn disease Type predication on both Histroical and Forecast for CornFusariumHeadBlight",
        "PropertyType": "Output",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in the range of 0-10. 0 = no risk,10 = High"
    },
    {
        "ModelName": "Gray Leaf Spot",
        "ModelID": "CornGrayLeafSpot",
        "ModelDomain": "Corn",
        "Description": "Corn disease Type predication on both Histroical and Forecast for CornGrayLeafSpot",
        "PropertyType": "Output",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in the range of 0-10. 0 = no risk,10 = High"
    },
    {
        "ModelName": "Northern corn leaf blight",
        "ModelID": "CornNortherncornleafblightNCLB",
        "ModelDomain": "Corn",
        "Description": "Corn disease Type predication on both Histroical and Forecast for CornNortherncornleafblightNCLB",
        "PropertyType": "Output",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in the range of 0-10. 0 = no risk,10 = High"
    },
    {
        "ModelName": "Southern corn leaf blight",
        "ModelID": "CornSoutherncornleafblightSCLB",
        "ModelDomain": "Corn",
        "Description": "Corn disease Type predication on both Histroical and Forecast for CornSoutherncornleafblightSCLB",
        "PropertyType": "Output",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in the range of 0-10. 0 = no risk,10 = High"
    },
    {
        "ModelName": "TarSpot",
        "ModelID": "TarSpot",
        "ModelDomain": "Corn",
        "Description": "Corn disease Type predication on Forecast for TarSpot",
        "PropertyType": "Output",
        "PropertyName": "RiskPredicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in 5 numerical values. 0 > no risk,1 > Very low, 2 > Low, 3 > Medium, 4 > High"
    }

];
