import { Button, Col, Input, Modal, Popconfirm, Radio, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import "./index.less";
import { IFieldsModel } from "@/util/http/Quantis/responseType";
import { useDispatch, useSelector } from "react-redux";
import { normalActionTypes, sagaActionTypes } from "@/redux/actions/quantisAction";
import { RootState } from "@/redux/reducer/store";
import {updateCurrentSelectedField,deleteCurrentSelectedField} from "@/util/http/Quantis/index";
const {Option} = Select;

interface IProp{
    showDeleteOrUpdateFieldModal: boolean
    cropValue: string;
    fieldName: string;
    changeShowDeleteOrUpdateFieldModalCallback: (falg:boolean)=>void
    updateFieldCallBack: (crop:string, fieldName:string, bookmarkFieldName?:string) =>void
}

const UpdateOrDeleteFieldModal = (props:IProp)=>{
    /**
     * when we click the Google Map's Marker, will show this Modal
     *      after click ,it will get related crop value, fieldName and other things
     *
     * init:
     *      updateOrDeleteCrop: is from cropValue,
     *      updateOrDeleteFieldName: is from fieldName
     *      lat and long is from current Marker
     *      updateOrDeleteBookMark: allFields filter by crop and fieldName, form it's isBookMark properties
     */
    const {
        showDeleteOrUpdateFieldModal,
        cropValue,
        fieldName,
        changeShowDeleteOrUpdateFieldModalCallback,
        updateFieldCallBack
    } = props;

    const dispatch = useDispatch();

    const {
        allCrop,
        allFields,
        currentMapCenter,
        updateFieldStatus
    } = useSelector((state: RootState) => state.quantisReducer);

    const [updateOrDeleteCrop,setUpdateOrDeleteCrop] = useState(null);
    const [udpateOrDeleteFeildName,setUpdateOrDeleteFieldName] = useState(null);
    const [orginUpdateOrDeleteFieldName,setOrginUpdateOrDeleteFieldName] = useState(null);
    const [updateOrDeleteIsBookMark,setUpdateOrDeleteIsBookMark] = useState(null);
    const [orginIsBookMark,setOrginIsBookMark] = useState(null); // this value is used in invok updateField function
    const initUpdateOrDeleteFieldModalState = ()=>{
        const tmpField = allFields?.filter( item => item.crop === cropValue &&
            item?.fieldName === fieldName
        )[0];

        setUpdateOrDeleteCrop(cropValue);
        setUpdateOrDeleteFieldName(fieldName);
        setOrginUpdateOrDeleteFieldName(fieldName);
        setUpdateOrDeleteIsBookMark(tmpField?.isBookMark);
        setOrginIsBookMark(tmpField?.isBookMark);
    };

    const publicChangeState = (type:string, value?: string | boolean)=>{
        console.log(`change : type is ${type}, value is ${value}`);
        switch(type){
            case "Crop":
                setUpdateOrDeleteCrop(value);
                break;
            case "FieldName":
                setUpdateOrDeleteFieldName(value);
                break;
            case "BookMark":
                setUpdateOrDeleteIsBookMark(value);
                break;
            default:
                console.log("update error");
        }
    };



    useEffect(()=>{
        if(showDeleteOrUpdateFieldModal) initUpdateOrDeleteFieldModalState();
    },[showDeleteOrUpdateFieldModal]);
    const onUpdateEnableBookmarkChange = ()=>{
        console.log();
    };
    const handleOK = ()=>{
        console.log();
    };
    const handleCancel = ()=>{
        changeShowDeleteOrUpdateFieldModalCallback(false);
        console.log();
    };
    const handleOk = ()=>{
        changeShowDeleteOrUpdateFieldModalCallback(false);
        console.log();
    };
    /**
     * click delete button and confirm to delete
     */
    const handleDeleteField = async()=>{
        dispatch({
            type: sagaActionTypes.SAGA_DELETE_CURRENTSELECTEDFIELD,
            payload: {
                crop: updateOrDeleteCrop,
                fieldName: udpateOrDeleteFeildName,
                latitude: currentMapCenter?.latitude?.toString(),
                longitude: currentMapCenter?.longitude?.toString()
            }
        });
        changeShowDeleteOrUpdateFieldModalCallback(false);
    };
    /**
     * click save button, final will invoke updateField func
     */
    const handleSaveButtonClick = ()=>{
        if(orginUpdateOrDeleteFieldName != udpateOrDeleteFeildName){
            const checkResult = checkWantToUpdateFieldName();
            if(!checkResult) {
                // TODO: old logic will set all value to empty, i don't know whether to ti
                return;
            }
        }

        dispatch({
            type: sagaActionTypes.SAGA_UPDATE_CURRENTSELECTEDFIELD,
            payload: {
                originalCrop: cropValue,
                originalFieldName: fieldName,
                latitude: currentMapCenter?.latitude?.toString(),
                longitude: currentMapCenter?.longitude?.toString(),
                newCrop: updateOrDeleteCrop,
                newFieldName: udpateOrDeleteFeildName,
                isBookMark: updateOrDeleteIsBookMark
            }
        });

    };
    const checkWantToUpdateFieldName = ()=>{
        if(!udpateOrDeleteFeildName){
            dispatch({
                type: normalActionTypes.NORMAL_UPDATE_SHOWERRORMESSAGE,
                payload: "The field name can'g change to empty."
            });
            return false;
        }
        else{
            const tmpField = allFields?.filter((item) => item?.fieldName === udpateOrDeleteFeildName);
            if(tmpField?.length >= 1){
                dispatch({
                    type: normalActionTypes.NORMAL_UPDATE_SHOWERRORMESSAGE,
                    payload: "The current field name already exists. Please change it."
                });
                return false;
            }
            else{
                return true;
            }
        }
    };

    useEffect(()=>{
        if(updateFieldStatus === "Success"){
            dispatch({
                type: normalActionTypes.NORMAL_UPDATE_SHOWSUCCESSMESSAGE,
                payload: "Update field successfully."
            });
            /**
             * current modal value: crop , fieldName and bookmarkValue should to main page
             */
            let tmpBookMarkValue = null;
            if(updateOrDeleteIsBookMark === "Y"){
                tmpBookMarkValue = udpateOrDeleteFeildName;
            }
            else{
                tmpBookMarkValue = "";
            }
            console.log(`update callcack: crop is ${updateOrDeleteCrop}, fieldValue is ${udpateOrDeleteFeildName}, isBookMarkValue is ${tmpBookMarkValue}`);
            updateFieldCallBack(updateOrDeleteCrop,udpateOrDeleteFeildName,tmpBookMarkValue);
            dispatch({
                type: normalActionTypes.NORMAL_UPDATE_UPDATEFIELDSTATUS,
                payload: null
            });
            // close the modal
            changeShowDeleteOrUpdateFieldModalCallback(false);
        }
        else if(updateFieldStatus === "Faild"){
            dispatch({
                type: normalActionTypes.NORMAL_UPDATE_UPDATEFIELDSTATUS,
                payload: null
            });
        }
    },[updateFieldStatus]);



    return(
        <div className="change-crop-add-field-modal-container" id="change-crop-add-field-modal-container">
            <Modal
                title="Update the field"
                getContainer = {()=> document.getElementById("change-crop-add-field-modal-container") as HTMLElement}
                open = {showDeleteOrUpdateFieldModal}
                onCancel={handleCancel}
                footer={[
                    <Popconfirm
                        key = "delete-field-confirm-modal"
                        title="It will delete relevant reports also ,are you sure to delete this field?"
                        onConfirm={handleDeleteField}
                        onCancel={() => {
                            changeShowDeleteOrUpdateFieldModalCallback(false);
                        }}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button key="close" type="default">
              Delete
                        </Button>
                    </Popconfirm>,
                    <Button
                        key="submit"
                        type="primary"
                        className="update-field-modal-button"
                        onClick={handleSaveButtonClick}
                    >
                  Save
                    </Button>,
                ]}
            >
                <div style={{ fontSize: 12 }} className="update-or-delete-all">
                    <Row style={{ height: 40 }}>
                        {/* <Col span={8}>Latitude: {cityCenter.center.lat.toFixed(4)}</Col> */}
                        <Col span={8}>Latitude: {currentMapCenter?.latitude?.toFixed(4)}</Col>
                        {/* <Col span={8}>Longitude: {cityCenter.center.lng.toFixed(4)}</Col> */}
                        <Col span={8}>Longitude: {currentMapCenter?.longitude?.toFixed(4)}</Col>
                    </Row>
                    <Row style={{ height: 40 }}>
                        <Col span={8}>Update Crop:</Col>
                        <Col span={16}>
                            <Select
                                value={updateOrDeleteCrop}
                                placeholder="Crop"
                                popupMatchSelectWidth ={false}
                                onChange={(e) => {
                                    // publicChangeState("Crop",e.target.value);
                                    publicChangeState("Crop",e);
                                }}
                            >
                                {allCrop
                                    ? allCrop.map((item: any, index: number) => (
                                        <Option key={index} value={item}>
                                            {item}
                                        </Option>
                                    ))
                                    : []}
                            </Select>
                        </Col>
                    </Row>
                    <Row style={{ height: 40 }}>
                        <Col span={8}>Update field name:</Col>
                        <Col span={16}>
                            <Input
                                placeholder="Field name"
                                value={udpateOrDeleteFeildName}
                                onChange={(e)=>{
                                    publicChangeState("FieldName",e.target.value);
                                }}
                            />
                        </Col>
                    </Row>
                    <Row style={{ height: 40 }}>
                        <Col span={8}>Update Bookmark:</Col>
                        <Col span={16}>
                            <Radio.Group
                                value={updateOrDeleteIsBookMark}
                                onChange={(e)=>{
                                    publicChangeState("BookMark",e.target.value);
                                }}
                            >
                                <Radio value="Y">Yes</Radio>
                                <Radio value="N">No</Radio>
                            </Radio.Group>
                        </Col>
                    </Row>
                </div>
            </Modal>
        </div>
    );
};

export default UpdateOrDeleteFieldModal;