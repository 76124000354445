import { IDiseaseMapDataModel, IDiseaseRiskGetLocationResModel, IDiseaseRiskInfoResModel,
    ILocationModel, ITranslateTextModel } from "@/util/http/responseType";
import { normalActionTypes } from "../actions/diseaseAction";
// init dieseae and translate data
import {InitDiseaseDataFromXML,InitTranslateText,InitDiseaseRiskInfo} from "@/resource/js/diseaseInitData";


interface IDiseaseModel {
    diseaseData?: IDiseaseMapDataModel;
    translateText?: ITranslateTextModel[];
    diseaseRiskInfo?: IDiseaseRiskInfoResModel[];
    showLodding: boolean;
    diseaseMapPredictionRiskInfo: IDiseaseRiskGetLocationResModel[];
    isExecuteDone?: boolean;
}



const initialCommon: IDiseaseModel = {
    // diseaseData: InitDiseaseDataFromXML,
    diseaseData: null,
    translateText: InitTranslateText,
    // diseaseRiskInfo: InitDiseaseRiskInfo,
    diseaseRiskInfo: [],
    showLodding: false,
    diseaseMapPredictionRiskInfo: [],
    isExecuteDone: false
};

const commonReducer = (state: IDiseaseModel = initialCommon, action) => {
    console.log("actionType", action.type);
    console.log("action.payload", action.payload);

    switch (action.type) {
        case normalActionTypes.NORMAL_DISEASE_DATA:
            console.log("commonReducer:",action.payload);
            return {
                ...state,
                diseaseData: action.payload
            };
        case normalActionTypes.NORMAL_TRANSLATETEXT:
            return{
                ...state,
                translateText: action.payload
            };
        case normalActionTypes.NORMAL_DISEASERISKINFO:
            return{
                ...state,
                diseaseRiskInfo: action.payload
            };
        case normalActionTypes.NORMAL_SHOWLODDING:
            return{
                ...state,
                showLodding: action.payload
            };
        case normalActionTypes.NORMAL_ISEXECUTEDONE:
            return{
                ...state,
                isExecuteDone: action.payload
            };
        case normalActionTypes.NORMAL_DISEASEMAPPREDICTIONRISKINFO:
            return{
                ...state,
                diseaseMapPredictionRiskInfo: action.payload
            };
        default:
            return state;
    }
};

export type {
    IDiseaseModel,
    ITranslateTextModel
};

export default commonReducer;