import React, { useCallback, useEffect, useState } from "react";
import "./index.less";
import { Link, useParams } from "react-router-dom";
import { getCalculatorConvertorDataFromXML } from "@/util/http";
import { IGetCalculatorConvertorResModel } from "@/util/http/responseType";
import { IGetCalculatorConvertorReqModel } from "@/util/http/requestType";
import IF from "@/components/IF";
import { Button, Col, Input, Row, Space, Tabs ,InputNumber} from "antd";
import { ResetValueIcon } from "@/resource/IconComponents";

enum themeEnum {
    LightTheme = "light-theme",
    DarkTheme = "dark-theme"
}

const UnitConversionsCalculators = () => {

    // add google analysis
    useEffect(() => {
        console.log("just invoke once");
        const head = document.getElementsByTagName("head")[0];
        const googleAnalyticsCode = "G-JE0GVD2GLS";
        const googleAnalyticsFirstScript = document.createElement("script");
        googleAnalyticsFirstScript.async = true;
        googleAnalyticsFirstScript.src =
          "https://www.googletagmanager.com/gtag/js?id=" + googleAnalyticsCode;

        const googleAnalyticsSecondScript = document.createElement("script");
        googleAnalyticsSecondScript.innerHTML =
          "    window.dataLayer = window.dataLayer || [];\n" +
          "    function gtag(){dataLayer.push(arguments);}\n" +
          "    gtag('js', new Date());\n" +
          "\n" +
          "    gtag('config', '" +
          googleAnalyticsCode +
          "',{\n" +
          "cookie_flags:'max-age=7200;secure;samesite-none'" +
          "});";

        head.insertBefore(googleAnalyticsSecondScript, head.firstChild);
        head.insertBefore(googleAnalyticsFirstScript, head.firstChild);
    }, []);

    const routerParams = useParams();
    // use regex to get title, unit, description
    const formatTitle = (title: string) => {
        const strReg = /\{((\S| )*)\}/;
        return title.match(strReg) == null ? title : title.match(strReg)[1].trim();
    };
    const getUnit = (description: string) => {
        const strReg = /\s+(\([a-zA-Z0-9/<>.° ]*\))\s*/;
        description = formatTitle(description);
        if (description.match(strReg) == null) {
            return null;
        }
        else {
            const strUnit = description.match(strReg)[1].trim();
            return <div dangerouslySetInnerHTML={{ __html: strUnit.match(/[^()]*/g)[1].trim() }}></div>;
        }
    };
    const getDescription = (description: string) => {
        const strReg = /[a-zA-Z0-9/_ ]*(\(\d*\))?/;
        description = formatTitle(description);
        return description.match(strReg) == null ? description : description.match(strReg)[0].trim();
    };
    // reset calculators & converters
    const resetCalculator = (calculatorIndex) => {
        const newDetails = { ...details };
        const newCalculator = newDetails.calculators[calculatorIndex];
        for (let x = 0; x < newCalculator.columns.length; x++) {
            newCalculator.columns[x].value = "";
        }
        newDetails.calculators[calculatorIndex] = newCalculator;
        setDetails(newDetails);
    };
    const resetConverter = (converterIndex) => {
        const newDetails = { ...details };
        const newConvertor = newDetails.convertors[converterIndex];
        for (let x = 0; x < newConvertor.columns.length; x++) {
            newConvertor.columns[x].value = "";
        }
        newDetails.convertors[converterIndex] = newConvertor;
        setDetails(newDetails);
    };
    const formatCalculateResult = (value: string) => {
        if (value == null || value == undefined || value == "" || value == "NaN") return value;
        const num = (+value);
        if (num == 0) return 0;
        if (Math.abs(num) > 1E6 || Math.abs(num) < 1E-6)
            return Number(num).toExponential(4);
        if (Math.abs(num) > 1)
            return Number(num).toFixed(2);
        if (Math.abs(num) < 1)
            return Math.round(num * 1E6) / 1E6;
        return num;
    };
    const changeCalaulatorValue = (calculatorIndex, expression, value) => {
        const newDetails = { ...details };
        const newCalculator = newDetails.calculators[calculatorIndex];
        for (let x = 0; x < newCalculator.columns.length; x++) {
            if (newCalculator.columns[x].expression == expression) {
                newCalculator.columns[x].value = value;
                break;
            }
        }
        newDetails.calculators[calculatorIndex] = newCalculator;
        setDetails(newDetails);
    };
    const calculateCalculatorValue = (calculatorIndex) => {
        const newDetails = { ...details };
        const newCalculator = newDetails.calculators[calculatorIndex];
        if (newCalculator.columns.filter(x => x.expressionType == "CNV Calculation Parameter" && (x.value ?? "") == "").length > 0) {
            for (let x = 0; x < newCalculator.columns.length; x++) {
                if (newCalculator.columns[x].expressionType == "CNV Calculation Result") {
                    newCalculator.columns[x].value = "NaN";
                }
            }
        } else {
            const strExpressions = newCalculator.columns.filter(x => x.expressionType == "CNV Calculation Parameter").map(x => `var ${x.expression}=${x.value};`).join(" ");
            for (let x = 0; x < newCalculator.columns.length; x++) {
                if (newCalculator.columns[x].expressionType == "CNV Calculation Result") {
                    let result = 0;
                    result = 0;
                    eval(`${strExpressions}; result=${newCalculator.columns[x].expression};`);
                    newCalculator.columns[x].value = `${result}`;
                }
            }
        }
        newDetails.calculators[calculatorIndex] = newCalculator;
        setDetails(newDetails);
    };
    const changeConvertorValue = (convertorIndex, columnIndex, value) => {
        const newDetails = { ...details };
        const newConvertor = newDetails.convertors[convertorIndex];
        newConvertor.columns[columnIndex].value = value ?? "";
        if (newConvertor.columns[columnIndex].value == "") {
            for (let x = 0; x < newConvertor.columns.length; x++) {
                newConvertor.columns[x].value = "";
            }
        } else {
            let val = 0;
            for (let x = 0; x < newConvertor.columns.length; x++) {
                val = (+newConvertor.columns[columnIndex].value);
                eval(`val=${newConvertor.columns[columnIndex].toReference}`);
                if (x != columnIndex) {
                    eval(`val=${newConvertor.columns[x].fromReference}`);
                    newConvertor.columns[x].value = `${formatCalculateResult(`${val}`)}`;
                }
            }
        }
        newDetails.convertors[convertorIndex] = newConvertor;
        setDetails(newDetails);
    };

    const [currentTheme, setCurrentTheme] = useState<string>(themeEnum.LightTheme.toString());
    const [basicParams, setBasicParams] = useState<IGetCalculatorConvertorReqModel>({
        language: "en",
        countryCode: "AU",
        token: "3B819BB1-3084-40B4-BF27-ADC00375C6F8"
    });

    const [details, setDetails] = useState<IGetCalculatorConvertorResModel | null>(null);

    const getCalculatorAndConvertors = async (basicParams) => {
        const res = await getCalculatorConvertorDataFromXML(basicParams);
        setDetails(res.data);
    };

    useEffect(() => {
        const { language, countryCode, token, theme } = routerParams;
        let _theme = themeEnum.LightTheme.toString();
        if (theme && theme.match(/dark/ig)) {
            _theme = themeEnum.DarkTheme.toString();
        }
        setBasicParams({ ...basicParams, language, countryCode, token });
        setCurrentTheme(_theme);
    }, []);

    useEffect(() => {
        getCalculatorAndConvertors(basicParams);
    }, [basicParams]);

    return (
        <div className={`div-container-unit ${currentTheme}`}>
            <div className="pageTitleDiv">
                <p className="pageTitle">
                Unit Conversion Calculators
                </p>
            </div>
            <div className="div-content">
                {
                    details == null || details.calculators == null ? null :
                        <div className="div-calculators">

                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 64 }}>
                                {
                                    details.calculators.map((calculator, calculatorIndex) =>
                                        <Col xs={23} sm={23} md={15} lg={12} xl={7} key={calculatorIndex} className="gutter-row">
                                            <div className="card">
                                                <div className="cardContent">
                                                    <div className="cardTitle">{formatTitle(calculator.title)}</div>
                                                    <div className="iconAndTitle" onClick={(e) => { resetCalculator(calculatorIndex); }}>
                                                        <ResetValueIcon />
                                                        <span className="iconTitle">Reset Values</span>
                                                    </div>
                                                    {
                                                        calculator.columns.filter(x => x.expressionType == "CNV Calculation Parameter").map((column, columnIndex) =>
                                                            <div className="rowStyle" key={columnIndex}>
                                                                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 64 }}>
                                                                    <Col xs={23} sm={23} md={11} lg={9} xl={9}>
                                                                        <span className="columnTitle">{getDescription(column.label)} :</span>
                                                                    </Col>
                                                                    <Col xs={20} sm={20} md={12} lg={14} xl={14} className="mlForMobile">
                                                                        <InputNumber size="large" addonAfter={getUnit(column.label)}
                                                                            value={column.value}
                                                                            onChange={(value) => { changeCalaulatorValue(calculatorIndex, column.expression, value); }} />

                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        )
                                                    }
                                                    <div className="divider"></div>
                                                    <Button className="bottomButton" onClick={() => { calculateCalculatorValue(calculatorIndex); }}>
                                                        <span style={{color:"#FFFFFF"}}>Calculate</span>
                                                    </Button>
                                                    {
                                                        calculator.columns.filter(x => x.expressionType == "CNV Calculation Result").map((column, columnIndex) =>
                                                            <div className="rowStyle" style={{marginTop:"14px"}} key={columnIndex}>
                                                                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 64 }}>
                                                                    <Col xs={23} sm={23} md={14} lg={13} xl={13}>
                                                                        <p className="bottomTitle" >{formatTitle(column.label)}</p>
                                                                    </Col>
                                                                    <Col xs={23} sm={23} md={11} lg={10} xl={10}>
                                                                        <p className="numberStyle">{formatCalculateResult(column.value)}</p>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </Col>
                                    )
                                }
                            </Row>

                        </div>

                }
                {
                    details == null || details.convertors == null ? null :
                        <div className="div-convertors">
                            {
                                details.convertors.map((converter, converterIndex) =>
                                    <div key={converterIndex}>
                                        <div className="unitStyle">
                                            <div className="unitReset">
                                                <Row style={{ marginBottom: "11px", paddingTop: "14px" }}>
                                                    <Col xs={23} sm={23} md={20} lg={20} xl={20}>
                                                        <div className="title">{formatTitle(converter.groupname)}</div>
                                                    </Col>
                                                    <Col xs={23} sm={23} md={4} lg={4} xl={4}>
                                                        <div className="reset" onClick={(e) => { resetConverter(converterIndex); }}>
                                                            <ResetValueIcon />
                                                            <span className="resetText">Reset Values</span>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <div className="unitContent">
                                                <Row>
                                                    {
                                                        converter.columns.map((column, columnIndex) =>
                                                            <Col xs={23} sm={23} md={12} lg={12} xl={6} key={columnIndex}>
                                                                <Row className="tab-row">
                                                                    <Col xs={22} sm={22} md={12} lg={11} xl={11}>
                                                                        <span className="card-row-title">{getDescription(column.label)} :</span>
                                                                    </Col>
                                                                    <Col xs={22} sm={22} md={18} lg={13} xl={13} style={{ paddingRight: "20px" }}>

                                                                        <InputNumber size="large" addonAfter={getUnit(column.label)}
                                                                            value={column.value}
                                                                            onChange={(value) => { changeConvertorValue(converterIndex, columnIndex, value); }} />

                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        )
                                                    }
                                                </Row>
                                            </div>
                                        </div>
                                        <div className={converter?.legends?.length > 0 ? "legendContent" : "noDisplay"}>
                                            {
                                                converter.legends == null ? null :
                                                    converter.legends.map((legend, legendIndex) =>
                                                        <Row gutter={36} className="tab-row" key={legendIndex}>
                                                            {
                                                                legend.unitLegendKeyValue == null ? null :
                                                                    legend.unitLegendKeyValue.map((item, itemIndex) =>
                                                                        <Col xs={23} sm={23} md={12} lg={6} xl={6} className="tab-card" key={itemIndex}>
                                                                            <span className="textStyle">{formatTitle(item.label)} :</span>
                                                                            <span className="detailStyle"> {formatTitle(item.key)}</span>
                                                                            <span className="detailNumStyle"> {formatTitle(item.value)}</span>
                                                                        </Col>
                                                                    )
                                                            }
                                                        </Row>
                                                    )
                                            }

                                        </div>
                                    </div>
                                )
                            }
                        </div>
                }
            </div>
        </div >
    );
};

export default UnitConversionsCalculators;