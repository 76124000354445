import { Button, Modal } from "antd";
import React, { useState } from "react";
import Article from "@/page/Quantis/Article";
import "./index.less";
const PrivacyNotice = ()=>{

    const [showMapModalVisableDesc,setShowMapModalVisableDesc] = useState(false);
    const handleCancel = ()=>{
        setShowMapModalVisableDesc(false);
    };

    return(

        <div className="privacy-notice-container " id="privacy-notice-container"
            style={{marginBottom:"15px"}}>
            <div style={{ margin: "30 0", textAlign: "center" }}>
                <p>
                    <a
                        onClick={(e) => setShowMapModalVisableDesc(true)}
                        style={{ textDecoration: "underline", fontSize: 11 }}
                    >
                        Terms & conditions and privacy notice
                    </a>
                </p>
            </div>
            <Modal
                className="laws-and-regulations-modal"
                title="laws and regulations"
                bodyStyle={{ height: 360, overflow: "auto" }}
                width="90%"
                open={showMapModalVisableDesc}
                onCancel={handleCancel}
                getContainer = {()=> document.getElementById("privacy-notice-container") as HTMLElement}
                footer={[
                    <Button key="close" type="default" onClick={handleCancel}>
            Close
                    </Button>,
                ]}
            >
                <Article />
            </Modal>
        </div>
    );
};

export default PrivacyNotice;