import { Axios, AxiosResponse } from "axios";
import { http, ServerType } from "./http";

import {
    IAddBookMarkReqModel,
    IDiseaseDataFromXMLReqModel,
    IGetDiseaseRiskInfoReqModel,
    ILocationSearchReqModel,
    ITranslateTextReqModel,
    IGetCorpsSprayReqModel,
    ICornYieldRiskReqModal,
    IGetAggregatedWeatherReqModel,
    IHistTemperatureRiskReqModal,
    IGetCalculatorConvertorReqModel,
    IGetServerWarningReqModel,
    IGetKeyTurfGrowReqModel,
    IGetLocationReqModel,
    IHierarchiesRequestModel,
    IExecuteQueryRequestModel,
    IcheckQueryIsExistsRequestModel,
    ISaveQueryRequestModel
} from "./requestType";

import {
    IBookmarkResModel,
    ICoordinateSearchResModel,
    IDiseaseMapDataModel,
    ILocalDailyWidgetWeatherResModel,
    ITranslateTextModel,
    IUserInfoResModel,
    IWeatherForcastDailyListResModel,
    IDiseaseRiskGetLocationResModel,
    IDiseaseRiskInfoResModel,
    IChartResModel,
    IGetCorpsSprayResModel,
    ILoctionModal,
    IGetAggregatedWeatherResModel,
    IGetCalculatorConvertorResModel,
    IGetKeyTurfGrowResModel,
    INewLocationModel,
    ILocationForSimulationModel,
    IMetadataByGroupModel,
    ITemperatureAndRegionSettingModel,
    IHeatMapLocationModel,
    ICountryBoundries

} from "./responseType";

const searchLoaction = async (data: ILocationSearchReqModel) => {
    const url = "/api/LocationSearch/GenerateLocationObj";
    const res = await http.get(url, data);
    return res;
};
//============= use for disease map and disease risk =================
const diseaseData = async (data: IDiseaseDataFromXMLReqModel) => {
    const url = "/api/WeatherDiseaseMap/getDiseaseMapDataFromXML";
    const res = await http.post<IDiseaseDataFromXMLReqModel, AxiosResponse<IDiseaseMapDataModel>>(url,
        data,
        ServerType.ServerLocal,
        false
    );
    return res;
};

const getTranslatedText = async (data: ITranslateTextReqModel) => {
    const url = "/api/Translation/GetTranslatedText";
    const res = await http.post<ITranslateTextReqModel, AxiosResponse<ITranslateTextModel[]>>(
        url,
        data,
        ServerType.ServerLocal,
        false
    );
    return res;
};
const getDiseaseRiskInfo = async (data: IGetDiseaseRiskInfoReqModel) => {
    const url = "/api/WeatherDiseaseMap/GetDiseaseRiskInfo";
    const res = await http.post<IGetDiseaseRiskInfoReqModel, AxiosResponse<IDiseaseRiskInfoResModel[]>>(url,
        data,
        ServerType.ServerLocal,
        false
    );
    return res;
};
// Disease Risk
const getDiseaseRisksLocationAndValue = async (data: IGetLocationReqModel) => {
    // const url = "/api/Disease/GetLocation";
    const url = "/api/WeatherDiseaseMap/GetDiseases";
    console.log("data pa", data);
    const res = await http.get<IGetLocationReqModel, AxiosResponse<IDiseaseRiskGetLocationResModel[]>>(url,
        data,
        ServerType.ServerLocal,
        false
    );
    return res;
};

//============= use for disease map and disease risk =================


// longitude 51.51279
// latitude -0.09184
const searchCoordinate = async (data: { latitude: number, longitude: number }) => {
    const url = "/api/LocationSearch/GenerateLocationByCoordinate";
    const res = await http.get<{ latitude: number, longitude: number }, AxiosResponse<ICoordinateSearchResModel>>(
        url,
        data,
        ServerType.ServerLocal
    );
    return res;
};

const addBookMark = async (data: IAddBookMarkReqModel) => {
    const url = "/api/Bookmark/AddBookmark";
    const res = await http.post<IAddBookMarkReqModel, AxiosResponse<boolean>>(url, data, ServerType.ServerLocal, false);
    return res;
};

const getBookMark = async (data: { userName: string, countryCode: string }) => {
    const url = "api/Bookmark/GetBookmarks";
    const res = await http.post<{ userName: string, countryCode: string }, AxiosResponse<IBookmarkResModel[]>>(url, data, ServerType.ServerLocal, false);
    return res;
};

const getUserInfo = async (encryptedText: string) => {
    const url = "/api/Cryptography/Decrypt";
    const res = await http.get<{ encryptedText: string }, AxiosResponse<IUserInfoResModel>>(
        url,
        { encryptedText },
        ServerType.ServerLocal
    );
    return res;
};

const getWeatherForCastDailyList = async (data: { latitude: number, longitude: number,
                                                cultureCode : string, numberOfDays: number,
                                                isGreencastForecast: boolean }) => {
    const url = "/api/Icon/GetWeatherForecastDaily";
    const res = await http.get<{ latitude: number, longitude: number, cultureCode :
                string, numberOfDays: number,
                isGreencastForecast: boolean },
                IWeatherForcastDailyListResModel[]
                >(
                    url,
                    data,
                    ServerType.ServerLocal
                );
    return res;
};

const getLocalHourlyWidget = async (data: { latitude: number, longitude: number,
    cultureCode: string, numberOfDays: number, isNowcast: boolean,isGreencastForecast:boolean }) => {
    const url = "/api/Icon/GetLocalHourlyWidget";
    const res = await http.get<
        { isNowcast: boolean, latitude: number, longitude: number, cultureCode: string,isGreencastForecast:boolean },
        AxiosResponse<ILocalDailyWidgetWeatherResModel[]>>(
            url,
            data,
            ServerType.ServerLocal
        );
    return res;
};
//corn yield risk page
const getLocations = async () => {
    const url = "/api/CornYieldRisk/GetLocations";
    const res = await http.get<
        AxiosResponse<any>,
        AxiosResponse<ILoctionModal[]>>(
            url,
            null,
            ServerType.ServerLocal
        );
    return res;
};
const getCornYieldRisk = async (data: {
    latitude: number, longitude: number, riskType: string,
    startDate: string,
    endDate: string,
    thresholdValue?: number,
    minConsecutiveDays?: number,
    maxConsecutiveDays?: number,
    minThresholdValue?: number,
    maxThresholdValue?: number,
    download?: boolean}) => {
    //console.log("aa download",data);
    //console.log("aa download",data.download);
    const url = "/api/CornYieldRisk/CornYieldRisk";
    let tmpData = null;
    if (data.download) {
        const fileName = "CornYieldRisk_" + new Date().getTime();
        console.log("aa s Enter download");
        const res = await http.downloadBlob<
            ICornYieldRiskReqModal,
            AxiosResponse<any>>(url, data, fileName, ServerType.ServerLocal);
    }
    else {
        const res = await http.get<
            ICornYieldRiskReqModal,
            AxiosResponse<any>>(
                url,
                data,
                ServerType.ServerLocal
            );
        tmpData = res;
    }
    return tmpData;
};

const getChartData = async (data: { latitude: number, longitude: number, cultureCode: string,
     numberOfDays: number, unit: string, isGreencastForecast: boolean}) => {
    const url = "/api/Chart/GetTemperatureAndPrecipitation";
    const res = await http.get<{ latitude: number, longitude: number,
         cultureCode: string, numberOfDays: number, unit: string, isGreencastForecast:boolean },
          AxiosResponse<IChartResModel[]>
         >(
             url,
             data,
             ServerType.ServerLocal
         );
    return res;
};

const getCorps = async (data: { cropList: string, cultureCode: string, pubname: string }) => {
    const url = "/api/Table/GetCrops";
    const res = await http.get<{ cropList: string, cultureCode: string, pubname: string }, AxiosResponse<{ key: string, name: string }[]>>(url, data, ServerType.ServerLocal);
    return res;
};

const getCorpsSpray = async (data: IGetCorpsSprayReqModel) => {
    const url = "/api/Table/GetCorpsSpray";
    const res = await http.get<IGetCorpsSprayReqModel, AxiosResponse<IGetCorpsSprayResModel>>(url, data, ServerType.ServerLocal);
    return res;
};

const getAggregateWeather = async (data: IGetAggregatedWeatherReqModel) => {
    const url = "/api/Table/GetAggregatedWeather";
    const res = await http.get<IGetAggregatedWeatherReqModel, AxiosResponse<IGetAggregatedWeatherResModel>>(
        url,
        data,
        ServerType.ServerLocal
    );
    return res;
};

//Historical Temperature Risk Page
const getHistTemperatureRisk = async (
    data:{
    placeName:string,
    latitude: number,
    longitude: number,
    startDate : string,
    endDate : string,
    maxNormalTemperature?: number,
    consHours?: number,
    orangeConsDays?: number,
    redConsDays?: number,
    download?: boolean}
) => {
    const url = "/api/Chart/HistTemperatureRisk";
    let tmpData = null;
    if(data.download){
        const fileName="HistTemperatureRisk"+new Date().getTime();
        console.log("aa s Enter download");
        const res = await http.downloadBlob<
        IHistTemperatureRiskReqModal,
        AxiosResponse<any>>(url,data,fileName,ServerType.ServerLocal);
    }
    else{
        const res = await http.get<
        IHistTemperatureRiskReqModal,
        AxiosResponse<any>>(
            url,
            data,
            ServerType.ServerLocal
        );
        tmpData = res;
    }
    return tmpData;
};

// Calculators
const getCalculatorConvertorDataFromXML = async (data: IGetCalculatorConvertorReqModel) => {
    const url = "api/Calculation/GetCalculatorConvertorDataFromXML";
    const res = await http.get<IGetCalculatorConvertorReqModel, AxiosResponse<IGetCalculatorConvertorResModel>>(url, data, ServerType.ServerLocal);
    return res;
};

const getServerWarning = async (data: IGetServerWarningReqModel) => {
    const url = "/api/Table/GetSevereWarning";
    const res = await http.get<IGetServerWarningReqModel, AxiosResponse<IGetCorpsSprayResModel>>(url, data, ServerType.ServerLocal);
    return res;
};

const getKeyTurfGrowthData = async (data: IGetKeyTurfGrowReqModel) => {
    const url = "/api/Table/GetKeyTurfGrowthData";
    const res = await http.get<IGetKeyTurfGrowReqModel, IGetKeyTurfGrowResModel>(url, data, ServerType.ServerLocal);
    return res;
};

const getFrostWarning = async (data: IGetKeyTurfGrowReqModel) => {
    const url = "/api/Table/GetFrostWarning";
    const res = await http.get(url, data, ServerType.ServerLocal);
    return res;
};

const getDisease = async (data: IGetServerWarningReqModel) => {
    const url = "/api/Table/GetDisease";
    const res = await http.get<IGetServerWarningReqModel, AxiosResponse<IGetAggregatedWeatherResModel>>(url, data, ServerType.ServerLocal);
    return res;
};


const getLocationOrCoordinate = async (data: string | { latitude: number, longitude: number } | object) => {
    const url = "/api/LocationSearch/GetLocationByNameOrCoordinate";
    const res = await http.get<string | { latitude: number, longitude: number } | object, AxiosResponse<INewLocationModel[]>>(
        url,
        data,
        ServerType.ServerLocal
    );
    return res;
};
//simulation
const getHierarchiesForSimulation = async (data: {
    hierarchy: string,
    country: string,
    crop?: string,
    location?: string,
    soilTexture?: string,
    soilDepth?: number,
    faw?: string,
    enso?:string,
    water?:string,
    cultivar?:string,
    year?:number
}) => {
    const url = "/api/Simulation/GetHierarchies";
    const res = await http.post<
    IHierarchiesRequestModel,
     AxiosResponse<[]>>(url, data, ServerType.ServerLocal,false);
    return res;
};
const getMetadataByGroupForSimulation = async (data: {group:string,isActive:boolean}) => {
    const url = "/api/Simulation/GetMetadataByGroup";
    const res = await http.post<
    {group:string,isActive:boolean},
     AxiosResponse<IMetadataByGroupModel[]>>(url, data, ServerType.ServerLocal,false);
    return res;
};
const getCountriesForSimulation = async () => {
    const url = "/api/Simulation/GetCountries";
    const res = await http.post<
        AxiosResponse<any>,
        AxiosResponse<any>>(
            url,
            null,
            ServerType.ServerLocal,
            false
        );
    return res;
};
const getLocationsForSimulation = async (data: { isActive: boolean,locationId?:number}) => {
    const url = "/api/Simulation/GetLocations";
    const res = await http.post<
    { isActive: boolean,locationId?:number},
    AxiosResponse<ILocationForSimulationModel[]>
    >(
        url,
        null,
        ServerType.ServerLocal,
        false);
    return res;
};
const getCorpsForSimulation = async (data: { isActive: boolean,locationId?:number}) => {
    const url = "/api/Simulation/GetCrops";
    const res = await http.post<
    {isActive: boolean,locationId?:number},
     AxiosResponse<{
     id: number,
     crop: string,
     isActive: boolean,
     lastModified: string }[]>>(url, data, ServerType.ServerLocal,false);
    return res;
};
const getSoilMaxDepthForSimulation = async () => {
    const url = "/api/Simulation/GetSoilMaxDepth";
    const res = await http.post<null,AxiosResponse<any>>(url, null, ServerType.ServerLocal,false);
    return res;
};
const getPlantDayOfYearForSimulation = async (data:{location:string,year?:number,latitude?:number,longitude?:number}) => {
    const url = "/api/Simulation/GetPlantDayOfYear";
    const res = await http.post<
    {location:string,year?:number,latitude?:number,longitude?:number},
    AxiosResponse<{
    value: string,
    text: string
    }>>(url, data, ServerType.ServerLocal,false);
    return res;
};

const simulationGetSignUrl = async () => {
    const url = "/api/Simulation/GetSignInUrl";
    const res = await http.get<null, AxiosResponse<{ value: string, text: string }>>(url, null, ServerType.ServerSimulation);
    return res;
};

const simulationGetToken = async (data:{authorizedCode: string, codeVerifier: string}) => {
    const url = "/api/Simulation/GetSimulationToken";
    const res = await http.post<{authorizedCode: string, codeVerifier: string},AxiosResponse<{value: string, text: string}>>(url, data, ServerType.ServerSimulation, false);
    return res;
};
const executeQueryForSimulation = async (data:IExecuteQueryRequestModel) => {
    const url = "/api/Simulation/ExecuteQuery";
    const res = await http.post<
    IExecuteQueryRequestModel,
    AxiosResponse<any>>(url, data, ServerType.ServerLocal,false);
    return res;
};
const saveQueryForSimulation = async (data:ISaveQueryRequestModel) => {
    const url = "/api/Simulation/SaveQuery";
    const res = await http.post<
    ISaveQueryRequestModel,
    AxiosResponse<any>>(url, data, ServerType.ServerLocal,false);
    return res;
};
const downloadQueryRawData = async (data:{queryId:number,fieldKey:string,year?:number}) => {
    const url = "/api/Simulation/DownloadQueryRawData";
    const fileName="RawData"+new Date().getTime();
    const res = await http.downloadBlob2<
    {queryId:number,fieldKey:string,year?:number},
    AxiosResponse<any>>(url, data, fileName,ServerType.ServerLocal);
};
const getRecentQueriesForSimulation= async (data:{days?:number,limit?:number}) => {
    const url = "/api/Simulation/getRecentQueries";
    const res = await http.post<
    {days?:number,limit?:number},
    AxiosResponse<any>>(url, data, ServerType.ServerLocal,false);
    return res;
};
const getSavedQueriesForSimulation= async () => {
    const url = "/api/Simulation/GetSavedQueries";
    const res = await http.post<
    AxiosResponse<any>,
    AxiosResponse<any>>(url, null, ServerType.ServerLocal,false);
    return res;
};
const executeQueryByGuidForSimulation= async (data:{queryId:number,queryGuid?:string}) => {
    const url = "/api/Simulation/ExecuteQueryByGuid";
    const res = await http.post<
    {queryId:number,queryGuid?:string},
    AxiosResponse<any>>(url, data, ServerType.ServerLocal,false);
    return res;
};
const getQueryForSimulation= async (data:{queryId:number,queryGuid?:number}) => {
    const url = "/api/Simulation/GetQuery";
    const res = await http.post<
    {queryId:number,queryGuid?:number},
    AxiosResponse<any>>(url, data, ServerType.ServerLocal,false);
    return res;
};
const deleteQueryForSimulation= async (data:{queryId:number}) => {
    const url = "/api/Simulation/DeleteQuery";
    const res = await http.post<
    {queryId:number},
    AxiosResponse<any>>(url, data, ServerType.ServerLocal,false);
    return res;
};
const checkQueryIsExistsForSimulation= async (data:IcheckQueryIsExistsRequestModel) => {
    const url = "/api/Simulation/CheckQueryIsExists";
    const res = await http.post<
    IcheckQueryIsExistsRequestModel,
    AxiosResponse<any>>(url, data, ServerType.ServerLocal,false);
    return res;
};
const checkQueryNameIsExistsForSimulation= async (data:{queryName:string}) => {
    const url = "/api/Simulation/CheckQueryNameIsExists";
    const res = await http.post<
    {queryName:string},
    AxiosResponse<any>>(url, data, ServerType.ServerLocal,false);
    return res;
};
const uploadErrorLogInfo= async (data:{appName:string, content: string}) => {
    const url = "/api/Log/LogClientInfo";
    const res = await http.post<
    {appName:string, content: string},
    AxiosResponse<any>>(url, null, ServerType.ServerLocal, true, data);
    return res;
};

const getMapSetting = async () => {
    const url = "/api/HeatMap/GetMappingSetting";
    const res = await http.get<null, AxiosResponse<{
        boundary_day: number,
        amber_level: number,
        red_level: number,
        amber_count: number,
        red_count: number
    }>>(
        url,
        null,
        ServerType.ServerLocal,
        false
    );
    return res;
};

const updateMapSetting = async (data:{boundary_day: number, red_level: number, amber_level: number, amber_count: number, red_count: number}) => {
    const url = "/api/HeatMap/UpdateMappingSetting";
    const res = await http.post<{
        boundary_day: number,
        red_level: number,
        amber_level: number,
        amber_count: number,
        red_count: number
    }, AxiosResponse<string>>(
        url,
        data,
        ServerType.ServerLocal,
        false
    );
    return res;
};

const getContriesBoundries = async (data: { geonameId: number[]}) => {
    const url = "/api/HeatMap/GetCountryBoundaries";
    const res = await http.post<{geonameId: number[]}, AxiosResponse<ICountryBoundries>>(
        url,
        data,
        ServerType.ServerLocal,
        false
    );
    return res;
};

const getTemperatureByRegionAndSetting = async (data) => {
    const url = "/api/HeatMap/GetTemperatureByRegionAndSetting";
    const res = await http.post<{geonameId: number[], stressType: string, startTime: string, endTime: string}, AxiosResponse<ITemperatureAndRegionSettingModel>>(
        url,
        data,
        ServerType.ServerLocal,
        false
    );
    return res;
};

const getAllLocations = async () => {
    const url = "/api/HeatMap/GetAllLocations";
    const res = await http.get<null, AxiosResponse<IHeatMapLocationModel[]>>(url, null, ServerType.ServerLocal, false);
    return res;
};

const downloadHeatMapExcel = async (data: {
    excelName: string
}) => {
    const url = "/api/HeatMap/DownloadTemplate";
    const fileName="HeatMap"+new Date().getTime();
    const res = await http.downloadBlob2<
    {excelName: string},
    AxiosResponse<any>>(url, data, fileName,ServerType.ServerLocal, false);
};

const checkServiceTokenIsValid = async (token_value:string) => {
    const url = "/api/WeatherDiseaseMap/CheckServiceTokenIsValid";
    const res = await http.get<any, AxiosResponse<boolean>>(
        url,
        {token:token_value},
        ServerType.ServerLocal,
        false
    );
    return res;
};

const checkForecastMapTokenServiceTokenIsValid = async (token_value:string) => {
    const url = "/api/WeatherDiseaseMap/CheckForecastMapTokenIsValid";
    const res = await http.get<any, AxiosResponse<boolean>>(
        url,
        {token:token_value},
        ServerType.ServerLocal,
        false
    );
    return res;
};


export {
    searchLoaction,
    diseaseData,
    getTranslatedText,
    getDiseaseRisksLocationAndValue,
    getDiseaseRiskInfo,
    searchCoordinate,
    addBookMark,
    getBookMark,
    getUserInfo,
    getWeatherForCastDailyList,
    getLocalHourlyWidget,
    getChartData,
    getCorps,
    getCorpsSpray,
    getLocations,
    getCornYieldRisk,
    getAggregateWeather,
    getHistTemperatureRisk,
    getCalculatorConvertorDataFromXML,
    getServerWarning,
    getKeyTurfGrowthData,
    getFrostWarning,
    getDisease,
    getLocationOrCoordinate,
    getHierarchiesForSimulation,
    getCountriesForSimulation,
    getLocationsForSimulation,
    getCorpsForSimulation,
    getMetadataByGroupForSimulation,
    getSoilMaxDepthForSimulation,
    getPlantDayOfYearForSimulation,
    simulationGetSignUrl,
    simulationGetToken,
    executeQueryForSimulation,
    saveQueryForSimulation,
    downloadQueryRawData,
    getRecentQueriesForSimulation,
    getSavedQueriesForSimulation,
    executeQueryByGuidForSimulation,
    deleteQueryForSimulation,
    getQueryForSimulation,
    checkQueryIsExistsForSimulation,
    checkQueryNameIsExistsForSimulation,
    uploadErrorLogInfo,
    getMapSetting,
    updateMapSetting,
    getContriesBoundries,
    getTemperatureByRegionAndSetting,
    getAllLocations,
    downloadHeatMapExcel,
    checkServiceTokenIsValid,
    checkForecastMapTokenServiceTokenIsValid
};
