import { getSeries } from "@/util/http/HistWeather";
import { IGetSeriesResModel } from "@/util/http/HistWeather/responseType";
import { useEffect,useState } from "react";
import {debounce} from "lodash";
import { useDispatch } from "react-redux";
import { normalActionTypes } from "@/redux/actions/diseaseAction";

interface IProp{
    countryCode: string;
    culture: string;
    unit: string;
    servicepage: string;
    pubname: string;
}

const useSeries = (props:IProp)=>{
    const {
        countryCode,
        culture,
        unit,
        servicepage,
        pubname
    } = props;

    const dispatch = useDispatch();

    const [seriesOptions,setSeriesOptions] = useState<IGetSeriesResModel[]>([]);

    const getSeriesFunc = async()=>{
        try{
            dispatch({ type: normalActionTypes.NORMAL_SHOWLODDING, payload: true});
            const res = await getSeries({
                countryCode,
                culture,
                unit,
                pubname
            });
            setSeriesOptions(res.data);
            dispatch({ type: normalActionTypes.NORMAL_SHOWLODDING, payload: false});
        }
        catch(error){
            dispatch({ type: normalActionTypes.NORMAL_SHOWLODDING, payload: false});
            console.log("========= useAggretation  getDropdownTranslata error======",error);
        }
    };

    useEffect(()=>{
        const myDebounceFunc = debounce(getSeriesFunc,500);
        if(countryCode && culture && unit) {
            myDebounceFunc();
        }
        return()=>{
            myDebounceFunc.cancel();
        };
    },[countryCode,culture,unit]);

    return {
        seriesOptions
    };
};

export default useSeries;