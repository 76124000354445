// import echarts from "echarts/types/dist/echarts";
import { IGetHistoricCharInfoResModel } from "@/util/http/HistWeather/responseType";
import dayjs from "dayjs";
import * as echarts from "echarts";
import React, { useEffect, useRef, useState } from "react";

interface IProp{
    type?: string;
    title: string;
    echartData?: IGetHistoricCharInfoResModel;
    colorArray: string[];
    idArray: string[];
    width?: number;
    countrycode?: string;
    echartSeriesName?: string[];
    echartTitle?: string;
}

const CountryDateFormat = {
    "AE": "DD/MM/YYYY",
    "AU": "DD/MM/YYYY",
    "BY": "DD.MM.Y",
    // "CH": "dd.mm.yy",
    "CH": "MM/DD/YYYY",
    "DE": "DD.MM.YYYY",
    "NZ": "dd-mm-yyyy",
    "US": "MM/DD/YYYY",
    "ES": "DD/MM/YYYY",
    "FR": "DD/MM/YYYY",
    "GB": "DD/MM/YYYY",
    "IL": "DD/MM/YYYY",
    "JP": "YYYY/MM/DD",
    "KR": "YYYY-MM-DD",
    "PL": "DD.MM.YYYY",
    "PT": "DD-MM-YYYY",
    "SE": "YYYY-MM-DD",
    "SI": "DD.MM.YYYY",
    "TR": "DD.MM.YYYY",
    "ZA": "dd/mm/yyyy",
    "NO": "DD/MM/YYYY",
    "FI": "DD/MM/YYYY",
    "DK": "DD/MM/YYYY",
};

const USSeriesNameAndID = [
    {
        seriesName: "GDD Accumulative",
        seriesID: "gdd_c_accumulated"
    },
    {
        seriesName: "Daily GDD",
        seriesID: "gdd_c_dailysum"
    },
    {
        seriesName: "Air Temperature Max",
        seriesID: "tempair_c_daytimemax_AltAdj"
    },
    {
        seriesName: "Air Temperature Min",
        seriesID: "tempair_c_nighttimemin_AltAdj"
    },
];

const selectedType = [
    "Air temperature",
    "Accumulated GDD"
];
const USColor = [
    "#1abe23",
    "#ea8104",
    "#2850de",
    "#08a6c1"
];

const EchartRender = (prop:IProp)=>{

    const {
        type ,
        title,
        echartData,
        colorArray,
        idArray,
        width,
        countrycode,
        echartSeriesName,
        echartTitle
    } = prop;
    console.log("echart render type",type);
    console.log("echart render idArray",idArray);
    console.log("echart render colorArray",colorArray);
    console.log("echart render echartData",echartData);
    console.log("echart render echartSeriesName",echartSeriesName);
    console.log("echart render width",width);

    const chartRef = useRef<HTMLDivElement>(null);
    const [echartContainerHeight,setEchartContainerHeight] = useState(430);



    const getFilterData = (id:string):number[] =>{
        const tmpData = [];
        echartData?.data?.forEach((item)=>{
            tmpData.push(item[id]);
            // if(item[id]) {
            //     tmpData.push(item[id]);
            // }
        });
        return tmpData;
    };

    // set left width
    const getLeftWidth = (isYAxisWidth?:boolean):number=>{
        let tmpWidth = 25;
        if(isYAxisWidth){
            if(width <= 800) tmpWidth = 20;
            else tmpWidth = 40;
        }
        else{
            if(width <= 800) tmpWidth = 25;
            else tmpWidth = 50;
        }
        return tmpWidth;
    };

    const getXAxisData = ():string[] =>{
        const tmpXAxisData = [];
        const formatStr = CountryDateFormat[countrycode] || "DD/MM/YYYY";
        echartData?.data?.forEach((item)=>{
            tmpXAxisData.push(dayjs(item?.date).format(formatStr));
        });
        return tmpXAxisData;
    };

    const changeEchartContainerHeight = ()=>{
        let tmpHeight = 430;
        console.log("lanxue aaaaa width",width);
        console.log("lanxue aaaaa countrycode",countrycode);
        if(countrycode === "US"){
            if(width <= 280) tmpHeight = 500;
            else if(width > 280 && width <= 385) tmpHeight = 460;
            else tmpHeight = 440;
        }
        else{
            tmpHeight = 435;
            // if(width <= 280){
            //     if(idArray.length <= 1){
            //         tmpHeight = 430;
            //     }
            //     else{
            //         const addHeight = (idArray.length -1 ) * 15;
            //         tmpHeight = tmpHeight + addHeight;
            //     }

            // }
        }
        setEchartContainerHeight(tmpHeight);
    };

    useEffect(()=>{
        // if(countrycode === "US"){
        //     changeEchartContainerHeight();
        // }
        changeEchartContainerHeight();
    },[countrycode,width]);
    console.log("lanxue chart echartContainerHeight",echartContainerHeight);
    console.log("lanxue chart width",width);
    console.log("lanxue chart countrycode",countrycode);

    // default options
    const options = {
        grid: {
            show: true,
            containLabel: true,
            left: getLeftWidth(),
            top: 10,
            width: width ? width -20 -50 - 10: 800 -50,
            height: 350
        },
        color: colorArray,
        xAxis: {
            data: [],
            type: "category",
            splitNumber: 14,
            position: "top",
            axisLine: {
                lineStyle: {
                    color: "#A3A9B9",
                    type: "solid"
                }
            },
            axisTick: {
                show: false,
            },
            splitLine: {
                show: true,
                lineStyle: {
                    type: "solid",
                    color: "#A3A9B9"
                }
            },
            axisLabel: {
                show: true,
            }
        },
        yAxis: {
            type: "value",
            // name: title,
            nameLocation: "center",
            nameGap: getLeftWidth(true),
            position: "left" ,
            nameTextStyle: {
                color: "#14151C",
                fontSize: 12,
                fontfamily: "Noto-Sans-Regular"
            },
            axisLabel: {
                show: true,
                interval: 0,
            },
            axisLine: {
                show: true, // 显示轴线
                lineStyle: {
                    type: "solid",
                    color: "#A3A9B9"
                }
            },
            axisTick: {
                show: false, // 隐藏刻度线
                alignWithLabel: true
            },
            data: [], // 刻度线对应的值
            splitLine: {
                show: true,
                lineStyle: {
                    type: "dashed",
                    color: "#A3A9B9"
                }
            }
        },
        series: [],
        textStyle: {
            fontFamily: "Noto-Sans-Regular",
            fontSize: 12,
            fontWeight: 400
        },
    };

    const changeEchartOption = (echartData:IGetHistoricCharInfoResModel)=>{
        changeEchartContainerHeight();
        const yData = [0,1,2,3,4,5,6,7,8,9,10,11,12]; // y轴的刻度值
        const xData = getXAxisData(); // x轴的刻度值
        const options = {
            grid: {
                show: true,
                containLabel: true,
                left: getLeftWidth(),
                top: 10,
                width: width ? width -20 -50 - 10: 800 -50,
                height: 350
            },
            color: colorArray,
            xAxis: {
                data: xData,
                type: "category",
                splitNumber: 14,
                axisLine: {
                    //show: false,
                    lineStyle: {
                        color: "#A3A9B9",
                        type: "solid"
                    }
                },
                axisTick: {
                    show: false,
                },
                splitLine: {
                    show: true,
                    //interval: 0,
                    lineStyle: {
                        type: "solid",
                        color: "#A3A9B9"
                    }
                },
                axisLabel: {
                    show: true,
                    //interval: 1,
                }
            },
            yAxis: {
                type: "value",
                name: title,
                nameLocation: "center",
                // nameGap: 40,
                nameGap: getLeftWidth(true),
                splitNumber: 9,
                // min: 0,
                // max: 12,
                nameTextStyle: {
                    color: "#14151C",
                    fontSize: 12,
                    fontfamily: "Noto-Sans-Regular"
                },
                axisLabel: {
                    show: true,
                    interval: 0,
                },
                axisLine: {
                    show: true, // 显示轴线
                    lineStyle: {
                        type: "solid",
                        color: "#A3A9B9"
                    }
                },
                axisTick: {
                    show: false, // 隐藏刻度线
                    //interval: 1
                    alignWithLabel: true
                },
                data: yData, // 刻度线对应的值
                splitLine: {
                    show: true,
                    interval: 0.5,
                    lineStyle: {
                        type: "dashed",
                        color: "#A3A9B9"
                    }
                }
            },
            series: (()=>{
                const tmp = [];
                idArray?.map((item,index)=>{
                    const tmpName = echartSeriesName[index];
                    tmp.push({
                        name: tmpName,
                        // type: "line",
                        type: type === "Precipitation" ? "bar" : "line",
                        smooth: true,
                        symbol: "none",
                        data: getFilterData(item)
                    });
                });
                return tmp;
            })(),
            tooltip: {
                trigger: "axis",
                backgroundColor: "white"
            },
            textStyle: {
                fontFamily: "Noto-Sans-Regular",
                fontSize: 12,
                fontWeight: 400
            },
            title: {
                text: echartTitle,
                top: 370,
                left: getLeftWidth(),
                textStyle: {
                    fontFamily: "Noto-Sans-Regular",
                    fontSize: 14,
                    fontWeight: 600,
                },
            },
            legend: {
                data: echartSeriesName,
                selected: (()=>{
                    let tmp = {};
                    echartSeriesName.forEach((item)=>{
                        tmp = {...tmp,item:true};
                    });
                })(),
                left: getLeftWidth(),
                // top: 395,
                top: 395,
            }
        };
        // chart.setOption(options);
        return options;
    };


    const changeUSEchartOption = (echartData:IGetHistoricCharInfoResModel)=>{
        const yData = [0,1,2,3,4,5,6,7,8,9,10,11,12]; // y轴的刻度值
        const xData = getXAxisData(); // x轴的刻度值
        changeEchartContainerHeight();
        const options = {
            grid: {
                show: true,
                containLabel: true,
                // left: getLeftWidth(),
                left: 30,
                top: 10,
                // right:10,
                // width: width ? width -45 -getLeftWidth(): 800 -50,
                // width: auto,
                height: 380
            },
            color: USColor,
            xAxis: {
                data: xData,
                type: "category",
                splitNumber: 14,
                axisLine: {
                    //show: false,
                    lineStyle: {
                        color: "#A3A9B9",
                        type: "solid"
                    }
                },
                axisTick: {
                    show: false,
                },
                splitLine: {
                    show: true,
                    //interval: 0,
                    lineStyle: {
                        type: "solid",
                        color: "#A3A9B9"
                    }
                },
                axisLabel: {
                    show: true,
                    //interval: 1,
                }
            },
            yAxis: (()=>{
                const tmp = [];
                selectedType.forEach((item,index)=>{
                    tmp.push({
                        type: "value",
                        name: item === "Air temperature" ? title : item,
                        nameLocation: "center",
                        nameGap: index === 0 ? 30 : 40 ,
                        position: index === 0 ? "left" : "right",
                        nameTextStyle: {
                            color: "#14151C",
                            fontSize: 12,
                            fontfamily: "Noto-Sans-Regular"
                        },
                        axisLabel: {
                            show: true,
                            interval: 0,
                        },
                        axisLine: {
                            show: true, // 显示轴线
                            lineStyle: {
                                type: "solid",
                                color: "#A3A9B9"
                            }
                        },
                        axisTick: {
                            show: false, // 隐藏刻度线
                            alignWithLabel: true
                        },
                        data: (()=>{
                            const tmp = [];
                            echartData?.data?.forEach((innerItem,innerIndex)=>{
                                if(item === "Air Temperature"){
                                    tmp.push(innerItem["tempair_c_daytimemax_AltAdj"]);
                                    tmp.push(innerItem["tempair_c_nighttimemin_AltAdj"]);
                                }
                                if(item === "Accumulated GDD"){
                                    tmp.push(innerItem["gdd_c_dailysum"]);
                                    tmp.push(innerItem["gdd_c_accumulated"]);
                                }
                            });
                            return tmp;
                        })(), // 刻度线对应的值
                        splitLine: {
                            show: true,
                            //interval: 0.5,
                            lineStyle: {
                                type: index === 0 ? "dashed" : "solid",
                                color: "#A3A9B9"
                            }
                        }
                    });
                });
                return tmp;
            })(),
            series: (()=>{
                const tmp = [];
                USSeriesNameAndID?.map((item,index)=>{
                    const tmpName = USSeriesNameAndID[index].seriesName;
                    tmp.push({
                        name: tmpName,
                        type: "line",
                        smooth: true,
                        symbol: "none",
                        data: getFilterData(item.seriesID),
                        yAxisIndex: index < 1 ? 1 : 0
                    });
                });
                return tmp;
            })(),
            tooltip: {
                trigger: "axis",
                backgroundColor: "white"
            },
            textStyle: {
                fontFamily: "Noto-Sans-Regular",
                fontSize: 12,
                fontWeight: 400
            },
            legend: {
                data: (()=>{
                    const tmp = [];
                    USSeriesNameAndID.forEach((item)=>{
                        tmp.push(item.seriesName);
                    });
                    return tmp;
                })(),
                selected: (()=>{
                    const tmp = {};
                    USSeriesNameAndID.forEach((item)=>{
                        const {seriesName} = item;
                        //tmp = {...tmp,seriesName:true};
                        tmp[seriesName]=true;
                    });
                    return tmp;
                })(),
                left: getLeftWidth(),
                top: 395,
            }
        };
        return options;
    };

    useEffect(() => {
        const chart = echarts.init(chartRef.current);
        let tmpOptions = null;
        console.log("echart render countrycode",countrycode);
        if(echartData?.data?.length > 0 && countrycode === "US"){
            console.log("ddd",echartData);
            console.log("ddd",echartData.data);
            tmpOptions = changeUSEchartOption(echartData);
        }
        else if(echartData?.data?.length > 0){
            console.log("ddd",echartData);
            console.log("ddd",echartData.data);
            tmpOptions = changeEchartOption(echartData);
        }
        else{
            tmpOptions = options;
        }
        console.log("echart render tmpOptions",tmpOptions);
        chart.setOption(tmpOptions);
        // chart.resize();
        return ()=>{
            chart.dispose();
        };
    }, [echartData,width]);
    return(
        <>
            <div
                ref={chartRef}
                style={{width:`${width ? width - 20 : 800}px`,height:`${echartContainerHeight}px`}}></div>
        </>
    );
};

export default React.memo(EchartRender);
// export default EchartRender;