import React, { useState ,useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import "./index.less";
import {Modal, Select, Row,Col ,DatePicker,Button,Input,Space,message,List,Spin } from "antd";
import {
    ManageSettingsIcon,
    DownloadAsImageIcon,
    GreenCircleIcon,
    OrangeCircleIcon,
    RedCircleIcon,
    BookMarkIcon} from "@/resource/IconComponents";
import ReactEcharts from "echarts-for-react";
import dayjs from "dayjs";
import { RootState } from "@/redux/reducer/store";
import {
    getHistTemperatureRisk
} from "@/util/http";
import {
    IHistTemperatureRiskModal
} from "@/util/http/responseType";
import IF from "@/components/IF";
import Search from "./components/Search";
import { normalActionTypes } from "@/redux/actions/diseaseAction";
import {
    sagaActionTypes,
    searchTargetCoordinate,
} from "@/redux/actions/commonAction";
import html2canvas from "html2canvas";
import * as echarts from "echarts";
import type { DatePickerProps, RangePickerProps } from "antd/es/date-picker";
import Circle from "@/resource/images/svg/cornYieldRisk/default.svg";
import { ILocationModel } from "@/util/http/responseType";
import { useLocation } from "react-router-dom";

interface ISearchObj{
    theme?: string;
}
const HistTemperatureRisk = () => {
    // const [histTemperatureRiskData,setHistTemperatureRiskData]=useState<IHistTemperatureRiskModal[]>([]);
    const dispatch = useDispatch();
    const urlLocation = useLocation();
    const {
        showLodding
    } = useSelector((state: RootState) => state.diseaseReducer);

    // add google analysis
    useEffect(() => {
        console.log("just invoke once");
        const head = document.getElementsByTagName("head")[0];
        const googleAnalyticsCode = "G-LVZM5Z6VH7";
        const googleAnalyticsFirstScript = document.createElement("script");
        googleAnalyticsFirstScript.async = true;
        googleAnalyticsFirstScript.src =
          "https://www.googletagmanager.com/gtag/js?id=" + googleAnalyticsCode;

        const googleAnalyticsSecondScript = document.createElement("script");
        googleAnalyticsSecondScript.innerHTML =
          "    window.dataLayer = window.dataLayer || [];\n" +
          "    function gtag(){dataLayer.push(arguments);}\n" +
          "    gtag('js', new Date());\n" +
          "\n" +
          "    gtag('config', '" +
          googleAnalyticsCode +
          "',{\n" +
          "cookie_flags:'max-age=7200;secure;samesite-none'" +
          "});";

        head.insertBefore(googleAnalyticsSecondScript, head.firstChild);
        head.insertBefore(googleAnalyticsFirstScript, head.firstChild);
    }, []);

    const [searchObj,setSearObj] = useState<ISearchObj>({
        theme: "light",
    });
    useEffect(()=>{
        document.body.style.backgroundColor = searchObj?.theme === "light" ? "white" : "black";
    },[searchObj?.theme]);
    const [bookLists,setBookLists]=useState([]);
    const [rawData,setRawData]=useState<IHistTemperatureRiskModal[]>([]);
    const [levelData,setLevelData]=useState<IHistTemperatureRiskModal[]>([]);

    const [placeName ,setPlaceName ]=useState("London");
    const [longitude,setLongitude]=useState(51.50853);
    const [latitude,setLatitude]=useState(-0.12574);
    const defaultStartDate = dayjs().add(-1,"year");
    const defaultEndDate = dayjs().add(-1,"day");
    const [startDate,setStartDate]=useState("");
    const [endDate,setEndDate]=useState("");
    const { RangePicker } = DatePicker;

    const [inputText, setInputText] = useState("London");
    const [currentLocation, setCurrentLocation] = useState<ILocationModel>();
    const [locationListShow, setLocationListShow] = useState(false);

    //This page is limited to the following countries
    const currentCountryCode ="GB;IE;NL;BE";

    const dateOnChange = (
        value: DatePickerProps["value"] | RangePickerProps["value"],
        dateString: [string, string] | string,
    ) => {
        if(value){
            getHistTemperatureRisks(
                placeName,
                latitude,
                longitude,
                dateString[0],
                dateString[1],
                maxNormalTemperature,
                consHours,
                orangeConsDays,
                redConsDays,
                false
            );
            setStartDate(dateString[0]);
            setEndDate(dateString[1]);
        }else
        {
            setStartDate("");
            setEndDate("");
        }
    };
    const dateOnOk = (value: DatePickerProps["value"] | RangePickerProps["value"]) => {
        console.log("onOk: ", value);
    };
    //Input data
    const [maxNormalTemperature,setMaxNormalTemperature]=useState(22);
    const [consHours,setConsHours]=useState(4);
    const [orangeConsDays,setOrangeConsDays]=useState(2);
    const [redConsDays,setRedConsDays]=useState(3);

    const coordinateButtonClicked = () => {
        navigator.geolocation.getCurrentPosition(
            (position: GeolocationPosition) => {
                dispatch({ type: sagaActionTypes.SAGA_COORDINATE_SEARCH, payload: {
                    searchText:`${position.coords.longitude},${position.coords.latitude}`}
                });
            },
            (err) => {
                console.log("test get current location error", err);
            }
        );
    };

    const defaultSettings = [
        { "setting": "SoilTemperatureAtEmergencePeriod", "thresholdValue": "10.00", "minConsecutiveDays": "1", "maxConsecutiveDays": "5", "diffDays": "0" },
        { "setting": "AirTemperatureAtEmergencePeriod", "thresholdValue": "10.00", "minConsecutiveDays": "1", "maxConsecutiveDays": "5", "diffDays": "0" },
        { "setting": "SoilWaterContentDuringRootDevelopment", "minThresholdValue": "5", "maxThresholdValue": "20", "diffDays": "0" },
        { "setting": "ParAtV6", "minThresholdValue": "10", "maxThresholdValue": "100", "diffDays": "0" },
        { "setting": "HeatStressAtFlowering", "thresholdValue": "10.00", "diffDays": "0" }
    ];

    const formatDate = ( time: any ) => {
        const Dates = new Date( time );
        const year: number = Dates.getFullYear();
        const month: any = ( Dates.getMonth() + 1 ) < 10 ? "0" + ( Dates.getMonth() + 1 ) : ( Dates.getMonth() + 1 );
        const day: any = Dates.getDate() < 10 ? "0" + Dates.getDate() : Dates.getDate();
        return day + "/" + month + "/" + year;
    };

    const formatTime = ( time: any ) => {
        const Dates = new Date( time );
        const year: number = Dates.getFullYear();
        const month: any = ( Dates.getMonth() + 1 ) < 10 ? "0" + ( Dates.getMonth() + 1 ) : ( Dates.getMonth() + 1 );
        const day: any = Dates.getDate() < 10 ? "0" + Dates.getDate() : Dates.getDate();
        const hs:any=time.substring(11,16);
        return day + "/" + month + "/" + year+" "+hs;
    };

    const getHistTemperatureRisks=async(
        placeName:string,
        latitude: number,
        longitude: number,
        startDate : string,
        endDate : string,
        maxNormalTemperature?: number,
        consHours?: number,
        orangeConsDays?: number,
        redConsDays?: number,
        download?: boolean)=>{
        //console.log("res res res is:",download);
        dispatch({ type: normalActionTypes.NORMAL_SHOWLODDING, payload: true});
        const res=await getHistTemperatureRisk(
            { placeName:placeName,
                latitude : latitude,
                longitude : longitude,
                startDate : startDate,
                endDate : endDate,
                maxNormalTemperature: maxNormalTemperature,
                consHours: consHours,
                orangeConsDays: orangeConsDays,
                redConsDays: redConsDays,
                download: download,
            });
        dispatch({ type: normalActionTypes.NORMAL_SHOWLODDING, payload: false});
        if(!download){
            setRawData(res.data.rawData);
            setLevelData(res.data.levelData);
        }
    };
    const initSearchObj = ()=>{
        const searchStr = location?.search.replace("?","");
        const searchArr = searchStr?.split("&");
        const tmpSearchObj = {...searchObj};
        searchArr?.map((item)=>{
            const searchKeyValue = item?.split("=");
            tmpSearchObj[searchKeyValue[0].toLowerCase()] = searchKeyValue[1];
        });
        setSearObj(tmpSearchObj);
    };
    useEffect(() => {
        // init the searchobj, and the value data is form the url
        initSearchObj();
    }, [urlLocation]);

    useEffect(()=>{
        const tempconsHours= localStorage.getItem("consHours");
        const tempOrangeConsDays=localStorage.getItem("orangeConsDays");
        const tempRedConsDays= localStorage.getItem("redConsDays");
        const tempMaxNormalTemperature= localStorage.getItem("maxNormalTemperature");
        setConsHours(!tempconsHours?4:Number(tempconsHours));
        setOrangeConsDays(!tempOrangeConsDays?2:Number(tempOrangeConsDays));
        setRedConsDays(!tempRedConsDays?3:Number(tempRedConsDays));
        setMaxNormalTemperature(!tempMaxNormalTemperature?22:Number(tempMaxNormalTemperature));
    },[]);

    const initPage=()=>{
        getHistTemperatureRisks(
            "London",
            51.50853,
            -0.12574,
            formatDate(defaultStartDate),
            formatDate(defaultEndDate),
            maxNormalTemperature,
            consHours,
            orangeConsDays,
            redConsDays,
            false
        );
    };
    useEffect(() => {
        getStressEvent();
        initPage();
    }, []);
    useEffect(() => {
        getStressEvent();
    }, [levelData]);

    //stressEventData Details
    interface ICategory1 {
        value: number,
    }
    interface ICategory2 {
        time: string,
    }
    interface ICategory3 {
        Latitude:string,
        Longitude: string,
        placeName: string,
    }
    const [levelDatas,setLevelDatas]=useState([]);
    const [timeDatas,settimeDatas]=useState([]);

    const [valueDatas,setValueDatas]=useState([]);
    const [rawDataTime,setRawDataTime]=useState([]);

    const getStressEvent =async()=>{
        const tmpArray1: ICategory1[] = [];
        const tmpArray2: ICategory2[] = [];
        const tmpArray3: ICategory1[] = [];
        const tmpArray4: ICategory2[] = [];
        levelData?.map((item) => {
            tmpArray1.push({
                value: item.value,
            });
            tmpArray2.push({
                time: formatDate(item.time?.substring(0,10))
            });
        });
        rawData?.map((item) => {
            tmpArray3.push({
                value: item.value,
            });
            tmpArray4.push({
                time: formatTime(item.time)
            });
        });
        setLevelDatas(tmpArray1);
        settimeDatas(tmpArray2);
        setValueDatas(tmpArray3);
        setRawDataTime(tmpArray4);
    };

    const stressEventOption = {
        tooltip: {
            formatter: function (obj) {
                // console.log("obj is:",obj);
                if (obj.length > 0) {
                    let r = "";
                    r += "Time: " + obj[0].name;
                    for (let i = 0; i < obj.length; i++) {
                        r += "</br>" + "Level" + ": " + obj[i].value;
                    }
                    return r;
                }
                else
                    return "";
            },
            trigger: "axis"
        },
        grid: {
            show:true,
            top:"2%",
            left: "3%",
            right: "2%",
            bottom: "18%",
            containLabel: true,
            borderColor:"#DFE2E7",
        },
        xAxis: {
            type: "category",
            boundaryGap: false,
            splitLine: {
                show: true,
                interval:0,
                lineStyle: {
                    type: "solid",
                    color: "#DFE2E7",
                    width: 1.5
                },
            },
            data: timeDatas?.map( (item) =>{
                //console.log("33333333333:",item.time);
                return item.time;
            })
        },
        yAxis: {
            type: "value",
            min: 1,
            max: 5,
            interval: 2,
            axisLabel: {
                formatter: (value: number) => {
                    //console.log("yAxis value is:",value);
                    if (value === 1) {
                        return "No Stress";
                    } else if (value === 3) {
                        return "Pre Stress";
                    } else if (value === 5) {
                        return "Stress Event";
                    } else {
                        return "";
                    }
                },
                fontSize:"10px",
                fontFamily:"Noto-Sans-Regular",
                fontWeight:400,
                verticalAlign: "middle",
            },
            splitLine: {
                show: true,
                interval: 0.5,
                lineStyle: {
                    type: "dashed",
                    color: "#A3A9B9"
                }
            },
            data: [1,3,5]

        },
        dataZoom: [
            {
                type: "slider",
                backgroundColor: "#F2F5F9",
                fillerColor: "#0092E4",
                height: 8,
                handleSize: "200%",
                right: 100,
                left: 100,
                bottom: 15,
                moveHandleSize: 0,
                handleIcon: "image://"+Circle ,
                handleStyle: {
                    color: "#fff",
                    borderColor:"#0092E4",
                    borderType :"solid",
                    opacity:1,
                },
            }
        ],
        series: [
            {
                type: "scatter",
                data: levelDatas?.map( (item) =>{
                    return item.value;
                }),
                markLine: {
                    silent: true,
                    lineStyle: {
                        type: "dotted",
                        color: "#333"
                    },
                },
                itemStyle: {
                    color: function (value) {
                        //console.log("value.data is:",value.data);
                        if (value.data === 1) {
                            return "#19A04B";
                        } else if (value.data=== 3) {
                            return "#E99921";
                        } else if (value.data === 5) {
                            return "#CF3537";
                        } else {
                            return "";
                        }
                    }
                },
            }
        ]
    };
    const soilTemperatureOption = {
        title: {
            text: "℃",
            left:20,
            // top:20,
            textStyle:{
                fontStyle:"normal",
                fontSize:"16px"
            }
        },
        grid: {
            show:true,
            top: 40,
            left: "3%",
            right: "2%",
            bottom: "18%",
            containLabel: true,
            borderColor:"#DFE2E7",
        },
        tooltip: {
            formatter: function (obj) {
                console.log("ob33333j is:",obj);
                if (obj.length > 0) {
                    let r = "";
                    r += "Time: " + obj[0].name;
                    for (let i = 0; i < obj.length; i++) {
                        r += "</br>" + "Temperature: " + ": " + obj[i].value + " ℃";
                    }
                    return r;
                }
                else
                    return "";
            },
            trigger: "axis"
        },
        xAxis: {
            type: "category",
            boundaryGap: false,
            data: rawDataTime?.map( (item) =>{
                return item.time;
            }),
            axisLabel: {
                formatter: function (value, index) {
                    return value.substring(0,10);
                }
            }
        },
        yAxis: {
            type: "value",
            splitLine: {
                show: true,
                interval: 0.5,
                lineStyle: {
                    type: "dashed",
                    color: "#A3A9B9"
                }
            },
        },
        dataZoom: [
            {
                type: "slider",
                backgroundColor: "#F2F5F9",
                fillerColor: "#0092E4",
                height: 8,
                handleSize: "200%",
                right: 100,
                left: 100,
                bottom: 15,
                //start:0,
                //end:13,
                moveHandleSize: 0,
                handleIcon: "image://"+Circle ,
                handleStyle: {
                    color: "#fff",
                    borderColor:"#0092E4",
                    borderType :"solid",
                    opacity:1,
                },
            }
        ],
        series: [
            {
                type: "line",
                smooth: true,
                symbol: "none",
                areaStyle: {
                    opacity: 0.3,
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        {
                            offset: 0,
                            color: "#0092E4"
                        },
                        {
                            offset: 1,
                            color: "rgba(255, 255, 255, 0)"
                        }
                    ])
                },
                lineStyle: {
                    color:"#0092E4",
                    width: 2
                },
                data: valueDatas?.map( (item) =>{
                    return item.value;
                }),
            },
        ]
    };

    //modal open
    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };
    //click Save button function
    const handleOk = () => {
        getHistTemperatureRisks(
            placeName,
            latitude,
            longitude,
            !startDate?formatDate(defaultStartDate):startDate,
            !endDate?formatDate(defaultEndDate):endDate,
            maxNormalTemperature,
            consHours,
            orangeConsDays,
            redConsDays,
            false,
        );
        console.log("33333",maxNormalTemperature);
        console.log("33333",consHours);
        console.log("33333",orangeConsDays);
        console.log("33333",redConsDays);
        localStorage.setItem("maxNormalTemperature",maxNormalTemperature.toString());
        localStorage.setItem("consHours",consHours.toString());
        localStorage.setItem("orangeConsDays",orangeConsDays.toString());
        localStorage.setItem("redConsDays",redConsDays.toString());
        alert("Save successfully!");
        setIsModalOpen(false);
    };
    //click Cancel button function
    const handleCancel = () => {
        setIsModalOpen(false);
        console.log("i am cancel");
    };

    //content change function

    //modal change function
    const maxNormalTemperatureOnChange=(e)=>{
        setMaxNormalTemperature(e);
    };
    const consHoursOnChange=(e)=>{
        setConsHours(e);
    };
    const orangeConsDaysOnChange=(e)=>{
        setOrangeConsDays(e);
    };
    const redConsDaysOnChange=(e)=>{
        console.log("minThresholdValueOnChangeForSW is",e);
        setRedConsDays(e);
    };

    const dateFormatList = ["DD/MM/YYYY", "DD/MM/YY", "DD-MM-YYYY", "DD-MM-YY"];

    const exportToExcel=async()=>{
        console.log("This is exportToExcel");
        getHistTemperatureRisks(
            placeName,
            latitude,
            longitude,
            !startDate?formatDate(defaultStartDate):startDate,
            !endDate?formatDate(defaultEndDate):endDate,
            maxNormalTemperature,
            consHours,
            orangeConsDays,
            redConsDays,
            true,
        );
    };

    //be empty
    const isNull = (obj) =>{
        return obj == null || obj == "" || obj.toString().trim() == "";
    };

    //should be numeric and negative sign(-) can be used before number
    const checkNum =(obj)=>{
        const string=/^[-]?\d+(\.\d+)?$/;
        //console.log("aaaaaaaaaaaa",string.test(obj));
        return string.test(obj);
    };
    //should be numeric and greater than zero
    const checkNumGreaterZero =(obj)=>{
        const string=/^[1-9]*[1-9][0-9]*$/;
        //console.log("ssssssssssssssss",string.test(obj));
        return string.test(obj);
    };

    //Disabled Save button
    const [isDisabledSave,setIsDisabledSave]=useState(false);

    //Check the Input is Valid before Save
    const [isInvalidForMaxNormalTemperature,setIsInvalidForMaxNormalTemperature]=useState(false);
    const maxNormalTemperatureOnBlur=(value)=>{

        if(checkNum(value))
        {
            console.log("I AM NUMBER");
            setIsInvalidForMaxNormalTemperature(false);
            setIsDisabledSave(false);
        }
        else
        {
            setIsInvalidForMaxNormalTemperature(true);
            console.log("I AM not NUMBER");
            setIsDisabledSave(true);
        }

    };

    const [isInvalidForConsHours,setIsInvalidForConsHours]=useState(false);
    const consHoursOnBlur=(value)=>{
        if(checkNumGreaterZero(value))
        {
            console.log("I AM NUMBER");
            setIsInvalidForConsHours(false);
            setIsDisabledSave(false);
        }
        else
        {
            setIsInvalidForConsHours(true);
            console.log("I AM not NUMBER");
            setIsDisabledSave(true);
        }

    };

    //Selected risk type:"Air temperature at emergence period"
    //Check the Input is Valid before Save
    const [isInvalidForOrangeConsDays,setIsInvalidForOrangeConsDays]=useState(false);
    const orangeConsDaysOnBlur=(value)=>{
        if(checkNumGreaterZero(value))
        {
            console.log("I AM NUMBER");
            setIsInvalidForOrangeConsDays(false);
            setIsDisabledSave(false);
        }
        else
        {
            setIsInvalidForOrangeConsDays(true);
            console.log("I AM not NUMBER");
            setIsDisabledSave(true);
        }

    };
    const [isInvalidForRedConsDays,setIsInvalidForRedConsDays]=useState(false);
    const redConsDaysOnBlur=(value)=>{
        if(checkNumGreaterZero(value))
        {
            console.log("I AM NUMBER");
            setIsInvalidForRedConsDays(false);
            setIsDisabledSave(false);
        }
        else
        {
            setIsInvalidForRedConsDays(true);
            console.log("I AM not NUMBER");
            setIsDisabledSave(true);
        }
    };

    //save image
    const saveStressEventToDataUrl = ()=>{
        const element = document.getElementById("stressEventDiv");
        if(element){
            html2canvas(element,{
                useCORS: true
            }).then((canvas)=>{
                const dataUrl = canvas.toDataURL("image/png");
                //console.log("test images",dataUrl);

                const link = document.createElement("a");
                link.href = dataUrl;
                link.download = "stressEventIdentification.png";
                link.click();
            });
        }
    };
    const saveSoilTemperatureToDataUrl = ()=>{
        const element = document.getElementById("soilTemperatureDiv");
        if(element){
            html2canvas(element,{
                useCORS: true
            }).then((canvas)=>{
                const dataUrl = canvas.toDataURL("image/png");
                //console.log("test images",dataUrl);

                const link = document.createElement("a");
                link.href = dataUrl;
                link.download = "soilTemperature.png";
                link.click();
            });
        }
    };

    const {
        locations,
        bookmarks,
        weatherForcastDailyList,
        localDailyWidgetWeather,
        currentLanguage,
        chartData,
        cropsItems,
        cropsSpray,
        topInfoCardLoading,
        weatherListLoading,
        sprayLoading,
        paramModel,
        weather24HoursData,
        weather8HoursData,
        weatherDayData,
        showAddBookSuccess,
        searchCoordinateResult
    } = useSelector((state: RootState) => state.commonReducer);

    //console.log("11111 locations",locations);

    useEffect(() => {
        if (locations.length > 0 && locations.length !== 1) {
            setLocationListShow(true);
        }
        if (locations.length === 1) {
            setCurrentLocation(locations[0]);
            setPlaceName(locations[0].Name._text);
            setInputText(locations[0].Name._text);
            setLatitude(Number(locations[0].Latitude._text));
            setLongitude(Number(locations[0].Longitude._text));
        }
    }, [locations]);

    useEffect(() => {
        if(currentLocation){
            setInputText(currentLocation?.Name._text);
            getHistTemperatureRisks(
                currentLocation?.Name._text,
                Number(currentLocation?.Latitude._text),
                Number(currentLocation?.Longitude._text),
                !startDate?formatDate(defaultStartDate):startDate,
                !endDate?formatDate(defaultEndDate):endDate,
                maxNormalTemperature,
                consHours,
                orangeConsDays,
                redConsDays,
                false
            );
            setSelectPlaceName("");
        }
    }, [currentLocation]);

    const [selectPlaceName,setSelectPlaceName]=useState("");
    const placeNameOnchange=(e)=>{
        const tmpPlaceName=e.split(",")[0];
        setSelectPlaceName(tmpPlaceName);
        setInputText(tmpPlaceName);
        const tmpLatitude=e.split(",")[1];
        const tmpLongitude=e.split(",")[2];
        getHistTemperatureRisks(
            tmpPlaceName,
            Number(tmpLatitude),
            Number(tmpLongitude),
            !startDate?formatDate(defaultStartDate):startDate,
            !endDate?formatDate(defaultEndDate):endDate,
            maxNormalTemperature,
            consHours,
            orangeConsDays,
            redConsDays,
            false
        );
    };

    const addBookmarkButtonClicked = () => {
        const tmpArray1: ICategory3[] = [];
        tmpArray1.push({
            Latitude:!currentLocation?"51.50853":currentLocation?.Latitude._text,
            Longitude:!currentLocation?"-0.12574":currentLocation?.Longitude._text,
            placeName: !currentLocation?"London":currentLocation?.Name._text
        });
        const tmpArray2=JSON.parse(localStorage.getItem("mybookMarkList"));
        let tmpArray3;
        if(!tmpArray2)//If the storage is empty, store it directly;
            tmpArray3=tmpArray1;
        else// otherwise, merge the storage
            tmpArray3=tmpArray1.concat(tmpArray2);//Merge two array objects
        const newJson = []; //A new array for storing deduplicated data
        let testFlag = false;
        tmpArray3?.map((item1)=>{
            //Establish a marker to determine if the data is duplicated, and true indicates no duplication
            let flag = true;
            newJson?.map((item2)=>{
                //Compare the content of the JSON array object with the content of the new array, and if the same, change the flag to false
                if(item1?.placeName==item2?.placeName){
                    flag = false;
                    testFlag = true;
                }
            });
            if(flag){ //Determine if it is duplicated
                newJson.push(item1); //Place in a new array without repetition. The contents of the new array will continue through the loop above.
            }
        });
        localStorage.setItem("mybookMarkList",JSON.stringify(newJson));
        setBookLists(newJson);
        if(testFlag){
            alert("It is already added.");
        }
        else{
            alert("Added successfully.");
        }

    };

    useEffect(()=>{
        const tmpArray1=JSON.parse(localStorage.getItem("mybookMarkList"));
        setBookLists(tmpArray1);
    },[]);

    const disabledDate: RangePickerProps["disabledDate"] = (current) => {
        // Can not select days after today and today
        return current && current > dayjs().add(-1,"day").endOf("day");
    };

    return (
        <Spin spinning={showLodding}>
            <div className="hist-temperature-risk-all-container">
                <div className={`hist-temperature-risk-container bg-${searchObj.theme}`}
                    id={"hist-temperature-risk-search-id"}
                >
                    {/* Modal Setting */}
                    <Modal className="hist-temperature-risk-modal"
                        title={"Setting"}
                        open={isModalOpen}
                        onOk={handleOk}
                        onCancel={handleCancel}
                        width={755}
                        footer={[
                            <Button key="back" className="modal-footer-cancel-button" onClick={handleCancel}>
                            Cancel and return
                            </Button>,
                            <button key="submit"
                                className={isDisabledSave?"modal-footer-DisabledSave-button": "modal-footer-save-button"}
                                onClick={handleOk}
                                disabled={isDisabledSave}>
                      Save
                            </button>,
                        ]}
                        getContainer = {()=> document.getElementById("hist-temperature-risk-search-id") as HTMLElement}
                    >
                        <div className="cornYieldRisk-modal-content">
                            <div className="setting-details" style={{marginBottom:"20px"}}>
                                <Row>
                                    <Col
                                        xs = {24}
                                        sm = {24}
                                        md = {24}
                                        lg = {24}
                                        xl = {20}
                                        xxl = {20}
                                    >
                                        <div className="leftDiv">
                                            <p>
                                                <span className="pStyle">Boundary Value temperature</span>
                                            </p>
                                            <div className="detailDiv">
                                                <p className="detailP" >( The point on the stress event chart will be marked as
                                                    <GreenCircleIcon/>
                                                 if the temperature is no more than the boundary value )</p>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col
                                        // span={4}
                                        xs = {12}
                                        sm = {12}
                                        md = {6}
                                        lg = {4}
                                        xl = {4}
                                        xxl = {4}
                                    >
                                        <div className="rightDiv">
                                            <Space.Compact size="large">
                                                <Input addonAfter={"℃"} placeholder="23.00"
                                                    value={maxNormalTemperature}
                                                    onBlur={(e)=>{maxNormalTemperatureOnBlur(e.target.value);}}
                                                    onChange={(e)=>{
                                                        maxNormalTemperatureOnChange(e.target.value);
                                                    }}/>
                                            </Space.Compact>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <div className="errorDiv">
                                <IF show={isInvalidForMaxNormalTemperature}>
                                    <p className="errorP" >
                            Temperature should be numeric and negative sign(-) can be used before number, to input minus temperatures</p>
                                </IF>
                            </div>
                            <div className="setting-details">
                                <Row>
                                    <Col
                                        xs = {24}
                                        sm = {24}
                                        md = {24}
                                        lg = {24}
                                        xl = {20}
                                        xxl = {20}
                                    >
                                        <div className="leftDiv">
                                            <p> <span className="pStyle">Number of consecutive hours in day to consider it as a stress event</span>
                                            </p>
                                        </div>
                                    </Col>
                                    <Col
                                    // span={4}
                                        xs = {12}
                                        sm = {12}
                                        md = {6}
                                        lg = {4}
                                        xl = {4}
                                        xxl = {4}
                                    >
                                        <div className="rightDiv">
                                            <Space.Compact size="large">
                                                <Input addonAfter={"days"} placeholder="5"
                                                    value={consHours}
                                                    onBlur={(e)=>{consHoursOnBlur(e.target.value);}}
                                                    onChange={(e)=>{console.log("eeeeeeeeee:",e.target.value);
                                                        consHoursOnChange(e.target.value);
                                                    }}/>
                                            </Space.Compact>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <div className="errorDiv">
                                <IF show={isInvalidForConsHours}>
                                    <p className="errorP" >
                            Hours and Days should be numeric and greater than zero ({">"}0).</p>
                                </IF>
                            </div>
                            <div className="setting-details">
                                <Row>
                                    <Col
                                    //  span={20}
                                        xs = {24}
                                        sm = {24}
                                        md = {24}
                                        lg = {24}
                                        xl = {20}
                                        xxl = {20}
                                    >
                                        <div className="leftDiv">
                                            <p>
                                                <span className="pStyle">Number of consecutive days to consider it as a pre-stress event <OrangeCircleIcon/></span>
                                            </p>
                                        </div>
                                    </Col>
                                    <Col
                                        // span={4}
                                        xs = {12}
                                        sm = {12}
                                        md = {6}
                                        lg = {4}
                                        xl = {4}
                                        xxl = {4}
                                    >
                                        <div className="rightDiv">
                                            <Space.Compact size="large">
                                                <Input addonAfter={"days"} placeholder="5"
                                                    value={orangeConsDays}
                                                    onBlur={(e)=>{orangeConsDaysOnBlur(e.target.value);}}
                                                    onChange={(e)=>{console.log("eeeeeeeeee:",e.target.value);
                                                        orangeConsDaysOnChange(e.target.value);
                                                    }}/>
                                            </Space.Compact>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <div className="errorDiv">
                                <IF show={isInvalidForOrangeConsDays}>
                                    <p className="errorP" >
                            Hours and Days should be numeric and greater than zero ({">"}0).</p>
                                </IF>
                            </div>
                            <div className="setting-details">
                                <Row>
                                    <Col
                                        xs = {24}
                                        sm = {24}
                                        md = {24}
                                        lg = {24}
                                        xl = {20}
                                        xxl = {20}
                                    >
                                        <div className="leftDiv">
                                            <p>
                                                <span className="pStyle">Number of consecutive days to consider it as a stress event <RedCircleIcon/></span>
                                            </p>
                                        </div>
                                    </Col>
                                    <Col
                                        xs = {12}
                                        sm = {12}
                                        md = {6}
                                        lg = {4}
                                        xl = {4}
                                        xxl = {4}
                                    >
                                        <div className="rightDiv">
                                            <Space.Compact size="large">
                                                <Input addonAfter={"days"} placeholder="5"
                                                    value={redConsDays}
                                                    onBlur={(e)=>{redConsDaysOnBlur(e.target.value);}}
                                                    onChange={(e)=>{console.log("eeeeeeeeee:",e.target.value);
                                                        redConsDaysOnChange(e.target.value);
                                                    }}/>
                                            </Space.Compact>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <div className="errorDiv">
                                <IF show={isInvalidForRedConsDays}>
                                    <p className="errorP" >
                            Hours and Days should be numeric and greater than zero ({">"}0).</p>
                                </IF>
                            </div>
                        </div>

                    </Modal>
                    {/* Modal location */}
                    <Modal
                        title={(
                            <label
                                style={{ fontSize: "22px", color: "green" }}
                            >
                        Search Location
                            </label>
                        )}
                        open={locationListShow}
                        onCancel={() => setLocationListShow(false)}
                        style={{
                            minWidth: "50%"
                        }}
                        footer={null}
                        destroyOnClose={true}
                        getContainer = {()=> document.getElementById("hist-temperature-risk-search-id") as HTMLElement}
                        className="hist-temperature-risk-search-modal"
                    >
                        <List
                            dataSource={locations}
                            style={{
                                height: "500px",
                                overflow: "auto",
                                borderTop: "1px solid lightgray",
                            }}
                            renderItem={(item: ILocationModel) => {
                                return (
                                    <div
                                        className="result-item"
                                    >
                                        <label
                                            onClick={() => {
                                                setCurrentLocation(item);
                                                setLocationListShow(false);
                                                setInputText(item.AdminName._text.split(",")[0]);
                                            }}
                                            style={{
                                                color: "#337ab7",
                                                fontSize: "20px"
                                            }}
                                        >
                                            {item.AdminName._text}
                                        </label>
                                    </div>
                                );
                            } } />
                    </Modal>
                    <Modal
                        title="Warning"
                        open={searchCoordinateResult}
                        onCancel={() => {
                            dispatch(searchTargetCoordinate(false));
                        }}
                        onOk={() => {
                            dispatch(searchTargetCoordinate(false));
                        }}
                    >
                        <label>{currentLanguage.No_place_found}</label>
                    </Modal>
                    {/*container */}
                    <Row>
                        <Col
                            // span={6}
                            xs = {24}
                            sm = {24}
                            md = {24}
                            lg = {24}
                            xl = {6}
                            xxl = {6}

                        >
                            <div className="leftContent">
                                <div className="head-title">
                                Historical Temperature Risk
                                </div>
                                <div className="leftContent-item-container">
                                    <div className="ag-search-div">
                                        <span className="search-title">
                                    Select your location to see historical weather
                                        </span>
                                        <Search
                                            onChange={(text: string) => setInputText(text)}
                                            value={inputText}
                                            placeholder="Location name or long,lat"
                                            searchButtonClick={() => {
                                                const strArr = inputText.split(",");
                                                if (strArr.length === 2) {
                                                    const longitude = parseFloat(strArr[0]);
                                                    const latitude = parseFloat(strArr[1]);
                                                    if (typeof(latitude) === "number" && typeof(longitude) === "number") {
                                                        dispatch({
                                                            type: sagaActionTypes.SAGA_COORDINATE_SEARCH,
                                                            payload: {searchText:`${longitude},${latitude}`}

                                                        });
                                                    }
                                                } else {
                                                    dispatch({
                                                        type: sagaActionTypes.SAGA_COORDINATE_SEARCH,
                                                        payload: {searchText: `${inputText},${currentCountryCode}`}
                                                    });
                                                }
                                            } }
                                            locationButtonClicked={() => coordinateButtonClicked()} />
                                    </div>
                                    <div className="ag-bookmark">
                                        <label className="bookmark-title">
                                    Select from your saved bookmarks
                                        </label>
                                        <div className="select-div">
                                            <Select
                                                className="select"
                                                showSearch
                                                placeholder="My Bookmarks"
                                                size="large"
                                                value={selectPlaceName}
                                                onChange={(e) => {
                                                    placeNameOnchange(e);
                                                }}
                                            >
                                                {
                                                    bookLists?.map((item) => {
                                                        return (
                                                            <option key={item.placeName+","+item.Latitude+","+item.Longitude}>{item.placeName}</option>
                                                        );
                                                    })
                                                } </Select>
                                            <button className="bookmark-button" onClick={addBookmarkButtonClicked}>
                                                <BookMarkIcon />
                                            </button>
                                        </div>
                                    </div>
                                    <div className="ag-date">
                                        <label className="bookmark-title theme-title-color">
                                    Select your start and end date
                                        </label>
                                        <div className="select-div">
                                            <RangePicker
                                                defaultValue={[defaultStartDate,defaultEndDate]}
                                                format="DD/MM/YYYY"
                                                disabledDate={disabledDate}
                                                onChange={dateOnChange}
                                                onOk={dateOnOk}
                                            />
                                        </div>
                                    </div>
                                    <div className="bottom-div">
                                        <button onClick={exportToExcel}>Export to Excel</button>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        {/* <Col span={1}></Col> */}
                        <Col
                            // span={17}
                            xs = {24}
                            sm = {24}
                            md = {24}
                            lg = {24}
                            xl = {17}
                            xxl = {17}
                        >
                            <div className="rightContent">
                                <div className="circle-round-div">
                                    <div className="circle-bottom-div">
                                        <div className="circle-left-div">
                                            <label>
                                            Stress Event Identification
                                            </label>
                                        </div>
                                        <div className="circle-middle-div" >
                                            <div className="setting-div">
                                                <span onClick={showModal}><ManageSettingsIcon />
                                                    <label>
                                                    Manage Settings
                                                    </label>
                                                </span>
                                            </div>
                                            <div className="download-div"
                                                onClick={()=>{
                                                    saveStressEventToDataUrl();
                                                }}>
                                                <DownloadAsImageIcon/>
                                                <label>
                                                Download as Image
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="circle-content" id="stressEventDiv">
                                        <ReactEcharts
                                            option={stressEventOption}
                                            style={{height: "300px", width: "90%"}}
                                            notMerge
                                            lazyUpdate />
                                    </div>
                                    <div className="circle-bottom-div">
                                        <div className="circle-left-div"
                                        >
                                            <div className="download-div">
                                                <p className="slideText">
                                                Slide to zoom in on the timeline
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="circle-round-div">
                                    <div className="circle-bottom-div">
                                        <div className="circle-left-div">
                                            <label>
                                            Temperature
                                                <span style={{marginLeft:"5px",marginRight:"5px"}}>-</span>
                                                {!currentLocation?"London":currentLocation?.Name._text}
                                            </label>
                                        </div>
                                        <div className="circle-middle-div"
                                            onClick={()=>{
                                                saveSoilTemperatureToDataUrl();
                                            }}>
                                            <div className="download-div">
                                                <DownloadAsImageIcon/>
                                                <label>
                                                Download as Image
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="circle-content" id="soilTemperatureDiv">
                                        <ReactEcharts
                                            option={soilTemperatureOption}
                                            // style={{height: "300px", width: "90%",marginTop:"-35px"}}
                                            style={{height: "300px", width: "90%"}}
                                            notMerge
                                            lazyUpdate />

                                    </div>
                                    <div className="circle-bottom-div">
                                        <div className="circle-left-div">
                                            <div className="download-div">
                                                <p className="slideText">
                                                Slide to zoom in on the timeline
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>

        </Spin>
    );
};

export default HistTemperatureRisk;
