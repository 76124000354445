import React, { useEffect, useState } from "react";
import "./index.less";
import PrivacyNotice from "./components/PrivacyNoticeComponents";
import {Select,
    Image,
    Modal,
    message,
    notification,
    Popconfirm,
    Input,
    Space,
    Row,
    Col,
    List,
    Radio,
    Button,} from "antd";
import { AimOutlined, EnvironmentOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/redux/reducer/store";
import { useLocation, useNavigate } from "react-router-dom";
import { normalActionTypes, sagaActionTypes } from "@/redux/actions/quantisAction";
import {getFields,addFieldToBookMark} from "@/util/http/Quantis/index";
import store from "@/util/store";
const { Search } = Input;
const { Option } = Select;
import SettingModal from "./components/SettingModal/settingModal";
import dayjs from "dayjs";
import NozzleImageComponents from "./components/NozzleImageComponents/nozzleImageComponents";
import LegendAndDescription from "./components/LegendAndDescriptionComponents";
import LocationSearchModal from "./components/LocationSearchModal";
import GoogleMapComponents from "./components/GoogleMapComponents";
import MapAddFieldModal from "./components/MapAddFieldModal";
import TestGoogleMap from "./components/GoogleMapComponents/testGoogleMap";
import TestGoogleMapB from "./components/GoogleMapComponents/testGoogleMapB";
import TestGoogleMapD from "./components/GoogleMapComponents/testGoogleMapD";
import { getDayOfWeek } from "@/util/quantisCommonFunction";
import UpdateOrDeleteField from "./components/UpdateOrDeleteFieldModal";

// init data
const forecastDayOptions:any[] = [];
for(let i = 1; i < 15; i++){
    forecastDayOptions.push(
        <Option key={"foreactDay-option" + i} value={i}>{i}</Option>
    );
}
let initDataCount = 0;
const Main = ()=>{
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const urlLocation = useLocation();

    const {
        allCrop,
        allCropFromFields,
        allFields,
        getAllFieldsStatus,
        allBookMarkFields,
        mainPageFirstGetFieldFlag,
        setting,
        currentGetWeatherData,
        placeName,
        geoLocationListData,
        getWeatherAfterAddOrUpdateSettingFlag,
        currentMapCenter,
        deleteFieldStatus,
        mainInitDataCount
    } = useSelector((state: RootState) => state.quantisReducer);
    console.log("main init data allCrop",allCrop);
    console.log("main init data allFields",allFields);
    console.log("main init data allBookMarkFields",allBookMarkFields);
    console.log("main init data setting",setting);
    console.log("main init data currentGetWeather",currentGetWeatherData);
    console.log("main init data currentMapCenter",currentMapCenter);
    console.log("main init data mainInitDataCount",mainInitDataCount);

    /**
     * Location search and manage bookmark
     */
    const [searchCityName,setSearchCityName] = useState<string>(null);
    const [bookMarkValue,setBookMarkValue] = useState(null);
    const [showLocationSearchModal,setShowLocationSearchModal] = useState(false);
    const childChangeShowLocationSearchModalCallback = ()=>{
        setShowLocationSearchModal(false);
    };
    const childClickGeonamesLocationDataItemCallback = (placeName:string,latitude:string,longitude:string)=>{
        setSearchCityName(placeName);
        setShowSearchCityName(placeName);
        /**
         * after click the detail location item , should switch map center
         */
        // dispatch({
        //     type: normalActionTypes.NORMAL_UPDATE_CURRENTMAPCENTER,
        //     payload: {
        //         latitude: Number(latitude),
        //         longitude: Number(longitude),
        //         zoom: 8,
        //         refreshClick: true
        //     }
        // });
        // console.log(`main init data currentMapCenter, lat is ${latitude}, long is ${longitude}`);
        // setCropValue("");
        // setFieldValue("");
        // setBookMarkValue("");
        // get weather by new latitude and longtidue
        getWeatherData(latitude,longitude,true);

        console.log(`callback value: latitude is ${latitude}, longitude is ${longitude}`);
    };
    // this function will invoke when we click the search button
    const onSearchButtonClick = ()=>{
        /**
         * we use searchCityName value, because searchCityName will be change by Search's onChange function
         * when we invoke this function, will request GetGeonamesLocationData api
         * and will update below data which in redux
         *      geoLocationListData
         *      placeName
         */
        dispatch({
            type: sagaActionTypes.SAGA_GET_GETGEONAMESLOCATIONDATA,
            payload: searchCityName
        });
        setShowLocationSearchModal(true);
    };
    const onSerarchValueChange = (value?:string)=>{
        setSearchCityName(value);
    };
    // this function is get the Location by chrome, will invoke when no bookmark and cityDataSelect and auto click the icon
    const getCurrentLocation = () => {
        if (navigator.geolocation) {
            // the callback function id init, but don't invoke, so use below
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    /**
                     * mean get current location's lat and long
                     */
                    // get weather data
                    getWeatherData(position.coords.latitude?.toString(),position.coords.longitude?.toString(),true);
                    // get new location name
                    dispatch({
                        type: sagaActionTypes.SAGA_GET_PLACANAMEBYLATANDLONG,
                        payload: {
                            latitude: position.coords.latitude?.toString(),
                            longitude: position.coords.longitude?.toString()
                        }
                    });
                    // dispatch({
                    //     type: normalActionTypes.NORMAL_UPDATE_CURRENTMAPCENTER,
                    //     payload: {
                    //         latitude: position.coords.latitude,
                    //         longitude: position.coords.longitude,
                    //         zoom: 8,
                    //         refreshClick: true
                    //     }
                    // });
                    // if (cropValue != null || cropValue != "") {
                    //     // setCropValue("");
                    //     setFieldValue("");
                    //     setBookMarkValue("");
                    // }
                },
                (error: any) => {
                    switch (error.code) {
                        case error.PERMISSION_DENIED:
                            alert("You declined your request for a geographic location");
                            break;
                        case error.POSITION_UNAVAILABLE:
                            alert("Location information is not available");
                            break;
                        case error.TIMEOUT:
                            alert("The request for your geographic location timed out");
                            break;
                        case error.UNKNOWN_ERROR:
                            alert("unknown error");
                            break;
                    }
                },
            );
        } else {
            alert("Getting your location using HTML browser 5 is not supported");
        }
    };
    const handleBookMarkOptionChange = (bookmarkFieldName?:string)=>{
        console.log("main currentChangeBookMarkOption",bookmarkFieldName);
        /**
         * when change boomkark be option change,
         * we should change crop, field, forecast
         * all data can get from allBookmarkFields array
         */
        // const tmpField = allBookMarkFields?.filter(item => item?.crop === cropValue && item?.fieldName === bookmarkFieldName)[0];
        const tmpField = allBookMarkFields?.filter(item => item?.fieldName === bookmarkFieldName)[0];
        if(tmpField?.crop){
            setCropValue(tmpField?.crop);
            setFieldValue(tmpField?.fieldName);
            setForecastDayValue(tmpField?.forecastDays);
        }
        setBookMarkValue(bookmarkFieldName);
    };
    // TODO: 大致写完了，还有几个情况没测，添加已经存在的已经成功了，其它的还没有测试
    const addBookBook = async()=>{
        let canAddToBookMark = false;
        let tmpLatitude = null;
        let tmpLongitude = null;
        if(cropValue && fieldValue){
            // cropValue and fieldValue must exist
            if(allBookMarkFields.length === 0){
                /**
                 * if allBookMarkField is empty, current field can direct add to bookmark
                 */
                const tmpField = allFields?.filter(item => item?.crop === cropValue
                    && item?.fieldName === fieldValue)[0];
                tmpLatitude = tmpField.latitude;
                tmpLongitude = tmpField.longitude;
                canAddToBookMark = true;
            }
            else{
                /**
                 * if allBookMarkFields is not empty
                 * we should check current select fieldName whether exist in allBookMarkFields
                 *      if not exist, we can add it to allBookMarkFields
                 *      if exist, we should check it's isBookMark properties
                 *              if isBookMark is Y, mean this fieldName is been marked, we should show error message
                 *              if isBookMark is N, mean we can add this fieldName to allBookMarkFields
                 */
                const tmpCurrentBookMarkField = allBookMarkFields?.filter(item => item?.crop === cropValue
                    && item?.fieldName === fieldValue)[0];
                if(!tmpCurrentBookMarkField){
                    // mean current field not in allBookMarkFields, we can add
                    const tmpAllField = allFields?.filter(item => item?.crop === cropValue
                        && item?.fieldName === fieldValue)[0];
                    tmpLatitude = tmpAllField.latitude;
                    tmpLongitude = tmpAllField.longitude;
                    canAddToBookMark = true;
                }
                else{
                    // mean current field is in allBookMarkFields, we should check isBookMark properttis
                    if(tmpCurrentBookMarkField.isBookMark === "Y"){
                        dispatch({
                            type: normalActionTypes.NORMAL_UPDATE_SHOWERRORMESSAGE,
                            payload: "Current crop and field has been marked."
                        });
                    }
                    else if(tmpCurrentBookMarkField.isBookMark === "N"){
                        tmpLatitude = tmpCurrentBookMarkField.latitude;
                        tmpLongitude = tmpCurrentBookMarkField.longitude;
                        canAddToBookMark = true;
                    }
                }
            }
        }
        else{
            dispatch({
                type: normalActionTypes.NORMAL_UPDATE_SHOWERRORMESSAGE,
                payload: "Before add bookmark, please confirm that you have selected the crop and field"
            });
        }
        if(canAddToBookMark){
            const res = await addFieldToBookMark({
                crop: cropValue,
                fieldName: fieldValue,
                latitude: tmpLatitude,
                longitude: tmpLongitude
            });
            if(res.data === "Success"){
                dispatch({
                    type: normalActionTypes.NORMAL_UPDATE_SHOWSUCCESSMESSAGE,
                    payload: "AddBookMark successfully"
                });
                /**
                 * because we add a field, so we need invoke getFields angin
                 *       to update allBookMarkFields to redux
                 */
                dispatch({
                    type: sagaActionTypes.SAGA_GET_FIELDS,
                    payload: null
                });
                setBookMarkValue(fieldValue);
            }
        }
    };

    /**
     * crop, field and forecast day conatiner
     * and should show location name, day
     */
    const [showSearchCityName,setShowSearchCityName] = useState("");
    const [cropValue,setCropValue] = useState("Potato");
    // const [cropValue,setCropValue] = useState(null);
    const [fieldValue,setFieldValue] = useState(null);
    const [forecastDayValue,setForecastDayValue] = useState<number>(5);

    const handlleCropSelectOptionChange = (cropValue?:string)=>{
        /**
         * when change crop, whoud change related fieldName
         * and bookmark value should set to empty
         * but do not change forecast day value, i think is error
         *
         * final, will change search and show city name
         * main/getGeonamesSelectDATA， main/getPlaceName
         */
        // when change crop, should change related fieldname, forecastday,
        let tmpFieldValue = null;
        const tmpBookmarkValue = null;
        let tmpForecastDayValue = 5;
        if(allFields?.length >= 1){
            const tmpCropArray = allFields?.filter(item => item?.crop === cropValue);
            console.log("lanxue tmpCropArray",tmpCropArray);
            if(tmpCropArray.length >= 1){
                const {fieldName,forecastDays} = tmpCropArray.slice(-1)[0]; // get the last item value
                tmpFieldValue = fieldName;
                tmpForecastDayValue = forecastDays;
            }
        }
        setCropValue(cropValue);
        setFieldValue(tmpFieldValue);
        setBookMarkValue(tmpBookmarkValue); // when change crop or field by option change, should set bookmark to empty
    };
    const handleFieldSelectOptionChange = (fieldNameValue?:string)=>{
        /**
         * when invoke this function, mean fieldName change
         * but crop should not change, because fieldName is filter by crop
         * bookmarkValue should set to empty
         * forecast should change, we should get by filter
         */
        const tmpField = allFields?.filter(item => item?.crop === cropValue && item?.fieldName === fieldNameValue)[0];
        if(tmpField?.forecastDays){
            setForecastDayValue(tmpField?.forecastDays);
        }
        setFieldValue(fieldNameValue);
        setBookMarkValue(null);
    };

    const handleForecastSelectOptionChange = (forecastValue?:number)=>{
        setForecastDayValue(forecastValue);
    };

    /**
     * main setting
     */
    const [showSettingModal,setShowSettingModal] = useState(false);
    const settingButtonClick = ()=>{
        console.log("cliak setting Button");
        setShowSettingModal(true);
    };
    const childChangeShowSettingModalCallback = ()=>{
        setShowSettingModal(false);
    };

    /**
     * this function is used to get weather data, will render weather chart
     * it will invoke by below value change
     *      first: field change, because it can get cropValue, fieldName, forecast value information
     *      second: forecast change, because weather data is depend on it
     *      third: setting change in setting modal(update or add setting), because api will used setting value
     */
    const getWeatherData = (latitude?:string,longitude?:string,refreshClick?:boolean)=>{
        if(cropValue && forecastDayValue){
            // if(fieldValue && forecastDayValue){
            // const tmpField = allBookMarkFields?.filter(item => item?.crop === cropValue && item?.fieldName === fieldValue)[0];
            const tmpField = allFields?.filter(item => item?.crop === cropValue && item?.fieldName === fieldValue)[0];
            const tmpLatitude = latitude? latitude : tmpField?.latitude;
            const tmpLongitude = longitude? longitude : tmpField?.longitude;
            const tmpRefleshClick = refreshClick === true ? true : false;
            const tmpZoom = refreshClick === true ? 8 : tmpField?.mapZoom;
            // change Map center
            dispatch({
                type: normalActionTypes.NORMAL_UPDATE_CURRENTMAPCENTER,
                payload: {
                    latitude: Number(tmpLatitude),
                    longitude: Number(tmpLongitude),
                    zoom: tmpZoom,
                    refreshClick: tmpRefleshClick
                }
            });
            dispatch({
                type: sagaActionTypes.SAGA_GET_WEATHERDATA,
                payload: {
                    crop: tmpField?.crop || "Potato",
                    latitude: tmpLatitude, //"25.11103410212362"
                    longitude: tmpLongitude, // "102.60503349121092"
                    showDays: forecastDayValue || 5,
                    startDate: dayjs().format("YYYY-MM-DD"),
                    endDate: dayjs().add( forecastDayValue ? forecastDayValue -1 : 5 - 1 ,"day").format("YYYY-MM-DD"),
                }
            });
        }
    };

    /**
     * used for Google Map
     */
    const [showUpdateOrDeleteFieldModal,setShowUpdateOrDeleteFieldModal] = useState(false);
    const [showMapAddFieldModal,setShowMapAddFieldModal] = useState(false);
    const childChangeShowUpdateOrDeleteFieldModalCallback = (flag:boolean)=>{
        setShowUpdateOrDeleteFieldModal(flag);
    };
    const childChangeShowMapAddFieldModalCallback = (flag:boolean)=>{
        setShowMapAddFieldModal(flag);
    };
    const childChangeCropAndFieldNameAndBookMarkValueCallback = (crop:string, fieldName:string, bookmarkFieldName?:string, foreactDay?:number)=>{
        setCropValue(crop);
        setFieldValue(fieldName);
        setBookMarkValue(bookmarkFieldName);
        if(foreactDay){
            setForecastDayValue(foreactDay);
        }
    };

    /**
     * get init data
     */
    const setInitCropAndFieldFromAllFieldOrAllBookMarkField = ()=>{
        let tmpCropValue = null;
        let tmpFieldValue = null;
        let tmpBookmarkValue = null;
        let tmpForecastDayValue = 5;
        if(allBookMarkFields?.length >= 1){
            const {crop, fieldName,forecastDays} = allBookMarkFields.slice(-1)[0]; // get the array last item
            tmpCropValue = crop;
            tmpFieldValue = fieldName;
            tmpBookmarkValue = fieldName;
            tmpForecastDayValue = forecastDays;
        }
        else if(allFields?.length >= 1){
            const {crop, fieldName,forecastDays} = allFields.slice(-1)[0]; // get the array last item
            tmpCropValue = crop;
            tmpFieldValue = fieldName;
            // tmpBookmarkValue = fieldName;
            tmpBookmarkValue = null;
            tmpForecastDayValue = forecastDays;
        }
        else{
            // invoke currentLocation();
            getCurrentLocation();
            return;
        }
        setCropValue(tmpCropValue);
        setFieldValue(tmpFieldValue);
        setBookMarkValue(tmpBookmarkValue);
        setForecastDayValue(tmpForecastDayValue);
        // alert("ddd");
    };
    useEffect(()=>{
        // get all fields
        dispatch({
            type: sagaActionTypes.SAGA_GET_FIELDS,
            payload: true
        });
        if(store.get("quantis-token")){
            // get all Crops，when token exist, should invoke
            dispatch({
                type: sagaActionTypes.SAGA_GET_ALLCROP,
                payload: null
            });
        }
        // mean we had see this page, and after we visit other page, back to main page
        console.log(`start change: mainPageFirstGetFieldFlag is ${mainPageFirstGetFieldFlag}, mainInitDataCount is ${mainInitDataCount} `);

    },[urlLocation]);
    useEffect(()=>{
        if(getAllFieldsStatus){
            if(mainInitDataCount === 1){
                // initDataCount++;
                console.log("start change invoke");
                setInitCropAndFieldFromAllFieldOrAllBookMarkField();
                dispatch({
                    type: normalActionTypes.NORMAL_UPDATE_GETALLFIELDSSTATUS,
                    payload: false
                });
            }
        }
    },[getAllFieldsStatus]);
    useEffect(()=>{
        /**
         * this is the init method,
         * first check the bookmark value, when have value, show the last bookmark
         * when no bookmark, check the last filedname, and last check the crop value
         *
         * old funciton : initCropOrFieldnameOrBookmarkForShow, logic is set in here
         * in old page, deleteField function will invoke initCropOrFieldnameOrBookmarkForShow
         * when i modify, i will invoke related saga in the deleteField function
         */
        if(mainPageFirstGetFieldFlag && initDataCount === 0){
            initDataCount++;
            dispatch({
                type: normalActionTypes.NORMAL_UPDATE_MAININITDATACOUNT,
                payload: 1
            });
            setInitCropAndFieldFromAllFieldOrAllBookMarkField();
        }
    },[allFields,allBookMarkFields,mainPageFirstGetFieldFlag]);

    useEffect(()=>{
        return ()=>{
            dispatch({
                type: normalActionTypes.NORMAL_UPDATE_MAINPAGEFIRSTGETFIELDFLAG,
                payload: false
            });
        };
    },[]);

    useEffect(()=>{
        if(deleteFieldStatus === "Success"){
            dispatch({
                type: normalActionTypes.NORMAL_UPDATE_SHOWSUCCESSMESSAGE,
                payload: "Delete successfully"
            });
            setInitCropAndFieldFromAllFieldOrAllBookMarkField();
            dispatch({
                type: normalActionTypes.NORMAL_UPDATE_DELETEFIELDSTATUS,
                payload: null
            });
        }
        if(deleteFieldStatus === "Faild"){
            dispatch({
                type: normalActionTypes.NORMAL_UPDATE_DELETEFIELDSTATUS,
                payload: null
            });
        }
    },[deleteFieldStatus]);

    useEffect(()=>{
        /**
         * when change cropvalue, should change related fieldValue
         * TODO: should get new setting
         */
        if(cropValue){
            dispatch({
                type: sagaActionTypes.SAGA_GET_SETTING,
                payload: cropValue
            });
        }
    },[cropValue]);
    useEffect(()=>{
        getWeatherData();
        if(getWeatherAfterAddOrUpdateSettingFlag){
            dispatch({
                type: normalActionTypes.NORMAL_UPDATE_GETWEATHERAFTERADDORUPDATESETTING,
                payload: false
            });
        }
    },[fieldValue,forecastDayValue,getWeatherAfterAddOrUpdateSettingFlag]);


    useEffect(()=>{
        // when have placeName, we need change sarch
        if(placeName){
            setSearchCityName(placeName);
            setShowSearchCityName(placeName);
        }
    },[placeName]);


    return(
        <div className="main-container">
            {/* Location and AddBookMark  */}
            <div className="location-bookmark-container cantainer-base-style-color">
                <div className="location-bookmark-title">Select your location</div>
                <Row style={{ paddingBottom: "10px" }} gutter={[10, 10]}>
                    <Col md={8} sm={16} xs={24}>
                        <Search
                            value={searchCityName}
                            onChange={(e)=>{onSerarchValueChange(e.target.value);}}
                            placeholder="input search text"
                            onSearch={(e) => onSearchButtonClick()}
                            style={{ width: "calc(100% - 36px )" }}
                        />
                        <Button icon={<AimOutlined />} onClick={getCurrentLocation} />
                    </Col>
                    <Col className="textR Jflex" md={16} sm={16} xs={24}>
                        <span
                            onClick={addBookBook}
                            className="textFont"
                            style={{
                                padding: "0 10px 0 0",
                                lineHeight: "32px",
                                cursor: "pointer",
                                color:"white"
                            }}
                        >
                            Add bookmark
                        </span>
                        <Select
                            className="floatL"
                            // dropdownMatchSelectWidth={false}
                            value={bookMarkValue === "" ? undefined : bookMarkValue}
                            onChange={(e)=>{handleBookMarkOptionChange(e);}}
                            style={{ width: 200, textAlign: "left" }}
                            placeholder="------Select a Field------"
                        >
                            {
                                allBookMarkFields?.map((item,index)=>{
                                    return(
                                        <>
                                            <Option
                                                key={item?.fieldName + index+ item?.crop}
                                                value = {item?.fieldName}
                                            >{item?.fieldName}</Option>
                                        </>
                                    );
                                })
                            }
                        </Select>
                    </Col>
                </Row>

                {/* Location Search Modal */}
                <LocationSearchModal
                    showLocationSearchModal={showLocationSearchModal}
                    geoLocationListData={geoLocationListData}
                    changeShowLocationSearchModalCallback={childChangeShowLocationSearchModalCallback}
                    geoLicationDataItemClickCallback={childClickGeonamesLocationDataItemCallback}
                />
            </div>

            {/* Crop, Field , forecast day select container, and will should location name , day information */}
            <div className="crop-field-forecastday-container cantainer-base-style-color">
                <div className="">
                    <div
                        style={{
                            backgroundColor: "#3a7bb9",
                            marginTop: 1,
                        }}
                    >
                        <Row gutter={[10, 10]}>
                            <Col lg={8} md={24} xs={24}>
                                <span className={"align"}>
                                    <EnvironmentOutlined /> {showSearchCityName} &gt;{" "}
                                    {`${getDayOfWeek(dayjs().format("YYYY-MM-DD"),true)} ${dayjs().format("DD.MM")}`}
                                    {/* {"Sun. 19.05"} */}
                                    {/* {getWeek(moment().toString())} {moment().format("DD.MM")} */}
                                </span>
                            </Col>
                            <Col lg={8} md={24} xs={24}>
                                <Row>
                                    <Col className="textL" flex="100px">
                                        <Select
                                            value={cropValue === "" ? undefined : cropValue}
                                            dropdownMatchSelectWidth={false}
                                            onChange={(e)=>{handlleCropSelectOptionChange(e);}}
                                            style={{ margin: "5px 10px 5px 0" }}
                                            placeholder="Crop"
                                        >
                                            {
                                                allCropFromFields?.map((item,index)=>{
                                                    return(
                                                        <>
                                                            <Option
                                                                key = {item + index}
                                                                value = {item}
                                                            >
                                                                {item}
                                                            </Option>
                                                        </>
                                                    );
                                                })
                                            }
                                        </Select>
                                    </Col>
                                    <Col
                                        className="textRi"
                                        flex="auto"
                                        style={{ lineHeight: "42px" }}
                                    >
                                        <Select
                                            value={fieldValue === "" ? undefined : fieldValue}
                                            dropdownMatchSelectWidth={false}
                                            onChange={(e)=>{handleFieldSelectOptionChange(e);}}
                                            style={{ maxWidth: 320, width: "100%", textAlign: "left" }}
                                            placeholder="------Select a Field------"
                                        >
                                            {
                                                allFields?.map((item,index)=>{
                                                    if(item?.crop === cropValue){
                                                        return(
                                                            <>
                                                                <Option
                                                                    key = {item?.crop + index + item?.fieldName}
                                                                    value = {item?.fieldName}
                                                                >
                                                                    {item?.fieldName}
                                                                </Option>
                                                            </>
                                                        );
                                                    }
                                                })
                                            }
                                        </Select>
                                    </Col>
                                </Row>
                            </Col>
                            <Col
                                className="textR"
                                lg={8}
                                md={24}
                                xs={24}
                                style={{ textAlign: "right" }}
                            >
                                <span
                                    className="ord"
                                    style={{
                                        color: "#fff",
                                        margin: "5px 5px 5px 0",
                                        lineHeight: "32px",
                                    }}
                                >
                                    Number of Forecast Days
                                </span>
                                <Select
                                    value={forecastDayValue}
                                    onChange={(e)=>{handleForecastSelectOptionChange(e);}}
                                    style={{ width: 80, marginTop: "5px" }}
                                >
                                    {forecastDayOptions}
                                </Select>
                                {
                                    (store.get("quantisUserRole") == "ordinary") ? null :
                                        // <div
                                        //     className="setting-button"
                                        //     onClick={settingButtonClick}
                                        // >Setting</div>
                                        <Button
                                            key="1"
                                            type="link"
                                            // style={{ color: "#fff", marginTop: "5px" }}
                                            className="setting-button"
                                            onClick={(e) => settingButtonClick()}
                                        >
                      Setting
                                        </Button>
                                }

                            </Col>
                        </Row>
                    </div>
                </div>
                <SettingModal
                    showSettingModal = {showSettingModal}
                    parentSetting={setting}
                    cropValue={"Potato"}
                    allCrop={allCrop}
                    changeShowSettingModelCallback={childChangeShowSettingModalCallback}
                />
            </div>

            {/* Google Map Components */}
            <div className="google-map-all-container">
                <div className="google-map-container">
                    {/* <GoogleMapComponents
                        changeShowDeleteOrUpdateFieldModalCallback={childChangeShowUpdateOrDeleteFieldModalCallback}
                    /> */}
                    <TestGoogleMapD
                        changeShowDeleteOrUpdateFieldModalCallback={childChangeShowUpdateOrDeleteFieldModalCallback}
                        changeShowMapAddFieldModalCallback={childChangeShowMapAddFieldModalCallback}
                        changeMarkerCallback={childChangeCropAndFieldNameAndBookMarkValueCallback}
                    />
                </div>
                <div className="google-map-notice-message">
                    <p>
          Note: Please double click the location on the map if you want to save
          it as a targeted field.
                    </p>
                </div>
                {/* <button onClick={()=>{setShowUpdateOrDeleteFieldModal(true);}}>Test Update or Delete Field Modal</button>
                <div style={{width:"100%",height:"3px"}}></div>
                <button onClick={()=>{setShowMapAddFieldModal(true);}}>Add new Field</button> */}
            </div>

            <UpdateOrDeleteField
                showDeleteOrUpdateFieldModal = {showUpdateOrDeleteFieldModal}
                cropValue={cropValue}
                fieldName={fieldValue}
                changeShowDeleteOrUpdateFieldModalCallback={childChangeShowUpdateOrDeleteFieldModalCallback}
                updateFieldCallBack={childChangeCropAndFieldNameAndBookMarkValueCallback}
            />
            <MapAddFieldModal
                showMapAddFieldModal={showMapAddFieldModal}
                forecastDay={forecastDayValue}
                changeMapAddFieldModalCallback={childChangeShowMapAddFieldModalCallback}
                addFieldCallBack={childChangeCropAndFieldNameAndBookMarkValueCallback}
            />

            {/* Nozzle Image show */}
            <NozzleImageComponents
                currentGetWeatherData={currentGetWeatherData}
            />

            {/* Legned and Description components */}
            <LegendAndDescription/>

            {/* Term  & conditions and Privacy notice */}
            <PrivacyNotice/>

            {/* <TestGoogleMap
                changeShowDeleteOrUpdateFieldModalCallback={childChangeShowUpdateOrDeleteFieldModalCallback}
            /> */}
            {/* <div>=============== map B ===============</div> */}
            {/* <TestGoogleMapB/> */}
        </div>
    );
};

export default Main;