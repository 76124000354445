import React, { useEffect, useRef, useState } from "react";
import "./index.less";
import htmlDoc from "./forecastmap.html";
import { useLocation } from "react-router-dom";
import {checkForecastMapTokenServiceTokenIsValid} from "util/http/index";

interface ISearchObj{
    token?: string;
    showPage: boolean;
}

const ForcastWeatherMap = () => {

    const location = useLocation();
    const iframeRef = useRef(null);

    const [searchObj,setSearObj] = useState<ISearchObj>({
        token: null,
        showPage: true
    });

    const initSearchObj = async()=>{
        console.log("lanxue init ",searchObj);
        const searchStr = location?.search.replace("?","");
        const searchArr = searchStr?.split("&");
        const tmpSearchObj = {...searchObj};
        searchArr?.map((item)=>{
            const searchKeyValue = item?.split("=");
            if(searchKeyValue[0] === "token"){
                tmpSearchObj[searchKeyValue[0]] = searchKeyValue[1];
            }
        });
        if(tmpSearchObj?.token){
            tmpSearchObj.showPage = await checkForcastWeatherMapTokenIsValidFunc(tmpSearchObj?.token);
        }
        else{
            tmpSearchObj.showPage = false;
        }
        setSearObj(tmpSearchObj);
    };

    const checkForcastWeatherMapTokenIsValidFunc = async(token:string)=>{
        const res = await checkForecastMapTokenServiceTokenIsValid(token);
        return res.data;
    };

    // =================== useEffect ==========================
    useEffect(()=>{
        initSearchObj();
    },[]);
    // add google analysis
    useEffect(() => {
        console.log("just invoke once");
        const head = document.getElementsByTagName("head")[0];
        const googleAnalyticsCode = "G-8LMTKR0K8F";
        const googleAnalyticsFirstScript = document.createElement("script");
        googleAnalyticsFirstScript.async = true;
        googleAnalyticsFirstScript.src =
          "https://www.googletagmanager.com/gtag/js?id=" + googleAnalyticsCode;

        const googleAnalyticsSecondScript = document.createElement("script");
        googleAnalyticsSecondScript.innerHTML =
          "    window.dataLayer = window.dataLayer || [];\n" +
          "    function gtag(){dataLayer.push(arguments);}\n" +
          "    gtag('js', new Date());\n" +
          "\n" +
          "    gtag('config', '" +
          googleAnalyticsCode +
          "',{\n" +
          "cookie_flags:'max-age=7200;secure;samesite-none'" +
          "});";

        head.insertBefore(googleAnalyticsSecondScript, head.firstChild);
        head.insertBefore(googleAnalyticsFirstScript, head.firstChild);
    }, []);

    useEffect(() => {
        const iframe = iframeRef.current;
        let handleIframeLoad = null;
        if(searchObj?.showPage){
            handleIframeLoad = () => {
                iframe.style.height = iframe.contentWindow.document.body.scrollHeight + "px";
            };
            iframe.addEventListener("load", handleIframeLoad);
        }
        return () => {
            if(searchObj?.showPage){
                iframe.removeEventListener("load", handleIframeLoad);
            }
        };
    }, [searchObj?.showPage]);

    return (
        <>
            {
                searchObj?.showPage ?
                    <iframe
                        ref={iframeRef}
                        srcDoc={htmlDoc}
                        style={{ width: "100%", overflow: "hidden", height: "97vh" }}
                        scrolling="no"
                    /> :
                    <div className="error_page">
                        <div className="error_content">
                            <span>There is an issue with accessing the page. </span>
                            <span>Please provide the correct token.</span>
                        </div>
                    </div>

            }
        </>
    );
};

export default ForcastWeatherMap;