import { ICurrentGetWeatherDataModel } from "@/util/http/Quantis/responseType";
import { Row , Col,Image} from "antd";
import React from "react";
import {getWeatherImage,getNozzleImage} from "../../init";
import "./index.less";

interface IProp{
    currentGetWeatherData: ICurrentGetWeatherDataModel
}

const NozzleImage = (props: IProp)=>{

    const {currentGetWeatherData} = props;

    return(
        <div className="nozzle-image-container">
            <Row gutter={10} className="outBox">
                {
                    currentGetWeatherData?.pictoCode?.map((item,index)=>{
                        // TODO: item 每一项具体的元素和值还没有设置完全
                        return(
                            <>
                                <Col key={index + currentGetWeatherData.weekName[index]} className="itemW">
                                    <p className="title">{currentGetWeatherData.weekName[index]}</p>
                                    <p className="time">{currentGetWeatherData.nozzleColorFormatDate[index]}</p>
                                    {/* show weather image */}
                                    <div className="tianqi">
                                        <Image
                                            preview={false}
                                            src={getWeatherImage(item)}
                                            width={130}
                                            // className="smallImage"
                                        />
                                    </div>
                                    {/* show nozzle iamge, speed and tempreture */}
                                    {/* first row: just show nozzel and tempreture iamge */}
                                    <Row justify="space-between" align="bottom">
                                        <Col span={8}>
                                            <div className={"center nozzle-imager-container"}>
                                                <Image
                                                    preview={false}
                                                    src={getNozzleImage(currentGetWeatherData.nozzleColorData[index]?.color)}
                                                    width={70}
                                                    height={80}
                                                    className="smallImg"
                                                />
                                            </div>
                                        </Col>
                                        <Col span={8}>
                                            {/* <center><span className="ngicon-Windicon"></span></center> */}
                                            <div className={"styles.center"}>
                                                <span className="ngicon-Windicon"></span>
                                            </div>
                                        </Col>
                                        <Col span={8}>
                                            {/* <center> <span className="ngicon-Temperatureicon"></span></center> */}
                                            <div className={"styles.center"}>
                                                {" "}
                                                <span className="ngicon-Temperatureicon"></span>
                                            </div>
                                        </Col>
                                    </Row>
                                    {/* second row: show detail value */}
                                    <Row>
                                        <Col span={8}>
                                        </Col>
                                        {/* windspeed_mean and windDireciton */}
                                        <Col span={8}>
                                            {/* windspeed_mean: like 3.6 kh */}
                                            <h3 className="smallF" style={{ textAlign: "center" }}>
                                                {currentGetWeatherData.windspeed_mean[index]} k/h
                                            </h3>
                                            {/* windDirection: like SW */}
                                            <h3 className="smallF" style={{ textAlign: "center" }}>
                                                {" "}
                                                {currentGetWeatherData.windDirectionLetter[index]}{" "}
                                            </h3>
                                        </Col>
                                        {/* temperature_max and temperature_min */}
                                        <Col span={8}>
                                            <h3
                                                className="smallF"
                                                style={{ textAlign: "center", color: "#EB8200" }}
                                            >
                                                {currentGetWeatherData.temperature_max[index]}°
                                            </h3>
                                            <h3
                                                className="smallF"
                                                style={{ textAlign: "center", color: "#66C9DE" }}
                                            >
                                                {currentGetWeatherData.temperature_min[index]}°
                                            </h3>
                                        </Col>
                                    </Row>
                                    {/* show bottom color */}
                                    <div style={{ padding: "20px" }}>
                                        <Row className={"green box-container"} gutter={5}>
                                            {
                                                [0,1,2,3,4].map((item,index)=>{
                                                    return(
                                                        <> <Col
                                                            key={item + "-box-item"+index}
                                                            flex={1}
                                                        >
                                                            <div className={currentGetWeatherData.predictability_class[index] > item ?
                                                                "greenbox" : "whitebox"
                                                            }
                                                            ></div>
                                                        </Col>
                                                        </>
                                                    );
                                                })
                                            }
                                        </Row>
                                    </div>
                                </Col>
                            </>
                        );
                    })
                }
            </Row>

        </div>
    );
};

export default React.memo(NozzleImage);