// import echarts from "echarts/types/dist/echarts";
import { IGetHistoricCharInfoResModel } from "@/util/http/HistWeather/responseType";
import dayjs from "dayjs";
import * as echarts from "echarts";
import React, { useEffect, useRef, useState } from "react";
const customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(customParseFormat);

interface IProp{
    selectedType?: string[];
    echartData?: IGetHistoricCharInfoResModel;
    colorArray: string[];
    idArray: string[];
    echartSeriesName?: string[];
    selectedCountry: string;
    width?: number;
}

const CountryDateFormat = {
    "AE": "DD/MM/YYYY",
    "AU": "DD/MM/YYYY",
    "BY": "DD.MM.YY",
    "CH": "DD.mm.YY",
    "DE": "DD.MM.YYYY",
    "NZ": "DD-MM-YYYY",
    "US": "MM/DD/YYYY",
    "ES": "DD/MM/YYYY",
    "FR": "DD/MM/YYYY",
    "GB": "DD/MM/YYYY",
    "IL": "DD/MM/YYYY",
    "JP": "YYYY/MM/DD",
    "KR": "YYYY-MM-DD",
    "PL": "DD.MM.YYYY",
    "PT": "DD-MM-YYYY",
    "SE": "YYYY-MM-DD",
    "SI": "DD.MM.YYYY",
    "TR": "DD.MM.YYYY",
    "ZA": "DD/MM/YYYY"
};

const EchartRender = (prop:IProp)=>{

    const {
        selectedType ,
        colorArray,
        idArray,
        echartData,
        echartSeriesName,
        width,
        selectedCountry
    } = prop;
    console.log("echart render type",selectedType);
    console.log("echart render colorArray",colorArray);
    console.log("echart render idArray",idArray);
    console.log("echart render echartData",echartData);
    console.log("echart render echartSeriesName",echartSeriesName);
    console.log("echart render width",width);
    console.log("echart render selectedCountry",selectedCountry);

    const chartRef = useRef<HTMLDivElement>(null);
    const type = "Precipitation";

    const setOption = ()=>{
        const yData = [0,1,2,3,4,5,6,7,8,9,10,11,12]; // y轴的刻度值
        const xData = [
            "01/04/2023",
            "02/04/2023",
            "03/04/2023",
            "04/04/2023",
            "05/04/2023",
            "06/04/2023",
            "07/04/2023",
            "08/04/2023",
            "09/04/2023",
            "10/04/2023",
            "11/04/2023",
            "12/04/2023",
            "13/04/2023",
            "14/04/2023",
            "15/04/2023",
        ]; // x轴的刻度值
        const options = {
            grid: {
                show: true,
                containLabel: true,
                left: 50,
                top: 10,
                width: (width || 1000) - 50,
                height: 350
            },
            color: ["#27CBFF","red"],
            xAxis: {
                data: xData,
                type: "category",
                splitNumber: 14,
                position: "top",
                axisLine: {
                    //show: false,
                    lineStyle: {
                        color: "#A3A9B9",
                        type: "solid"
                    }
                },
                axisTick: {
                    show: false,
                },
                splitLine: {
                    show: true,
                    //interval: 0,
                    lineStyle: {
                        type: "solid",
                        color: "#A3A9B9"
                    }
                },
                axisLabel: {
                    show: true,
                    //interval: 1,
                }
            },
            yAxis: {
                type: "value",
                //name: type,
                nameLocation: "center",
                //nameGap: 40,
                splitNumber: 9,
                min: 0,
                max: 12,
                nameTextStyle: {
                    color: "#14151C",
                    fontSize: 12,
                    fontfamily: "Noto-Sans-Regular"
                },
                axisLabel: {
                    show: true,
                    interval: 0,
                },
                axisLine: {
                    show: true, // 显示轴线
                    lineStyle: {
                        type: "solid",
                        color: "#A3A9B9"
                    }
                },
                axisTick: {
                    show: false, // 隐藏刻度线
                    //interval: 1
                    alignWithLabel: true
                },
                data: yData, // 刻度线对应的值
                splitLine: {
                    show: true,
                    interval: 0.5,
                    lineStyle: {
                        type: "dashed",
                        color: "#A3A9B9"
                    }
                }
            },
            series: [
                // {
                //     type: "bar",
                //     data: [4,0.5,2,2.4,1,1,1,3.4,2.2,4.2,4.3,1.6,6.8,0.5],
                // },
            ],
            // tooltip: {
            //     trigger: "item",
            //     backgroundColor: "white"
            // },
            textStyle: {
                fontFamily: "Noto-Sans-Regular",
                fontSize: 12,
                fontWeight: 400
            }
        };
        // chart.setOption(options);
        return options;
    };

    const options = {
        grid: {
            show: true,
            containLabel: true,
            left: 50,
            top: 10,
            width: (width || 1000) - 50 - 10,
            height: 390
        },
        color: colorArray,
        xAxis: {
            data: [],
            type: "category",
            splitNumber: 14,
            position: "top",
            axisLine: {
                //show: false,
                lineStyle: {
                    color: "#A3A9B9",
                    type: "solid"
                }
            },
            axisTick: {
                show: false,
            },
            splitLine: {
                show: true,
                //interval: 0,
                lineStyle: {
                    type: "solid",
                    color: "#A3A9B9"
                }
            },
            axisLabel: {
                show: true,
                //interval: 1,
            }
        },
        yAxis: {
            type: "value",
            // name: "anme",
            nameLocation: "center",
            // nameGap: 40,
            // splitNumber: 9,
            // min: 0,
            // max: 12,
            position: "left" ,
            nameTextStyle: {
                color: "#14151C",
                fontSize: 12,
                fontfamily: "Noto-Sans-Regular"
            },
            axisLabel: {
                show: true,
                interval: 0,
            },
            axisLine: {
                show: true, // 显示轴线
                lineStyle: {
                    type: "solid",
                    color: "#A3A9B9"
                }
            },
            axisTick: {
                show: false, // 隐藏刻度线
                alignWithLabel: true
            },
            data: [], // 刻度线对应的值
            splitLine: {
                show: true,
                //interval: 0.5,
                lineStyle: {
                    type: "dashed",
                    color: "#A3A9B9"
                }
            }
        },
        series: [],
        tooltip: {
            trigger: "axis",
            backgroundColor: "white"
        },
        textStyle: {
            fontFamily: "Noto-Sans-Regular",
            fontSize: 12,
            fontWeight: 400
        },
    };

    const getFilterData = (id:string):number[] =>{
        const tmpData = [];
        echartData?.data?.forEach((item)=>{
            tmpData.push(item[id]);
            // if(item[id]) {
            //     tmpData.push(item[id]);
            // }
        });
        return tmpData;
    };

    const getXAxisData = ():string[] =>{
        const tmpXAxisData = [];
        const tmpFormat = CountryDateFormat[selectedCountry] || "DD/MM/YYYY";
        echartData?.data?.forEach((item)=>{
            tmpXAxisData.push(dayjs((item?.date),"YYYY/MM/DD").format(tmpFormat));
        });
        return tmpXAxisData;
    };

    const changeEchartOption = (echartData:IGetHistoricCharInfoResModel)=>{
        const yData = [0,1,2,3,4,5,6,7,8,9,10,11,12]; // y轴的刻度值
        const xData = getXAxisData(); // x轴的刻度值
        const options = {
            grid: {
                show: true,
                containLabel: true,
                left: 50,
                top: 10,
                right: 0,
                width: (width || 1000) - 50 -20 -10,
                height: 390
            },
            color: colorArray,
            xAxis: {
                data: xData,
                type: "category",
                splitNumber: 14,
                position: "top",
                axisLine: {
                    //show: false,
                    lineStyle: {
                        color: "#A3A9B9",
                        type: "solid"
                    }
                },
                axisTick: {
                    show: false,
                },
                splitLine: {
                    show: true,
                    //interval: 0,
                    lineStyle: {
                        type: "solid",
                        color: "#A3A9B9"
                    }
                },
                axisLabel: {
                    show: true,
                    //interval: 1,
                }
            },
            yAxis: (()=>{
                const tmp = [];
                selectedType?.forEach((item,index)=>{
                    tmp.push({
                        type: "value",
                        name: item,
                        nameLocation: "center",
                        nameGap: 40,
                        position: index === 0 ? "left" : "right",
                        nameTextStyle: {
                            color: "#14151C",
                            fontSize: 12,
                            fontfamily: "Noto-Sans-Regular"
                        },
                        axisLabel: {
                            show: true,
                            interval: 0,
                        },
                        axisLine: {
                            show: true, // 显示轴线
                            lineStyle: {
                                type: "solid",
                                color: "#A3A9B9"
                            }
                        },
                        axisTick: {
                            show: false, // 隐藏刻度线
                            alignWithLabel: true
                        },
                        data: (()=>{
                            const tmp = [];
                            echartData?.data?.forEach((innerItem,innerIndex)=>{
                                if(item === "Air Temperature"){
                                    tmp.push(innerItem["tempair_c_daytimemax_AltAdj"]);
                                    tmp.push(innerItem["tempair_c_nighttimemin_AltAdj"]);
                                }
                                if(item === "Accumulated GDD"){
                                    tmp.push(innerItem["gdd_c_dailysum"]);
                                    tmp.push(innerItem["gdd_c_accumulated"]);
                                }
                            });
                            return tmp;
                        })(), // 刻度线对应的值
                        splitLine: {
                            show: true,
                            //interval: 0.5,
                            lineStyle: {
                                type: index === 0 ? "dashed" : "solid",
                                color: "#A3A9B9"
                            }
                        }
                    });
                });
                return tmp;
            })(),
            series: (()=>{
                const tmp = [];
                idArray?.map((item,index)=>{
                    const tmpName = echartSeriesName[index];
                    tmp.push({
                        name: tmpName,
                        type: "line",
                        smooth: true,
                        data: getFilterData(item),
                        symbol: "none",
                        // yAxisIndex: item != "gdd_c_accumulated" ? 0 : 1
                        yAxisIndex: item == "gdd_c_accumulated" && selectedType?.length === 2 ? 1 : 0
                    });
                });
                return tmp;
            })(),
            tooltip: {
                trigger: "axis",
                backgroundColor: "white"
            },
            textStyle: {
                fontFamily: "Noto-Sans-Regular",
                fontSize: 12,
                fontWeight: 400
            },
        };
        // chart.setOption(options);
        return options;
    };

    useEffect(() => {
        const chart = echarts.init(chartRef.current);
        let tmpOptions = null;
        if(echartData?.data?.length > 0){
            if(selectedType.length > 0){
                tmpOptions = changeEchartOption(echartData);
            }
            else{
                tmpOptions = options;
            }
            console.log("echart render dataOptions",tmpOptions);
        }
        else{
            tmpOptions = setOption();
            console.log("echart render tmpOptions",tmpOptions);
        }
        chart.setOption(tmpOptions);
        return ()=>{
            chart.dispose();
        };
    }, [selectedType,echartData]);
    return(
        <div
            ref={chartRef}
            style={{width:`${width || 1000}px`,height:"420px"}}></div>
    );
};

export default React.memo(EchartRender);