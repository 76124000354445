/* eslint-disable max-len */
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig } from "axios";
import qs from "qs";
import { xml2json } from "xml-js";
import {
    API_LOCAL_URL,
    API_SIMULATION_URL,
    API_QUANTIS_PUBLIC_URL,
    TIME_OUT
} from "./config";
import store from "../store";
import { normalActionTypes, updateUnAuthorizedFlag } from "@/redux/actions/quantisAction";
import reduxStore from "@/redux/reducer/store";
// const dispatch = useDispatch();
// store.dispatch(updateUnAuthorizedFlag);

const headers: Readonly<Record<string, string | boolean>> = {
    Accept: "text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;application/json;text/plain",
    "Content-Type": "application/json;application/xml;charset=utf-8;",
    "Access-Control-Allow-Credentials": true,
    "X-Requested-With": "XMLHttpRequest",
    "Access-Control-Allow-Origin": true,
};

enum StatusCode {
    Unauthorized = 401,
    Forbidden = 403,
    TooManyRequests = 429,
    InternalServerError = 500,
    AddAnalysisError = 2002
}

enum ServerType {
    ServerLocal,
    ServerSimulation,
    ServerDownload,
    ServerPublicService
}

class Http {
    private instance: AxiosInstance | null = null;
    private localServerInstance: AxiosInstance | null = null;
    private simulationServerInstance: AxiosInstance | null = null;
    private downloadInstance: AxiosInstance | null = null;
    private publicServiceInstance: AxiosInstance | null = null;

    // private get http(): AxiosInstance {
    //     return this.instance != null ? this.instance : this.initHttp(ServerType.ServerNormal);
    // }

    private get locaServerHttp(): AxiosInstance {
        return this.localServerInstance != null ? this.localServerInstance : this.initHttp(ServerType.ServerLocal);
    }

    private get simulationServerHttp(): AxiosInstance {
        return this.simulationServerInstance != null ? this.simulationServerInstance : this.initHttp(ServerType.ServerSimulation);
    }
    private get publicServiceHttp(): AxiosInstance {
        return this.publicServiceInstance != null ? this.publicServiceInstance : this.initHttp(ServerType.ServerPublicService);
    }

    private get downloadHttp(): AxiosInstance {
        return this.downloadInstance != null ? this.downloadInstance : this.initHttp(ServerType.ServerDownload);
    }

    private xmlToJson = (xml) => {
        const options = {
            compact: true,
            ignoreComment: true,
            spaces: 4,
        };
        const json = xml2json(xml, options);
        return JSON.parse(json);
    };

    private initHttp(type: ServerType) {
        // let baseURL = API_BASE_URL;
        let baseURL = API_LOCAL_URL;
        switch (type) {
            case ServerType.ServerLocal: {
                baseURL = API_LOCAL_URL;
                break;
            }
            case ServerType.ServerDownload: {
                baseURL = API_LOCAL_URL;
                break;
            }
            case ServerType.ServerSimulation: {
                baseURL = API_SIMULATION_URL;
                break;
            }
            case ServerType.ServerPublicService: {
                baseURL = API_QUANTIS_PUBLIC_URL;
                break;
            }
        }
        const http = axios.create({
            baseURL,
            headers,
            // this properties should open after the login is finish
        });

        http.interceptors.request.use(
            this.configRequestPayloadFormat,
            (error) => {
                return Promise.reject(error);
            },
        );

        http.interceptors.response.use(
            this.handleResponseDataValue,
            // (error) => {
            //     return Promise.reject(error);
            // }
            (error) => {
                console.log("error error",error);
                const response = this.handleError(error);
                return Promise.reject(response);
            }
        );
        http.defaults.timeout = TIME_OUT;
        switch (type) {
            // case ServerType.ServerNormal:
            //     this.instance = http;
            //     break;
            case ServerType.ServerLocal:
                this.localServerInstance = http;
                break;
            case ServerType.ServerSimulation:
                this.simulationServerInstance = http;
                break;
            case ServerType.ServerPublicService:
                this.publicServiceInstance = http;
                break;
        }
        return http;
    }

    private async handleError(error) {
        const response = error.toJSON();
        const { status } = response;
        switch (status) {
            case StatusCode.InternalServerError: {
                // store.dispatch(updateWarningMessage(error && error.response.data.message));
                break;
            }

            case StatusCode.AddAnalysisError: {
                // store.dispatch(updateWarningMessage(error && error.response.data.message));
                break;
            }

            case StatusCode.Forbidden: {
                break;
            }

            case StatusCode.Unauthorized: {
                // if not have token, should go to login page
                try {
                    console.log("error error handle",StatusCode.Unauthorized);
                    localStorage.removeItem("quantis-token");
                    reduxStore.dispatch({
                        type: normalActionTypes.NORMAL_UPDATE_UNAUTHORIZEDFLAG,
                        payload: true
                    });
                    // store.dispatch(updateUnAuthorizedFlag(true));
                } catch (err) {
                    console.log("error");
                    // if the getsignUrl cannot request success it should be remind customer refresh current page
                    // store.dispatch(updateRefreshModalShow(true));
                }
                break;
            }

            // case StatusCode.TooManyRequests: {
            //     break;
            // }
        }
        return response;
    }
    private configRequestPayloadFormat = (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
        try {
            if (!config.headers.isJson) {
                config.headers["Content-Type"] = "application/x-www-form-urlencoded";
                if(!(config.data instanceof FormData)){
                    config.data = qs.stringify(config.data); // convert to formdata
                }

                if (config.headers.upload) {
                    config.headers["Content-Type"] = "multipart/form-data";
                }
            }
            const simuationToken = localStorage.getItem("simulation-token");
            const quantisToken = store.get("quantis-token");
            if (config.url.toLowerCase().indexOf("simulation") > -1 && simuationToken) {
                config.headers["Authorization"] = simuationToken;
            }
            else if (config.headers?.quantis && quantisToken) {
                console.log("lanxue authorization quantisToken", quantisToken);
                console.log("lanxue authorization simuationToken", simuationToken);
                config.headers["Accept"] = "application/json, text/plain, */*";
                config.headers["Authorization"] = quantisToken;
            }
            else {
                config.headers["Authorization"] = "";
            }
            return config;
        } catch (error) {
            return error;
        }
    };

    private handleResponseDataValue = (response) => {
        const data = response.data;
        console.log("error response ",data);
        if (typeof (data) === "string" && data.length > 10
        && !response.data.includes("Update success")
        && !store.get("quantis-token")
        ) {
            const jsonData = this.xmlToJson(data);
            response.data = jsonData;
        }
        return response;
    };

    public get<T, R = AxiosResponse<T>>(
        url: string,
        params?: T,
        serverType = ServerType.ServerLocal,
        isJson = true
    ): Promise<R> {
        switch (serverType) {
            // case ServerType.ServerNormal:
            //     return this.http.get<T, R>(url, {params, headers: { isJson }});
            //     break;
            case ServerType.ServerLocal:
                return this.locaServerHttp.get<T, R>(url, { params, headers: { isJson } });
                break;
            case ServerType.ServerSimulation:
                return this.simulationServerHttp.get<T, R>(url, { params, headers: { isJson } });
                break;
            case ServerType.ServerPublicService:
                return this.publicServiceHttp.get<T, R>(url, { params, headers: { isJson } });
                break;
        }
    }

    public post<T, R = AxiosResponse<T>>(
        url: string,
        data?: T,
        serverType = ServerType.ServerLocal,
        isJson = true,
        params?: T,
        quantis?: boolean,
        upload?: boolean
    ): Promise<R> {
        switch (serverType) {
            // case ServerType.ServerNormal:
            //     return this.http.post<T, R>(url, data, {headers: { isJson }});
            //     break;
            case ServerType.ServerLocal:
                return this.locaServerHttp.post<T, R>(url, data, { params, headers: { isJson } });
                break;
            case ServerType.ServerSimulation:
                return this.simulationServerHttp.post<T, R>(url, data, { params, headers: { isJson, quantis, upload } });
                break;
            case ServerType.ServerPublicService:
                return this.publicServiceHttp.post<T, R>(url, data, { params, headers: { isJson, quantis, upload } });
                break;
        }
    }

    public delete<T, R = AxiosResponse<T>>(
        url: string,
        config?: AxiosRequestConfig
    ): Promise<R> {
        return this.locaServerHttp.delete<T, R>(url, config);
    }

    public downloadBlob<T, R = AxiosResponse<T>>(
        url: string,
        params?: T,
        fileName?: string,
        type = ServerType.ServerLocal,
        isJson = false
    ) {
        let newHttp: AxiosInstance = this.downloadHttp;
        switch (type) {
            case ServerType.ServerLocal:
                return new Promise((resolve) => {
                    newHttp = Object.assign({}, this.downloadHttp);
                    //console.log("aa http",newHttp);
                    newHttp.defaults.responseType = "blob";
                    newHttp.get<T, R>(url, { params, headers: { isJson } }).then(response => {
                        //console.log("respons666666666e", response, params);
                        this.downFile(response, fileName, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
                        resolve(response);
                    });
                });
                break;
        }
    }
    public downloadBlob2<T, R = AxiosResponse<T>>(
        url: string,
        params?: T,
        fileName?: string,
        type = ServerType.ServerLocal,
        isJson = false
    ) {
        let newHttp: AxiosInstance = this.downloadHttp;
        switch (type) {
            case ServerType.ServerLocal:
                return new Promise((resolve) => {
                    newHttp = Object.assign({}, this.downloadHttp);
                    //console.log("aa http",newHttp);
                    newHttp.defaults.responseType = "blob";
                    newHttp.post<T, R>(url, params).then(response => {
                        //console.log("respons666666666e", response, params);
                        this.downFile(response, fileName, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
                        resolve(response);
                    });
                });
                break;
        }
    }

    public downFile(response, fileName: string | undefined = undefined, fileType: string | undefined = undefined): void {
        const data = response.data;
        const blob = new Blob([data], { type: fileType });
        const objectUrl = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.setAttribute("style", "display:none");
        a.setAttribute("href", objectUrl);
        a.setAttribute("download", fileName);
        a.click();
        URL.revokeObjectURL(objectUrl);
    }

    public download(
        url: string,
        data?: { selectedUserIDs?: string, listProfilesIDs?: number[], enumFileType?: "XLS" | "XLSX" | "PDF" | "CSV" },
    ): Promise<any> {
        return this.post(url, data);
    }

}

const http = new Http();

export {
    http,
    ServerType
};
