import React, { useEffect, useState } from "react";
import "./index.less";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);
// import image
import NoRiskImage from "@/resource/images/svg/diseaseMap/no_risk.svg";
import LowRiskImage from "@/resource/images/svg/diseaseMap/low_risk.svg";
import MediumRiskImage from "@/resource/images/svg/diseaseMap/mefium_risk.svg";
import HighRiskImage from "@/resource/images/svg/diseaseMap/high_risk.svg";
import VeryHighRiskImage from "@/resource/images/svg/diseaseMap/very_high_risk.svg";
import { DatePicker, Input } from "antd";


const jobForcastData = 6;
interface IProp{
    selectedData: string;
    forcastDay: number
    className?: string;
    dateFormat?: string;
    childChangeSelectedDate?: (value:string)=>void
}

const RiskPicAndNotes = (prop:IProp) => {
    const {
        selectedData,
        className,
        dateFormat,
        forcastDay,
        childChangeSelectedDate
    } = prop;

    const [disableDate,setDisableDate] = useState(dayjs().add(jobForcastData,"day"));

    const disabledDateFunc = (currentDate:any)=>{
        return currentDate && currentDate > disableDate;
    };

    useEffect(()=>{
        if(forcastDay && selectedData){
            // const tmpStartDate = dayjs(selectedData, dateFormat || "DD/MM/YYYY");
            // const tmpEndDate = dayjs().add(jobForcastData,"day");
            // const dayDiff = tmpEndDate.diff(tmpStartDate,"day");
            // let finalAdd = 0;
            // if(dayDiff < forcastDay){
            //     finalAdd = dayDiff;
            // }
            // else if(forcastDay <= jobForcastData){
            //     finalAdd = forcastDay -1;
            // }
            // else{
            //     finalAdd = jobForcastData;
            // }
            const tmpStartDate = dayjs();
            setDisableDate(tmpStartDate.add(jobForcastData,"day"));
        }
    },[forcastDay,selectedData]);

    return (
        <div className="riskAndNotes">
            <div className="risk-date-item">
                <span className="font-14 start-date">Select start date :</span>
                <DatePicker
                    format={dateFormat || "DD/MM/YYYY"}
                    className={`${className} date-button`}
                    placeholder=""
                    showToday = {false}
                    disabledDate={disabledDateFunc}
                    value={selectedData? dayjs(selectedData,"DD/MM/YYYY") : null}
                    onChange={(e,dateString)=>{
                        console.log("datechange true change",e.format("DD/MM/YYYY"));
                        console.log("datechange true change str",dateString);
                        childChangeSelectedDate(dateString);
                    }}
                />
            </div>
            <div className="risk-title theme-color">Disease Risk Color Legend</div>
            <div className="image-list">
                <div className="image-list-item">
                    <img src={NoRiskImage} />
                    <span className="theme-color">No Risk</span>
                </div>
                <div className="image-list-item">
                    <img src={LowRiskImage} />
                    <span className="theme-color">Low Risk</span>
                </div>
                <div className="image-list-item">
                    <img src={MediumRiskImage} />
                    <span className="theme-color">Medium Risk</span>
                </div>
                <div className="image-list-item">
                    <img src={HighRiskImage} />
                    <span className="theme-color">High Risk</span>
                </div>
                <div className="image-list-item">
                    <img src={VeryHighRiskImage} />
                    <span className="theme-color">Very High Risk</span>
                </div>
            </div>
            <div className="image-bottom"></div>
            <div className="notes">
                <div className="first-notes">
                    <div className="notes-item theme-color">
            This visualization shows you the outcome of the basic weather
            disease indicators stored in the CE HUB.
                    </div>
                    <div className="notes-item theme-color">
            These indicators use just weather related information to display a
            theoretical potential risk for given disease to happen.
                    </div>
                    <div className="notes-item theme-color">
            That risk is calculated based on parameters such as temperature, RH
            %, Rainfall, or leaf-wetness. How and which parameters are used will
            depend on each indicator.
                    </div>
                </div>
                <div className="second-notes">
                    <div className="notes-item theme-color">
                        <span>Important:</span>These indicators (models) used, in theory,
            some information available in literature to define the thresholds
            for the different diseases.
                    </div>
                    <div className="notes-item theme-color">
            Unfortunately, there is no documentation available about the
            specific sources used to create them.
                    </div>
                    <div className="notes-item theme-color">
            The indicators have not been calibrated or tested internally
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RiskPicAndNotes;
