// Crop array
export const CropOptions = [
    {
        CropName: "Potatoes",
        CropValue: "Potato_V2",
        CropID: "Potato_V2",
    }
];

export const DiseaseOptions = [
    {
        "ModelName": "Potato - Late Blight (Phytophthora infestans) - Theory",
        "ModelID": "PotatoLateBlightPhytophthorainfestans",
        "ModelDomain": "Potato_V2",
        "Description": "Potato disease Type predication on both Histroical and Forecast for Phytophthorainfestans",
        "PropertyType": "Output",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in the range of 0-10. 0 = no risk,10 = High"
    },
    {
        "ModelName": "Potato - Early Blight (Alternaria solani) - Theory",
        "ModelID": "PotatoEarlyBlightAlternariasolani",
        "ModelDomain": "Potato_V2",
        "Description": "Potato disease Type predication on both Histroical and Forecast for Alternariasolani",
        "PropertyType": "Output",
        "PropertyName": "RiskIndicator (class)",
        "PropertyDescription": "Indicator for the expected disease risk classified in the range of 0-10. 0 = no risk,10 = High"
    }
];