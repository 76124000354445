/* eslint-disable max-len */
import { Upload, Modal, Button,message } from "antd";
import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
// import Config from "@/utils/config";
// const { apiPrefix } = Config;
// TODO: 这个值还需要修改
const apiPrefix = "https://cehub-dev.syngenta-ais.com";
import React, { useEffect, useState } from "react";
import type { UploadFile as IFileListType } from "antd";
import {uploadImage} from "@/util/http/Quantis/index";
import { normalActionTypes } from "@/redux/actions/quantisAction";
import { useDispatch } from "react-redux";
import {getReportImgUrlFromS3} from "@/util/http/Quantis/index";
function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}


interface IProp{
    initReportImgUrl: IFileListType[]
    type?: string
    changeParentReportLogosProperties?: (logos?:string)=> void
}


const ImageUpload = (props:IProp)=>{

    /**
     * imgURL is already set
     * if is edit, the data like below
     *      [
                {
                    uid:`Edit-logos-${index}`,
                    url: API_LOCAL_URL + "/quantis-logos/" + obj,
                    name: obj
                },
                {
                    uid:`Edit-logos-${index}`,
                    url: API_LOCAL_URL + "/quantis-logos/" + obj,
                    name: obj
                }
            ];
        if is add, the data like below
            [
                {
                    uid:"add-logos-1",
                    url: API_LOCAL_URL + "/quantis-logos/Quantis.jpg",
                    name: "Quantis.jpg" }
                }
            ];
     */


    const{
        initReportImgUrl,
        type = "img",
        changeParentReportLogosProperties
    } = props;
    const dispatch = useDispatch();

    const [previewVisible,setPreviewVisible] = useState(false);
    const [previewImage,setPreviewImage] = useState("");
    const [previewTitle,setPreviewTitle] = useState("");
    // const [fileList,setFileList] = useState<IFileListType[]>([]); // 所有上传文件的集合
    const [fileList,setFileList] = useState<any[]>([]); // 所有上传文件的集合
    const [n,setN] = useState(0);



    const handleCancel= () =>{
        setPreviewVisible(false);
    };

    const handlePreview = async (file )=> {
        console.log(file);
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file?.url || file?.preview);
        setPreviewVisible(true);
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf("/") + 1));
    };

    const beforeUpload = async (file) => {
        console.log("uploadcomponent beforeupload file",file);
        const isJpg = file.type === "image/jpeg" ||file.type == "image/png";
        if(!isJpg){
            message.error("You can only upload Jpg/Png file!");
            return false;
        }
        // const { onChange } = this.props;
        // TODO: 上传完成后，需要通知到这里,然后监听变量，需要将上传更改的图片，加入到本组件的 fileList中，以及调用回调函数，更改到父组件的 form 的 logos 中
        // const formData = new FormData();
        // formData.append("file",file);
        const formData = new FormData();
        formData.append("file", file);
        const res = await uploadImage(formData);
        if(res.data?.logoName){
            dispatch({
                type: normalActionTypes.NORMAL_UPDATE_SHOWSUCCESSMESSAGE,
                payload: "Pictures are valid, please click Save button to save it to this report"
            });
            const {logoName} = res.data;
            // when upload success, shoulo get true url from S3 by image name
            const nameRes = await getReportImgUrlFromS3(logoName);
            if(nameRes?.data){
                const tmpFile = {
                    uid: `-${fileList.length}+1`,
                    name: logoName,
                    status: "done",
                    // url: apiPrefix+"/quantis-logos/"+logoName,
                    url: nameRes.data,
                };
                const tmpFileList = [...fileList];
                tmpFileList.push(tmpFile);
                setFileList(tmpFileList);
                // TODO: 这个还需要测试一下应该就差不多了
                if (changeParentReportLogosProperties) {
                    const strs = tmpFileList.map(obj=>obj.name).join(",");
                    changeParentReportLogosProperties(strs);
                }
            }
        }
    };

    const onRemove = (file) => {
        let tmpFileList = [];
        tmpFileList = fileList.filter(v => v.url !== file.url);
        // this.setState({ fileList: fileList });
        const arrName = [];
        tmpFileList.map(obj=>{
            arrName.push(obj.name);
        });
        if (changeParentReportLogosProperties) {
            changeParentReportLogosProperties(arrName.join(","));
        }
        setFileList(tmpFileList);
    };

    const uploadButton = (
        <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload Image</div>
        </div>
    );
    // init the FileList by the initReportImgUrl
    const getImageTrueUrlFromS3ByImageName = async(imageName:string) =>{
        try{
            const res = await getReportImgUrlFromS3(imageName);
            if(res.data){
                return res.data;
            }
        }
        catch(error){
            console.log("eror is ",error);
            return "";
        }
    };
    const InitReportImgUrlForS3 = async () => {
        // 使用 map 而不是 forEach 来创建一个新的 Promise 数组
        const promises = initReportImgUrl.map(async (item, index) => {
            if (item?.name !== "Quantis.jpg") {
                // 获取图片的真实 URL，并等待 Promise 解析
                const tmpUrl = await getImageTrueUrlFromS3ByImageName(item.name);
                // 返回一个包含更新后信息的新对象
                return { ...item, url: tmpUrl };
            }
            // 如果名称是 "Quantis.jpg"，则直接返回原始对象
            return item;
        });

        // 使用 Promise.all 等待所有 Promise 解析
        const updatedReportImgUrls = await Promise.all(promises);

        // 设置更新后的文件列表
        setFileList(updatedReportImgUrls);
        setN(1);
    };
    useEffect(()=>{
        if(initReportImgUrl.length >= 1 && n=== 0){
            InitReportImgUrlForS3();
        }
    },[initReportImgUrl]);


    return(
        <div>
            <Upload
                listType={"picture-card"}
                fileList={fileList}
                onPreview={handlePreview}
                beforeUpload={beforeUpload}
                onRemove={onRemove}
            >
                {type == "img" ? (fileList.length >= 8 ? null : uploadButton) : <Button icon={<UploadOutlined />}>上传</Button>}
            </Upload>
            <Modal
                open = {previewVisible}
                title={previewTitle}
                footer={null}
                onCancel={handleCancel}
            >
                <img alt="example" style={{ width: "100%" }} src={previewImage} />
            </Modal>
        </div>
    );
};


export default ImageUpload;