import React, { useState ,useEffect,useRef} from "react";
import "./index.less";
import {Modal, Select, Row,Col ,DatePicker,Button,Input,Space,message,Spin} from "antd";
import {
    ManageSettingsIcon,
    DownloadAsImageIcon,
    GreenCircleIcon,
    OrangeCircleIcon,
    RedCircleIcon} from "@/resource/IconComponents";
import { useDispatch, useSelector } from "react-redux";
import ReactEcharts from "echarts-for-react";
import dayjs from "dayjs";
import type { Dayjs } from "dayjs";
import {
    getLocations,
    getCornYieldRisk
} from "@/util/http";
import {
    ILoctionModal,
    ICornYieldRiskModal
} from "@/util/http/responseType";
import IF from "@/components/IF";
import html2canvas from "html2canvas";
import * as echarts from "echarts";
import Circle from "@/resource/images/svg/cornYieldRisk/default.svg";
import { template } from "@babel/core";
import { normalActionTypes } from "@/redux/actions/diseaseAction";
import { RootState } from "@/redux/reducer/store";
import { useLocation } from "react-router-dom";

interface ISearchObj{
    theme?: string;
}
const CornYieldRisk = () => {
    const dispatch = useDispatch();
    const urlLocation = useLocation();
    const {
        showLodding
    } = useSelector((state: RootState) => state.diseaseReducer);

    // add google analysis
    useEffect(() => {
        console.log("just invoke once");
        const head = document.getElementsByTagName("head")[0];
        const googleAnalyticsCode = "G-N1SRG7PN3V";
        const googleAnalyticsFirstScript = document.createElement("script");
        googleAnalyticsFirstScript.async = true;
        googleAnalyticsFirstScript.src =
          "https://www.googletagmanager.com/gtag/js?id=" + googleAnalyticsCode;

        const googleAnalyticsSecondScript = document.createElement("script");
        googleAnalyticsSecondScript.innerHTML =
          "    window.dataLayer = window.dataLayer || [];\n" +
          "    function gtag(){dataLayer.push(arguments);}\n" +
          "    gtag('js', new Date());\n" +
          "\n" +
          "    gtag('config', '" +
          googleAnalyticsCode +
          "',{\n" +
          "cookie_flags:'max-age=7200;secure;samesite-none'" +
          "});";

        head.insertBefore(googleAnalyticsSecondScript, head.firstChild);
        head.insertBefore(googleAnalyticsFirstScript, head.firstChild);
    }, []);

    const [searchObj,setSearObj] = useState<ISearchObj>({
        theme: "light",
    });
    const [title,setTitle]=useState("Soil Temperature");
    const [settingUnit,setSettingUnit]=useState("℃");

    const [locationSearch,setLocation]=useState<ILoctionModal[]>([]);
    const [cornYieldRiskList,setCornYieldRiskList]=useState<ICornYieldRiskModal[]>([]);

    const [point,setPoint]=useState("Soustons");
    const [longitude,setLongitude]=useState(-1.33);
    const [latitude,setLatitude]=useState(43.752);
    const [riskType,setRiskType]=useState("SoilTemperatureAtEmergencePeriod");
    const [startDate,setStartDate]=useState("01/04/2023");
    const [endDate,setEndDate]=useState("");

    //Input data
    const [thresholdValueForST,setThresholdValueForST]=useState(10.00);
    const [thresholdValueForAT,setThresholdValueForAT]=useState(10.00);
    const [thresholdValueForHS,setThresholdValueForHS]=useState(10.00);

    const [minThresholdValueForSW,setMinThresholdValueForSW]=useState(5);
    const [minThresholdValueForPV,setMinThresholdValueForPV]=useState(10);

    const [maxThresholdValueForSW,setMaxThresholdValueForSW]=useState(20);
    const [maxThresholdValueForPV,setMaxThresholdValueForPV]=useState(100);

    const [minConsecutiveDaysForST,setMinConsecutiveDaysForST]=useState(1);
    const [minConsecutiveDaysForAT,setMinConsecutiveDaysForAT]=useState(1);

    const [maxConsecutiveDaysForST,setMaxConsecutiveDaysForST]=useState(5);
    const [maxConsecutiveDaysForAT,setMaxConsecutiveDaysForAT]=useState(5);

    //modalTitle
    const [modalTitle,setModalTitle]=useState("Setting for Soil temperature at emergence period");

    //isShow which setting
    const [isSoilTemperature,setIsSoilTemperature]=useState(true);
    const [isAirTemperature,setIsAirTemperature]=useState(false);
    const [isSoilWater,setIsSoilWater]=useState(false);
    const [isParAtV,setIsParAtV]=useState(false);
    const [isHeatStress,setIsHeatStress]=useState(false);

    const defaultSettings = [
        { "setting": "SoilTemperatureAtEmergencePeriod", "thresholdValue": "10.00", "minConsecutiveDays": "1", "maxConsecutiveDays": "5", "diffDays": "0" },
        { "setting": "AirTemperatureAtEmergencePeriod", "thresholdValue": "10.00", "minConsecutiveDays": "1", "maxConsecutiveDays": "5", "diffDays": "0" },
        { "setting": "SoilWaterContentDuringRootDevelopment", "minThresholdValue": "5", "maxThresholdValue": "20", "diffDays": "0" },
        { "setting": "ParAtV6", "minThresholdValue": "10", "maxThresholdValue": "100", "diffDays": "0" },
        { "setting": "HeatStressAtFlowering", "thresholdValue": "10.00", "diffDays": "0" }
    ];

    const formatDate = ( time: any ) => {
        const Dates = new Date( time );
        const year: number = Dates.getFullYear();
        const month: any = ( Dates.getMonth() + 1 ) < 10 ? "0" + ( Dates.getMonth() + 1 ) : ( Dates.getMonth() + 1 );
        const day: any = Dates.getDate() < 10 ? "0" + Dates.getDate() : Dates.getDate();
        return day + "/" + month + "/" + year;
    };
    const currentDate = formatDate( new Date().getTime());
    const yesterday = formatDate( new Date().getTime() -( 1000 * 3600 * 24 ));
    //console.log("444444current date",formatDate("2023/03/18"));

    const formatTime = ( time: any ) => {
        const Dates = new Date( time );
        const year: number = Dates.getFullYear();
        const month: any = ( Dates.getMonth() + 1 ) < 10 ? "0" + ( Dates.getMonth() + 1 ) : ( Dates.getMonth() + 1 );
        const day: any = Dates.getDate() < 10 ? "0" + Dates.getDate() : Dates.getDate();
        const hs:any=time.substring(11,16);
        return day + "/" + month + "/" + year+" "+hs;
    };
    const getLocation=async()=>{
        const res=await getLocations();
        setLocation(res.data);
        //console.log("getLocation",res.data);
    };

    const getCornYieldRisks=async(
        latitude:number,longitude:number,riskType:string,startDate:string,endDate:string,
        thresholdValue?:number,minConsecutiveDays?:number,maxConsecutiveDays?:number,
        minThresholdValue?:number,maxThresholdValue?:number,download?:boolean)=>{
        //console.log("aa",download);
        dispatch({ type: normalActionTypes.NORMAL_SHOWLODDING, payload: true});
        const res=await getCornYieldRisk(
            { latitude : latitude,
                longitude : longitude,
                riskType : riskType,
                startDate : startDate,
                endDate : endDate,
                thresholdValue: thresholdValue,
                minConsecutiveDays: minConsecutiveDays,
                maxConsecutiveDays: maxConsecutiveDays,
                minThresholdValue: minThresholdValue,
                maxThresholdValue: maxThresholdValue,
                download: download,
            });
        dispatch({ type: normalActionTypes.NORMAL_SHOWLODDING, payload: false});
        //console.log("res res res is:",res);
        if(!download){
            setCornYieldRiskList(res.data);
        }
    };

    const initPage=()=>{
        setEndDate(yesterday);
        getCornYieldRisks(
            43.752,
            -1.33,
            "SoilTemperatureAtEmergencePeriod",
            "01/04/2023",
            yesterday,
            10,
            5
        );
        // localStorage.setItem("cornYieldRiskBookMark",JSON.stringify(defaultSettings));
    };
    const initSearchObj = ()=>{
        const searchStr = location?.search.replace("?","");
        const searchArr = searchStr?.split("&");
        const tmpSearchObj = {...searchObj};
        searchArr?.map((item)=>{
            const searchKeyValue = item?.split("=");
            tmpSearchObj[searchKeyValue[0].toLowerCase()] = searchKeyValue[1];
        });
        setSearObj(tmpSearchObj);
    };

    useEffect(() => {
        getLocation();
        getStressEvent();
        initPage();
    }, []);
    useEffect(() => {
        getStressEvent();
    }, [cornYieldRiskList]);

    useEffect(() => {
        // init the searchobj, and the value data is form the url
        initSearchObj();
    }, [urlLocation]);
    //save image
    const saveStressEventToDataUrl = ()=>{
        const element = document.getElementById("stressEventDiv");
        if(element){
            html2canvas(element,{
                useCORS: true
            }).then((canvas)=>{
                const dataUrl = canvas.toDataURL("image/png");
                //console.log("test images",dataUrl);

                const link = document.createElement("a");
                link.href = dataUrl;
                link.download = "stressEventIdentification.png";
                link.click();
            });
        }
    };
    const saveSoilTemperatureToDataUrl = ()=>{
        const element = document.getElementById("soilTemperatureDiv");
        if(element){
            html2canvas(element,{
                useCORS: true
            }).then((canvas)=>{
                const dataUrl = canvas.toDataURL("image/png");
                //console.log("test images",dataUrl);

                const link = document.createElement("a");
                link.href = dataUrl;
                link.download = "soilTemperature.png";
                link.click();
            });
        }
    };

    //stressEventData Details
    interface ICategory1 {
        value: number,
    }
    interface ICategory2 {
        time: string,
    }
    interface ICategory3 {
        diffDays: string,
        maxConsecutiveDays: string,
        minConsecutiveDays: string,
        setting: string,
        thresholdValue: string,
    }
    const [levelDatas,setLevelDatas]=useState([]);
    const [timeDatas,settimeDatas]=useState([]);
    const [temperatureTime,setTemperatureTime]=useState([]);

    const [valueDatas,setValueDatas]=useState([]);
    const [nightValueDatas,setNightValueDatas]=useState([]);
    const [dayValueDatas,setDayValueDatas]=useState([]);
    const [nightDayValueDatas,setNightDayValueDatas]=useState([]);

    const getStressEvent =async()=>{
        const tmpArray1: ICategory1[] = [];
        const tmpArray2: ICategory2[] = [];
        const tmpArray3: ICategory1[] = [];
        const tmpArray4: ICategory1[] = [];
        const tmpArray5: ICategory1[] = [];
        const tmpArray6: ICategory1[] = [];
        const tmpArray7: ICategory2[] = [];
        console.log("aaaaaaaaaaa",cornYieldRiskList);
        cornYieldRiskList?.map((item) => {
            tmpArray1.push({
                value: item.level,
            });
            tmpArray2.push({
                time: formatDate(item.time?.substring(0,10))
            });
            tmpArray3.push({
                value: item.dayValue,
            });
            tmpArray4.push({
                value: item.nightValue,
            });
            tmpArray5.push({
                value: Number((item.dayValue-item.nightValue).toFixed(2)),
            });
            tmpArray6.push({
                value: Number(item.value.toFixed(2)),
            });
            tmpArray7.push({
                time: formatTime(item.time),
            });
        });
        setLevelDatas(tmpArray1);
        settimeDatas(tmpArray2);
        setNightValueDatas(tmpArray3);
        setDayValueDatas(tmpArray4);
        setNightDayValueDatas(tmpArray5);
        setValueDatas(tmpArray6);
        setTemperatureTime(tmpArray7);
    };

    const stressEventOption = {
        tooltip: {
            formatter: function (obj) {
                console.log("obj objobjobjobjis:",obj);
                if (obj.length > 0) {
                    let r = "";
                    r += "Time: " + obj[0].name;
                    for (let i = 0; i < obj.length; i++) {
                        r += "</br>" + "Level" + ": " + obj[i].value;
                    }
                    return r;
                }
                else
                    return "";
            },
            trigger: "axis"
        },
        grid: {
            show:true,
            top:"2%",
            left: "3%",
            right: "2%",
            bottom: "18%",
            containLabel: true,
            borderColor:"#DFE2E7",
        },
        xAxis: {
            type: "category",
            boundaryGap: false,
            splitLine: {
                show: true,
                interval:0,
                lineStyle: {
                    type: "solid",
                    color: "#DFE2E7",
                    width: 1.5
                },
            },
            data: timeDatas?.map( (item) =>{
                //console.log("33333333333:",item.time);
                return item.time;
            })
        },
        yAxis: {
            type: "value",
            min: 1,
            max: 5,
            interval: 2,
            axisLabel: {
                formatter: (value: number) => {
                    //console.log("yAxis value is:",value);
                    if (value === 1) {
                        return "No Stress";
                    } else if (value === 3) {
                        return "Pre Stress";
                    } else if (value === 5) {
                        return "Stress Event";
                    } else {
                        return "";
                    }
                },
                fontSize:"10px",
                fontFamily:"Noto-Sans-Regular",
                fontWeight:400,
                verticalAlign: "middle",
            },
            splitLine: {
                show: true,
                interval: 0.5,
                lineStyle: {
                    type: "dashed",
                    color: "#A3A9B9"
                }
            },
            data: [1,3,5]

        },
        dataZoom: [
            {
                type: "slider",
                backgroundColor: "#F2F5F9",
                fillerColor: "#0092E4",
                height: 8,
                handleSize: "200%",
                right: 100,
                left: 100,
                bottom: 15,
                moveHandleSize: 0,
                handleIcon: "image://"+Circle ,
                handleStyle: {
                    color: "#fff",
                    borderColor:"#0092E4",
                    borderType :"solid",
                    opacity:1,
                },
                //showDetail:false
            }
        ],
        series: [
            {
                type: "scatter",
                data: levelDatas?.map( (item) =>{
                    return item.value;
                }),
                markLine: {
                    silent: true,
                    lineStyle: {
                        type: "dotted",
                        color: "#333"
                    },
                },
                itemStyle: {
                    color: function (value) {
                        //console.log("value.data is:",value.data.value);
                        if (value.data === 1) {
                            return "#19A04B";
                        } else if (value.data=== 3) {
                            return "#E99921";
                        } else if (value.data === 5) {
                            return "#CF3537";
                        } else {
                            return "";
                        }
                    }
                },
            }
        ]
    };

    const soilTemperatureOption = {
        title: {
            text: settingUnit,
            left:20,
            top:20,
            textStyle:{
                fontStyle:"normal",
                fontSize:"16px"
            }
        },
        grid: {
            show:true,
            left: "3%",
            right: "2%",
            // bottom: "18%",
            containLabel: true,
            borderColor:"#DFE2E7",
        },
        tooltip: {
            formatter: function (obj) {
                console.log("objobjobjobjobj is:",obj);
                if (obj.length > 0) {
                    let r = "";
                    r += "Time: " + obj[0].name;
                    for (let i = 0; i < obj.length; i++) {
                        r += "</br>" + "Temperature: " + ": " + obj[i].value + " ℃";
                    }
                    return r;
                }
                else
                    return "";
            },
            trigger: "axis"
        },
        xAxis: {
            type: "category",
            boundaryGap: false,
            data: timeDatas?.map( (item) =>{
                //console.log("33333333333:",item.time);
                return item.time;
            })
        },
        yAxis: {
            type: "value",
        },
        dataZoom: [
            {
                type: "slider",
                backgroundColor: "#F2F5F9",
                fillerColor: "#0092E4",
                height: 8,
                handleSize: "200%",
                right: 100,
                left: 100,
                //start:0,
                ///end:13,
                bottom: 15,
                moveHandleSize: 0,
                handleIcon: "image://"+Circle ,
                handleStyle: {
                    color: "#fff",
                    borderColor:"#0092E4",
                    borderType :"solid",
                    opacity:1,
                },
                //showDetail:false
            }
        ],
        series: [
            {
                type: "line",
                smooth: true,
                symbol: "none",
                areaStyle: {
                    opacity: 0.2,
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        {
                            offset: 0,
                            color: "#0092E4"
                        },
                        {
                            offset: 1,
                            color: "rgba(255, 255, 255, 0)"
                        }
                    ])
                },
                lineStyle: {
                    color:"#0092E4",
                    width: 2
                },
                data: valueDatas?.map( (item) =>{
                    return item.value;
                }),
            },
        ]
    };

    const getWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const [soilTemperatureOption2,setSoilTemperatureOption2] = useState(null);

    useEffect(()=>{
        const tmpClientWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        const tmpTitleTop = 0;
        const tmpLegentTop = 20;
        let tmpGridTop = 50;
        if(tmpClientWidth <= 325){
            tmpGridTop = 90;
        }
        else if(tmpClientWidth > 325 && tmpClientWidth <= 425){
            tmpGridTop = 80;
        }
        else if(tmpClientWidth > 425 && tmpClientWidth < 600){
            tmpGridTop = 60;
        }
        const tmpsoilTemperatureOption2 = {
            title: {
                text: settingUnit,
                left:20,
                top:tmpTitleTop,
                textStyle:{
                    fontStyle:"normal",
                    fontSize:"16px"
                }
            },
            grid: {
                show:true,
                left: "3%",
                right: "2%",
                top: tmpGridTop,
                // bottom: "18%",
                containLabel: true,
                borderColor:"#DFE2E7",
            },
            tooltip: {
                formatter: function (obj) {
                    //console.log("obj is:",obj);
                    if (obj.length > 0) {
                        let r = "";
                        r += "Time: " + obj[0].name;
                        for (let i = 0; i < obj.length; i++) {
                            r += "</br>" + obj[i].seriesName + ": " + obj[i].value + " ℃";
                        }
                        return r;
                    }
                    else
                        return "";
                },
                trigger: "axis"
            },
            legend: {
                data: ["Night temperature", "Day temperature", "Day-night amplitude temperature"],
                top: tmpLegentTop,
            },
            xAxis: {
                type: "category",
                boundaryGap: false,
                data: timeDatas?.map( (item) =>{
                    //console.log("33333333333:",item.time);
                    return item.time;
                })
            },
            yAxis: {
                type: "value",
            },
            dataZoom: [
                {
                    type: "slider",
                    backgroundColor: "#F2F5F9",
                    fillerColor: "#0092E4",
                    height: 8,
                    handleSize: "200%",
                    right: 100,
                    left: 100,
                    //start:0,
                    ///end:13,
                    bottom: 15,
                    moveHandleSize: 0,
                    handleIcon: "image://"+Circle ,
                    handleStyle: {
                        color: "#fff",
                        borderColor:"#0092E4",
                        borderType :"solid",
                        opacity:1,
                    },
                    //showDetail:false
                }
            ],
            series: [
                {
                    name:"Day temperature",
                    type: "line",
                    smooth: true,
                    symbol: "none",
                    data: dayValueDatas?.map( (item) =>{
                        return item.value;
                    }),
                    areaStyle: {
                        opacity: 0.3,
                        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                            {
                                offset: 0,
                                color: "#5470c6"
                            },
                            {
                                offset: 1,
                                color: "rgba(255, 255, 255, 0)"
                            }
                        ])
                    },
                },
                {
                    name:"Night temperature",
                    type: "line",
                    smooth: true,
                    symbol: "none",
                    data: nightValueDatas?.map( (item) =>{
                        return item.value;
                    }),
                    areaStyle: {
                        opacity: 0.8,
                        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                            {
                                offset: 0,
                                color: "#91cc75"
                            },
                            {
                                offset: 1,
                                color: "rgba(255, 255, 255, 0)"
                            }
                        ])
                    },
                },
                {
                    name:"Day-night amplitude temperature",
                    type: "line",
                    smooth: true,
                    symbol: "none",
                    data: nightDayValueDatas?.map( (item) =>{
                        return item.value;
                    }),
                    areaStyle: {
                        opacity: 0.8,
                        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                            {
                                offset: 0,
                                color: "#fac858"
                            },
                            {
                                offset: 1,
                                color: "rgba(255, 255, 255, 0)"
                            }
                        ])
                    },
                },
            ]
        };
        setSoilTemperatureOption2(tmpsoilTemperatureOption2);
    },[dayValueDatas,nightValueDatas,nightDayValueDatas]);



    console.log("lanxue cornyildrisk isHeatStress",isHeatStress);
    console.log("lanxue cornyildrisk getWidth",getWidth);

    //modal open
    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };
    //click Save button function
    const handleOk = () => {
        //console.log("i am save:",riskType);
        let flag = true;
        switch (riskType) {
            case "SoilTemperatureAtEmergencePeriod":
                if (Number(maxConsecutiveDaysForST) <= 1) {
                    alert("The consecutive days of the Stress event value should exceed value 1.");
                    flag = false;
                }
                break;
            case "AirTemperatureAtEmergencePeriod":
                if (Number(maxConsecutiveDaysForAT) <= 1) {
                    alert("The consecutive days of the Stress event value should exceed value 1.");
                    flag = false;
                }
                break;
            case "SoilWaterContentDuringRootDevelopment":
                if (Number(maxThresholdValueForSW) <= Number(minThresholdValueForSW)) {
                    alert("The percent of Stress event value should exceed pre-stress value.");
                    flag = false;
                }
                break;
            case "ParAtV6":
                break;
            case "HeatStressAtFlowering":
                break;
            default: break;
        }
        if (flag) {
            //console.log("flag is true");
            switch (riskType) {
                case "SoilTemperatureAtEmergencePeriod":{
                    const soilTemperatureSettings = [
                        { "setting": "SoilTemperatureAtEmergencePeriod", "thresholdValue": thresholdValueForST,
                            "minConsecutiveDays": minConsecutiveDaysForST, "maxConsecutiveDays": maxConsecutiveDaysForST, "diffDays": "0" },
                        { "setting": "AirTemperatureAtEmergencePeriod", "thresholdValue": "10.00", "minConsecutiveDays": "1", "maxConsecutiveDays": "5", "diffDays": "0" },
                        { "setting": "SoilWaterContentDuringRootDevelopment", "minThresholdValue": "5", "maxThresholdValue": "20", "diffDays": "0" },
                        { "setting": "ParAtV6", "minThresholdValue": "10", "maxThresholdValue": "100", "diffDays": "0" },
                        { "setting": "HeatStressAtFlowering", "thresholdValue": "10.00", "diffDays": "0" }
                    ];
                    localStorage.setItem("cornYieldRiskBookMark",JSON.stringify(soilTemperatureSettings));
                    getCornYieldRisks(
                        latitude,
                        longitude,
                        riskType,
                        startDate,
                        endDate,
                        thresholdValueForST,
                        minConsecutiveDaysForST,
                        maxConsecutiveDaysForST,
                        null,
                        null,
                        false
                    );
                    break;}
                case "AirTemperatureAtEmergencePeriod":{
                    const airTemperatureSettings = [
                        { "setting": "SoilTemperatureAtEmergencePeriod", "thresholdValue": thresholdValueForST,
                            "minConsecutiveDays": minConsecutiveDaysForST, "maxConsecutiveDays": maxConsecutiveDaysForST, "diffDays": "0" },
                        { "setting": "AirTemperatureAtEmergencePeriod", "thresholdValue": thresholdValueForAT,
                            "minConsecutiveDays": minConsecutiveDaysForAT, "maxConsecutiveDays": maxConsecutiveDaysForAT, "diffDays": "0" },
                        { "setting": "SoilWaterContentDuringRootDevelopment", "minThresholdValue": "5", "maxThresholdValue": "20", "diffDays": "0" },
                        { "setting": "ParAtV6", "minThresholdValue": "10", "maxThresholdValue": "100", "diffDays": "0" },
                        { "setting": "HeatStressAtFlowering", "thresholdValue": "10.00", "diffDays": "0" }
                    ];
                    localStorage.setItem("cornYieldRiskBookMark",JSON.stringify(airTemperatureSettings));
                    getCornYieldRisks(
                        latitude,
                        longitude,
                        riskType,
                        startDate,
                        endDate,
                        thresholdValueForAT,
                        minConsecutiveDaysForAT,
                        maxConsecutiveDaysForAT,
                        null,
                        null,
                        false
                    );
                    break;}
                case "SoilWaterContentDuringRootDevelopment":{
                    const soilWaterSettings = [
                        { "setting": "SoilTemperatureAtEmergencePeriod", "thresholdValue": thresholdValueForST,
                            "minConsecutiveDays": minConsecutiveDaysForST, "maxConsecutiveDays": maxConsecutiveDaysForST, "diffDays": "0" },
                        { "setting": "AirTemperatureAtEmergencePeriod", "thresholdValue": thresholdValueForAT,
                            "minConsecutiveDays": minConsecutiveDaysForAT, "maxConsecutiveDays": maxConsecutiveDaysForAT, "diffDays": "0" },
                        { "setting": "SoilWaterContentDuringRootDevelopment", "minThresholdValue": minThresholdValueForSW,
                            "maxThresholdValue": "20", "diffDays": "0" },
                        { "setting": "ParAtV6", "minThresholdValue": "10", "maxThresholdValue": "100", "diffDays": "0" },
                        { "setting": "HeatStressAtFlowering", "thresholdValue": "10.00", "diffDays": "0" }
                    ];
                    localStorage.setItem("cornYieldRiskBookMark",JSON.stringify(soilWaterSettings));
                    getCornYieldRisks(
                        latitude,
                        longitude,
                        riskType,
                        startDate,
                        endDate,
                        null,
                        null,
                        null,
                        minThresholdValueForSW,
                        maxThresholdValueForSW,
                        false
                    );
                    break;}
                case "ParAtV6":{
                    const parSettings = [
                        { "setting": "SoilTemperatureAtEmergencePeriod", "thresholdValue": thresholdValueForST,
                            "minConsecutiveDays": minConsecutiveDaysForST, "maxConsecutiveDays": maxConsecutiveDaysForST, "diffDays": "0" },
                        { "setting": "AirTemperatureAtEmergencePeriod", "thresholdValue": thresholdValueForAT,
                            "minConsecutiveDays": minConsecutiveDaysForAT, "maxConsecutiveDays": maxConsecutiveDaysForAT, "diffDays": "0" },
                        { "setting": "SoilWaterContentDuringRootDevelopment", "minThresholdValue": minThresholdValueForSW,
                            "maxThresholdValue": "20", "diffDays": "0" },
                        { "setting": "ParAtV6", "minThresholdValue": minThresholdValueForPV, "maxThresholdValue": "100", "diffDays": "0" },
                        { "setting": "HeatStressAtFlowering", "thresholdValue": "10.00", "diffDays": "0" }
                    ];
                    localStorage.setItem("cornYieldRiskBookMark",JSON.stringify(parSettings));
                    getCornYieldRisks(
                        latitude,
                        longitude,
                        riskType,
                        startDate,
                        endDate,
                        null,
                        null,
                        null,
                        minThresholdValueForPV,
                        maxThresholdValueForPV,
                        false
                    );
                    break;}
                case "HeatStressAtFlowering":{
                    const heatSettings = [
                        { "setting": "SoilTemperatureAtEmergencePeriod", "thresholdValue": thresholdValueForST,
                            "minConsecutiveDays": minConsecutiveDaysForST, "maxConsecutiveDays": maxConsecutiveDaysForST, "diffDays": "0" },
                        { "setting": "AirTemperatureAtEmergencePeriod", "thresholdValue": thresholdValueForAT,
                            "minConsecutiveDays": minConsecutiveDaysForAT, "maxConsecutiveDays": maxConsecutiveDaysForAT, "diffDays": "0" },
                        { "setting": "SoilWaterContentDuringRootDevelopment", "minThresholdValue": minThresholdValueForSW,
                            "maxThresholdValue": "20", "diffDays": "0" },
                        { "setting": "ParAtV6", "minThresholdValue": minThresholdValueForPV, "maxThresholdValue": "100", "diffDays": "0" },
                        { "setting": "HeatStressAtFlowering", "thresholdValue": thresholdValueForHS, "diffDays": "0" }
                    ];
                    localStorage.setItem("cornYieldRiskBookMark",JSON.stringify(heatSettings));
                    getCornYieldRisks(
                        latitude,
                        longitude,
                        riskType,
                        startDate,
                        endDate,
                        thresholdValueForHS,
                        null,
                        null,
                        null,
                        null,
                        false
                    );
                    break;
                }
            }
            alert("Save successfully!");
            setIsModalOpen(false);
        }

    };
    //click Cancel button function
    const handleCancel = () => {
        setIsModalOpen(false);
        //console.log("i am cancel");
    };

    //content change function
    const [placeName,setPlaceName]=useState("Soustons");
    const pointOnChange=(e)=>{
        console.log("33333pointOnChange is",e);
        console.log("aaaa",location);
        console.log("333e.split(",")[0]",e.split(",")[0]);
        console.log("333e.split(",")[1]",e.split(",")[1]);
        setPlaceName(e.split(",")[1]);
        let tmpLatitude,tmpLongitude,tmpStartDate,tmpEndDate;
        locationSearch?.map((item) => {
            if(e.split(",")[0] === item.pointId){
                tmpLatitude=item.latitude,
                tmpLongitude=item.longitude;
                tmpStartDate=item.startDate;
                tmpEndDate=item.endDate;
            }
        });
        switch (riskType) {
            case "SoilTemperatureAtEmergencePeriod": {
                setModalTitle("Setting for Soil temperature at emergence period");
                setIsSoilTemperature(true);
                setIsAirTemperature(false);
                setIsSoilWater(false);
                setIsParAtV(false);
                setIsHeatStress(false);

                getCornYieldRisks(
                    tmpLatitude,
                    tmpLongitude,
                    riskType,
                    tmpStartDate,
                    tmpEndDate,
                    thresholdValueForST,
                    minConsecutiveDaysForST,
                    maxConsecutiveDaysForST
                );
                break;
            }
            case "AirTemperatureAtEmergencePeriod": {
                setModalTitle("Setting for Air temperature at emergence period");
                setIsSoilTemperature(false);
                setIsAirTemperature(true);
                setIsSoilWater(false);
                setIsParAtV(false);
                setIsHeatStress(false);

                getCornYieldRisks(
                    tmpLatitude,
                    tmpLongitude,
                    riskType,
                    tmpStartDate,
                    tmpEndDate,
                    thresholdValueForAT,
                    minConsecutiveDaysForAT,
                    maxConsecutiveDaysForAT
                );
                break;
            }
            case "SoilWaterContentDuringRootDevelopment": {
                setModalTitle("Setting for Soil water content during root development");
                setIsSoilTemperature(false);
                setIsAirTemperature(false);
                setIsSoilWater(true);
                setIsParAtV(false);
                setIsHeatStress(false);

                getCornYieldRisks(
                    tmpLatitude,
                    tmpLongitude,
                    riskType,
                    tmpStartDate,
                    tmpEndDate,
                    minThresholdValueForSW,
                    maxThresholdValueForSW
                );
                break;
            }
            case "ParAtV6": {
                setModalTitle("Setting for PAR at V6/V8");
                setIsSoilTemperature(false);
                setIsAirTemperature(false);
                setIsSoilWater(false);
                setIsParAtV(true);
                setIsHeatStress(false);

                getCornYieldRisks(
                    tmpLatitude,
                    tmpLongitude,
                    riskType,
                    tmpStartDate,
                    tmpEndDate,
                    minThresholdValueForPV,
                    maxThresholdValueForPV
                );
                break;
            }
            case "HeatStressAtFlowering": {
                setModalTitle("Setting for Heat stress at flowering");
                setIsSoilTemperature(false);
                setIsAirTemperature(false);
                setIsSoilWater(false);
                setIsParAtV(false);
                setIsHeatStress(true);

                getCornYieldRisks(
                    tmpLatitude,
                    tmpLongitude,
                    riskType,
                    tmpStartDate,
                    tmpEndDate,
                    thresholdValueForHS,
                );
                break;
            }
            default: break;
        }
        setPoint(e);
        setLatitude(tmpLatitude);
        setLongitude(tmpLongitude);
        setStartDate(tmpStartDate);
        setEndDate(tmpEndDate);
    };
    const riskTypeOnChange=(e)=>{
        //console.log("riskTypeOnChange is",e);
        setRiskType(e);
        switch (e) {
            case "SoilTemperatureAtEmergencePeriod": {
                setModalTitle("Setting for Soil temperature at emergence period");
                setIsSoilTemperature(true);
                setIsAirTemperature(false);
                setIsSoilWater(false);
                setIsParAtV(false);
                setIsHeatStress(false);
                setTitle("Soil Temperature");
                setSettingUnit("℃");

                getCornYieldRisks(
                    latitude,
                    longitude,
                    e,
                    startDate,
                    endDate,
                    thresholdValueForST,
                    minConsecutiveDaysForST,
                    maxConsecutiveDaysForST
                );
                break;
            }
            case "AirTemperatureAtEmergencePeriod": {
                setModalTitle("Setting for Air temperature at emergence period");
                setIsSoilTemperature(false);
                setIsAirTemperature(true);
                setIsSoilWater(false);
                setIsParAtV(false);
                setIsHeatStress(false);
                setTitle("Air Temperature");
                setSettingUnit("℃");

                getCornYieldRisks(
                    latitude,
                    longitude,
                    e,
                    startDate,
                    endDate,
                    thresholdValueForAT,
                    minConsecutiveDaysForAT,
                    maxConsecutiveDaysForAT
                );
                break;
            }
            case "SoilWaterContentDuringRootDevelopment": {
                setModalTitle("Setting for Soil water content during root development");
                setIsSoilTemperature(false);
                setIsAirTemperature(false);
                setIsSoilWater(true);
                setIsParAtV(false);
                setIsHeatStress(false);
                setTitle("Daily Precipitation");
                setSettingUnit("mm");

                getCornYieldRisks(
                    latitude,
                    longitude,
                    e,
                    startDate,
                    endDate,
                    minThresholdValueForSW,
                    maxThresholdValueForSW
                );
                break;
            }
            case "ParAtV6": {
                setModalTitle("Setting for PAR at V6/V8");
                setIsSoilTemperature(false);
                setIsAirTemperature(false);
                setIsSoilWater(false);
                setIsParAtV(true);
                setIsHeatStress(false);
                setTitle("Photosynthetic active radiation");
                setSettingUnit("W/m²");

                getCornYieldRisks(
                    latitude,
                    longitude,
                    e,
                    startDate,
                    endDate,
                    minThresholdValueForPV,
                    maxThresholdValueForPV
                );
                break;
            }
            case "HeatStressAtFlowering": {
                setModalTitle("Setting for Heat stress at flowering");
                setIsSoilTemperature(false);
                setIsAirTemperature(false);
                setIsSoilWater(false);
                setIsParAtV(false);
                setIsHeatStress(true);
                setTitle("Temperature");
                setSettingUnit("℃");

                getCornYieldRisks(
                    latitude,
                    longitude,
                    e,
                    startDate,
                    endDate,
                    thresholdValueForHS,
                );
                break;
            }
            default: break;
        }
    };

    const startDateOnChange=(date: Dayjs,dateString: string) => {
        if (date) {
            const begin=stringToDate(dateString);
            const end=stringToDate(endDate);
            if(begin>end){
                alert("The start date should not exceed the end date.");
                return;
            }
            setStartDate(dateString);
            switch (riskType) {
                case "SoilTemperatureAtEmergencePeriod": {
                    setModalTitle("Setting for Soil temperature at emergence period");
                    setIsSoilTemperature(true);
                    setIsAirTemperature(false);
                    setIsSoilWater(false);
                    setIsParAtV(false);
                    setIsHeatStress(false);

                    getCornYieldRisks(
                        latitude,
                        longitude,
                        riskType,
                        dateString,
                        endDate,
                        thresholdValueForST,
                        minConsecutiveDaysForST,
                        maxConsecutiveDaysForST
                    );
                    break;
                }
                case "AirTemperatureAtEmergencePeriod": {
                    setModalTitle("Setting for Air temperature at emergence period");
                    setIsSoilTemperature(false);
                    setIsAirTemperature(true);
                    setIsSoilWater(false);
                    setIsParAtV(false);
                    setIsHeatStress(false);

                    getCornYieldRisks(
                        latitude,
                        longitude,
                        riskType,
                        dateString,
                        endDate,
                        thresholdValueForAT,
                        minConsecutiveDaysForAT,
                        maxConsecutiveDaysForAT
                    );
                    break;
                }
                case "SoilWaterContentDuringRootDevelopment": {
                    setModalTitle("Setting for Soil water content during root development");
                    setIsSoilTemperature(false);
                    setIsAirTemperature(false);
                    setIsSoilWater(true);
                    setIsParAtV(false);
                    setIsHeatStress(false);

                    getCornYieldRisks(
                        latitude,
                        longitude,
                        riskType,
                        dateString,
                        endDate,
                        minThresholdValueForSW,
                        maxThresholdValueForSW
                    );
                    break;
                }
                case "ParAtV6": {
                    setModalTitle("Setting for PAR at V6/V8");
                    setIsSoilTemperature(false);
                    setIsAirTemperature(false);
                    setIsSoilWater(false);
                    setIsParAtV(true);
                    setIsHeatStress(false);

                    getCornYieldRisks(
                        latitude,
                        longitude,
                        riskType,
                        dateString,
                        endDate,
                        minThresholdValueForPV,
                        maxThresholdValueForPV
                    );
                    break;
                }
                case "HeatStressAtFlowering": {
                    setModalTitle("Setting for Heat stress at flowering");
                    setIsSoilTemperature(false);
                    setIsAirTemperature(false);
                    setIsSoilWater(false);
                    setIsParAtV(false);
                    setIsHeatStress(true);

                    getCornYieldRisks(
                        latitude,
                        longitude,
                        riskType,
                        dateString,
                        endDate,
                        thresholdValueForHS,
                    );
                    break;
                }
                default: break;
            }
        } else {
            console.log("Clear");
        }
    };
    const endDateOnChange=(date: Dayjs,dateString: string) => {
        if (date) {
            const current=stringToDate(currentDate);
            const begin=stringToDate(startDate);
            const end=stringToDate(dateString);
            if(begin>end){
                alert("The start date should not exceed the end date.");
                return;
            }
            if(end>current){
                alert("The end date should not exceed today.");
                return;
            }
            setEndDate(dateString);
            switch (riskType) {
                case "SoilTemperatureAtEmergencePeriod": {
                    setModalTitle("Setting for Soil temperature at emergence period");
                    setIsSoilTemperature(true);
                    setIsAirTemperature(false);
                    setIsSoilWater(false);
                    setIsParAtV(false);
                    setIsHeatStress(false);

                    getCornYieldRisks(
                        latitude,
                        longitude,
                        riskType,
                        startDate,
                        dateString,
                        thresholdValueForST,
                        minConsecutiveDaysForST,
                        maxConsecutiveDaysForST
                    );
                    break;
                }
                case "AirTemperatureAtEmergencePeriod": {
                    setModalTitle("Setting for Air temperature at emergence period");
                    setIsSoilTemperature(false);
                    setIsAirTemperature(true);
                    setIsSoilWater(false);
                    setIsParAtV(false);
                    setIsHeatStress(false);

                    getCornYieldRisks(
                        latitude,
                        longitude,
                        riskType,
                        startDate,
                        dateString,
                        thresholdValueForAT,
                        minConsecutiveDaysForAT,
                        maxConsecutiveDaysForAT
                    );
                    break;
                }
                case "SoilWaterContentDuringRootDevelopment": {
                    setModalTitle("Setting for Soil water content during root development");
                    setIsSoilTemperature(false);
                    setIsAirTemperature(false);
                    setIsSoilWater(true);
                    setIsParAtV(false);
                    setIsHeatStress(false);

                    getCornYieldRisks(
                        latitude,
                        longitude,
                        riskType,
                        startDate,
                        dateString,
                        minThresholdValueForSW,
                        maxThresholdValueForSW
                    );
                    break;
                }
                case "ParAtV6": {
                    setModalTitle("Setting for PAR at V6/V8");
                    setIsSoilTemperature(false);
                    setIsAirTemperature(false);
                    setIsSoilWater(false);
                    setIsParAtV(true);
                    setIsHeatStress(false);

                    getCornYieldRisks(
                        latitude,
                        longitude,
                        riskType,
                        startDate,
                        dateString,
                        minThresholdValueForPV,
                        maxThresholdValueForPV
                    );
                    break;
                }
                case "HeatStressAtFlowering": {
                    setModalTitle("Setting for Heat stress at flowering");
                    setIsSoilTemperature(false);
                    setIsAirTemperature(false);
                    setIsSoilWater(false);
                    setIsParAtV(false);
                    setIsHeatStress(true);

                    getCornYieldRisks(
                        latitude,
                        longitude,
                        riskType,
                        startDate,
                        dateString,
                        thresholdValueForHS,
                    );
                    break;
                }
                default: break;
            }
        } else {
            console.log("Clear");
        }
    };

    //modal change function
    const thresholdValueOnChangeForST=(e)=>{
        setThresholdValueForST(e);
    };
    const thresholdValueOnChangeForAT=(e)=>{
        setThresholdValueForAT(e);
    };
    const thresholdValueOnChangeForHS=(e)=>{
        setThresholdValueForHS(e);
    };
    const minThresholdValueOnChangeForSW=(e)=>{
        //console.log("minThresholdValueOnChangeForSW is",e);
        setMinThresholdValueForSW(e);
    };
    const minThresholdValueOnChangeForPV=(e)=>{
        //console.log("minThresholdValueOnChangeForPV is",e);
        setMinThresholdValueForPV(e);
    };
    const maxThresholdValueOnChangeForSW=(e)=>{
        //console.log("maxThresholdValueOnChange is",e);
        setMaxThresholdValueForSW(e);
    };
    const maxConsecutiveDaysOnChangeForAT=(e)=>{
        //console.log("maxConsecutiveDaysOnChange is",e);
        setMaxConsecutiveDaysForAT(e);
    };
    const maxConsecutiveDaysOnChangeForST=(e)=>{
        //console.log("maxConsecutiveDaysOnChange is",e);
        setMaxConsecutiveDaysForST(e);
    };

    const dateFormatList = ["DD/MM/YYYY", "DD/MM/YY", "DD-MM-YYYY", "DD-MM-YY"];

    const exportToExcel=async()=>{
        //console.log("This is exportToExcel");
        //console.log("This is exportToExcel riskType:",riskType);
        switch (riskType) {
            case "SoilTemperatureAtEmergencePeriod": {
                setModalTitle("Setting for Soil temperature at emergence period");
                setIsSoilTemperature(true);
                setIsAirTemperature(false);
                setIsSoilWater(false);
                setIsParAtV(false);
                setIsHeatStress(false);
                getCornYieldRisks(
                    latitude,
                    longitude,
                    riskType,
                    startDate,
                    endDate,
                    thresholdValueForST,
                    minConsecutiveDaysForST,
                    maxConsecutiveDaysForST,
                    null,
                    null,
                    true
                );
                break;
            }
            case "AirTemperatureAtEmergencePeriod": {
                setModalTitle("Setting for Air temperature at emergence period");
                setIsSoilTemperature(false);
                setIsAirTemperature(true);
                setIsSoilWater(false);
                setIsParAtV(false);
                setIsHeatStress(false);

                getCornYieldRisks(
                    latitude,
                    longitude,
                    riskType,
                    startDate,
                    endDate,
                    thresholdValueForAT,
                    minConsecutiveDaysForAT,
                    maxConsecutiveDaysForAT,
                    null,
                    null,
                    true
                );
                break;
            }
            case "SoilWaterContentDuringRootDevelopment": {
                setModalTitle("Setting for Soil water content during root development");
                setIsSoilTemperature(false);
                setIsAirTemperature(false);
                setIsSoilWater(true);
                setIsParAtV(false);
                setIsHeatStress(false);

                getCornYieldRisks(
                    latitude,
                    longitude,
                    riskType,
                    startDate,
                    endDate,
                    null,
                    null,
                    null,
                    minThresholdValueForSW,
                    maxThresholdValueForSW,
                    true
                );
                break;
            }
            case "ParAtV6": {
                setModalTitle("Setting for PAR at V6/V8");
                setIsSoilTemperature(false);
                setIsAirTemperature(false);
                setIsSoilWater(false);
                setIsParAtV(true);
                setIsHeatStress(false);

                getCornYieldRisks(
                    latitude,
                    longitude,
                    riskType,
                    startDate,
                    endDate,
                    null,
                    null,
                    null,
                    minThresholdValueForPV,
                    maxThresholdValueForPV,
                    true
                );
                break;
            }
            case "HeatStressAtFlowering": {
                setModalTitle("Setting for Heat stress at flowering");
                setIsSoilTemperature(false);
                setIsAirTemperature(false);
                setIsSoilWater(false);
                setIsParAtV(false);
                setIsHeatStress(true);

                getCornYieldRisks(
                    latitude,
                    longitude,
                    riskType,
                    startDate,
                    endDate,
                    thresholdValueForHS,
                    null,
                    null,
                    null,
                    null,
                    true
                );
                break;
            }
            default: break;
        }
    };

    //be empty
    const isNull = (obj) =>{
        return obj == null || obj == "" || obj.toString().trim() == "";
    };
    //be numeric which at most two decimal places and negative sign(-)
    const checkNumOrDecimal2 =(obj)=>{
        const string=/^(-|\+)?\d+(\.\d{1,2})?$/;
        //console.log("aaaaaaaaaaaa",string.test(obj));
        return string.test(obj);
    };
    //be numeric and greater than zero (>0)
    const checkNumGreaterZero =(obj)=>{
        const string=/^[1-9]\d*$/;
        //console.log("ssssssssssssssss",string.test(obj));
        return string.test(obj);
    };
    //be numeric which at most two decimal places.
    const checkPositiveNumOrDecimal2 =(obj)=>{
        const string=/^\d+(\.\d{1,2})?$/;
        //console.log("ssssssssssssssss",string.test(obj));
        return string.test(obj);
    };

    //Disabled Save button
    const [isDisabledSave,setIsDisabledSave]=useState(false);

    //Selected risk type:"Soil temperature at emergence period"
    //Check the Input is Valid before Save
    const [isNullForThresholdValueST,setIsNullForThresholdValueST]=useState(false);
    const [isInvalidForThresholdValueST,setIsInvalidForThresholdValueST]=useState(false);
    const thresholdValueForSTOnBlur=(value)=>{
        if(isNull(value))
        {
            //console.log("i am null");
            setIsNullForThresholdValueST(true);
            setIsInvalidForThresholdValueST(false);
            setIsDisabledSave(true);
        }
        else
        {
            if(checkNumOrDecimal2(value))
            {
                //console.log("I AM NUMBER");
                setIsNullForThresholdValueST(false);
                setIsInvalidForThresholdValueST(false);
                setIsDisabledSave(false);
            }
            else
            {
                setIsNullForThresholdValueST(false);
                setIsInvalidForThresholdValueST(true);
                //console.log("I AM not NUMBER");
                setIsDisabledSave(true);
            }}

    };
    const [isNullForMaxConST,setIsNullForMaxConST]=useState(false);
    const [isInvalidForMaxConST,setIsInvalidForMaxConST]=useState(false);
    const maxConsecutiveDaysForSTOnBlur=(value)=>{
        if(isNull(value))
        {
            //console.log("i am null");
            setIsNullForMaxConST(true);
            setIsInvalidForMaxConST(false);
            setIsDisabledSave(true);
        }
        else
        {
            if(checkNumGreaterZero(value))
            {
                //console.log("I AM NUMBER GREATER 0");
                setIsNullForMaxConST(false);
                setIsInvalidForMaxConST(false);
            }
            else
            {
                //console.log("I AM not NUMBER GREATER 0 ");
                setIsNullForMaxConST(false);
                setIsInvalidForMaxConST(true);
                setIsDisabledSave(true);
            }}

    };

    //Selected risk type:"Air temperature at emergence period"
    //Check the Input is Valid before Save
    const [isNullForThresholdValueAT,setIsNullForThresholdValueAT]=useState(false);
    const [isInvalidForThresholdValueAT,setIsInvalidForThresholdValueAT]=useState(false);
    const thresholdValueForATOnBlur=(value)=>{
        if(isNull(value))
        {
            //console.log("i am null");
            setIsNullForThresholdValueAT(true);
            setIsInvalidForThresholdValueAT(false);
            setIsDisabledSave(true);
        }
        else
        {
            if(checkNumOrDecimal2(value))
            {
                //console.log("I AM NUMBER");
                setIsNullForThresholdValueAT(false);
                setIsInvalidForThresholdValueAT(false);
                setIsDisabledSave(false);
            }
            else
            {
                setIsNullForThresholdValueAT(false);
                setIsInvalidForThresholdValueAT(true);
                //console.log("I AM not NUMBER");
                setIsDisabledSave(true);
            }}

    };
    const [isNullForMaxConAT,setIsNullForMaxConAT]=useState(false);
    const [isInvalidForMaxConAT,setIsInvalidForMaxConAT]=useState(false);
    const maxConsecutiveDaysForATOnBlur=(value)=>{
        if(isNull(value))
        {
            //console.log("i am null");
            setIsNullForMaxConAT(true);
            setIsInvalidForMaxConAT(false);
            setIsDisabledSave(true);
        }
        else
        {
            if(checkNumGreaterZero(value))
            {
                //console.log("I AM NUMBER GREATER 0");
                setIsNullForMaxConAT(false);
                setIsInvalidForMaxConAT(false);
            }
            else
            {
                //console.log("I AM not NUMBER GREATER 0 ");
                setIsNullForMaxConAT(false);
                setIsInvalidForMaxConAT(true);
                setIsDisabledSave(true);
            }}

    };

    //Selected risk type:"Soil water content during root development"
    //Check the Input is Valid before Save
    const [isNullForMinThresholdValueSW,setIsNullForMinThresholdValueSW]=useState(false);
    const [isInvalidForMinThresholdValueSW,setIsInvalidForMinThresholdValueSW]=useState(false);
    const minThresholdValueForSWOnBlur=(value)=>{
        if(isNull(value))
        {
            //console.log("i am null");
            setIsNullForMinThresholdValueSW(true);
            setIsInvalidForMinThresholdValueSW(false);
            setIsDisabledSave(true);
        }
        else
        {
            if(checkPositiveNumOrDecimal2(value))
            {
                //console.log("I AM NUMBER");
                setIsNullForMinThresholdValueSW(false);
                setIsInvalidForMinThresholdValueSW(false);
                setIsDisabledSave(false);
            }
            else
            {
                setIsNullForMinThresholdValueSW(false);
                setIsInvalidForMinThresholdValueSW(true);
                //console.log("I AM not NUMBER");
                setIsDisabledSave(true);
            }}

    };
    const [isNullForMaxThresholdValueSW,setIsNullForMaxThresholdValueSW]=useState(false);
    const [isInvalidForMaxThresholdValueSW,setIsInvalidForMaxThresholdValueSW]=useState(false);
    const maxThresholdValueForSWOnBlur=(value)=>{
        if(isNull(value))
        {
            //console.log("i am null");
            setIsNullForMaxThresholdValueSW(true);
            setIsInvalidForMaxThresholdValueSW(false);
            setIsDisabledSave(true);
        }
        else
        {
            if(checkPositiveNumOrDecimal2(value))
            {
                //console.log("I AM NUMBER");
                setIsNullForMaxThresholdValueSW(false);
                setIsInvalidForMaxThresholdValueSW(false);
                setIsDisabledSave(false);
            }
            else
            {
                setIsNullForMaxThresholdValueSW(false);
                setIsInvalidForMaxThresholdValueSW(true);
                //console.log("I AM not NUMBER");
                setIsDisabledSave(true);
            }}

    };

    //Selected risk type:"PAR at V6/V8"
    //Check the Input is Valid before Save
    const [isNullForMinThresholdValuePV,setIsNullForMinThresholdValuePV]=useState(false);
    const [isInvalidForMinThresholdValuePV,setIsInvalidForMinThresholdValuePV]=useState(false);
    const minThresholdValueForPVOnBlur=(value)=>{
        if(isNull(value))
        {
            //console.log("i am null");
            setIsNullForMinThresholdValuePV(true);
            setIsInvalidForMinThresholdValuePV(false);
            setIsDisabledSave(true);
        }
        else
        {
            if(checkNumGreaterZero(value))
            {
                //console.log("I AM NUMBER");
                setIsNullForMinThresholdValuePV(false);
                setIsInvalidForMinThresholdValuePV(false);
                setIsDisabledSave(false);
            }
            else
            {
                setIsNullForMinThresholdValuePV(false);
                setIsInvalidForMinThresholdValuePV(true);
                //console.log("I AM not NUMBER");
                setIsDisabledSave(true);
            }}

    };

    //Selected risk type:"Heat stress at flowering"
    //Check the Input is Valid before Save
    const [isNullForThresholdValueHS,setIsNullForThresholdValueHS]=useState(false);
    const [isInvalidForThresholdValueHS,setIsInvalidForThresholdValueHS]=useState(false);
    const thresholdValueForHSOnBlur=(value)=>{
        if(isNull(value))
        {
            //console.log("i am null");
            setIsNullForThresholdValueHS(true);
            setIsInvalidForThresholdValueHS(false);
            setIsDisabledSave(true);
        }
        else
        {
            if(checkNumOrDecimal2(value))
            {
                //console.log("I AM NUMBER");
                setIsNullForThresholdValueHS(false);
                setIsInvalidForThresholdValueHS(false);
                setIsDisabledSave(false);
            }
            else
            {
                setIsNullForThresholdValueHS(false);
                setIsInvalidForThresholdValueHS(true);
                //console.log("I AM not NUMBER");
                setIsDisabledSave(true);
            }}

    };

    const stringToDate=(strDate)=> {
        const tempStrs = strDate.split(" ");

        const dateStrs = tempStrs[0].split("/");
        const year = parseInt(dateStrs[2], 10);
        const month = parseInt(dateStrs[1], 10) - 1;
        const day = parseInt(dateStrs[0], 10);

        const date = new Date(year, month, day);
        return date;
    };


    useEffect(()=>{
        const tmpData= JSON.parse(localStorage.getItem("cornYieldRiskBookMark"));
        const tmpThresholdValueForAT=10.00;
        const tmpThresholdValueForST=10.00;
        const tmpThresholdValueForHS=10.00;
        const tmpMinThresholdValueForSW=1;
        const tmpMinThresholdValueForPar=1;
        const tmpMaxConsecutiveDaysForST=5;
        const tmpMaxConsecutiveDaysForAT=5;
        console.log("3333333333i no have datas");
        if(tmpData){
            console.log("33333333i have datas");
            tmpData?.map((item)=>{
                if(item.setting=="SoilTemperatureAtEmergencePeriod")
                {
                    setThresholdValueForST(item.thresholdValue);
                    setMaxConsecutiveDaysForST(item.maxConsecutiveDays);
                }
                if(item.setting=="AirTemperatureAtEmergencePeriod")
                {
                    setThresholdValueForAT(item.thresholdValue);
                    setMaxConsecutiveDaysForAT(item.maxConsecutiveDays);
                }
                if(item.setting=="SoilWaterContentDuringRootDevelopment")
                {
                    setMinThresholdValueForSW(item.minThresholdValue);
                    setMaxThresholdValueForSW(item.maxThresholdValue);
                }
                if(item.setting=="ParAtV6")
                {
                    setMinThresholdValueForPV(item.minThresholdValue);
                }
                if(item.setting=="HeatStressAtFlowering")
                {
                    setThresholdValueForHS(item.thresholdValue);
                }

            });
            console.log("555555",tmpData);}
    },[]);
    return (
        <Spin spinning={showLodding}>
            <div className="corn-yield-risk-all-container">
                <div className={`corn-yield-risk-container bg-${searchObj.theme}`}
                    id = "corn-yield-risk-container-modal"
                >
                    {/* Modal */}
                    <Modal className="corn-yield-risk-modal"
                        title={modalTitle}
                        open={isModalOpen}
                        onOk={handleOk}
                        onCancel={handleCancel}
                        width={isSoilWater?790:755}
                        footer={[
                            <Button key="back" className="modal-footer-cancel-button" onClick={handleCancel}>
                      Cancel and return
                            </Button>,
                            <button key="submit"
                                className={isDisabledSave?"modal-footer-DisabledSave-button": "modal-footer-save-button"}
                                onClick={handleOk}
                                disabled={isDisabledSave}>
                      Save
                            </button>
                        ]}
                        getContainer = {()=> document.getElementById("corn-yield-risk-container-modal") as HTMLElement}
                    >
                        {/* Selected risk type:"Soil temperature at emergence period" */}
                        <IF show={isSoilTemperature}>
                            <div className="cornYieldRisk-modal-content">
                                <div className="setting-details">
                                    <GreenCircleIcon/>
                                    <span className="pStyle">No stress event, if temperature is greater than or equal to</span>
                                    <Space.Compact size="large">
                                        <Input addonAfter={"℃"} placeholder="10.00"
                                            value={thresholdValueForST}
                                            onBlur={(e)=>{thresholdValueForSTOnBlur(e.target.value);}}
                                            onChange={(e)=>{
                                                thresholdValueOnChangeForST(e.target.value);
                                            }}/>
                                    </Space.Compact>
                                </div>
                                <div className="errorDiv">
                                    <IF show={isNullForThresholdValueST}>
                                        <p className="errorP" >Temperature is required.</p>
                                    </IF>
                                    <IF show={isInvalidForThresholdValueST}>
                                        <p className="errorP" >
                                    Temperature should be numeric which at most two decimal places and negative sign(-) can be used before number, to input minus temperatures.</p>
                                    </IF>
                                </div>
                                <div className="setting-details">
                                    <p>
                                        <OrangeCircleIcon/>
                                        <span className="pStyle">Pre-stress event, if temperature is less than or equal to 10.00 °C for not up to</span>
                                        <Space.Compact size="large">
                                            <Input addonAfter={"days"} placeholder="5"
                                                value={maxConsecutiveDaysForST}
                                                onBlur={(e)=>{maxConsecutiveDaysForSTOnBlur(e.target.value);}}
                                                onChange={(e)=>{
                                                    maxConsecutiveDaysOnChangeForST(e.target.value);
                                                }}/>
                                        </Space.Compact>
                                    </p>
                                </div>
                                <div className="errorDiv">
                                    <IF show={isNullForMaxConST}>
                                        <p className="errorP" >Consecutive days is required.</p>
                                    </IF>
                                    <IF show={isInvalidForMaxConST}>
                                        <p className="errorP" >
                                Consecutive days should be numeric and greater than zero ({">"}0).</p>
                                    </IF>
                                </div>
                                <div className="setting-details">
                                    <p><RedCircleIcon/>
                                        <span className="pStyle">Stress event, if temperature less than or equal to 10.00 °C for greater than {maxConsecutiveDaysForST} consecutive days.</span>
                                    </p>
                                </div>
                            </div>
                        </IF>
                        {/* Selected risk type:"Air temperature at emergence period" */}
                        <IF show={isAirTemperature}>
                            <div className="cornYieldRisk-modal-content">
                                <div className="setting-details">
                                    <p><GreenCircleIcon/>
                                        <span className="pStyle">No stress event, if temperature is greater than or equal to</span>
                                        <Space.Compact size="large">
                                            <Input addonAfter={"℃"} placeholder="10.00"
                                                value={thresholdValueForAT}
                                                onBlur={(e)=>{thresholdValueForATOnBlur(e.target.value);}}
                                                onChange={(e)=>{
                                                    thresholdValueOnChangeForAT(e.target.value);
                                                }}/>
                                        </Space.Compact>
                                    </p>
                                </div>
                                <div className="errorDiv">
                                    <IF show={isNullForThresholdValueAT}>
                                        <p className="errorP" >Temperature is required.</p>
                                    </IF>
                                    <IF show={isInvalidForThresholdValueAT}>
                                        <p className="errorP" >
                                    Temperature should be numeric which at most two decimal places and negative sign(-) can be used before number, to input minus temperatures.</p>
                                    </IF>
                                </div>
                                <div className="setting-details">
                                    <p>
                                        <OrangeCircleIcon/>
                                        <span className="pStyle">Pre-stress event, if temperature is less than or equal to 10.00 °C for not up to</span>
                                        <Space.Compact size="large">
                                            <Input addonAfter={"days"} placeholder="5"
                                                value={maxConsecutiveDaysForAT}
                                                onBlur={(e)=>{maxConsecutiveDaysForATOnBlur(e.target.value);}}
                                                onChange={(e)=>{
                                                    maxConsecutiveDaysOnChangeForAT(e.target.value);
                                                }}/>
                                        </Space.Compact>
                                    </p>
                                </div>
                                <div className="errorDiv">
                                    <IF show={isNullForMaxConAT}>
                                        <p className="errorP" >Consecutive days is required.</p>
                                    </IF>
                                    <IF show={isInvalidForMaxConAT}>
                                        <p className="errorP" >
                                Consecutive days should be numeric and greater than zero ({">"}0).</p>
                                    </IF>
                                </div>
                                <div className="setting-details">
                                    <p><RedCircleIcon/>
                                        <span className="pStyle">Stress event, if temperature less than or equal to 10.00 °C for greater than {maxConsecutiveDaysForAT} consecutive days.</span>
                                    </p>
                                </div>
                            </div>
                        </IF>
                        {/* Selected risk type:"Soil water content during root development" */}
                        <IF show={isSoilWater}>
                            <div className="cornYieldRisk-modal-content">
                                <div className="setting-details">
                                    <p><GreenCircleIcon/>
                                        <span className="pStyle">No stress event, if daily precipitation with respect to long term precipitation is ±</span>
                                        <Space.Compact size="large">
                                            <Input addonAfter={"%"} placeholder="5"
                                                value={minThresholdValueForSW}
                                                onBlur={(e)=>{minThresholdValueForSWOnBlur(e.target.value);}}
                                                onChange={(e)=>{
                                                    minThresholdValueOnChangeForSW(e.target.value);
                                                }}/>
                                        </Space.Compact>
                                    </p>
                                </div>
                                <div className="errorDiv">
                                    <IF show={isNullForMinThresholdValueSW}>
                                        <p className="errorP" >Percent is required.</p>
                                    </IF>
                                    <IF show={isInvalidForMinThresholdValueSW}>
                                        <p className="errorP" >
                                Percent should be numeric which at most two decimal places.</p>
                                    </IF>
                                </div>
                                <div className="setting-details">
                                    <p>
                                        <OrangeCircleIcon/>
                                        <span className="pStyle">Pre stress event, if daily precipitation with respect to long term precipitation is up to ± </span>
                                        <Space.Compact size="large">
                                            <Input addonAfter={"%"} placeholder="20"
                                                value={maxThresholdValueForSW}
                                                onBlur={(e)=>{maxThresholdValueForSWOnBlur(e.target.value);}}
                                                onChange={(e)=>{
                                                    maxThresholdValueOnChangeForSW(e.target.value);
                                                }}/>
                                        </Space.Compact>
                                    </p>
                                </div>
                                <div className="errorDiv">
                                    <IF show={isNullForMaxThresholdValueSW}>
                                        <p className="errorP" >Percent is required.</p>
                                    </IF>
                                    <IF show={isInvalidForMaxThresholdValueSW}>
                                        <p className="errorP" >
                                Percent should be numeric which at most two decimal places.</p>
                                    </IF>
                                </div>
                                <div className="setting-details">
                                    <p><RedCircleIcon/>
                                        <span className="pStyle">Stress event, if daily precipitation with respect to long term precipitation is greater than ± {maxThresholdValueForSW} %</span>
                                    </p>
                                </div>
                            </div>
                        </IF>
                        {/* Selected risk type:"PAR at V6/V8" */}
                        <IF show={isParAtV}>
                            <div className="cornYieldRisk-modal-content">
                                <div className="setting-details">
                                    <p><GreenCircleIcon/>
                                        <span className="pStyle">No stress event, if daily PAR is equal to or greater than to long term average PAR.</span>
                                    </p>
                                </div>
                                <div className="setting-details">
                                    <p>
                                        <OrangeCircleIcon/>
                                        <span className="pStyle">Pre stress event, if daily PAR with respect to long term average PAR is up to -</span>
                                        <Space.Compact size="large">
                                            <Input addonAfter={"%"} placeholder="10"
                                                value={minThresholdValueForPV}
                                                onBlur={(e)=>{minThresholdValueForPVOnBlur(e.target.value);}}
                                                onChange={(e)=>{
                                                    minThresholdValueOnChangeForPV(e.target.value);
                                                }}/>
                                        </Space.Compact>
                                    </p>
                                </div>
                                <div className="errorDiv">
                                    <IF show={isNullForMinThresholdValuePV}>
                                        <p className="errorP" >Percent is required.</p>
                                    </IF>
                                    <IF show={isInvalidForMinThresholdValuePV}>
                                        <p className="errorP" >
                                Percent should be integer and greater than zero ({">"}0).</p>
                                    </IF>
                                </div>
                                <div className="setting-details">
                                    <p><RedCircleIcon/>
                                        <span className="pStyle">Stress event, if daily PAR with respect to long term average PAR is less than -{minThresholdValueForPV} %.</span>
                                    </p>
                                </div>
                            </div>
                        </IF>
                        {/* Selected risk type:"Heat stress at flowering" */}
                        <IF show={isHeatStress}>
                            <div className="cornYieldRisk-modal-content">
                                <div className="setting-details">
                                    <p><OrangeCircleIcon/>
                                        <span className="pStyle">Pre-stress event, if Day-night amplitude is greater than or equal to</span>
                                        <Space.Compact size="large">
                                            <Input addonAfter={"℃"} placeholder="10.00"
                                                value={thresholdValueForHS}
                                                onBlur={(e)=>{thresholdValueForHSOnBlur(e.target.value);}}
                                                onChange={(e)=>{
                                                    thresholdValueOnChangeForHS(e.target.value);
                                                }}/>
                                        </Space.Compact>
                                    </p>
                                </div>
                                <div className="errorDiv">
                                    <IF show={isNullForThresholdValueHS}>
                                        <p className="errorP" >Day-night amplitude is required.</p>
                                    </IF>
                                    <IF show={isInvalidForThresholdValueHS}>
                                        <p className="errorP" >
                                Day-night amplitude should be numeric which at most two decimal places and negative sign(-) can be used before number, to input minus amplitude.</p>
                                    </IF>
                                </div>
                                <div className="setting-details">
                                    <p><RedCircleIcon/>
                                        <span className="pStyle">Stress event, if Day-night amplitude is less than 10.00 °C.</span>
                                    </p>
                                </div>
                            </div>
                        </IF>
                    </Modal>
                    {/*container */}
                    <Row>
                        <Col
                        //  span={6}
                            xs = {24}
                            sm = {24}
                            md = {24}
                            lg = {24}
                            xl = {6}
                            xxl = {6}
                            style={{marginRight:"4%"}}>
                            <div className="accurate-left">
                                <div className="head-title">
                                Corn Yield Risk Identification
                                </div>
                                <div className="ab-bookmark-container">
                                    <div className="ag-bookmark">
                                        <label className="bookmark-title">
                                Select your location
                                        </label>
                                        <div className="select-div">
                                            <Select
                                                className="select"
                                                size="large"
                                                value={point}
                                                onChange={e => { pointOnChange(e); }}
                                                defaultValue="M_1"
                                            >
                                                <option></option>
                                                {
                                                    locationSearch ? locationSearch.map((item, index) => {
                                                        return <option key={item.pointId+","+item.point} >{item.point}</option>;
                                                    }) : []
                                                }
                                            </Select>
                                        </div>
                                    </div>
                                    <div className="ag-bookmark">
                                        <label className="bookmark-title">
                                    Select your risk type
                                        </label>
                                        <div className="select-div">
                                            <Select
                                                className="select"
                                                size="large"
                                                value={riskType}
                                                onChange={e => { riskTypeOnChange(e); }}
                                                options={[
                                                    { value: "SoilTemperatureAtEmergencePeriod", label: "Soil temperature at emergence period" },
                                                    { value: "AirTemperatureAtEmergencePeriod", label: "Air temperature at emergence period" },
                                                    { value: "SoilWaterContentDuringRootDevelopment", label: "Soil water content during root development" },
                                                    { value: "ParAtV6", label: "PAR at V6/V8" },
                                                    { value: "HeatStressAtFlowering", label: "Heat stress at flowering" },
                                                ]}
                                                defaultValue="SoilTemperatureAtEmergencePeriod"
                                            />
                                        </div>
                                    </div>
                                    <div className="ag-bookmark">
                                        <Row>
                                            <Col span={11}>
                                                <label className="bookmark-title">
                            Select start date
                                                </label>
                                                <div className="select-div">
                                                    <DatePicker
                                                        size="large"
                                                        defaultValue={dayjs("01/04/2023", dateFormatList[0])}
                                                        format={dateFormatList}
                                                        value={dayjs(startDate, dateFormatList[0])}
                                                        onChange={startDateOnChange}
                                                    />
                                                </div>
                                            </Col>
                                            <Col span={2}></Col>
                                            <Col span={11}>
                                                <label className="bookmark-title">
                            Select end date
                                                </label>
                                                <div className="select-div">
                                                    <DatePicker
                                                        size="large"
                                                        defaultValue={dayjs("09/07/2023", dateFormatList[0])}
                                                        format={dateFormatList}
                                                        value={dayjs(endDate, dateFormatList[0])}
                                                        onChange={endDateOnChange}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                        <div className="bottom-div">
                                            <button onClick={exportToExcel}>Export to Excel</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col
                        // span={17}
                            xs = {24}
                            sm = {24}
                            md = {24}
                            lg = {24}
                            xl = {17}
                            xxl = {17}
                        >
                            <div className="accurate-right">
                                <div className="circle-round-div">
                                    <div className="circle-bottom-div">
                                        <div className="circle-left-div">
                                            <label>
                                Stress Event Identification
                                            </label>
                                        </div>
                                        <div className="circle-middle-div" >
                                            <div className="setting-div">
                                                <span onClick={showModal}><ManageSettingsIcon />
                                                    <label>
                                Manage Settings
                                                    </label>
                                                </span>
                                            </div>
                                            <div className="download-div"
                                                onClick={()=>{
                                                    saveStressEventToDataUrl();
                                                }}>
                                                <DownloadAsImageIcon/>
                                                <label>
                                  Download as Image
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="circle-content" id="stressEventDiv">
                                        <ReactEcharts
                                            option={stressEventOption}
                                            style={{width:"90%",height:"300px"}}
                                            notMerge
                                            lazyUpdate />
                                        {/* <div
                            ref={chartRef}
                            style={{width:"839px",height:"300px"}}>
                        </div> */}
                                    </div>
                                    <div className="circle-bottom-div">
                                        <div className="circle-left-div">
                                            <div className="download-div">
                                                <p className="slideText">
                                    Slide to zoom in on the timeline
                                                </p>
                                            </div>
                                            {/* <Input className="leftInput" value={startDateInput}/> */}
                                            {/* <div className="download-div">
                                <p className="slideText">
                                    Slide to zoom in on the timeline
                                </p>
                            </div> */}
                                            {/* <Input className="rightInput" value={endDateInput} /> */}
                                            {/* <Row style={{marginTop:"-69px"}}>
                                <Col span={6}>
                                    <Input style={{width:"60px",height:"32px",padding:"0",fontSize:"10px"}} value={endDateInput}/>
                                </Col>
                                <Col span={12}>
                                    <p>
                                    Slide to zoom in on the timeline
                                    </p>
                                </Col>
                                <Col span={6} style={{textAlign:"right"}}>
                                    <Input style={{width:"60px",height:"32px",padding:"0",fontSize:"10px"}} value={startDateInput} />
                                </Col>
                            </Row> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="circle-round-div">
                                    <div className="circle-bottom-div">
                                        <div className="circle-left-div">
                                            <label>
                                                {title}
                                                <span style={{marginLeft:"5px",marginRight:"5px"}}>-</span>
                                                {placeName}
                                            </label>
                                        </div>
                                        <div className="circle-middle-div"
                                            onClick={()=>{
                                                saveSoilTemperatureToDataUrl();
                                            }}>
                                            <div className="download-div">
                                                <DownloadAsImageIcon/>
                                                <label>
                                                Download as Image
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`${isHeatStress? "circle-content" : "circle-content clearPadding"}`}
                                        id="soilTemperatureDiv"
                                    >
                                        {/* <div
                            ref={chartRef1}
                            style={{width:"839px",height:"300px"}}>
                        </div> */}
                                        <ReactEcharts
                                            option={isHeatStress?soilTemperatureOption2:soilTemperatureOption}
                                            className={isHeatStress?"echartsStyle2":"echartsStyle1"}
                                            notMerge
                                            lazyUpdate />
                                    </div>
                                    <div className="circle-bottom-div">
                                        <div className="circle-left-div">
                                            <div className="download-div">
                                                <p className="slideText">
                                    Slide to zoom in on the timeline
                                                </p>
                                            </div>
                                            {/* <Input className="leftInput" value={startDateInputTem}/> */}
                                            {/* <div className="download-div">
                                <p className="slideText">
                                    Slide to zoom in on the timeline
                                </p>
                            </div> */}
                                            {/* <Input className="rightInput" value={endDateInputTem} /> */}
                                            {/* <Row style={{marginTop:"-69px"}}>
                                <Col span={6}>
                                    <Input style={{width:"60px",height:"32px"}} value={endDateInput}/>
                                </Col>
                                <Col span={12}>
                                    <p>
                                    Slide to zoom in on the timeline
                                    </p>
                                </Col>
                                <Col span={6} style={{textAlign:"right"}}>
                                    <Input style={{width:"60px",height:"32px"}} value={startDateInput} />
                                </Col>
                            </Row> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>

        </Spin>
    );
};

export default CornYieldRisk;