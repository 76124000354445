import { Col, Row , Image} from "antd";
import React from "react";
import "./index.less";
import {getNozzleImage} from "../../init";


const LegendAndDescription = ()=>{

    return(
        <div className="legend-and-description-container">
            <Row align="middle" style={{ margin: "20px 0" }}>
                <Col
                    md={24}
                    xs={24}
                    sm={24}
                    className="legend-title-container"
                >
                    <span>Legend</span>
                    <span>Description </span>
                </Col>
                <Col md={24} xs={24} sm={24} style={{ marginTop: 10 }}>
                    <Image
                        preview={false}
                        src={getNozzleImage("red",true)}
                        width={60}
                    />
                    <span style={{ paddingLeft: 70 }}>
            Heat stress predicted - Apply Quantis
                    </span>
                </Col>
                <Col md={24} xs={24} sm={24}>
                    <Image
                        preview={false}
                        src={getNozzleImage("orange",true)}
                        width={60}
                    />
                    <span style={{ paddingLeft: 70 }}>
            Conditions may change, be prepared
                    </span>
                </Col>
                <Col md={24} xs={24} sm={24}>
                    <Image
                        preview={false}
                        src={getNozzleImage("green",true)}
                        width={60}
                    />
                    <span style={{ paddingLeft: 70 }}>No heat stress predicted</span>
                </Col>
                <Col md={24} xs={24} sm={24} style={{ margin: "10 0",height:"48px",lineHeight:"48px" }}>
                    <div
                        style={{
                            width: 60,
                            height: 14,
                            position: "relative",
                            display: "inline-block",
                            top: 6,
                        }}
                    >
                        <div
                            style={{
                                width: "15px",
                                height: "8px",
                                marginRight: 4,
                                float: "left",
                            }}
                            className={"greenbox"}
                        ></div>
                        <div
                            style={{
                                width: "15px",
                                height: "8px",
                                marginRight: 4,
                                float: "left",
                            }}
                            className={"greenbox"}
                        ></div>
                        <div
                            style={{
                                width: "15px",
                                height: "8px",
                                marginRight: 4,
                                float: "left",
                            }}
                            className={"greenbox"}
                        ></div>
                    </div>

                    <span style={{ paddingLeft: 70 }}>Defines confidence level</span>
                </Col>
            </Row>
        </div>
    );
};

export default LegendAndDescription;