import { sagaActionTypes } from "@/redux/actions/diseaseAction";
import { RootState } from "@/redux/reducer/store";
import { getSeries } from "@/util/http/HistWeather";
import { IGetSeriesResModel } from "@/util/http/HistWeather/responseType";
import { useEffect,useState } from "react";
import { useDispatch, useSelector } from "react-redux";

interface IProp{
    countryCode: string;
    language: string;
}

interface IDiseaseMapTranslate{
    txt_SelDiseaseToViewMap?: string;
    txt_SelStartDate?: string;
    txt_DownloadAsImage?: string;
    txt_Legend?: string;
    txt_DiseaseRisks?: string;
    gc_SelectRegion?: string;
    txt_DiseaseMap?: string;
    txt_Color?: string;
}

const useDiseaseMapTranslate = (props:IProp)=>{
    const {
        countryCode,
        language
    } = props;
    const dispatch = useDispatch();
    const {
        translateText
    } = useSelector((state: RootState) => state.diseaseReducer);

    const [diseaseMapTranslate,setDiseaseMapTranslate] = useState<IDiseaseMapTranslate>(null);

    const TranslateObj = {
        "txt_SelDiseaseToViewMap": "txt_SelDiseaseToViewMap", // Select disease to view map
        "txt_SelStartDate": "txt_SelStartDate", // select start date
        "txt_DownloadAsImage": "txt_DownloadAsImage", // download as Image
        "txt_Legend": "txt_Legend", // Legend
        "txt_DiseaseRisks": "txt_DiseaseRisks", // disesas risks
        "gc_SelectRegion": "gc_SelectRegion", // select Region
        "txt_DiseaseMap": "txt_DiseaseMap", // Diseae Map
        "txt_Color": "txt_Color", // Color
    };

    // when get translatetext from api, assign to page item
    const setPageItemToTranslate = ()=>{
        console.log("translateText",translateText);
        const tmpTranslate = {...diseaseMapTranslate};
        translateText.forEach((item)=>{
            if(TranslateObj[item.key]) tmpTranslate[TranslateObj[item.key]] = item.value;
        });
        setDiseaseMapTranslate(tmpTranslate);
    };

    const getTranslateFunc = ()=>{
        // get translate text by saga, and put response to the redux store
        dispatch({
            type: sagaActionTypes.SAGA_TRANSLATETEXT,
            payload:{
                strCultureCode:language + "-" + countryCode,
                pubname: "glibsip03p"
            }
        });
    };

    useEffect(()=>{
        if(countryCode && language) getTranslateFunc();
    },[countryCode,language]);

    useEffect(()=>{
        if(translateText?.length > 0) setPageItemToTranslate();
    },[translateText]);

    return {
        diseaseMapTranslate
    };
};

export default useDiseaseMapTranslate;