import React from "react";
import "./index.less";

const AGTwoDayWeather = () => {

    return (
        <div>
            Home page
        </div>
    );
};

export default AGTwoDayWeather;