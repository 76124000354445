import React, { useState, useEffect,useCallback } from "react";
import {Select,Row,Col,Input,message,Spin,Alert,Modal,Button} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/redux/reducer/store";
import "./index.less";
import QuerySelectComponent from "./components/QuerySelect";
import QueryTabPaneComponent from "./components/QueryTabPane";
import { StartIcon, CloseIcon} from "@/resource/IconComponents";
import { normalActionTypes } from "@/redux/actions/diseaseAction";
import { normalActionTypes as simulationAction} from "@/redux/actions/simulationAction";
import {
    getCountriesForSimulation,
    getCorpsForSimulation,
    getLocationsForSimulation,
    getHierarchiesForSimulation,
    getMetadataByGroupForSimulation,
    executeQueryForSimulation,
    checkQueryIsExistsForSimulation,
    getSoilMaxDepthForSimulation,
    checkQueryNameIsExistsForSimulation,
    saveQueryForSimulation,
    getRecentQueriesForSimulation
} from "@/util/http";
import { sagaActionTypes } from "@/redux/actions/commonAction";
import { useLocation, useNavigate } from "react-router-dom";
import IF from "@/components/IF";
import HeadComponent from "./components/Head";
import SideBarComponent from "./components/SideBar";
import GoogleMapComponents from "./components/GoogleMapComponents";
//import store from "store";

const CreateQuery = () => {
    const { showLodding } = useSelector(
        (state: RootState) => state.diseaseReducer
    );
    const {
        simulationIsMapClick,
        simulationLocationInfo,
        simulationClickCount
    } = useSelector((state: RootState) => state.simulationReducer);

    const ssoSigninUrl: string | undefined = useSelector((state:RootState) => state.commonReducer.ssoSigninUrl);
    const token: string | undefined = useSelector((state: any) => state.commonReducer?.token || localStorage.getItem("simulation-token"));
    //const user: any = useSelector((state: any) => state.simulation?.user || store.get("simulation-user"));
    const user:any ="user";

    const dispatch = useDispatch();
    const location = useLocation();

    useEffect(() => {
        if (location.pathname != "/simulation/environmental-characterization") {
            return;
        }
        const { hash } = location;
        const _codeVerifier = localStorage.getItem("simulation-code-verifier");
        const _redirectUrl = localStorage.getItem("simulation-redirect-url");

        if (_codeVerifier && hash.length > 0 && hash?.toLowerCase().indexOf("code=") > -1 && !token) {
            const result = hash.replace("#code=", "");
            const authorizedCode = result.split("&")[0];
            dispatch({ type: sagaActionTypes.SAGA_GET_TOKEN, payload: { authorizedCode, codeVerifier: _codeVerifier } });
        } else if (token) {
            localStorage.removeItem("simulation-redirect-url");
            localStorage.removeItem("simulation-code-verifier");
        } else {
            // if ((!_codeVerifier || !_redirectUrl) && !token) {
            //     dispatch({ type: sagaActionTypes.SAGA_GET_SIGN_URL });
            // }
            // else
            if (_redirectUrl && _redirectUrl.length > 0) {
                window.location.href = _redirectUrl;
            }
        }
    }, [location, token, ssoSigninUrl]);

    const [showMap, setShowMap] = useState<boolean>(true);
    const [isShowSeason,setIsShowSeason]=useState(false);
    const [chartsData, setChartsData] = useState([] as any);
    //country
    const CountryBrazil = "brazil";
    const [selectedCountry, setSelectedCountry] = useState<string | null>(null);
    const [countryList, setCountryList] = useState([]);
    //crop
    const [selectedCrop, setSelectedCrop] = useState<string | null>(null);
    const [cropList, setCropList] = useState([]);
    //location
    const [selectedLocation, setSelectedLocation] = useState<string | null>(null);
    const [locationList, setLocationList] = useState([]);
    const [filteredLocationList, setFilteredLocationList] = useState([]);
    //SoilTexture
    const [selectedSoilTexture, setSelectedSoilTexture] = useState<string | null>(null);
    const [soilTextureList, setSoilTextureList] = useState([]);
    //Enso
    const [selectedEnso, setSelectedEnso] = useState<string | null>(null);
    const [ensoList, setEnsoList] = useState([]);
    //Faw
    const [selectedFaw, setSelectedFaw] = useState("");
    const [fawList, setFawList] = useState([]);
    //Cultivar
    const [selectedCultivar, setSelectedCultivar] = useState<string[]>([]);
    const [cultivarList, setCultivarList] = useState([]);
    //Water
    const [selectedWater, setSelectedWater] = useState<string[]>([]);
    const [waterList, setWaterList] = useState([]);
    //Percentile
    const [selectedPercentile, setSelectedPercentile] = useState<string | null>(null);
    const [percentileList, setPercentileList] = useState([]);
    //SoilDepth
    const [selectedSoilDepth, setSelectedSoilDepth] = useState<string[]>([]);
    const [soilDepthList, setSoilDepthList] = useState([]);
    //Season
    const [selectedSeason, setSelectedSeason] = useState<string | null>(null);
    const [seasonList, setSeasonList] = useState([]);

    const [formEditable, setFormEditable] = useState<boolean>(true);
    const navigate = useNavigate();
    useEffect(() => {
        console.log("location.hash:===", location.hash);
        console.log("token:===", token);

        if (location.hash && location.hash.length > 0 && token && token.length > 0) {
            navigate(location.pathname);
        }
    }, [token, location]);

    // useEffect(() => {
    //     try {
    //         getRecentQueriesForSimulation({ limit: 1 });
    //     } catch(err) {
    //         console.log("test111111111111111111111111111111");
    //     }
    // }, []);


    //Call API
    const getCountries = async () => {
        const res = await getCountriesForSimulation();
        setCountryList(res.data);
        setFilteredWater(res.data);
    };
    const getCorps = async () => {
        const res = await getCorpsForSimulation(
            {
                isActive:true,
            });
        const tmpArray1 = [];
        res.data.map((item,index)=>{
            tmpArray1.push({
                value: item.crop,
            });
        });
        setCropList(tmpArray1);
    };
    const getLocations = async () => {
        const res = await getLocationsForSimulation(
            {
                isActive:true,
            });
        setLocationList(res.data);
    };
    const getSoilMaxDepth = async () => {
        const res = await getSoilMaxDepthForSimulation();
    };
    const getMetadataByGroup = async (selectedGroup:string) => {
        const res = await getMetadataByGroupForSimulation(
            {
                group:selectedGroup,
                isActive:true,
            });
        if(selectedGroup=="Enso")//
            setEnsoList(res.data);
        if(selectedGroup=="SoilTexture")
            setSoilTextureList(res.data);
        if(selectedGroup=="Faw")
            setFawList(res.data);
        if(selectedGroup=="Cultivar")
            setCultivarList(res.data);
        if(selectedGroup=="Water")
            setWaterList(res.data);
        if(selectedGroup=="Percentile")
            setPercentileList(res.data);
        if(selectedGroup=="SoilDepth")
            setSoilDepthList(res.data);
        if(selectedGroup=="Season")//
            setSeasonList(res.data);
    };
    const getHierarchies = async (hierarchy,country,crop?,location?) => {
        const res = await getHierarchiesForSimulation(
            {
                hierarchy: hierarchy,
                country: country,
                crop:crop,
                location:location
            }
        );
        return res.data;
    };

    // crop filtered by country
    const [filteredCrop, setFilteredCrop] = useState<string[]>([]);
    // filtered by country, crop, location
    const [filteredSoilTexture, setFilteredSoilTexture] = useState<string[]>([]);
    const [filteredSoilDepth, setFilteredSoilDepth] = useState<string[]>([]);
    const [filteredFaw, setFilteredFaw] = useState<string[]>([]);
    const [filteredEnso, setFilteredEnso] = useState<string[]>([]);
    const [filteredSeason, setFilteredSeason] = useState<string[]>([]);
    const [filteredWater, setFilteredWater] = useState<string[]>([]);
    const [filteredCultivar, setFilteredCultivar] = useState<string[]>([]);

    //CHANGE
    const firstLevelChanged = async (countryValue:any, cropValue:string, locationValue:any) => {
        console.log("countryValue",countryValue);
        setSelectedCountry(countryValue);
        if (countryValue) {
            //when country is changed,fiter other fields
            //Crop
            const filteredCrop=await getHierarchies("Crop",countryValue);
            setFilteredCrop(filteredCrop);
            //Location
            const tmpArr=[];
            locationList?.map((item,index)=>{
                if(item.country==countryValue)
                    tmpArr.push(
                        item
                    );
            });
            setFilteredLocationList(tmpArr);

            // missed
            if (cropValue && filteredCrop && !(filteredCrop as string[])?.includes(cropValue)) {
                // if (cropValue && filteredCrop && filteredCrop?.indexOf(cropValue) == -1) {
                setSelectedCrop(null);
                cropValue = null;
            } else {
                setSelectedCrop(cropValue);
            }
            // if (locationValue && tmpArr.filter(l => l.country == countryValue)?.map(x => x.value).indexOf(locationValue) == -1) {
            if (locationValue && tmpArr.filter(l => l.country == countryValue)?.map(x => x.location).indexOf(locationValue) == -1) {
                console.log("aaaaa change value tmpArr",tmpArr);
                console.log("aaaaa change value filteredCrop",filteredCrop);
                console.log("aaaaa change value locationValue",locationValue);
                setSelectedLocation(null);
                locationValue = null;
            } else {
                setSelectedLocation(locationValue);
            }
            //SoilTexture
            const filteredSoilTexture= await getHierarchies("SoilTexture",countryValue,cropValue,locationValue);
            setFilteredSoilTexture(filteredSoilTexture);
            //SoilDepth
            const filteredSoilDepth= await getHierarchies("SoilDepth",countryValue,cropValue,locationValue);
            setFilteredSoilDepth(filteredSoilDepth);
            //Faw
            const filteredFaw= await getHierarchies("Faw",countryValue,cropValue,locationValue);
            setFilteredFaw(filteredFaw);
            //Enso
            const filteredEnso= await getHierarchies("Enso",countryValue,cropValue,locationValue);
            setFilteredEnso(filteredEnso);
            //Water
            const filteredWater= await getHierarchies("Water",countryValue,cropValue,locationValue);
            setFilteredWater(filteredWater);
            //Cultivar
            const filteredCultivar= await getHierarchies("Cultivar",countryValue,cropValue,locationValue);
            setFilteredCultivar(filteredCultivar);
            if(countryValue?.toLowerCase() =="brazil"){
                //if country is "brazil",show Season label
                setIsShowSeason(true);
                //Season
                const filteredSeason= await getHierarchies("Season",countryValue,cropValue,locationValue);
                setFilteredSeason(filteredSeason);
            }
        }
        else{
            // setSelectedLocation(null);
            // setSelectedCrop(null);
        }
    };

    const [executeClickedStatus, setExecuteClickedStatus] = useState(true);
    const alertPopInfo = {
        className: executeClickedStatus ? "alert-tip" : "alert-tip-down",
        messageContent: executeClickedStatus
            ? "Select a location on the map to continue"
            : "<span style=\"font-family:Noto-Sans-Regular;font-size: 16px;font-weight: 400;line-height: 24px;letter-spacing: -0.0075em;text-align: left;\">"+
            "Please fill all required parameters. Once you have pressed Execute, the following will be calculated:<br/>- Attainable yield<br/>"
           + "- Flowering cycle<br/>- Crop cycle duration</span>",
    };
    const onCloseAlert = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setVisible(false);
    };
    // set divLeftScrollHeight
    const [divLeftScrollSize, setDivLeftScrollSize] = useState(569);
    const pageBodyHeight = window.screen.height;
    const divLeftScrollHeight = divLeftScrollSize + "px";

    useEffect(() => {
        // when pageBodyHeight=971,the whole divLeft can show,The height from the divLeft to the pageTop is 56
        if (pageBodyHeight < 971) {
            setDivLeftScrollSize(pageBodyHeight - 56);
        }
        if (pageBodyHeight >= 971) {
            setDivLeftScrollSize(971 - 56);
        }
        const _codeVerifier = localStorage.getItem("simulation-code-verifier");
        const _redirectUrl = localStorage.getItem("simulation-redirect-url");
        if ((!_codeVerifier || !_redirectUrl) && !token) {
            dispatch({ type: sagaActionTypes.SAGA_GET_SIGN_URL });
        }else{
            getCountries();
            getCorps();
            getLocations();
            getMetadataByGroup("Enso");
            getMetadataByGroup("SoilTexture");
            getMetadataByGroup("Faw");
            getMetadataByGroup("Cultivar");
            getMetadataByGroup("Water");
            getMetadataByGroup("Percentile");
            getMetadataByGroup("SoilDepth");
            getMetadataByGroup("Season");
            getSoilMaxDepth();
        }
    }, []);

    // default show the location tip after location selected
    const [visible, setVisible] = useState(true);
    const [execute, setExecute] = useState<boolean>(false);
    // currentQueryId
    const [currentQueryId, setCurrentQueryId] = useState<number | null>(null);
    // executeQueryIds
    const [executeQueryIds, setExecuteQueryIds] = useState<number[]>([]);
    // savedQueryIds
    const [savedQueryIds, setSavedQueryIds] = useState<number[]>([]);

    const goExecuteQuery = async (_queryId, _options) => {
        dispatch({ type: normalActionTypes.NORMAL_SHOWLODDING, payload: true});
        const res = await executeQueryForSimulation(
            {
                queryId:_queryId,
                crop:_options?.crop,
                country:_options?.country,
                location:_options?.location,
                soilTexture:_options?.soilTexture,
                soilDepth:_options?.soilDepth,
                faw:_options?.faw,
                enso:_options?.enso,
                season:_options?.season,
                cultivar:_options?.cultivar,
                water:_options?.water
            }
        );
        const _result=res.data;
        dispatch({ type: normalActionTypes.NORMAL_SHOWLODDING, payload: false});
        setVisible(false);

        const {
            queryId,
            queryGroupData,
        }: { queryId: number; queryGroupData: any } = _result;
        if (queryId) {
            setExecuteQueryIds(Array.from(new Set([...executeQueryIds, queryId])));
            setCurrentQueryId(queryId);
        }
        if (!queryGroupData) {
            message.info(
                "We cann`t find reliative data! Please select other condition!",
            );
            setExecute(false);
            setShowMap(true);
        } else {
            setChartsData(queryGroupData);
            setExecute(true);
            setShowMap(false);
        }
    };
    const goSaveQuery = async (_queryId, _queryName, _options) => {
        const res=await saveQueryForSimulation({
            queryId:_queryId,
            queryName:_queryName,
            crop:_options?.crop,
            country:_options?.country,
            location:_options?.location,
            soilTexture:_options?.soilTexture,
            soilDepth:_options?.soilDepth,
            faw:_options?.faw,
            enso:_options?.enso,
            season:_options?.season,
            cultivar:_options?.cultivar,
            water:_options?.water,
        });
        console.log("goSaveQuery",res.data);
        const _currentQueryId=res.data;
        message.info("Query saved successfully.");
        setSaveQueryModalVisible(false);
        setConfirmLoading(false);
        setSavedQueryIds(Array.from(new Set([...savedQueryIds, _currentQueryId])));
        setExecuteQueryIds(
            Array.from(new Set([...executeQueryIds, _currentQueryId])),
        );
        setCurrentQueryId(_currentQueryId);
        setQueryName("");
    };
    // save query modal for query name
    const [saveQueryModalVisible, setSaveQueryModalVisible] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    // query name
    const [queryName, setQueryName] = useState<string>("");
    const changeQueryName = useCallback((value: string) => {
        setQueryName(value);
    }, []);
    const handleOk = useCallback(
        async (queryOptionsValue) => {
            console.log("99999Enter handleOk");
            if (queryName == "") {
                return message.info("Please fill the Query Name");
            }
            setConfirmLoading(true);
            const res=await checkQueryNameIsExistsForSimulation({
                queryName:queryName
            });
            const isExists=res.data;
            console.log("queryName",queryName);
            console.log("isExists",isExists);
            if (isExists) {
                setConfirmLoading(false);
                return message.info("The query name already exists.");
            }
            console.log("99999executeQueryIds",executeQueryIds);
            if (executeQueryIds.length > 0) {
                const result=await checkQueryIsExistsForSimulation(
                    {
                        queryIds:executeQueryIds.join(","),
                        crop:queryOptionsValue.crop,
                        country:queryOptionsValue.country,
                        location:queryOptionsValue.location,
                        soilTexture:queryOptionsValue.soilTexture,
                        soilDepth:queryOptionsValue.soilDepth,
                        faw:queryOptionsValue.faw,
                        enso:queryOptionsValue.enso,
                        season:queryOptionsValue.season,
                        cultivar:queryOptionsValue.cultivar,
                        water:queryOptionsValue.water
                    }
                );
                console.log("99999result.data",result.data);
                const _outQueryId=result.data;
                if (_outQueryId > 0) {
                    goSaveQuery(_outQueryId, queryName, null);
                } else {
                    goSaveQuery(null, queryName, queryOptionsValue);
                }}
            else{
                goSaveQuery(null, queryName, queryOptionsValue);
            }
        },
        [executeQueryIds, savedQueryIds, queryName],
    );
    const handleCancel = () => {
        setSaveQueryModalVisible(false);
        setQueryName("");
    };

    const getQueryOptionsValue = useCallback(() => {
        return {
            country: selectedCountry,
            crop: selectedCrop,
            location: selectedLocation,
            latitude: null,
            longitude: null,
            soilTexture: selectedSoilTexture,
            soilDepth: selectedSoilDepth,
            faw: selectedFaw,
            enso: selectedEnso,
            season: selectedSeason,
            cultivar: selectedCultivar,
            water: selectedWater,
            plantDayOfYear: null,
        };
    }, [
        selectedCountry,
        selectedCrop,
        selectedLocation,
        selectedSoilTexture,
        selectedSoilDepth,
        selectedFaw,
        selectedEnso,
        selectedSeason,
        selectedCultivar,
        selectedWater,
    ]);
    const validQueryOptions = useCallback((queryOptionsValue) => {
        let key: string;
        for (key in queryOptionsValue) {
            if (
                key !== "plantDayOfYear" &&
                key !== "season" &&
                key !== "latitude" &&
                key !== "longitude"
            ) {
                if (
                    queryOptionsValue[key] == null ||
                    queryOptionsValue[key].length == 0
                ) {
                    return false;
                }
                if (
                    key == "country" &&
                    queryOptionsValue.country?.toLowerCase() == CountryBrazil &&
                    queryOptionsValue.season == null
                ) {
                    return false;
                }
            }
        }
        return true;
    }, []);

    const saveClicked = useCallback(
        async (queryOptionsValue) => {
            if (!validQueryOptions(queryOptionsValue)) {
                setVisible(true);
                if (queryOptionsValue.location) {
                    setExecuteClickedStatus(false);
                }
                return;
            }
            if (executeQueryIds.length > 0) {
                const res=await checkQueryIsExistsForSimulation(
                    {
                        queryIds:executeQueryIds.join(","),
                        crop:queryOptionsValue.crop,
                        country:queryOptionsValue.country,
                        location:queryOptionsValue.location,
                        soilTexture:queryOptionsValue.soilTexture,
                        soilDepth:queryOptionsValue.soilDepth,
                        faw:queryOptionsValue.faw,
                        enso:queryOptionsValue.enso,
                        season:queryOptionsValue.season,
                        cultivar:queryOptionsValue.cultivar,
                        water:queryOptionsValue.water
                    }
                );
                const _outQueryId=res.data;
                console.log("saveClicked",res.data);
                if (savedQueryIds.indexOf(_outQueryId) > -1) {
                    message.info("The filled conditions is existed and saved!");
                    setSaveQueryModalVisible(false);
                } else {
                    setSaveQueryModalVisible(true);
                }
            } else {
                setSaveQueryModalVisible(true);
            }
        },
        [executeQueryIds, savedQueryIds],
    );
    const executeClicked = useCallback(
        async (queryOptionsValue) => {
            if (!validQueryOptions(queryOptionsValue)) {
                setVisible(true);
                if (queryOptionsValue.location) {
                    setExecuteClickedStatus(false);
                }
                return;
            }
            setExecute(false);
            console.log("9999999executeQueryIds",executeQueryIds);
            if (executeQueryIds.length > 0) {
                const res = await checkQueryIsExistsForSimulation(
                    {
                        queryIds:executeQueryIds.join(","),
                        crop:queryOptionsValue.crop,
                        country:queryOptionsValue.country,
                        location:queryOptionsValue.location,
                        soilTexture:queryOptionsValue.soilTexture,
                        soilDepth:queryOptionsValue.soilDepth,
                        faw:queryOptionsValue.faw,
                        enso:queryOptionsValue.enso,
                        season:queryOptionsValue.season,
                        cultivar:queryOptionsValue.cultivar,
                        water:queryOptionsValue.water
                    }
                );
                const _outQueryId=res.data;
                //console.log("9999999_outQueryId",_outQueryId);
                if (_outQueryId > 0) {
                    goExecuteQuery(_outQueryId, null);
                } else {
                    goExecuteQuery(null, queryOptionsValue);
                }
            } else {
                //console.log("9999999firstExecute");
                goExecuteQuery(null, queryOptionsValue);
            }

        },
        [executeQueryIds],
    );

    const mapSelectedLocationCallBack = (locationInfo:any) => {
        console.log("selected callback",locationInfo);
        if (locationInfo) {
            const [location, latitude, longitude] = locationInfo.split("#");
            console.log("selected callback data:",location, " lat: ", latitude, " long: ",longitude);
            const _locations = locationList.filter(x => x.location == location && x.latitude == latitude && x.longitude == longitude);
            console.log("selected callback _locations:",_locations);
            if (_locations.length > 0) {
                firstLevelChanged(_locations[0].country, selectedCrop, _locations[0].location);
            } else {
                firstLevelChanged(null, selectedCrop, null);
            }
        } else {
            firstLevelChanged(null, selectedCrop, null);
        }
    };
    useEffect(()=>{
        if(simulationIsMapClick){
            const tmpInfo = simulationLocationInfo?.split("#") || "0#0#0#0";
            console.log("aaaaa selected locationinfo selectedLocationInfo",simulationLocationInfo);
            console.log("aaaaa selected locationinfo location",tmpInfo[0]);
            console.log("aaaaa selected locationinfo lat",tmpInfo[1]);
            console.log("aaaaa selected locationinfo long",tmpInfo[2]);
            const _locations = locationList.filter(x => x.location === tmpInfo[0]
                                                    && x.latitude === Number(tmpInfo[1])
                                                    && x.longitude === Number(tmpInfo[2]));
            console.log("aaaaa selected callback _locations:",_locations);
            console.log("aaaaa selected callback locationList:",locationList);
            if (_locations.length > 0) {
                firstLevelChanged(_locations[0].country, selectedCrop, _locations[0].location);
            } else {
                firstLevelChanged(null, selectedCrop, null);
            }
            // dispatch({
            //     type: simulationAction.NORMAL_SIMULATION_IS_MAPCLICK,
            //     payload:{
            //         isClick: false
            //     }
            // });
        }
        else{
            console.log("aaaaa selectedLocation, ",selectedLocation);
            dispatch({
                type: simulationAction.NORMAL_SIMULATION_LOCATION_INFO,
                payload: "0#0#0#0"
            });
            setSelectedLocation(null);
            // setSelectedCountry(null);
        }
    },[simulationIsMapClick,simulationClickCount]);

    return (
        <Spin spinning={showLodding}>
            <div className="create-query-container">
                <HeadComponent></HeadComponent>
                <SideBarComponent></SideBarComponent>
                <div
                    className="div-left"
                    style={{ height: divLeftScrollHeight, overflow: "auto" }}
                >
                    <div style={{ marginTop: "8px" }} className="div-title">
                        <span>Environmental Characterization</span>
                    </div>

                    {/* Environmental Characterization : Country , Crop  and Location select */}
                    <div style={{ marginTop: "9px" }}>
                        <div>
                            <label>
                                  Country <span className="span-item"> *</span>
                            </label>
                        </div>
                        <div className="selectLocation">
                            <Select
                                size="large"
                                placeholder="Select country"
                                value={selectedCountry}
                                onChange={(value, option) => {
                                    console.log("Select country",value);
                                    setSelectedCountry(value);
                                    firstLevelChanged(value, selectedCrop, selectedLocation);
                                }}
                            >
                                {countryList?.map((item, index) => {
                                    return (
                                        <>
                                            <option
                                                key={item.countryCode + index}
                                                value={item.country}
                                            >
                                                {item.country}
                                            </option>
                                        </>
                                    );
                                })}
                            </Select>
                        </div>
                        <div>
                            <label>
                                  Crop <span className="span-item"> *</span>
                            </label>
                        </div>
                        <div>
                            <Select size="large"
                                value={selectedCrop}
                                placeholder="Select crop"
                                options={cropList.filter(
                                    (c) => filteredCrop?.indexOf(c.value) > -1,
                                )}
                                onChange={(value, option) => {
                                    console.log("Select crop",value);
                                    setSelectedCrop(value);
                                    firstLevelChanged(selectedCountry, value, selectedLocation);
                                }}>
                            </Select>
                        </div>
                        <div>
                            <label>
                                  Location <span className="span-item"> *</span>
                            </label>
                        </div>
                        <div className="selectLocation">
                            <Select size="large"
                                placeholder="Select location"
                                value={selectedLocation}
                                onChange={(value, option:any) => {
                                    console.log("Select location",value);
                                    console.log("Select location option",option);
                                    console.log("Select location key",option?.key);
                                    setSelectedLocation(value);
                                    firstLevelChanged(selectedCountry, selectedCrop, value);
                                    dispatch({
                                        type: simulationAction.NORMAL_SIMULATION_LOCATION_INFO,
                                        payload: option?.key
                                    });
                                }}>
                                {filteredLocationList?.map((item, index) => {
                                    return (
                                        <>
                                            <option
                                                // key={item.location + index}
                                                key={`${item?.location}#${item?.latitude}#${item?.longitude}#${index}`}
                                                value={item.location}
                                            >
                                                {item.location}
                                            </option>
                                        </>
                                    );
                                })}
                            </Select>
                        </div>
                    </div>

                    {/* Environment:  Soil texture, Rooting depth (in cm), Water availability at planting (% FC), ENSO */}
                    <div style={{ marginTop: "16px" }}>
                        <div>
                            <span>Environment</span>
                        </div>
                        <div>
                            <label>
                                Soil texture <span className="span-item"> *</span>
                            </label>
                        </div>
                        <div>
                            <Select size="large"
                                placeholder="Select"
                                value={selectedSoilTexture}
                                onChange={(e)=>{
                                    console.log("Select SoilTexture",e);
                                    setSelectedSoilTexture(e);
                                }}
                                options={soilTextureList.filter(
                                    (st) => filteredSoilTexture?.indexOf(st.value) > -1,
                                )}
                            >
                            </Select>
                        </div>
                        <div>
                            <label>
                                  Rooting depth (in cm)<span className="span-item"> *</span>
                            </label>
                        </div>
                        <div className="rootStyle">
                            <QuerySelectComponent
                                placeholder="Add production"
                                disabled={!formEditable}
                                optionSource={soilDepthList.filter(
                                    (sd) => filteredSoilDepth?.indexOf(sd.value) > -1,
                                )}
                                selectedValue={selectedSoilDepth}
                                setSelectedValue={setSelectedSoilDepth}
                            />
                        </div>
                        <div>
                            <label>
                                  Water availability at planting (% FC){" "}
                                <span className="span-item"> *</span>
                            </label>
                        </div>
                        <div>
                            <Select size="large"
                                placeholder="Select"
                                value={selectedFaw}
                                onChange={(e)=>{
                                    console.log("Select Faw",e);
                                    setSelectedFaw(e);
                                }}
                                options={fawList.filter(
                                    (f) => filteredFaw?.indexOf(f.value) > -1,
                                )}
                            >
                            </Select>
                        </div>
                        <div>
                            <label>
                                  ENSO<span className="span-item"> *</span>
                            </label>
                        </div>
                        <div>
                            <Select size="large"
                                placeholder="Select"
                                value={selectedEnso}
                                onChange={(e)=>{
                                    console.log("Select Enso",e);
                                    setSelectedEnso(e);
                                }}
                                options={ensoList.filter(
                                    (e) => filteredEnso?.indexOf(e.value) > -1,
                                )}
                            >
                            </Select>
                        </div>
                    </div>

                    {/* Growing data: Season, Water regime, Cultivar maturity */}
                    <div style={{ marginTop: "16px" }}>
                        <div>
                            <span>Growing data</span>
                        </div>
                        <IF show={isShowSeason}>
                            <div>
                                <div>
                                    <label>
                                          Season<span className="span-item"> *</span>
                                    </label>
                                </div>
                                <div>
                                    <Select size="large"
                                        placeholder="Select"
                                        value={selectedSeason}
                                        onChange={(e)=>{
                                            console.log("Select Season",e);
                                            setSelectedSeason(e);
                                        }}
                                        options={seasonList.filter(
                                            (s) => filteredSeason?.indexOf(s.value) > -1,
                                        )}
                                    >
                                    </Select>
                                </div>
                            </div>
                        </IF>
                        <div>
                            <label>
                                 Water regime <span className="span-item"> *</span>
                            </label>
                        </div>
                        <div className="rootStyle">
                            <QuerySelectComponent
                                placeholder="Add production"
                                disabled={!formEditable}
                                optionSource={waterList.filter(
                                    (w) => filteredWater?.indexOf(w.value) > -1,
                                )}
                                selectedValue={selectedWater}
                                setSelectedValue={setSelectedWater}
                            />
                        </div>
                        <div>
                            <label>
                                Cultivar maturity<span className="span-item"> *</span>
                            </label>
                        </div>
                        <div>
                            <QuerySelectComponent
                                placeholder="Add cultivar maturity"
                                disabled={!formEditable}
                                optionSource={cultivarList.filter(
                                    (c) => filteredCultivar?.indexOf(c.value) > -1,
                                )}
                                selectedValue={selectedCultivar}
                                setSelectedValue={setSelectedCultivar}
                            />
                        </div>
                        <div
                            className="div-submit"
                            style={{
                                display: formEditable ? "block" : "none",
                                marginTop: "8px",marginRight:"15px"
                            }}
                        >
                            <input type="button" className="btn-save" value="Save"
                                onClick={() => {
                                    const queryOptionsValue = getQueryOptionsValue();
                                    saveClicked(queryOptionsValue);
                                }} />
                            <input type="button" className="btn-execute" value="Execute"
                                onClick={() => {
                                    const queryOptionsValue = getQueryOptionsValue();
                                    executeClicked(queryOptionsValue);
                                }} />
                        </div>
                    </div>
                </div>

                {/* show part, map and chart view is set in here */}
                <div className="div-right">
                    {visible ? (
                        <Alert
                            className={alertPopInfo.className}
                            description={
                                <Row gutter={24}>
                                    <Col style={{ marginTop: "20px" }} span={3}>
                                        <label className="start-icon">
                                            <StartIcon />
                                        </label>
                                    </Col>
                                    <Col span={21}>
                                        <label className="postion">
                                            <div
                                                style={{ marginBottom: "23px" }}
                                                dangerouslySetInnerHTML={{
                                                    __html: alertPopInfo.messageContent,
                                                }}
                                            ></div>
                                        </label>
                                    </Col>
                                </Row>
                            }
                            type="warning"
                            closeIcon={<CloseIcon />}
                            closable
                            onClose={onCloseAlert}
                        />
                    ) : (
                        ""
                    )}
                    {/* Chart View button : Click this will show others chart*/}
                    <IF show={showMap}>
                        <div style={{
                            display:"flex",
                            alignItems:"center",
                            flexDirection:"row-reverse",
                            height:"72px"}}>
                            <Button
                                style={{
                                    border:"1px solid #59B948",
                                    borderRadius:"24px",
                                    float:"right",
                                    width:"98px",
                                    height: "40px",
                                    fontFamily: "Noto-Sans-Regular",
                                    fontStyle: "normal",
                                    fontWeight: 400,
                                    fontSize: "14px",
                                    lineHeight: "20px",
                                }}
                                onClick={() => {setShowMap(false);}}>
                                <span style={{color: "#59B948"}}>Chart View</span>
                            </Button>
                        </div>
                        <div>
                            <GoogleMapComponents
                                mapSelectedLocationCallBack={mapSelectedLocationCallBack}
                            />
                        </div>
                    </IF>
                    {/* Tap Pane button: here includes map view and download and others button, map show set in this components  */}
                    <IF show={!showMap}>
                        <QueryTabPaneComponent
                            chartsData={chartsData}
                            queryId={currentQueryId}
                            showMapViewBtn={true}
                            mapViewCallback={() => {
                                setShowMap(true);
                            }}
                        />
                    </IF>
                </div>

                {/* When click the save button, show this Modal, to save this query to the My Query part */}
                <Modal
                    className="save-query"
                    title="Save query"
                    okText="Save"
                    cancelText="Cancel and return"
                    open={saveQueryModalVisible}//Is the dialog box visible
                    onOk={() => {
                        const queryOptionsValue = getQueryOptionsValue();
                        handleOk(queryOptionsValue);
                    }}
                    confirmLoading={confirmLoading}
                    onCancel={handleCancel}
                >
                    <p>Check the Query Name before first.</p>
                    <p>Location, environment and growing data will be save.</p>
                    <p className="pad-top-space">
                            Query name
                        <Input
                            onChange={(e) => {
                                changeQueryName(e.target.value);
                            }}
                            value={queryName}
                            placeholder="Query Name"
                            allowClear={true}
                        />
                    </p>
                </Modal>
            </div>
        </Spin>
    );
};

export default CreateQuery;
