import React, { useEffect } from "react";
import "./index.less";
import IF from "@/components/IF";

interface IProps {
    data:{
        [keyText: string]: {
            weatherDate: string
            serieName: string
            serieValue: string
        }
    }
    frostWarning: string
}

const InfoCard = (props: IProps) => {
    const {
        data,
        frostWarning
    } = props;

    return (
        <div className="info-card">
            <div className="single-turf-div">
                <IF show={Boolean(data["soiltemp10cm"])}>
                    <label>{data["soiltemp10cm"]?.serieName}</label>
                    <label>{data["soiltemp10cm"]?.serieValue}</label>
                </IF>
                <IF show={Boolean(data["soiltemp10cm_f"])}>
                    <label>{data["soiltemp10cm_f"]?.serieName}</label>
                    <label>{data["soiltemp10cm_f"]?.serieValue}</label>
                </IF>
            </div>
            <div className="single-turf-div">
                <IF show={Boolean(data["frostWarning"])}>
                    <label>{frostWarning}</label>
                    <label>{data["frostWarning"]?.serieValue}</label>
                </IF>
                <IF show={Boolean(data["frostWarning_f"])}>
                    <label>{frostWarning}</label>
                    <label>{data["frostWarning_f"]?.serieValue}</label>
                </IF>
            </div>
            <div className="single-turf-div">
                <IF show={Boolean(data["groundtemp"])}>
                    <label>{data["groundtemp"]?.serieName}</label>
                    <label>{data["groundtemp"]?.serieValue}</label>
                </IF>
                <IF show={Boolean(data["groundtemp_f"])}>
                    <label>{data["groundtemp_f"]?.serieName}</label>
                    <label>{data["groundtemp_f"]?.serieValue}</label>
                </IF>
            </div>
        </div>
    );
};

export default InfoCard;