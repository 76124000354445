export type NavMenusType ={
    path: string;
    title?: string;
    admin?: boolean;
}

export const navMenus : NavMenusType[] = [
    {
        path: "/cropstress/home",
        title: "Main",
        admin: false
    },
    {
        path: "/cropstress/report",
        title: "Report",
        admin: false
    },
    {
        path: "/cropstress/UserManagement",
        title: "User Mananement",
        admin: true
    }
];

// export const notShowMenus: string[] = [
//     "/404",
//     "/visualization/DiseaseRiskPrediction",
//     "/visualization/cropstress-terms",
//     "/visualization/cropstress-login",
//     "/visualization/cropstress-signUp",
//     "/visualization/cropstress-forgetPassword"
// ];

export const notShowMenus: string[] = [
    "/404",
    "/cropstress/DiseaseRiskPrediction",
    "/cropstress/terms",
    "/cropstress/login",
    "/cropstress/signUp",
    "/cropstress/forgetPassword"
];