import React from "react";
import "./index.less";
import {
    DewPointFirstIcon,
    WindDirectionSecondIcon,
    WindGustsSecondIcon,
    WindSpeedIconSecondIcon,
    NewWindDirectionIcon
} from "@/resource/IconComponents";
import IF from "@/components/IF";
import { useSelector } from "react-redux";
import { RootState } from "@/redux/reducer/store";
interface IProps {
    weatherData?: {
        [keyValu: string]: string
    }
}


const DailyWind = (props: IProps) => {

    const {
        currentLanguage
    } = useSelector((state: RootState) => state.commonReducer);

    const {
        weatherData
    } = props;

    return (
        <div className="daily-container-wind">
            <div className="up-div">
                {currentLanguage.txt_Wind}
            </div>
            <div className="down-div">
                <IF show={Boolean(weatherData["we_wind_kmh"]) || Boolean(weatherData["we_wind_mph"])}>
                    <div>
                        <div className="icon-div">
                            <WindSpeedIconSecondIcon />
                            <label className="number-label">{weatherData["we_wind_kmh"]}</label>
                            <label className="number-label">{weatherData["we_wind_mph"]}</label>

                        </div>
                        <div className="text-div">
                            {weatherData["we_wind_kmh-name"]}
                            {weatherData["we_wind_mph-name"]}
                        </div>
                    </div>
                </IF>
                <IF show={Boolean(weatherData["ResWindDirection"])}>
                    <div>
                        <div className="icon-div">
                            <NewWindDirectionIcon />
                            <label className="number-label">{weatherData["ResWindDirection"]}</label>
                        </div>
                        <div className="text-div">
                            {weatherData["ResWindDirection-name"]}
                        </div>
                    </div>
                </IF>
                <IF show={Boolean(weatherData["we_windgusts_kmh"]) || Boolean(weatherData["wind_gusts_mph"])}>
                    <div>
                        <div className="icon-div">
                            <WindGustsSecondIcon />
                            <label className="number-label">{weatherData["we_windgusts_kmh"]}</label>
                            <label className="number-label">{weatherData["wind_gusts_mph"]}</label>
                        </div>
                        <div className="text-div">
                            {weatherData["we_windgusts_kmh-name"]}
                            {weatherData["wind_gusts_mph-name"]}
                        </div>
                    </div>
                </IF>
            </div>
        </div>
    );
};

export default DailyWind;